import * as React from 'react';
import { TwitterPicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import { AbstractCode } from 'src/types/insights';
import CodeChip from '../Common/CodeChip';
import { TAG_COLORS } from '../utils/color';
import CodeSelect from './CodeSelect';

interface BaseProps {
  closeDialog: () => void;
  codeType: string;
  codes: AbstractCode[];
  onSuccess: (
    code: Pick<
      AbstractCode,
      'color' | 'description' | 'name' | 'parent_id' | 'code_type'
    >
  ) => void;
  open: boolean;
}

type Props = BaseProps & DispatchProp;

const CreateCodeDialog: React.FunctionComponent<Props> = (props: Props) => {
  const { closeDialog, codes, onSuccess, open, codeType } = props;
  const { t } = useTranslation();

  const [color, setColor] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [name, setName] = React.useState('');
  const [nameError, setNameError] = React.useState('');
  const [parentId, setParentId] = React.useState(-1);
  const parentCode: AbstractCode | undefined = codes.filter(
    (code) => code.id === parentId
  )[0];
  const calculatedColor =
    color || (parentCode !== undefined ? parentCode.color : '#ffffff');

  React.useEffect(() => {
    // reset when opening only
    // avoids making the reset visible during close
    if (open) {
      setColor('');
      setDescription('');
      setName('');
      setNameError('');
      setParentId(-1);
    }
  }, [open]);

  const handleCancel = () => {
    closeDialog();
  };

  const handleSubmit = () => {
    if (nameError.length > 0) {
      return;
    }
    closeDialog();
    onSuccess({
      color: calculatedColor,
      description: description,
      name: name,
      parent_id: parentId,
      code_type: codeType,
    });
  };

  const handleCodeSelectChange = (parentId: number) => {
    setParentId(parentId);
  };

  const handleColorChangeComplete = (color: any) => {
    setColor(color.hex);
  };

  const exampleCode: AbstractCode = {
    id: -1,
    name: name,
    color: calculatedColor,
    parentage: [-1],
    code_type: codeType,
  };
  let dialogTitle;

  switch (codeType) {
    case 'thematic':
      dialogTitle = t('insights.create_thematic_code');
      break;
    case 'structural':
      dialogTitle = t('insights.create_structural_code');
      break;
    case 'demographic':
      dialogTitle = t('insights.create_demographic');
      break;
    default:
      dialogTitle = t('insights.create_code');
      break;
  }

  return (
    <Dialog
      data-testid="CreateCodeDialog"
      disableRestoreFocus
      onClose={closeDialog}
      open={open}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          sx={{ width: 360 }}
        >
          <CodeChip code={exampleCode} />
        </Box>
        <Box sx={{ mt: 4 }}>
          <TextField
            autoFocus
            error={nameError.length > 0}
            fullWidth
            helperText={nameError}
            label={t('common.name')}
            onChange={(event) => {
              if (event.target.value === '') {
                setNameError(t('insights.name_error'));
              } else if (event.target.value === 'Exclude from Portal') {
                setNameError(t('insights.reserve_name_error'));
              } else {
                setNameError('');
              }
              setName(event.target.value);
            }}
            value={name}
          />
        </Box>
        <Box sx={{ mt: 4 }}>
          <TextField
            fullWidth
            label={t('common.description')}
            multiline
            onChange={(event) => {
              setDescription(event.target.value);
            }}
            value={description}
          />
        </Box>
        <Box sx={{ mt: 4 }}>
          <TwitterPicker
            color={color}
            colors={TAG_COLORS}
            onChangeComplete={handleColorChangeComplete}
            triangle="hide"
            width="360px"
          />
        </Box>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          sx={{ mt: 2 }}
        >
          <Button
            disabled={color === ''}
            onClick={() => setColor('')}
            startIcon={<ClearIcon />}
            variant="outlined"
          >
            {t('insights.unset_color')}
          </Button>
        </Box>
        <Box sx={{ mt: 4 }}>
          <CodeSelect codes={codes} onChange={handleCodeSelectChange} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>{t('common.cancel')}</Button>
        <Button onClick={handleSubmit}>{t('common.confirm')}</Button>
      </DialogActions>
    </Dialog>
  );
};

CreateCodeDialog.displayName = 'CreateCodeDialog';

export default connect()(CreateCodeDialog);
