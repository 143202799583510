import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';

import BgImage from 'src/components/BgImage/BgImage';
import Button from 'src/components/core/Button/Button';
import LoadingSpinner from 'src/components/core/LoadingSpinner/LoadingSpinner';
import { EditTransaction } from 'src/redux/auth/auth-slice';
import { User } from 'src/types/auth';

import styles from './ProfileSettings.module.scss';

interface FormProps {
  email: string;
  firstName: string;
  lastName: string;
  profileImage: string | undefined;
  profileImageFile: File | undefined;
}

interface ProfileSettingsProps {
  user: User;
  profileTransaction?: EditTransaction;
  profileImageTransaction?: EditTransaction;
  onUpdateUser: (editTransaction: EditTransaction) => void;
}

const InnerForm = (props: ProfileSettingsProps & FormikProps<FormProps>) => {
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    isSubmitting,
    status,
    profileTransaction,
    profileImageTransaction,
    setFieldValue,
    setStatus,
    setSubmitting,
    setFieldError,
    dirty,
  } = props;

  const handleChangeProfileImage = (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = evt.target.files;
    if (files && files.length) {
      const file = files[0];
      setFieldValue('profileImageFile', file);
      // https://github.com/jaredpalmer/formik/issues/1218#issuecomment-481707848
      setTimeout(handleSubmit, 0);
    }
  };
  const { t } = useTranslation();

  React.useEffect(() => {
    // Logic for Profile Update
    if (profileTransaction?.isSaved) {
      setSubmitting(false);
      setStatus({
        status: 'success',
        message: 'Successfully updated!',
        attribute: 'profile',
      });
    }
    if (profileTransaction?.error) {
      setSubmitting(false);
      setStatus(profileTransaction.error);
    }

    // Logic for Profile Image Update
    if (profileImageTransaction?.isSaved) {
      setSubmitting(false);
      setFieldValue('profileImageFile', undefined);
      setStatus({
        status: 'success',
        message: 'Successfully updated!',
        attribute: 'profileImage',
      });
    }
    if (profileImageTransaction?.error) {
      const error = profileImageTransaction?.error;
      setSubmitting(false);
      setFieldValue('profileImageFile', undefined);
      setStatus(error);
      setFieldError('profileImage', error.message);
    }
  });

  const profileImageHeight = '90px';

  return (
    <div className="d-flex">
      <Form onSubmit={handleSubmit} className="me-5">
        <div className="form-row">
          <FormGroup className="col">
            <Label for="firstName">{t('settings.first_name')}</Label>
            <Input
              onChange={handleChange}
              value={values.firstName}
              onBlur={handleBlur}
              name="firstName"
              id="firstName"
              data-testid="first-name"
            />
            {status && status.attribute === 'first_name' && (
              <div className="text-danger">{status.message}</div>
            )}
          </FormGroup>
          <FormGroup className="col">
            <Label for="lastName">{t('settings.last_name')}</Label>
            <Input
              onChange={handleChange}
              value={values.lastName}
              onBlur={handleBlur}
              name="lastName"
              data-testid="last-name"
              id="lastName"
            />
            {status && status.attribute === 'last_name' && (
              <div className="text-danger">{status.message}</div>
            )}
          </FormGroup>
        </div>
        <FormGroup>
          <Label for="email">{t('settings.email')}</Label>
          <Input
            type="email"
            onChange={handleChange}
            value={values.email}
            onBlur={handleBlur}
            name="email"
            id="email"
            autoComplete="username"
            data-testid="email"
          />
          {errors.email && (
            <div className="text-danger" data-testid="error-email">
              {errors.email}
            </div>
          )}
          {status && status.attribute === 'email' && (
            <div className="text-danger">{status.message}</div>
          )}
        </FormGroup>

        <Button
          type="submit"
          disabled={!dirty || isSubmitting || !!errors.email}
          color="primary"
          className="me-2"
          data-testid="save-button"
        >
          {t('settings.update')}
        </Button>
        <LoadingSpinner
          active={isSubmitting && values.profileImageFile == null}
        />
        {status && !errors.email && status.attribute === 'profile' && (
          <div className={cx({ 'text-danger': status.status === 'fail' })}>
            {status.message}
          </div>
        )}
      </Form>
      <FormGroup className="text-center">
        <div>
          <BgImage
            src={values.profileImage}
            className={cx('rounded-circle mb-2', {
              'position-absolute': values.profileImage == null,
            })}
            width="90px"
            height={profileImageHeight}
            vignette
          />
          {/* place a camera icon over the blank BgImage */}
          {values.profileImage == null && (
            <div
              style={{ height: profileImageHeight }}
              className={cx(
                'mb-2 d-flex justify-content-center align-items-center',
                styles.nullProfileImage
              )}
            >
              <FontAwesomeIcon icon="camera"></FontAwesomeIcon>
            </div>
          )}
        </div>
        <Input
          type="file"
          name="profileImage"
          id="profileImage"
          onChange={handleChangeProfileImage}
          accept=".jpg,.jpeg,.png"
          className={styles.profileImageInput}
          aria-label="change profile image"
        />
        <div className="text-start">
          <Label
            htmlFor="profileImage"
            className={cx(
              styles.profileImageLabelButton,
              'btn btn-secondary text-dark'
            )}
          >
            {t('common.change')}
          </Label>{' '}
          <LoadingSpinner
            active={isSubmitting && values.profileImageFile != null}
          />
          {status && status.attribute === 'profile_image' && (
            <div className="text-danger">{status.message}</div>
          )}
        </div>
      </FormGroup>
    </div>
  );
};

const ProfileSettings = withFormik<ProfileSettingsProps, FormProps>({
  // set initial values based on user
  mapPropsToValues: ({ user }: ProfileSettingsProps) => ({
    email: user.email,
    firstName: user.first_name,
    lastName: user.last_name,
    profileImage: user.profile_image,
    profileImageFile: undefined,
  }),

  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email('Email is not valid')
      .required('Email is required'),
    firstName: Yup.string(),
    lastName: Yup.string(),
  }),

  handleSubmit(
    { email, firstName, lastName, profileImageFile }: FormProps,
    { props, setSubmitting }
  ) {
    setSubmitting(true);

    // if we have a profile image change, submit it
    if (profileImageFile != null) {
      props.onUpdateUser({
        userId: props.user.id,
        changes: {
          new_profile_image: profileImageFile,
        },
        editType: 'profile_image',
      });
      return;
    }

    // submit the settings
    props.onUpdateUser({
      userId: props.user.id,
      changes: {
        email,
        first_name: firstName,
        last_name: lastName,
      },
      editType: 'profile',
    });
  },

  validateOnBlur: true,
  enableReinitialize: true,
})(InnerForm);

export default ProfileSettings;
