import * as React from 'react';
import { connect } from 'react-redux';

import Tag from 'src/components/core/Tag/Tag';
import { StoreState } from 'src/redux/store';
import topicsSelectors from 'src/redux/topics/topics-selectors';
import { TopicWithRelatedContent } from 'src/types/conversation';
import { ColorScale } from 'src/types/core';

import styles from './TopicTag.module.scss';

interface BaseProps {
  topic: TopicWithRelatedContent | undefined;
}

interface StateProps {
  topicColorScale: ColorScale | undefined;
}

type Props = BaseProps & StateProps;

const mapStateToProps = (state: StoreState): StateProps => ({
  topicColorScale: topicsSelectors.getTopicColorScale(state),
});

/* A tag styled to show a highlight's topic */
const TopicTag = ({ topic, topicColorScale }: Props) => {
  if (!topic || !topicColorScale) {
    return null;
  }

  const color = styles[`color${topicColorScale(topic.display_name)}`];

  return (
    <div className="mt-1">
      <Tag className={color} uppercase={true} data-testid="tag">
        {topic.display_name}
      </Tag>
    </div>
  );
};

export default connect(mapStateToProps)(TopicTag);
