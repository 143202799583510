import * as React from 'react';
import turf_circle from '@turf/circle';
import { extent } from 'd3';

import { SVGRedrawOptions } from 'src/types/maps';

const jitters = Array(100)
  .fill(0)
  .map(() => Math.random() - 0.5);

export const ApproximateLocationMarker = ({
  center,
  project,
  className,
  onClick,
  milesRadius = 0.5,
  minPointWidth = 8,
  jitterKey,
}: {
  center: [number, number] | [] | undefined;
  project: SVGRedrawOptions['project'];
  className?: string | undefined;
  onClick?: (evt: React.SyntheticEvent) => void;
  milesRadius?: number;
  minPointWidth?: number;
  jitterKey?: number | undefined;
}) => {
  if (!center || center.length !== 2) {
    return null;
  }

  const options = {
    steps: 64, // number of slices in the polygon to make it round
    units: 'miles' as const,
  };

  if (jitterKey) {
    const jitterScale = 0.001;
    const jitterX =
      jitterScale * jitters[Math.round((2 * jitterKey) % jitters.length)];
    const jitterY =
      jitterScale * jitters[Math.round((jitterKey % jitters.length) / 2)];
    center = [center[0] + jitterX, center[1] + jitterY];
  }

  const circle = turf_circle(center, milesRadius, options);
  if (!circle.geometry) {
    return null;
  }
  let points = circle.geometry.coordinates[0].map((lngLat: [number, number]) =>
    project(lngLat)
  ) as [number, number][];

  const xExtent = extent(points, (d) => d[0]) as [number, number];
  const pointWidth = xExtent[1] - xExtent[0];

  // if too small, recompute points with min point width
  if (pointWidth < minPointWidth) {
    const scale = minPointWidth / pointWidth;
    const circle = turf_circle(center, milesRadius * scale, options);
    if (circle.geometry) {
      points = circle.geometry.coordinates[0].map((lngLat: [number, number]) =>
        project(lngLat)
      );
    }
  }

  // draw the neighborhood
  const pathD = `M${points
    .map((point) => `L${point[0]},${point[1]}`)
    .join(' ')
    .substring(1)}Z`;

  return <path d={pathD} className={className} onClick={onClick} />;
};

export default ApproximateLocationMarker;
