import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import { useUserTransaction } from 'src/components/AdminPage/Common/Hooks/useUserTransaction';
import NameColumnContent from 'src/components/AdminPage/Common/TableColumnContent/NameColumnContent';
import { fullName } from 'src/components/AdminPage/Common/utils';
import ConfirmationModal from 'src/components/ConfirmationModal/ConfirmationModal';
import Button from 'src/components/core/Button/Button';
import {
  ConsumeEvents,
  Table,
  TableProps,
} from 'src/components/core/Table/Table';
import { useCatalogPageContext } from 'src/components/Insights/Catalog/CatalogPageProvider';
import { loadSensemakers } from 'src/redux/catalog/catalog-slice';
import { User } from 'src/types/auth';
import { CRUD } from 'src/types/core';
import { Catalog } from 'src/types/insights';
import { CatalogRole } from 'src/types/organization';
import dateUtils from 'src/util/date';
import { createBasicProvider } from 'src/util/provider';

const [LocalProvider, useLocalContext] = createBasicProvider<{
  sensemakers: User[];
  catalog: Catalog;
  screenIsLoading: boolean;
  getSensemakers: () => void;
}>();

const SensemakersPage = () => {
  const { catalog, sensemakers, dispatch, t, isLoading } =
    useCatalogPageContext();
  const getSensemakers = () => {
    dispatch(loadSensemakers());
  };

  React.useEffect(() => {
    getSensemakers();
  }, []);

  return (
    <LocalProvider
      value={{
        sensemakers,
        catalog,
        getSensemakers,
        screenIsLoading: isLoading,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '0 15rem',
          width: '100%',
        }}
      >
        <div className="d-flex flex-column my-4">
          <span>
            {t('insights.sensemakers_header_text', {
              count: sensemakers.length,
            })}
          </span>
        </div>
        <SensemakersTable />
      </Box>
    </LocalProvider>
  );
};

const SensemakersTable = (props: Partial<TableProps<User>>) => {
  const { t } = useTranslation();
  const { sensemakers } = useLocalContext();

  const tableProps: TableProps<User> = {
    ...props,
    values: sensemakers,
    testId: 'user-catalogs-table',
    valToKey: (state) => state.id,
    columns: [
      {
        id: 'sensemaker-name',
        headerLabel: t('insights.sensemaker_column_1'),
        widthFraction: 6,
        content: NameColumnContent,
      },
      // TODO: enable when highlight assignment is active
      // {
      //   id: 'catalog-role',
      //   headerLabel: t('insights.sensemaker_column_2'),
      //   widthFraction: 2,
      //   content: CatalogRoleContent,
      // },
      {
        id: 'sensemaker-status',
        headerLabel: t('insights.sensemaker_column_3'),
        widthFraction: 4,
        content: StatusContent,
      },
      {
        id: 'sensemaker-remove',
        headerLabel: '',
        widthFraction: 2,
        content: ActionContent,
      },
    ],
  };

  return <Table {...tableProps} />;
};

const StatusContent = ({ val: member }: { val: User }) => {
  const lastActivity = dateUtils.relativeAbsDateFormat(member?.last_access, 30);
  return <Typography>{lastActivity}</Typography>;
};

const ActionContent = ({ val: member }: { val: User }) => {
  const { catalog, getSensemakers, screenIsLoading } = useLocalContext();
  const { transactionLoading, transactionError, startTransaction } =
    useUserTransaction(undefined, getSensemakers);
  const { t } = useTranslation();
  const [confirmationModal, setConfirmationModal] = React.useState(false);

  const onRemoveSensemaker = () => {
    startTransaction({
      type: 'update_user_catalog_role',
      crud: CRUD.delete,
      data: {
        id: member.id,
        catalogId: catalog.id,
        organization_id: catalog.organization_id,
        roles: [
          {
            role_type: CatalogRole.sensemaker.toLowerCase(),
          },
        ],
      },
    });
  };

  // Close modal when transaction has completed has completed and screen is reloaded
  React.useEffect(() => {
    if (!screenIsLoading) {
      setConfirmationModal(false);
    }
  }, [screenIsLoading]);

  // Error Handling
  React.useEffect(() => {
    if (transactionError) {
      toast.error(
        t('insights.remove_sensemaker_error_toast', {
          name: fullName(member),
          catalog: catalog.title,
        }),
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: false,
        }
      );
    }
  }, [transactionError]);

  return (
    <>
      <ConfirmationModal
        isLoading={!!transactionLoading || !!screenIsLoading}
        isOpen={confirmationModal}
        handleCloseModal={() => setConfirmationModal(false)}
        text={{
          question: t('insights.remove_sensemaker_modal_question'),
          description: t('insights.remove_sensemaker_modal_description', {
            name: fullName(member),
            catalog: catalog.title,
          }),
          confirm: t('insights.remove_sensemaker_modal_action'),
        }}
        confirm={onRemoveSensemaker}
      />
      <ConsumeEvents>
        <Button onClick={() => setConfirmationModal(true)}>
          {t('common.remove')}
        </Button>
      </ConsumeEvents>
    </>
  );
};

SensemakersPage.displayName = 'SensemakersPage';
export default SensemakersPage;
