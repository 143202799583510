import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import styles from './ProgressRing.module.scss';

interface Props {
  percent: number;
  size?: 'sm' | 'md' | 'lg' | number | undefined;
  frontColor?: string;
  backColor?: string;
  className?: string;
  id?: string;
  showCheck?: boolean;
  tabIndex?: number;
  style?: React.CSSProperties;
  thickness?: number;
}

class ProgressRing extends React.Component<Props> {
  static defaultProps = {
    frontColor: '#606f7b',
    backColor: '#dfe6ec',
  };

  render() {
    const {
      id,
      size,
      percent,
      className,
      showCheck,
      tabIndex,
      style,
      thickness,
    } = this.props;
    const { frontColor, backColor } = this.props;

    const strokeWidth = thickness ?? 4;
    let width: number | string = 24;
    if (typeof size === 'number') {
      width = size;
    } else if (size === 'sm') {
      width = 16;
    } else if (size === 'md') {
      width = 24;
    } else if (size === 'lg') {
      width = 48;
    } else {
      width = 36;
    }

    const height = width;

    // circumference: 2*pi*r
    const r = 12 - strokeWidth - 2; // 12 - 2 for the width of the stroke
    const circumference = 2 * Math.PI * r;
    const dashArray = `${circumference} ${circumference}`;
    const offset = circumference - (percent / 100) * circumference;

    return (
      <div
        className={cx(className)}
        id={id}
        style={{ position: 'relative', ...style }}
      >
        <svg
          viewBox="0 0 24 24"
          width={width}
          height={height}
          tabIndex={tabIndex}
        >
          <g transform="translate(12 12) rotate(-90)">
            <circle
              r={r}
              stroke={backColor}
              strokeWidth={strokeWidth}
              fill="none"
            />
            <circle
              className="inner-circle"
              r={r}
              stroke={frontColor}
              strokeWidth={strokeWidth}
              strokeDasharray={dashArray}
              strokeDashoffset={offset}
              fill="none"
            />
          </g>
        </svg>
        {showCheck && (
          <span>
            <FontAwesomeIcon icon="check" className={styles.checkIcon} />
          </span>
        )}
      </div>
    );
  }
}

export default ProgressRing;
