import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { Container } from 'reactstrap';
import { NumberParam, useQueryParams } from 'use-query-params';

import { ActivityMapData } from 'src/components/ActivityMap/ActivityMap';
import CollectionSummary from 'src/components/CollectionSummary/CollectionSummary';
import ExploreConversations from 'src/components/ExploreConversations/ExploreConversations';
import Layout from 'src/components/Layout/Layout';
import Panel from 'src/components/Panel/Panel';
import RecentActivity from 'src/components/RecentActivity/RecentActivity';
import StackedHighlightCards from 'src/components/StackedHighlightCards/StackedHighlightCards';
import authSelectors from 'src/redux/auth/auth-selectors';
import collectionSelectors from 'src/redux/collection/collection-selectors';
import {
  loadActivityMap,
  loadCollectionDetails,
} from 'src/redux/collection/collection-slice';
import conversationsSelectors from 'src/redux/conversations/conversations-selectors';
import { loadConversations } from 'src/redux/conversations/conversations-slice';
import { StoreState } from 'src/redux/store';
import { User } from 'src/types/auth';
import { CollectionDetail } from 'src/types/collection';
import { Conversation, Paging } from 'src/types/conversation';
import { makePagingHandlers } from 'src/util/paging';

const PAGE_SIZE = 12;

type BaseProps = RouteComponentProps<{ collectionId: string }>;

interface StateProps {
  activityMapData: ActivityMapData[];
  collectionDetail: CollectionDetail | undefined;
  collectionDetailError: Error | undefined;
  user: User;

  isLoadingConversations: boolean;
  conversationsWithoutSearch: Conversation[];
  conversationsError: Error | undefined;
  conversationsPagingInfo: Paging | undefined;
}

type Props = BaseProps & StateProps & DispatchProp;

const mapStateToProps = (state: StoreState): StateProps => ({
  activityMapData: collectionSelectors.getActivityMap(state),
  collectionDetail: collectionSelectors.getCollectionDetail(state),
  collectionDetailError: collectionSelectors.getCollectionDetailError(state),
  user: authSelectors.getUser(state),

  conversationsWithoutSearch: conversationsSelectors.getConversations(state),
  isLoadingConversations: conversationsSelectors.isLoading(state),
  conversationsError: conversationsSelectors.getError(state),
  conversationsPagingInfo:
    conversationsSelectors.getConversationsPagingInfo(state),
});

const PublicCollectionPage = ({
  match,
  dispatch,
  collectionDetail,
  collectionDetailError,
  user,
  conversationsWithoutSearch,
  isLoadingConversations,
  conversationsError,
  conversationsPagingInfo,
}: Props) => {
  const { t } = useTranslation();
  const collectionId = +match.params.collectionId;
  const userNestedCollection = user.collections.find(
    (c) => c.id === collectionId
  );
  const { title } = userNestedCollection || { title: '' };

  const [query, setQuery] = useQueryParams({
    page: NumberParam,
  });
  const { page: currentPage = 1 } = query;

  React.useEffect(() => {
    dispatch(loadCollectionDetails(collectionId));
    dispatch(loadActivityMap(collectionId));
  }, [collectionId, dispatch]);

  // reload conversations on page change
  React.useEffect(() => {
    const conversationsFilters = {
      page: currentPage,
      limit: PAGE_SIZE,
      collectionIds: [`${collectionId}`],
    };
    dispatch(loadConversations(conversationsFilters));
  }, [collectionId, dispatch, currentPage]);

  // formatted so it would be easy to swap between conversations/secondary results e.g. search/filtered if we ever add a second view
  const conversationData = {
    default: {
      conversations: conversationsWithoutSearch,
      pagingInfo: conversationsPagingInfo,
      error: conversationsError,
      isLoading: isLoadingConversations,
    },
  };

  const { conversations, pagingInfo, error, isLoading } =
    conversationData.default;
  const pagingHandlers = makePagingHandlers(
    currentPage,
    PAGE_SIZE,
    pagingInfo ? pagingInfo.total : 0,
    (page: number) => setQuery({ page }, 'pushIn')
  );

  if (collectionDetailError) {
    // redirect to homepage in case of a collection detail err
    return <Redirect to="/" />;
  }

  return (
    <Layout className="PublicCollectionPage" title={title}>
      <Container>
        <section className="my-5 text-center">
          <h1 className="mb-1">{title}</h1>
          {collectionDetail ? (
            <CollectionSummary
              collectionDetail={collectionDetail}
              showDescription
            />
          ) : (
            <div style={{ minHeight: '4.8rem' }} />
          )}
        </section>
        <section className="mb-5">
          <h2 className="h5 section-title">
            {t('collection.activity_header')}
          </h2>
          <Panel className="p-0 overflow-hidden">
            <RecentActivity activeCollection={userNestedCollection} />
          </Panel>
        </section>
        <section className="mb-5">
          <h2 className="h5 section-title">{t('highlights.explore_header')}</h2>
          {userNestedCollection && (
            <div>
              <span className="section-subtitle">
                {t('highlights.subheader', { title })}.
              </span>
              <StackedHighlightCards
                collectionId={collectionId}
                linkToAllHighlights={false}
              />
            </div>
          )}
        </section>
        {conversations && conversations.length > 0 && (
          <section className="mb-5">
            <h2 className="h5 section-title">
              {t('conversations.explore_header')}
            </h2>
            <ExploreConversations
              conversations={conversations}
              isLoading={isLoading}
              error={error}
              pagingInfo={pagingInfo}
              pagingHandlers={pagingHandlers}
              pageSize={PAGE_SIZE}
            />
          </section>
        )}
      </Container>
    </Layout>
  );
};

export default connect(mapStateToProps)(PublicCollectionPage);
