import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Col, Collapse, Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import { PRIVACY_URL, TERMS_URL } from 'src/constants';
import authSelectors from 'src/redux/auth/auth-selectors';
import { EditTransaction, editUser } from 'src/redux/auth/auth-slice';
import { StoreState } from 'src/redux/store';
import { User } from 'src/types/auth';
import dateFormatter from 'src/util/date';
import { redirectUserToConversations } from 'src/util/urls';
import Layout from '../Layout/Layout';
import ChangePassword from './ChangePassword/ChangePassword';
import ProfileSettings from './ProfileSettings/ProfileSettings';
import UserPreferences from './UserPreferences/UserPreferences';

import styles from './SettingsPage.module.scss';

interface StateProps {
  user: User;
  chapterTransaction?: EditTransaction;
  languageTransaction?: EditTransaction;
  profileTransaction?: EditTransaction;
  profileImageTransaction?: EditTransaction;
  passwordTransaction?: EditTransaction;
}

type Props = RouteComponentProps & StateProps & DispatchProp;

/** Map state from redux to the components props */
const mapStateToProps = (state: StoreState): StateProps => ({
  user: authSelectors.getUser(state),
  chapterTransaction: authSelectors.getEditUserTransaction(state, 'chapter'),
  profileTransaction: authSelectors.getEditUserTransaction(state, 'profile'),
  profileImageTransaction: authSelectors.getEditUserTransaction(
    state,
    'profile_image'
  ),
  languageTransaction: authSelectors.getEditUserTransaction(state, 'language'),
  passwordTransaction: authSelectors.getEditUserTransaction(state, 'password'),
});

/**
 * User preferences page
 */
const SettingsPage = ({
  user,
  chapterTransaction,
  profileTransaction,
  profileImageTransaction,
  languageTransaction,
  passwordTransaction,
  dispatch,
}: Props) => {
  const { t } = useTranslation();
  const [isOpenChangePassword, setIsOpenChangePassword] = React.useState(false);

  const toggleOpenChangePassword = () => {
    setIsOpenChangePassword(!isOpenChangePassword);
  };

  const handleUpdateUser = (editTransaction: EditTransaction) => {
    // set the edit user id so that the state will update
    dispatch(editUser(editTransaction));
  };

  const passwordResetUrl = `/password_reset?next=${redirectUserToConversations(
    window.location.pathname
  )}`;
  return (
    <Layout className="SettingsPage" title={t('settings.header')}>
      <Container>
        <header className="mb-5">
          <h2 className="mb-1">{t('settings.header')}</h2>
          <p className="text-muted lead">
            {t('settings.subheader', {
              date: dateFormatter.dateFormat(user.date_joined, {
                includeYear: true,
              }),
            })}
          </p>
        </header>
        <Row className="mb-5">
          <Col md={8} lg={6}>
            <h3 className="mb-2">{t('settings.profile')}</h3>
            <ProfileSettings
              user={user}
              onUpdateUser={handleUpdateUser}
              profileTransaction={profileTransaction}
              profileImageTransaction={profileImageTransaction}
            />
          </Col>
        </Row>
        <Row className="mb-5">
          <Col md={8} lg={6}>
            <h3 className="mb-2" data-testid="settings-preference">
              {t('settings.pref')}
            </h3>
            <UserPreferences
              user={user}
              onUpdateUser={handleUpdateUser}
              chapterTransaction={chapterTransaction}
              languageTransaction={languageTransaction}
            />
          </Col>
        </Row>
        <Row className="mb-5">
          <Col md={8} lg={6}>
            {user.has_password ? (
              <>
                <button
                  onClick={toggleOpenChangePassword}
                  className={cx(styles.stylelessButton, 'mb-3')}
                  data-testid="password-collapse"
                  aria-expanded={isOpenChangePassword}
                >
                  <h3 className="mb-0">
                    <span className="me-2">
                      {t('settings.password_update')}
                    </span>
                    {isOpenChangePassword ? (
                      <FontAwesomeIcon
                        icon={['far', 'chevron-down']}
                        size="sm"
                      ></FontAwesomeIcon>
                    ) : (
                      <FontAwesomeIcon
                        icon={['far', 'chevron-right']}
                        size="sm"
                      />
                    )}
                  </h3>
                </button>
                <Collapse isOpen={isOpenChangePassword}>
                  <ChangePassword
                    user={user}
                    passwordResetUrl={passwordResetUrl}
                    passwordTransaction={passwordTransaction}
                    onUpdateUser={handleUpdateUser}
                  />
                </Collapse>
              </>
            ) : (
              <>
                <h3 id="create-password" className="mb-2">
                  {t('register.password_header')}
                </h3>
                <p className="text-gray-700">
                  <Trans
                    i18nKey="settings.password_create"
                    /*eslint-disable */
                    components={{
                      1: (
                        <a
                          href={passwordResetUrl}
                          rel="noopener noreferrer"
                          target="_blank"
                        />
                      ),
                    }}
                    /*eslint-disable */
                  />
                </p>
              </>
            )}
          </Col>
        </Row>
        <section className="border-top pt-4">
          <ul className="list-unstyled">
            <li>
              <a href={PRIVACY_URL} rel="noopener noreferrer" target="_blank">
                {t('settings.privacy')}
              </a>
            </li>
            <li>
              <a href={TERMS_URL} rel="noopener noreferrer" target="_blank">
                {t('settings.terms')}
              </a>
            </li>
          </ul>
        </section>
      </Container>
    </Layout>
  );
};

// use connect() to get redux to connect to the component
export default connect(mapStateToProps)(SettingsPage);
