import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import cx from 'classnames';

import Button from '../core/Button/Button';
import LoadingSpinner from '../core/LoadingSpinner/LoadingSpinner';

import styles from './ConfirmationModal.module.scss';

interface ConfirmationModalProps {
  isOpen: boolean;
  isLoading: boolean;
  text?: {
    question?: string;
    description?: string;
    confirm?: string;
    cancel?: string;
  };
  handleCloseModal: () => void;
  confirm: () => void;
  reject?: () => void;
}

// Use this component sparingly...
const ConfirmationModal = ({
  isOpen,
  isLoading,
  text,
  handleCloseModal,
  confirm,
  reject = handleCloseModal,
}: ConfirmationModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      className={cx('ModalContent', styles.modal)}
      overlayClassName="ModalOverlay"
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      contentLabel="Confirmation Modal"
      testId={'confirmation-modal'}
    >
      <header>
        <h1>{text?.question ?? t('common.confirmation_question')}</h1>
      </header>
      <br />
      <main>
        {text?.description && <p className="text-center">{text.description}</p>}
        <div className="d-flex justify-content-center">
          <Button
            onClick={confirm}
            className={cx(styles.danger, styles.button)}
            data-testid="confirmation-modal-confirm"
          >
            {isLoading ? (
              <LoadingSpinner active size="md" />
            ) : text?.confirm ? (
              text.confirm
            ) : (
              t('common.confirm')
            )}
          </Button>
          <Button
            onClick={reject}
            className={cx(styles.cancel, styles.button)}
            data-testid="confirmation-modal-cancel"
          >
            {text?.cancel ? text.cancel : t('common.cancel')}
          </Button>
        </div>
      </main>
    </Modal>
  );
};
export default ConfirmationModal;
