import * as React from 'react';
import { Popover } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import nanoid from 'nanoid';

import ConversationPrivacyLabel from 'src/components/ConversationPrivacyLabel/ConversationPrivacyLabel';
import Button from 'src/components/core/Button/Button';
import { Conversation, ConversationPrivacyLevel } from 'src/types/conversation';
import ConversationPrivacyMenu from './ConversationPrivacyMenu/ConversationPrivacyMenu';

import styles from './ConversationPrivacyDropdown.module.scss';

export interface Props {
  conversation: Conversation;
  onChange: (privacy: ConversationPrivacyLevel) => void;

  className?: string;
}

const ConversationPrivacyDropdown = ({
  className,
  onChange,
  conversation,
}: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = (privacyLevel: ConversationPrivacyLevel) => {
    onChange(privacyLevel);
    setIsOpen(false);
  };

  // make a unique ID for each button to target
  const buttonId = React.useMemo(
    () => 'conversationPrivacyButton-' + nanoid(),
    []
  );

  return (
    <div className={className} data-testid="conversation-privacy-dropdown">
      <Button
        className={cx(styles.dropdownButton, 'fwmedium ps-0', {
          [styles.open]: isOpen,
        })}
        onClick={handleToggle}
        id={buttonId}
        compact
      >
        <ConversationPrivacyLabel
          conversation={conversation}
          className="pe-1 text-gray-800"
          iconSize="xs"
        />
        {
          <FontAwesomeIcon
            icon="caret-down"
            className={styles.caretIcon}
            size="xs"
          />
        }
      </Button>

      <Popover
        placement="bottom-start"
        isOpen={isOpen}
        toggle={() => setIsOpen(false)} // set this to false or else the popover won't stay closed when handlePrivacyChange is called
        size="sm"
        target={buttonId}
        trigger="legacy" // allows clicking inside as well as going away when clicking outside
        hideArrow
        className="p-0"
      >
        <ConversationPrivacyMenu
          collectionTitle={conversation.collection.title}
          activePrivacyLevel={conversation.privacy_level}
          highlights={conversation.highlights}
          onChange={handleChange}
        />
      </Popover>
    </div>
  );
};

export default ConversationPrivacyDropdown;
