import * as React from 'react';
import * as ReactDOM from 'react-dom';

/**
 * Pass props into here to see what caused a re-render
 * @param props
 */
export function useTraceUpdate(props: any) {
  const prev = React.useRef(props);
  React.useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps: any, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log('Changed props:', changedProps);
    }
    prev.current = props;
  });
}

// Used like prevProps in classes
// https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
export function usePrevious(value: any, initialValue: any) {
  const ref = React.useRef(initialValue);
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

/* Renders a react component in a hidden element to measure the element before it is rendered in root of the app. 
Resource intensive, use with care. Make sure it's not run unnecessarily and use other options if possible.
Inspired by: https://medium.com/trabe/measuring-non-rendered-elements-in-react-with-portals-c5b7c51aec25
*/
export const measureElement: (
  element: React.ReactElement
) => Promise<{ height: number; width: number }> = async (
  element: React.ReactElement
) => {
  // creates the hidden div appended to the document body
  const container: HTMLElement = document.createElement('div');
  container.style.width = 'auto';
  container.style.position = 'absolute';
  container.style.whiteSpace = 'nowrap';
  document.body.appendChild(container);

  return await new Promise((res) => {
    // renders the React element into the hidden div
    ReactDOM.render(element, container, function () {
      const height = container.clientHeight;
      const width = container.clientWidth;

      // removes the element and its wrapper from the document
      ReactDOM.unmountComponentAtNode(container);
      container.parentNode?.removeChild(container);
      res({ height, width });
    });
  });
};

export default measureElement;
