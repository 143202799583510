import Html5Audio from './Html5Audio';

/** Approximation around seekTime to allow lead-in/lead-out for matching boundaries */
export const SEEK_EPSILON = 0.1;

/**
 * A description of when a subscriber should receive seek time updates
 */
export interface SeekSubscription {
  /** Callback to send the latest seekTime when conditions are met */
  callback: (seekTime: number | undefined) => void;
  /** If provided, only send updates when the src matches the active audio */
  src?: string | undefined;
  /** If provided, only send updates when the seekTime >= startTime */
  startTime?: number | undefined;
  /** If provided, only send updates when the seekTime <= endTime */
  endTime?: number | undefined;
  /** If provided, only send updates when the id matches the active audio */
  id?: string;
}

// Subscription type
type SubscriptionChangeFunction = (
  seekTime: number | undefined,
  activeSrc: string | undefined,
  activeId: string | undefined
) => void;

/**
 * Callback for when something changes with the audio state.
 */
export type ChangeCallback = (
  seekTime: number | undefined,
  activeSrc: string | undefined,
  activeId: string | undefined
) => void;

export class AudioManager {
  sound: Html5Audio;
  raf: any | null = null;
  unmounting = false;
  endPlayTime: number | undefined = undefined;
  /** any metadata to go along with the active audio file (e.g. a conversation obj) */
  meta: any = undefined;
  // a string id to uniquely identify what is playing (most of the time src is enough, but this
  // handles the case where it's the same audio file, but maybe from different highlights)
  id: string | undefined;
  /** Collection of callbacks and conditions for sending seekTime */
  seekSubscriptions: SeekSubscription[] = [];
  /** Collection of callbacks for audio state changes */
  changeSubscriptions: SubscriptionChangeFunction[] = [];
  /** Callback for after all changes have been published */
  onUpdate: () => void = () => {
    return;
  };

  constructor() {
    this.sound = new Html5Audio({
      onPlay: this.handleAudioPlay,
      onPause: this.handleAudioPause,
      onEnd: this.handleAudioEnd,
      onLoad: this.handleAudioLoaded,
      onError: this.handleAudioError,
    });
  }

  /** Reset the manager  */
  destroy() {
    this.endPlayTime = undefined;

    if (this.raf) {
      cancelAnimationFrame(this.raf);
    }
    // flag we are unmounting to prevent calling forceUpdate in handlers
    this.unmounting = true;

    if (this.sound) {
      this.sound.unload();
    }
    this.seekSubscriptions = [];
    this.changeSubscriptions = [];
  }

  /** Publish changes to those that are listening */
  changePublish() {
    for (const subscription of this.changeSubscriptions) {
      subscription(this.seekTime(), this.sound.getSrc(), this.id);
    }
    // also update seek times (it's possible they were modified by a change)
    // we force these through in case we seek to a place that doesn't meet
    // the criteria. We are forcing an update to the component anyway so
    // might as well give it an accurate seek time.
    this.seekPublish(true);
    this.onUpdate();
  }

  /** Add a new change listener */
  changeSubscribe = (changeSubscription: ChangeCallback) => {
    this.changeSubscriptions.push(changeSubscription);
  };

  /** Remove a change listener */
  changeUnsubscribe = (changeSubscription: ChangeCallback) => {
    this.changeSubscriptions = this.changeSubscriptions.filter(
      (d) => d !== changeSubscription
    );
  };

  /** Publish latest seek times if the conditions are met */
  seekPublish(force?: boolean) {
    const seekTime = this.seekTime();
    const src = this.sound.getSrc();
    for (const subscription of this.seekSubscriptions) {
      this.seekPublishSubscription(subscription, seekTime, src, force);
    }
  }

  seekPublishSubscription(
    subscription: SeekSubscription,
    seekTime: number | undefined,
    src: string | undefined,
    force?: boolean
  ) {
    // only callback if we care based on the subscription
    // use a little padding ensure we get smooth starts and ends
    if (
      force ||
      seekTime == null ||
      ((subscription.startTime == null ||
        subscription.startTime - SEEK_EPSILON <= seekTime) &&
        (subscription.endTime == null ||
          seekTime <= subscription.endTime + SEEK_EPSILON))
    ) {
      // if it is for the desired audio file, set it
      if (subscription.src == null || subscription.src === src) {
        subscription.callback(seekTime);
      } else if (force) {
        // otherwise updating a seek time for a different audio file, reset to undefined
        subscription.callback(undefined);
      }
    }
  }

  /** Add a new seek listener */
  seekSubscribe = (seekSubscription: SeekSubscription) => {
    this.seekSubscriptions.push(seekSubscription);
    // publish to it immediately upon subscription so it gets latest value right away
    this.seekPublishSubscription(
      seekSubscription,
      this.seekTime(),
      this.sound.getSrc(),
      false
    );
  };

  /** Remove a seek listener */
  seekUnsubscribe = (seekSubscription: SeekSubscription) => {
    this.seekSubscriptions = this.seekSubscriptions.filter(
      (d) => d !== seekSubscription
    );
  };

  /** We can use meta to pass in a conversation as context for other players to know about
   * Can also pass around 'id' to better be able to tell what is being played at a more granular level
   */
  handleChangeSound = (src: string | undefined, meta?: any, id?: string) => {
    if (this.meta !== meta) {
      this.meta = meta;
    }

    if (this.sound.getSrc() !== src) {
      this.endPlayTime = undefined;
      this.sound.setSrc(src);
    }

    // when remove a src, also clear out the id
    if (src == null) {
      this.id = undefined;
    }

    if (this.id !== id) {
      this.id = id;
      this.changePublish();
    }
  };

  getSrc() {
    return this.sound.getSrc();
  }

  getError() {
    return this.sound.getError();
  }

  isPlaying() {
    return this.sound.isPlaying();
  }

  play() {
    this.sound.play();
  }

  pause() {
    this.sound.pause();
  }

  playbackSpeed() {
    return !this.isLoading() ? this.sound.getPlaybackRate() : 1;
  }

  duration() {
    return !this.isLoading() ? this.sound.getDuration() : undefined;
  }

  seekTime() {
    return !this.isLoading()
      ? (this.sound.getCurrentTime() as number)
      : undefined;
  }

  seek(seekTime: number, play?: boolean) {
    if (play && !this.sound.isPlaying()) {
      this.sound.play();
    }
    this.sound.seek(seekTime);
  }

  changePlaybackSpeed(playbackSpeed: number) {
    this.sound.setPlaybackRate(playbackSpeed);
  }

  isLoading() {
    return this.sound.isLoading();
  }

  handleAudioLoaded = () => {
    this.changePublish();
    this.onUpdate();
  };

  handleAudioPlay = () => {
    this.changePublish();
    this.playingTick();
  };

  handleAudioPause = () => {
    this.changePublish();
  };

  handleAudioError = (instance: Html5Audio, audio: HTMLAudioElement) => {
    console.error('Audio error for ' + audio.src + '\n', audio.error, audio);
    this.changePublish();
  };

  handleAudioEnd = () => {
    if (this.raf) {
      cancelAnimationFrame(this.raf);
      this.raf = null;
    }
    this.changePublish();
  };

  handleControlsPlay = (seekTime?: number, endTime?: number) => {
    if (!this.isPlaying()) {
      this.play();
    }
    if (seekTime != null) {
      this.seek(seekTime);
    }

    // store time to auto end play
    this.endPlayTime = endTime;
  };

  handleControlsPause = () => {
    if (this.isPlaying()) {
      this.pause();
    }

    this.endPlayTime = undefined;
  };

  handleControlsTogglePlaying = () => {
    if (this.isPlaying()) {
      this.pause();
    } else {
      this.play();
    }
  };

  handleControlsStop = () => {
    this.handleControlsPause();
    this.handleChangeSound(undefined);
    this.changePublish();
  };

  handleControlsSeek = (seekTime: number, play?: boolean) => {
    this.seek(seekTime, play);
    this.changePublish();

    // if we seek past the end play time, then reset the end play time.
    if (this.endPlayTime && seekTime > this.endPlayTime) {
      this.endPlayTime = undefined;
    }
  };

  /**
   * Performs a relative seek (e.g. back 5 seconds) without requiring
   * the caller to know the current seek time.
   */
  handleControlsRelativeSeek = (deltaSeekTime: number, play?: boolean) => {
    const seekTime = this.seekTime();
    if (seekTime != null) {
      this.handleControlsSeek(Math.max(0, seekTime + deltaSeekTime), play);
    }
  };

  /**
   * Performs a seek only if the seekTime is not within the range specified
   */
  handleControlsConditionalSeek = (
    minTime: number,
    maxTime?: number | undefined,
    targetTime: number = minTime
  ) => {
    const seekTime = this.seekTime();

    if (
      seekTime == null ||
      (seekTime != null &&
        (seekTime < minTime || (maxTime != null && seekTime > maxTime)))
    ) {
      this.handleControlsSeek(targetTime);
    }
  };

  handleControlsChangePlaybackSpeed = (playbackSpeed: number) => {
    this.changePlaybackSpeed(playbackSpeed);
    this.changePublish();
  };

  /**
   * Called continuously while playing to update the seekTime value
   */
  playingTick = () => {
    // let subscribers know a new seek time is available
    this.seekPublish();

    if (this.isPlaying()) {
      // pause if we've reached the desired end time
      if (this.endPlayTime) {
        const seekTime = this.seekTime();
        if (seekTime != null && seekTime >= this.endPlayTime) {
          this.handleControlsPause();
        }
      }

      // keep repeating as long as we are playing
      this.raf = requestAnimationFrame(this.playingTick);
    }
  };
}

export default AudioManager;
