import * as React from 'react';
import { Translation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import { FormGroup, FormText, Input, Label } from 'reactstrap';

import Button from 'src/components/core/Button/Button';
import SaveButton from 'src/components/SaveButton/SaveButton';
import conversationSelectors from 'src/redux/conversation/conversation-selectors';
import { editConversation } from 'src/redux/conversation/conversation-slice';
import { StoreState } from 'src/redux/store';
import { Conversation } from 'src/types/conversation';

interface BaseProps {
  conversation: Conversation;
  onCancel: () => void;
  onSaved: (conversation: Conversation) => void;
}

interface StateProps {
  isSaving: boolean;
  isSaved: boolean;
  error: Error | undefined;
}

type Props = BaseProps & StateProps & DispatchProp;

/** Map state from redux to the components props */
const mapStateToProps = (state: StoreState): StateProps => ({
  isSaving: conversationSelectors.isSavingEditConversation(state),
  isSaved: conversationSelectors.isSavedEditConversation(state),
  error: conversationSelectors.getErrorEditConversation(state),
});

interface State {
  draftTitle: string;
  draftLocation: string;
  ignoreError: boolean;
}

export class ConversationEdit extends React.Component<Props, State> {
  state: State = {
    draftTitle: this.props.conversation.title,
    draftLocation: this.props.conversation.location.name,
    ignoreError: false,
  };

  componentDidUpdate(prevProps: Props) {
    const { isSaved, conversation, onSaved, error } = this.props;
    const { ignoreError } = this.state;
    // signal that it has saved successfully
    if (isSaved && prevProps.isSaved !== isSaved) {
      onSaved(conversation);
    }

    // restore errors
    if (prevProps.error !== error && ignoreError) {
      this.setState({
        ignoreError: false,
      });
    }
  }

  handleChangeInput = (evt: React.SyntheticEvent<HTMLInputElement>) => {
    const { name, value } = evt.currentTarget;
    this.setState({
      [name]: value,
      ignoreError: true, // hide errors on change
    } as any);
  };

  handleSaveEdit = (evt: React.SyntheticEvent) => {
    const { dispatch, conversation } = this.props;
    const { draftTitle, draftLocation } = this.state;

    evt.preventDefault(); // stop form submission

    const changes: Partial<Conversation> = {};
    if (conversation.title !== draftTitle) {
      changes.title = draftTitle;
    }
    if (conversation.location.name !== draftLocation) {
      changes.location = {
        ...conversation.location,
        name: draftLocation,
      };
    }

    dispatch(editConversation({ conversationId: conversation.id, changes }));
  };

  render() {
    const { onCancel, isSaving, error } = this.props;
    const { draftTitle, draftLocation, ignoreError } = this.state;

    return (
      <Translation>
        {(t) => (
          <div>
            <form onSubmit={this.handleSaveEdit}>
              <FormGroup>
                <Label for="conversationTitle">{t('admin.draft_title')}</Label>
                <Input
                  type="text"
                  name="draftTitle"
                  id="conversationTitle"
                  value={draftTitle}
                  onChange={this.handleChangeInput}
                />
              </FormGroup>
              <FormGroup>
                <Label for="conversationLocation">
                  {t('common.neighborhood')}
                </Label>
                <Input
                  type="text"
                  name="draftLocation"
                  id="conversationLocation"
                  value={draftLocation}
                  onChange={this.handleChangeInput}
                />
                <FormText>{t('conversation.edit_location')}</FormText>
              </FormGroup>
              <div className="mt-4">
                <SaveButton
                  onClick={this.handleSaveEdit}
                  isSaving={isSaving}
                  error={ignoreError ? undefined : error}
                  className="me-2"
                  type="submit"
                />
                <Button disabled={isSaving} type="button" onClick={onCancel}>
                  {t('common.cancel')}
                </Button>
              </div>
            </form>
          </div>
        )}
      </Translation>
    );
  }
}

export default connect(mapStateToProps)(ConversationEdit);
