import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import { selectors as authSelectors } from 'src/redux/auth/auth-selectors';
import { createCatalog } from 'src/redux/insights/insights-slice';
import { StoreState } from 'src/redux/store';
import { Organization } from 'src/types/auth';

interface BaseProps {
  open: boolean;
  closeDialog: () => void;
}

interface StateProps {
  organizations: Organization[];
}

type Props = BaseProps & StateProps & DispatchProp;

const mapStateToProps = (state: StoreState): StateProps => ({
  organizations: authSelectors.getOrganizations(state),
});

const CreateCatalogDialog: React.FunctionComponent<Props> = (props: Props) => {
  const { dispatch, open, organizations, closeDialog } = props;
  const { t } = useTranslation();
  const [description, setDescription] = React.useState('');
  const [organizationId, setOrganizationId] = React.useState('');
  const [organizationIdError, setOrganizationIdError] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const [titleError, setTitleError] = React.useState(false);
  const history = useHistory();

  React.useEffect(() => {
    // Reset on open
    setTitle('');
    setTitleError(false);
    setDescription('');
    setOrganizationId('');
    setOrganizationIdError(false);
  }, [open]);

  const handleSubmit = () => {
    let errored = false;
    if (title.length === 0) {
      setTitleError(true);
      errored = true;
    }
    if (organizationId.length === 0) {
      setOrganizationIdError(true);
      errored = true;
    }
    if (errored) {
      return;
    }
    closeDialog();
    dispatch(
      createCatalog({
        data: {
          description: description,
          organization_id: Number(organizationId),
          title: title,
        },
        callback: (newCatalogId) =>
          history.push(`insights/catalog/${newCatalogId}`),
      })
    );
  };

  return (
    <Dialog disableRestoreFocus onClose={closeDialog} open={open}>
      <DialogTitle>{t('insights.create_new_catalog')}</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <TextField
            autoFocus
            error={titleError}
            fullWidth
            helperText={titleError ? t('insights.catalog_title_error') : ''}
            id="title"
            label={t('insights.catalog_title')}
            margin="dense"
            onChange={(e) => {
              if (e.target.value != '') setTitleError(false);
              setTitle(e.target.value);
            }}
            type="text"
            variant="standard"
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            fullWidth
            id="description"
            label="Catalog description (optional)"
            margin="dense"
            multiline
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            type="text"
            variant="standard"
          />
        </FormControl>
        <FormControl fullWidth sx={{ mt: 1 }} variant="standard">
          <InputLabel id="new-catalog-organization-select-label">
            {t('insights.organization')}
          </InputLabel>
          <Select
            error={organizationIdError}
            data-testid="new-catalog-organization-select"
            id="new-catalog-organization-select"
            labelId="new-catalog-organization-select-label"
            onChange={(e) => {
              if (e.target.value != '') setOrganizationIdError(false);
              setOrganizationId(e.target.value);
            }}
            value={organizationId}
          >
            {organizations.map((organization) => (
              <MenuItem key={organization.id} value={organization.id}>
                {organization.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error={organizationIdError}>
            {organizationIdError ? t('insights.organization_title_error') : ''}
          </FormHelperText>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} sx={{ color: '#000' }}>
          {t('common.cancel')}
        </Button>
        <Button onClick={handleSubmit} sx={{ color: '#000' }}>
          {t('common.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect(mapStateToProps)(CreateCatalogDialog);
