import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Input } from 'reactstrap';
import cx from 'classnames';
import { debounce } from 'lodash';

import CheckboxWrapper from 'src/components/CheckboxWrapper/CheckboxWrapper';
import Button from 'src/components/core/Button/Button';
import { SearchParams } from 'src/types/transcript';
import { MIN_SEARCH_WORD_LENGTH } from 'src/util/slate';

import styles from './FindAndReplace.module.scss';

// The time in seconds to delay the search
const SEARCH_INTERVAL = 0.25;

interface Props {
  onClose: () => void;
  searchParams: SearchParams;
  setSearchParams: (s: SearchParams) => void;
  onNextSearch: () => void;
  onPreviousSearch: () => void;
  onReplace: (text: string, all: boolean) => void;
  searchIndex: number;
  totalSearchResults: number;
  className?: string;
}
const FindAndReplace = ({
  searchParams,
  setSearchParams,
  onClose,
  onNextSearch,
  onPreviousSearch,
  onReplace,
  searchIndex,
  totalSearchResults,
  className,
}: Props) => {
  const [draftReplace, setDraftReplace] = React.useState('');

  const search = React.useMemo(
    () =>
      debounce((value: string) => {
        setSearchParams({
          q: value,
          caseSensitive: searchParams.caseSensitive,
          isSearching: true,
        });
      }, SEARCH_INTERVAL * 1000),
    [searchParams.caseSensitive, setSearchParams]
  );

  const handleChangeSearch = (evt: React.SyntheticEvent<HTMLInputElement>) => {
    const { value } = evt.currentTarget;
    search(value);
  };
  const toggleCaseSensitive = () => {
    setSearchParams({
      q: searchParams.q,
      caseSensitive: !searchParams.caseSensitive,
      isSearching: true,
    });
  };
  const handleChangeReplace = (evt: React.SyntheticEvent<HTMLInputElement>) => {
    setDraftReplace(evt.currentTarget.value);
  };
  const handleReplaceOne = () => {
    onReplace(draftReplace, false);
  };
  const handleReplaceAll = () => {
    onReplace(draftReplace, true);
  };
  const { t } = useTranslation();

  return (
    <div
      className={cx('d-flex justify-content-between', className)}
      data-testid="find-and-replace"
      data-ignore-click={true}
    >
      <div className="flex-grow-1 me-3">
        <div className="d-flex mb-2">
          <div className="col-6 me-2 p-0">
            <Input
              placeholder={t('transcript_editor.search_placeholder')}
              defaultValue={searchParams.q}
              onChange={handleChangeSearch}
              data-testid="search-input"
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  if (event.shiftKey) {
                    // Shift + Enter
                    onPreviousSearch();
                  } else {
                    // Just Enter
                    onNextSearch();
                  }
                }
              }}
            />
            {searchParams.q.length > MIN_SEARCH_WORD_LENGTH && (
              <span className={styles.results} data-testid="search-results">
                <Trans
                  i18nKey={'conversation.stepper'}
                  values={{
                    count: totalSearchResults ? searchIndex + 1 : 0,
                    total: totalSearchResults,
                  }}
                />
              </span>
            )}
          </div>
          <Button
            className="me-2 col-3"
            onClick={onPreviousSearch}
            data-testid="previous-btn"
          >
            {t('common.previous')}
          </Button>
          <Button
            className="col-3"
            onClick={onNextSearch}
            data-testid="next-btn"
          >
            {t('common.next')}
          </Button>
        </div>
        <div className="d-flex mb-2">
          <Input
            placeholder={t('transcript_editor.replace_placeholder')}
            className="me-2 col-6 w-50"
            onChange={handleChangeReplace}
            value={draftReplace}
            style={{ height: 'fit-content' }}
            data-testid="replace-input"
          />
          <Button
            className="me-2 col-3"
            onClick={handleReplaceOne}
            data-testid="replace-btn"
          >
            {t('transcript_editor.replace')}
          </Button>
          <Button
            className="col-3"
            onClick={handleReplaceAll}
            data-testid="replace-all-btn"
          >
            {t('transcript_editor.replace_all')}
          </Button>
        </div>
        <div>
          <CheckboxWrapper
            inputName="caseSensitive"
            checked={searchParams.caseSensitive}
            labelText={t('transcript_editor.case')}
            spacing={'me-2'}
          >
            <input
              type="checkbox"
              name="caseSensitive"
              id="caseSensitive"
              checked={searchParams.caseSensitive}
              data-testid="case-sensitive-checkbox"
              onChange={toggleCaseSensitive}
            />
          </CheckboxWrapper>
        </div>
      </div>
      {/* negative to get the x to float above the rest */}
      <div className="mt-n3">
        <Button
          onClick={onClose}
          icon={['far', 'times']}
          color="plain"
          data-testid="close-btn"
        />
      </div>
    </div>
  );
};

export default FindAndReplace;
