import * as React from 'react';

import HighlightCard from 'src/components/HighlightCard/HighlightCard';
import {
  Conversation,
  Highlight as HighlightType,
} from 'src/types/conversation';
import { highlightInSnippet } from 'src/util/snippets';

interface Props {
  conversation: Conversation;
  highlight: HighlightType;
  onViewInTranscript: (
    targetSeekTimeMin: number,
    targetSeekTimeMax?: number
  ) => void;
}

const ConversationHighlight: React.FunctionComponent<Props> = ({
  onViewInTranscript,
  highlight,
  conversation,
}) => {
  const handleViewInTranscript = () => {
    onViewInTranscript(
      highlight.audio_start_offset,
      highlight.audio_end_offset
    );
  };

  let { snippets = [] } = conversation;
  snippets = snippets.filter((snippet) =>
    highlightInSnippet(snippet, highlight)
  );

  return (
    <HighlightCard
      conversation={conversation}
      snippets={snippets}
      highlight={highlight}
      inConversation
      onViewInTranscript={handleViewInTranscript}
      className="mb-4"
    />
  );
};

export default ConversationHighlight;
