import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import Button from 'src/components/core/Button/Button';
import LoadingSpinner from 'src/components/core/LoadingSpinner/LoadingSpinner';

import styles from './PublishButton.module.scss';

interface Props {
  isDraft: boolean;
  isSaving: boolean;
  error: Error | undefined;
  isSaved: boolean;
  // if not toggle, then will just show a success message
  // otherwise if toggle is true, then will show a button to make draft
  toggle?: boolean;
  disabled?: boolean;
  onClick: (isDraft: boolean) => void;
}
const PublishButton = ({
  isDraft,
  isSaving,
  error,
  isSaved,
  toggle,
  disabled,
  onClick,
}: Props) => {
  const { t } = useTranslation();
  if (isSaved && !isDraft && !toggle) {
    return (
      <span>
        <FontAwesomeIcon icon={['fas', 'check']} /> {t('admin.draft_done')}
      </span>
    );
  }

  if (isSaving) {
    const text = isDraft
      ? t('admin.draft_publishing')
      : t('admin.draft_making');
    return (
      <Button whiteBg disabled>
        <span className="me-2">{text}</span>{' '}
        <LoadingSpinner active={isSaving} />
      </Button>
    );
  }

  if (error) {
    return (
      <div className={cx('d-flex align-items-center', styles.errorMessage)}>
        <FontAwesomeIcon
          icon={['fas', 'exclamation-circle']}
          className="me-2"
        />
        <div>{t('admin.draft_error_2')}</div>
      </div>
    );
  }

  const buttonText = isDraft ? t('admin.draft_publish') : t('admin.draft_make');

  const handleClick = () => {
    onClick(!isDraft);
  };

  return (
    <Button
      whiteBg
      disabled={disabled}
      onClick={handleClick}
      data-testid="publish-button"
    >
      {buttonText}
    </Button>
  );
};

export default PublishButton;
