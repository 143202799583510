import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Button from 'src/components/core/Button/Button';
import FormStep, { FormContent } from 'src/components/FormStep/FormStep';

const Under13Step = () => {
  const { t } = useTranslation();
  return (
    <FormStep>
      <FormContent>
        <h1 data-testid="under13">{t('register.13_header')}</h1>
        <p>
          <Trans
            i18nKey="register.13_subtext"
            /*eslint-disable */
            components={{
              1: (
                <a
                  href="https://lvn.org/about#eligibility-and-access"
                  rel="noopener noreferrer"
                  target="_blank"
                />
              ),
            }}
            /*eslint-disable */
          />
        </p>
      </FormContent>
      <Button
        tag="a"
        href="https://lvn.org"
        rel="noopener noreferrer"
        color="link"
        target="_blank"
        data-testid="lvn-org-button"
      >
        {t('register.13_button')}
      </Button>
    </FormStep>
  );
};

export default Under13Step;
