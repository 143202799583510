import * as React from 'react';
import { Popover } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import nanoid from 'nanoid';

import conversationPrivacyDropdownStyles from 'src/components/ConversationPrivacyDropdown/ConversationPrivacyDropdown.module.scss';
import ConversationPrivacyMenu from 'src/components/ConversationPrivacyDropdown/ConversationPrivacyMenu/ConversationPrivacyMenu';
import Button from 'src/components/core/Button/Button';
import { useConversationPrivacyContent } from 'src/constants';
import { ConversationPrivacyLevel } from 'src/types/conversation';

import styles from './CollectionPrivacyDropdown.module.scss';

interface Props {
  value: ConversationPrivacyLevel | undefined;
  collectionTitle: string;
  onChange: (privacy: ConversationPrivacyLevel) => void;
  className?: string;
}

const CollectionPrivacyDropdown = ({
  value,
  collectionTitle,
  onChange,
  className,
}: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = (privacyLevel: ConversationPrivacyLevel) => {
    onChange(privacyLevel);
    setIsOpen(false);
  };

  let defaultIcon = undefined;
  let defaultTitle = 'Choose privacy settings';
  const privacyConstants = useConversationPrivacyContent();

  if (value) {
    const { icon, title } = privacyConstants[value];
    defaultIcon = (
      <FontAwesomeIcon icon={icon} className={`text-${value} me-1`} />
    );
    defaultTitle = title();
  }

  // make a unique ID for each button to target
  const buttonId = React.useMemo(
    () => 'collectionPrivacyButton-' + nanoid(),
    []
  );

  return (
    <div className={className} data-testid="conversation-privacy-dropdown">
      <Button
        className={cx(
          'form-control d-flex justify-content-between',
          styles.dropdownToggle,
          {
            [conversationPrivacyDropdownStyles.open]: isOpen,
          }
        )}
        onClick={handleToggle}
        id={buttonId}
        data-testid="privacy-settings-toggle"
        name="privacySettings"
        compact
      >
        <span>
          {defaultIcon} {defaultTitle}
        </span>
        <FontAwesomeIcon
          icon="caret-down"
          className={conversationPrivacyDropdownStyles.caretIcon}
          size="xs"
        />
      </Button>

      <Popover
        placement="bottom-start"
        isOpen={isOpen}
        toggle={() => setIsOpen(false)} // set this to false or else the popover won't stay closed when handlePrivacyChange is called
        size="sm"
        target={buttonId}
        trigger="legacy" // allows clicking inside as well as going away when clicking outside
        hideArrow
        className="p-0"
      >
        <ConversationPrivacyMenu
          activePrivacyLevel={value}
          collectionTitle={collectionTitle}
          onChange={handleChange}
          isCollectionMenu
        />
      </Popover>
    </div>
  );
};

export default CollectionPrivacyDropdown;
