import { Bin, histogram as d3Histogram, ScaleLinear } from 'd3';
import memoize from 'memoizee';

import { BaseTermTimings } from 'src/types/conversation';

export const minBinPixelWidth = 140; // need room for bigrams

export const binTerms = memoize(
  (
    topTerms: BaseTermTimings[],
    timeScale: ScaleLinear<number, number>,
    width: number,
    injectTerm?: BaseTermTimings
  ): Bin<BaseTermTimings, number>[] => {
    const numBins = Math.floor(width / minBinPixelWidth);
    const histogram = d3Histogram<BaseTermTimings, number>()
      // .value((d: TermTimings) => mean(d.timings) as number)
      .value(
        // take the median value
        (d: BaseTermTimings) =>
          d.timings[Math.floor((d.timings.length - 1) / 2)]
      )
      .domain(timeScale.domain() as [number, number])
      .thresholds(timeScale.ticks(numBins));

    const sortedTerms = topTerms.slice().sort((a, b) => b.count - a.count);
    const termsToUse = sortedTerms;

    if (injectTerm) {
      const termRegex = new RegExp(`^${injectTerm.term}$`, 'i');

      const existingIndex = sortedTerms.findIndex((d) =>
        termRegex.test(d.term)
      );
      if (existingIndex !== -1) {
        termsToUse[existingIndex] = injectTerm;
      } else {
        termsToUse.unshift(injectTerm);
      }
    }

    const bins = histogram(termsToUse);

    return bins;
  }
);
