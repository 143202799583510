import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { animated, useSpring } from 'react-spring';
import { useMeasure } from 'react-use';
import cx from 'classnames';

import Button from 'src/components/core/Button/Button';
import HorizontalBarChart, {
  DataPoint,
  TOP_PADDING as CHART_TOP_PADDING,
} from 'src/components/HorizontalBarChart/HorizontalBarChart';
import { Topics } from 'src/types/conversation';
import { ColorScale } from 'src/types/core';

import styles from './TopicsAndKeywordsChart.module.scss';

const COLLAPSED_THRESHOLD = 10;
const SHORT_BAR_VIEWPORT_WIDTH = 500; // in px, smaller than our usual mobile breakpoint because the chart fits

// in px
const TOTAL_BAR_HEIGHT = 40;

interface Props {
  topicColorScale: ColorScale;
  topics: Topics;
  activeTopicId: number | undefined;
  setTopic?: (id?: number) => void;
  className?: string;
  getBarLinkTo?: (id?: number) => string;
}

function formatTopicsData(topics: Topics) {
  return (
    Object.values(topics)
      .map((t) => ({
        label: t.display_name,
        value: t.num_conversations,
        id: t.id,
      }))
      // topics can be 0 because of convo thresholding LVN-1998
      .filter((t) => t.value > 0)
      .sort((a, b) => b.value - a.value)
  );
}

function formatKeywordsData(topics: Topics, activeTopicId: number | undefined) {
  if (activeTopicId == null) {
    return [];
  }
  return topics[activeTopicId].keywords
    .map((kw) => ({
      label: kw.term,
      value: kw.num_instances_matched,
      id: activeTopicId,
    }))
    .sort((a, b) => b.value - a.value);
}

const TopicsAndKeywordsChart = ({
  topicColorScale,
  topics,
  activeTopicId,
  setTopic,
  getBarLinkTo,
  className,
}: Props) => {
  const { t } = useTranslation();
  const [showFullChart, setShowFullChart] = React.useState(false);
  const toggleSeeMore = () => setShowFullChart(!showFullChart);

  const [ref, dimensions] = useMeasure();

  // when active topic changes, go back to the 'Show More' state
  React.useEffect(() => {
    setShowFullChart(false);
  }, [activeTopicId]);

  // memoize the formatting of the data
  const topicsData = React.useMemo(() => formatTopicsData(topics), [topics]);
  const keywordsData = React.useMemo(
    () => formatKeywordsData(topics, activeTopicId),
    [topics, activeTopicId]
  );

  // define props to Topics bar chart
  let title = t('topics.topics_chart_header');
  let subtitle = t('topics.topics_chart_subheader');
  let onClick = setTopic;
  let data: DataPoint[] = topicsData;
  let activeTopic;

  // define props to Keywords bar chart
  if (activeTopicId != null) {
    activeTopic = topics[activeTopicId];
    const totalMentions = activeTopic.keywords.reduce(
      (acc, cur) => acc + cur.num_instances_matched,
      0
    );
    title = t('topics.keyword_chart');
    subtitle = t('topics.keyword_chart_helper', { number: totalMentions });
    onClick = () => {
      return;
    };
    data = keywordsData;
  }

  // chart component renders all data, but this component visually hides bars that are above the given threshold when collapsed for easier transitions
  // chart component applies aria-hidden to bars visually hidden by this component
  const allDataChartHeight = TOTAL_BAR_HEIGHT * data.length + CHART_TOP_PADDING;
  const collapsedChartMaxHeight =
    TOTAL_BAR_HEIGHT * COLLAPSED_THRESHOLD + CHART_TOP_PADDING;

  const animatedProps = useSpring({
    maxHeight: showFullChart ? allDataChartHeight : collapsedChartMaxHeight,
  });
  const seeMoreBtnText = showFullChart
    ? t('common.button_less')
    : t('common.button_more', { number: data.length - COLLAPSED_THRESHOLD });

  function handleBack() {
    if (setTopic) {
      setTopic();
    }
  }

  const smallViewport = dimensions.width <= SHORT_BAR_VIEWPORT_WIDTH;

  const getBarClassName = (id: number) => {
    const topic = topics[id];
    if (activeTopicId) {
      return styles[`keyword${topicColorScale(topic.display_name)}`];
    }
    return styles[`topic${topicColorScale(topic.display_name)}`];
  };

  return (
    <div
      className={cx('text-center', className)}
      data-testid="topics-and-keywords-chart"
    >
      {activeTopicId && (
        <div
          className="position-relative"
          style={{
            height: smallViewport ? 55 : 0, // place above chart in sm viewports
          }}
        >
          <Button
            icon={['fas', 'chevron-left']}
            className={cx(styles.backBtn, 'insights-btn p-2')}
            onClick={handleBack}
          >
            {t('topics.keyword_chart_button_back')}
          </Button>
        </div>
      )}
      <animated.div
        style={{ ...animatedProps, overflow: 'hidden' }}
        className="position-relative"
      >
        <div style={{ height: allDataChartHeight }}>
          <HorizontalBarChart
            data={data}
            totalBarHeight={TOTAL_BAR_HEIGHT}
            wrapperRef={ref}
            title={title}
            subtitle={subtitle}
            className={styles.barChartWrapper}
            width={Math.min(800, dimensions.width)}
            onClick={onClick}
            visibleBarCount={COLLAPSED_THRESHOLD}
            smallViewport={smallViewport}
            getBarClassName={getBarClassName}
            getBarLinkTo={getBarLinkTo}
          />
        </div>
      </animated.div>
      {/* only render the See More button if there's actually more to see */}
      {data.length > COLLAPSED_THRESHOLD && (
        <div className="position-relative">
          <Button
            onClick={toggleSeeMore}
            className={cx('insights-btn p-2 mt-2', styles.moreBtn)}
          >
            {seeMoreBtnText}
          </Button>
        </div>
      )}
    </div>
  );
};

export default TopicsAndKeywordsChart;
