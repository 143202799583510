import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Alert,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import CheckboxWrapper from 'src/components/CheckboxWrapper/CheckboxWrapper';
import Button from 'src/components/core/Button/Button';
import { SpeakerChangeRequest, SpeakerOption } from 'src/types/transcript';
import { formatTime } from 'src/util/format';

import styles from './EditableSpeakerLabel.module.scss';

const UNKNOWN_SPEAKER_ID = 'unknown_speaker_id';

interface Props {
  speakerName?: string;
  speakerId?: string;
  time: number;
  paragraphID: number;
  onChange: (speakerData: SpeakerChangeRequest) => void;
  speakerOptions: SpeakerOption[];
  onPlay: (seekTime: number) => void;
}
const EditableSpeakerLabel = ({
  speakerName = 'Unknown',
  speakerId = UNKNOWN_SPEAKER_ID,
  time,
  paragraphID,
  onChange,
  speakerOptions,
  onPlay,
}: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const [applyToAllSpeakers, setApplyToAllSpeakers] = React.useState(false);
  const [speakerInput, setSpeakerInput] = React.useState('');
  const [duplicateWarning, setDuplicateWarning] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
    setDuplicateWarning(isOpen);
  };
  const handleSave = (options: Partial<SpeakerOption>) => {
    onChange({
      all: applyToAllSpeakers,
      current_speaker_id: speakerId,
      speaker_name: options.speaker_name,
      speaker_id: options.speaker_id,
    });
    options.speaker_name && setSpeakerInput('');
    handleClose();
  };
  const handleClose = () => {
    setDuplicateWarning(false);
    setIsOpen(false);
  };
  const handlePlay = () => {
    onPlay(time);
    setDuplicateWarning(false);
  };
  const toggleWarning = () => {
    setDuplicateWarning(!duplicateWarning);
  };
  const onSubmit = (
    event: React.FormEvent<HTMLFormElement>,
    options: Partial<SpeakerOption>
  ) => {
    event.preventDefault();
    // Disallow empty speaker name
    if (options.speaker_name === '') {
      setErrorMessage('empty_name');
      setDuplicateWarning(true);
      return;
    }
    // Disallow duplicate speaker name
    const duplicateName = speakerOptions.find((speaker: SpeakerOption) => {
      return speaker.speaker_name === options.speaker_name;
    });
    if (duplicateName?.speaker_name && !options.speaker_id) {
      setErrorMessage('duplicate_name');
      setDuplicateWarning(true);
    } else {
      handleSave({
        speaker_name: speakerInput,
        speaker_id: applyToAllSpeakers ? speakerId : undefined,
      });
    }
  };
  return (
    <div
      contentEditable={false}
      className="d-flex align-items-center justify-content-center"
    >
      <Dropdown isOpen={isOpen} toggle={handleToggleOpen} direction="down">
        <DropdownToggle
          className="fwbold p-0 me-2 text-lg"
          id={`editableSpeakerLabel-${paragraphID}`}
          color="plain"
          data-testid={`editableSpeakerLabel-${paragraphID}`}
        >
          {speakerName}
        </DropdownToggle>
        <DropdownMenu
          className={styles.popover}
          data-testid={`editableSpeakerPopover-${paragraphID}`}
        >
          <DropdownItem
            className={cx('mb-2', styles.background)}
            toggle={false}
            onKeyDown={(e) => {
              if (e.key === 'ArrowRight') {
                document
                  .getElementById(`editableSpeakerCheckbox-${paragraphID}`)
                  ?.focus();
                e.preventDefault();
              }
            }}
          >
            <CheckboxWrapper
              inputName={`editableSpeakerCheckbox-${paragraphID}`}
              checked={applyToAllSpeakers}
              labelText={
                <Trans
                  i18nKey="transcript_editor.speaker_change"
                  values={{
                    speaker_name: speakerName,
                  }}
                  /*eslint-disable */
                  components={{
                    1: <b />,
                  }}
                  /*eslint-disable */
                />
              }
              spacing={'me-2'}
            >
              <input
                type="checkbox"
                name={`editableSpeakerCheckbox-${paragraphID}`}
                id={`editableSpeakerCheckbox-${paragraphID}`}
                checked={applyToAllSpeakers}
                data-testid="all-speakers-checkbox"
                onChange={() => setApplyToAllSpeakers(!applyToAllSpeakers)}
              />
            </CheckboxWrapper>
          </DropdownItem>
          {speakerOptions.map((speakerOption) => {
            return (
              <DropdownItem
                key={speakerOption.speaker_id}
                className={styles.button}
                onClick={() => {
                  handleSave({
                    speaker_name: speakerOption.speaker_name,
                    speaker_id: speakerOption.speaker_id,
                  });
                  handleClose();
                }}
                data-testid={`speaker-option-${speakerOption.speaker_id}`}
              >
                {speakerOption.speaker_id === speakerId && (
                  <FontAwesomeIcon
                    icon={['fas', 'check']}
                    className={styles.icon}
                    data-testid="check-icon"
                  />
                )}
                {speakerOption.speaker_name}
              </DropdownItem>
            );
          })}
          <DropdownItem
            toggle={false}
            onKeyDown={(e) => {
              if (e.key === 'ArrowRight') {
                document
                  .getElementById(`editableSpeakerInput-${paragraphID}`)
                  ?.focus();
                e.preventDefault();
              }
            }}
            className={cx('mb-2', styles.background)}
          >
            <form
              className="input-group d-flex flex-column mt-2"
              onSubmit={(event) => {
                onSubmit(event, {
                  speaker_name: speakerInput,
                });
              }}
            >
              <Label className={styles.smallFont}>
                {t('transcript_editor.create_speaker')}
              </Label>
              <Input
                value={speakerInput}
                className={cx(`form-control w-100`)}
                onChange={(event) => setSpeakerInput(event.currentTarget.value)}
                aria-label={'Speaker Name'}
                data-testid="speaker-name-input"
                id={`editableSpeakerInput-${paragraphID}`}
              />
              <Alert
                className={styles.warning}
                color="warning"
                fade={false}
                isOpen={duplicateWarning}
                toggle={toggleWarning}
              >
                {t(`transcript_editor.${errorMessage}`)}
              </Alert>
            </form>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>

      <Button
        color="plain"
        onClick={handlePlay}
        data-testid="time-control"
        className="px-0"
      >
        ▶ {formatTime(time)}
      </Button>
    </div>
  );
};

export default EditableSpeakerLabel;
