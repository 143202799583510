import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { Container } from 'reactstrap';
import cx from 'classnames';
import { NumberParam, stringify, useQueryParams } from 'use-query-params';

import { ActivityMapData } from 'src/components/ActivityMap/ActivityMap';
import CollectionDropdown from 'src/components/CollectionDropdown/CollectionDropdown';
import CollectionSummary from 'src/components/CollectionSummary/CollectionSummary';
import CollectionTopicsPreview from 'src/components/CollectionTopicsPage/CollectionTopicsPreview/CollectionTopicsPreview';
import Layout from 'src/components/Layout/Layout';
import Panel from 'src/components/Panel/Panel';
import RecentActivity from 'src/components/RecentActivity/RecentActivity';
import SearchInput from 'src/components/SearchInput/SearchInput';
import StackedHighlightCards from 'src/components/StackedHighlightCards/StackedHighlightCards';
import authSelectors from 'src/redux/auth/auth-selectors';
import collectionSelectors from 'src/redux/collection/collection-selectors';
import {
  loadActivityMap,
  loadCollectionDetails,
} from 'src/redux/collection/collection-slice';
import { StoreState } from 'src/redux/store';
import { User } from 'src/types/auth';
import { CollectionDetail } from 'src/types/collection';
import { useActiveCollection } from 'src/util/hooks';
import { getPreConversationRole, hasRole } from 'src/util/user';

import styles from './HomeRoute.module.scss';

interface StateProps {
  activityMapData: ActivityMapData[];
  user: User;
  collectionDetail: CollectionDetail | undefined;
  collectionDetailError: Error | undefined;
}

type Props = RouteComponentProps & StateProps & DispatchProp;

/** Map state from redux to the components props */
const mapStateToProps = (state: StoreState): StateProps => ({
  activityMapData: collectionSelectors.getActivityMap(state),
  user: authSelectors.getUser(state),
  collectionDetail: collectionSelectors.getCollectionDetail(state),
  collectionDetailError: collectionSelectors.getCollectionDetailError(state),
});

/**
 * Landing page for app.lvn.org
 */
export const HomeRoute = ({
  dispatch,
  collectionDetail,
  history,
  user,
}: Props) => {
  const { t } = useTranslation();
  // get search query parameters
  const [query, setQuery] = useQueryParams({
    c: NumberParam,
  });
  const { c: activeCollectionId } = query;

  const activeCollection = useActiveCollection(user, activeCollectionId);

  // when changing from the dropdown, update the URL
  const handleCollectionChange = (collectionId: number) => {
    setQuery({ c: collectionId });
  };

  // load conversations on mount
  React.useEffect(() => {
    if (!activeCollection) {
      return;
    }

    // load collection details
    dispatch(loadCollectionDetails(activeCollection.id));

    // load latest conversations if no search results
    dispatch(loadActivityMap(activeCollection.id));
  }, [dispatch, activeCollection]);

  const handleSearch = (searchQuery: string) => {
    history.push(
      `/search/?${stringify({
        q: searchQuery,
        c: activeCollection && activeCollection.id,
      })}`
    );
  };

  // if this is a pre-convo user, redirect to homepage
  if (hasRole(user, getPreConversationRole())) {
    return <Redirect to="/" />;
  }

  return (
    <Layout
      className="HomeRoute"
      title={activeCollection ? activeCollection.title : t('main_nav.home')}
    >
      <Container data-testid="home-route">
        <section className="mb-5">
          <div className={cx('text-center mb-4 mt-5', styles.headerContainer)}>
            <CollectionDropdown
              user={user}
              activeCollection={activeCollection}
              onChange={handleCollectionChange}
            />
            {collectionDetail &&
              collectionDetail.id === activeCollection.id && (
                <CollectionSummary collectionDetail={collectionDetail} />
              )}
          </div>
          <div className={cx(styles.searchInputContainer, 'mx-auto')}>
            <SearchInput
              onSearch={handleSearch}
              size="lg"
              inputClassName="shadow"
            />
          </div>
        </section>

        <section className="mb-5">
          <h5 className="section-title">{t('collection.activity_header')}</h5>
          <Panel className="p-0 overflow-hidden">
            <RecentActivity activeCollection={activeCollection} />
          </Panel>
        </section>
        <section className="mb-5">
          <h5 className="section-title">{t('highlights.explore_header')}</h5>
          {activeCollection && (
            <div>
              <span className="section-subtitle">
                {t('highlights.subheader', { title: activeCollection.title })}.
              </span>
              <StackedHighlightCards collectionId={activeCollection.id} />
            </div>
          )}
        </section>
        <section className="mb-5">
          <h5 className="section-title">{t('topics.topics_header')}</h5>
          <span className="section-subtitle">
            {t('topics.topics_subheader')}
          </span>
          <CollectionTopicsPreview activeCollection={activeCollection} />
        </section>
      </Container>
    </Layout>
  );
};

// use connect() to get redux to connect to the component
export default connect(mapStateToProps)(HomeRoute);
