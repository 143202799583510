import React from 'react';
import cx from 'classnames';

import PlayButton from 'src/components/PlayButton/PlayButton';
import { formatTime } from 'src/util/format';

import styles from './SpeakerPlay.module.scss';

interface TimeElapsedProps {
  seekTime: number | undefined;
  startTime: number;
  audioDuration: number;
  className?: string;
}
const TimeElapsed = ({
  seekTime,
  startTime,
  audioDuration,
  className,
}: TimeElapsedProps) => {
  // if audio is not loaded, just show full duration
  if (seekTime == null) {
    return <div className={className}>{formatTime(audioDuration)}</div>;
  }

  // otherwise show the elapsed time | total time, i.e. 0:33 / 1:20
  const elapsed = Math.max(seekTime - startTime, 0);
  return (
    <div className={className}>
      <span className={styles.elapsedTime}>{formatTime(elapsed)}</span>{' '}
      <span>/ {formatTime(audioDuration)}</span>
    </div>
  );
};

interface Props {
  speakerName: string;
  locationName: string;
  seekTime: number | undefined;
  startTime: number;
  audioDuration: number;
  onPlay: () => void;
  isActive: boolean;
  isPlaying: boolean;
  isLoading: boolean;
  audioError: MediaError | undefined;
  className?: string;
  tabIndex?: number;
}
const SpeakerPlay = ({
  speakerName,
  locationName,
  seekTime,
  startTime,
  audioDuration,
  onPlay,
  isActive,
  isPlaying,
  audioError,
  isLoading,
  className,
  tabIndex,
}: Props) => (
  <div className={cx(styles.container, className)}>
    <PlayButton
      shape="circle"
      isPlaying={isActive && isPlaying}
      isLoading={isActive && isLoading}
      isError={isActive && audioError != null}
      onClick={onPlay}
      className="me-2"
      tabIndex={tabIndex}
    />
    <div className="d-inline">
      <span className="fwbold">{speakerName}</span>{' '}
      <span className="text-gray-700">
        in {locationName} ·{' '}
        <TimeElapsed
          startTime={startTime}
          seekTime={seekTime}
          audioDuration={audioDuration}
          className="d-inline"
        ></TimeElapsed>
      </span>
    </div>
  </div>
);

export default SpeakerPlay;
