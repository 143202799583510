import React from 'react';
import cx from 'classnames';

import styles from './FormErrorText.module.scss';

interface Props {
  children: React.ReactNode;
  id: string;
  className?: string;
  testId?: string;
}

const FormErrorText = ({ children, id, className, testId }: Props) => (
  <p
    className={cx(styles.errorText, 'text-danger', className)}
    id={id}
    data-testid={testId}
  >
    {children}
  </p>
);

export default FormErrorText;
