// This file is referenced by Product and Partnerships teams
// and serves as documentation for the analytics events that are being tracked.
// Please coordinate with them before making any changes, additions, or removals.

export enum Category {
  Conversation = 'Conversation',
  Highlight = 'Highlight',
  LiveRecording = 'Live Recording',
  UploadConversation = 'Upload Conversation',
}

export enum Action {
  Cancel = 'Cancel',
  Click = 'Click',
  Download = 'Download',
  Embed = 'Embed',
  Pause = 'Pause',
  Play = 'Play',
  Seek = 'Seek',
  Share = 'Share',
  StatusChange = 'Status Change',
  Stop = 'Stop',
  Upload = 'Upload',
}

export enum Name {
  ConversationRoute = 'Conversation Route',
  HighlightCard = 'Highlight Card',
  SelectNewMicrophone = 'Select New Microphone',
  StartRecordingButton = 'Start Recording Button',
  UpdateRecordingRoom = 'Update Recording Room',
  ViewConversationButton = 'View your conversation here Button',
}
export interface AnalyticsEvent {
  category: Category;
  action: Action;
  name: Name;
}
