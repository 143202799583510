import * as React from 'react';
import { Trans, useTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Label } from 'reactstrap';
import Cleave from 'cleave.js/react';
import { FormikProps } from 'formik';
import * as Yup from 'yup';

import FormButton from 'src/components/FormButton/FormButton';
import FormError from 'src/components/FormError/FormError';
import FormStep, { FormContent } from 'src/components/FormStep/FormStep';
import { useSlashDateFormat } from 'src/util/date';
import { getErrorMessage } from 'src/util/form';

interface BaseProps {
  onBack: () => void;
  autoFocus: boolean;
}
type Props = BaseProps & FormikProps<any> & RouteComponentProps;

const BirthdayStep = ({
  autoFocus,
  onBack,
  values,
  handleChange,
  errors,
  location,
  status,
  setStatus,
}: Props) => {
  const { t } = useTranslation();
  const DATEFORMAT = useSlashDateFormat();
  React.useEffect(() => {
    if (location.state) {
      // the server error for date is usually a programming error, not a user error
      // so replace the error message
      const e = {
        message: (
          <span>
            <Trans
              i18nKey="register.birthdate_error"
              /*eslint-disable */
              components={{
                1: <a href="mailto:help@lvn.org" />,
              }}
              /*eslint-disable */
            />
          </span>
        ),
        attribute: 'birthdate',
      };
      setStatus(e);
    }
  }, [location.state, setStatus]);

  const errorMessage = getErrorMessage({
    field: 'birthdate',
    status,
    errors,
  });

  return (
    <FormStep onBack={onBack}>
      <FormContent>
        <h1>{t('register.birthdate_header')}</h1>
        <Label className="w-100" for="birthdate">
          <Cleave
            placeholder={DATEFORMAT}
            options={{ date: true, datePattern: ['m', 'd', 'Y'] }}
            onChange={handleChange}
            value={values.birthdate}
            id="birthdate"
            className="form-control"
            data-testid="birthdate-step"
            aria-label="Birthdate"
            aria-describedby="birthdate-helper error-msg-birthdate"
            aria-required
            aria-invalid={Boolean(errorMessage != null)}
            autoFocus={autoFocus}
          />
        </Label>
        <FormError name="birthdate">{errorMessage}</FormError>
        <p id="birthdate-helper">{t('register.birthdate_subtext')}</p>
      </FormContent>
      <FormButton type="submit" data-testid="next-button">
        {t('common.next')}
      </FormButton>
    </FormStep>
  );
};

BirthdayStep.validationSchema = ({ t }: WithTranslation) =>
  Yup.object().shape({
    birthdate: Yup.date().required(t('register.birthdate_validation')),
  });

export default BirthdayStep;
