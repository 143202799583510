import * as React from 'react';
import { useTranslation } from 'react-i18next';
import xor from 'lodash.xor';
import {
  ArrayParam,
  QueryParamConfig,
  SetQuery,
  useQueryParams,
} from 'use-query-params';

import {
  ConversationsFilterOptions,
  FilterOption,
  HighlightsFilterOptions,
} from 'src/types/core';
import FilterChecklist from '../FilterChecklist/FilterChecklist';
import SideFilter from '../SideFilter/SideFilter';

export const getActiveForums = (
  forumIds: string[] | undefined,
  filterOptions:
    | ConversationsFilterOptions
    | HighlightsFilterOptions
    | undefined
) => {
  return (
    forumIds &&
    filterOptions &&
    filterOptions.forums &&
    forumIds.map((id) =>
      filterOptions.forums.find((col) => String(col.id) === id)
    )
  );
};

export const handleForumChange = (
  option: FilterOption,
  forumIds: string[] | undefined,
  setQuery: SetQuery<{
    f: QueryParamConfig<string[] | undefined>;
    page: QueryParamConfig<number | undefined>;
  }>
) => {
  const newForumIds = xor(forumIds, [String(option.id)]);
  setQuery({
    f: newForumIds,
    page: 1,
  });
};

interface Props {
  filterOptions:
    | ConversationsFilterOptions
    | HighlightsFilterOptions
    | undefined;
}

const ForumFilter = ({ filterOptions }: Props) => {
  const [query, setQuery] = useQueryParams({
    f: ArrayParam,
  });
  const { f: forumIds } = query;
  const { t } = useTranslation();
  return (
    <SideFilter
      title={t('conversations.forums_plural')}
      open={Boolean(forumIds)}
      disabled={!(filterOptions && filterOptions.forums)}
    >
      {filterOptions && filterOptions.forums && (
        <FilterChecklist
          options={filterOptions.forums}
          onChange={(option) => {
            handleForumChange(option, forumIds, setQuery);
          }}
          activeFilterOptions={getActiveForums(forumIds, filterOptions)}
          title={t('conversations.forums_plural')}
          fade
        />
      )}
    </SideFilter>
  );
};

export default ForumFilter;
