// from https://stackoverflow.com/questions/10730362/get-cookie-by-name
function getCookie(name: string): string | undefined {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) {
    return match[2];
  }
  return undefined;
}

class FetchError extends Error {
  constructor(...args: any[]) {
    super(...args);
    Error.captureStackTrace(this, FetchError);
  }
}

class FetchFail extends Error {
  constructor(...args: any[]) {
    super(...args);
    Error.captureStackTrace(this, FetchFail);
  }
}

function fetchErrorCheck(response: any) {
  return (results: any) => {
    /** handle http status codes not being 200 OK and JSend error formats */
    if (response.status >= 500 || results.status === 'fail') {
      throw new FetchFail(
        (results.data && results.data.error) ||
          results.detail ||
          results.message ||
          JSON.stringify(results)
      );
    }
    if (response.status >= 400 || results.status === 'error') {
      throw new FetchError(
        results.error ||
          results.detail ||
          results.message ||
          JSON.stringify(results)
      );
    }
    return results;
  };
}

function urlWithQueryParams(
  url: string,
  query?: { [key: string]: string } | undefined | null
) {
  let urlFormatted;
  if (query) {
    const queryString = Object.keys(query)
      .filter((key) => query[key] != null)
      .map((key) => `${key}=${encodeURIComponent(query[key])}`)
      .join('&');
    urlFormatted = `${url}?${queryString}`;
  } else {
    urlFormatted = url;
  }

  return urlFormatted;
}

/**
 * Helper for fetching GET requests
 */
export async function get(
  url: string,
  query?: { [key: string]: string } | undefined | null
): Promise<any> {
  return fetch(urlWithQueryParams(url, query), {
    method: 'GET',
    credentials: 'include',
  }).then((response) => response.json().then(fetchErrorCheck(response)));
}

export async function getText(
  url: string,
  query?: { [key: string]: string } | undefined | null
): Promise<any> {
  return fetch(urlWithQueryParams(url, query), {
    method: 'GET',
    credentials: 'include',
  }).then((response) => response.text().then(fetchErrorCheck(response)));
}

/**
 * Helper for fetching POST requests
 */
export async function post(
  url: string,
  query: { [key: string]: string } | undefined | null,
  body: Record<string, unknown> | undefined | null
): Promise<any> {
  const headers: HeadersInit = {
    'Content-Type': 'application/json',
  };

  // need the CSRF token for some queries, so include it
  const csrfToken = getCookie('csrftoken');
  if (csrfToken) {
    headers['X-CSRFToken'] = csrfToken;
  }

  return fetch(urlWithQueryParams(url, query), {
    method: 'POST',
    headers,
    body: body == null ? undefined : JSON.stringify(body),
    credentials: 'include',
  }).then((response) => response.json().then(fetchErrorCheck(response)));
}

/**
 * Helper for fetching PATCH requests
 */
export async function patch(
  url: string,
  query: { [key: string]: string } | undefined | null,
  body: Record<string, unknown> | undefined | null
): Promise<any> {
  const headers: HeadersInit = {
    'Content-Type': 'application/json',
  };

  // need the CSRF token for some queries, so include it
  const csrfToken = getCookie('csrftoken');
  if (csrfToken) {
    headers['X-CSRFToken'] = csrfToken;
  }

  return fetch(urlWithQueryParams(url, query), {
    method: 'PATCH',
    headers,
    body: body == null ? undefined : JSON.stringify(body),
    credentials: 'include',
  }).then((response) => response.json().then(fetchErrorCheck(response)));
}

/**
 * Helper for fetching DELETE requests
 */
export async function del(
  url: string,
  query?: { [key: string]: string } | undefined | null,
  body?: Record<string, unknown> | undefined | null
): Promise<any> {
  return fetch(urlWithQueryParams(url, query), {
    method: 'DELETE',
    body: body == null ? undefined : JSON.stringify(body),
    credentials: 'include',
  }).then((response) => response.json().then(fetchErrorCheck(response)));
}

/**
 * Helper for fetching PUT requests
 */
export async function put(
  url: string,
  query: { [key: string]: string } | undefined | null,
  body: Record<string, unknown> | undefined | null
): Promise<any> {
  const headers: HeadersInit = {
    'Content-Type': 'application/json',
  };

  return fetch(urlWithQueryParams(url, query), {
    method: 'PUT',
    headers,
    body: body == null ? undefined : JSON.stringify(body),
    credentials: 'include',
  }).then((response) => response.json().then(fetchErrorCheck(response)));
}

/**
 * Helper for fetching PUT requests for files
 */
export async function putFile(url: string, body: FormData): Promise<any> {
  // note: headers are not set for this to work. let the browser automatically set it
  // (https://muffinman.io/uploading-files-using-fetch-multipart-form-data/)
  return fetch(url, {
    method: 'PUT',
    body,
    credentials: 'include',
  }).then((response) => response.json().then(fetchErrorCheck(response)));
}

/**
 * Helper for fetching POST requests for multipart data
 */
export async function postMultipart(url: string, body: FormData): Promise<any> {
  return fetch(url, {
    method: 'POST',
    body,
    credentials: 'include',
  }).then((response) => response.json().then(fetchErrorCheck(response)));
}
