import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import AccordianTable from 'src/components/AdminPage/Common/AccordianTable';
import { TableProps } from 'src/components/core/Table/Table';
import adminSelectors from 'src/redux/admin/admin-selectors';
import { selectors as insightsSelectors } from 'src/redux/insights/insights-selectors';
import { Catalog } from 'src/types/insights';
import { UpdateCatalogSenseMakerToggle } from './CatalogSenseMakerToggle';

import styles from './MemberCatalogsTable.module.scss';

const CatalogNameContent = ({ val: catalog }: { val: Catalog }) => {
  return (
    <Link className={cx(styles.title)} to={`/insights/catalog/${catalog.id}`}>
      {catalog.title}
    </Link>
  );
};

const CatalogRoleContent = ({ val: catalog }: { val: Catalog }) => {
  return <UpdateCatalogSenseMakerToggle catalog={catalog} />;
};

const MemberCatalogsTable = () => {
  const { t } = useTranslation();
  const catalogs = useSelector(insightsSelectors.getCatalogs);
  const activeOrganizationId = useSelector(adminSelectors.getOrganizationId);

  const orgSpecificCatalogs = useMemo(
    () => catalogs.filter((c) => c.organization_id === activeOrganizationId),
    [catalogs, activeOrganizationId]
  );

  const tableProps: TableProps<Catalog> = {
    values: orgSpecificCatalogs,
    testId: 'user-catalogs-table',
    valToKey: (catalog) => catalog.id,
    columns: [
      {
        id: 'catalog-name',
        headerLabel: t('admin.member_catalog_column_1'),
        widthFraction: 8,
        content: CatalogNameContent,
      },
      {
        id: 'catalog-role',
        headerLabel: t('admin.member_catalog_column_2'),
        widthFraction: 4,
        content: CatalogRoleContent,
      },
    ],
  };

  return (
    <AccordianTable
      {...tableProps}
      label={t('admin.catalog_accordian_label')}
    />
  );
};

export default MemberCatalogsTable;
