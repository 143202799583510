import * as React from 'react';

interface Props {
  title: string;
  children: React.ReactNode;
}

const EmptyTable = ({ title, children }: Props) => (
  <div className="text-center p-3" data-testid="empty-table">
    <h3>{title}</h3>
    {children}
  </div>
);

export default EmptyTable;
