import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Container } from 'reactstrap';

import LoadingOverlay from 'src/components/core/LoadingOverlay/LoadingOverlay';
import Layout from 'src/components/Layout/Layout';
import { Shift } from 'src/types/registration';

import styles from '../RegistrationRoutes.module.scss';

const ShiftNotFoundMessage = () => (
  <p data-testid="shift-not-found-error">
    <Trans
      i18nKey="confirm_signup.not_found_subtext"
      /*eslint-disable */
      components={{ 1: <a href="mailto:help@lvn.org" /> }}
      /*eslint-disable */
    />
  </p>
);

const ShiftFullMessage = () => (
  <p data-testid="shift-full-error">
    <Trans
      i18nKey="confirm_signup.conversation_full"
      /*eslint-disable */
      components={{
        1: (
          <a href="https://lvn.org" target="_blank" rel="noopener noreferrer" />
        ),
      }}
      /*eslint-disable */
    />
  </p>
);

interface Props {
  isLoading: boolean;
  shiftId: Shift['id'] | undefined;
  shiftIdRequired?: boolean;
  shift: Shift | undefined;
  title: string;
  children: React.ReactNode;
}
/**
 * A component that renders shift related error messages if there are errors.
 * If there are no errors, it will render the child component
 */
const ShiftChecker = ({
  isLoading,
  shiftId,
  shiftIdRequired,
  shift,
  title,
  children,
}: Props) => {
  const { t } = useTranslation();
  if (isLoading) {
    return <LoadingOverlay active={isLoading} />;
  }

  let errorMessageElement = null;

  if (shiftIdRequired && !shiftId) {
    errorMessageElement = <ShiftNotFoundMessage />;
  }

  if (shiftId) {
    if (!shift) {
      errorMessageElement = <ShiftNotFoundMessage />;
    } else if (
      shift.num_volunteers_still_needed != null &&
      shift.num_volunteers_still_needed < 1
    ) {
      errorMessageElement = <ShiftFullMessage />;
    }
  }

  if (errorMessageElement) {
    return (
      <Layout title={title} className={styles.layout}>
        <Container className={styles.container}>
          <div className="text-center">
            <h1>{t('confirm_signup.not_found')}!</h1>
            {errorMessageElement}
          </div>
        </Container>
      </Layout>
    );
  }

  return <>{children}</>;
};

export default ShiftChecker;
