import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import Button from 'src/components/core/Button/Button';
import LoadingSpinner from 'src/components/core/LoadingSpinner/LoadingSpinner';
import adminSelectors from 'src/redux/admin/admin-selectors';
import { selectors as insightsSelectors } from 'src/redux/insights/insights-selectors';
import { loadCatalogs } from 'src/redux/insights/insights-slice';
import AddOrganizationMemberForm from './AddOrganizationMemberForm/AddOrganizationMemberForm';

import styles from './AddOrganizationMember.module.scss';

const AddOrganizationMember = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [, setQuery] = useQueryParams({
    page: StringParam,
    userId: NumberParam,
  });

  const isLoadingUsers = useSelector(adminSelectors.isLoadingUsers);
  const isLoadingCatalogs = useSelector(insightsSelectors.isLoading);

  const [startedLoadingCatalogs, setStartedLoadingCatalogs] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (startedLoadingCatalogs && !isLoadingCatalogs && !isLoadingUsers) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [startedLoadingCatalogs, isLoadingCatalogs, isLoadingUsers]);

  useEffect(() => {
    dispatch(loadCatalogs());
    setStartedLoadingCatalogs(true);
  }, [dispatch]);

  return (
    <div className={cx(styles.fixedWidth)}>
      <Button
        color={'link'}
        onClick={() =>
          setQuery({ userId: undefined, page: undefined }, 'pushIn')
        }
        icon={['far', 'arrow-left']}
        content={t('admin.go_to_team')}
      />
      {isLoading ? (
        <div className="w-100 text-center">
          <LoadingSpinner thick size="lg" active theme="dark" />
        </div>
      ) : (
        <div className="d-flex flex-row">
          <div className={cx(styles.section)}>
            <Header />
          </div>
          <div className={cx(styles.section)}>
            <AddOrganizationMemberForm />
          </div>
        </div>
      )}
    </div>
  );
};

const Header = () => {
  const { t } = useTranslation();

  return (
    <div className={cx(styles.headerContainer)}>
      <div className="mb-4">
        <span>{t('admin.add_org_member_header')}</span>
      </div>
      <span>
        {
          <Trans
            i18nKey="admin.add_org_member_header_member_description"
            components={{
              1: <span className={cx(styles.bold)} />,
            }}
          />
        }
      </span>
      <br />
      <span>
        {
          <Trans
            i18nKey="admin.add_org_member_header_admin_description"
            components={{
              1: <span className={cx(styles.bold)} />,
            }}
          />
        }
      </span>
      <div className="mt-4">
        <span>
          {
            <Trans
              i18nKey="admin.add_org_member_header_learn_more"
              components={{
                1: (
                  <a href="https://help.fora.io/hc/en-us/articles/4402109472023-User-roles-and-permissions" />
                ),
              }}
            />
          }
        </span>
      </div>
    </div>
  );
};

export default AddOrganizationMember;
