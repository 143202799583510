import moment from 'moment-timezone';

// This function provides the correct spanish ordinal given a number
// English ordinals are 1st, 2nd, 3rd, ect.
export const getSpanishOrdinal = (number: number) => {
  let ordinal = `${number}`;
  if (number === 0) {
    return ordinal;
  }
  const lastDigit = number % 10;
  if ([1, 3].includes(lastDigit)) ordinal = ordinal + 'ro';
  if ([2].includes(lastDigit)) ordinal = ordinal + 'do';
  if ([4, 5, 6].includes(lastDigit)) ordinal = ordinal + 'to';
  if ([7, 0].includes(lastDigit)) ordinal = ordinal + 'mo';
  if ([8].includes(lastDigit)) ordinal = ordinal + 'vo';
  if ([9].includes(lastDigit)) ordinal = ordinal + 'no';

  return ordinal;
};

// This will need te be generated ourselves for every language added to the system
// https://momentjs.com/docs/#/i18n/
moment.locale('es-US', {
  months:
    'enero_febrero_marzo_abril_mayo_junio_julio_agosto_septiembre_octubre_noviembre_diciembre'.split(
      '_'
    ),
  monthsShort:
    'enero_feb._marzo_abr._mayo_jun_jul._agosto_set._oct._nov._dic.'.split('_'),
  monthsParseExact: true,
  weekdays: 'domingo_lunes_martes_miércoles_jeuves_viernes_sábado'.split('_'),
  weekdaysShort: 'D_L_M_X_J_V_S'.split('_'),
  weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sá'.split('_'),
  weekdaysParseExact: true,
  longDateFormat: {
    LT: 'h:mm a z',
    LTS: 'h:mm:ss a z',
    L: 'MM/DD/YYYY',
    l: 'YYYY-MM-DD',
    LL: 'MMMM D',
    ll: 'MMM D',
    LLL: 'MMMM D, YYYY',
    lll: 'MMM D, YYYY',
    LLLL: '',
    llll: 'MM/DD/YY',
  },
  calendar: {
    sameDay: '[hoy a las] LT',
    nextDay: '[mañana a las] LT',
    nextWeek: 'dddd [a las] LT',
    lastDay: '[ayer a las] LT',
    lastWeek: 'dddd [pasado a las] LT',
    sameElse: 'L',
  },
  relativeTime: {
    future: 'en %s',
    past: '%s hace',
    s: 'unos pocos segundos',
    ss: '%d sequndos',
    m: 'un minuto',
    mm: '%d minuto',
    h: 'una hora',
    hh: '%d horas',
    d: 'un dia',
    dd: '%d dias',
    w: 'una semana',
    ww: '%d semanas',
    M: 'un mes',
    MM: '%d meses',
    y: 'un año',
    yy: '%d años',
  },
  dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
  ordinal: getSpanishOrdinal,
  meridiemParse: /am|pm/,
  isPM: function (input) {
    return input.charAt(0) === 'p';
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  meridiem: function (hours, minutes, isLower) {
    return hours < 12 ? 'am' : 'pm';
  },
  week: {
    dow: 0, // First day of week is Sunday
    doy: 6, // First week of year must contain 1 January (7 + 0 - 1)
  },
});

moment.updateLocale('en', {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  meridiem: function (hour, minute, isLowerCase) {
    if (hour < 12) {
      return 'a.m.';
    } else {
      return 'p.m.';
    }
  },
  longDateFormat: {
    // If these are updated, we also need to update the same in the Spanish translations
    LT: 'h:mm a z',
    LTS: 'h:mm:ss a z',
    L: 'MM/DD/YYYY',
    l: 'YYYY-MM-DD',
    LL: 'MMMM D',
    ll: 'MMM D',
    LLL: 'MMMM D, YYYY',
    lll: 'MMM D, YYYY',
    LLLL: '',
    llll: 'MM/DD/YY',
  },
  monthsShort:
    'Jan._Feb._March_April_May_June_July_Aug._Sept._Oct._Nov._Dec.'.split('_'),
});
