import * as React from 'react';
import cx from 'classnames';

import Button from 'src/components/core/Button/Button';
import { Paging } from 'src/types/conversation';

import styles from './PagingControl.module.scss';

interface Props {
  pagingInfo: Paging | undefined;
  itemsPerPage: number;
  onNext: () => void;
  onPrevious: () => void;
  onSeek: (pageNum: number) => void;
  className?: string;
  style?: React.CSSProperties;
}

// helper function to determine if we are on the first page
function isOnFirstPage(pageNum: number, itemsPerPage: number): boolean {
  const firstItemNumber = (pageNum - 1) * itemsPerPage + 1;
  return firstItemNumber === 1;
}

// helper function to determine if we are on the last page
function isOnLastPage(
  totalNumPages: number,
  pageNum: number,
  itemsPerPage: number
): boolean {
  const lastPage = Math.ceil(totalNumPages / itemsPerPage);
  return pageNum >= lastPage;
}

// helper function to get a list of pages to show, possibly with '...' inside (coded by -1)
// e.g. [1,-1,9,10] would look like [1 ... 9 10]
function getPageNumbersToShow(
  totalNumPages: number,
  pageNum: number,
  itemsPerPage: number
) {
  const lastPage = Math.ceil(totalNumPages / itemsPerPage);

  const pageNumbers: number[] = []; // for keeping track of page numbers
  const pageNumbersToShow: number[] = []; // for final render (possibly includes ...'s)

  // if there are less than 5 pages, just show buttons for all of them
  if (lastPage < 5) {
    // create array of [1, 2, 3], or up to whatever lastPage is
    for (let i = 1; i < lastPage + 1; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  }

  // otherwise, we need to do some work on which pages to show
  else {
    // always show the first page
    pageNumbers.push(1);

    // if it's the first page, just show that and page 2
    if (isOnFirstPage(pageNum, itemsPerPage)) {
      pageNumbers.push(2);
    }

    // if it's the last page, just show that and page lastPage - 1
    else if (isOnLastPage(totalNumPages, pageNum, itemsPerPage)) {
      pageNumbers.push(lastPage - 1);
    }

    // otherwise show the numbers around the current page number
    else {
      // don't want to show [1, 1, 2, 3, ..., 10], so make sure we are above page 2
      if (pageNum > 2) {
        pageNumbers.push(pageNum - 1);
      }
      pageNumbers.push(pageNum);
      // don't want to show the last page twice [1, ..., 9, 10, 10]
      // so make sure we aren't too close to the end
      if (pageNum <= lastPage - 2) {
        pageNumbers.push(pageNum + 1);
      }
    }
    // always show the last page
    pageNumbers.push(lastPage);

    // see if we need to insert ...'s (coded as -1)
    // loops through the numbers we have, and adds -1 if the difference between two numbers is > 1
    pageNumbers.forEach((page, i, arr) => {
      // always add the first number
      if (i === 0) {
        pageNumbersToShow.push(page);
      }
      // difference is more than 1, so add the ellipse, then the number
      else if (arr[i - 1] + 1 < page) {
        pageNumbersToShow.push(-1);
        pageNumbersToShow.push(page);
      }
      // needed to add the last number
      else {
        pageNumbersToShow.push(page);
      }
    });
  }
  return pageNumbersToShow;
}

const PagingControl = ({
  pagingInfo,
  itemsPerPage,
  onNext,
  onPrevious,
  onSeek,
  style = {},
}: Props) => {
  if (!pagingInfo) {
    return null;
  }

  const handleSeek = (pageNum: number) => {
    onSeek(pageNum);
  };

  const onFirstPage = isOnFirstPage(pagingInfo.page_num, itemsPerPage);
  const onLastPage = isOnLastPage(
    pagingInfo.total,
    pagingInfo.page_num,
    itemsPerPage
  );
  const pageNumbersToShow = getPageNumbersToShow(
    pagingInfo.total,
    pagingInfo.page_num,
    itemsPerPage
  );

  // do not render controls if this is the only page
  if (onFirstPage && onLastPage) {
    return null;
  }

  // render the controls
  return (
    <div
      className={cx(styles.buttonContainer)}
      style={style}
      data-testid="pagingControl"
    >
      {!onFirstPage && (
        <Button
          icon={['far', 'chevron-left']}
          onClick={onPrevious}
          disabled={onFirstPage}
          className={styles.prevButton}
          shape="rounded"
          aria-label="back"
        />
      )}
      {pageNumbersToShow.map((pageNumber, i) => {
        if (pageNumber === -1) {
          // render ... instead of a button page number
          return (
            <Button
              compact
              grayOutline
              shape="rounded"
              color="plain"
              key={i}
              className={`${styles.spacer} ms-2`}
              disabled
            >
              · · ·
            </Button>
          );
        }
        const active = pageNumber === pagingInfo.page_num;
        return (
          <Button
            key={i}
            active={active}
            shape="rounded"
            color={active ? 'primary' : 'default'}
            outline={active}
            className={`${styles.pageBtn} ms-2`}
            compact
            onClick={() => handleSeek(pageNumber)}
          >
            {pageNumber}
          </Button>
        );
      })}
      {!onLastPage && (
        <Button
          icon={['far', 'chevron-right']}
          className={`${styles.nextButton} ms-2`}
          onClick={onNext}
          disabled={onLastPage}
          shape="rounded"
          aria-label="next"
        />
      )}
    </div>
  );
};

export default PagingControl;
