import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import { Alert } from 'reactstrap';

import { getBootstrapData } from 'src/api/api';
import Button from 'src/components/core/Button/Button';
import LoadingOverlay from 'src/components/core/LoadingOverlay/LoadingOverlay';
import LoadingSpinner from 'src/components/core/LoadingSpinner/LoadingSpinner';
import Panel from 'src/components/Panel/Panel';
import authSelectors from 'src/redux/auth/auth-selectors';
import { loginSuccess } from 'src/redux/auth/auth-slice';
import collectionsSelectors from 'src/redux/collections/collections-selectors';
import {
  clearCreateCollection,
  createCollection,
  deleteCollection,
  loadManagedCollections,
} from 'src/redux/collections/collections-slice';
import { StoreState } from 'src/redux/store';
import { Organization, User } from 'src/types/auth';
import { Collection, CollectionFields } from 'src/types/collection';
import { OrganizationMetadata } from 'src/types/organization';
import { getCollectionsPermission, hasPermission } from 'src/util/user';
import CollectionDetailEditForm from './CollectionDetailEditForm/CollectionDetailEditForm';
import CollectionManagementTable from './CollectionManagementTable/CollectionManagementTable';

import styles from './ManageCollections.module.scss';

interface BaseProps {
  organization: Organization | undefined;
  organizationMetadata: OrganizationMetadata | undefined;
}

interface StateProps {
  user: User;
  collections: Collection[] | undefined;
  isLoading: boolean;
  error: Error | undefined;
  newCollectionIsSaving: boolean;
  newCollectionIsSaved: boolean;
  newCollectionError: Error | undefined;
}
type Props = BaseProps & StateProps & DispatchProp;

const mapStateToProps = (state: StoreState): StateProps => ({
  user: authSelectors.getUser(state),
  isLoading: collectionsSelectors.isLoading(state),
  collections: collectionsSelectors.getCollections(state),
  error: collectionsSelectors.getCollectionsError(state),
  newCollectionIsSaved: collectionsSelectors.getCreateIsSaved(state),
  newCollectionIsSaving: collectionsSelectors.getCreateIsSaving(state),
  newCollectionError: collectionsSelectors.getCreateError(state),
});

export const ManageCollections = ({
  collections,
  user,
  error,
  isLoading,
  newCollectionIsSaving,
  newCollectionIsSaved,
  newCollectionError,
  organization,
  organizationMetadata,
  dispatch,
}: Props) => {
  const { t } = useTranslation();
  React.useEffect(() => {
    dispatch(loadManagedCollections({ organizationId: organization?.id }));
  }, [dispatch, organization]);
  const [addingNewCollection, setAddingNewCollection] = React.useState(false);
  const canAddNewCollection = hasPermission(
    user,
    getCollectionsPermission({ operation: 'create' })
  );

  const capSummary = organizationMetadata ? (
    t('admin.collection_cap_summary', {
      conversations: organizationMetadata.num_conversations,
      cap: organizationMetadata.upload_cap,
    })
  ) : (
    <LoadingSpinner active size="sm" />
  );

  const handleDeleteCollection = (collectionId: Collection['id']) => {
    dispatch(deleteCollection(collectionId));
  };

  const handleCreateCollection = (collectionFields: CollectionFields) => {
    dispatch(createCollection(collectionFields));
  };

  React.useEffect(() => {
    if (newCollectionIsSaved) {
      dispatch(clearCreateCollection());
      // refresh bootstrap data after creating a new collection
      getBootstrapData().then((data) => {
        dispatch(loginSuccess(data));
      });
      setAddingNewCollection(false);
    }
  }, [newCollectionIsSaved, dispatch]);

  // don't show loader when we are refetching after a new collection is added
  const showLoader = isLoading && !addingNewCollection;

  if (error) {
    return (
      <Alert color="danger">
        {t('admin.collection_error')} {error.message}
      </Alert>
    );
  }

  if (showLoader) {
    return (
      <LoadingOverlay
        active={showLoader}
        className={styles.overlay}
        bgColor="transparent"
      />
    );
  }

  return (
    <div>
      <div className="mb-2">
        <p className="text-gray-800 mb-1" data-testid="cap-summary">
          {capSummary}
        </p>
        <div className="d-flex justify-content-end mb-3">
          {canAddNewCollection && (
            <Button
              onClick={() => setAddingNewCollection(true)}
              color="primary"
            >
              {t('admin.collection_add')}
            </Button>
          )}
        </div>
      </div>
      <>
        {addingNewCollection ? (
          <Panel className={styles.panel} data-testid="add-new-collection">
            <h2 className="p py-3 mb-0 fwbold text-fora-purple">
              {t('admin.collection_add')}
            </h2>
            <div className="w-100 d-flex justify-content-center mb-4">
              {organization ? (
                <CollectionDetailEditForm
                  organizationId={organization.id}
                  isSaved={newCollectionIsSaved}
                  isSaving={newCollectionIsSaving}
                  error={newCollectionError}
                  onCollapse={() => setAddingNewCollection(false)}
                  onSubmit={handleCreateCollection}
                  className={styles.form}
                />
              ) : (
                <Alert color="danger">
                  <Trans
                    i18nKey="admin.collection_add_error"
                    /*eslint-disable */
                    components={{
                      1: <a href="mailto:help@lvn.org" />,
                    }}
                    /*eslint-disable */
                  />
                </Alert>
              )}
            </div>
          </Panel>
        ) : (
          <CollectionManagementTable
            collections={collections}
            isLoading={isLoading}
            onDeleteCollection={handleDeleteCollection}
          />
        )}
      </>
    </div>
  );
};

export default connect(mapStateToProps)(ManageCollections);
