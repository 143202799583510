import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { connect, DispatchProp } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import Button from 'src/components/core/Button/Button';
import highlightCardStyles from 'src/components/HighlightCard/HighlightCard.module.scss';
import HighlightTitle from 'src/components/HighlightCard/HighlightTitle/HighlightTitle';
import HighlightEdit from 'src/components/HighlightEdit/HighlightEdit';
import authSelectors from 'src/redux/auth/auth-selectors';
import highlightSelectors from 'src/redux/highlight/highlight-selectors';
import {
  deleteHighlight,
  editHighlight,
} from 'src/redux/highlight/highlight-slice';
import { StoreState } from 'src/redux/store';
import { User } from 'src/types/auth';
import { Conversation, Highlight, Snippet } from 'src/types/conversation';

interface BaseProps {
  conversation: Conversation;
  highlight: Highlight;
  snippets: Snippet[];

  handleCloseModal: () => void;
  modalIsOpen: boolean;
  modalType: string | null;
  onDelete?: () => void;
}

interface StateProps {
  isSaving: boolean;
  isSaved: boolean;
  saveError: Error | undefined;
  user: User;
}

type Props = BaseProps & StateProps & DispatchProp;

/** Map state from redux to the components props */
const mapStateToProps = (state: StoreState, props: BaseProps): StateProps => {
  const { highlight } = props;
  const editHighlightId = highlightSelectors.getEditHighlightId(state);

  return {
    isSaving:
      highlight.id === editHighlightId &&
      highlightSelectors.isSavingEditHighlight(state),
    isSaved:
      highlight.id === editHighlightId &&
      highlightSelectors.isSavedEditHighlight(state),
    saveError:
      highlight.id === editHighlightId
        ? highlightSelectors.getErrorEditHighlight(state)
        : undefined,
    user: authSelectors.getUser(state),
  };
};

/* Highlight card modal that shows highlight edit and deletion flows */
const HighlightModal = ({
  dispatch,
  conversation,
  modalType,
  highlight,
  snippets,
  user,
  handleCloseModal,
  isSaving,
  isSaved,
  saveError,
  modalIsOpen,
  onDelete,
}: Props) => {
  const { t } = useTranslation();
  const handleDelete = () => {
    handleCloseModal();
    dispatch(deleteHighlight(highlight.id));
    if (onDelete) {
      onDelete();
    }
  };

  const handleEditHighlight = (changes: Partial<Highlight>) => {
    dispatch(editHighlight({ highlight, changes }));
    handleCloseModal();
  };

  let borderClass;
  let modalContents;
  if (modalType === 'delete') {
    modalContents = (
      <div className="text-center">
        {t('highlight.confirm_delete')}
        <div className="mt-4">
          <Button
            color="danger"
            className="me-3"
            content={t('common.delete')}
            onClick={handleDelete}
            data-testid="confirm-delete"
          />
          <Button
            color="default"
            content={t('common.cancel')}
            onClick={handleCloseModal}
          />
        </div>
      </div>
    );
  } else if (modalType === 'edit') {
    modalContents = (
      <div className={highlightCardStyles.editModalInner}>
        <h5>
          {t('common.edit')}{' '}
          <HighlightTitle highlight={highlight} snippets={snippets} />
        </h5>
        <HighlightEdit
          highlight={highlight}
          user={user}
          onSave={handleEditHighlight}
          isSaving={isSaving}
          isSaved={isSaved}
          saveError={saveError}
          onCancel={handleCloseModal}
          conversation={conversation}
        />
      </div>
    );
  } else if (modalType === 'license') {
    borderClass = 'border border-primary';
    modalContents = (
      <div data-testid="modal-type-license">
        <Button
          icon={['far', 'times']}
          title={t('common.close')}
          onClick={handleCloseModal}
          color="plain"
          size="lg"
          className={highlightCardStyles.modalCloseBtn}
          data-testid="modal-close-btn"
        />
        <div className="text-center">
          <span>
            <FontAwesomeIcon
              icon={['fab', 'creative-commons']}
              className="text-fora-purple Icon me-2"
              size="2x"
            />
            <FontAwesomeIcon
              icon={['fab', 'creative-commons-by']}
              className="text-fora-purple Icon"
              size="2x"
            />
          </span>
          <h2 className="h4 mb-2 mt-1">{t('highlight.thanks')}</h2>
          <p className="mb-0">
            <Trans
              i18nKey="highlight.license"
              /*eslint-disable */
              components={{
                1: (
                  <a
                    href="https://creativecommons.org/licenses/by/4.0/"
                    rel="noopener noreferrer"
                  />
                ),
              }}
              /*eslint-disable */
            />
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      {modalContents != null && (
        <Modal
          className={cx('ModalContent', borderClass)}
          overlayClassName="ModalOverlay"
          isOpen={modalIsOpen}
          onRequestClose={handleCloseModal}
          contentLabel="Highlight Modal"
        >
          {modalContents}
        </Modal>
      )}
    </>
  );
};

export default connect(mapStateToProps)(HighlightModal);
