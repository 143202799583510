import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { TableProps } from 'src/components/core/Table/Table';
import adminSelectors from 'src/redux/admin/admin-selectors';
import { Collection } from 'src/types/collection';
import AccordianTable from '../../../Common/AccordianTable';
import UpdateCollectionRoleDropdown from '../../PillDropdowns/CollectionRoleDropdown';
import CollectionRoleTooltip from '../../Tooltips/CollectionRoleTooltip';
import UpdateUploadConversationsToggle from './UploadConversationsToggle';

import styles from './MemberCollectionsTable.module.scss';

const CollectionNameContent = ({ val: collection }: { val: Collection }) => {
  return (
    <Link className={cx(styles.title)} to={`/?c=${collection.id}`}>
      {collection.title}
    </Link>
  );
};

const CollectionRoleContent = ({ val: collection }: { val: Collection }) => {
  return <UpdateCollectionRoleDropdown collection={collection} />;
};

const UploadToggleContent = ({ val: collection }: { val: Collection }) => {
  return <UpdateUploadConversationsToggle collection={collection} />;
};

const CollectionRoleColumnLabel = () => {
  const { t } = useTranslation();
  const label = t('admin.member_collection_column_2');
  return (
    <div className="d-flex">
      {label}
      <CollectionRoleTooltip width="300px" />
    </div>
  );
};

const MemberCollectionsTable = () => {
  const { t } = useTranslation();
  const collections = useSelector(adminSelectors.getCollections);

  const tableProps: TableProps<Collection> = {
    values: collections,
    testId: 'user-collection-table',
    valToKey: (collection) => collection.id,
    columns: [
      {
        id: 'collection-name',
        headerLabel: t('admin.member_collection_column_1'),
        content: CollectionNameContent,
      },
      {
        id: 'collection-role',
        headerLabel: CollectionRoleColumnLabel,
        content: CollectionRoleContent,
      },
      {
        id: 'upload-conversations',
        headerLabel: t('admin.member_collection_column_3'),
        content: UploadToggleContent,
      },
    ],
  };

  return (
    <AccordianTable
      {...tableProps}
      label={t('admin.collection_accordian_label')}
    />
  );
};

export default MemberCollectionsTable;
