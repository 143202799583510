/* eslint-disable */
import Downshift, {
  ControllerStateAndHelpers,
  DownshiftProps,
  DownshiftState,
  StateChangeOptions,
} from 'downshift';
// originally from: https://codesandbox.io/s/W6gyJ30kn
import * as React from 'react';

export interface RemoveButtonProps<Item> {
  item?: Item;
  onClick?: (evt: React.SyntheticEvent) => void;
  className?: string;
}

interface MultiControllerStateAndHelpers<Item>
  extends ControllerStateAndHelpers<Item> {
  getRemoveButtonProps: (options?: RemoveButtonProps<Item>) => any;
  selectedItems: Item[];
  toggleItem: (item: Item) => any;
}

type ChildrenFunction<Item> = (
  options: MultiControllerStateAndHelpers<Item>
) => React.ReactNode;

type DownshiftPropsExcluded<Item> = {
  [K in Exclude<
    keyof DownshiftProps<Item>,
    | 'onChange'
    | 'onSelect'
    | 'selectedItem'
    | 'defaultSelectedItem'
    | 'children'
  >]: DownshiftProps<Item>[K];
};

interface Props<Item> extends Partial<DownshiftPropsExcluded<Item>> {
  children?: ChildrenFunction<Item>;
  selectedItems: Item[];
  onSelect?: (
    selectedItems: Item[],
    stateAndHelpers: ControllerStateAndHelpers<Item>
  ) => void;
  onChange?: (
    selectedItems: Item[],
    stateAndHelpers: ControllerStateAndHelpers<Item>
  ) => void;
}

interface State<Item> {
  selectedItems: Item[];
}

class MultiDownshift<Item> extends React.Component<Props<Item>, State<Item>> {
  stateReducer = (
    state: DownshiftState<Item>,
    changes: StateChangeOptions<Item>
  ): Partial<StateChangeOptions<Item>> => {
    switch (changes.type) {
      // @pbeshai modification: auto select first item when typing so we
      // can just press enter to select it.
      case Downshift.stateChangeTypes.changeInput:
        // note we set highlightIndex to 0 here so we always have the
        // top match selected even if there was a previously selected one
        // (e.g. the user moused over item 5 before typing). If we do not
        // it is possible that the existing index is no longer available
        // due to the list being filtered and so we'd see nothing highlighted.
        if (changes.inputValue !== '') {
          return {
            ...changes,
            highlightedIndex: 0,
          };
        }
        return changes;

      default:
        return changes;
    }
  };

  handleSelection = (
    selectedItem: Item,
    downshift: ControllerStateAndHelpers<Item>
  ) => {
    const { selectedItems } = this.props;

    const callOnChange = (selectedItems: Item[]) => {
      if (this.props.onSelect) {
        this.props.onSelect(selectedItems, this.getStateAndHelpers(downshift));
      }
      if (this.props.onChange) {
        this.props.onChange(selectedItems, this.getStateAndHelpers(downshift));
      }
    };

    // remove
    if (selectedItems.includes(selectedItem)) {
      callOnChange(selectedItems.filter((i) => i !== selectedItem));

      // add
    } else {
      callOnChange([...selectedItems, selectedItem]);
    }
  };

  getRemoveButtonProps = (downshift: ControllerStateAndHelpers<Item>) => {
    return ({ onClick, item, ...props }: RemoveButtonProps<Item> = {}) => {
      return {
        onClick: (evt: React.SyntheticEvent<any>) => {
          // TODO: use something like downshift's composeEventHandlers utility instead
          onClick && onClick(evt);
          evt.stopPropagation();
          if (item) {
            this.handleSelection(item, downshift);
          }
        },
        ...props,
      };
    };
  };

  getStateAndHelpers(downshift: ControllerStateAndHelpers<Item>) {
    const { selectedItems } = this.props;
    return {
      getRemoveButtonProps: this.getRemoveButtonProps(downshift),
      // @pbeshai modification: add removeItem as a prop so we can use it on backspace
      toggleItem: (item: Item) => this.handleSelection(item, downshift),

      selectedItems,
      ...downshift,
    };
  }
  render() {
    const { children, ...props } = this.props;
    // TODO: compose together props (rather than overwriting them) like downshift does
    return (
      <Downshift
        {...props}
        stateReducer={this.stateReducer}
        onChange={this.handleSelection}
        selectedItem={null}
      >
        {(downshift) =>
          children && children(this.getStateAndHelpers(downshift))
        }
      </Downshift>
    );
  }
}

export default MultiDownshift;
