import { selectors as entitiesSelectors } from 'src/redux/entities/entities-selectors';
import { StoreState } from 'src/redux/store';
import { Conversation, Paging } from 'src/types/conversation';

// selectors
export const selectors = {
  isSearchConversationsLoading: (state: StoreState): boolean =>
    state.search.isLoading,
  getSearchConversations: (state: StoreState): Conversation[] =>
    entitiesSelectors.getConversations(state, state.search.conversationIds, {
      nestSnippets: true,
    }),
  getSearchConversationsError: (state: StoreState): Error | undefined =>
    state.search.error,
  getSearchPagingInfo: (state: StoreState): Paging | undefined =>
    state.search.paging,
};
export default selectors;
