import * as React from 'react';
import cx from 'classnames';

import color from 'src/assets/_util.scss';
import {
  default as Transcript,
  Props as TranscriptProps,
} from 'src/components/Transcript/Transcript';
import { useSeekTimeScroll } from './useSeekTimeScroll';

import styles from './ScrollingTranscript.module.scss';

export interface Props extends TranscriptProps {
  handleScrollInterruption?: () => void;
  autoScroll?: boolean;
  linkToConversation?: boolean;
  style?: React.CSSProperties;
  innerStyle?: React.CSSProperties;
}

const ScrollingTranscript = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      snippets,
      seekTime,
      handleScrollInterruption,
      autoScroll = true,
      embedded = true,
      conversation,
      style: styleProp,
      innerStyle,
      ...other
    },
    ref
  ) => {
    const scrollContainerRef = React.useRef<HTMLDivElement>(null);

    useSeekTimeScroll(
      ref ?? scrollContainerRef,
      seekTime,
      handleScrollInterruption,
      autoScroll
    );

    const style = {
      // handle embedded styling here and don't pass through since
      // overflow: hidden hides a shadow and border
      padding: embedded ? '1rem' : 0,
      ...innerStyle,
    };

    return (
      <div
        // note we wrap with a div here so the scrollbar gets rounded edges.
        style={{
          border: `1px solid ${color.gray300}`,
          borderRadius: '5px',
          overflow: embedded ? 'hidden' : 'auto',
          ...styleProp,
        }}
      >
        <div
          tabIndex={0}
          className={cx(styles.scrollingTranscript)}
          style={style}
          ref={ref ?? scrollContainerRef}
        >
          <Transcript
            snippets={snippets}
            seekTime={seekTime}
            embedded={false}
            conversation={conversation}
            {...other}
          />
        </div>
      </div>
    );
  }
);

export default React.memo(ScrollingTranscript);
