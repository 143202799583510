import * as React from 'react';

import Tag from 'src/components/core/Tag/Tag';

interface Props {
  tags: string[];
  className?: string;
  color?: string;
  size?: string;
  pale?: boolean;
}

const TagList = ({ tags, className, size, color, ...other }: Props) => {
  if (!tags) {
    return null;
  }

  return (
    <div className={className}>
      {tags.map((tag) => {
        return (
          <Tag
            key={tag}
            size={size}
            color={color}
            className="me-1 mb-1"
            {...other}
          >
            {tag}
          </Tag>
        );
      })}
    </div>
  );
};

export default React.memo(TagList);
