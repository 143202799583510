import * as React from 'react';
import cx from 'classnames';

import {
  AnnotationCreationSelection,
  Conversation,
  Highlight,
  TranscriptSnippet,
} from 'src/types/conversation';
import { highlightInSnippet, seekInSnippet } from 'src/util/snippets';
import MarginControls from './MarginControls/MarginControls';
import TranscriptParagraph from './TranscriptParagraph/TranscriptParagraph';

import styles from './Transcript.module.scss';

export interface Props {
  snippets: TranscriptSnippet[];
  conversation?: Conversation;
  conversationId?: number;
  seekTime?: number;
  filterWordsStart?: number;
  filterWordsEnd?: number;
  onPlay?: (seekTime?: number, endTime?: number) => void;
  emphasizedTerms?: string[];
  primaryTerms?: string[];
  secondaryTerms?: string[];
  primaryClassName?: string;
  secondaryClassName?: string;
  className?: string;
  embedded?: boolean; // set to true for embedded styling
  annotationCreationSelection?: AnnotationCreationSelection | undefined;
  interactive?: boolean;
  onPersistSelection?: (persistSelection: boolean) => void;
  onClearSelection?: () => void;
  linkToConversation?: boolean;
  hideAdditionalInfo?: boolean; // hide participant name & timestamp
  transcriptRef?: React.Ref<any>;
}

const Transcript = ({
  snippets,
  conversation,
  seekTime,
  onPlay,
  filterWordsEnd,
  filterWordsStart,
  emphasizedTerms,
  primaryTerms,
  secondaryTerms,
  primaryClassName,
  secondaryClassName,
  className,
  embedded = true,
  conversationId,
  annotationCreationSelection,
  interactive,
  onPersistSelection,
  onClearSelection,
  linkToConversation,
  hideAdditionalInfo,
  transcriptRef,
}: Props) => {
  const handlePlay = React.useCallback(
    (seekTime?: number, endTime?: number) => {
      if (onPlay) {
        onPlay(seekTime, endTime);
      }
    },
    [onPlay]
  );

  const [activeHighlight, setActiveHighlight] = React.useState<
    Highlight | undefined
  >(undefined);

  const handleChangeActiveHighlight = React.useCallback(
    (highlight: Highlight | undefined, removeIfMatch?: boolean) => {
      // only remove if the same
      if (removeIfMatch) {
        // remove if match, otherwise ignore
        if (highlight === activeHighlight) {
          setActiveHighlight(undefined);
        }
      } else {
        setActiveHighlight(highlight);
      }
    },
    [activeHighlight, setActiveHighlight]
  );

  // Sort snippets so that they appear approriately in the UI
  const sortedSnippets = React.useMemo(
    () =>
      snippets.sort(
        (a, b) => a.index_in_conversation - b.index_in_conversation
      ),
    [snippets]
  );

  return (
    <div
      className={cx(className, {
        'border p-3 rounded-3 shadow-sm': embedded,
        isCreatingHighlight: !!annotationCreationSelection,
      })}
      ref={transcriptRef}
    >
      {sortedSnippets.map((snippet, i) => {
        // if the next snippet is longer than this many seconds, add a divider
        const maxAdjacentThreshold = 10;

        // don't show speaker if prev snippet is same speaker and we're within no divider range or if not showing additional info
        const prevSnippet = sortedSnippets[i - 1];
        const showSpeaker =
          !hideAdditionalInfo &&
          (!prevSnippet ||
            prevSnippet.speaker_id !== snippet.speaker_id ||
            (prevSnippet.speaker_id === snippet.speaker_id &&
              snippet.audio_start_offset - prevSnippet.audio_end_offset >
                maxAdjacentThreshold));

        const filteredSeekTime = seekInSnippet(seekTime, snippet)
          ? seekTime
          : undefined;

        const { speaker_name: speakerName } = snippet;
        const speakerShortName = speakerName && speakerName[0];

        const removeTextFormatting = (
          event: React.ClipboardEvent<HTMLDivElement>
        ) => {
          const selection = document
            .getSelection()
            ?.toString()
            .split(/\d+:\d+/)
            ?.join('');
          console.log(selection);
          if (selection) {
            event.clipboardData?.setData('text/plain', selection);
          }
          event.preventDefault();
        };
        // only include an annotation creation selection if this snippet
        // contains it
        const filteredAnnotationCreationSelection =
          annotationCreationSelection &&
          highlightInSnippet(snippet, annotationCreationSelection)
            ? annotationCreationSelection
            : undefined;

        const filteredActiveHighlight =
          activeHighlight && highlightInSnippet(snippet, activeHighlight)
            ? activeHighlight
            : undefined;

        return (
          <div
            key={snippet.id}
            className={cx({
              [styles.withMargin]: interactive,
              'mt-3': showSpeaker && i !== 0,
            })}
            onCopy={removeTextFormatting}
          >
            <TranscriptParagraph
              snippet={snippet}
              filterWordsStart={filterWordsStart}
              filterWordsEnd={filterWordsEnd}
              showSpeaker={showSpeaker}
              speakerName={speakerName}
              speakerShortName={speakerShortName}
              onPlay={handlePlay}
              seekTime={filteredSeekTime}
              emphasizedTerms={emphasizedTerms}
              primaryTerms={primaryTerms}
              secondaryTerms={secondaryTerms}
              primaryClassName={primaryClassName}
              secondaryClassName={secondaryClassName}
              annotationCreationSelection={filteredAnnotationCreationSelection}
              activeHighlight={filteredActiveHighlight}
              linkToConversation={linkToConversation}
              hideAdditionalInfo={hideAdditionalInfo}
            />
            {interactive && conversation && (
              <div className={cx(styles.margin, 'd-none d-sm-flex')}>
                <MarginControls
                  conversation={conversation}
                  snippet={snippet}
                  snippets={sortedSnippets}
                  onPlay={onPlay}
                  seekTime={
                    filteredAnnotationCreationSelection ? seekTime : undefined
                  }
                  highlights={snippet.highlights}
                  conversationId={conversationId}
                  annotationCreationSelection={
                    filteredAnnotationCreationSelection
                  }
                  onPersistSelection={onPersistSelection}
                  onClearSelection={onClearSelection}
                  onChangeActiveHighlight={handleChangeActiveHighlight}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
const MemoTranscript = React.memo(Transcript);
(MemoTranscript as any).whyDidYouRender = false;
export default MemoTranscript;
