import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import HeadwayWidget from 'src/components/HeadwayWidget/HeadwayWidget';

import styles from './NotificationBell.module.scss';

const TRIGGER_ID = 'notification-bell';

const NotificationBell = ({ className }: { className?: string }) => {
  return (
    <button
      className={cx(
        styles.container,
        className,
        'd-flex justify-content-center align-items-center border-0'
      )}
      id={TRIGGER_ID}
      aria-label="notifications"
    >
      <FontAwesomeIcon icon="bell" className={styles.bell} />
      <HeadwayWidget trigger={`#${TRIGGER_ID}`} />
    </button>
  );
};

export default NotificationBell;
