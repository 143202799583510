import * as React from 'react';
import Box from '@mui/material/Box';

const NotFoundPage: React.FunctionComponent = () => {
  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="center"
      minHeight="100vh"
    >
      <h1>404 Not Found</h1>
    </Box>
  );
};

NotFoundPage.displayName = 'NotFoundPage';

export default NotFoundPage;
