import { useSelector } from 'react-redux';

import adminSelectors from 'src/redux/admin/admin-selectors';
import authSelectors from 'src/redux/auth/auth-selectors';
import { StoreState } from 'src/redux/store';
import { Role, User } from 'src/types/auth';
import { CRUD } from 'src/types/core';
import { CommunityMember } from 'src/types/forum';
import { CommunityRole } from 'src/types/organization';
import { useUserTransaction } from '../../Common/Hooks/useUserTransaction';
import PillDropdown, { PillDropdownProps } from './PillDropdown';

interface UpdateCommunityRoleDropdownProps {
  member: User | CommunityMember;
  communityId?: number;
}

const UpdateCommunityRoleDropdown = ({
  member,
  communityId,
}: UpdateCommunityRoleDropdownProps) => {
  const iAmCommunityAdmin = useSelector((state: StoreState) =>
    adminSelectors.iAmCommunityAdmin(state, communityId)
  );

  const { startTransaction, transactionLoading } = useUserTransaction();

  const activeCommunity = useSelector(adminSelectors.getActiveCommunity);

  const targetCommunityId = communityId || activeCommunity?.id;

  const roleFilter = (role: Role) => {
    return (
      role.community_id === targetCommunityId &&
      Object.values(CommunityRole)
        .map((role) => role.toLowerCase())
        .includes(role.role_type as CommunityRole)
    );
  };

  const getRolePriority = (role: Role) =>
    Object.values(CommunityRole)
      // remove host role and lowercase the rest
      .map((role) => role.toLowerCase())
      .indexOf(role.role_type as CommunityRole);

  const sortedCommunityRoles =
    member?.roles
      ?.filter(roleFilter)
      ?.sort((a, b) => getRolePriority(a) - getRolePriority(b)) ?? [];

  const communityRole = (sortedCommunityRoles[0]?.role_type ||
    'None') as CommunityRole;

  const isCommunityMember = communityRole.toLowerCase() !== 'none';

  const isCommunityOwner =
    communityRole.toLowerCase() === CommunityRole.owner.toLowerCase();

  const disabled = isCommunityOwner || !iAmCommunityAdmin || !isCommunityMember;

  const handleAccessLevelChange = (communityRole: CommunityRole) => {
    startTransaction({
      type: 'update_user_community_role',
      crud: CRUD.put,
      data: {
        id: member.id,
        communityId: targetCommunityId,
        roles: [
          {
            role_type: communityRole.toLowerCase(),
          },
        ],
      },
    });
  };

  const testId = `member-${member.id}-access-level`;

  const viewProps: CommunityRoleDropdownViewProps = {
    communityRole,
    testId,
    disabled,
    loading: transactionLoading,
    onSelect: handleAccessLevelChange,
  };

  return <CommunityRoleDropdown {...viewProps} />;
};

type CommunityRoleDropdownViewProps = {
  communityRole: CommunityRole | 'None';
  onSelect: (role: CommunityRole) => void;
  testId?: string;
  loading?: boolean;
  disabled?: boolean;
};

export const CommunityRoleDropdown = ({
  communityRole,
  testId,
  loading,
  disabled,
  onSelect,
}: CommunityRoleDropdownViewProps) => {
  const user = useSelector(authSelectors.getUser);
  const USER_MANAGEMENT_FLAG = user.flags?.user_management_changes;

  const props: PillDropdownProps = {
    dropdownItems: [
      {
        value: CommunityRole.owner,
        hide: true,
      },
      {
        value: CommunityRole.admin,
        hide: false,
      },
      {
        value: CommunityRole.sensemaker,
        hide: USER_MANAGEMENT_FLAG ? true : false,
      },
      {
        value: CommunityRole.host,
        hide: false,
      },
      {
        value: CommunityRole.member,
        hide: false,
      },
      {
        value: 'None',
        hide: communityRole.toLowerCase() !== 'none',
      },
    ],
    selectedValue: communityRole,
    onSelect: onSelect as (val: string) => void,
    loading,
    disabled,
    testId,
  };

  return <PillDropdown {...props} />;
};

export default UpdateCommunityRoleDropdown;
