import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { ScaleLinear } from 'd3';

import { Highlight } from 'src/types/conversation';

import styles from './HighlightsBar.module.scss';

interface Props {
  width: number;
  height: number;
  onPlay: (seekTime?: number) => void;
  timeScale: ScaleLinear<number, number>;
  nameWidth: number;
  highlights: Highlight[];
  onViewHighlights: () => void;
}

const HighlightsBar = ({
  width = 800,
  height = 24,
  onPlay,
  timeScale,
  nameWidth = 100,
  highlights,
  onViewHighlights,
}: Props) => {
  const { t } = useTranslation();
  const handleClickHighlight = (highlight: Highlight) => {
    onPlay(highlight.audio_start_offset);
  };

  const handleViewHighlights = () => {
    onViewHighlights();
  };

  const barHeight = height * 0.8;
  const iconWidth = barHeight * 0.7;
  const iconMargin = Math.floor((height - iconWidth) / 2);
  const iconHeight = iconWidth;

  return (
    <g>
      <g
        className={styles.highlightsBarLabelG}
        transform={`translate(${-nameWidth} 0 )`}
      >
        <rect
          x={0}
          width={nameWidth}
          height={barHeight}
          className={styles.highlightsBarLabelBg}
          fillOpacity={0}
          onClick={handleViewHighlights}
        />
        <text className={styles.highlightsBarLabel} dy="1em">
          {t('highlights.header')}
        </text>
      </g>

      <rect className={styles.bgRect} height={height} width={width} />
      {highlights.map((highlight) => {
        const x = timeScale(highlight.audio_start_offset);
        return (
          <g
            key={highlight.id}
            className={styles.highlightG}
            transform={`translate(${x} 0)`}
            onClick={() => handleClickHighlight(highlight)}
          >
            <circle
              className={styles.highlightIconCircle}
              r={barHeight / 2}
              cy={height / 2}
              fill="black"
              fillOpacity={0.0}
            />
            {highlight.annotation_type === 'highlight_curated' ? (
              <path
                className={cx(
                  styles.highlightIcon,
                  styles[`highlightIcon_${highlight.annotation_type}`]
                )}
                transform={`translate(${-iconWidth / 2} ${iconMargin}) scale(${
                  iconWidth / 512
                })`}
                d="M471.99 334.43L336.06 256l135.93-78.43c7.66-4.42 10.28-14.2 5.86-21.86l-32.02-55.43c-4.42-7.65-14.21-10.28-21.87-5.86l-135.93 78.43V16c0-8.84-7.17-16-16.01-16h-64.04c-8.84 0-16.01 7.16-16.01 16v156.86L56.04 94.43c-7.66-4.42-17.45-1.79-21.87 5.86L2.15 155.71c-4.42 7.65-1.8 17.44 5.86 21.86L143.94 256 8.01 334.43c-7.66 4.42-10.28 14.21-5.86 21.86l32.02 55.43c4.42 7.65 14.21 10.27 21.87 5.86l135.93-78.43V496c0 8.84 7.17 16 16.01 16h64.04c8.84 0 16.01-7.16 16.01-16V339.14l135.93 78.43c7.66 4.42 17.45 1.8 21.87-5.86l32.02-55.43c4.42-7.65 1.8-17.43-5.86-21.85z"
              />
            ) : (
              <path
                className={cx(
                  styles.highlightIcon,
                  styles[`highlightIcon_${highlight.annotation_type}`]
                )}
                transform={`translate(0 ${iconMargin})`}
                d={`M0,0 L${iconWidth / 2},${
                  iconHeight / 2
                } L0,${iconHeight} L${-iconWidth / 2},${iconHeight / 2}Z`}
              />
            )}
          </g>
        );
      })}
    </g>
  );
};

export default HighlightsBar;
