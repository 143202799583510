import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

interface BaseProps {
  closeDialog: () => void;
  confirmationText?: string;
  description: React.ReactNode;
  onSuccess: () => void;
  open: boolean;
  submitText: string;
  title: string;
}

type Props = BaseProps;

const ConfirmationDialog: React.FunctionComponent<Props> = (props: Props) => {
  const {
    closeDialog,
    confirmationText,
    onSuccess,
    open,
    description,
    submitText,
    title,
  } = props;
  const [confirmation, setConfirmation] = React.useState('');
  const [confirmationError, setConfirmationError] = React.useState(false);
  const { t } = useTranslation();

  const handleSubmit = () => {
    if (confirmationText !== undefined && confirmation !== confirmationText) {
      setConfirmationError(true);
      return;
    }
    closeDialog();
    onSuccess();
  };

  React.useEffect(() => {
    // Reset on open
    setConfirmation('');
    setConfirmationError(false);
  }, [open]);

  return (
    <Dialog
      data-testid="ConfirmationDialog"
      disableRestoreFocus
      onClose={closeDialog}
      open={open}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
        {confirmationText && (
          <TextField
            autoFocus
            error={confirmationError}
            fullWidth
            label={t('insights.delete_confirmation')}
            margin="dense"
            onChange={(event) => {
              setConfirmation(event.target.value);
              if (confirmation.length !== 0) setConfirmationError(false);
            }}
            sx={{ mt: 3 }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>{t('common.cancel')}</Button>
        <Button onClick={handleSubmit}>{submitText}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
