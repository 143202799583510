import * as React from 'react';

interface Props {
  size?: 'sm' | 'md' | 'lg' | number | undefined;
  active: boolean;
  frontColor: string;
  backColor: string;
  thick: boolean;

  /** if light, uses rgba 255, dark uses rgba 0. if specified, ignores frontColor/backColor */
  theme?: 'light' | 'dark' | undefined;
}

class LoadingSpinner extends React.Component<Props> {
  static defaultProps = {
    active: false,
    frontColor: '#606f7b',
    backColor: '#dfe6ec',
    thick: false,
  };

  render() {
    const { active, size, thick, theme } = this.props;
    let { frontColor, backColor } = this.props;

    if (!active) {
      return null;
    }

    if (theme === 'light') {
      frontColor = 'rgba(255, 255, 255, 0.8)';
      backColor = 'rgba(255, 255, 255, 0.35)';
    } else if (theme === 'dark') {
      frontColor = 'rgba(0, 0, 0, 0.6)';
      backColor = 'rgba(0, 0, 0, 0.2)';
    }

    const strokeWidth = thick ? 4 : 1.5;
    let width: number | string = 24;
    if (typeof size === 'number') {
      width = size;
    } else if (size === 'sm') {
      width = 16;
    } else if (size === 'md') {
      width = 24;
    } else if (size === 'lg') {
      width = 48;
    } else {
      width = '1em';
    }

    const height = width;

    // circumference: 2*pi*r
    const r = 12 - strokeWidth; // 12 - 2 for the width of the stroke
    const circumference = 2 * Math.PI * r;
    const opening = 0.7;
    const dashArray = `${circumference * (1 - opening)} ${
      circumference * opening
    }`;

    return (
      <svg
        viewBox="0 0 24 24"
        width={width}
        height={height}
        className="is-spinning"
      >
        <g transform="translate(12 12)">
          <circle
            r={r}
            stroke={backColor}
            strokeWidth={strokeWidth}
            fill="none"
          />
          <circle
            r={r}
            stroke={frontColor}
            strokeWidth={strokeWidth}
            strokeDasharray={dashArray}
            fill="none"
          />
        </g>
      </svg>
    );
  }
}

export default LoadingSpinner;
