import * as React from 'react';
import { Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import Button from 'src/components/core/Button/Button';

import styles from './SideFilter.module.scss';

interface Props {
  title: string;
  children: React.ReactNode;
  collapsible?: boolean;
  open?: boolean;
  disabled?: boolean;
}

const SideFilter = ({
  title,
  children,
  open,
  disabled,
  collapsible = true,
}: Props) => {
  const [isOpen, setIsOpen] = React.useState(open);
  const toggle = () => setIsOpen(!isOpen);
  const container = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (container) {
      // This is needed because an unwanted scroll jump when the screen reloads
      const scrollAdjustment = () => {
        container.current?.scrollTo(0, 0);
      };
      container.current?.scrollTo(0, 0);
      window.addEventListener('click', scrollAdjustment);

      return () => {
        window.removeEventListener('click', scrollAdjustment);
      };
    }
  }, [container]);
  return (
    <div
      className={cx(styles.container, 'rounded card border mb-2')}
      data-testid={`${title}-side-filter`}
      ref={container}
    >
      {collapsible ? (
        <>
          <Button
            color="white"
            onClick={toggle}
            disabled={disabled}
            className={cx(
              styles.filterToggle,
              'py-2 fwmedium w-100 justify-content-between shadow-none'
            )}
            data-testid={`${title}-filter-toggle`}
          >
            {title}
            {!disabled && (
              <FontAwesomeIcon
                icon={['far', isOpen ? 'chevron-up' : 'chevron-down']}
                className="float-end"
              />
            )}
          </Button>
          <Collapse className={styles.collapse} isOpen={isOpen}>
            {children}
          </Collapse>
        </>
      ) : (
        <>
          <div
            className={cx(
              // match styling of button version of title
              'py-2 px-3 fwmedium'
            )}
          >
            {title}
          </div>
          {children}
        </>
      )}
    </div>
  );
};

export default React.memo(SideFilter);
