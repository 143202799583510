import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import { Alert } from 'reactstrap';

import DraftConversationTable from 'src/components/AdminPage/DraftConversations/DraftConversationTable/DraftConversationTable';
import LoadingOverlay from 'src/components/core/LoadingOverlay/LoadingOverlay';
import adminSelectors from 'src/redux/admin/admin-selectors';
import conversationSelectors from 'src/redux/conversations/conversations-selectors';
import { loadDraftConversations } from 'src/redux/conversations/conversations-slice';
import { StoreState } from 'src/redux/store';
import { Organization } from 'src/types/auth';
import { Conversation } from 'src/types/conversation';

interface StateProps {
  conversations: Conversation[];
  isLoading: boolean;
  conversationLoadError: Error | undefined;
  activeOrganization: Organization | undefined;
}

type Props = StateProps & DispatchProp;

const mapStateToProps = (state: StoreState): StateProps => ({
  conversations: conversationSelectors.getDraftConversations(state),
  isLoading: conversationSelectors.isDraftConversationsLoading(state),
  conversationLoadError:
    conversationSelectors.getDraftConversationsError(state),
  activeOrganization: adminSelectors.getActiveOrganization(state),
});

const DraftConversations = ({
  dispatch,
  conversations,
  isLoading,
  conversationLoadError,
  activeOrganization,
}: Props) => {
  const { t } = useTranslation();
  // load conversations
  React.useEffect(() => {
    dispatch(
      loadDraftConversations({
        isDraft: true,
        orderByPostTime: true,
        organizationIds: activeOrganization
          ? [activeOrganization.id.toString()]
          : undefined,
      })
    );
  }, [dispatch, activeOrganization]);

  if (isLoading) {
    return <LoadingOverlay active={isLoading} bgColor="transparent" />;
  }

  return (
    <>
      {conversationLoadError ? (
        <Alert color="danger">
          {t('admin.draft_error')}
          {conversationLoadError.message}
        </Alert>
      ) : (
        <DraftConversationTable
          conversations={conversations}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default connect(mapStateToProps)(DraftConversations);
