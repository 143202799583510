import * as React from 'react';
import { ArrayParam, NumberParam, useQueryParams } from 'use-query-params';

import {
  getActiveCollections,
  handleCollectionChange,
} from 'src/components/card-filters/CollectionFilter';
import FilterEmptyState from 'src/components/card-filters/FilterEmptyState';
import {
  getActivePrivacy,
  handlePrivacyChange,
} from 'src/components/card-filters/PrivacyFilter';
import {
  getActiveTags,
  handleTagChange,
} from 'src/components/card-filters/TagFilter';
import FilterTags from 'src/components/FilterTags/FilterTags';
import HighlightCard from 'src/components/HighlightCard/HighlightCard';
import styles from 'src/components/HighlightsRoute/HighlightsRoute.module.scss';
import PagingControl from 'src/components/PagingControl/PagingControl';
import PagingSummary from 'src/components/PagingSummary/PagingSummary';
import { Highlight, Paging } from 'src/types/conversation';
import { HighlightsFilterOptions } from 'src/types/core';
import { makePagingHandlers } from 'src/util/paging';

interface BaseProps {
  currentPage: number;
  highlights: Highlight[];
  pagingInfo: Paging | undefined;
  filterOptions: HighlightsFilterOptions | undefined;
  emphasizedTerms?: string[];
  reloadTab?: () => void;
  noneFoundMsg: React.ReactNode;
  isLoading: boolean;
  noneExistMsg?: React.ReactNode;
}

type Props = BaseProps;

const PAGE_SIZE = 10;

const HighlightsTabPane = ({
  currentPage,
  highlights,
  pagingInfo,
  emphasizedTerms,
  filterOptions,
  reloadTab,
  isLoading,
  noneFoundMsg,
  noneExistMsg,
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    c: ArrayParam,
    tags: ArrayParam,
    priv: ArrayParam,
  });
  const { c: collectionIds, tags, priv: privacyLevels } = query;

  const { handleNextPage, handlePrevPage, handleSeekPage } = makePagingHandlers(
    currentPage,
    PAGE_SIZE,
    pagingInfo ? pagingInfo.total : 0,
    (page: number) => setQuery({ page }, 'pushIn')
  );

  const clearAllFilters = () => {
    setQuery({
      c: undefined,
      tags: undefined,
      priv: undefined,
    });
  };

  const activeCollections = getActiveCollections(collectionIds, filterOptions);
  const activeTags = getActiveTags(tags, filterOptions);
  const activePrivacy = getActivePrivacy(privacyLevels, filterOptions);

  if (isLoading) {
    return null;
  }

  if (highlights.length === 0) {
    const filtersActive =
      collectionIds != null || tags != null || privacyLevels != null;

    return (
      <FilterEmptyState
        onClear={clearAllFilters}
        filtersActive={filtersActive}
        title={noneFoundMsg}
        noneExistMsg={noneExistMsg}
        className="mt-3"
      />
    );
  }

  return (
    <div
      className={styles.highlightCardsContainer}
      data-testid="highlight-tab-pane"
    >
      <>
        <PagingSummary
          className="mb-1 text-gray-700"
          itemsPerPage={PAGE_SIZE}
          pagingInfo={pagingInfo}
          i18NextKey="highlights.showing"
          i18NextRangeKey="highlights.showing_range"
        />
        <div className="mb-1">
          <FilterTags
            activeFilterOptions={activeCollections}
            onClear={(option) =>
              handleCollectionChange(option, collectionIds, setQuery)
            }
          />
          <FilterTags
            activeFilterOptions={activeTags}
            onClear={(option) => handleTagChange(option, tags, setQuery)}
          />
          <FilterTags
            activeFilterOptions={activePrivacy}
            onClear={(option) =>
              handlePrivacyChange(option, privacyLevels, setQuery)
            }
          />
        </div>
      </>
      {highlights.map((highlight) => (
        <HighlightCard
          key={highlight.id}
          className="mb-4"
          highlight={highlight}
          emphasizedTerms={emphasizedTerms}
          onDelete={reloadTab}
        />
      ))}
      <PagingControl
        className={'mb-5 text-center'}
        itemsPerPage={PAGE_SIZE}
        pagingInfo={pagingInfo}
        onNext={handleNextPage}
        onPrevious={handlePrevPage}
        onSeek={handleSeekPage}
      />
    </div>
  );
};

export default HighlightsTabPane;
