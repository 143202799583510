import * as React from 'react';
import { useTranslation } from 'react-i18next';
import xor from 'lodash.xor';
import {
  ArrayParam,
  QueryParamConfig,
  SetQuery,
  useQueryParams,
} from 'use-query-params';

import { ConversationsFilterOptions, FilterOption } from 'src/types/core';
import FilterChecklist from '../FilterChecklist/FilterChecklist';
import SideFilter from '../SideFilter/SideFilter';

export const getActiveOrganizations = (
  organizationIds: string[] | undefined,
  filterOptions: ConversationsFilterOptions | undefined
) => {
  return (
    organizationIds &&
    filterOptions &&
    filterOptions.organizations &&
    organizationIds.map((id) =>
      filterOptions.organizations.find((org) => String(org.id) === id)
    )
  );
};

export const handleOrganizationChange = (
  option: FilterOption,
  organizationIds: string[] | undefined,
  setQuery: SetQuery<{
    org: QueryParamConfig<string[] | undefined>;
    page: QueryParamConfig<number | undefined>;
  }>
) => {
  const newOrgIds = xor(organizationIds, [String(option.id)]);
  setQuery({
    org: newOrgIds,
    page: 1,
  });
};

interface Props {
  filterOptions: ConversationsFilterOptions | undefined;
}

const PartnerFilter = ({ filterOptions }: Props) => {
  const [query, setQuery] = useQueryParams({
    org: ArrayParam,
  });
  const { t } = useTranslation();
  const { org: organizationIds } = query;
  return (
    <SideFilter
      title={t('conversations.filter_partners')}
      open={Boolean(organizationIds)}
      disabled={!(filterOptions && filterOptions.organizations.length)}
    >
      {filterOptions && filterOptions.organizations && (
        <FilterChecklist
          options={filterOptions.organizations}
          onChange={(option) => {
            handleOrganizationChange(option, organizationIds, setQuery);
          }}
          activeFilterOptions={getActiveOrganizations(
            organizationIds,
            filterOptions
          )}
          title={t('conversations.filter_partners')}
          fade
        />
      )}
    </SideFilter>
  );
};

export default PartnerFilter;
