/**
 * Custom hook for handling paging
 *
 * @param currentPage Current page number
 * @param pageSize Expected page size for a full page
 * @param totalItems Total number of items in the dataset
 * @param setCurrentPage Callback to set the current page
 * @param onPageChange Callback called when page changes
 */
export const makePagingHandlers = (
  currentPage: number,
  pageSize: number,
  totalItems: number,
  setCurrentPage: (
    currPage: number,
    updateType?: 'replace' | 'replaceIn' | 'push' | 'pushIn'
  ) => void,
  onPageChange?: () => void
) => {
  const numPages = Math.ceil(totalItems / pageSize);

  const handlePageChange = () => {
    if (onPageChange) {
      onPageChange();
    }
  };

  const handleSeekPage = (
    seekToPage: number,
    updateType: 'replace' | 'replaceIn' | 'push' | 'pushIn' = 'pushIn'
  ) => {
    if (seekToPage > 0 && seekToPage <= numPages) {
      setCurrentPage(seekToPage, updateType);
      handlePageChange();
    }
  };

  const handleNextPage = () => {
    handleSeekPage(currentPage + 1);
    handlePageChange();
  };

  const handlePrevPage = () => {
    handleSeekPage(currentPage - 1);
    handlePageChange();
  };

  return {
    handleSeekPage,
    handleNextPage,
    handlePrevPage,
  };
};
