import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import ExpandingButton from 'src/components/core/ExpandingButton/ExpandingButton';
import ExpandingTranscript from 'src/components/ExpandingTranscript/ExpandingTranscript';
import PlayButton from 'src/components/PlayButton/PlayButton';
import GlobalAudioContext, {
  useSeekAudio,
} from 'src/contexts/GlobalAudioContext';
import { TopicSnippet } from 'src/types/conversation';
import dateFormatter from 'src/util/date';

import styles from './SnippetCard.module.scss';

interface Props {
  snippet: TopicSnippet;
  emphasizedTerms?: string[];
  primaryTerms?: string[];
  secondaryTerms?: string[];
  primaryTopicClassName?: string;
  secondaryTopicClassName?: string;
}

const SnippetCard = ({
  snippet,
  emphasizedTerms,
  primaryTerms,
  secondaryTerms,
  primaryTopicClassName,
  secondaryTopicClassName,
}: Props) => {
  const { t } = useTranslation();
  const { speaker_name, audio_start_offset, audio_end_offset, conversation } =
    snippet;
  const {
    location,
    id: conversationId,
    audio_url,
    title: conversationTitle,
  } = conversation;

  const { isLoading, audioError, isPlaying } =
    React.useContext(GlobalAudioContext);

  const {
    isActive,
    seekTime,
    toggleActivatedPlaying,
    activatedPlay,
    hasEnded,
  } = useSeekAudio({
    audioUrl: audio_url,
    startTime: audio_start_offset,
    endTime: audio_end_offset,
    meta: conversation,
    id: `snippet-${snippet.id}`,
  });

  const playing = isActive && isPlaying;

  const handlePlayBtnClick = () => {
    if (!playing && !expanded) {
      setExpanded(true);
    }
    // toggles play/pause
    toggleActivatedPlaying();
  };

  const handleTranscriptClick = (seekTime?: number) => {
    if (!playing && !expanded) {
      setExpanded(true);
    }
    // just sets play, allowing users to click another part of the transcript and audio continues to play
    // begins play at selected transcript time, ends at snippet end
    activatedPlay(seekTime, audio_end_offset);
  };

  // expanding transcript state
  const [expanded, setExpanded] = React.useState(false);
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  React.useEffect(() => {
    // collapse when snippet is done playing
    if (hasEnded) {
      setExpanded(false);
    }
  }, [hasEnded]);

  const conversationPath = `/conversation/${conversationId}?t=${audio_start_offset}`;
  let nameAndLocation = <span className="fwbold">{speaker_name}</span>;

  if (location.name && location.name !== 'Unknown') {
    nameAndLocation = (
      <>
        {nameAndLocation} {` in ${location.name} `}
      </>
    );
  }

  return (
    <div
      className={cx('d-flex border-top p-4 bg-white', {
        [styles.playing]: playing,
      })}
    >
      <div className="me-3">
        <PlayButton
          shape="circle"
          size="lg"
          isPlaying={playing}
          isLoading={isActive && isLoading}
          isError={isActive && audioError != null}
          onClick={handlePlayBtnClick}
          className="me-2"
        />
      </div>
      <div className="me-3 w-100">
        <p className={cx('mb-1', styles.metadata)}>
          {nameAndLocation}
          {` • ${dateFormatter.relativeAbsDateFormat(conversation.time)} • `}
          <Link to={conversationPath} className="underline">
            {conversationTitle}
          </Link>
        </p>
        <ExpandingTranscript
          snippets={[snippet]}
          seekTime={isActive ? seekTime : undefined}
          emphasizedTerms={emphasizedTerms}
          primaryTerms={primaryTerms}
          secondaryTerms={secondaryTerms}
          primaryClassName={cx(styles.term, primaryTopicClassName)}
          secondaryClassName={cx(styles.term, secondaryTopicClassName)}
          onPlay={handleTranscriptClick}
          expanded={expanded}
          toggleExpanded={toggleExpanded}
        />
      </div>
      <div
        className={cx(
          'd-flex justify-content-center align-items-center',
          styles.conversationBtnContainer
        )}
      >
        <ExpandingButton
          tag={Link}
          // open up in a new tab
          target="_blank"
          rel="noopener noreferrer"
          icon={['fas', 'arrow-right']}
          title={t('conversations.go_to')}
          to={conversationPath}
          size="lg"
          className={styles.conversationBtn}
          data-testid="go-to-convo-btn"
        >
          {t('conversations.go_to')}
        </ExpandingButton>
      </div>
    </div>
  );
};

export default React.memo(SnippetCard);
