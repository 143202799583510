import * as React from 'react';
import cx from 'classnames';

import Button, {
  Props as ButtonProps,
} from 'src/components/core/Button/Button';

import styles from './FormButton.module.scss';

interface FormButtonProps extends ButtonProps {
  outline?: boolean;
  children?: React.ReactNode;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void | undefined;
}

// Button component styled for use in the registration form
const FormButton = ({
  outline = false,
  children,
  className,
  ...rest
}: FormButtonProps) => (
  <Button
    {...rest}
    color="primary"
    outline={outline}
    className={cx(className, styles.formButton)}
  >
    {children}
  </Button>
);

export default FormButton;
