import * as React from 'react';
import { ViewportProps } from 'react-map-gl';
import distance from '@turf/distance';
import { Units } from '@turf/helpers';
import WebMercatorViewport from 'viewport-mercator-project';

import LoadingOverlay from 'src/components/core/LoadingOverlay/LoadingOverlay';
import { mapbox } from 'src/config';
import { DEFAULT_LONG_LAT } from 'src/constants';

const { mapStyles } = mapbox;

// react-map-gl is pretty big, so we use code splitting to only load it when needed
const ReactMapGL = React.lazy(() => import('react-map-gl')) as any;
const Suspense = React.Suspense;

interface Props {
  initialLngLat?: [number, number];
  width: number;
  height: number;
  initialZoom?: number;
  initialBearing?: number;
  initialPitch?: number;
  mapStyle?: string | Record<string, unknown>;
  children?: (mapChildProps: MapChildProps) => void;
  minZoom?: number;
  maxZoom?: number;
  limitDistance?: number;
  limitDistanceUnits?: Units | undefined;
  fixed?: boolean;
  className?: string | undefined;
  mapRef?: React.RefObject<any>;
  onViewportChange?: (viewport: ViewportProps) => void;
  viewport?: ViewportProps;
  dragPan?: boolean;
  bounds?: [[number, number], [number, number]];
  scrollZoom?: boolean;
  doubleClickZoom?: boolean;
}

export interface MapChildProps extends ViewportProps {
  onViewportChange: (viewport: ViewportProps) => void;
}

interface State {
  // TODO: we don't supply all the values that are not required. we should figure out
  // what values work well and set those as defaults.
  // then we should fix all the casting to ViewportProps below
  viewport: ViewportProps;
}

const Map: React.FC<Props> = ({
  initialLngLat = DEFAULT_LONG_LAT,
  width,
  height,
  initialZoom = 14,
  initialBearing = 0,
  initialPitch = 0,
  mapStyle = mapStyles.lvnLighter,
  children,
  minZoom = 0,
  maxZoom = 20,
  limitDistance,
  limitDistanceUnits = 'miles',
  fixed = false,
  bounds,
  className,
  mapRef,
  onViewportChange,
  viewport,
  dragPan = true,
  scrollZoom = true,
  doubleClickZoom = true,
}) => {
  React.useEffect(() => {
    const initialState = {
      longitude: initialLngLat[0],
      latitude: initialLngLat[1],
      zoom: initialZoom,
      bearing: initialBearing,
      pitch: initialPitch,
    };
    // check that bounds exist and that our width/height are not 0 to avoid fitBounds assertion errors
    // the autosizer might make these 0 while switching between collections (maybe only during tests?)
    if (bounds && width > 0 && height > 0) {
      const newViewport = new WebMercatorViewport({
        width,
        height,
      });

      // calculate an approximate padding to use. this basically tries to use more padding
      // as the width area of conversations gets bigger. this won't work for everything, but seems
      // to be reasonable for our data right now. if we wanted it to work more widely, we'd
      // have to do more math since the earth isn't flat and our pitch isn't 0 by default
      const lngDiff = bounds[1][0] - bounds[0][0];
      let padding = 20;
      if (lngDiff < 1) {
        padding = 100 * lngDiff;
      }
      // fitBounds gives us a lnglat + zoom combo that will fit our points
      // note: fitBounds doesn't currently take bearing/pitch, so deconstruct and make
      // our own viewport object https://github.com/mapbox/mapbox-gl-js/issues/1338
      const boundedViewport = newViewport.fitBounds(bounds, {
        padding,
      });
      // only use bounded viewport if zoom is less than the default initial zoom
      // (in the case of only a few conversations we don't want to be super zoomed in)
      if (boundedViewport.zoom < initialZoom) {
        setState({
          viewport: {
            longitude: boundedViewport.longitude,
            latitude: boundedViewport.latitude,
            zoom: boundedViewport.zoom,
            bearing: initialBearing,
            pitch: initialPitch,
          } as ViewportProps,
        });
      } else {
        // in the case of going from a collection where we used the custom zoom
        // to one where we have to use the default zoom
        setState({
          viewport: initialState as ViewportProps,
        });
      }
    } else {
      // this is used when switching between collections and the new bounds aren't ready yet
      // we at least move the map to the right-ish area based on the collection lnglat
      setState({ viewport: initialState as ViewportProps });
    }
  }, [
    bounds,
    height,
    width,
    initialBearing,
    initialPitch,
    initialZoom,
    initialLngLat,
  ]);

  const [state, setState] = React.useState<State>({
    viewport: {
      longitude: initialLngLat[0],
      latitude: initialLngLat[1],
      zoom: initialZoom,
      bearing: initialBearing,
      pitch: initialPitch,
    } as ViewportProps,
  });

  // disable panning on small maps, makes mobile work better
  dragPan = dragPan && width > 500;

  viewport = viewport || state.viewport;

  // if we are fixed, don't get the viewport from the state
  if (fixed) {
    viewport = {
      longitude: initialLngLat[0],
      latitude: initialLngLat[1],
      zoom: initialZoom,
    } as ViewportProps;
  }

  // use viewport/onViewportChange from props for components
  // that need access to the viewport outside of the map component
  // otherwise use default viewport/onViewportChange fns
  if (!onViewportChange) {
    onViewportChange = (viewport: ViewportProps) => {
      const newViewport = { ...viewport, ...viewport };

      // don't update viewport if beyond the distance limit (if provided)
      if (limitDistance && initialLngLat && initialLngLat.length === 2) {
        const dist = distance(
          initialLngLat,
          [viewport.longitude, viewport.latitude],
          { units: limitDistanceUnits }
        );
        if (dist > limitDistance) {
          newViewport.longitude = viewport.longitude;
          newViewport.latitude = viewport.latitude;
        }
      }

      setState({
        ...state,
        viewport: newViewport,
      });
    };
  }

  const getCursor = () => (fixed || !dragPan ? 'default' : 'grab');

  return (
    <Suspense
      fallback={
        <div
          style={{
            width: `${width}px`,
            height: `${height}px`,
            position: 'relative',
          }}
          data-testid="suspended-loader"
        >
          <LoadingOverlay active />
        </div>
      }
    >
      <ReactMapGL
        {...viewport}
        onViewportChange={
          fixed
            ? () => {
                return;
              }
            : onViewportChange
        }
        width={width}
        height={height}
        minZoom={minZoom}
        maxZoom={maxZoom}
        mapboxApiAccessToken={mapbox.accessToken}
        mapStyle={mapStyle}
        className={className}
        getCursor={getCursor}
        dragPan={dragPan}
        touchAction="pan-y"
        ref={mapRef}
        scrollZoom={scrollZoom}
        doubleClickZoom={doubleClickZoom}
        tabIndex={-1}
      >
        {children
          ? children({
              ...viewport,
              onViewportChange,
            })
          : null}
      </ReactMapGL>
    </Suspense>
  );
};

export default Map;
