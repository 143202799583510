import * as React from 'react';
import cx from 'classnames';

import { CrossPollinatedConversation } from 'src/types/conversation';
import CrossPollinatedConversationListElement from './CrossPollinatedConversationListElement';

import styles from './CrossPollinatedConversationList.module.scss';

interface Props {
  numberOfCrossPollinations: number;
  crossPollinatedConversations: CrossPollinatedConversation[];
}

const CrossPollinatedConversationList = ({
  numberOfCrossPollinations,
  crossPollinatedConversations,
}: Props) => {
  return (
    <ul className="list-unstyled mb-0">
      <li
        className={cx(
          'd-flex align-items-baseline pt-2 px-3',
          styles.menuOption
        )}
      >
        <span className={styles.title}>
          This highlight was played in {numberOfCrossPollinations} other
          conversation{numberOfCrossPollinations === 1 ? '' : 's'}
        </span>{' '}
      </li>
      {crossPollinatedConversations.map((xpol) => {
        return (
          <CrossPollinatedConversationListElement
            xpol={xpol}
            key={xpol.conversation_id}
          />
        );
      })}
      {numberOfCrossPollinations > crossPollinatedConversations.length && (
        <li
          className={cx(
            'd-flex align-items-baseline py-2 px-3',
            styles.menuOption
          )}
        >
          <span className={styles.subtitle}>
            {crossPollinatedConversations.length > 0 ? 'and ' : ''}
            {numberOfCrossPollinations -
              crossPollinatedConversations.length}{' '}
            conversations from other chapters
          </span>{' '}
        </li>
      )}
    </ul>
  );
};

export default CrossPollinatedConversationList;
