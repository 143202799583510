import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import Button from 'src/components/core/Button/Button';
import LoadingSpinner from 'src/components/core/LoadingSpinner/LoadingSpinner';
import SearchInput from 'src/components/SearchInput/SearchInput';
import adminSelectors from 'src/redux/admin/admin-selectors';
import { getUsers } from 'src/redux/admin/admin-slice';
import { User } from 'src/types/auth';
import { sortUsers } from 'src/util/user';
import { TeamPageName } from '../ManageTeam';
import OrganizationRoleTooltip from '../Tooltips/OrganizationRoleTooltip';
import TeamMemberTable from './TeamMemberTable/TeamMemberTable';

import styles from './TeamList.module.scss';

const matchMember = (query: string, member: User) => {
  // Join the queries to match on (note, first last position is important)
  return `${member.first_name} ${member.last_name} ${member.email}`
    .toLowerCase() // Make case insensitive
    .includes(query.toLowerCase());
};

const TeamList = () => {
  const dispatch = useDispatch();
  const members = useSelector(adminSelectors.getUsers);
  const error = useSelector(adminSelectors.getUsersError);
  const organizationId = useSelector(adminSelectors.getOrganizationId);
  const isLoadingUsers = useSelector(adminSelectors.isLoadingUsers);
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = React.useState<string>('');

  const [, setQuery] = useQueryParams({
    page: StringParam,
    userId: NumberParam,
  });

  const onPageChange = (page: TeamPageName) => {
    setQuery({ page, userId: undefined }, 'pushIn');
  };

  React.useEffect(() => {
    if (!members.length) {
      // Users don't exist in redux - prevents abundant api calls
      dispatch(getUsers({ userType: 'members', organizationId }));
    }
  }, [dispatch, organizationId, members]);

  React.useEffect(() => {
    dispatch(getUsers({ userType: 'members', organizationId }));
  }, [dispatch, organizationId]);

  const isLoading = (!members.length && !error) || isLoadingUsers;

  const filteredMembers = searchQuery
    ? members.filter((m) => matchMember(searchQuery, m))
    : members;

  const sortedMembers = [...filteredMembers].sort(sortUsers);

  return (
    <div className={styles.fixedWidth}>
      {isLoading ? (
        <div className="w-100 text-center">
          <LoadingSpinner thick size="lg" active theme="dark" />
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-between mb-4 flex-wrap">
            <div className="d-flex flex-column">
              <div className="d-flex flex-row">
                <span>{t('admin.tab_team_list_helper_1')}</span>
                <OrganizationRoleTooltip width="300px" />
              </div>
              <span>
                {t('admin.tab_team_list_helper_2', { count: members.length })}
              </span>
            </div>
            <div className="flex-row d-flex flex-wrap">
              <div style={{ width: '350px', margin: 'auto' }}>
                <SearchInput
                  initialSearchQuery={searchQuery || ''}
                  shape="round"
                  placeholder={t('common.search')}
                  size="lg"
                  onSearch={setSearchQuery}
                  onClear={() => setSearchQuery('')}
                />
              </div>
              <div style={{ margin: 'auto' }}>
                <Button
                  type="submit"
                  color="primary"
                  className="ms-4"
                  style={{ width: '200px', height: '40px' }}
                  onClick={() => onPageChange('add')}
                  data-testid={`team-add-button`}
                >
                  {t('common.add')}
                </Button>
              </div>
            </div>
          </div>
          {searchQuery && (
            <Trans
              i18nKey={
                sortedMembers.length > 0
                  ? 'search.results_description'
                  : 'search.results_description_none'
              }
              values={{
                query: searchQuery,
              }}
              components={{
                1: <b />,
              }}
            />
          )}
          {sortedMembers.length > 0 && (
            <TeamMemberTable members={sortedMembers} />
          )}
        </>
      )}
    </div>
  );
};

export default TeamList;
