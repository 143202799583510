import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';

import MultiSelect, {
  Option,
} from 'src/components/core/MultiSelect/MultiSelect';
import { StoreState } from 'src/redux/store';
import tagsSelectors from 'src/redux/tags/tags-selectors';
import { loadTags } from 'src/redux/tags/tags-slice';
import { Tag } from 'src/types/conversation';

interface BaseProps {
  values: string[];
  onChange: (tags: Option[]) => void;
}

interface StateProps {
  options: Option[];
  isLoadingAllTags: boolean;
  errorAllTags: Error | undefined;
}

type Props = BaseProps & StateProps & DispatchProp;

const transformTagsToOptions = (tags: Tag[]): Option[] => {
  return tags.map((d) => {
    return { label: d.tag, value: d.tag, score: d.count };
  });
};

/** Map state from redux to the components props */
const mapStateToProps = (state: StoreState): StateProps => ({
  options: transformTagsToOptions(tagsSelectors.getTags(state)),
  isLoadingAllTags: tagsSelectors.isTagsLoading(state),
  errorAllTags: tagsSelectors.getTagsError(state),
});

/**
 * Replaces a tag with a new sanitized tag if it is needed.
 * Removes trailing spaces and non-word characters
 */
function sanitizeTags(tags: Option[]) {
  return tags.map((tag) => {
    if (tag.value.match(/(\W+\s*|\s+)/)) {
      return {
        ...tag,
        value: tag.value.replace(/[\W\s]+$/, ''),
        label: tag.label.replace(/[\W\s]+$/, ''),
      };
    } else {
      return tag;
    }
  });
}

export const HighlightTagInput = ({
  options,
  dispatch,
  values,
  onChange,
}: Props) => {
  const [addedOptions, setAddedOptions] = React.useState<Option[]>([]);
  const allOptions = React.useMemo(() => {
    const combinedOptions = [...options, ...addedOptions];
    combinedOptions.sort((a, b) => {
      if (a.score === b.score) {
        return a.label.localeCompare(b.label);
      }
      if (a.score === undefined) {
        return -1;
      }
      if (b.score === undefined) {
        return 1;
      }
      return a.score - b.score;
    });
    return combinedOptions;
  }, [options, addedOptions]);

  React.useEffect(() => {
    dispatch(loadTags());
  }, [dispatch]);

  const handleChangeTag = (tags: Option[]) => {
    // clean up tags to remove trailing spaces and non-word characters
    const sanitizedTags = sanitizeTags(tags);
    // get a list of anything that was added that isn't an existing option
    const addedOptions = sanitizedTags.filter((tag) => {
      return !options.find((opt) => opt.value === tag.value);
    });

    setAddedOptions(addedOptions);
    onChange(sanitizedTags);
  };

  return (
    <div>
      <MultiSelect
        options={allOptions}
        value={values ? values : []} // if values is null, pass in an empty array
        onChange={handleChangeTag}
        enableAddNew
        maxSelectedItems={20}
      />
    </div>
  );
};

// use connect() to get redux to connect to the component
export default connect(mapStateToProps)(HighlightTagInput);
