import { useMemo } from 'react';
import { Typography } from '@mui/material';
import _ from 'lodash';

import Color from 'src/assets/_util.scss';
import {
  ArrowLeftButton,
  ArrowRightButton,
} from 'src/components/core/Button/ArrowButton';
import { HStack } from 'src/components/core/Stack';
import ConversationCard from './ConversationCard';
import {
  CONVERSATIONS_PER_PAGE,
  useConversationsPageContext,
} from './ConversationsPageProvider';

export function ConversationCardModal() {
  const { selectedConversation } = useConversationsPageContext();
  return !selectedConversation ? null : (
    <>
      <ConversationCard conversation={selectedConversation} open />
      <CarouselNavBar />
    </>
  );
}

function CarouselNavBar() {
  const {
    conversations,
    selectedConversation,
    onConversationClick,
    setCurrentPage,
    t,
  } = useConversationsPageContext();

  const itemsById = useMemo(
    () =>
      _.keyBy(
        conversations.map((entry, index) => ({ ...entry, index })),
        'id'
      ),
    [conversations]
  );

  const conversationCount = conversations.length;
  const activeIndex = selectedConversation
    ? itemsById[selectedConversation.id].index
    : -1;
  const onFirstEntry = activeIndex === 0;
  const onLastEntry = activeIndex === conversationCount - 1;

  const goTo = (index: number) => {
    onConversationClick(conversations[index].id);
    setCurrentPage(Math.floor(index / CONVERSATIONS_PER_PAGE + 1));
  };

  return (
    <HStack
      style={{
        background: Color.white,
        borderRadius: '20px',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '12px',
        zIndex: 1500,
        minWidth: '151px',
        flex: '0 0 auto',
        marginTop: 'auto',
        pointerEvents: 'auto',
      }}
    >
      <ArrowLeftButton
        size={32}
        disabled={onFirstEntry}
        onClick={() => goTo(activeIndex - 1)}
        aria-label={t('highlights.previous')}
      />
      <Typography
        variant="body2"
        style={{ fontWeight: '600', color: Color.gray800 }}
      >
        {activeIndex + 1} / {conversationCount}
      </Typography>
      <ArrowRightButton
        size={32}
        disabled={onLastEntry}
        onClick={() => goTo(activeIndex + 1)}
        aria-label={t('highlights.next')}
      />
    </HStack>
  );
}
