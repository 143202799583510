import * as React from 'react';
import { ScaleLinear } from 'd3';

import { minBinPixelWidth } from 'src/components/ConversationTimeline/bin-terms';
import { formatTime } from 'src/util/format';

import styles from './Ruler.module.scss';

interface Props {
  onSeek: (seekTime: number) => void;
  rulerHeight: number;
  timelineHeight: number;
  timeScale: ScaleLinear<number, number>;
  width: number;
}

interface State {
  dragging: boolean;
}

export class Ruler extends React.PureComponent<Props, State> {
  static defaultProps = {
    seekTime: 0,
    duration: 1,
    width: 800,
  };

  state: State = {
    dragging: false,
  };

  rootNode: React.RefObject<SVGGElement> = React.createRef();

  handleStartSeekDrag = (evt: React.MouseEvent<any>) => {
    this.seekByXPosition(evt.clientX);
    this.setState({
      dragging: true,
    });
    document.body.addEventListener('mousemove', this.handleSeekDragMouseMove);
    document.body.addEventListener('mouseup', this.handleStopDrag);
    document.body.addEventListener('mouseleave', this.handleStopDrag);
  };
  handleSeekDragMouseMove = (evt: any) => {
    this.seekByXPosition(evt.clientX);
  };

  handleStopDrag = (evt: any) => {
    this.seekByXPosition(evt.clientX);
    this.setState({
      dragging: false,
    });
    document.body.removeEventListener(
      'mousemove',
      this.handleSeekDragMouseMove
    );
    document.body.removeEventListener('mouseup', this.handleStopDrag);
    document.body.removeEventListener('mouseleave', this.handleStopDrag);
  };

  seekByXPosition = (x: number) => {
    const { timeScale, onSeek } = this.props;
    const rects = (
      this.rootNode.current as SVGGElement
    ).getBoundingClientRect() as DOMRect;

    const seekTime = timeScale.invert(x - rects.x + timeScale.range()[0]);
    onSeek(seekTime);
  };

  render() {
    const { timeScale, timelineHeight, rulerHeight, width } = this.props;

    const numTicks = Math.floor(width / minBinPixelWidth);
    const ticks = timeScale.ticks(numTicks);

    return (
      <g
        className={styles.ruler}
        ref={this.rootNode}
        onMouseDown={this.handleStartSeekDrag}
      >
        <rect
          x={timeScale.range()[0]}
          width={timeScale.range()[1] - timeScale.range()[0]}
          height={rulerHeight}
        />
        {ticks.map((tick, i) => {
          const x = timeScale(tick);
          return (
            <g key={i} transform={`translate(${x} 0)`}>
              <line y1={0} y2={timelineHeight} />
              <text dx="3" dy="1em">
                {formatTime(tick)}
              </text>
            </g>
          );
        })}
      </g>
    );
  }
}

export default Ruler;
