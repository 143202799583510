import { Box, BoxProps } from '@mui/material';

import Color from 'src/assets/_util.scss';

export function VDivider({ color, ...props }: DividerProps) {
  return (
    <Box
      bgcolor={color ?? Color.gray400}
      height="100%"
      width="1px"
      flexBasis="1px"
      {...props}
    />
  );
}

export function HDivider({ color, ...props }: DividerProps) {
  return (
    <Box
      bgcolor={color ?? Color.gray400}
      width="100%"
      height="1px"
      flexBasis="1px"
      {...props}
    />
  );
}

type DividerProps = BoxProps & {
  color?: string;
};
