import * as React from 'react';
import memoize from 'memoizee';

import ConversationCard from 'src/components/ConversationCard/ConversationCard';
import ScrollingTranscript from 'src/components/ScrollingTranscript/ScrollingTranscript';
import { useSeekAudio } from 'src/contexts/GlobalAudioContext';
import { Conversation, Snippet } from 'src/types/conversation';

interface Props {
  conversation: Conversation;
  className?: string | undefined;
}

// wrap snippet in an array without creating a new one each time
const wrapSnippet = memoize((snippet: Snippet) => [snippet]);

const ConversationSearchResultCard = ({ conversation, className }: Props) => {
  const { snippets = [], audio_url: rawAudioUrl } = conversation;
  const [currSnippetIndex, setCurrSnippetIndex] = React.useState(0);
  const currSnippet = snippets && snippets[currSnippetIndex];

  const playSeekTime =
    currSnippet &&
    (currSnippet.words.length
      ? currSnippet.words[0][1] // some snippets have words before their start timesrc/components. e.g. 27100029 TODO fix the data
      : currSnippet.audio_start_offset);
  const playEndTime = currSnippet && currSnippet.audio_end_offset;

  const { seekTime, activatedPlay } = useSeekAudio({
    audioUrl: rawAudioUrl,
    startTime: playSeekTime,
    endTime: playEndTime,
    meta: conversation,
  });

  return (
    <ConversationCard
      conversation={conversation}
      className={className}
      page={currSnippetIndex}
      numPages={snippets.length}
      onChangePage={setCurrSnippetIndex}
    >
      {currSnippet && (
        <ScrollingTranscript
          snippets={wrapSnippet(currSnippet)}
          conversation={conversation}
          seekTime={seekTime}
          onPlay={activatedPlay}
          emphasizedTerms={currSnippet.search_matches || []}
          linkToConversation
        />
      )}
    </ConversationCard>
  );
};

export default React.memo(ConversationSearchResultCard);
