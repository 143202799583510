import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { call, put, SagaReturnType, takeLatest } from 'redux-saga/effects';

import * as api from 'src/api/api';
import { Tag } from 'src/types/conversation';

interface TagsState {
  latest: {
    isLoading: boolean;
    error: Error | undefined;
    tags: Tag[];
    prefix?: string;
  };
}

// initial state for reducer
const initialState: TagsState = {
  latest: {
    isLoading: false,
    error: undefined,
    tags: [],
    prefix: undefined,
  },
};

const slice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    loadTags(state, action: PayloadAction<string | undefined>) {
      state.latest.isLoading = true;
      state.latest.error = undefined;
      state.latest.prefix = action.payload;
    },

    loadTagsSuccess(state, action: PayloadAction<Tag[]>) {
      state.latest.isLoading = false;
      state.latest.tags = action.payload || [];
    },

    loadTagsFailure(state, action: PayloadAction<Error>) {
      state.latest.isLoading = false;
      state.latest.error = action.payload;
    },
  },
});

export const { loadTags, loadTagsSuccess, loadTagsFailure } = slice.actions;
export const actions = slice.actions;

export default slice.reducer;

/** Sagas */
export function* sagaLoadTags(action: ReturnType<typeof loadTags>) {
  try {
    const prefix = action.payload;

    // load tags from API
    const tags: SagaReturnType<typeof api.getTags> = yield call(
      api.getTags,
      prefix
    );

    // fire the action with successful response
    yield put(loadTagsSuccess(tags));
  } catch (err) {
    // an error occurred, fire the failure action
    yield put(loadTagsFailure(err as Error));
  }
}

export const sagas = [takeLatest(loadTags.type, sagaLoadTags)];
