import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  numberOfCrossPollinations: number;
  className?: string | undefined;
}

const CrossPollinationLabel = ({
  numberOfCrossPollinations,
  className,
}: Props) => {
  return (
    <div className={className}>
      <FontAwesomeIcon icon="comments" /> {numberOfCrossPollinations}
    </div>
  );
};

export default CrossPollinationLabel;
