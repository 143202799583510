import React from 'react';
import { RenderElementProps } from 'slate-react';

import { SpeakerChangeRequest, SpeakerOption } from 'src/types/transcript';
import EditableSpeakerLabel from '../EditableSpeakerLabel/EditableSpeakerLabel';

import styles from './TimedText.module.scss';

interface Props extends RenderElementProps {
  onSpeakerChange: (speakerData: SpeakerChangeRequest) => void;
  speakerOptions: SpeakerOption[];
  onPlay: (seekTime: number) => void;
}

const TimedText = ({
  element,
  attributes,
  children,
  onSpeakerChange,
  speakerOptions,
  onPlay,
}: Props) => {
  const { id, speaker_name, speaker_id, audio_start_offset } = element;
  return (
    <div
      key={`snippet-${id}`}
      className="d-flex"
      data-testid={`timed-text-${id}`}
    >
      <div className={styles.transcript_speaker_avatar} contentEditable={false}>
        {speaker_name ? speaker_name[0] : ''}
      </div>
      <div>
        <div className={styles.transcript_speaker_row}>
          <EditableSpeakerLabel
            speakerName={speaker_name}
            speakerId={speaker_id}
            time={audio_start_offset}
            paragraphID={id}
            onChange={onSpeakerChange}
            speakerOptions={speakerOptions}
            onPlay={onPlay}
          />
        </div>
        <p {...attributes}>{children}</p>
      </div>
    </div>
  );
};

export default TimedText;
