import { ActivityMapData } from 'src/components/ActivityMap/ActivityMap';
import { StoreState } from 'src/redux/store';
import { CollectionDetail } from 'src/types/collection';
import { Activity, Paging } from 'src/types/conversation';

// selectors
export const selectors = {
  isLoadingCollectionDetail: (state: StoreState): boolean =>
    state.collection.collectionDetail.isLoading,
  getCollectionDetailError: (state: StoreState): Error | undefined =>
    state.collection.collectionDetail.error,
  getCollectionDetail: (state: StoreState): CollectionDetail | undefined =>
    state.collection.collectionDetail.details,

  isLoadingRecentActivity: (state: StoreState): boolean =>
    state.collection.recentActivity.isLoading,
  getRecentActivityError: (state: StoreState): Error | undefined =>
    state.collection.recentActivity.error,
  getRecentActivities: (state: StoreState): Activity[] =>
    state.collection.recentActivity.activities,
  getRecentActivitiesPaging: (state: StoreState): Paging | undefined =>
    state.collection.recentActivity.paging,

  isActivityMapLoading: (state: StoreState): boolean =>
    state.collection.activityMap.isLoading,
  getActivityMap: (state: StoreState): ActivityMapData[] =>
    state.collection.activityMap.activityMapData,
  getActivityMapError: (state: StoreState): Error | undefined =>
    state.collection.activityMap.error,
};
export default selectors;
