import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  put,
  SagaReturnType,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import * as api from 'src/api/api';
import { HighlightsFilters } from 'src/api/api';
import { MIN_TOPIC_PROB } from 'src/constants';
import { callWithUser } from 'src/redux/redux-helpers';
import { Highlight, Paging } from 'src/types/conversation';
import {
  HighlightsFilterOptions,
  NormalizedHighlightEntities,
} from 'src/types/core';

// google analytics category
const gaCategory = 'highlights';

interface HighlightState {
  userHighlights: {
    isLoading: boolean;
    error: Error | undefined;
    ids: Highlight['id'][];
    paging: Paging | undefined;
    filters: HighlightsFilterOptions | undefined;
  };
  allHighlights: {
    isLoading: boolean;
    error: Error | undefined;
    ids: Highlight['id'][];
    paging: Paging | undefined;
    filters: HighlightsFilterOptions | undefined;
  };
  starredHighlights: {
    isLoading: boolean;
    error: Error | undefined;
    ids: Highlight['id'][];
    paging: Paging | undefined;
    filters: HighlightsFilterOptions | undefined;
  };
  starredAndUserHighlights: {
    isLoading: boolean;
    error: Error | undefined;
    ids: Highlight['id'][];
    paging: Paging | undefined;
  };
  searchHighlights: {
    isLoading: boolean;
    error: Error | undefined;
    ids: Highlight['id'][];
    paging: Paging | undefined;
    searchQuery: string | undefined;
    filters: HighlightsFilterOptions | undefined;
  };
  singleHighlight: {
    isLoading: boolean;
    error: Error | undefined;
  };
  exploreHighlights: {
    isLoading: boolean;
    error: Error | undefined;
    ids: Highlight['id'][];
    paging: Paging | undefined;
    collectionIds: number[] | undefined;
  };
  exploreTopicsHighlights: {
    isLoading: boolean;
    error: Error | undefined;
    ids: Highlight['id'][];
    paging: Paging | undefined;
    collectionId: number[] | undefined;
    topicCodeForData: string | undefined;
  };
  editHighlight: {
    isSaving: boolean;
    isSaved: boolean;
    error: Error | undefined;
    highlightId: Highlight['id'] | undefined;
  };
  starHighlight: {
    isSaving: boolean; // unused right now
    error: Error | undefined | Highlight;
    highlightId: Highlight['id'] | undefined;
  };
}

// initial state for reducer
const initialState: HighlightState = {
  userHighlights: {
    isLoading: false,
    error: undefined,
    ids: [],
    paging: undefined,
    filters: undefined,
  },
  allHighlights: {
    isLoading: false,
    error: undefined,
    ids: [],
    paging: undefined,
    filters: undefined,
  },
  starredHighlights: {
    isLoading: false,
    error: undefined,
    ids: [],
    paging: undefined,
    filters: undefined,
  },
  starredAndUserHighlights: {
    isLoading: false,
    error: undefined,
    ids: [],
    paging: undefined,
  },
  searchHighlights: {
    isLoading: false,
    error: undefined,
    ids: [],
    paging: undefined,
    searchQuery: undefined,
    filters: undefined,
  },
  singleHighlight: {
    isLoading: false,
    error: undefined,
  },
  exploreHighlights: {
    isLoading: false,
    error: undefined,
    ids: [],
    paging: undefined,
    collectionIds: undefined,
  },
  exploreTopicsHighlights: {
    isLoading: false,
    error: undefined,
    ids: [],
    paging: undefined,
    collectionId: undefined,
    topicCodeForData: undefined,
  },
  editHighlight: {
    isSaving: false,
    isSaved: false,
    error: undefined,
    highlightId: undefined,
  },
  starHighlight: {
    isSaving: false,
    error: undefined,
    highlightId: undefined,
  },
};

const slice = createSlice({
  name: 'highlight',
  initialState,
  reducers: {
    loadUserHighlights(
      state,
      action: PayloadAction<Partial<HighlightsFilters>>
    ) {
      state.userHighlights.isLoading = true;
      state.userHighlights.error = undefined;
    },

    loadUserHighlightsSuccess(
      state,
      action: PayloadAction<NormalizedHighlightEntities>
    ) {
      state.userHighlights.isLoading = false;
      state.userHighlights.ids = action.payload.order.annotations || [];
      state.userHighlights.paging = action.payload.paging;
      state.userHighlights.filters = action.payload.filters;
    },

    loadUserHighlightsFailure(state, action: PayloadAction<Error>) {
      state.userHighlights.isLoading = false;
      state.userHighlights.error = action.payload;
      state.userHighlights.filters = undefined;
      state.userHighlights.ids = [];
    },

    loadAllHighlights(
      state,
      action: PayloadAction<Partial<HighlightsFilters>>
    ) {
      state.allHighlights.isLoading = true;
      state.allHighlights.error = undefined;
    },

    loadAllHighlightsSuccess(
      state,
      action: PayloadAction<NormalizedHighlightEntities>
    ) {
      state.allHighlights.isLoading = false;
      state.allHighlights.ids = action.payload.order.annotations || [];
      state.allHighlights.paging = action.payload.paging;
      state.allHighlights.filters = action.payload.filters;
    },

    loadAllHighlightsFailure(state, action: PayloadAction<Error>) {
      state.allHighlights.isLoading = false;
      state.allHighlights.error = action.payload;
      state.allHighlights.filters = undefined;
      state.allHighlights.ids = [];
    },

    loadStarredHighlights(
      state,
      action: PayloadAction<Partial<HighlightsFilters>>
    ) {
      state.starredHighlights.isLoading = true;
      state.starredHighlights.error = undefined;
    },

    loadStarredHighlightsSuccess(
      state,
      action: PayloadAction<NormalizedHighlightEntities>
    ) {
      state.starredHighlights.isLoading = false;
      state.starredHighlights.ids = action.payload.order.annotations || [];
      state.starredHighlights.paging = action.payload.paging;
      state.starredHighlights.filters = action.payload.filters;
    },

    loadStarredHighlightsFailure(state, action: PayloadAction<Error>) {
      state.starredHighlights.isLoading = false;
      state.starredHighlights.error = action.payload;
      state.starredHighlights.filters = undefined;
      state.searchHighlights.ids = [];
    },

    loadStarredAndUserHighlights(
      state,
      action: PayloadAction<Partial<HighlightsFilters>>
    ) {
      state.starredAndUserHighlights.isLoading = true;
      state.starredAndUserHighlights.error = undefined;
      state.starredAndUserHighlights.ids = [];
    },

    loadStarredAndUserHighlightsSuccess(
      state,
      action: PayloadAction<NormalizedHighlightEntities>
    ) {
      state.starredAndUserHighlights.isLoading = false;
      state.starredAndUserHighlights.ids =
        action.payload.order.annotations || [];
      state.starredAndUserHighlights.paging = action.payload.paging;
    },

    loadStarredAndUserHighlightsFailure(state, action: PayloadAction<Error>) {
      state.starredAndUserHighlights.isLoading = false;
      state.starredAndUserHighlights.error = action.payload;
    },

    loadSearchHighlights(
      state,
      action: PayloadAction<Partial<HighlightsFilters>>
    ) {
      state.searchHighlights.searchQuery = action.payload.searchQuery;
      state.searchHighlights.isLoading = true;
      state.searchHighlights.error = undefined;
    },

    loadSearchHighlightsSuccess(
      state,
      action: PayloadAction<NormalizedHighlightEntities>
    ) {
      state.searchHighlights.isLoading = false;
      state.searchHighlights.ids = action.payload.order.annotations || [];
      state.searchHighlights.paging = action.payload.paging;
      state.searchHighlights.filters = action.payload.filters;
    },

    loadSearchHighlightsFailure(state, action: PayloadAction<Error>) {
      state.searchHighlights.isLoading = false;
      state.searchHighlights.error = action.payload;
      state.searchHighlights.ids = [];
      state.searchHighlights.filters = undefined;
    },

    loadHighlight(state, action: PayloadAction<Highlight['id']>) {
      state.singleHighlight.isLoading = true;
      state.singleHighlight.error = undefined;
    },

    loadHighlightSuccess(
      state,
      action: PayloadAction<NormalizedHighlightEntities>
    ) {
      state.singleHighlight.isLoading = false;
    },

    loadHighlightFailure(state, action: PayloadAction<Error>) {
      state.singleHighlight.isLoading = false;
      state.singleHighlight.error = action.payload;
    },

    loadExploreHighlights(
      state,
      action: PayloadAction<{ limit: number; collectionIds: number[] }>
    ) {
      state.exploreHighlights.isLoading = true;
      state.exploreHighlights.error = undefined;
      // reset the list of highlights if the collection has changed
      if (
        state.exploreHighlights.collectionIds !== action.payload.collectionIds
      ) {
        state.exploreHighlights.collectionIds = action.payload.collectionIds;
        state.exploreHighlights.ids = [];
      }
    },

    loadExploreHighlightsSuccess(
      state,
      action: PayloadAction<NormalizedHighlightEntities>
    ) {
      state.exploreHighlights.isLoading = false;
      // we want to add to our existing list in order to view more highlight explorations
      state.exploreHighlights.ids = state.exploreHighlights.ids.concat(
        action.payload.order.annotations
      );
      state.exploreHighlights.paging = action.payload.paging;
    },

    loadExploreHighlightsFailure(state, action: PayloadAction<Error>) {
      state.exploreHighlights.isLoading = false;
      state.exploreHighlights.error = action.payload;
    },

    loadExploreTopicsHighlights(
      state,
      action: PayloadAction<Partial<HighlightsFilters>>
    ) {
      state.exploreTopicsHighlights.isLoading = true;
      state.exploreTopicsHighlights.error = undefined;
      state.exploreTopicsHighlights.ids = [];
      state.exploreTopicsHighlights.topicCodeForData = action.payload.topicCode;
    },

    loadExploreTopicsHighlightsSuccess(
      state,
      action: PayloadAction<NormalizedHighlightEntities>
    ) {
      state.exploreTopicsHighlights.isLoading = false;
      state.exploreTopicsHighlights.ids = action.payload.order.annotations;
      state.exploreTopicsHighlights.paging = action.payload.paging;
    },

    loadExploreTopicsHighlightsFailure(state, action: PayloadAction<Error>) {
      state.exploreTopicsHighlights.isLoading = false;
      state.exploreTopicsHighlights.error = action.payload;
    },

    deleteHighlight: {
      reducer(state, action: PayloadAction<Highlight['id']>) {
        const highlightId = action.payload;
        state.userHighlights.ids = state.userHighlights.ids.filter(
          (hId) => hId !== highlightId
        );
        state.allHighlights.ids = state.allHighlights.ids.filter(
          (hId) => hId !== highlightId
        );
        state.searchHighlights.ids = state.searchHighlights.ids.filter(
          (hId) => hId !== highlightId
        );
      },
      prepare: (highlightId: Highlight['id']) => {
        return { payload: highlightId };
      },
    },

    editHighlight: {
      reducer(
        state,
        action: PayloadAction<{
          highlight: Highlight;
          changes: Partial<Highlight>;
        }>
      ) {
        const { highlight } = action.payload;
        state.editHighlight.isSaving = true;
        state.editHighlight.isSaved = false;
        state.editHighlight.error = undefined;
        state.editHighlight.highlightId = highlight.id;
      },
      prepare: (payload: {
        highlight: Highlight;
        changes: Partial<Highlight>;
      }) => {
        return { payload: payload };
      },
    },

    editHighlightSuccess(state, action: PayloadAction<Highlight>) {
      state.editHighlight.isSaving = false;
      state.editHighlight.isSaved = true;
    },

    editHighlightFailure(state, action: PayloadAction<Error>) {
      state.editHighlight.error = action.payload;
      state.editHighlight.isSaving = false;
      state.editHighlight.isSaved = false;
    },

    changeStarHighlightState: {
      reducer(
        state,
        action: PayloadAction<{ highlight: Highlight; star: boolean }>
      ) {
        const { highlight } = action.payload;
        state.starHighlight.isSaving = true;
        state.starHighlight.error = undefined;
        state.starHighlight.highlightId = highlight.id;
      },
      prepare: (payload: { highlight: Highlight; star: boolean }) => {
        return { payload: payload };
      },
    },

    changeStarHighlightStateSuccess(state, action: PayloadAction<Highlight>) {
      state.starHighlight.isSaving = false;
      state.starHighlight.error = undefined;
      state.starHighlight.highlightId = action.payload.id;
    },
    changeStarHighlightStateFailure(state, action: PayloadAction<Error>) {
      state.starHighlight.isSaving = false;
      state.starHighlight.error = action.payload;
    },
  },
});

export const {
  changeStarHighlightState,
  changeStarHighlightStateFailure,
  changeStarHighlightStateSuccess,
  deleteHighlight,
  editHighlight,
  editHighlightFailure,
  editHighlightSuccess,
  loadAllHighlights,
  loadAllHighlightsFailure,
  loadAllHighlightsSuccess,
  loadExploreHighlights,
  loadExploreHighlightsFailure,
  loadExploreHighlightsSuccess,
  loadExploreTopicsHighlights,
  loadExploreTopicsHighlightsFailure,
  loadExploreTopicsHighlightsSuccess,
  loadHighlight,
  loadHighlightFailure,
  loadHighlightSuccess,
  loadSearchHighlights,
  loadSearchHighlightsFailure,
  loadSearchHighlightsSuccess,
  loadStarredHighlights,
  loadStarredHighlightsFailure,
  loadStarredHighlightsSuccess,
  loadStarredAndUserHighlights,
  loadStarredAndUserHighlightsFailure,
  loadStarredAndUserHighlightsSuccess,
  loadUserHighlights,
  loadUserHighlightsFailure,
  loadUserHighlightsSuccess,
} = slice.actions;
export const actions = slice.actions;

export default slice.reducer;

/** Sagas */
export function* sagaLoadUserHighlights(
  action: ReturnType<typeof loadUserHighlights>
) {
  try {
    const filters = action.payload;
    const userHighlights: SagaReturnType<typeof api.getHighlights> =
      yield callWithUser(api.getHighlights, {
        ...filters,
        currentUser: true,
      });

    // fire the action with successful response
    yield put(loadUserHighlightsSuccess(userHighlights));
  } catch (err) {
    // an error occurred, fire the failure action
    yield put(loadUserHighlightsFailure(err as Error));
  }
}

export function* sagaLoadAllHighlights(
  action: ReturnType<typeof loadAllHighlights>
) {
  try {
    const filters = action.payload;
    const allHighlights: SagaReturnType<typeof api.getHighlights> =
      yield callWithUser(api.getHighlights, filters);

    // fire the action with successful response
    yield put(loadAllHighlightsSuccess(allHighlights));
  } catch (err) {
    // an error occurred, fire the failure action
    yield put(loadAllHighlightsFailure(err as Error));
  }
}

export function* sagaLoadStarredHighlights(
  action: ReturnType<typeof loadStarredHighlights>
) {
  try {
    const filters = action.payload;
    const allHighlights: SagaReturnType<typeof api.getHighlights> =
      yield callWithUser(api.getHighlights, {
        ...filters,
        starred: true,
      });

    // fire the action with successful response
    yield put(loadStarredHighlightsSuccess(allHighlights));
  } catch (err) {
    // an error occurred, fire the failure action
    yield put(loadStarredHighlightsFailure(err as Error));
  }
}

export function* sagaLoadStarredAndUserHighlights(
  action: ReturnType<typeof loadStarredAndUserHighlights>
) {
  try {
    const { limit, page } = action.payload;
    const allHighlights: SagaReturnType<typeof api.getHighlights> =
      yield callWithUser(api.getHighlights, {
        limit,
        page,
        starredAndUser: true,
      });

    // fire the action with successful response
    yield put(loadStarredAndUserHighlightsSuccess(allHighlights));
  } catch (err) {
    // an error occurred, fire the failure action
    yield put(loadStarredAndUserHighlightsFailure(err as Error));
  }
}

export function* sagaLoadSearchHighlights(
  action: ReturnType<typeof loadSearchHighlights>
) {
  try {
    const filters = action.payload;
    const searchHighlights: SagaReturnType<typeof api.getHighlights> =
      yield callWithUser(api.getHighlights, filters);

    // fire the action with successful response
    yield put(loadSearchHighlightsSuccess(searchHighlights));
  } catch (err) {
    // an error occurred, fire the failure action
    yield put(loadSearchHighlightsFailure(err as Error));
  }
}

export function* sagaLoadExploreTopicsHighlights(
  action: ReturnType<typeof loadExploreTopicsHighlights>
) {
  try {
    const { limit, page, collectionIds, topicCode } = action.payload;
    const exploreTopicsHighlights: SagaReturnType<typeof api.getHighlights> =
      yield callWithUser(api.getHighlights, {
        limit,
        page,
        collectionIds,
        topicCode,
        excludeUserPrivate: true,
        annotationTypes: ['highlight_auto'],
        // see constants file for details on how this value works
        minTopicProb: MIN_TOPIC_PROB,
      });

    // fire the action with successful response
    yield put(loadExploreTopicsHighlightsSuccess(exploreTopicsHighlights));
  } catch (err) {
    // an error occurred, fire the failure action
    yield put(loadExploreTopicsHighlightsFailure(err as Error));
  }
}

export function* sagaLoadHighlight(action: ReturnType<typeof loadHighlight>) {
  try {
    const highlight: SagaReturnType<typeof api.getHighlights> =
      yield callWithUser(api.getHighlights, {
        id: action.payload,
      });

    // fire the action with successful response
    yield put(loadHighlightSuccess(highlight));
  } catch (err) {
    // an error occurred, fire the failure action
    yield put(loadHighlightFailure(err as Error));
  }
}

export function* sagaLoadExploreHighlights(
  action: ReturnType<typeof loadExploreHighlights>
) {
  try {
    const { limit, collectionIds } = action.payload;
    const exploreHighlights: SagaReturnType<typeof api.getHighlights> =
      yield callWithUser(api.getHighlights, {
        limit,
        collectionIds,
        randomize: true,
        excludeUserPrivate: true,
      });

    // fire the action with successful response
    yield put(loadExploreHighlightsSuccess(exploreHighlights));
  } catch (err) {
    // an error occurred, fire the failure action
    yield put(loadExploreHighlightsFailure(err as Error));
  }
}

export function* sagaDeleteHighlight(
  action: ReturnType<typeof deleteHighlight>
) {
  try {
    const highlightId = action.payload;

    // delete highlight via API
    yield call(api.deleteHighlight, highlightId);
  } catch (err) {
    // an error occurred, ignore for now
  }
}

export function* sagaEditHighlight(action: ReturnType<typeof editHighlight>) {
  try {
    const { highlight, changes } = action.payload;

    const updatedHighlight: SagaReturnType<typeof api.editHighlight> =
      yield call(api.editHighlight, highlight.id, changes);

    // fire the action with successful response
    yield put(editHighlightSuccess(updatedHighlight));
  } catch (err) {
    // an error occurred, fire the failure action
    yield put(editHighlightFailure(err as Error));
  }
}

export function* sagaStarHighlight(
  action: ReturnType<typeof changeStarHighlightState>
) {
  try {
    const { highlight, star } = action.payload;

    // star the highlight via API
    if (star) {
      yield call(api.starHighlight, highlight.id);
    } else {
      yield call(api.unstarHighlight, highlight.id);
    }
    // fire the action with successful response
    yield put(changeStarHighlightStateSuccess(highlight));
  } catch (err) {
    // an error occurred, fire the failure action
    yield put(changeStarHighlightStateFailure(err as Error));
  }
}

export const sagas = [
  takeLatest(loadUserHighlights.type, sagaLoadUserHighlights),
  takeLatest(loadAllHighlights.type, sagaLoadAllHighlights),
  takeLatest(loadStarredHighlights.type, sagaLoadStarredHighlights),
  takeLatest(
    loadStarredAndUserHighlights.type,
    sagaLoadStarredAndUserHighlights
  ),
  takeLatest(loadSearchHighlights.type, sagaLoadSearchHighlights),
  takeLatest(loadHighlight.type, sagaLoadHighlight),
  takeLatest(loadExploreHighlights.type, sagaLoadExploreHighlights),
  takeLatest(loadExploreTopicsHighlights.type, sagaLoadExploreTopicsHighlights),
  takeEvery(deleteHighlight.type, sagaDeleteHighlight),
  takeEvery(editHighlight.type, sagaEditHighlight),
  takeEvery(changeStarHighlightState.type, sagaStarHighlight),
];
