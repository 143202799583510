import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import { useConversationPrivacyContent } from 'src/constants';
import { Conversation } from 'src/types/conversation';

import styles from './ConversationPrivacyLabel.module.scss';

interface Props {
  conversation: Conversation;
  className?: string;
  iconOnly?: boolean;
  iconSize?: 'xs' | 'sm';
}

const ConversationPrivacyLabel = ({
  className,
  conversation,
  iconOnly,
  iconSize,
}: Props) => {
  let { privacy_level: privacyLevel } = conversation;
  if (conversation.forum) {
    privacyLevel = 'protected';
  }
  const { title, icon } = useConversationPrivacyContent()[privacyLevel];
  const fullTitle = title('Conversation');

  return (
    <div className={className} data-testid="conversation-privacy-label">
      <span
        className="position-relative"
        aria-hidden={!iconOnly}
        title={fullTitle}
        data-testid={`${fullTitle}-icon`}
      >
        <FontAwesomeIcon
          icon={icon}
          className={`text-${privacyLevel}`}
          size={iconSize}
        />
      </span>{' '}
      {!iconOnly && (
        <span className={cx(styles.title, 'ps-1')}>{fullTitle}</span>
      )}
    </div>
  );
};

export default ConversationPrivacyLabel;
