import React from 'react';
import { connect } from 'react-redux';
import { Route, RouteProps } from 'react-router-dom';

import authSelectors from 'src/redux/auth/auth-selectors';
import { StoreState } from 'src/redux/store';
import { redirectUserToConversations } from 'src/util/urls';

interface BaseProps extends RouteProps {
  isAuthenticated: boolean;
}

interface StateProps {
  isLoggingOut: boolean;
}

type Props = BaseProps & StateProps;

/** Map state from redux to the components props */
const mapStateToProps = (state: StoreState): StateProps => ({
  isLoggingOut: authSelectors.isLoggingOut(state),
});

const PrivateRoute = ({
  isAuthenticated,
  isLoggingOut,
  location,
  ...rest
}: Props) => {
  if (!isAuthenticated && !isLoggingOut) {
    let to = '/';
    if (location && location.pathname) {
      to = location.pathname;
    }
    window.location.href = `/login/?next=${redirectUserToConversations(to)}`;
  }

  return <Route {...rest} />;
};

export default connect(mapStateToProps)(PrivateRoute);
