import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function useHashHandler(isLoading: boolean) {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    if (hash === '') {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'center' });
        }
      }, 0);
    }
  }, [isLoading, pathname, hash, key]); // do this on route change
}
