import * as React from 'react';
import { Tooltip } from 'reactstrap';

import {
  SPEECH_PIPELINE_STATUS,
  usePipelineStatusProperties,
} from 'src/constants';
import { Conversation } from 'src/types/conversation';
import ProgressRing from './ProgressRing';

const PipelineStatusIcon = ({
  speechPipelineStatus,
  conversationId,
  className,
}: {
  speechPipelineStatus: Conversation['speech_pipeline_status'];
  conversationId: Conversation['id'];
  className?: string;
}) => {
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const statusPropertiesIndex: number | 'default' =
    speechPipelineStatus == null ? 'default' : speechPipelineStatus;
  const {
    percent,
    className: progressRingClassName,
    tooltip,
  } = usePipelineStatusProperties()[statusPropertiesIndex];
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const tooltipTargetId = `status-${conversationId}`;

  return (
    <div data-testid={`status-${speechPipelineStatus}`} className={className}>
      <Tooltip isOpen={tooltipOpen} target={tooltipTargetId} toggle={toggle}>
        {tooltip}
      </Tooltip>
      <ProgressRing
        id={tooltipTargetId}
        percent={percent}
        showCheck={
          speechPipelineStatus === SPEECH_PIPELINE_STATUS.FINAL_TRANSCRIPT
        }
        tabIndex={0}
        className={progressRingClassName}
      />
    </div>
  );
};

export default PipelineStatusIcon;
