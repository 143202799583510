import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { put, SagaReturnType, takeLatest } from 'redux-saga/effects';

import * as api from 'src/api/api';
import { SearchFilters } from 'src/api/api';
import { callWithUser } from 'src/redux/redux-helpers';
import { Paging } from 'src/types/conversation';
import { NormalizedEntities } from 'src/types/core';

interface SearchState {
  isLoading: boolean;
  error: Error | undefined;
  conversationIds: number[];
  snippetIds: number[];
  paging: Paging | undefined;
}

// initial state for reducer
const initialState: SearchState = {
  isLoading: false,
  error: undefined,
  conversationIds: [],
  snippetIds: [],
  paging: undefined,
};

// google analytics category
const gaCategory = 'conversation';

const slice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    searchConversations: {
      reducer(state, action: PayloadAction<Partial<SearchFilters>>) {
        state.isLoading = true;
        state.error = undefined;
        state.conversationIds = [];
        state.snippetIds = [];
      },
      prepare: (filters: Partial<SearchFilters>) => {
        return { payload: filters };
      },
    },

    searchConversationsSuccess(
      state,
      action: PayloadAction<NormalizedEntities>
    ) {
      state.isLoading = false;
      state.conversationIds = action.payload.order.conversations || [];
      state.snippetIds = action.payload.order.snippets || [];
      state.paging = action.payload.paging;
    },

    searchConversationsFailure(state, action: PayloadAction<Error>) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  searchConversations,
  searchConversationsSuccess,
  searchConversationsFailure,
} = slice.actions;
export const actions = slice.actions;

export default slice.reducer;

/** Sagas */
export function* sagaSearchConversations(
  action: ReturnType<typeof searchConversations>
) {
  try {
    const searchQuery = action.payload;

    // load conversation from API
    const conversations: SagaReturnType<typeof api.searchConversations> =
      yield callWithUser(api.searchConversations, searchQuery);

    // fire the action with successful response
    yield put(searchConversationsSuccess(conversations));
  } catch (err) {
    // an error occurred, fire the failure action
    yield put(searchConversationsFailure(err as Error));
  }
}

export const sagas = [
  takeLatest(searchConversations.type, sagaSearchConversations),
];
