import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  put,
  SagaReturnType,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import * as api from 'src/api/api';
import { callWithUser } from 'src/redux/redux-helpers';
import {
  Annotation,
  Conversation,
  Highlight,
  TermTimings,
} from 'src/types/conversation';
import { NormalizedEntities } from 'src/types/core';

interface ConversationState {
  active: {
    isLoading: boolean;
    error: Error | undefined;
  };
  newAnnotation: {
    creationTag: string | undefined;
    isSaving: boolean;
    isSaved: boolean;
    error: Error | undefined;
  };
  editConversation: {
    isSaving: boolean;
    isSaved: boolean;
    error: Error | undefined;
  };
  editConversationDraftState: {
    isSaving: boolean;
    isSaved: boolean;
    error: Error | undefined;
    conversationId: Conversation['id'] | undefined;
  };
  topTerms: {
    topTerms: TermTimings[];
    isLoading: boolean;
    error: Error | undefined;
  };
}

// initial state for reducer
const initialState: ConversationState = {
  active: {
    isLoading: false,
    error: undefined,
  },
  newAnnotation: {
    creationTag: undefined,
    isSaving: false,
    isSaved: false,
    error: undefined,
  },
  editConversation: {
    isSaving: false,
    isSaved: false,
    error: undefined,
  },
  editConversationDraftState: {
    isSaving: false,
    isSaved: false,
    error: undefined,
    conversationId: undefined,
  },
  topTerms: {
    topTerms: [],
    isLoading: false,
    error: undefined,
  },
};

// google analytics category
const gaCategory = 'conversation';

const slice = createSlice({
  name: 'conversation',
  initialState,
  reducers: {
    loadConversation(state, action: PayloadAction<Conversation['id']>) {
      state.active.isLoading = true;
      state.active.error = undefined;
      state.editConversation.isSaved = false;
      state.editConversationDraftState.isSaved = false;
    },

    loadConversationSuccess(state, action: PayloadAction<NormalizedEntities>) {
      state.active.isLoading = false;
    },

    loadConversationFailure: {
      reducer(state, action: PayloadAction<Error>) {
        state.active.isLoading = false;
        state.active.error = action.payload;
      },
      prepare(error: Error, conversationId: Conversation['id']) {
        return { payload: error, meta: { conversationId } };
      },
    },

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    deleteHighlight(state, action: PayloadAction<Highlight['id']>) {},

    saveNewAnnotation: {
      reducer(state, action: PayloadAction<Partial<Annotation>>) {
        state.newAnnotation.creationTag = action.payload.creation_tag;
        state.newAnnotation.isSaving = true;
        state.newAnnotation.isSaved = false;
        state.newAnnotation.error = undefined;
      },
      prepare: (annotation: Partial<Annotation>) => {
        const event = {
          category: gaCategory,
          action:
            annotation.annotation_type === 'highlight_community'
              ? 'saveNewHighlight'
              : 'saveNewAnnotation',
          label: `conversation:${annotation.conversation_id}`,
        };
        const eventName =
          annotation.annotation_type === 'highlight_community'
            ? 'saved a new highlight'
            : 'saved a new annotation';
        return { payload: annotation };
      },
    },

    saveNewAnnotationSuccess(state, action: PayloadAction<Annotation>) {
      state.newAnnotation.isSaving = false;
      state.newAnnotation.isSaved = true;
    },

    saveNewAnnotationFailure(state, action: PayloadAction<Error>) {
      state.newAnnotation.isSaved = false;
      state.newAnnotation.isSaving = false;
      state.newAnnotation.error = action.payload;
    },

    editConversation: {
      reducer(
        state,
        action: PayloadAction<{
          conversationId: Conversation['id'];
          changes: Partial<Conversation>;
        }>
      ) {
        state.editConversation.isSaving = true;
        state.editConversation.isSaved = false;
        state.editConversation.error = undefined;
      },
      prepare: (payload: {
        conversationId: Conversation['id'];
        changes: Partial<Conversation>;
      }) => {
        return { payload: payload };
      },
    },

    editConversationSuccess(
      state,
      action: PayloadAction<Partial<Conversation>>
    ) {
      state.editConversation.isSaving = false;
      state.editConversation.isSaved = true;
    },

    editConversationFailure(state, action: PayloadAction<Error>) {
      state.editConversation.error = action.payload;
      state.editConversation.isSaving = false;
      state.editConversation.isSaved = false;
    },

    editConversationDraftState(
      state,
      action: PayloadAction<{
        conversationId: Conversation['id'];
        is_draft: Conversation['is_draft'];
      }>
    ) {
      state.editConversationDraftState.conversationId =
        action.payload.conversationId;
      state.editConversationDraftState.isSaving = true;
      state.editConversationDraftState.isSaved = false;
      state.editConversationDraftState.error = undefined;
    },

    editConversationDraftStateSuccess(
      state,
      action: PayloadAction<{
        conversationId: Conversation['id'];
        changes: Partial<Conversation>;
      }>
    ) {
      state.editConversationDraftState.isSaving = false;
      state.editConversationDraftState.isSaved = true;
    },

    editConversationDraftStateFailure(state, action: PayloadAction<Error>) {
      state.editConversationDraftState.error = action.payload;
      state.editConversationDraftState.isSaving = false;
      state.editConversationDraftState.isSaved = false;
    },

    loadConversationTopTerms(
      state,
      action: PayloadAction<{ conversationId: number; topicCode: string }>
    ) {
      state.topTerms.isLoading = true;
      state.topTerms.error = undefined;
      state.topTerms.topTerms = [];
    },

    loadConversationTopTermsSuccess(
      state,
      action: PayloadAction<TermTimings[]>
    ) {
      state.topTerms.isLoading = false;
      state.topTerms.error = undefined;
      state.topTerms.topTerms = action.payload;
    },

    loadConversationTopTermsFailure(state, action: PayloadAction<Error>) {
      state.topTerms.isLoading = false;
      state.topTerms.error = action.payload;
    },
  },
});

export const {
  loadConversation,
  loadConversationSuccess,
  loadConversationFailure,
  saveNewAnnotation,
  saveNewAnnotationSuccess,
  saveNewAnnotationFailure,
  editConversation,
  editConversationSuccess,
  editConversationFailure,
  editConversationDraftState,
  editConversationDraftStateSuccess,
  editConversationDraftStateFailure,
  deleteHighlight,
  loadConversationTopTerms,
  loadConversationTopTermsSuccess,
  loadConversationTopTermsFailure,
} = slice.actions;
export const actions = slice.actions;

export default slice.reducer;

/** Sagas */
export function* sagaLoadConversation(
  action: ReturnType<typeof loadConversation>
) {
  const conversationId = action.payload;
  try {
    // load conversation from API
    const conversation: SagaReturnType<typeof api.getConversation> = yield call(
      api.getConversation,
      conversationId
    );

    // fire the action with successful response
    yield put(loadConversationSuccess(conversation));
  } catch (err) {
    // an error occurred, fire the failure action
    yield put(loadConversationFailure(err as Error, conversationId));
  }
}

export function* sagaSaveNewAnnotation(
  action: ReturnType<typeof saveNewAnnotation>
) {
  try {
    const annotationToSave = action.payload;

    // save annotation via API
    const savedAnnotation: SagaReturnType<typeof api.saveNewAnnotation> =
      yield callWithUser(api.saveNewAnnotation, annotationToSave);

    // fire the action with successful response
    yield put(saveNewAnnotationSuccess(savedAnnotation));
  } catch (err) {
    // an error occurred, fire the failure action
    yield put(saveNewAnnotationFailure(err as Error));
  }
}

export function* sagaDeleteHighlight(
  action: ReturnType<typeof deleteHighlight>
) {
  try {
    const highlightId = action.payload;

    // delete highlight via API
    yield call(api.deleteHighlight, highlightId);
  } catch (err) {
    // an error occurred, ignore for now
  }
}

export function* sagaEditConversation(
  action: ReturnType<typeof editConversation>
) {
  try {
    const { conversationId, changes } = action.payload;

    const updatedConversation: SagaReturnType<typeof api.editConversation> =
      yield call(api.editConversation, conversationId, changes);

    // fire the action with successful response
    yield put(editConversationSuccess(updatedConversation));
  } catch (err) {
    // an error occurred, fire the failure action
    yield put(editConversationFailure(err as Error));
  }
}

/**
 * A separate saga for editing conversation draft state so that
 * we can keep its success/error/saving states separate from
 * other conversation edits
 */
export function* sagaEditConversationDraftState(
  action: ReturnType<typeof editConversationDraftState>
) {
  try {
    const { conversationId, is_draft } = action.payload;

    const updatedConversation: SagaReturnType<typeof api.editConversation> =
      yield call(api.editConversation, conversationId, { is_draft });

    // fire the action with successful response
    yield put(
      editConversationDraftStateSuccess({
        changes: updatedConversation,
        conversationId,
      })
    );
  } catch (err) {
    // an error occurred, fire the failure action
    yield put(editConversationDraftStateFailure(err as Error));
  }
}

export const sagas = [
  takeLatest(loadConversation.type, sagaLoadConversation),
  takeEvery(saveNewAnnotation.type, sagaSaveNewAnnotation),
  takeEvery(deleteHighlight.type, sagaDeleteHighlight),
  takeEvery(editConversation.type, sagaEditConversation),
  takeEvery(editConversationDraftState.type, sagaEditConversationDraftState),
];
