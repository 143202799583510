import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import cx from 'classnames';

import Button from 'src/components/core/Button/Button';
import LanguageOptions from '../I18n/LanguageOptions';

import styles from './FormStep.module.scss';

export const FormContent = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <div className={cx(className, styles.formContent, 'flex-grow-1')}>
    {children}
  </div>
);

interface FormStepProps {
  children?: React.ReactNode;
  onBack?: () => void;
  hideBackBtn?: boolean;
}

const FormStep = ({ children, onBack, hideBackBtn }: FormStepProps) => {
  const { t, i18n } = useTranslation();

  return (
    <FormGroup className="d-flex flex-column">
      <Container>
        <Row className="my-2">
          <Col sm={`${onBack ? '5' : '0'}`}>
            <Button
              onClick={onBack}
              icon={['far', 'chevron-left']}
              color="link"
              size="sm"
              className={cx(styles.backButton, 'align-self-start', {
                invisible: !onBack,
                'd-none': hideBackBtn,
              })}
              data-testid="back-button"
            >
              {t('common.back')}
            </Button>
          </Col>
          <Col sm="auto">
            <Row>
              <Label for="userLanguage" className={'mt-1'}>
                <span className="me-2">{t('settings.languages')}</span>
              </Label>
              <div>
                <Input
                  type="select"
                  name="userLanguage"
                  id="userLanguage"
                  data-testid="user-language"
                  className="w-auto d-inline-block"
                  onChange={(value) => {
                    const language = value.target.value;
                    i18n.changeLanguage(language);
                  }}
                >
                  <LanguageOptions />
                </Input>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
      {children}
    </FormGroup>
  );
};

export default FormStep;
