import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  closable: boolean;
  onClose: () => void;
}
const ClosableTab = React.forwardRef(
  ({ closable, onClose, children, ...props }: Props, ref) => {
    return (
      <div className="d-flex">
        <button ref={ref as any} {...props}>
          {children}
        </button>
        {closable && (
          <button {...props} className="px-2" onClick={onClose}>
            <FontAwesomeIcon icon={['far', 'times']} />
          </button>
        )}
      </div>
    );
  }
);

export default ClosableTab;
