import { ConversationPrivacyLevel } from 'src/types/conversation';
import { LngLat } from 'src/types/core';

export interface UserNestedCollection {
  id: number;
  lng_lat: LngLat;
  title: string;
  // All Collections option lack org_id, org_name, privacy_level
  organization_id?: number;
  organization_name?: string;
  privacy_level?: ConversationPrivacyLevel;
  is_all_collection_option?: boolean;
}

export interface ConversationNestedCollection {
  description: string;
  id: number;
  lng_lat: LngLat;
  organization_id: number;
  title: string;
  web_id: string;
}

export interface CollectionDetail {
  // fields with ? only exist when querying for an individual collection, not all collections
  id: number;
  title: string;
  description: string;
  num_speakers: number;
  num_conversations: number;
  lng_lat?: [number, number];
  web_id?: string;
}

export interface Collection {
  description: string;
  id: number;
  num_conversations: number;
  organization_id: number;
  privacy_level: ConversationPrivacyLevel;
  title: string;
}

// Data collected by CollectionDetailEditForm that changes based on if the form is being used to edit or create a collection
export type CollectionFields =
  | Partial<Collection>
  | Omit<Collection, 'id' | 'num_conversations'>;

export enum CollectionRole {
  manager = 'Manager',
  collaborator = 'Collaborator',
  highlighter = 'Highlighter',
  viewer = 'Viewer',
  host = 'Host',
}
