import { DependencyList, useEffect, useState } from 'react';

/** returns the same thing as useState.
 *
 * when `deps` change, the state is set to `value` after `delay` milliseconds.
 */
export function useDelayedState<T>(
  value: T,
  delay: number,
  deps: DependencyList = []
) {
  const stateObj = useState(value);
  useDelayedEffect(() => stateObj[1](value), delay, deps);
  return stateObj;
}

/** when `deps` change, `effect` runs after `delay` milliseconds */
export function useDelayedEffect(
  effect: () => void,
  delay: number,
  deps: DependencyList = []
) {
  useEffect(() => {
    const timeout = setTimeout(effect, delay);
    return () => clearTimeout(timeout);
  }, [effect, delay, ...deps]);
}
