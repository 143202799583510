import type { ComponentType, Provider } from 'react';
import { createContext, useContext } from 'react';

type CreateProviderValue<T> = [ComponentType, () => T];

export function createProvider<T>(
  useContextValue: () => T
): CreateProviderValue<T> {
  const context = createContext<T | null>(null);

  const ProviderComponent: ComponentType = (props) => (
    <context.Provider {...props} value={useContextValue()} />
  );

  const useProviderContext = () => {
    const value = useContext(context);
    if (!value) {
      throw new Error('component must be wrapped with <Context.Provider>');
    }
    return value;
  };

  return [ProviderComponent, useProviderContext];
}

export function createBasicProvider<T>(): [Provider<T | null>, () => T] {
  const context = createContext<T | null>(null);

  const useProviderContext = () => {
    const value = useContext(context);
    if (!value) {
      throw new Error('component must be wrapped with <Context.Provider>');
    }
    return value;
  };

  return [context.Provider, useProviderContext];
}
