export function isActiveAudio(src: string | undefined, audioUrl: string) {
  const isActive = src !== undefined && src === audioUrl;
  return isActive;
}

/**
 * If we have a seek time relative to the highlight, this gives us the seek time relative to the conversation
 * (taking into account audio fade in/out on highlights). This is useful so that we don't have to adjust
 * all of the word timings which are relative to the conversation length
 */
export const mapSeekTimeToConversation = (
  seekTime: number | undefined,
  audioStartOffset: number,
  audioFadeInOut: number
) => {
  return seekTime != null
    ? Math.max(seekTime + audioStartOffset - audioFadeInOut, 0)
    : undefined;
};

/**
 * If we have seek time relative to the conversation, this gives us the seek time relative to the highlight
 * (taking into account audio fade in/out on highlights). This is useful for something like the TranscriptRoll,
 * where we seek based on the word timing which is relative to the conversation length, but we are seeking through
 * the highlight audio
 */
export const mapSeekTimeToHighlight = (
  seekTime: number | undefined,
  audioStartOffset: number,
  audioFadeInOut: number
) => {
  return seekTime != null
    ? seekTime - audioStartOffset + audioFadeInOut
    : undefined;
};

/**
 * Checks if seek time has reached the end of the audio (rounded to one decimal place)
 * @param seekTime
 * @param audioEndOffset
 * @returns boolean
 */
export function audioHasPlayedToEnd(
  seekTime: number | undefined,
  audioEndOffset: number | undefined
) {
  if (seekTime == null || audioEndOffset == null) {
    return false;
  }
  return (
    Math.round(seekTime * 10) / 10 === Math.round(audioEndOffset * 10) / 10
  );
}

export const urlToRelativeUrl = (url?: string) => {
  if (!url) {
    return undefined;
  } else {
    return url[0] === '/'
      ? `${window.location.origin}${url}`
      : `${window.location.origin}${new URL(url).pathname}`;
  }
};
