import * as React from 'react';
import { Trans } from 'react-i18next';

import ConversationExcerpt from 'src/components/ConversationExcerpt/ConversationExcerpt';
import ConversationExcerptDivider from 'src/components/ConversationExcerptDivider/ConversationExcerptDivider';
import { Conversation, Snippet } from 'src/types/conversation';

interface Props {
  conversation: Conversation;
  snippets: Snippet[];
  onPlay: (seekTime?: number) => void;
  onPause: () => void;
  speakerName: string;
  onViewInTranscript: (
    targetSeekTimeMin: number,
    targetSeekTimeMax?: number
  ) => void;
}

const ConversationSpeakerExcerpts: React.FunctionComponent<Props> = ({
  speakerName,
  snippets,
  onPlay,
  onPause,
  onViewInTranscript,
}) => {
  return (
    <div>
      {snippets.length === 0 ? (
        <p>
          <Trans
            i18nKey={'conversation.transcript_excerpt_speaker_subtitle_none'}
            values={{
              name: speakerName,
            }}
            components={{
              1: <b />,
            }}
          />
        </p>
      ) : (
        <div>
          <p>
            <Trans
              i18nKey={'conversation.transcript_excerpt_speaker_subtitle'}
              values={{
                count: snippets.length,
                name: speakerName,
              }}
              components={{
                1: <b />,
              }}
            />
          </p>
          {snippets.map((snippet, i) => {
            // if the next snippet is longer than this many seconds, add a divider
            const maxAdjacentThreshold = 10;
            const nextSnippet = snippets[i + 1];
            const addDivider =
              i < snippets.length - 1 &&
              nextSnippet &&
              nextSnippet.audio_start_offset - snippet.audio_end_offset >
                maxAdjacentThreshold;

            // don't show speaker if within divider range
            const prevSnippet = snippets[i - 1];
            const showSpeaker =
              !prevSnippet ||
              prevSnippet.speaker_id !== snippet.speaker_id ||
              (prevSnippet.speaker_id === snippet.speaker_id &&
                snippet.audio_start_offset - prevSnippet.audio_end_offset >
                  maxAdjacentThreshold);

            return (
              <div key={snippet.id}>
                <ConversationExcerpt
                  snippet={snippet}
                  onPause={onPause}
                  showSpeaker={showSpeaker}
                  onPlay={onPlay}
                  onViewInTranscript={onViewInTranscript}
                  showControls={showSpeaker}
                />
                {addDivider && (
                  <div className="row">
                    <div className="col-lg-8">
                      <ConversationExcerptDivider />
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default React.memo(ConversationSpeakerExcerpts);
