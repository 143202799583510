import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Label } from 'reactstrap';
import cx from 'classnames';
import { Field, Formik } from 'formik';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import * as Yup from 'yup';

import { useUserTransaction } from 'src/components/AdminPage/Common/Hooks/useUserTransaction';
import LoadingSpinner from 'src/components/core/LoadingSpinner/LoadingSpinner';
import adminSelectors from 'src/redux/admin/admin-selectors';
import {
  UpdateUserTransaction,
  UserCatalogMetadata,
  UserCollectionMetadata,
  UserCommunityMetadata,
} from 'src/types/admin';
import { OrganizationRole } from 'src/types/organization';
import CatalogsTableInput from '../../AddMember/AddMemberForm/CatalogsTableInput';
import CollectionsTableInput from '../../AddMember/AddMemberForm/CollectionsTableInput';
import CommunitiesTableInput from '../../AddMember/AddMemberForm/CommunitiesTableInput';
import { OrganizationRoleDropdown } from '../../PillDropdowns/OrganizationRoleDropdown';

import styles from './AddOrganizationMemberForm.module.scss';

interface AddOrganizationMemberFormProps {
  first_name: string;
  last_name: string;
  email: string;
  org_role: OrganizationRole;
  catalogs: UserCatalogMetadata[];
  communities: UserCommunityMetadata[];
  collections: UserCollectionMetadata[];
}

const AddOrganizationMemberForm = () => {
  const { t } = useTranslation();

  const [, setQuery] = useQueryParams({
    page: StringParam,
    userId: NumberParam,
  });

  const users = useSelector(adminSelectors.getUsers);

  const {
    startTransaction,
    transactionData,
    transactionLoading,
    transactionError,
  } = useUserTransaction();

  useEffect(() => {
    if (transactionData) {
      const userId = (transactionData as UpdateUserTransaction['data']).id;
      // Navigate to to newly created user (id is only put into transaction data on successful user creation)
      userId && setQuery({ userId, page: 'view' });
    }
  }, [transactionData, setQuery]);

  const initialValues: AddOrganizationMemberFormProps = {
    first_name: '',
    last_name: '',
    email: '',
    org_role: OrganizationRole.member,
    catalogs: [],
    communities: [],
    collections: [],
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().trim(),
    last_name: Yup.string().trim(),
    email: Yup.string()
      .trim()
      .matches(/@/, t('register.email_validation_1'))
      .email(t('register.email_validation_2'))
      .required(t('register.email_validation_3')),
  });

  const handleSubmitForm = (values: AddOrganizationMemberFormProps) => {
    if (users.find((u) => u.email === values.email)) {
      toast.error(
        t('admin.add_org_member_already_exists_error_toast', {
          email: values.email,
        }),
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: false,
        }
      );

      return;
    }

    startTransaction({
      type: 'create',
      data: { ...values },
    });
  };

  return (
    <div className="d-flex flex-column rounded" data-testid="add-member-form">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmitForm}
        validationSchema={validationSchema}
      >
        {(props) => (
          <form
            onSubmit={props.handleSubmit}
            className={cx('w-100 pb-4 justify-content-center')}
          >
            <div className="d-flex flex-row flex-wrap">
              <div className="flex-grow-1 mb-4">
                <Label for="first_name" className={styles.label}>
                  {t('settings.first_name')}
                </Label>
                <Field
                  name="first_name"
                  id="first_name"
                  type="text"
                  component="input"
                  onChange={props.handleChange}
                  value={props.values.first_name}
                  aria-describedby="name-helper error-msg-name"
                  aria-invalid={Boolean(
                    props.errors && props.errors.first_name
                  )}
                  aria-required
                  data-testid="first-name-field"
                  className={cx('form-control', styles.h40)}
                />
              </div>
              <div className={styles.spacer} />
              <div className="flex-grow-1 mb-4">
                <Label for="last_name" className={styles.label}>
                  {t('settings.last_name')}
                </Label>
                <Field
                  name="last_name"
                  id="last_name"
                  type="text"
                  component="input"
                  onChange={props.handleChange}
                  value={props.values.last_name}
                  aria-describedby="name-helper error-msg-name"
                  aria-invalid={Boolean(props.errors && props.errors.last_name)}
                  aria-required
                  data-testid="last-name-field"
                  className={cx('form-control', styles.h40)}
                />
              </div>
            </div>
            <div className="mb-4">
              <Label for="email" className={styles.label}>
                {t('settings.email')}
              </Label>
              <Field
                name="email"
                id="email"
                type="text"
                component="input"
                onChange={props.handleChange}
                value={props.values.email}
                aria-describedby="name-helper error-msg-name"
                aria-invalid={Boolean(props.errors && props.errors.email)}
                aria-required
                data-testid="email-field"
                className={cx('form-control', styles.h40)}
              />
            </div>
            <div className="mb-4">
              <Label for="org_role" className={styles.label}>
                {t('admin.tab_team_member_list_column_2')}
              </Label>
              <OrganizationRoleDropdown
                testId="org-role-dropdown"
                onSelect={(val) => props.setFieldValue('org_role', val)}
                organizationRole={props.values.org_role}
              />
            </div>
            <div>
              <CollectionsTableInput
                accordianClassNames="mb-4"
                name="collections"
              />
            </div>
            <div>
              <CatalogsTableInput accordianClassNames="mb-4" name="catalogs" />
            </div>
            <div>
              <CommunitiesTableInput
                accordianClassNames="mb-4"
                name="communities"
              />
            </div>
            <div className="d-flex">
              <Button
                color="primary"
                type="submit"
                disabled={!props.values.email || transactionLoading}
                data-testid="team-member-submit"
                className={cx(styles.h40, styles.w200)}
              >
                {transactionLoading ? (
                  <LoadingSpinner active size="md" />
                ) : (
                  t('common.add')
                )}
              </Button>

              {transactionError && (
                <span className={styles.error} data-testid="add-error">
                  <Trans
                    i18nKey="admin.tab_team_add_member_error"
                    /*eslint-disable */
                    components={{
                      1: <a href="mailto:help@lvn.org" />,
                    }}
                    /*eslint-disable */
                  />
                </span>
              )}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AddOrganizationMemberForm;
