import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import TopicsAndKeywordChart from 'src/components/CollectionTopicsPage/TopicsAndKeywordsChart/TopicsAndKeywordsChart';
import ExpandingButton from 'src/components/core/ExpandingButton/ExpandingButton';
import LoadingOverlay from 'src/components/core/LoadingOverlay/LoadingOverlay';
// want to match the styles of the SnippetCard expand button
import styles from 'src/components/SnippetCard/SnippetCard.module.scss';
import { StoreState } from 'src/redux/store';
import topicSelectors from 'src/redux/topics/topics-selectors';
import { loadTopics } from 'src/redux/topics/topics-slice';
import { UserNestedCollection } from 'src/types/collection';
import { Topics } from 'src/types/conversation';
import { ColorScale } from 'src/types/core';

interface BaseProps {
  activeCollection: UserNestedCollection;
}

interface StateProps {
  topicColorScale: ColorScale | undefined;
  topics: Topics;
  isLoading: boolean;
}

type Props = BaseProps & StateProps & DispatchProp;

/** Map state from redux to the components props */
const mapStateToProps = (state: StoreState): StateProps => ({
  topicColorScale: topicSelectors.getTopicColorScale(state),
  topics: topicSelectors.getTopics(state),
  isLoading: topicSelectors.isTopicsLoading(state),
});

const CollectionTopicsPreview = ({
  activeCollection,
  topicColorScale,
  topics,
  isLoading,
  dispatch,
}: Props) => {
  const { t } = useTranslation();
  // initial load for topics
  React.useEffect(() => {
    dispatch(loadTopics(activeCollection.id));
  }, [dispatch, activeCollection]);

  const topicsPage = '/topics';
  const getBarLinkTo = (id?: number) => {
    if (id == null) {
      return topicsPage;
    } else {
      const topicName = topics[id].name;
      return `${topicsPage}/?topic=${topicName}`;
    }
  };

  if (!topicColorScale) {
    return null;
  }
  if (isLoading) {
    return (
      <LoadingOverlay
        active
        bgColor="transparent"
        className="position-relative"
      />
    );
  }
  return (
    <div className="bg-gray-200 py-3 d-flex">
      <TopicsAndKeywordChart
        topicColorScale={topicColorScale}
        topics={topics}
        activeTopicId={undefined}
        className="flex-grow-1"
        getBarLinkTo={getBarLinkTo}
      />
      <div className={cx('me-3', styles.conversationBtnContainer)}>
        <ExpandingButton
          tag={Link}
          icon={['fas', 'arrow-right']}
          title={t('topics.topics_button_hover')}
          to={topicsPage}
          size="lg"
          className={styles.conversationBtn}
          data-testid="go-to-insights-btn"
        >
          {t('topics.topics_button_hover')}
        </ExpandingButton>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(CollectionTopicsPreview);
