import * as React from 'react';
import throttle from 'lodash.throttle';

import smoothScroll from 'src/util/smooth-scroll';

// this is needed since the smooth scroller cancels animations if it detects an
// unexpected change in the scrollTop (e.g. the user scrolled). This means
// that if animation A is playing then we try to start animation B, anim B gets
// canceled.
const scroller = throttle(
  (
    scrollContainer,
    newTop,
    duration,
    onScrollInterruption = () => {
      return;
    }
  ) =>
    smoothScroll(scrollContainer, newTop, duration).catch(onScrollInterruption), // call onScrollInterruption handler when the element is manually scrolled if one is defined
  350,
  { leading: true, trailing: true }
);

// extracted from jQuery - gets the pixel offset this element is drawn on screen at
// the current window scroll level
function getOffset(node: HTMLElement) {
  const rect = node.getBoundingClientRect();
  return {
    top: rect.top + window.pageYOffset,
    left: rect.left + window.pageXOffset,
  };
}

function scrollToSnippet(
  scrollContainerRef: any,
  seekTime: any,
  onScrollInterrupted?: () => void
) {
  const scrollContainer = scrollContainerRef.current;
  if (scrollContainer) {
    const snippetChunk: HTMLElement | null =
      scrollContainer.querySelector('.is-seek-chunk');

    // it's possible we have no is-seeked-to, e.g. if the seekTime is before the first track
    // in which case, we should return to 0.
    let newTop;
    if (snippetChunk) {
      const offset = 67; // leave some space to show there may be things above it.
      // getOffset will give us the y position of it on the screen (even if clipped)
      // we add the current scrolltop to it to get the delta between the chunk top
      // and the container top if the container were scrolled to the very top.
      // this is the new scrollTop
      const chunkTop = getOffset(snippetChunk).top;
      const containerTop = getOffset(scrollContainer).top;
      newTop = Math.max(
        0,
        chunkTop + scrollContainer.scrollTop - containerTop - offset
      );

      // scroll to top if seekTime is near beginning and we have no snippetChunk
    } else if (!seekTime || seekTime < 30) {
      newTop = 0;
    }

    // if we have a new top to set and it doesn't equal the current scroll top
    if (newTop != null && newTop !== scrollContainer.scrollTop) {
      return scroller(scrollContainer, newTop, 300, onScrollInterrupted);
    }
  }
}

export function useSeekTimeScroll(
  scrollContainerRef: React.Ref<any>,
  seekTime: number | undefined,
  onScrollInterruption?: () => void | undefined,
  autoScroll?: boolean
) {
  React.useEffect(() => {
    if (autoScroll) {
      scrollToSnippet(scrollContainerRef, seekTime, onScrollInterruption);
    }
  }, [scrollContainerRef, seekTime, onScrollInterruption, autoScroll]);
}
