import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import ConversationPrivacyLabel from 'src/components/ConversationPrivacyLabel/ConversationPrivacyLabel';
import Panel from 'src/components/Panel/Panel';
import Stepper from 'src/components/Stepper/Stepper';
import { Conversation } from 'src/types/conversation';
import { Forum } from 'src/types/forum';
import dateFormatter from 'src/util/date';
import OrganizationFavicon from '../OrganizationFavicon/OrganizationFavicon';

import styles from './ConversationCard.module.scss';

interface Props {
  conversation: Conversation;
  className?: string | undefined;
  children?: React.ReactNode;
  page?: number | undefined;
  numPages?: number | undefined; // if provided, will render pager
  mini?: boolean; // use minimal version w/ small card size & less data
  onChangePage?: (page: number) => void;
}

const Subtitle = ({
  collection,
  forum,
  organization,
}: {
  collection: Conversation['collection'];
  forum: Forum | undefined;
  organization: Conversation['organization'];
}) => {
  if (!organization) {
    return (
      <h3
        className="h6 text-gray-700 mb-1 me-4"
        data-testid="collection-subtitle"
      >
        {collection?.title && collection.title}
        {forum?.name && forum.name}
      </h3>
    );
  }

  return (
    <h3 className="h6 text-gray-700 mb-1 me-4">
      {organization.name && <>{organization.name} • </>}
      {collection?.title && collection.title}
      {forum?.name && forum.name}
    </h3>
  );
};

const ConversationCard = ({
  conversation,
  children,
  page,
  numPages,
  mini,
  onChangePage,
  className,
}: Props) => {
  const { t } = useTranslation();
  const {
    host,
    organization,
    id: conversationId,
    title: conversationTitle,
    collection,
    forum,
    location,
  } = conversation;
  const showPager = numPages && numPages > 1 && onChangePage && page != null;
  return (
    <Panel
      className={cx(className, 'py-3 px-3', {
        [styles.mini]: mini,
      })}
      p={null}
      data-testid="conversationCard"
    >
      <div
        className={cx('d-flex', {
          'mb-2': !!children,
        })}
      >
        <div className={cx('d-flex flex-grow-1', { 'me-3': showPager })}>
          <div className="align-self-center flex-grow-1">
            <div>
              <h2 className="h5 mb-1 d-inline">
                <Link
                  to={`/conversation/${conversationId}`}
                  data-testid={`convo-title-${conversation.id}`}
                >
                  {conversationTitle}
                </Link>
              </h2>
            </div>
            {!mini && (
              <Subtitle
                collection={collection}
                forum={forum}
                organization={organization}
              />
            )}

            <span className="text-gray-700">
              {location && location.name && location.name !== 'Unknown' && (
                <span>
                  {location.name} {' • '}{' '}
                </span>
              )}
              {conversation.forum && <span>Mobile {' • '} </span>}
              <span data-testid={`conversationCardDate-${conversationId}`}>
                {dateFormatter.relativeAbsDateFormat(conversation.time)}
              </span>
              {!mini && (
                <>
                  {' • '}
                  <ConversationPrivacyLabel
                    conversation={conversation}
                    iconOnly
                    className="d-inline align-baseline small"
                  />
                </>
              )}
            </span>
            {host.name != null && (
              <span className="d-block text-gray-700">
                {t('common.hosted_by')}{' '}
                <span className="text-gray-800">{host.name}</span>
              </span>
            )}
          </div>
        </div>
        <div className="d-flex flex-column justify-content-between align-items-end">
          <div>
            {!mini && organization && (
              <OrganizationFavicon
                organization={organization}
                width="32px"
                height="32px"
                alt={organization.name}
                title={organization.name}
                className="float-end"
              />
            )}
          </div>
          {/* TODO: add topics */}
          {numPages && numPages > 1 && onChangePage && page != null ? (
            <Stepper
              onChange={onChangePage}
              numSteps={numPages}
              step={page}
              className={styles.pager}
            />
          ) : null}
        </div>
      </div>
      <div>{children}</div>
    </Panel>
  );
};

export default React.memo(ConversationCard);
