import nanoid from 'nanoid';
import { call, select } from 'redux-saga/effects';

import { UserTransactions } from 'src/types/admin';
import { User } from 'src/types/auth';
import { StoreState } from './store';

export function* callWithUser(
  apiFunc: (...args: any[]) => Promise<any>,
  ...args: any[]
) {
  const currentUser: User = yield select(
    (state: StoreState) => state.auth.user
  );
  // @ts-ignore not sure how to properly type this
  const result = yield call(apiFunc, currentUser, ...args);
  return result;
}

export const generateTransaction = (transaction: UserTransactions) => {
  return { id: nanoid(), ...transaction };
};
