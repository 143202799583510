export const matomoAnalytics = {
  trackerUrl: 'https://fora.matomo.cloud/matomo.php',
  src: '//cdn.matomo.cloud/fora.matomo.cloud/matomo.js',
  cookieDomain: '*.fora.org',
};

export const isProduction =
  window.location.host === 'app.lvn.org' ||
  window.location.host === 'app.fora.io';

export const mapbox = {
  accessToken:
    process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || 'unknown mapbox key',
  mapStyles: {
    lvnLight: 'mapbox://styles/peter-cortico/cjrv2pc7q11vw1fla31dcr536',
    lvnLighter: 'mapbox://styles/peter-cortico/cjzbpialw16qy1dn0gznned9o',
    lvnLighterHighContrast:
      'mapbox://styles/peter-cortico/ck0cy2afh0a1a1co9dp8jm7ee/draft',
    light: 'mapbox://styles/mapbox/light-v9',
    dark: 'mapbox://styles/mapbox/dark-v9',
    streets: 'mapbox://styles/mapbox/streets-v10',
    outdoors: 'mapbox://styles/mapbox/outdoors-v10',
  },
};

export const site = {
  baseUrl: process.env.NODE_ENV === 'production' ? '' : '',
  apiUrl: process.env.NODE_ENV === 'production' ? '' : '',
};

export const headway = {
  account: '76Aqnx',
  elementId: 'headway',
};
