import * as React from 'react';
import cx from 'classnames';

import styles from './BgImage.module.scss';

interface Props {
  src: string | undefined;
  title?: string;
  width?: string;
  height?: string;
  style?: React.CSSProperties;
  className?: string;
  backgroundSize?: 'auto' | 'contain' | 'cover';
  vignette?: boolean;
  testId?: string;
}

class BgImage extends React.Component<Props> {
  render() {
    const {
      className,
      src,
      width,
      height,
      style = {},
      backgroundSize,
      vignette,
      title,
      testId,
    } = this.props;
    return (
      <div
        data-testid={testId || 'image-div'}
        className={cx(styles.bgImage, className, {
          [styles.vignette]: vignette,
        })}
        style={{
          backgroundImage: src ? `url(${src})` : undefined,
          width,
          height,
          backgroundSize,
          ...style,
        }}
        title={title}
      />
    );
  }
}

export default BgImage;
