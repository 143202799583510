import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import highlightCardStyles from 'src/components/HighlightCard/HighlightCard.module.scss';
import Restrict from 'src/components/Restrict/Restrict';
import { User } from 'src/types/auth';
import { Conversation, Highlight } from 'src/types/conversation';
import {
  getFeatureHighlightPermission,
  getHighlightPermission,
  hasPermission,
} from 'src/util/user';

interface Props {
  conversation: Conversation;
  highlight: Highlight;
  tabIndex?: number;
  handleToggleFeatured: () => void;
  handleOpenModal: (type: string) => void;
  isFeatured: boolean;
  user: User;
  noHighlightLink?: boolean;
}

/* Highlight card dropdown that shows edit, feature, and deletion options */
const HighlightDropdown = ({
  conversation,
  highlight,
  tabIndex,
  handleToggleFeatured,
  handleOpenModal,
  isFeatured,
  user,
  noHighlightLink,
}: Props) => {
  const { t } = useTranslation();
  /** State for the dropdown menu */
  const [dropdownIsOpen, setDropdownIsOpen] = React.useState(false);
  const handleToggleDropdown = () => setDropdownIsOpen(!dropdownIsOpen);

  const isActiveUser = user.id === highlight.user_id;
  const canFeatureHighlights = hasPermission(
    user,
    getFeatureHighlightPermission('update', conversation.collection.id)
  );

  // don't render if the dropdown should not show a link to the highlight
  // and the user cannot feature, edit, or delete the highlight
  if (noHighlightLink && !isActiveUser && !canFeatureHighlights) {
    return null;
  }

  return (
    <Dropdown
      isOpen={dropdownIsOpen}
      toggle={handleToggleDropdown}
      className="d-inline-block"
      data-testid="dropdown-menu"
    >
      <DropdownToggle
        className={cx(highlightCardStyles.dotsButton, 'btn--icon-only mb-1')}
        data-testid="dropdown-menu-toggle"
        icon="ellipsis-v"
        color="plain"
        aria-label="highlight menu"
        tabIndex={tabIndex}
      >
        <FontAwesomeIcon icon="ellipsis-v" />
      </DropdownToggle>
      <DropdownMenu end>
        <Restrict
          activeUserId={highlight.user_id}
          permission={getHighlightPermission(
            'update',
            conversation.collection.id
          )}
        >
          <DropdownItem
            onClick={() => handleOpenModal('edit')}
            data-testid="edit-highlight"
            tabIndex={tabIndex}
          >
            {t('highlights.edit')}
          </DropdownItem>
        </Restrict>
        <Restrict
          permission={getFeatureHighlightPermission(
            'update',
            conversation.collection.id
          )}
        >
          <Restrict activeUserId={highlight.user_id}>
            <DropdownItem divider />
          </Restrict>
          <DropdownItem
            onClick={handleToggleFeatured}
            data-testid="feature-highlight-button"
            tabIndex={tabIndex}
          >
            {isFeatured ? t('highlights.unfeature') : t('highlights.feature')}
          </DropdownItem>
        </Restrict>
        <Restrict activeUserId={highlight.user_id}>
          <DropdownItem divider />
          <DropdownItem
            onClick={() => handleOpenModal('delete')}
            data-testid="delete-button"
            tabIndex={tabIndex}
          >
            {t('highlights.delete')}
          </DropdownItem>
        </Restrict>
        {!noHighlightLink && (
          <DropdownItem
            tag={Link}
            to={`/highlight/${highlight.id}/`}
            data-testid="go-to-highlight"
            tabIndex={tabIndex}
          >
            {t('highlights.go_to')}
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

export default HighlightDropdown;
