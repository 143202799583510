import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import { Topic } from 'src/types/conversation';

interface Props {
  activeTopic: Topic;
  relatedTopic: Topic;
  className?: string;
  primaryTopicClassName?: string;
  secondaryTopicClassName?: string;
}
const TopicsLegend = ({
  activeTopic,
  relatedTopic,
  primaryTopicClassName,
  secondaryTopicClassName,
  className,
}: Props) => {
  return (
    <div
      className={cx(className, 'd-flex justify-content-end')}
      data-testid="topics-legend"
    >
      <LegendItem
        iconClassName={primaryTopicClassName}
        name={activeTopic.display_name}
        className={'me-2'}
      />
      <LegendItem
        iconClassName={secondaryTopicClassName}
        name={relatedTopic.display_name}
      />
    </div>
  );
};

const LegendItem = ({
  iconClassName,
  name,
  className,
}: {
  iconClassName?: string;
  name: string;
  className?: string;
}) => {
  return (
    <div className={className}>
      <FontAwesomeIcon
        icon={['fas', 'circle']}
        size="sm"
        className={cx('me-1', iconClassName)}
      />
      {name}
    </div>
  );
};

export default TopicsLegend;
