import React from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { connect, DispatchProp } from 'react-redux';
import { AutoSizer } from 'react-virtualized';
import cx from 'classnames';
import moment from 'moment-timezone';

import {
  ActiveHex,
  ActivityMapData,
} from 'src/components/ActivityMap/ActivityMap';
import LoadingOverlay from 'src/components/core/LoadingOverlay/LoadingOverlay';
import recentActivitySelectors from 'src/redux/collection/collection-selectors';
import {
  clearRecentActivity,
  loadRecentActivity,
} from 'src/redux/collection/collection-slice';
import { StoreState } from 'src/redux/store';
import { UserNestedCollection } from 'src/types/collection';
import { Activity, Paging } from 'src/types/conversation';
import { ActivityItem } from './ActivityItem/ActivityItem';

import styles from './RecentActivity.module.scss';

const ActivityMap = React.lazy(
  () => import('src/components/ActivityMap/ActivityMap')
);

interface BaseProps {
  activeCollection: UserNestedCollection | undefined;
}

interface StateProps {
  activities: Activity[];
  paging: Paging | undefined;
  activityMapData: ActivityMapData[];
}

type Props = BaseProps & StateProps & DispatchProp;

/** Map state from redux to the components props */
const mapStateToProps = (state: StoreState): StateProps => ({
  activities: recentActivitySelectors.getRecentActivities(state),
  paging: recentActivitySelectors.getRecentActivitiesPaging(state),
  activityMapData: recentActivitySelectors.getActivityMap(state),
});

export const RecentActivity = ({
  activities,
  paging,
  activeCollection,
  activityMapData,
  dispatch,
}: Props) => {
  const { t } = useTranslation();
  const PAGE_SIZE = 10;

  // reset activity log to top of log
  React.useEffect(() => {
    dispatch(clearRecentActivity());
  }, [dispatch]);

  React.useEffect(() => {
    if (activeCollection != null) {
      dispatch(
        loadRecentActivity({
          limit: PAGE_SIZE,
          page: 1,
          collectionIds: [activeCollection.id],
        })
      );
    }
  }, [dispatch, activeCollection]);

  const handleLoadMoreActivities = (page: number) => {
    if (activeCollection != null) {
      dispatch(
        loadRecentActivity({
          limit: PAGE_SIZE,
          page,
          collectionIds: [activeCollection.id],
        })
      );
    }
  };

  const hasMore =
    paging && paging.page_num + 1 < Math.ceil(paging.total / paging.page_size);
  const loader = (
    <div className="text-muted text-center" key={0}>
      {t('common.loading')}
    </div>
  );

  // activity map toggling
  const [activeHex, setActiveHex] = React.useState<ActiveHex | undefined>(
    undefined
  );
  const recentCutoffDate = React.useMemo(
    () => moment().subtract(2, 'weeks'),
    []
  );

  return (
    <div
      className={cx('d-flex', styles.container)}
      data-testid="recent-activity"
    >
      <div className={'flex-grow-1'}>
        <AutoSizer defaultWidth={500} disableHeight>
          {({ width, height = 400 }) => (
            <React.Suspense
              fallback={
                <div
                  style={{
                    width: `${width}px`,
                    height: `${height}px`,
                    position: 'relative',
                  }}
                >
                  <LoadingOverlay active />
                </div>
              }
            >
              <ActivityMap
                key={
                  // reset lng&lat when id changes
                  activeCollection && activeCollection.id
                }
                activityMapData={activityMapData}
                initialLngLat={activeCollection && activeCollection.lng_lat}
                width={width}
                height={height}
                activeHex={activeHex}
                onChangeActiveHex={setActiveHex}
                recentCutoffDate={recentCutoffDate}
              />
            </React.Suspense>
          )}
        </AutoSizer>
      </div>
      <div className={cx(styles.logContainer, 'p-3 border-start')}>
        <InfiniteScroll
          pageStart={1}
          loadMore={handleLoadMoreActivities}
          hasMore={hasMore}
          loader={loader}
          useWindow={false}
        >
          {activities.map((activity, idx) => {
            return <ActivityItem activity={activity} key={idx} />;
          })}
        </InfiniteScroll>
      </div>
    </div>
  );
};
export default connect(mapStateToProps)(RecentActivity);
