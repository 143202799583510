import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Input, InputGroup, Popover } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import nanoid from 'nanoid';

import Button from 'src/components/core/Button/Button';
import PrivacyLabel from 'src/components/PrivacyLabel/PrivacyLabel';
import PrivacyMenu from 'src/components/PrivacyMenu/PrivacyMenu';
import Restrict from 'src/components/Restrict/Restrict';
import { User } from 'src/types/auth';
import { AnnotationPrivacyLevel, Highlight } from 'src/types/conversation';
import { Conversation } from 'src/types/conversation';
import { getHighlightPermission } from 'src/util/user';
import {
  EmbedHelperText,
  HighlightHelperText,
} from './ShareHelperText/ShareHelperText';

import styles from './ShareControl.module.scss';

interface Props {
  highlight: Highlight;
  conversation: Conversation;
  user: User;
  onChange: (privacy: AnnotationPrivacyLevel) => void;
  className?: string;
}

const getHighlightLink = (highlight: Highlight) => {
  const baseUrl = window.location.origin; // http://localhost:3000 or https://app.lvn.dev or https://app.lvn.org
  const highlightPath = `highlight/${highlight.id}`;
  const link = `${baseUrl}/${highlightPath}`;
  return link;
};

const getEmbedCode = (highlight: Highlight) => {
  /**
   * This will return the dev.embed url when developing locally and the production embed url when deployed to either dev or prod
   */

  let embedBaseUrl = 'https://embed.lvn.org';
  if (process.env.NODE_ENV === 'development') {
    embedBaseUrl = 'https://origin-embed.lvn.dev';
  }
  const embedUrl = `${embedBaseUrl}/?hid=${highlight.id}`;
  return `<iframe src="${embedUrl}" width="100%" height="225" scrolling="no" frameborder="no" style="max-width: 570px; margin: 0 auto; display: block"></iframe>`;
};

const ShareControl = ({
  highlight,
  conversation,
  user,
  onChange,
  className,
}: Props) => {
  const { t } = useTranslation();
  const [isPrivacyChangeView, setIsPrivacyChangeView] = React.useState(false);
  const [shareWindowIsOpen, setShareWindowIsOpen] = React.useState(false);
  const handleToggleShareWindow = () => {
    // if we are closing the share window, reset the state of the window to the default view
    if (shareWindowIsOpen) {
      setIsPrivacyChangeView(false);
    }
    setShareWindowIsOpen(!shareWindowIsOpen);
  };
  // make a unique ID for each share window to target
  const shareButtonId = React.useMemo(() => 'shareButton-' + nanoid(), []);
  const highlightLinkRef: React.RefObject<HTMLInputElement> | undefined =
    React.createRef();
  const embedCodeRef: React.RefObject<HTMLInputElement> | undefined =
    React.createRef();

  const highlightLink = getHighlightLink(highlight);
  const embedCode = getEmbedCode(highlight);
  const collection = conversation.collection;

  const handleCopyHighlightLink = () => {
    // select the text to copy
    if (highlightLinkRef.current) {
      highlightLinkRef.current.select();
      document.execCommand('copy');
    }
  };

  const handleCopyEmbedCode = () => {
    if (embedCodeRef.current) {
      embedCodeRef.current.select();
      document.execCommand('copy');
    }
  };

  const handlePrivacyChange = (privacyLevel: AnnotationPrivacyLevel) => {
    onChange(privacyLevel);
    setIsPrivacyChangeView(false);
  };

  return (
    <div className={className} data-testid="shareControl">
      <Button
        color="primary"
        outline={!shareWindowIsOpen}
        grayOutline={!shareWindowIsOpen}
        whiteBg={!shareWindowIsOpen}
        id={shareButtonId}
        onClick={handleToggleShareWindow}
      >
        <FontAwesomeIcon icon="share" className="me-2" />
        {t('highlight.card_share')}
      </Button>
      <Popover
        placement="bottom"
        isOpen={shareWindowIsOpen}
        toggle={handleToggleShareWindow}
        target={shareButtonId}
        trigger="legacy" // allows clicking inside as well as going away when clicking outside
        hideArrow
        data-testid="sharePopover"
      >
        <div className={cx(styles.container)}>
          <div className="d-flex justify-content-between align-items-baseline px-3 pt-3">
            <h5 className="mb-1">{t('highlight.share_heading')}</h5>
            {isPrivacyChangeView && (
              <Button
                color="link"
                size="sm"
                onClick={() => setIsPrivacyChangeView(false)}
              >
                {t('common.cancel')}
              </Button>
            )}
          </div>
          {isPrivacyChangeView ? (
            <PrivacyMenu
              highlight={highlight}
              collection={collection}
              user={user}
              onChange={handlePrivacyChange}
            />
          ) : (
            <div className="px-3">
              <div className="d-flex justify-content-between align-items-start mb-3">
                <PrivacyLabel
                  privacyLevel={highlight.privacy_level}
                  collectionName={collection.title}
                  conversation={conversation}
                />
                <Restrict
                  permission={getHighlightPermission('create', collection.id)}
                >
                  <Button
                    color="link"
                    size="sm"
                    onClick={() => setIsPrivacyChangeView(true)}
                    data-testid="change-button"
                  >
                    {t('common.change')}
                  </Button>
                </Restrict>
              </div>
              <div className="mb-3">
                <h6 className="mb-1">{t('highlight.subheading')}</h6>
                <HighlightHelperText
                  privacyLevel={highlight.privacy_level}
                  className={cx(styles.helperText, 'mb-1')}
                />
                <InputGroup>
                  <Input
                    defaultValue={highlightLink}
                    innerRef={highlightLinkRef}
                  />
                  <Button size="sm" onClick={handleCopyHighlightLink}>
                    <FontAwesomeIcon icon={['far', 'copy']} />
                  </Button>
                </InputGroup>
              </div>

              <div className="mb-3">
                <h6 className="mb-1">{t('highlight.embed_subheading')}</h6>
                <EmbedHelperText
                  privacyLevel={highlight.privacy_level}
                  onChangePrivacy={() => setIsPrivacyChangeView(true)}
                  className={cx(styles.helperText, 'mb-1')}
                />
                {highlight.privacy_level === 'public' && (
                  <InputGroup>
                    <Input defaultValue={embedCode} innerRef={embedCodeRef} />
                    <Button size="sm" onClick={handleCopyEmbedCode}>
                      <FontAwesomeIcon icon={['far', 'copy']} />
                    </Button>
                  </InputGroup>
                )}
              </div>
            </div>
          )}
        </div>
      </Popover>
    </div>
  );
};

export default ShareControl;
