import * as React from 'react';
import { ScaleLinear } from 'd3';

import styles from './TimelinePlayhead.module.scss';

interface Props {
  seekTime: number;
  onSeek: (seekTime: number) => void;
  height: number;
  timeScale: ScaleLinear<number, number>;
}

interface State {
  dragging: boolean;
}

export class TimelinePlayhead extends React.PureComponent<Props, State> {
  static defaultProps = {
    seekTime: 0,
    duration: 1,
    width: 800,
  };

  state: State = {
    dragging: false,
  };

  rootNode: React.RefObject<SVGGElement> = React.createRef();

  handleStartSeekDrag = (evt: React.MouseEvent<any>) => {
    this.seekByXPosition(evt.clientX);
    this.setState({
      dragging: true,
    });
    document.body.addEventListener('mousemove', this.handleSeekDragMouseMove);
    document.body.addEventListener('mouseup', this.handleStopDrag);
    document.body.addEventListener('mouseleave', this.handleStopDrag);
  };
  handleSeekDragMouseMove = (evt: any) => {
    this.seekByXPosition(evt.clientX);
  };

  handleStopDrag = (evt: any) => {
    this.seekByXPosition(evt.clientX);
    this.setState({
      dragging: false,
    });
    document.body.removeEventListener(
      'mousemove',
      this.handleSeekDragMouseMove
    );
    document.body.removeEventListener('mouseup', this.handleStopDrag);
    document.body.removeEventListener('mouseleave', this.handleStopDrag);
  };

  seekByXPosition = (x: number) => {
    const { timeScale, onSeek } = this.props;
    const rects = (
      this.rootNode.current as SVGGElement
    ).getBoundingClientRect() as DOMRect;

    const seekTime = timeScale.invert(x - rects.x);
    onSeek(seekTime);
  };

  render() {
    const { seekTime, timeScale, height } = this.props;

    const x = timeScale(seekTime);
    //title="M-6,0 l12,0 l0,7 l-6,7 l-6,-7 Z"

    // need 2 <g> tags since one needs to not be translated to
    // use as a reference for the x position
    const yOffset = 4;
    const innerHeight = height - yOffset;

    return (
      <>
        <g ref={this.rootNode} />
        <g
          className={styles.playhead}
          transform={`translate(${x} ${yOffset})`}
          onMouseDown={this.handleStartSeekDrag}
        >
          <rect
            /* for interaction, so you can drag the line more easily */
            x={-2}
            width={4}
            height={innerHeight}
            fill="tomato"
            fillOpacity={0}
          />
          <path
            transform="translate(-7, 0)"
            d="M1.50551429,8.84472656 C1.34516508,8.47684349 1.11456009,8.37841251 1.03642156,7.5242513 C0.987859481,6.99340125 0.987859481,5.81496158 1.03642156,3.98893229 C1.21738962,3.17524727 1.55911422,2.51226984 2.06159536,2 C2.5640765,1.48773016 3.22389924,1.15439682 4.04106357,1 L7,1 L9.95893643,1 C10.7761008,1.15439682 11.4359235,1.48773016 11.9384046,2 C12.4408858,2.51226984 12.7826104,3.17524727 12.9635784,3.98893229 C13.0121405,5.81496158 13.0121405,6.99340125 12.9635784,7.5242513 C12.8854399,8.37841251 12.6548349,8.47684349 12.4944857,8.84472656 C12.1846541,9.55556143 10.3531589,11.6073192 7,15 C3.64684114,11.6073192 1.8153459,9.55556143 1.50551429,8.84472656 Z"
          />
          <line y1={7} y2={innerHeight} />
        </g>
      </>
    );
  }
}

export default TimelinePlayhead;
