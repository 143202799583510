import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import cx from 'classnames';

import LoadingOverlay from 'src/components/core/LoadingOverlay/LoadingOverlay';
import HighlightCard from 'src/components/HighlightCard/HighlightCard';
import Layout from 'src/components/Layout/Layout';
import entitiesSelectors from 'src/redux/entities/entities-selectors';
import highlightSelectors from 'src/redux/highlight/highlight-selectors';
import { loadHighlight } from 'src/redux/highlight/highlight-slice';
import { StoreState } from 'src/redux/store';
import { Highlight } from 'src/types/conversation';
import Button from '../core/Button/Button';

import styles from './HighlightRoute.module.scss';

interface BaseProps extends RouteComponentProps<{ highlightId: string }> {
  onHighlightPage?: boolean;
}

interface StateProps {
  highlight: Highlight | undefined;
  isLoading: boolean;
  error: Error | undefined;
}

type Props = BaseProps & StateProps & DispatchProp;

const mapStateToProps = (state: StoreState, props: BaseProps): StateProps => {
  // @ts-ignore haven't figured out how to type this to accept the second parameter
  const highlight = entitiesSelectors.getHighlight(state, props);
  return {
    highlight,
    isLoading: highlightSelectors.isLoadingHighlight(state),
    error: highlightSelectors.getErrorHighlight(state),
  };
};

const HighlightRoute: React.FunctionComponent<Props> = ({
  highlight,
  isLoading,
  dispatch,
  match,
}) => {
  const { t } = useTranslation();
  React.useEffect(() => {
    const highlightId = +match.params.highlightId;
    dispatch(loadHighlight(highlightId));
  }, [dispatch, match.params.highlightId]);

  return (
    <Layout className="HighlightRoute" title={t('highlight.header')}>
      {isLoading && (
        <div className="p-5 position-relative">
          <LoadingOverlay bgColor="transparent" active={isLoading} />
        </div>
      )}
      {!isLoading && (
        <div className={cx(styles.highlightCardsContainer, 'mx-auto')}>
          {highlight ? (
            <>
              <HighlightCard highlight={highlight} onHighlightPage={true} />
              <Button color="primary" href={'https://cortico.ai/partners/'}>
                {t('highlight.learn_more')}
              </Button>
            </>
          ) : (
            <div>{t('highlight.not_found')}</div>
          )}
        </div>
      )}
    </Layout>
  );
};

// use connect() to get redux to connect to the component
export default connect(mapStateToProps)(HighlightRoute);
