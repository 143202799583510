import * as React from 'react';
import cx from 'classnames';

import LoadingSpinner from 'src/components/core/LoadingSpinner/LoadingSpinner';

import './LoadingOverlay.scss';

interface Props {
  active: boolean;
  bgColor: string;
  textBgColor?: string;
  text?: string;
  className?: string | undefined;
  fixed?: boolean;
}

class LoadingOverlay extends React.Component<Props> {
  static defaultProps = {
    active: false,
    bgColor: 'rgba(255, 255, 255, 0.9)',
    textBgColor: '#ffffff',
  };

  render() {
    const { active, bgColor, text, textBgColor, className, fixed } = this.props;

    return (
      <div
        style={{ backgroundColor: active ? bgColor : 'transparent' }}
        className={cx('LoadingOverlay', { active, fixed }, className)}
        data-testid="loading-overlay"
      >
        {text ? (
          <div
            style={{ backgroundColor: active ? textBgColor : 'transparent' }}
            className="d-flex flex-column align-items-center p-3 rounded"
          >
            <span className="mb-2">{text}</span>
            <LoadingSpinner active={active} size="md" />
          </div>
        ) : (
          <LoadingSpinner active={active} size="md" />
        )}
      </div>
    );
  }
}

export default LoadingOverlay;
