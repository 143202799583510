import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import styles from 'src/components/PipelineStatusIcon/PipelineStatusIcon.module.scss';
import { Conversation, ConversationPrivacyLevel } from './types/conversation';
/**
 * Corresponds to a conversation's speech_pipeline_status value
 * Descriptions of statuses documented here:
 * https://www.notion.so/corticoai/Speech-Manager-e0889bf8090943b3b3c8452a81979e94
 */
export const SPEECH_PIPELINE_STATUS: {
  [key: string]: Conversation['speech_pipeline_info'];
} = {
  NO_TRANSCRIPT: null,
  AUTOMATED_TRANSCRIPT_PROCESSING: 0,
  AUTOMATED_TRANSCRIPT_COMPLETE: 1,
  AUTOMATED_TRANSCRIPT_UPLOADED: 2,
  HUMAN_TRANSCRIPT_PROCESSING: 3,
  HUMAN_TRANSCRIPT_EDITED: 4,
  HUMAN_TRANSCRIPT_UPLOADED: 5,
  FORCE_ALIGNMENT_COMPLETE: 6,
  CONVERSATION_TRIMMED: 7,
  FINAL_TRANSCRIPT: 8,
  FINAL_TRANSCRIPT_JUNKYARD: 9,
};

/**
 * React hook to allow for translations of pipeline status properties
 */
export const usePipelineStatusProperties = () => {
  const { t } = useTranslation();
  return useMemo(
    () => ({
      default: {
        percent: 0,
        className: styles.noTranscript,
        tooltip: t('conversation.pipeline_tooltip_1'),
        message: t('conversation.pipeline_transcribing'),
      },
      [SPEECH_PIPELINE_STATUS.AUTOMATED_TRANSCRIPT_PROCESSING]: {
        percent: 5,
        className: styles.noTranscript,
        tooltip: t('conversation.pipeline_tooltip_1'),
        message: t('conversation.pipeline_transcribing'),
      },
      [SPEECH_PIPELINE_STATUS.AUTOMATED_TRANSCRIPT_COMPLETE]: {
        percent: 25,
        className: styles.noTranscript,
        tooltip: t('conversation.pipeline_tooltip_2'),
        message: t('conversation.pipeline_transcribing'),
      },
      [SPEECH_PIPELINE_STATUS.AUTOMATED_TRANSCRIPT_UPLOADED]: {
        percent: 33,
        className: styles.roughTranscript,
        tooltip: t('conversation.pipeline_tooltip_3'),
        message: t('conversation.pipeline_rough_transcript'),
      },
      [SPEECH_PIPELINE_STATUS.HUMAN_TRANSCRIPT_PROCESSING]: {
        percent: 45,
        className: styles.roughTranscript,
        tooltip: t('conversation.pipeline_tooltip_3'),
        message: t('conversation.pipeline_rough_transcript'),
      },
      [SPEECH_PIPELINE_STATUS.HUMAN_TRANSCRIPT_EDITED]: {
        percent: 85,
        className: styles.finalTranscript,
        tooltip: t('conversation.pipeline_tooltip_4'),
        message: t('conversation.pipeline_correction_made'),
      },
      [SPEECH_PIPELINE_STATUS.HUMAN_TRANSCRIPT_UPLOADED]: {
        percent: 85,
        className: styles.finalTranscript,
        tooltip: t('conversation.pipeline_tooltip_5'),
        message: t('conversation.pipeline_processing'),
        // this message is vague and should only be used for steps that are brief
        // we're vague because statuses 5/6 could be before the human transcript is available
        // or after corrections have been made
      },
      [SPEECH_PIPELINE_STATUS.FORCE_ALIGNMENT_COMPLETE]: {
        percent: 85,
        className: styles.finalTranscript,
        tooltip: t('conversation.pipeline_tooltip_5'),
        message: t('conversation.pipeline_processing'),
      },
      [SPEECH_PIPELINE_STATUS.CONVERSATION_TRIMMED]: {
        percent: 85,
        className: styles.finalTranscript,
        tooltip: t('conversation.pipeline_tooltip_6'),
        message: t('conversation.pipeline_correction_made'),
      },
      [SPEECH_PIPELINE_STATUS.FINAL_TRANSCRIPT]: {
        percent: 100,
        className: styles.finalTranscript,
        tooltip: t('conversation.pipeline_tooltip_7'),
        message: t('conversation.pipeline_machine_only'),
      },
      [SPEECH_PIPELINE_STATUS.FINAL_TRANSCRIPT_JUNKYARD]: {
        percent: 100,
        className: styles.finalTranscript,
        // TODO: give better messages and i18n
        tooltip: t('conversation.pipeline_tooltip_7'),
        message: t('conversation.pipeline_junkyard'),
      },
    }),
    [t]
  );
};

/**
 * the minimum topic probability for a highlight to be considered related to a topic
 * e.g. if "lvn-education" has a topic prob of 0.024, the highlight can show an "Education" tag
 * if "lvn-education" had a topic prob of 0.014, the highlight will not show an "Education" tag
 *
 * if it seems like some highlights are unrelated to their topics, raise this value
 * if it seems like there are too few highlights, try lowering this value but make sure they still seem relevant
 */
export const MIN_TOPIC_PROB = 0.02;

/**
 * xs width from bootstrap, used (sparingly) for mobile behavior
 */
export const XS_VIEWPORT_WIDTH = 768; // in px

/**
 * default longitude latitude value, at media lab
 */
export const DEFAULT_LONG_LAT: [number, number] = [-71.087607, 42.360627];

export const NAVBAR_HEIGHT = 62;

/**
 * URLs referenced across the app
 */
export const PRIVACY_URL = 'https://cortico.ai/privacy';
export const TERMS_URL = 'https://cortico.ai/terms';

export type ConversationPrivacyContent = {
  [key in ConversationPrivacyLevel]: {
    icon: IconProp;
    title: (suffix?: 'Collection' | 'Conversation') => string;
    helperText: string;
    collectionHelperText: string;
    highlightWarningText: string;
  };
};

/**
 * React hook to allow for translations of privacy content
 */
export const useConversationPrivacyContent = (): ConversationPrivacyContent => {
  const { t } = useTranslation();
  return useMemo(
    () => ({
      protected: {
        icon: ['fas', 'user-shield'],
        title: (suffix?: string) =>
          suffix === 'Collection'
            ? t('conversation.privacy_protected_collection_header')
            : suffix === 'Conversation'
            ? t('conversation.privacy_protected_conversation_header')
            : t('conversation.privacy_protected'),
        helperText: t('conversation.privacy_protected_helper'),
        collectionHelperText: t('conversation.privacy_protected_collection'),
        highlightWarningText: t('conversation.privacy_protected_highlight'),
      },
      community: {
        icon: ['fas', 'users'],
        title: (suffix?: string) =>
          suffix === 'Collection'
            ? t('conversation.privacy_community_collection_header')
            : suffix === 'Conversation'
            ? t('conversation.privacy_community_conversation_header')
            : t('conversation.privacy_community'),
        helperText: t('conversation.privacy_community_helper'),
        collectionHelperText: t('conversation.privacy_community_collection'),
        highlightWarningText: t('conversation.privacy_default_highlight'),
      },
      public: {
        icon: ['fas', 'globe'],
        title: (suffix?: string) =>
          suffix === 'Collection'
            ? t('conversation.privacy_public_collection_header')
            : suffix === 'Conversation'
            ? t('conversation.privacy_public_conversation_header')
            : t('conversation.privacy_public'),
        helperText: t('conversation.privacy_public_helper'),
        collectionHelperText: t('conversation.privacy_public_collection'),
        highlightWarningText: t('conversation.privacy_default_highlight'),
      },
    }),
    [t]
  );
};
