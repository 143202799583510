import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import FormButton from 'src/components/FormButton/FormButton';
import FormStep, { FormContent } from 'src/components/FormStep/FormStep';

const EmailSent = () => {
  const { t } = useTranslation();
  return (
    <FormStep>
      <FormContent>
        <h1>{t('register.guardian_email_sent')}</h1>
        <p data-testid="email-sent">{t('register.guardian_email_next')}</p>
      </FormContent>
      <FormButton
        tag={Link}
        {...{
          to: `/`,
        }}
        data-testid="next-button"
      >
        {t('common.next')}
      </FormButton>
    </FormStep>
  );
};

export default EmailSent;
