import * as React from 'react';
import { useTranslation } from 'react-i18next';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import { makeStyles } from '@mui/styles';

import CreateCatalogDialog from '../Dialogs/CreateCatalogDialog';
import useDialogState from '../utils/dialog';

const useStyles = makeStyles(() => ({
  staticTooltipLabel: {
    whiteSpace: 'nowrap',
  },
}));

const CatalogsSpeedDial: React.FunctionComponent = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [dialogState, openDialog, closeDialog] = useDialogState();
  const { t } = useTranslation();

  return (
    <>
      <Backdrop open={open} sx={{ backgroundColor: '#fffe' }} />
      <SpeedDial
        ariaLabel="SpeedDial"
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        sx={{ position: 'fixed', bottom: 24, left: 24 }}
      >
        <SpeedDialAction
          classes={classes}
          icon={<AddCircleIcon />}
          key="Create New Catalog"
          onClick={openDialog('newCatalog')}
          tooltipOpen
          tooltipPlacement="right"
          tooltipTitle={t('insights.create_new_catalog')}
        />
      </SpeedDial>

      <CreateCatalogDialog
        closeDialog={closeDialog('newCatalog')}
        open={Boolean(dialogState['newCatalog'])}
      />
    </>
  );
};

export default CatalogsSpeedDial;
