import { forwardRef } from 'react';

import { View, ViewProps } from 'src/components/core/View';

export const HStack = forwardRef((props: ViewProps, ref) => (
  <View display="flex" flexDirection="row" {...props} ref={ref} />
));

export const VStack = forwardRef((props: ViewProps, ref) => (
  <View display="flex" flexDirection="column" {...props} ref={ref} />
));
