import * as React from 'react';
import { Alert } from 'reactstrap';

import PublishButton from 'src/components/PublishButton/PublishButton';
import { Conversation } from 'src/types/conversation';

interface Props {
  conversation: Conversation | undefined;
  isSaving: boolean;
  saveError: Error | undefined;
  isSaved: boolean;
  onChangeDraftStatus: (isDraft: boolean) => void;
}
const DraftBanner = ({
  conversation,
  isSaving,
  saveError,
  isSaved,
  onChangeDraftStatus,
}: Props) => {
  const [visible, setVisible] = React.useState(true);
  const onDismiss = () => setVisible(false);

  if (!conversation) {
    return null;
  }

  const { is_draft: isDraft } = conversation;

  // shouldn't show this banner if it isn't a draft
  // but if it was just saved or is currently saving, we should still show it so we can show
  // our success message
  if (!isDraft && !isSaved && !isSaving) {
    return null;
  }

  const showPublishedSuccess = isSaved && !isDraft;

  return (
    <Alert
      color={showPublishedSuccess ? 'success' : 'warning'}
      className="mb-0 d-flex align-items-center justify-content-end banner"
      data-testid="draft-banner"
      // only show the option to dismiss when we have just saved
      isOpen={showPublishedSuccess ? visible : undefined}
      toggle={showPublishedSuccess ? onDismiss : undefined}
    >
      {isDraft && (
        <span className="flex-grow-1">
          This conversation is only visible to staff and partner admins.
        </span>
      )}
      <div className="d-none d-sm-flex align-items-center">
        <PublishButton
          isDraft={isDraft}
          isSaving={isSaving}
          error={saveError}
          isSaved={isSaved}
          onClick={onChangeDraftStatus}
        />
      </div>
    </Alert>
  );
};

export default DraftBanner;
