// convert array into a map search query
export function generateGoogleMapsSearchURL(values: (string | undefined)[]) {
  const baseURL = 'https://www.google.com/maps/search/?api=1&query=';
  // remove null values and join with ' '
  const query = values.filter((value) => value).join(' ');

  if (!query) {
    return undefined;
  }

  return baseURL + encodeURIComponent(query);
}

export function generateGoogleCalendarEventURL({
  title,
  details,
  location,
  startTime,
  endTime,
}: {
  title: string;
  details: string;
  location?: string;
  startTime: string;
  endTime: string;
}) {
  const baseURL = 'https://calendar.google.com/calendar/r/eventedit?';
  const formattedDetails = details.replace(/\\n/g, '%0A');
  let queryString = `text=${title}&details=${formattedDetails}&dates=${startTime}/${endTime}`;
  if (location) {
    queryString += `&location=${location}`;
  }
  return baseURL + queryString;
}

export function generateIcsDownload({
  title,
  details,
  location,
  startTime,
  endTime,
  tz,
  id,
}: {
  title: string;
  details: string;
  startTime: string;
  endTime: string;
  tz: string;
  id: string;
  location?: string;
  url?: string;
}) {
  const eventString = [
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    'CALSCALE:GREGORIAN',
    'BEGIN:VEVENT',
    'TZID:' + tz,
    'DTSTART:' + startTime,
    'DTEND:' + endTime,
    'SUMMARY:' + title,
    'DESCRIPTION:' + details,
    'LOCATION:' + location,
    'UID:' + id + '@lvn.org',
    'END:VEVENT',
    'END:VCALENDAR',
  ].join('\n');
  const blob = new Blob([eventString], {
    type: 'text/calendar;charset=utf-8',
  });

  if (window && window.navigator.msSaveOrOpenBlob) {
    return (icsFilename: string) => {
      window.navigator.msSaveOrOpenBlob(blob, icsFilename);
    };
  } else if (window && window.URL.createObjectURL) {
    return window.URL.createObjectURL(blob);
  }

  return undefined;
}

export function generateSalesforceJobURL(
  salesforceBaseUrl: string,
  salesforceJobId: string | undefined
) {
  if (salesforceJobId == null || salesforceJobId === '') {
    return '';
  }

  return `${salesforceBaseUrl}/lightning/r/GW_Volunteers__Volunteer_Job__c/${salesforceJobId}/view`;
}

export function getSalesforceJobID(salesforceJobURL: string | null) {
  if (salesforceJobURL === '' || salesforceJobURL == null) {
    return '';
  }
  const splitURL = salesforceJobURL.split('/');
  const jobStrIndex = splitURL.indexOf('GW_Volunteers__Volunteer_Job__c');
  if (jobStrIndex === -1) {
    throw new Error('URL must include "GW_Volunteers__Volunteer_Job__c"');
  }
  return splitURL[jobStrIndex + 1];
}

/**
 * This function determines which URL path to redirect to based on where the user originally was
 * when redirected to the login page.
 * If the user is logging in to the 'home page' ('/'), we want to redirect the user to the
 * '/conversations' page so they can view their relevant content.
 */

export function redirectUserToConversations(path: string) {
  let next = '/';
  if (path !== '/') {
    next = encodeURIComponent(`${window.location.origin}${path}`);
  } else if (path === '/') {
    next = encodeURIComponent(`${window.location.origin}/conversations`);
  }
  return next;
}
