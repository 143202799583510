import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { MoreHorizRounded } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import Color from 'src/assets/_util.scss';
import { HSpacer } from 'src/components/core/Spacer';
import { View } from 'src/components/core/View';
import { useFlag } from 'src/Providers/FlagProvider';
import { AbstractCode, Coding } from 'src/types/insights';
import { Hidden } from 'src/util/styles';
import { EXCLUDE_FROM_PORTAL_CODE_ID } from '../../../redux/catalog/catalog-slice';
import { AccordionType, useCodebookContext } from '../Catalog/CodebookProvider';
import CodeChip from '../Common/CodeChip';

import foraColors from '../../../assets/_util.scss';

interface BaseProps<CodeType extends AbstractCode> {
  additionalIconButtons?: JSX.Element;
  codes: CodeType[];
  selectCode: (id: number, selected: boolean) => void;
  selectedCodes: Pick<Coding, 'code_id'>[];
  selecting: boolean;
  type: AccordionType;
}

const CodeAccordionModern = <CodeType extends AbstractCode>(
  props: BaseProps<CodeType>
) => {
  const { codes, selectCode, selectedCodes, selecting, type } = props;
  const {
    editingCodebook,
    codeFilter: filter,
    openDialog,
    setAccordionType,
    openMenu,
    showAIReasoning,
    selectedEntry,
    aiOn,
  } = useCodebookContext();
  const { t } = useTranslation();
  const title = t(`insights.${type}_codes`);
  const [open, setOpen] = React.useState(true);
  const improvedFiltering = useFlag('highlight_filtering');

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickAdd = (
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setAccordionType(type);
    openDialog('create')();
  };

  const filteredCodes = codes.filter(
    (code) => code.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1
  );
  const selectedCodeIds = new Set(selectedCodes.map((c) => c.code_id));

  const hasAIReason = (codeId: number) =>
    aiOn &&
    !!selectedEntry?.suggested_codings.some(
      (sc) => sc.code_id === codeId && sc.ai_metadata?.reason
    );

  return (
    <>
      <ListItemButton
        dense
        disableGutters
        divider
        onClick={handleClick}
        sx={{ mt: 2, minHeight: 45 }}
      >
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        <ListItemText
          primary={title}
          primaryTypographyProps={{ fontWeight: 600 }}
        />
        {props.additionalIconButtons}
        {editingCodebook && (
          <Tooltip
            arrow
            placement="left"
            title={t('insights.add_new_value', { value: title.toLowerCase() })}
          >
            <IconButton onClick={handleClickAdd}>
              <AddIcon
                fontSize="small"
                htmlColor={foraColors.foraPurpleDarker}
              />
            </IconButton>
          </Tooltip>
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto">
        <List data-testid={`CodeAccordion-${title}`}>
          {filteredCodes.map((code, idx, arr) => (
            <React.Fragment key={code.id}>
              <ListItem disableGutters sx={{ py: 0, minHeight: 36 }}>
                <ListItemText
                  sx={{ py: 0, mr: '8px', display: 'flex' }}
                  disableTypography
                >
                  {/* Only allow tagging of child codes */}
                  {code.parent_id && (
                    <Tooltip
                      arrow
                      placement="left"
                      title={t('insights.toggle_coding')}
                    >
                      <Checkbox
                        checked={selectedCodeIds.has(code.id)}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          selectCode(code.id, event.target.checked);
                        }}
                        size="small"
                        sx={{
                          py: 0,
                          pl: 0,
                          pr: 0.5,
                          ...Hidden(editingCodebook || !selecting),
                        }}
                      />
                    </Tooltip>
                  )}
                  {code.parent_id ? (
                    <View
                      onClick={() => showAIReasoning(code.id)}
                      style={{
                        cursor: hasAIReason(code.id) ? 'pointer' : 'auto',
                      }}
                    >
                      <CodeChip
                        code={code}
                        suggested={
                          aiOn &&
                          selectedEntry?.suggested_codings.some(
                            (c) => c.code_id === code.id
                          )
                        }
                        selected={selectedCodeIds.has(code.id)}
                      />
                    </View>
                  ) : (
                    <Typography
                      variant="body1"
                      data-testid="ParentCodeName"
                      sx={{ pt: 1, pb: 1, color: foraColors.foraPurpleDarker }}
                    >
                      {code.name}
                    </Typography>
                  )}
                </ListItemText>

                <HSpacer grow width="16px" />
                {!selectedEntry && !improvedFiltering && (
                  <VisibilityButton code={code} />
                )}

                {(selectedEntry || editingCodebook) && (
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      ...Hidden(
                        !editingCodebook ||
                          code.id === EXCLUDE_FROM_PORTAL_CODE_ID
                      ),
                    }}
                  >
                    <IconButton
                      onClick={({ currentTarget }) =>
                        openMenu(code, currentTarget, type)
                      }
                    >
                      <MoreHorizRounded
                        fontSize="small"
                        htmlColor={foraColors.foraPurpleDarker}
                      />
                    </IconButton>
                  </ListItemIcon>
                )}
              </ListItem>
              {/*
                If we're editing and we've reached the end of the codes list, or the next code is a parent header,
                display the "New child code" button
              */}
              {editingCodebook &&
                code.id !== EXCLUDE_FROM_PORTAL_CODE_ID &&
                (arr[idx + 1] === undefined ||
                  arr[idx + 1]?.parent_id === undefined) && (
                  <ListItemButton
                    disableGutters
                    sx={{ py: 0, minHeight: 36 }}
                    onClick={handleClickAdd}
                  >
                    <ListItemIcon sx={{ minWidth: 0, pr: 1 }}>
                      <AddIcon
                        fontSize="small"
                        htmlColor={foraColors.foraPurpleDarker}
                      />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 600,
                          color: foraColors.foraPurpleDarker,
                        }}
                      >
                        {t('insights.child_code_add')}
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                )}
            </React.Fragment>
          ))}
          {filteredCodes.length === 0 && (
            <ListItem disableGutters disabled sx={{ py: 0 }}>
              <ListItemText sx={{ pl: 1, py: 0, my: 0 }}>
                <Typography variant="body2">
                  {t('insights.empty_codes')}
                </Typography>
              </ListItemText>
            </ListItem>
          )}
        </List>
      </Collapse>
    </>
  );
};
export default CodeAccordionModern;

function VisibilityButton<CodeType extends AbstractCode>({
  code,
}: {
  code: CodeType;
}) {
  const { getCodeVisibility, setCodeVisibility, t } = useCodebookContext();

  const visibility = getCodeVisibility(code);
  const buttonHidden = visibility !== 'visible' && visibility !== undefined;
  const visible = visibility === 'visible';
  const Icon = visible ? VisibilityOffIcon : VisibilityIcon;

  return buttonHidden ? null : (
    <Tooltip
      arrow
      placement="left"
      title={t(
        visible
          ? 'insights.remove_visibility_filter'
          : 'insights.show_coded_entries'
      )}
    >
      <ListItemIcon sx={{ minWidth: 0 }}>
        <IconButton
          onClick={() => setCodeVisibility(code.id, !visible)}
          sx={{ py: 0 }}
        >
          <Icon
            fontSize="small"
            sx={{ fill: Color[visible ? 'foraPurple' : 'gray500'] }}
          />
        </IconButton>
      </ListItemIcon>
    </Tooltip>
  );
}
