import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx, { Argument } from 'classnames';

import { Table, TableProps } from 'src/components/core/Table/Table';

import styles from './AccordianTable.module.scss';

export type AccordianTableProps<T = any> = {
  label: string;
  startClosed?: boolean;
  accordianClassNames?: Argument;
} & TableProps<T>;

const AccordianTable = ({
  label,
  startClosed,
  accordianClassNames,
  tableClassNames,
  headerClassNames,
  ...tableProps
}: AccordianTableProps) => {
  const [isOpen, setIsOpen] = useState(!startClosed);

  return (
    <div className={cx(styles.panel, accordianClassNames)}>
      <div
        className={cx('d-flex', styles.header, !isOpen && styles.closed)}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div
          className={cx(
            'd-flex flex-column justify-content-center flex-grow-1',
            styles.title
          )}
        >
          <span>{label}</span>
        </div>
        <div
          className={cx(
            'd-flex flex-column justify-content-center',
            styles.chevron
          )}
        >
          <FontAwesomeIcon
            icon={isOpen ? 'chevron-up' : 'chevron-down'}
            size="sm"
          />
        </div>
      </div>
      {isOpen && (
        <Table
          {...tableProps}
          headerClassNames={cx(styles.tableHeader, headerClassNames)}
          tableClassNames={cx('rounded-top-0', styles.table, tableClassNames)}
        />
      )}
    </div>
  );
};

export default AccordianTable;
