import React from 'react';
import { useTranslation } from 'react-i18next';
import { AutoSizer } from 'react-virtualized';
import cx from 'classnames';
import moment from 'moment-timezone';

import LoadingOverlay from 'src/components/core/LoadingOverlay/LoadingOverlay';
import Panel from 'src/components/Panel/Panel';
import { User } from 'src/types/auth';
import dateFormatter from 'src/util/date';

import styles from './ConversationUploadFormSuccess.module.scss';

const ConversationMap = React.lazy(
  () => import('src/components/ConversationMap/ConversationMap')
);

interface MetadataRowProps {
  title: string;
  children: React.ReactNode;
  className?: string;
}

const MetadataRow = ({ title, children, className }: MetadataRowProps) => (
  <p className={cx('mb-2', className)}>
    <strong>{title}: </strong>
    {children}
  </p>
);

interface Props {
  conversationTitle: string;
  user: User;
  collection: User['collections'][0];
  startDatetime: string;
  sourceTypeTitle: string;
  location: [number, number];
  languageTitle: string;
  numberOfParticipants: number;
}

const ConversationUploadFormSuccess = ({
  conversationTitle,
  user,
  collection,
  startDatetime,
  sourceTypeTitle,
  location,
  languageTitle,
  numberOfParticipants,
}: Props) => {
  const { t } = useTranslation();
  const datetime = moment(startDatetime);
  const date = dateFormatter.dateFormat(datetime, { includeYear: true }); // May 14, 2020
  const time = dateFormatter.timeFromMoment(datetime); // 3:25 pm

  return (
    <Panel>
      <h4 className="mb-1">{conversationTitle}</h4>
      <p>
        {t('conversation_upload.success_card_subtext', {
          name: `${user.first_name ?? ''} ${user.last_name ?? ''}`,
          collection: collection.title,
        })}
      </p>
      <div className={styles.mapContainer}>
        <AutoSizer disableHeight>
          {({ width, height = Math.min(200, Math.max(150, width * 0.5)) }) => (
            <React.Suspense
              fallback={
                <div
                  style={{
                    width: `${width}px`,
                    height: `${height}px`,
                    position: 'relative',
                  }}
                >
                  <LoadingOverlay active />
                </div>
              }
            >
              <ConversationMap
                className={'rounded-8 shadow'}
                initialLngLat={location}
                width={width}
                height={height}
                initialZoom={11}
                fixed
              />
            </React.Suspense>
          )}
        </AutoSizer>
      </div>
      <MetadataRow className="mt-4" title={t('common.language')}>
        {languageTitle}
      </MetadataRow>
      <MetadataRow title={t('conversation_upload.participants')}>
        {numberOfParticipants}
      </MetadataRow>
      <MetadataRow title={t('conversation_upload.date_time')}>
        {date} at {time}
      </MetadataRow>
      <MetadataRow title="Source type">{sourceTypeTitle}</MetadataRow>
    </Panel>
  );
};

export default ConversationUploadFormSuccess;
