import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'reactstrap';
import { Tab } from '@reach/tabs';

import { TabName } from '../AdminPage';

interface Props {
  tabName: TabName;
  tooltipText?: string;
  canAccess: boolean;
}

const AdminTab = ({ tabName, tooltipText, canAccess }: Props) => {
  const { t } = useTranslation();
  const [lockTooltip, setLockTooltip] = React.useState<boolean>(false);
  const ref = React.useRef(null);

  return (
    <>
      <Tab
        ref={ref}
        key={tabName}
        disabled={!canAccess}
        data-testid={`tab-${tabName}`}
      >
        {t(`admin.tab_${tabName}`).toUpperCase()}
      </Tab>

      {tooltipText && (
        <Tooltip
          isOpen={lockTooltip}
          placement="top"
          hidearrow="false"
          target={ref}
          toggle={() => !canAccess && setLockTooltip((self) => !self)}
        >
          {t(`admin.tab_${tooltipText}`)}
        </Tooltip>
      )}
    </>
  );
};

export default AdminTab;
