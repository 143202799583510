import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import Button from 'src/components/core/Button/Button';
import { useAnalyticsContext } from 'src/Providers/AnalyticsProvider';
import { Action, Category, Name } from 'src/types/analytics';
import Layout from '../../Layout/Layout';

import styles from './RecordingComplete.module.scss';

const RecordingComplete = () => {
  const history = useHistory();
  const storedConversationId: string | null =
    sessionStorage.getItem('conversationId');
  const storedConversationTitle: string | null =
    sessionStorage.getItem('conversationTitle');
  const { analyticsEvent } = useAnalyticsContext();
  const handleNavigateToConversation = () => {
    analyticsEvent({
      category: Category.LiveRecording,
      action: Action.Click,
      name: Name.ViewConversationButton,
    });

    sessionStorage.clear();
    history.push(`/conversation/${storedConversationId}`);
  };
  const { t } = useTranslation();

  return (
    <Layout className={'recording complete'}>
      <div className={styles.conversationFinishedContainer}>
        <div>
          <h1 className={styles.conversationFinishedHeading}>
            {t('live_recording.recording_finished_header')}
          </h1>
          <div>
            {storedConversationId && (
              <p>
                {t(
                  'live_recording.recording_finished_view_conversation_message'
                )}
                :{' '}
                <span
                  className={styles.conversationLink}
                  onClick={handleNavigateToConversation}
                  data-testid="conversation-link"
                >
                  {storedConversationTitle}
                </span>
              </p>
            )}
            <p>{t('live_recording.recording_finished_info_message')}</p>
          </div>
        </div>
        <div className={styles.recordingCompleteFooter}>
          <Link to="/conversation-record">
            <Button color="primary" className="me-2 mb-2 w-100">
              {t('live_recording.record_again_button')}
            </Button>
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default RecordingComplete;
