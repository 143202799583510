import { Moment } from 'moment-timezone';

import { StoreState } from 'src/redux/store';
import {
  Organization,
  Permission,
  Role,
  User,
  UserPreferences,
} from 'src/types/auth';
import { FeatureFlags, LanguageOption } from 'src/types/core';
import { getFlag, isAnonymous } from 'src/util/user';
import { EditTransaction } from './auth-slice';

const cookieLocalStorageChoice = localStorage.getItem(
  'userCookieSelectionChoice'
) as unknown as boolean;

// selectors
export const selectors = {
  isAuthenticated: (state: StoreState): boolean =>
    !!state.auth.user && !isAnonymous(state.auth.user),
  isLoggingOut: (state: StoreState): boolean => state.auth.isLoggingOut,
  getUser: (state: StoreState): User => state.auth.user,
  getUserCookieChoice: (state: StoreState): boolean | undefined =>
    cookieLocalStorageChoice ?? state.auth.acceptedCookies,
  getUserEmail: (state: StoreState): string =>
    state.auth.user && state.auth.user.email,
  getUserPermissions: (state: StoreState): Permission[] =>
    state.auth.user && state.auth.user.permissions,
  getUserFeatureFlag: (state: StoreState, flag: FeatureFlags): boolean =>
    getFlag(state.auth.user, flag),
  getUserRoles: (state: StoreState): Role[] =>
    state.auth.user && state.auth.user.roles,
  getUserPreferences: (state: StoreState): UserPreferences | undefined =>
    state.auth.user && state.auth.user.preferences,
  getUserCollections: (state: StoreState): User['collections'] =>
    state.auth.user && state.auth.user.collections,
  isSavingUserCreation: (state: StoreState): boolean =>
    state.auth.createUser.isSaving,
  errorSavingUserCreation: (state: StoreState): Error | undefined =>
    state.auth.createUser.error,
  isSavedUserCreation: (state: StoreState): boolean =>
    state.auth.createUser.isSaved,
  getTermsEffectiveDate: (state: StoreState): Moment | undefined =>
    state.auth.termsEffectiveDate,
  getSalesforceUrl: (state: StoreState): string => state.auth.salesforceUrl,
  getEditUserTransaction: (
    state: StoreState,
    type: EditTransaction['editType']
  ): EditTransaction | undefined => {
    if (type === 'guardian_agree') {
      // A guardian does not need to be signed in to consent for a minor
      // Id check is not necessary.
      return state.auth.editUserTransactions.filter(
        (transaction) => transaction.editType === type
      )[0];
    }
    return state.auth.editUserTransactions.filter(
      (transaction) =>
        transaction.userId === state.auth.user.id &&
        transaction.editType === type
    )[0];
  },
  getLanguages: (state: StoreState): LanguageOption[] => state.auth.languages,
  getOrganizations: (state: StoreState): Organization[] => {
    return state.auth.user.organizations;
  },
};
export default selectors;
