import * as React from 'react';
import { max, scaleLinear } from 'd3';
import memoize from 'memoizee';

import LoadingOverlay from 'src/components/core/LoadingOverlay/LoadingOverlay';
import { usePersistedSeekTime } from 'src/contexts/GlobalAudioContext';
import {
  BaseTermTimings,
  Conversation,
  TermTimings,
} from 'src/types/conversation';
import { binTerms } from './bin-terms';
import HighlightsBar from './HighlightsBar/HighlightsBar';
import Ruler from './Ruler/Ruler';
import { computeSpeakerTurns } from './speaker-turns';
import SpeakerTracks from './SpeakerTracks/SpeakerTracks';
import TimelinePlayhead from './TimelinePlayhead/TimelinePlayhead';
import TimelineTerms from './TimelineTerms/TimelineTerms';

interface Props {
  conversation: Conversation;
  width: number;
  onToggleSpeaker: (speakerId: string | undefined) => void;
  toggledSpeakerId?: string | undefined;
  onToggleTerm: (topTermString: string | undefined) => void;
  toggledTerm?: TermTimings | undefined;
  searchTermTimings?: BaseTermTimings | undefined;
  onViewHighlights: () => void;
  duration: number;
  onPlay: (seekTime?: number) => void;
  onSeek: (seekTime: number, play?: boolean) => void;
  topTerms: TermTimings[] | undefined;
  isLoadingTopTerms: boolean;
}

const maxNumTopTerms = 30;
const truncateTopTerms = memoize((terms: TermTimings[]) =>
  terms.slice(0, maxNumTopTerms)
);

const ConversationTimeline: React.FunctionComponent<Props> = ({
  conversation,
  width = 800,
  onToggleSpeaker,
  toggledSpeakerId,
  toggledTerm,
  onToggleTerm,
  searchTermTimings,
  onViewHighlights,
  onPlay,
  onSeek,
  duration,
  topTerms,
  isLoadingTopTerms,
}) => {
  const { seekTime = 0 } = usePersistedSeekTime({
    src: conversation.audio_url,
  });

  const [hoveredTerm, setHoveredTerm] = React.useState<
    BaseTermTimings | undefined
  >(undefined);

  const speakerTurns = computeSpeakerTurns(conversation);

  const renderTerms = topTerms && topTerms.length !== 0;

  // need padding top for playhead border, right for playhead at end,
  // left for names
  const padding = { top: 1, right: 8, left: 100, bottom: 1 };
  const nameWidth = padding.left;
  const plotAreaWidth = width - padding.right - padding.left;

  const timeScale = React.useMemo(
    () =>
      scaleLinear().domain([0, duration]).range([0, plotAreaWidth]).clamp(true),
    [duration, plotAreaWidth]
  );

  const termBins = binTerms(
    truncateTopTerms(topTerms || []),
    timeScale,
    plotAreaWidth,
    searchTermTimings
  );
  const termHeight = 28;
  const termsHeight = renderTerms
    ? termHeight * (max(termBins, (d) => d.length) || 5)
    : 0;
  const termsMargin = 28;

  const trackHeight = 24;
  const rulerHeight = 18;
  const rulerMargin = 2;
  const tracksHeight = speakerTurns.length * trackHeight;
  const timelineHeight = rulerHeight + rulerMargin + tracksHeight;
  const timelineMargin = 4;
  const highlightsBarHeight = rulerHeight;
  const height =
    termsHeight +
    termsMargin +
    timelineHeight +
    timelineMargin +
    highlightsBarHeight;

  // const plotAreaHeight = height - padding.top - padding.bottom;

  return (
    <div style={{ width: `${width}px` }}>
      {isLoadingTopTerms && (
        <div
          className="position-absolute"
          style={{
            height: '34px',
            width: '34px',
            left: `${width / 2}px`,
            top: `34px`,
          }}
        >
          <LoadingOverlay bgColor="transparent" active />
        </div>
      )}
      <svg className="d-block mb-3" width={width} height={height}>
        <g transform={`translate(${padding.left} ${padding.top})`}>
          <g transform={`translate(0 ${termsHeight + termsMargin})`}>
            <Ruler
              onSeek={onSeek}
              width={plotAreaWidth}
              rulerHeight={rulerHeight}
              timelineHeight={timelineHeight}
              timeScale={timeScale}
            />
            <g transform={`translate(0 ${rulerHeight + rulerMargin})`}>
              <SpeakerTracks
                width={plotAreaWidth}
                onPlay={onPlay}
                trackHeight={trackHeight}
                speakerTurns={speakerTurns}
                nameWidth={nameWidth}
                timeScale={timeScale}
                toggledSpeakerId={toggledSpeakerId}
                onToggleSpeaker={onToggleSpeaker}
                searchTermTimings={searchTermTimings}
                toggledTerm={toggledTerm}
                hoveredTerm={hoveredTerm}
                seekTime={seekTime}
              />
            </g>
            <TimelinePlayhead
              seekTime={seekTime}
              onSeek={onSeek}
              width={plotAreaWidth}
              height={timelineHeight + timelineMargin}
              timeScale={timeScale}
            />
          </g>
          <g
            transform={`translate(0 ${
              termsHeight + termsMargin + timelineHeight + timelineMargin
            })`}
          >
            <HighlightsBar
              width={plotAreaWidth}
              height={highlightsBarHeight}
              timeScale={timeScale}
              onPlay={onPlay}
              nameWidth={nameWidth}
              highlights={conversation.highlights || []}
              onViewHighlights={onViewHighlights}
            />
          </g>
          {!isLoadingTopTerms && (
            <g>
              <TimelineTerms
                bins={termBins}
                onSeek={onSeek}
                annotationY={termsHeight + termsMargin}
                timeScale={timeScale}
                termHeight={termHeight}
                toggledTerm={toggledTerm}
                onToggleTerm={onToggleTerm}
                searchTermTimings={searchTermTimings}
                hoveredTerm={hoveredTerm}
                onHoverTerm={setHoveredTerm}
              />
            </g>
          )}
        </g>
      </svg>
    </div>
  );
};

export default ConversationTimeline;
