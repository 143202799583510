export const checkForSessionMetadata = () => {
  const storedMetadata = sessionStorage.getItem('recordingRoomMetadata');

  return !!storedMetadata;
};

export const checkFeatureSupport = () => {
  const supportsWakeLock = checkWakeLockSupport();
  const supportsMicPermissioning = checkForMicPermissioning();
  const checkBrowser = determineBrowser();

  if (
    !supportsWakeLock ||
    !supportsMicPermissioning ||
    checkBrowser === 'Firefox'
  ) {
    return false;
  }

  return true;
};

const checkWakeLockSupport = async () =>
  'wakeLock' in navigator && 'request' in navigator.wakeLock;

const checkForMicPermissioning = async () => {
  try {
    await navigator.permissions.query({
      name: 'microphone' as PermissionName,
    });
  } catch (error) {
    console.error('Error when checking microphone permission:', error);
  }
};

const determineBrowser = () => {
  const userAgent = navigator.userAgent;
  let browserInUse = '';

  if (userAgent.includes('Firefox')) {
    browserInUse = 'Firefox';
  } else if (userAgent.includes('Chrome')) {
    browserInUse = 'Chrome';
  } else if (userAgent.includes('Safari')) {
    browserInUse = 'Safari';
  } else if (userAgent.includes('Edge')) {
    browserInUse = 'Edge';
  } else {
    console.log('Unable to determine your browser.');
  }
  return browserInUse;
};

export const handleWakeLock = async (action: string) => {
  if ((await checkWakeLockSupport()) === false) {
    return;
  }

  let wakeLock: WakeLockSentinel | null = null;

  if (action === 'start') {
    try {
      wakeLock = await navigator.wakeLock.request('screen');
      return wakeLock;
    } catch (error) {
      console.log('wakeLock error:', error);
    }
  } else if (action === 'release') {
    wakeLock = await navigator.wakeLock.request('screen');
    wakeLock.release();
    console.log(action);
  } else {
    console.log(action);
  }
};
