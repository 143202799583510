import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import Button from 'src/components/core/Button/Button';
import { StoreState } from 'src/redux/store';
import topicsSelectors from 'src/redux/topics/topics-selectors';
import { Topic } from 'src/types/conversation';
import { ColorScale } from 'src/types/core';

import styles from './TopicsDropdown.module.scss';

interface BaseProps {
  onClear: () => void;
  onTopicSelection: (topic: Topic) => void;
  activeTopic: Topic | null;
  dropdownText: string;
  className?: string;
  topics: Topic[];
  isLoadingTopics: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  toggleClassName?: string;
  clearText?: string;
}

interface StateProps {
  topicColorScale: ColorScale | undefined;
}

type Props = BaseProps & StateProps;

const mapStateToProps = (state: StoreState): StateProps => ({
  topicColorScale: topicsSelectors.getTopicColorScale(state),
});

export const TopicsDropdown = ({
  topics,
  activeTopic,
  isLoadingTopics,
  topicColorScale,
  dropdownText,
  clearText,
  onClear,
  onTopicSelection,
  className,
  disabled,
  fullWidth,
  toggleClassName,
}: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const handleToggle = () => setIsOpen(!isOpen);
  const activeClass =
    activeTopic && topicColorScale
      ? styles[`color${topicColorScale(activeTopic.display_name)}`]
      : undefined;

  if (!topics || !topicColorScale) {
    return null;
  }

  return (
    <div className={cx(className, 'd-flex align-items-center')}>
      <Dropdown
        isOpen={isOpen}
        toggle={handleToggle}
        className={cx(styles.dropdownContainer, activeClass, 'bg-white', {
          'w-100': fullWidth,
        })}
      >
        <DropdownToggle
          color="''"
          caret
          data-testid="topics-dropdown-toggle"
          className={cx(
            { fwmedium: activeTopic },
            { 'w-100': fullWidth },
            'justify-content-between'
          )}
          disabled={disabled || topics.length < 1}
        >
          {activeTopic ? (
            <>
              <FontAwesomeIcon
                icon="circle"
                className={cx(styles.toggleTopicIndicator, 'me-2 fwmedium')}
                aria-hidden={true}
              />
              <span className={cx(styles.toggleText, 'me-1', toggleClassName)}>
                {activeTopic.display_name}
              </span>
            </>
          ) : (
            <span className={cx(styles.toggleText, 'me-1', toggleClassName)}>
              {isLoadingTopics ? t('common.loading') : dropdownText}
            </span>
          )}
        </DropdownToggle>
        <DropdownMenu
          className={cx(styles.menu, 'w-100')}
          data-testid="topics-dropdown-menu"
          style={{ maxHeight: '25rem', overflow: 'auto' }}
          flip={false}
        >
          {activeTopic && (
            <DropdownItem
              data-testid="topics-dropdown-clear-item"
              onClick={onClear}
            >
              <FontAwesomeIcon
                icon={['far', 'times']}
                className={cx(styles.clearIcon, 'ms-1')}
                aria-hidden={true}
              />
              {clearText ? clearText : t('conversation.topic_dropdown_clear')}
            </DropdownItem>
          )}
          {topics.map((topic) => {
            const { display_name, id, name, count } = topic;
            const isActiveTopic = activeTopic ? id === activeTopic.id : false;
            const color = topicColorScale
              ? styles[`color${topicColorScale(display_name)}`]
              : undefined;

            return (
              <DropdownItem
                key={`${name}-item`}
                data-testid={`${name}-item`}
                data-topic={name}
                className={cx({ [styles.active]: isActiveTopic })}
                onClick={() => onTopicSelection(topic)}
              >
                <FontAwesomeIcon
                  icon={['far', 'circle']}
                  className={cx(styles.topicIndicator, color, 'me-2')}
                  aria-hidden={true}
                />
                {display_name} {count != null && `(${count})`}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </Dropdown>
      {activeTopic && (
        <div>
          <Button
            color="basic"
            icon={['far', 'times']}
            className={cx(styles.clearIcon, { invisible: !activeTopic })}
            title={t('common.clear')}
            onClick={onClear}
            data-testid="topics-dropdown-clear-button"
          />
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(TopicsDropdown);
