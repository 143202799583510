import React from 'react';
import { BaseEditor, Descendant, Location, Transforms } from 'slate';
import { HistoryEditor } from 'slate-history';
import { ReactEditor } from 'slate-react';

/**
 * A React hook to add accessibility to our transcript editor
 * @param value - the value of the transcript editor
 * @param editor - the editor object
 * @param ref - the ref for the transcript editor container
 */
export const useAccessibility = (
  value: Descendant[],
  editor: BaseEditor & ReactEditor & HistoryEditor,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  // Grabs all the paragraph nodes present in the dom
  const getParagraphs = React.useCallback(
    () =>
      (ref as React.MutableRefObject<HTMLDivElement>)?.current.querySelectorAll(
        'p[data-slate-node="element"]'
      ) ?? [],
    [ref]
  );

  React.useEffect(() => {
    if (value) {
      // When the value changes, ensure all the paragraphs have a tab index
      getParagraphs().forEach((p) => {
        p.setAttribute('tabindex', '0');
      });
    }
  }, [value, getParagraphs]);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'ArrowRight') {
      // When the right arrow is clicked, find the index of the paragraph
      let nodeIndex;
      getParagraphs().forEach((paragraph, i) => {
        if (paragraph === e.target) {
          nodeIndex = i;
        }
      });
      if (nodeIndex) {
        // If the paragraph is found, set the editor selcetion to the beginning of that paragraph
        const newSelection: Location = {
          anchor: { path: [nodeIndex, 0], offset: 0 },
          focus: { path: [nodeIndex, 0], offset: 0 },
        };
        Transforms.select(editor, newSelection);
      }
    }
    if (e.key === 'Tab') {
      const paragraphs = getParagraphs();
      const path = editor.selection?.focus.path[0];
      if (path) {
        // If tab is pressed, and there is a selection in the editor, focus the element of the selection
        (paragraphs[path] as HTMLParagraphElement).focus();
        editor.selection = null;
        e.preventDefault();
      } else {
        return;
      }
    }
  };

  return { handleKeyPress };
};
