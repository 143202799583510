/*
 * Various date formatting functions
 */
import 'src/internationalization/moment';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { default as moment, Moment } from 'moment-timezone';

/**
 * Function to get the current slash date format
 * @returns string of current date format
 */
export function useSlashDateFormat() {
  const { i18n } = useTranslation();

  const [dateFormat, setDateFormat] = React.useState<string>('');

  React.useEffect(() => {
    switch (i18n.language) {
      case 'es-US':
        setDateFormat('MM/DD/YYYY'); // Will need to be changed after refactoring moment
        break;

      default:
        // en-US
        setDateFormat('MM/DD/YYYY');
        break;
    }
  }, [i18n.language]);

  return React.useMemo(() => {
    return dateFormat;
  }, [dateFormat]);
}

/**
 * Date formatting without time
 * "August 13" for 08/13/2019
 * "August 29, 2018" for 08/29/2018
 * "Monday, August 29, 2018"
 *
 * Force the inclusion of the year with includeYear option
 * e.g. dateFormat(date, {includeYear: true})
 * Force the inclusion of the day name with includeDayName option
 * e.g. dateFormat(date, {includeDayName: true})
 */
function dateFormat(
  date: Moment | undefined,
  options?: {
    includeDayName?: boolean;
    includeYear?: boolean;
  }
) {
  if (date == null) {
    return undefined;
  }

  if (!moment.isMoment(date)) {
    // If date isn't moment object, attempt to convert it
    date = timeToMoment(date);
  }

  const CURRENT_YEAR = moment().year();

  const includeYear =
    date.year() !== CURRENT_YEAR || (options && options.includeYear);

  const includeDay = options && options.includeDayName;

  if (includeDay && includeYear) {
    return date.format('dddd, LLL'); // dddd, MMMM D, YYYY
  } else if (includeDay) {
    return date.format('dddd, LL'); // dddd, MMMM D
  } else if (includeYear) {
    return date.format('LLL'); // MMMM D, YYYY
  } else {
    return date.format('LL'); // MMMM D
  }
}

/* Format date to be used in ics files */
function icsDateStringFormat(date: Moment) {
  const fmtString = 'YYYYMMDDTHHmmssZ';
  const formattedDate = moment.utc(date).format(fmtString);
  return formattedDate.replace('+00:00', 'Z');
}

/** If less than an N days ago, use relative, else use absolute */
function relativeAbsDateFormat(date: Moment | undefined, numDays = 3) {
  if (date == null) {
    return undefined;
  }

  const now = moment();
  const delta = now.diff(date, 'days');
  // use absolute if date has past and is not within numdays (exclusive)
  if (delta > numDays) {
    // if a different year, show the year
    if (now.year() !== date.year()) {
      return date.format('lll'); // MMM D, YYYY
    }
    return date.format('ll'); // MMM D
  }

  // show relative date for any date in future or within past numdays (exclusive)
  return date.fromNow();
}

/**
 * Converts iso time to moment
 * @param time string of time e.g. 2018-12-11T19:59:38.375339
 */
function timeToMoment(time: string): Moment {
  // safety checks
  if (time == null || !time.indexOf) {
    return moment();
  }

  // if it has milliseconds:
  if (time.indexOf('.') !== -1) {
    return moment(`${time}+0000`, 'lTHH:mm:ss.SSSSZ');
  }
  return moment(`${time}+0000`, 'lTTHH:mm:ssZ');
}

const dateToISOString = (date: Date) => moment(date).format('llll');

/**
 * Converts moment to a time string. Times come with meridiem (am/pm).
 * When using time zone, you must send a moment object with a timezone attached.
 * ie. moment().tz()
 * @param date moment object
 * @param options.timeZone optional parameter to add time zone to the string
 * @param options.withDate optional parameter to add date to the front of time
 * @param options.seconds optional parameter to add seconds to the string
 * @param options.shortTime optional parameter to return a shortened version of the time
 */
function timeFromMoment(
  date: Moment,
  options?: {
    timeZone?: boolean;
    withDate?: boolean;
    seconds?: boolean;
    shortTime?: boolean;
  }
): string {
  if (options?.shortTime) {
    // We don't want the meridierm (am/pm)
    return date.format('h:mm');
  }
  const format = `${options?.withDate ? 'lll, ' : ''}${
    options?.timeZone
      ? options?.seconds
        ? 'LTS'
        : 'LT'
      : options?.seconds
      ? 'h:mm:ss'
      : 'h:mm a'
  }`;
  return date.format(format);
}

/**
 * Converts moment to a numerical date. defaults to slash (MM/DD/YYY)
 * @param date moment object
 * @param dashed optional parameter to used dashed date rather than slashed date
 * @returns string of numerical date
 */
function numericalDateFormat(
  date: Moment,
  options?: { dashed?: true }
): string {
  if (options?.dashed) {
    return date.format('l');
  }
  return date.format('L');
}

export default {
  dateFormat,
  icsDateStringFormat,
  relativeAbsDateFormat,
  timeToMoment,
  dateToISOString,
  timeFromMoment,
  numericalDateFormat,
};
