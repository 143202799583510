import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import cx from 'classnames';
import { FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';

import Button from 'src/components/core/Button/Button';
import LoadingSpinner from 'src/components/core/LoadingSpinner/LoadingSpinner';
import { EditTransaction } from 'src/redux/auth/auth-slice';
import { User } from 'src/types/auth';

interface FormProps {
  currentPassword: string;
  newPassword: string;
  newPasswordAgain: string;
  email: string;
}

interface ChangePasswordProps {
  user: User;
  passwordResetUrl: string;
  passwordTransaction?: EditTransaction;
  onUpdateUser: (editTransaction: EditTransaction) => void;
}

const InnerForm = (props: ChangePasswordProps & FormikProps<FormProps>) => {
  const { t } = useTranslation();

  React.useEffect(() => {
    // Logic for Profile Image Update
    if (props.passwordTransaction?.isSaved) {
      props.setSubmitting(false);
      props.resetForm();
      props.setStatus({
        status: 'success',
        message: t('settings.password_success'),
      });
    }
    if (props.passwordTransaction?.error) {
      props.setSubmitting(false);
      props.setStatus(props.passwordTransaction?.error);
    }
  });

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    isSubmitting,
    errors,
    touched,
    status,
    passwordResetUrl,
  } = props;

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        {/* for accessibility: include hidden username field */}
        <Input
          hidden
          autoComplete="username"
          value={values.email}
          readOnly
        ></Input>
        <Row form={true}>
          {/* note current typings are wrong for "Row form" so we force it */}
          <Col sm={6}>
            <FormGroup>
              <Label for="currentPassword">
                {t('settings.password_current')}
              </Label>
              <Input
                type="password"
                name="currentPassword"
                id="currentPassword"
                value={values.currentPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="current-password"
                data-testid="current-password"
                aria-describedby="currentPasswordError"
                aria-invalid={Boolean(errors.currentPassword)}
              ></Input>
              {errors.currentPassword && (
                <p className="text-danger" id="currentPasswordError">
                  {errors.currentPassword}
                </p>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row form={true}>
          <Col sm={6}>
            <FormGroup>
              <Label for="newPassword">{t('settings.password_new')}</Label>
              <Input
                type="password"
                value={values.newPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                name="newPassword"
                id="newPassword"
                autoComplete="new-password"
                data-testid="new-password"
                aria-describedby="newPasswordError"
                aria-invalid={Boolean(
                  errors.newPassword && touched.newPassword
                )}
              ></Input>
              {errors.newPassword && touched.newPassword && (
                <p className="text-danger" id="newPasswordError">
                  {errors.newPassword}
                </p>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row form={true}>
          <Col sm={6}>
            <FormGroup>
              <Label for="newPasswordAgain">
                {t('settings.password_confirm')}
              </Label>
              <Input
                type="password"
                value={values.newPasswordAgain}
                onChange={handleChange}
                onBlur={handleBlur}
                name="newPasswordAgain"
                id="newPasswordAgain"
                autoComplete="new-password"
                data-testid="new-password-again"
                aria-describedby="newPasswordAgainError"
                aria-invalid={Boolean(errors.newPasswordAgain)}
              ></Input>
              {errors.newPasswordAgain && (
                <p className="text-danger" id="newPasswordAgainError">
                  {errors.newPasswordAgain}
                </p>
              )}
            </FormGroup>
          </Col>
        </Row>

        <FormGroup>
          <Button
            type="submit"
            disabled={
              isSubmitting ||
              !!errors.newPassword ||
              !!errors.newPasswordAgain ||
              !(
                values.newPassword &&
                values.newPasswordAgain &&
                values.currentPassword
              )
            }
            color="primary"
            className="me-2 mb-2"
            data-testid="password-submit-button"
            aria-label="update password"
          >
            {t('settings.update')}
          </Button>
          <a
            href={passwordResetUrl}
            rel="noopener noreferrer"
            target="_blank"
            className="me-2"
          >
            {t('settings.password_forgot')}
          </a>
          <LoadingSpinner active={isSubmitting} />
        </FormGroup>
        {status && (
          <p
            className={cx({ 'text-danger': status.status === 'fail' })}
            data-testid="password-submit-status"
          >
            {status.message}
          </p>
        )}
      </Form>
    </div>
  );
};

const ChangePassword = withFormik<ChangePasswordProps, FormProps>({
  // set initial values
  mapPropsToValues: ({ user }: ChangePasswordProps) => ({
    currentPassword: '',
    newPassword: '',
    newPasswordAgain: '',
    email: user.email,
  }),

  validationSchema: Yup.object().shape({
    currentPassword: Yup.string().required('Current password is required.'),
    newPassword: Yup.string()
      .required('New password is required.')
      .min(8, 'Password should be at least 8 characters'),
    newPasswordAgain: Yup.string().oneOf(
      [Yup.ref('newPassword'), ''],
      'Passwords must match.'
    ),
  }),

  handleSubmit(
    { currentPassword, newPassword }: FormProps,
    { props, setSubmitting }
  ) {
    setSubmitting(true);
    props.onUpdateUser({
      userId: props.user.id,
      changes: {
        current_password: currentPassword,
        new_password: newPassword,
      },
      editType: 'password',
    });
  },

  validateOnChange: false,
  enableReinitialize: true,
})(InnerForm);

export default ChangePassword;
