// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteProps,
  Chip,
  TextField,
} from '@mui/material';

interface FilterAutocompleteProps<T> {
  items: T[];
  selected: T[];
  optionLabelField: keyof T;
  filterLabel: string;
  onChange: (
    event: any,
    newValues: T[],
    reason: AutocompleteChangeReason
  ) => void;
}

const MAX_TAGS_IN_SEARCH_BOX = 4;

const FilterAutocomplete = <T extends { id: number }>(
  props: Omit<AutocompleteProps<T>, 'onChange'> & FilterAutocompleteProps<T>
) => {
  const { t } = useTranslation();
  const {
    items,
    optionLabelField,
    selected,
    filterLabel,
    onChange,
    ...autocompleteProps
  } = props;

  return (
    <Autocomplete
      data-testid={`Filter-${filterLabel}`}
      getOptionLabel={(option: T) => option[optionLabelField] as string}
      limitTags={MAX_TAGS_IN_SEARCH_BOX}
      multiple
      onChange={onChange}
      options={items}
      renderInput={(params) => (
        <TextField {...params} placeholder={filterLabel} variant="outlined" />
      )}
      renderOption={(props: any, option: T) => {
        return (
          <li {...props} key={`${filterLabel}-${option.id}`}>
            {option[optionLabelField]}
          </li>
        );
      }}
      renderTags={(value: T[], getTagProps) => {
        const numTags = value.length;
        return (
          <React.Fragment>
            {value.slice(0, MAX_TAGS_IN_SEARCH_BOX).map((val, index) => (
              <Chip label={val[optionLabelField]} {...getTagProps({ index })} />
            ))}
            {numTags > MAX_TAGS_IN_SEARCH_BOX && (
              <Chip
                data-testid="maximum-tag-chip"
                label={t('insights.maximum_tag_chip', {
                  count: numTags - MAX_TAGS_IN_SEARCH_BOX,
                })}
                variant="outlined"
              />
            )}
          </React.Fragment>
        );
      }}
      value={selected}
      {...autocompleteProps}
    />
  );
};

export default FilterAutocomplete;
