import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { animated, useSpring } from 'react-spring';
import { useMeasure } from 'react-use';
import cx from 'classnames';

import Button from 'src/components/core/Button/Button';
import ExpandingButton from 'src/components/core/ExpandingButton/ExpandingButton';
import Transcript, {
  Props as TranscriptProps,
} from 'src/components/Transcript/Transcript';

import styles from './ExpandingTranscript.module.scss';

// in px
const SHOW_MORE_HEIGHT_LIMIT = 104; // max size of transcript before we render "show more" btn
const COLLAPSED_MAX_HEIGHT = 97; // max height to apply to collapsed transcripts
const BUTTON_HEIGHT = 38;

interface Props extends TranscriptProps {
  expanded: boolean;
  toggleExpanded: () => void;
  noBtnClassName?: string; // classname applied to the div where the expand btn would be when the transcript is too short to expand
}

const ExpandingTranscript = ({
  expanded,
  toggleExpanded,
  className,
  ...other
}: Props) => {
  const { t } = useTranslation();
  const [ref, { height }] = useMeasure();
  const showMoreBtn = height > SHOW_MORE_HEIGHT_LIMIT;
  const showMoreBtnText = expanded
    ? t('collection.see_less')
    : t('collection.see_more');
  const animatedProps = useSpring({
    maxHeight: expanded ? height + BUTTON_HEIGHT : COLLAPSED_MAX_HEIGHT,
  });

  return (
    <>
      <animated.div
        className={styles.transcriptContainer}
        style={animatedProps}
      >
        <Transcript
          {...other}
          hideAdditionalInfo
          className={cx(
            styles.transcript,
            {
              [styles.collapsed]: !expanded,
            },
            className
          )}
          transcriptRef={ref}
        />
      </animated.div>
      <div className="position-relative">
        {showMoreBtn && (
          <ExpandingButton
            tag={Button}
            icon={expanded ? ['fas', 'chevron-up'] : ['fas', 'chevron-down']}
            title={showMoreBtnText}
            onClick={toggleExpanded}
            className={cx('insights-btn mt-1', styles.moreBtn)}
            expanded={expanded}
          >
            {showMoreBtnText}
          </ExpandingButton>
        )}
      </div>
    </>
  );
};

export default ExpandingTranscript;
