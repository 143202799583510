import React from 'react';
import cx from 'classnames';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import { avatarLabel, fullName } from 'src/components/AdminPage/Common/utils';
import BgImage from 'src/components/BgImage/BgImage';
import Button from 'src/components/core/Button/Button';
import { User } from 'src/types/auth';
import CommunityMemberToggle from '../../CommunityMemberToggle/CommunityMemberToggle';
import { TeamPageName } from '../../ManageTeam';
import UpdateOrganizationRoleDropdown from '../../PillDropdowns/OrganizationRoleDropdown';
import RemoveMemberButton from '../../RemoveMemberButton/RemoveMemberButton';
import UserStatus from '../../UserStatus/UserStatus';

import styles from './TeamMemberRow.module.scss';

interface TeamMemberRowProps {
  members: User[];
  member: User;
  isFirst?: boolean;
}

const ROW_HEIGHT = 60;

const TeamMemberRow = ({
  members,
  member,
  isFirst = false,
}: TeamMemberRowProps) => {
  // These Refs are needed because the whole row is a button, and we need to position the
  // other buttons in the row on top of the row button.
  const organizationDiv = React.useRef<HTMLDivElement>(null);
  const removeDiv = React.useRef<HTMLDivElement>(null);
  const statusDiv = React.useRef<HTMLDivElement>(null);
  const communityDiv = React.useRef<HTMLDivElement>(null);
  const [removePosition, setRemovePosition] = React.useState<{
    top: number;
    left: number;
  }>();
  const [organizationPosition, setOrganizationPosition] = React.useState<{
    top: number;
    left: number;
  }>();
  const [statusPosition, setStatusPosition] = React.useState<{
    top: number;
    left: number;
  }>();
  const [communityPosition, setCommunityPosition] = React.useState<{
    top: number;
    left: number;
  }>();

  React.useLayoutEffect(() => {
    const updatePositions = () => {
      setRemovePosition({
        top: (removeDiv.current?.offsetTop ?? 0) - ROW_HEIGHT / 5,
        left: removeDiv.current?.offsetLeft ?? 0,
      });
      setOrganizationPosition({
        top: (organizationDiv.current?.offsetTop ?? 0) - ROW_HEIGHT / 5,
        left: organizationDiv.current?.offsetLeft ?? 0,
      });
      setStatusPosition({
        top: (statusDiv.current?.offsetTop ?? 0) - ROW_HEIGHT / 5,
        left: statusDiv.current?.offsetLeft ?? 0,
      });
      setCommunityPosition({
        top: (communityDiv.current?.offsetTop ?? 0) - ROW_HEIGHT / 5,
        left: communityDiv.current?.offsetLeft ?? 0,
      });
    };
    if (organizationDiv && removeDiv && statusDiv && communityDiv) {
      updatePositions();
    }
    window.addEventListener('resize', updatePositions);
    return () => {
      window.removeEventListener('resize', updatePositions);
    };
  }, [organizationDiv, removeDiv, statusDiv, communityDiv, members]);

  const avatarStr = avatarLabel(member);
  const memberFullName = fullName(member);
  const [, setQuery] = useQueryParams({
    userId: NumberParam,
    page: StringParam,
  });

  // If the row is the first row in the list, remove the borders
  const rowStyle = isFirst ? 'border-0 p-0' : ' border-top p-0';

  const onPageChage = (userId: number, page: TeamPageName) => {
    setQuery({ userId, page }, 'pushIn');
  };

  return (
    <>
      <Button
        outline
        className={cx(styles.tr, 'w-100 px-0 py-2 shadow-none row', rowStyle)}
        onClick={() => {
          onPageChage(member.id, 'view');
        }}
        data-testid={`team-member-button-${member.id}`}
        style={{ height: `${ROW_HEIGHT} px` }}
      >
        {/* Name Column */}
        <div className="col-4 p-0">
          <div className="shadow-none ps-3 w-100 d-flex justify-content-start">
            {member.profile_image ? (
              <BgImage
                src={member.profile_image}
                className={cx('rounded-circle me-3')}
                width="40px"
                height="40px"
                vignette
              />
            ) : (
              <div
                className={cx(
                  'rounded-circle me-3 d-flex justify-content-center align-items-center',
                  styles.avatar
                )}
              >
                {avatarStr}
              </div>
            )}
            <div className="d-flex flex-column text-start">
              <span className={styles.name}>{memberFullName}</span>
              <span>{member.email}</span>
            </div>
          </div>
        </div>
        {/* OrganizationRole Column */}
        <div className={cx(styles.full, 'col-2 p-0')} ref={organizationDiv} />
        {/* Status Column */}
        <div className={cx(styles.full, 'col-2 p-0')} ref={statusDiv} />
        {/* Community Member Column */}
        <div className={cx(styles.full, 'col-1 p-0')} ref={communityDiv} />
        {/* Created Column */}
        <div className="col-2 p-0 text-start">
          {member.date_joined.fromNow()}
        </div>
        {/* Remove Column */}
        <div className={cx(styles.full, 'col-1 p-0')} ref={removeDiv} />
      </Button>
      {organizationDiv && (
        <div style={{ position: 'absolute', ...organizationPosition }}>
          <UpdateOrganizationRoleDropdown member={member} />
        </div>
      )}
      {statusDiv && (
        <div style={{ position: 'absolute', ...statusPosition }}>
          <UserStatus user={member} />
        </div>
      )}
      {communityDiv && (
        <div style={{ position: 'absolute', ...communityPosition }}>
          <CommunityMemberToggle member={member} />
        </div>
      )}
      {removeDiv && (
        <div
          className={`text-center h-full width-full`}
          style={{ position: 'absolute', margin: 'auto', ...removePosition }}
        >
          <RemoveMemberButton useIcon member={member} />
        </div>
      )}
    </>
  );
};

export default TeamMemberRow;
