import { Trans } from 'react-i18next';
import { Button, lighten, Typography } from '@mui/material';

import Color from 'src/assets/_util.scss';
import { HStack, VStack } from 'src/components/core/Stack';
import { useHighlightsPageContext } from 'src/components/Insights/Catalog/HighlightsPageProvider';

export function TaggingBannerDetails() {
  const { taggingProgress, stopTaggingWithAI, t } = useHighlightsPageContext();
  const {
    succeededCount,
    failedCount,
    canceledCount,
    emptyCount,
    totalCount,
    percent,
  } = taggingProgress;

  const shouldShowSucceeded = !!succeededCount || percent < 100;

  return (
    <HStack
      style={{
        margin: '12px 0 0',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '16px',
      }}
    >
      <VStack>
        {shouldShowSucceeded && (
          <Typography variant="body1">
            <Trans
              i18nKey="insights.taggingProgressDetailsSucceeded"
              values={{ succeededCount, totalCount }}
              components={{ b: <b /> }}
            />
          </Typography>
        )}

        {!!emptyCount && (
          <Typography variant="body1">
            <Trans
              i18nKey="insights.taggingProgressDetailsEmpty"
              values={{ emptyCount, totalCount }}
              components={{ b: <b /> }}
            />
          </Typography>
        )}

        {!!failedCount && (
          <Typography variant="body1">
            <Trans
              i18nKey="insights.taggingProgressDetailsFailed"
              values={{ failedCount, totalCount }}
              components={{ b: <b /> }}
            />
          </Typography>
        )}

        {!!canceledCount && (
          <Typography variant="body1">
            <Trans
              i18nKey="insights.taggingProgressDetailsCanceled"
              values={{ canceledCount, totalCount }}
              components={{ b: <b /> }}
            />
          </Typography>
        )}
      </VStack>

      <Button
        sx={{
          background: Color.danger,
          color: Color.white,
          border: 'none',
          borderRadius: '8px',
          display: percent === 100 ? 'none' : 'block',
          textTransform: 'none',
          ':hover': {
            background: lighten(Color.danger, 0.2),
          },
        }}
        onClick={stopTaggingWithAI}
      >
        {t('common.stop')}
      </Button>
    </HStack>
  );
}
