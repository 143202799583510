import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Button, Col, Container, Label, Row } from 'reactstrap';
import cx from 'classnames';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';

import LoadingSpinner from 'src/components/core/LoadingSpinner/LoadingSpinner';
import adminSelectors from 'src/redux/admin/admin-selectors';
import {
  setCommunity,
  updateCommunityDetails,
} from 'src/redux/admin/admin-slice';
import { StoreState } from 'src/redux/store';
import { Community } from 'src/types/forum';
import CommunityDropdown from '../../CommunityDropdown/CommunityDropdown';

import styles from './CommunityDetails.module.scss';

interface CommunityDetailsFormProps {
  name?: string;
  description?: string;
}

interface StateProps {
  communities: Community[];
  activeCommunity: Community | undefined;
}

type Props = StateProps & CommunityDetailsFormProps;

const mapStateToProps = (state: StoreState): StateProps => ({
  communities: adminSelectors.getCommunities(state),
  activeCommunity: adminSelectors.getActiveCommunity(state),
});

const CommunityDetails = ({ communities, activeCommunity }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isLoading = useSelector(adminSelectors.isLoadingUsers);
  const isUpdating = useSelector(adminSelectors.getIsCommunityUpdating);

  const initialValues: CommunityDetailsFormProps = {
    name: activeCommunity?.name,
    description: activeCommunity?.description,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().max(30, 'Name must be 30 characters or less'),
    description: Yup.string()
      .trim()
      .max(300, 'Description must be 300 characters or less'),
  });

  const handleSubmitForm = (changes: CommunityDetailsFormProps) => {
    if (activeCommunity && activeCommunity.id !== null) {
      dispatch(
        updateCommunityDetails({ communityId: activeCommunity.id, changes })
      );
    }
  };

  const handleCommunityChange = (communityId: number) => {
    const newCommunity = communities.find(
      (community) => community.id === communityId
    );
    if (newCommunity) {
      dispatch(setCommunity(newCommunity));
    }
  };

  if (!isLoading && !communities.length) {
    return <div>{t('admin.tab_communities_no_admin_access')}</div>;
  }

  return (
    <Container className={cx(styles.formContainer)}>
      <Container fluid>
        {isLoading ? (
          <div className="w-100 text-center">
            <LoadingSpinner thick size="lg" active theme="dark" />
          </div>
        ) : (
          <Row xs={12}>
            <Col xs={6}>
              <CommunityDropdown
                communities={communities}
                activeCommunity={activeCommunity}
                onChange={handleCommunityChange}
                headerTag="h1"
                className="mb-3"
                headerClassname="h2"
              />
            </Col>
            <Col xs={6}>
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmitForm}
                validationSchema={validationSchema}
              >
                {(props) => (
                  <form onSubmit={props.handleSubmit}>
                    <div className={cx(styles.formField)}>
                      <Label for="name" className={styles.label}>
                        {t('common.name')}
                      </Label>
                      <Field
                        name="name"
                        id="name"
                        type="text"
                        component="input"
                        onChange={props.handleChange}
                        value={props.values.name}
                        data-testid="name_input"
                        className={cx('form-control', styles.h40)}
                      />
                      <div className={styles.errors}>
                        <div>
                          {t('admin.tab_community_name_limit', {
                            nameCharacters: props.values?.name?.length ?? 0,
                          })}
                        </div>
                        {props.errors.name && <div>{props.errors.name}</div>}
                      </div>
                    </div>
                    <div className={cx(styles.formField)}>
                      <Label for="description" className={styles.label}>
                        {t('common.description')}
                      </Label>
                      <Field
                        name="description"
                        id="description"
                        type="text"
                        component="textarea"
                        onChange={props.handleChange}
                        value={props.values.description}
                        data-testid="description_input"
                        className={cx('form-control', styles.h70)}
                      />
                      <div className={styles.errors}>
                        <div>
                          {t('admin.tab_community_description_limit', {
                            descriptionCharacters:
                              props.values?.description?.length ?? 0,
                          })}
                        </div>
                        {props.errors.description && (
                          <div>{props.errors.description}</div>
                        )}
                      </div>
                    </div>
                    <div className={styles.buttonContainer}>
                      <Button
                        color="primary"
                        type="submit"
                        disabled={
                          !props.dirty ||
                          !!props.errors.name ||
                          !!props.errors.description
                        }
                        data-testid="team-member-update"
                        className={cx(styles.button)}
                      >
                        {isUpdating ? (
                          <LoadingSpinner active size="md" />
                        ) : (
                          t('common.update')
                        )}
                      </Button>
                      <Button
                        color="secondary"
                        onClick={() =>
                          props.resetForm({
                            values: {
                              name: activeCommunity?.name,
                              description: activeCommunity?.description,
                            },
                          })
                        }
                        disabled={!props.dirty || isUpdating}
                        data-testid="team-member-cancel"
                        className={cx(styles.button)}
                      >
                        {t('common.cancel')}
                      </Button>
                    </div>
                  </form>
                )}
              </Formik>
            </Col>
          </Row>
        )}
      </Container>
    </Container>
  );
};

export default connect(mapStateToProps)(CommunityDetails);
