import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import { Link } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import cx from 'classnames';

import Button from 'src/components/core/Button/Button';
import LoadingOverlay from 'src/components/core/LoadingOverlay/LoadingOverlay';
import ExpandedHighlightCard from 'src/components/ExpandedHighlightCard/ExpandedHighlightCard';
import highlightSelectors from 'src/redux/highlight/highlight-selectors';
import { loadExploreHighlights } from 'src/redux/highlight/highlight-slice';
import { StoreState } from 'src/redux/store';
import { Highlight } from 'src/types/conversation';

import styles from './StackedHighlightCards.module.scss';

interface BaseProps {
  collectionId: number;
  linkToAllHighlights?: boolean;
  className?: string;
}

interface StateProps {
  exploreHighlights: Highlight[];
  isLoadingExploreHighlights: boolean;
  exploreHighlightsError: Error | undefined;
}

const mapStateToProps = (state: StoreState): StateProps => ({
  exploreHighlights: highlightSelectors.getExploreHighlights(state),
  isLoadingExploreHighlights:
    highlightSelectors.isLoadingExploreHighlights(state),
  exploreHighlightsError: highlightSelectors.getErrorExploreHighlights(state),
});

type Props = BaseProps & StateProps & DispatchProp;

const NUM_CARDS_TO_LOAD = 5;
const ANIMATION_TIMEOUT = 500;

export const StackedHighlightCards = ({
  exploreHighlights,
  isLoadingExploreHighlights,
  collectionId,
  dispatch,
  className,
  linkToAllHighlights = true,
}: Props) => {
  const { t } = useTranslation();
  const [cardIndex, setCardIndex] = React.useState(0);
  const cardIndices = [cardIndex + 2, cardIndex + 1, cardIndex];

  const handleNextCard = React.useCallback(() => {
    // if we are almost at the end of highlights we have available, try to fetch more
    // we check the length - 3 to assure we have two highlights for the UI, not including the current card in view
    if (cardIndex === exploreHighlights.length - 3) {
      dispatch(
        loadExploreHighlights({
          limit: NUM_CARDS_TO_LOAD,
          collectionIds: [collectionId],
        })
      );
    }
    if (cardIndex < exploreHighlights.length) {
      setCardIndex(cardIndex + 1);
    }
  }, [collectionId, dispatch, exploreHighlights, cardIndex]);

  const handlePreviousCard = React.useCallback(() => {
    // go back to the previous card if possible
    if (cardIndex > 0) {
      setCardIndex(cardIndex - 1);
    }
  }, [cardIndex]);

  // load initial set of cards and on collection change
  React.useEffect(() => {
    dispatch(
      loadExploreHighlights({
        limit: NUM_CARDS_TO_LOAD,
        collectionIds: [collectionId],
      })
    );
    setCardIndex(0);
  }, [dispatch, collectionId]);

  // turn off horizontal overflow while animating
  const bodyOverflowTimer = React.useRef<number | undefined>(undefined);
  const hideBodyOverflow = () => {
    // restart the timer if there is one already running
    if (bodyOverflowTimer != null) {
      clearTimeout(bodyOverflowTimer.current);
    }
    document.body.style.overflowX = 'hidden';

    // clear after the animation finishes
    bodyOverflowTimer.current = window.setTimeout(() => {
      document.body.style.overflowX = '';
    }, ANIMATION_TIMEOUT * 1.2);
  };

  if (exploreHighlights.length === 0 && !isLoadingExploreHighlights) {
    return (
      <div className={cx(className, styles.stack)}>
        <div className="d-flex align-items-center justify-content-center text-gray-700 rounded card shadow p-0 h-100">
          {t('highlights.none')}
        </div>
      </div>
    );
  }

  return (
    <div className={className} data-testid="stackedHighlightCards">
      <div className={cx(styles.stack, 'mb-3')}>
        <TransitionGroup>
          {cardIndices.map((cardIndex, i) => {
            const isTopCard = i === cardIndices.length - 1;
            const childTabIndex = !isTopCard ? -1 : 0;
            const highlight = exploreHighlights[cardIndex];
            const shouldRenderHighlight =
              highlight && i >= cardIndices.length - 2;

            return (
              <CSSTransition
                key={cardIndex}
                classNames={{
                  enter: styles.cardEnter,
                  exit: styles.cardExit,
                }}
                timeout={ANIMATION_TIMEOUT}
                onExit={hideBodyOverflow}
              >
                <div
                  className={cx(styles.card, styles[`card-${i + 1}`], {
                    [styles.topCard]: isTopCard,
                  })}
                  //hides bottom card from screen reader, but still tabable
                  aria-hidden={!isTopCard}
                >
                  {isLoadingExploreHighlights ? (
                    <div className="rounded card shadow p-0 h-100">
                      <LoadingOverlay bgColor="transparent" active />
                    </div>
                  ) : shouldRenderHighlight ? (
                    <ExpandedHighlightCard
                      highlight={exploreHighlights[cardIndex]}
                      tabIndex={childTabIndex}
                    />
                  ) : (
                    <div className="rounded card shadow p-0 h-100" />
                  )}
                </div>
              </CSSTransition>
            );
          })}
        </TransitionGroup>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <div>
          <Button
            color="fora-purple"
            className="me-2"
            onClick={handleNextCard}
            disabled={cardIndex === exploreHighlights.length - 1}
            data-testid="next-button"
          >
            {t('highlights.next')}
          </Button>
          {/* Only show the undo button if we are not looking at the first card */}
          {cardIndex > 0 && (
            <Button
              icon={['far', 'arrow-left']}
              onClick={handlePreviousCard}
              data-testid="undo-button"
              aria-label={'Previous Highlight'}
            />
          )}
        </div>
        {linkToAllHighlights && (
          <Link to={`/highlights/?c=${collectionId}`}>
            {t('highlights.explore')}
          </Link>
        )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(StackedHighlightCards);
