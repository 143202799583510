import React from 'react';
import { BaseEditor, Editor, Selection, Transforms } from 'slate';
import { HistoryEditor } from 'slate-history';
import { ReactEditor } from 'slate-react';

import { Annotation } from 'src/types/conversation';
import { EditableParagraph } from 'src/types/transcript';

/**
 * This function enables selection handling in slate (used mainly for redactions)
 * @param editor - Slate Editor
 * @returns - values and functions necessarry for redactions in the editor
 */
const useTranscriptSelection = (
  editor: BaseEditor & ReactEditor & HistoryEditor
) => {
  React.useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const editorElements = document.querySelectorAll(
        '[data-ignore-click="true"]'
      ); // The elements to ignore on screen
      const redactionModal = document.querySelector(
        '[data-testid="redaction-modal"]'
      ); // The elements to ignore on screen
      let ignoreClick = false;
      editorElements.forEach((el) => {
        if (el === e.target || el.contains(e.target as HTMLElement)) {
          ignoreClick = true;
        }
      });

      if (
        redactionModal === e.target ||
        redactionModal?.contains(e.target as HTMLElement)
      ) {
        ignoreClick = true;
      }

      if ((e.target as HTMLElement).getAttribute('data-ignore-click')) {
        ignoreClick = true;
      }

      if (!ignoreClick) {
        Transforms.deselect(editor);
        setAnnotation(undefined);
      }
    };

    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  });
  const [isRedacting, setIsRedacting] = React.useState(false);
  const [newSelection, setNewSelection] = React.useState<Selection>();
  const [annotation, setAnnotation] =
    React.useState<
      Pick<Annotation, 'audio_start_offset' | 'audio_end_offset'>
    >();
  const [wordsToRedact, setWordsToRedact] = React.useState<string>();

  const handleSelect = () => {
    if (
      editor.selection &&
      editor.selection.anchor.path !== editor.selection.focus.path
    ) {
      const anchor = (
        editor.children[editor.selection.anchor.path[0]] as EditableParagraph
      ).children[editor.selection.anchor.path[1]];
      const focus = (
        editor.children[editor.selection.focus.path[0]] as EditableParagraph
      ).children[editor.selection.focus.path[1]];
      const allTimes = [
        anchor.audio_start_offset,
        anchor.audio_end_offset,
        focus.audio_start_offset,
        focus.audio_end_offset,
      ];
      const audio_start_offset = Math.min(...allTimes);
      const audio_end_offset = Math.max(...allTimes);
      let newSelection: Selection;
      if (audio_start_offset === anchor.audio_start_offset) {
        newSelection = {
          anchor: { path: editor.selection.anchor.path, offset: 0 },
          focus: {
            path: editor.selection.focus.path,
            offset: focus.text.length,
          },
        };
      } else {
        newSelection = {
          anchor: { path: editor.selection.focus.path, offset: 0 },
          focus: {
            path: editor.selection.anchor.path,
            offset: anchor.text.length,
          },
        };
      }
      setNewSelection(newSelection);
      setAnnotation({ audio_start_offset, audio_end_offset });
      setWordsToRedact(Editor.string(editor, newSelection));
    } else {
      setAnnotation(undefined);
    }
  };

  const handleRedactions = (
    trimTimes: Pick<Annotation, 'audio_start_offset' | 'audio_end_offset'>
  ) => {
    if (newSelection) {
      Transforms.insertText(editor, '[redacted] ', {
        at: newSelection,
      });
      setIsRedacting(true);
      setAnnotation(trimTimes);
    }
  };

  return {
    isRedacting,
    setIsRedacting,
    redactionParams: {
      annotation,
      wordsToRedact,
    },
    setAnnotation,
    handleSelect,
    handleRedactions,
  };
};

export default useTranscriptSelection;
