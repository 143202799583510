import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Annotation, Conversation } from 'src/types/conversation';
import ConversationHighlight from './ConversationHighlight/ConversationHighlight';

interface Props {
  conversation: Conversation;
  onViewInTranscript: (
    targetSeekTimeMin: number,
    targetSeekTimeMax?: number
  ) => void;
}

const ConversationHighlights: React.FunctionComponent<Props> = ({
  conversation,
  onViewInTranscript,
}) => {
  const { t } = useTranslation();
  const { highlights = [] } = conversation;

  return (
    <div>
      {highlights.length === 0 ? (
        <p>{t('conversation.no_highlights')}</p>
      ) : (
        <div>
          <p>
            {t('conversation.highlights_subtitle', {
              count: highlights.length,
            })}
          </p>
          <div className="row">
            <div className="col-lg-12">
              {highlights.map((highlight: Annotation) => {
                return (
                  <ConversationHighlight
                    key={highlight.id}
                    conversation={conversation}
                    highlight={highlight}
                    onViewInTranscript={onViewInTranscript}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConversationHighlights;
