import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  // .use(LanguageDetector) TODO: remove when proper spanish translations are available.
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en-US',
    lng: 'en-US',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: { loadPath: '/locales/{{lng}}/{{ns}}.json' },
  });

export default i18n;
