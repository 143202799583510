import { Box, Typography } from '@mui/material';

import {
  ArrowLeftButton,
  ArrowRightButton,
} from 'src/components/core/Button/ArrowButton';
import { HSpacer } from 'src/components/core/Spacer';
import { HStack } from 'src/components/core/Stack';
import FilterAutocomplete from '../Common/FilterAutocomplete';
import {
  CONVERSATIONS_PER_PAGE,
  useConversationsPageContext,
} from './ConversationsPageProvider';

const ConversationsPageToolbar = () => {
  const {
    currentPage,
    setCurrentPage,
    conversations,
    filters,
    setFilters,
    filteredConversations,
    t,
  } = useConversationsPageContext();
  const conversationCount = filteredConversations.length;
  const pageCount = Math.ceil(conversationCount / CONVERSATIONS_PER_PAGE);
  const onFirstPage = currentPage === 1;
  const onLastPage = currentPage === pageCount;
  const lowerBound = (currentPage - 1) * CONVERSATIONS_PER_PAGE + 1;
  const upperBound = Math.min(
    currentPage * CONVERSATIONS_PER_PAGE,
    conversationCount
  );
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '2rem',
      }}
    >
      <FilterAutocomplete
        filterLabel={t('insights.filter_conversations')}
        items={conversations}
        onChange={(event, newValues) => {
          setFilters(new Set(newValues));
        }}
        optionLabelField={'title'}
        selected={Array.from(filters)}
        sx={{ width: '40%', minWidth: '300px' }}
      />
      <HStack>
        <Typography variant="body1">
          {t('insights.showing_range_description', {
            lowerBound,
            upperBound,
            count: conversationCount,
          })}
        </Typography>
        <HSpacer width="2px" />
        <ArrowLeftButton
          size={28}
          disabled={onFirstPage}
          onClick={() => setCurrentPage(currentPage - 1)}
          aria-label={t('common.previous_page')}
        />
        <ArrowRightButton
          size={28}
          disabled={onLastPage}
          onClick={() => setCurrentPage(currentPage + 1)}
          aria-label={t('common.next_page')}
        />
      </HStack>
    </Box>
  );
};

export default ConversationsPageToolbar;
