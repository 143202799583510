import {
  BaseTermTimings,
  Highlight,
  Snippet,
  TranscriptSnippet,
  WordEntry,
} from 'src/types/conversation';

/**
 * Helper to check if a word is within a range, handling special case where a word has no duration.
 */
export function wordInRange(
  word: WordEntry,
  range: Pick<Highlight, 'audio_start_offset' | 'audio_end_offset'>
) {
  return (
    (range.audio_start_offset < word[2] && range.audio_end_offset > word[1]) ||
    (word[1] === word[2] && // when a word has no duration, treat it special and include it at boundaries. (LVN-140)
      range.audio_start_offset <= word[2] &&
      range.audio_end_offset >= word[1])
  );
}

/**
 * Returns true if the seek time is within the snippet range.
 */
export function seekInSnippet(
  seekTime: number | undefined,
  snippet: { audio_start_offset: number; audio_end_offset: number },
  /** if true, modify the result to be true if almost in the snippet */
  approximate = false
): boolean {
  // if within 0.1s of the start or end time, count it if using approximate
  const epsilon = approximate ? 0.1 : 0;
  return (
    seekTime != null &&
    snippet.audio_start_offset - epsilon <= seekTime &&
    seekTime < snippet.audio_end_offset + epsilon
  );
}

/**
 * Helper to check if highlights/snippets overlap. Handles special case
 * where a highlight or snippet may have 0 duration.
 * @param {boolean} [softEdges] If true, allows non-strict inequality at edges.
 * This is useful for chunks, but dangerous to do with snippets since gapless
 * snippets mean that the end of snippet S1 === start of snippet S2 and so it
 * would always be included.
 */
export function highlightInSnippet(
  snippet: Pick<Snippet, 'audio_start_offset' | 'audio_end_offset'>,
  highlight: Pick<Highlight, 'audio_start_offset' | 'audio_end_offset'>,
  softEdges?: boolean
) {
  const { audio_start_offset: aStart, audio_end_offset: aEnd } = snippet;
  const { audio_start_offset: bStart, audio_end_offset: bEnd } = highlight;

  // LVN-140: handle special case when no highlight or snippet has no duration
  if (aStart === aEnd) {
    // a no duration
    return bStart <= aStart && aEnd <= bEnd;
  } else if (bStart === bEnd) {
    // b no duration
    return aStart <= bStart && bEnd <= aEnd;
  }

  if (softEdges) {
    // highlight starts within snippet
    return (
      (bStart >= aStart && bStart <= aEnd) ||
      // highlight starts before snippet and ends after snippet
      (bStart <= aStart && bEnd >= aEnd) ||
      // highlight ends within snippet
      (bEnd >= aStart && bEnd <= aEnd)
    );
  }

  // highlight starts within snippet
  return (
    (bStart >= aStart && bStart < aEnd) ||
    // highlight starts before snippet and ends after snippet
    (bStart < aStart && bEnd > aEnd) ||
    // highlight ends within snippet
    (bEnd > aStart && bEnd <= aEnd)
  );
}

/**
 *
 * @param words words to match
 * @param sequenceTokensRegExps tokens as regular expressions
 */
function* wordSequenceMatch(
  words: WordEntry[],
  sequenceTokensRegExps: RegExp[]
): IterableIterator<number> {
  let tokenIndex = 0;
  for (let wordIndex = 0; wordIndex < words.length; ++wordIndex) {
    const word = words[wordIndex][0];
    if (sequenceTokensRegExps[tokenIndex].test(word)) {
      tokenIndex += 1;
    } else {
      tokenIndex = 0;
    }

    if (tokenIndex === sequenceTokensRegExps.length) {
      yield wordIndex - (sequenceTokensRegExps.length - 1);

      // reset tokenIndex for next match
      tokenIndex = 0;
    }
  }

  return -1;
}

function termMatchingRegExp(word: string) {
  return new RegExp(`^\\W*${word}`, 'i');
}

export function matchSnippets(
  allSnippets: Snippet[],
  searchQuery: string
): Snippet[] {
  const searchTokens = searchQuery.split(/\W+/).map(termMatchingRegExp);

  // test if the search string occurs in a snippet, if so it's a match
  return allSnippets.filter((snippet) => {
    const matchIterator = wordSequenceMatch(snippet.words, searchTokens);
    return matchIterator.next().value !== -1;
  });
}

/**
 * Computes the indices within words that match emphasis terms and should be emphasized
 * @param words Array of word entries, typically from a snippet [["word", start, end], ...]
 * @param emphasizedTerms Can include ngrams like "deep roots"
 */
export function computeWordEmphasisIndices(
  words: WordEntry[],
  emphasizedTerms: string[] | undefined
): { [index: number]: boolean | undefined } {
  if (!emphasizedTerms) {
    return {};
  }

  const emphasisTokens = emphasizedTerms.map((term) =>
    term.split(/\W+/).map(termMatchingRegExp)
  );
  const wordEmphasisIndices: { [index: number]: boolean | undefined } = {};

  for (const tokens of emphasisTokens) {
    const matchIterator = wordSequenceMatch(words, tokens);

    let matchedWordIndex = matchIterator.next().value;
    while (matchedWordIndex !== -1) {
      for (let i = 0; i < tokens.length; ++i) {
        wordEmphasisIndices[matchedWordIndex + i] = true;
      }
      matchedWordIndex = matchIterator.next().value;
    }
  }

  return wordEmphasisIndices;
}

/**
 * Finds all matches of a term in a set of snippets and returns the TermTimings
 * object using the audio_start_offset of each occurrence for the time.
 */
export function termTimings(
  snippets: Snippet[],
  term: string | undefined
): BaseTermTimings | undefined {
  if (!term) {
    return undefined;
  }
  const tokens = term.split(/\W+/).map(termMatchingRegExp);

  const timings: number[] = [];

  // test if the search string occurs in a snippet, if so it's a match
  snippets.forEach((snippet) => {
    const matchIterator = wordSequenceMatch(snippet.words, tokens);

    let matchedWordIndex = matchIterator.next().value;
    while (matchedWordIndex !== -1) {
      const start = snippet.words[matchedWordIndex][1];
      timings.push(start);
      matchedWordIndex = matchIterator.next().value;
    }
  });

  return {
    term,
    count: timings.length,
    timings,
  };
}

/**
 * Converts snippets into an array of text, one per snippet
 * @param snippets A list of snippets to get the text out of
 * @param startTime Filter words out that start before this
 * @param endTime Filter words out that end after this
 */
export function snippetsToText(
  snippets: TranscriptSnippet[],
  startTime: number,
  endTime: number
): string[] {
  const text = [];
  for (const snippet of snippets) {
    const snippetText = snippet.words
      .filter((word) => startTime < word[2] && endTime > word[1])
      .map((d) => d[0])
      .join(' ');
    text.push(snippetText);
  }

  return text;
}

/**
 * Convert snippets into an array of speakers, one per snippet
 */
export function snippetsToSpeakers(snippets: TranscriptSnippet[]): string[] {
  return snippets.map((d) => d.speaker_name);
}

/**
 * Convert snippets into an array of speakers, one per unique speaker
 */
export function snippetsToUniqueSpeakers(snippets: Snippet[]): string[] {
  const speakerIds: string[] = [];
  return snippets
    .filter((d) => {
      if (!speakerIds.includes(d.speaker_id)) {
        speakerIds.push(d.speaker_id);
        return d;
      }
      return false;
    })
    .map((d) => d.speaker_name);
}

/**
 * Given a seek time and a collection of snippets, get the one you
 * would like to display.
 */
export function getActiveSnippet(
  seekTime: number | undefined,
  snippets: Snippet[],
  startTime: number
): Snippet | undefined {
  if (!snippets.length) {
    return undefined;
  }
  let activeSnippet = snippets.find((snippet) =>
    seekInSnippet(seekTime, snippet)
  );

  // if we don't have any snippet in the seek time,
  // choose based on the passed in seek time (first or last)
  if (!activeSnippet) {
    if (seekTime == null || seekTime < startTime) {
      activeSnippet = snippets[0];
    } else {
      activeSnippet = snippets[snippets.length - 1];
    }
  }
  return activeSnippet;
}
