import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { selectors as authSelectors } from 'src/redux/auth/auth-selectors';
import { selectors as insightsSelectors } from 'src/redux/insights/insights-selectors';
import { loadCatalogs } from 'src/redux/insights/insights-slice';
import CatalogCard from './CatalogCard';
import SpeedDial from './CreateCatalogSpeedDial';
import { organizeCatalogs } from './utils';

const CatalogsPage = () => {
  const catalogs = useSelector(insightsSelectors.getCatalogs);
  const isLoading = useSelector(insightsSelectors.isLoading);
  const organizations = useSelector(authSelectors.getOrganizations);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(loadCatalogs());
  }, [dispatch]);

  return (
    <Container maxWidth={false} sx={{ pt: 3, pb: 3 }}>
      <Toolbar />
      <Grid container spacing={3}>
        {organizeCatalogs(catalogs, organizations).map((item, index) => {
          const [organization, catalogs] = item;
          return (
            <React.Fragment key={index}>
              <Grid item key={`organization/${organization.id}`} xs={12}>
                {index > 0 && <Divider sx={{ mb: 3 }} />}
                <Stack direction="row" spacing={2}>
                  <Typography component="h2" variant="h4">
                    {organization.name}
                  </Typography>
                </Stack>
              </Grid>
              {catalogs.map((catalog) => (
                <Grid
                  item
                  key={`catalog/${catalog.id}`}
                  lg={4}
                  md={6}
                  xl={3}
                  xs={12}
                >
                  <CatalogCard catalog={catalog} key={catalog.id} />
                </Grid>
              ))}
            </React.Fragment>
          );
        })}
      </Grid>
      <Backdrop
        open={isLoading}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress />
      </Backdrop>
      <SpeedDial />
    </Container>
  );
};

export default CatalogsPage;
