import { StoreState } from 'src/redux/store';
import { Collection } from 'src/types/collection';

export const selectors = {
  isLoading: (state: StoreState): boolean =>
    state.collections.managed.isLoading,
  getCollections: (state: StoreState): Collection[] | undefined =>
    state.collections.managed.collections,
  getCollectionsError: (state: StoreState): Error | undefined =>
    state.collections.managed.error,
  isSaving: (state: StoreState): boolean =>
    state.collections.editCollectionDetail.isSaving,
  isSaved: (state: StoreState): boolean =>
    state.collections.editCollectionDetail.isSaved,
  getEditCollectionDetailError: (state: StoreState): Error | undefined =>
    state.collections.editCollectionDetail.error,
  getEditCollectionId: (state: StoreState): number | undefined =>
    state.collections.editCollectionDetail.collectionId,
  getCreateIsSaving: (state: StoreState): boolean =>
    state.collections.createCollection.isSaving,
  getCreateIsSaved: (state: StoreState): boolean =>
    state.collections.createCollection.isSaved,
  getCreateError: (state: StoreState): Error | undefined =>
    state.collections.createCollection.error,
};
export default selectors;
