import { Selector, useDispatch, useSelector } from 'react-redux';

import FilterAutocomplete from 'src/components/Insights/Common/FilterAutocomplete';
import { useFilterValuesChanged } from 'src/components/Insights/Common/useFilterValuesChanged';
import { VisibilityCategory } from 'src/types/insights';

export function FilterField({
  label,
  visibilityCategory,
  optionLabelField,
  optionsSelector,
  visibilitySelector,
}: {
  label: string;
  visibilityCategory: VisibilityCategory;
  optionLabelField: any;
  optionsSelector: Selector<any, any>;
  visibilitySelector: Selector<any, any>;
}) {
  const dispatch = useDispatch();
  const handleFilterValuesChanged = useFilterValuesChanged({ dispatch });
  const options = useSelector(optionsSelector);
  const visibility = useSelector(visibilitySelector);

  return (
    <FilterAutocomplete
      filterLabel={label}
      items={options}
      onChange={(event, newValues, reason) => {
        handleFilterValuesChanged(
          visibilityCategory,
          newValues,
          options,
          visibility,
          reason
        );
      }}
      optionLabelField={optionLabelField}
      selected={options.filter((option: any) => visibility[option.id])}
      sx={{ maxWidth: '540px', minWidth: '260px', flex: 1 }}
    />
  );
}
