import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DispatchProp } from 'react-redux';
import cx from 'classnames';

import Button from 'src/components/core/Button/Button';
import Restrict from 'src/components/Restrict/Restrict';
import { changeStarHighlightState } from 'src/redux/highlight/highlight-slice';
import { Highlight } from 'src/types/conversation';
import { getPreConversationRole } from 'src/util/user';

import styles from './HighlightStarButton.module.scss';

type Props = {
  highlight: Highlight;
  tabIndex?: number;
  className?: string;
} & DispatchProp;

/* Star button to favorite highlights from highlight card*/
const HighlightStarButton = ({
  highlight,
  className,
  tabIndex,
  dispatch,
}: Props) => {
  const { t } = useTranslation();
  const handleStarringHighlight = React.useCallback(() => {
    dispatch(
      changeStarHighlightState({
        highlight,
        star: !highlight.is_starred,
      })
    );
  }, [dispatch, highlight]);

  return (
    <>
      <Restrict atLeastRole={getPreConversationRole()}>
        <Button
          className={cx(className, styles.starHighlightButton, {
            [styles.starred]: highlight.is_starred,
          })}
          onClick={handleStarringHighlight}
          color="plain"
          icon={highlight.is_starred ? ['fas', 'star'] : ['far', 'star']}
          title={
            highlight.is_starred
              ? t('highlight.unfavorite')
              : t('highlight.favorite')
          }
          data-testid="star-button"
          tabIndex={tabIndex}
        />
      </Restrict>
    </>
  );
};

export default HighlightStarButton;
