// The KeywordFilter is a little different than the other card filters because
// it keeps its own state as opposed to keeping state via query param
// It also does not have to connect to any FilterTag components, so can keep
// its handlers un-exported.

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import xor from 'lodash.xor';

import { FilterOption } from 'src/types/core';
import FilterChecklist from '../FilterChecklist/FilterChecklist';
import SideFilter from '../SideFilter/SideFilter';

interface Props {
  filterOptions: FilterOption[];
  activeOptions: FilterOption[] | undefined;
  onChange: (keywords: FilterOption[] | undefined) => void;
  disabled?: boolean;
  className?: string;
}

const getActiveKeywords = (
  activeOptions: string[] | undefined,
  filterOptions: FilterOption[]
) => {
  // the topic snippets endpoint accepts `keywords=undefined` to get all keywords
  // so if activeOptions == null, it means all keywords are checked
  if (activeOptions == null) {
    return filterOptions;
  }
  return activeOptions.map((kw) =>
    filterOptions.find((opt) => opt.name === kw)
  );
};

const KeywordFilter = ({
  filterOptions,
  activeOptions,
  onChange,
  disabled,
  className,
}: Props) => {
  const { t } = useTranslation();
  const handleChange = (option: FilterOption) => {
    // undefined activeOptions means all are selected
    const existingOptions = activeOptions ? activeOptions : filterOptions;
    // to xor properly, we have to do it on strings, not the objects
    const newActiveKeywords = xor(
      existingOptions.map((opt) => opt.name),
      [option.name]
    );
    const activeKeywords = getActiveKeywords(
      newActiveKeywords,
      filterOptions
    ).filter((d) => d !== undefined) as FilterOption[];
    onChange(activeKeywords);
  };

  const handleSelectAll = (selectAll: boolean) => {
    let selected: FilterOption[] | undefined = [];
    if (selectAll) {
      selected = undefined;
    }
    onChange(selected);
  };

  return (
    <div className={className}>
      <SideFilter title={t('topics.keyword_filter')} collapsible={false}>
        {filterOptions && (
          <FilterChecklist
            options={filterOptions}
            onChange={handleChange}
            onSelectAll={handleSelectAll}
            activeFilterOptions={getActiveKeywords(
              activeOptions?.map((opt) => opt.name),
              filterOptions
            )}
            title={t('topics.keyword_filter')}
            hideSearch
            selectAllDisabled={disabled}
          />
        )}
      </SideFilter>
    </div>
  );
};

export default KeywordFilter;
