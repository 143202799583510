import { Moment } from 'moment';

import { StoreState } from 'src/redux/store';
import { TranscriptEditTransaction } from './transcript-edit-slice';

export const selectors = {
  isSaving: (state: StoreState): boolean => state.transcriptEdit.isSaving,
  getSaveTime: (state: StoreState): Moment | undefined =>
    state.transcriptEdit.saveTime,
  getError: (state: StoreState): Error | undefined =>
    state.transcriptEdit.error,
  getVersion: (state: StoreState): number | undefined =>
    state.transcriptEdit.version,
  getConversationId: (state: StoreState): number =>
    state.transcriptEdit.conversationId,
  isResetting: (state: StoreState): boolean => state.transcriptEdit.isResetting,
  isRedacting: (state: StoreState): boolean => state.transcriptEdit.isRedacting,
  getRedactionTime: (state: StoreState): number | undefined =>
    state.transcriptEdit.redactionTime,
  getCurrentTransaction: (
    state: StoreState
  ): TranscriptEditTransaction | undefined =>
    state.transcriptEdit.transactions[
      state.transcriptEdit.transactions.length - 1
    ],
};
export default selectors;
