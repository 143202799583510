import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import {
  ArrayParam,
  DateParam,
  NumberParam,
  useQueryParams,
} from 'use-query-params';

import { ConversationsFilters } from 'src/api/api';
import CollectionFilter, {
  getActiveCollections,
  handleCollectionChange,
} from 'src/components/card-filters/CollectionFilter';
import DateFilter, {
  getActiveEndDates,
  getActiveStartDates,
} from 'src/components/card-filters/DateFilter';
import FilterEmptyState from 'src/components/card-filters/FilterEmptyState';
import ForumFilter, {
  getActiveForums,
  handleForumChange,
} from 'src/components/card-filters/ForumFilter';
import LanguageFilter, {
  getActiveLanguages,
  handleLanguageChange,
} from 'src/components/card-filters/LanguageFilter';
import PartnerFilter, {
  getActiveOrganizations,
  handleOrganizationChange,
} from 'src/components/card-filters/PartnerFilter';
import PrivacyFilter, {
  getActivePrivacy,
  handlePrivacyChange,
} from 'src/components/card-filters/PrivacyFilter';
import ConversationCard from 'src/components/ConversationCard/ConversationCard';
import LoadingOverlay from 'src/components/core/LoadingOverlay/LoadingOverlay';
import FilterTags from 'src/components/FilterTags/FilterTags';
import PagingControl from 'src/components/PagingControl/PagingControl';
import PagingSummary from 'src/components/PagingSummary/PagingSummary';
import authSelectors from 'src/redux/auth/auth-selectors';
import conversationsSelectors from 'src/redux/conversations/conversations-selectors';
import { loadConversations } from 'src/redux/conversations/conversations-slice';
import { StoreState } from 'src/redux/store';
import { User } from 'src/types/auth';
import { Conversation, Paging } from 'src/types/conversation';
import { ConversationsFilterOptions } from 'src/types/core';
import dateFormatter from 'src/util/date';
import { makePagingHandlers } from 'src/util/paging';

import styles from './ConversationCards.module.scss';

interface StateProps {
  isLoading: boolean;
  conversations: Conversation[];
  error: Error | undefined;
  pagingInfo: Paging | undefined;
  filterOptions: ConversationsFilterOptions | undefined;
  user: User;
}

type Props = StateProps & DispatchProp;

const mapStateToProps = (state: StoreState): StateProps => ({
  isLoading: conversationsSelectors.isLoading(state),
  conversations: conversationsSelectors.getConversations(state),
  error: conversationsSelectors.getError(state),
  pagingInfo: conversationsSelectors.getConversationsPagingInfo(state),
  filterOptions: conversationsSelectors.getConversationsFilterOptions(state),
  user: authSelectors.getUser(state),
});

const PAGE_SIZE = 10;

const ConversationCards = ({
  conversations,
  isLoading = true,
  error,
  pagingInfo,
  filterOptions,
  dispatch,
}: Props) => {
  const { t } = useTranslation();
  // get search query parameters
  const [query, setQuery] = useQueryParams({
    c: ArrayParam,
    f: ArrayParam,
    page: NumberParam,
    start: DateParam,
    end: DateParam,
    org: ArrayParam,
    lang: ArrayParam,
    priv: ArrayParam,
  });
  const {
    c: collectionIds,
    f: forumIds,
    page: currentPage = 1,
    start: startDate,
    end: endDate,
    org: organizationIds,
    lang: languageCodes,
    priv: privacyLevels,
  } = query;

  const [draftStartDate, setDraftStartDate] = React.useState('');
  const [draftEndDate, setDraftEndDate] = React.useState('');

  // load conversations on mount
  React.useEffect(() => {
    const filters: Partial<ConversationsFilters> = {
      page: currentPage,
      limit: PAGE_SIZE,
      collectionIds,
      forumIds,
      organizationIds,
      languageCodes,
      privacyLevels,
      startDate,
      endDate,
    };
    // update filter ui
    if (startDate) {
      setDraftStartDate(dateFormatter.dateToISOString(startDate));
    }
    if (endDate) {
      setDraftEndDate(dateFormatter.dateToISOString(endDate));
    }

    dispatch(loadConversations(filters));

    // make sure window is scrolled back to the top when we load new convos
    // not covered by ScrollToTop component since it's the same route
    window.scrollTo(0, 0);
  }, [
    collectionIds,
    forumIds,
    currentPage,
    startDate,
    endDate,
    organizationIds,
    languageCodes,
    privacyLevels,
    setDraftEndDate,
    setDraftStartDate,
    dispatch,
  ]);

  const clearAllFilters = () => {
    setDraftStartDate('');
    setDraftEndDate('');
    setQuery({
      org: undefined,
      lang: undefined,
      start: undefined,
      end: undefined,
      c: undefined,
      f: undefined,
      priv: undefined,
    });
  };

  const { handleNextPage, handlePrevPage, handleSeekPage } = makePagingHandlers(
    currentPage,
    PAGE_SIZE,
    pagingInfo ? pagingInfo.total : 0,
    (page: number) => setQuery({ page }, 'pushIn')
  );

  const activeOrgs = getActiveOrganizations(organizationIds, filterOptions);
  const activeCollections = getActiveCollections(collectionIds, filterOptions);
  const activeForums = getActiveForums(forumIds, filterOptions);
  const activeLanguages = getActiveLanguages(languageCodes, filterOptions);
  const activePrivacy = getActivePrivacy(privacyLevels, filterOptions);
  const activeStartDate = startDate && getActiveStartDates(startDate);
  const activeEndDate = endDate && getActiveEndDates(endDate);

  const filtersActive =
    collectionIds != null ||
    forumIds != null ||
    startDate != null ||
    endDate != null ||
    languageCodes != null ||
    organizationIds != null ||
    privacyLevels != null;

  return (
    <div className="d-flex">
      {error ? (
        <p className="text-danger">
          {t('common.error_unexpected')}
          {process.env.NODE_ENV !== 'production' && error.message}
        </p>
      ) : (
        <>
          <div className="me-4">
            <PartnerFilter filterOptions={filterOptions} />
            <CollectionFilter filterOptions={filterOptions} />
            <ForumFilter filterOptions={filterOptions} />
            <LanguageFilter filterOptions={filterOptions} />
            <PrivacyFilter filterOptions={filterOptions} />
            <DateFilter
              draftStartDate={draftStartDate}
              draftEndDate={draftEndDate}
              setDraftStartDate={setDraftStartDate}
              setDraftEndDate={setDraftEndDate}
            />
          </div>
          <div className={styles.conversationCardsContainer}>
            <LoadingOverlay active={isLoading} fixed />
            {conversations.length > 0 ? (
              <>
                <PagingSummary
                  className="mb-1 text-gray-700"
                  itemsPerPage={PAGE_SIZE}
                  pagingInfo={pagingInfo}
                  i18NextKey="conversations.showing"
                  i18NextRangeKey="conversations.showing_range"
                />
                <div className="mb-1">
                  <FilterTags
                    activeFilterOptions={activeOrgs}
                    onClear={(option) =>
                      handleOrganizationChange(
                        option,
                        organizationIds,
                        setQuery
                      )
                    }
                  />
                  <FilterTags
                    activeFilterOptions={activeCollections}
                    onClear={(option) =>
                      handleCollectionChange(option, collectionIds, setQuery)
                    }
                  />
                  <FilterTags
                    activeFilterOptions={activeForums}
                    onClear={(option) =>
                      handleForumChange(option, forumIds, setQuery)
                    }
                  />
                  <FilterTags
                    activeFilterOptions={activeLanguages}
                    onClear={(option) =>
                      handleLanguageChange(option, languageCodes, setQuery)
                    }
                  />
                  <FilterTags
                    activeFilterOptions={activePrivacy}
                    onClear={(option) =>
                      handlePrivacyChange(option, privacyLevels, setQuery)
                    }
                  />
                  {startDate && (
                    <FilterTags
                      activeFilterOptions={activeStartDate}
                      onClear={() => {
                        setDraftStartDate('');
                        setQuery({ start: undefined });
                      }}
                    />
                  )}

                  {endDate && (
                    <FilterTags
                      activeFilterOptions={activeEndDate}
                      onClear={() => {
                        setDraftEndDate('');
                        setQuery({ end: undefined });
                      }}
                    />
                  )}
                </div>
                <div>
                  <div>
                    {conversations.map((conversation) => (
                      <ConversationCard
                        key={conversation.id}
                        className="mb-3"
                        conversation={conversation}
                      />
                    ))}
                  </div>
                  {!isLoading && (
                    <PagingControl
                      className={'mb-5 text-center'}
                      itemsPerPage={PAGE_SIZE}
                      pagingInfo={pagingInfo}
                      onNext={handleNextPage}
                      onPrevious={handlePrevPage}
                      onSeek={handleSeekPage}
                    />
                  )}
                </div>
              </>
            ) : (
              <>
                {!isLoading && (
                  <FilterEmptyState
                    filtersActive={filtersActive}
                    onClear={clearAllFilters}
                    title={t('conversations.empty_state')}
                  />
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(ConversationCards);
