import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import AudioControls from 'src/components/AudioControls/AudioControls';
import PlaybackSpeed from 'src/components/ConversationTimeline/PlaybackControls/PlaybackSpeed/PlaybackSpeed';
import Button, { ButtonIconProp } from 'src/components/core/Button/Button';
import LoadingOverlay from 'src/components/core/LoadingOverlay/LoadingOverlay';
import { mapbox } from 'src/config';
import GlobalAudioContext, {
  useSeekTime,
} from 'src/contexts/GlobalAudioContext';
import { Conversation } from 'src/types/conversation';

import styles from './FixedAudioPlayer.module.scss';

const ConversationMap = React.lazy(
  () => import('src/components/ConversationMap/ConversationMap')
);

interface Props {
  className?: string | undefined;
  closable?: boolean;
}

const FixedAudioPlayer = ({ className, closable = true }: Props) => {
  const { t } = useTranslation();
  const {
    pause,
    isPlaying,
    src,
    isLoading,
    duration,
    play,
    seek,
    meta,
    stop,
    playbackSpeed,
    changePlaybackSpeed,
    relativeSeek,
  } = React.useContext(GlobalAudioContext);
  const seekTime = useSeekTime();

  if (src == null) {
    return null;
  }

  const conversation = meta as Conversation | undefined;
  let buttonIcon: ButtonIconProp;
  let buttonClickHandler;
  let playButtonTestId = '';
  if (isLoading) {
    buttonIcon = 'loading';
    playButtonTestId = 'play-btn-loading';
  } else if (isPlaying) {
    buttonIcon = 'pause';
    buttonClickHandler = () => pause();
    playButtonTestId = 'play-btn-pause';
  } else {
    buttonIcon = 'play';
    buttonClickHandler = () => play();
    playButtonTestId = 'play-btn-play';
  }

  const handleQuickRewind = () => {
    relativeSeek(-5);
  };

  return (
    <div
      className={cx(styles.fixedAudioPlayer, className, {
        'py-2': conversation == null, // need padding if there is no map
      })}
      data-testid="fixed-audio-player"
    >
      {closable && (
        <Button
          icon={['far', 'times']}
          color="plain"
          size="lg"
          className={cx(styles.closeBtn, 'close')}
          title={t('audio_player.close')}
          onClick={stop}
        />
      )}
      <div className={cx(styles.inner, 'd-flex align-items-center')}>
        <div
          className={cx('d-flex align-items-center flex-column text-center')}
        >
          <Button
            icon={buttonIcon}
            onClick={buttonClickHandler}
            color="fora-purple"
            outline
            grayOutline
            className={cx(styles.playBtn, 'w-100 mb-1')}
            title={t('audio_player.play')}
            data-testid={playButtonTestId}
          />
          <div className={styles.leftControls}>
            <Button
              icon={['far', 'undo']}
              onClick={handleQuickRewind}
              color="default"
              title={t('audio_player.rewind')}
              size="sm"
              className="me-1"
            />
            <PlaybackSpeed
              disabled={isLoading}
              speed={playbackSpeed}
              onChange={changePlaybackSpeed}
              size="sm"
              minimal
              className={cx('d-inline', styles.playbackSpeedBtn)}
            />
          </div>
        </div>

        <div className="flex-grow-1 pe-3 overflow-hidden">
          {conversation && (
            <div className={cx(styles.description, 'flex-grow-1 mt-1')}>
              <Link
                className={styles.conversationTitle}
                to={`/conversation/${conversation.id}`}
              >
                {conversation.title}
              </Link>
              <div className={styles.conversationMeta}>
                <Trans
                  i18nKey="audio_player.footer"
                  values={{
                    name: conversation.host.name,
                    location: conversation.location.name,
                  }}
                  components={{
                    1: <span className={styles.host} />,
                  }}
                />
              </div>
            </div>
          )}
          <AudioControls
            showPlayControls={false}
            isPlaying={isPlaying}
            isLoading={isLoading}
            seekTime={seekTime}
            duration={duration}
            onPlay={play}
            onSeek={seek}
            onPause={pause}
          />
        </div>
        {conversation && (
          <React.Suspense
            fallback={
              <div
                style={{
                  width: '180px',
                  height: '80px',
                  position: 'relative',
                }}
                data-testid="suspended-loader"
              >
                <LoadingOverlay active />
              </div>
            }
          >
            <ConversationMap
              initialLngLat={conversation.location.lng_lat}
              width={180}
              height={80}
              initialZoom={11.0}
              mapStyle={mapbox.mapStyles.lvnLighterHighContrast}
              className="border-start"
              fixed
            />
          </React.Suspense>
        )}
      </div>
    </div>
  );
};

export default FixedAudioPlayer;
