import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { animated, useSpring } from 'react-spring';
import { useMeasure, useWindowSize } from 'react-use';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import Button from 'src/components/core/Button/Button';
import { StoreState } from 'src/redux/store';
import topicsSelectors from 'src/redux/topics/topics-selectors';
import { Topics, TopicWithRelatedContent } from 'src/types/conversation';
import { ColorScale } from 'src/types/core';
import measureElement from 'src/util/react';

import styles from './TopicSelector.module.scss';

// measurements, in px
// update when TopicInput styles change
const TOGGLE_WIDTH = 40; // width of toggle btn
const CLEAR_BTN_WIDTH = 28; // width of clear 'x' btn + left margin
const INPUT_MARGIN = 10; // bottom and left margin value for inputs
const INPUT_HEIGHT = 40 + INPUT_MARGIN; // height of TopicInput + margin
const COLLAPSED_HEIGHT = INPUT_HEIGHT; // max height of the collapsed selector is the height of a single row

interface BaseProps {
  activeTopic: TopicWithRelatedContent | null;
  handleToggle: (id: number) => void;
  sticky?: boolean;
  className?: string;
}

interface StateProps {
  topics: Topics;
  topicColorScale: ColorScale | undefined;
}

type Props = BaseProps & StateProps;

const mapStateToProps = (state: StoreState): StateProps => ({
  topics: topicsSelectors.getTopics(state),
  topicColorScale: topicsSelectors.getTopicColorScale(state),
});
export const TopicSelector = ({
  activeTopic,
  handleToggle,
  topics,
  topicColorScale,
  sticky,
  className,
}: Props) => {
  const { t } = useTranslation();
  const [ref, { width, height: componentHeight }] = useMeasure();
  const { height: windowHeight } = useWindowSize();

  const [expanded, setExpanded] = React.useState(false);
  const toggleExpanded = () => setExpanded(!expanded);

  // convert topics to array and sort by num conversations
  const topicArray = React.useMemo<TopicWithRelatedContent[]>(
    () =>
      Object.values(topics).sort(
        (a, b) => b.num_conversations - a.num_conversations
      ),
    [topics]
  );

  // before they're rendered, measure TopicInput widths so we can fill the top row with as many as possible and hide the rest
  const [inputWidths, setInputWidths] = React.useState<number[]>([]);
  React.useEffect(() => {
    measureTopicWidths(topicArray).then((res) => {
      setInputWidths(res);
    });
  }, [topicArray]);

  // keep track of which inputs are in which rows
  const [rows, setRows] = React.useState<number[][]>([[]]);

  // calculate widths of TopicInputs for use in UI
  // only re-calculate when topics or TopicSelector width changes
  React.useEffect(() => {
    const tmpRows: number[][] = [[]];
    // initial row width starts with the width of the toggle btn because it is added to the top row + width of clear btn so links can expand without moving to the next row
    let rowWidth = TOGGLE_WIDTH + CLEAR_BTN_WIDTH;
    topicArray.forEach((t, i) => {
      const inputWidth = inputWidths[i]; // get pre-measured width of input
      const newRowWidth = rowWidth + inputWidth; // add next input width to existing row width
      if (newRowWidth > width) {
        // if row would be wider than the width of the container, start a new row
        tmpRows.push([t.id]);
        // start new row as width of first input + clear btn width so inputs can expand, same as initial row width
        rowWidth = inputWidth + CLEAR_BTN_WIDTH;
        return;
      }

      // if input fits in current row, add it
      rowWidth = newRowWidth;
      tmpRows[tmpRows.length - 1].push(t.id);
    });
    setRows(tmpRows);
  }, [topicArray, width, inputWidths]);

  React.useEffect(() => {
    // when page loads, selector should be expanded if active topic is not in the first row
    const firstRow = rows[0];
    if (
      activeTopic != null &&
      firstRow.length > 0 &&
      !firstRow.includes(activeTopic.id)
    ) {
      setExpanded(true);
    }
  }, [rows, activeTopic]);

  // animate change between expanded and collapsed
  const animatedProps = useSpring({
    maxHeight: expanded ? INPUT_HEIGHT * rows.length : COLLAPSED_HEIGHT,
  });

  // don't return anything if there are no topics or no color scale
  if (topicArray.length === 0 || !topicColorScale) {
    return null;
  }

  // do not use sticky nav if viewport is too short to see content below nav
  const enableStickyNav = sticky && windowHeight > componentHeight + 500;

  return (
    <div
      ref={ref}
      className={cx(className, styles.wrapper, {
        [styles.sticky]: enableStickyNav,
      })}
    >
      <animated.div
        className={cx([
          'mb-2',
          {
            [styles.collapsed]: !expanded,
          },
        ])}
        style={animatedProps}
      >
        {topicArray.map((topic, i) => {
          const topicId = topic.id;
          const isActive = !!(activeTopic && activeTopic.id === topicId);
          // if topic is the last item in the 1st row and there are multiple rows, render the toggle btn
          const showToggle = rows[0].length - 1 === i && rows.length > 1;
          const hidden = !expanded && !rows[0].includes(topic.id);
          return (
            <span key={`${topic.name}-topic`}>
              <TopicInput
                topic={topic}
                color={styles[`color${topicColorScale(topic.display_name)}`]}
                isActive={isActive}
                handleToggle={handleToggle}
                hidden={hidden}
              />
              {showToggle && (
                <>
                  <Button
                    icon={
                      expanded ? ['fas', 'chevron-up'] : ['fas', 'chevron-down']
                    }
                    shape="circle"
                    size="lg"
                    whiteBg
                    className={cx(styles.toggle)}
                    onClick={toggleExpanded}
                    title={
                      expanded
                        ? t('topics.fewer_topics')
                        : t('topics.more_topics')
                    }
                    data-testid={
                      expanded ? 'selector-collapse-btn' : 'selector-expand-btn'
                    }
                  />
                  <br />
                </>
              )}
            </span>
          );
        })}
      </animated.div>
    </div>
  );
};

interface TopicInputProps {
  topic: TopicWithRelatedContent;
  color?: string;
  isActive?: boolean;
  hidden?: boolean;
  handleToggle: (id: number) => void;
}

const TopicInput = ({
  topic,
  color,
  isActive,
  hidden,
  handleToggle,
}: TopicInputProps) => {
  const { t } = useTranslation();
  const topicName = topic.name;

  return (
    <div
      className={cx('btn btn-default', styles.topic, color, {
        [styles.active]: isActive,
        [styles.hidden]: hidden,
      })}
      data-testid={`${topicName}-topic`}
    >
      <label className={cx(styles.topicLabel)}>
        <FontAwesomeIcon
          icon={isActive ? ['fas', 'circle'] : ['far', 'circle']}
          size="sm"
          data-testid={`${topicName}-topic-indicator`}
          className={styles.topicIndicator}
          aria-hidden={true}
        />
        {/* only add input when TopicInput is usable
        (otherwise screenreaders read total # of radio btns including invisible btns)*/}
        {!hidden && (
          <input
            type="radio"
            name="topic-btn"
            value={topicName}
            className={styles.topicInput}
            checked={!!isActive}
            data-topic={topic.id}
            data-testid={`${topicName}-topic-input`}
            onChange={() => handleToggle(topic.id)}
          />
        )}
        {topic.display_name}
      </label>
      {isActive && (
        <button
          onClick={() => handleToggle(topic.id)}
          className={cx(
            styles.clearBtn,
            'btn--circle d-flex align-items-center justify-content-center'
          )}
          title={t('topics.topic_clear')}
          data-testid={`${topicName}-clear`}
        >
          <FontAwesomeIcon icon={['far', 'times']} />
        </button>
      )}
    </div>
  );
};

function measureTopicWidths(topics: TopicWithRelatedContent[]) {
  return Promise.all(
    topics.map(async (t) => {
      const { width } = await measureElement(
        <TopicInput
          topic={t}
          handleToggle={() => {
            return;
          }}
        />
      );
      return width;
    })
  );
}

export default connect(mapStateToProps)(TopicSelector);
