import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Panel from 'src/components/Panel/Panel';
import { Conversation } from 'src/types/conversation';

import styles from './ConversationHealthMetrics.module.scss';

interface Props {
  conversation: Conversation;
}

interface Metric {
  name: string;
  units: string;
  desc: string;
  breakpoints: [number, string][];
}

type MetricKey =
  | 'Words per hour'
  | 'Speech per turn'
  | 'Mean inter-speaker silence'
  | 'Turn taking balance'
  | 'Interruption rate'
  | 'MATTR lexical diversity'
  | 'Mean word length'
  | 'Speech recognizer word error rate';

const useMetricInfo = (): { [key in MetricKey]: Metric } => {
  const { t } = useTranslation();
  return React.useMemo(
    () => ({
      'Words per hour': {
        name: t('conversation.admin_metrics_wph'),
        units: '',
        desc: t('conversation.admin_metrics_wph_desc'),
        breakpoints: [
          [8900, t('conversation.admin_metrics_wph_bp_1')],
          [10500, t('conversation.admin_metrics_wph_bp_2')],
          [1e6, t('conversation.admin_metrics_wph_bp_3')],
        ],
      },
      'Speech per turn': {
        name: t('conversation.admin_metrics_spt'),
        units: t('conversation.admin_metrics_spt_units'),
        desc: t('conversation.admin_metrics_spt_desc'),
        breakpoints: [
          [30, t('conversation.admin_metrics_spt_bp_1')],
          [50, t('conversation.admin_metrics_spt_bp_2')],
          [1e6, t('conversation.admin_metrics_spt_bp_3')],
        ],
      },
      'Mean inter-speaker silence': {
        name: t('conversation.admin_metrics_mis'),
        units: t('conversation.admin_metrics_mis_units'),
        desc: t('conversation.admin_metrics_mis_desc'),
        breakpoints: [
          [0, t('conversation.admin_metrics_mis_bp_1')],
          [0.6, t('conversation.admin_metrics_mis_bp_2')],
          [0.8, t('conversation.admin_metrics_mis_bp_3')],
          [1e6, t('conversation.admin_metrics_mis_bp_4')],
        ],
      },
      'Turn taking balance': {
        name: t('conversation.admin_metrics_ttb'),
        units: t('conversation.admin_metrics_ttb_units'),
        desc: t('conversation.admin_metrics_ttb_desc'),
        breakpoints: [
          [0, t('conversation.admin_metrics_ttb_bp_1')],
          [0.9, t('conversation.admin_metrics_ttb_bp_2')],
          [0.97, t('conversation.admin_metrics_ttb_bp_3')],
          [1e6, t('conversation.admin_metrics_ttb_bp_4')],
        ],
      },
      'Interruption rate': {
        name: t('conversation.admin_metrics_ir'),
        units: t('conversation.admin_metrics_ir_units'),
        desc: t('conversation.admin_metrics_ir_desc'),
        breakpoints: [
          [0, t('conversation.admin_metrics_ir_bp_1')],
          [0.65, t('conversation.admin_metrics_ir_bp_2')],
          [1e6, t('conversation.admin_metrics_ir_bp_3')],
        ],
      },
      'MATTR lexical diversity': {
        name: t('conversation.admin_metrics_mattr'),
        units: t('conversation.admin_metrics_mattr_units'),
        desc: t('conversation.admin_metrics_mattr_desc'),
        breakpoints: [
          [0, t('conversation.admin_metrics_mattr_bp_1')],
          [400, t('conversation.admin_metrics_mattr_bp_2')],
          [1e6, t('conversation.admin_metrics_mattr_bp_3')],
        ],
      },
      'Mean word length': {
        name: t('conversation.admin_metrics_mwl'),
        units: t('conversation.admin_metrics_mwl_units'),
        desc: t('conversation.admin_metrics_mwl_desc'),
        breakpoints: [
          [4.3, t('conversation.admin_metrics_mwl_bp_1')],
          [4.35, t('conversation.admin_metrics_mwl_bp_2')],
          [1e6, t('conversation.admin_metrics_mwl_bp_3')],
        ],
      },
      'Speech recognizer word error rate': {
        name: t('conversation.admin_metrics_sr'),
        units: t('conversation.admin_metrics_sr_units'),
        desc: t('conversation.admin_metrics_sr_desc'),
        breakpoints: [
          [15, t('conversation.admin_metrics_sr_bp_1')],
          [30, t('conversation.admin_metrics_sr_bp_2')],
          [40, t('conversation.admin_metrics_sr_bp_3')],
          [100, t('conversation.admin_metrics_sr_bp_4')],
        ],
      },
    }),
    [t]
  );
};

const ConversationHealthMetrics = ({ conversation }: Props) => {
  const [tooltipOpenId, setTooltipOpenId] = React.useState<
    string | undefined
  >();
  const metricInfo = useMetricInfo();

  const toggleTooltip = (tooltipId: string) => {
    if (tooltipOpenId === tooltipId) {
      setTooltipOpenId(undefined);
    } else {
      setTooltipOpenId(tooltipId);
    }
  };
  const { speech_pipeline_info } = conversation;

  if (!speech_pipeline_info) {
    return null;
  }

  const { conversation_metrics: metrics, alignment_info } =
    speech_pipeline_info;

  if (!metrics) {
    return null;
  }

  // Add word error rate, if available
  let wer_metrics: any[] = [];
  if (alignment_info && alignment_info['word_error_rate']) {
    wer_metrics = [
      ['Speech recognizer word error rate', alignment_info['word_error_rate']],
    ];
  }

  return (
    <Panel>
      <table className="table with-row-borders w-auto">
        <tbody>
          {metrics.concat(wer_metrics).map(([name, value]: any, i: number) => {
            const metric = metricInfo[name as MetricKey];
            if (!metric) {
              return null;
            }

            const breakpoints = metric['breakpoints'];
            const unit_description = metric['units'];
            const metric_description = metric['desc'];
            const metric_name = metric['name'];
            let pill_description: string | undefined;

            for (let i = 0; i < breakpoints.length; i++) {
              if (value <= breakpoints[i][0]) {
                pill_description = breakpoints[i][1];
                break;
              }
            }

            const tooltipId = `tooltip-target-metrics-${i}`;
            return (
              <tr key={i}>
                <td className={styles.tableLabel}>
                  {metric_name}{' '}
                  <span id={tooltipId} className={styles.tooltipIcon}>
                    <FontAwesomeIcon icon={['far', 'info-circle']} />
                  </span>
                  <Tooltip
                    placement="bottom-start"
                    className={styles.tooltip}
                    isOpen={tooltipOpenId === tooltipId}
                    target={tooltipId}
                    toggle={() => toggleTooltip(tooltipId)}
                  >
                    {metric_description}
                  </Tooltip>
                </td>
                <td>
                  <span className="fwbold">{value.toFixed(2)}</span>{' '}
                  <span className="small">{unit_description}</span>
                </td>
                <td>
                  <div className="">{pill_description}</div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Panel>
  );
};

export default ConversationHealthMetrics;
