import { useTranslation } from 'react-i18next';
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  Link,
  LinkProps,
} from '@mui/material';
import { TFunctionKeys, TOptions } from 'i18next';

import Color from 'src/assets/_util.scss';

export function Button({
  sx,
  tKey,
  tOptions,
  children,
  busy = false,
  disabled = false,
  onClick,
  ...props
}: ButtonProps & {
  tKey?: TFunctionKeys;
  tOptions?: TOptions;
  busy?: boolean;
}) {
  const { t } = useTranslation();
  const label = tKey ? t(tKey, tOptions) : '';
  const enabled = !disabled && !busy;

  return (
    <MuiButton
      sx={{ ...s.button(enabled, busy), ...sx }}
      disabled={!enabled}
      onClick={enabled ? onClick : undefined}
      {...props}
    >
      {children ?? label}
    </MuiButton>
  );
}

export function LinkButton({
  tKey,
  tOptions,
  children,
  sx,
  ...props
}: LinkProps & ButtonProps) {
  const { t } = useTranslation();
  const label = tKey ? t(tKey, tOptions) : '';

  return (
    <Link
      underline="none"
      target="_blank"
      sx={{ ...s.button(true, false), ...sx }}
      {...props}
    >
      {children ?? label}
    </Link>
  );
}

// STYLE

const s = {
  button: (enabled: boolean, busy: boolean) => ({
    position: 'relative',
    zIndex: 2,
    color: `${Color[busy ? 'foraPurpleDark' : 'foraPurple']} !important`,
    fontWeight: 600,
    border: '0.5px solid #4F29B75C',
    borderRadius: '8px',
    padding: '12px 24px',
    lineHeight: '100%',
    boxShadow: '0 0 3px rgba(0, 0, 0, 0.12)',
    transition: 'all 200ms ease-out',
    textTransform: 'none',
    fontSize: '16px',
    ':hover': enabled
      ? {
          textDecoration: 'none',
          filter: 'brightness(108%)',
        }
      : {},
    cursor: busy ? 'wait !important' : enabled ? 'pointer' : 'default',
    pointerEvents: 'all !important',
    background: busy
      ? 'linear-gradient(283deg, rgba(76, 104, 209, 0.18) 13.32%, rgba(76, 104, 209, 0.02) 49.8%), \
         linear-gradient(258deg, rgba(74, 254, 209, 0.18) 0%, rgba(74, 254, 209, 0.02) 49.93%), \
         linear-gradient(79deg, rgba(247, 252, 157, 0.18) 0%, rgba(247, 252, 157, 0.02) 50.08%), \
         linear-gradient(102deg, rgba(247, 163, 146, 0.18) 10.55%, rgba(247, 163, 146, 0.02) 50.2%)'
      : '#E3DFF88A',
    animation: busy ? 'bgSpin 2s infinite alternate linear' : 'none',
  }),
};

// DEFINITIONS

type ButtonProps = MuiButtonProps & {
  tKey?: TFunctionKeys;
  tOptions?: TOptions;
  busy?: boolean;
};
