import { calculateVisibility } from 'src/components/Insights/utils/code';
import { StoreState } from 'src/redux/store';
import { User } from 'src/types/auth';
import { Conversation } from 'src/types/conversation';
import { Code, Demographic, VisibilityMap } from 'src/types/insights';

export const selectors = {
  getCodeVisibility: (state: StoreState): [Code['id'][], Code['id'][]] => {
    const codesToShow: Code['id'][] = [];
    const codesToHide: Code['id'][] = [];
    for (const code of Object.values(state.catalog.codes)) {
      const visibility =
        calculateVisibility(code, state.catalog_filters.internalCodes) ||
        calculateVisibility(code, state.catalog_filters.structuralCodes) ||
        calculateVisibility(code, state.catalog_filters.thematicCodes);
      if (visibility === 'visible' || visibility === 'implicitly-visible') {
        codesToShow.push(code.id);
      } else if (
        visibility === 'invisible' ||
        visibility === 'implicitly-invisible'
      ) {
        codesToHide.push(code.id);
      }
    }
    return [codesToShow, codesToHide];
  },
  getConversationVisibilityMap: (state: StoreState): VisibilityMap => {
    return state.catalog_filters.conversations;
  },
  getConversationVisibility: (
    state: StoreState
  ): [Conversation['id'][], Conversation['id'][]] => {
    const conversationsToShow: Conversation['id'][] = [];
    const conversationsToHide: Conversation['id'][] = [];
    for (const [id, filter] of Object.entries(
      state.catalog_filters.conversations
    )) {
      if (filter) {
        conversationsToShow.push(Number(id));
      } else {
        conversationsToHide.push(Number(id));
      }
    }
    return [conversationsToShow, conversationsToHide];
  },
  getDemographicVisibilityMap: (state: StoreState): VisibilityMap => {
    return state.catalog_filters.demographics;
  },
  getDemographicVisibility: (
    state: StoreState
  ): [Demographic['id'][], Demographic['id'][]] => {
    const demographicsToShow: Demographic['id'][] = [];
    const demographicsToHide: Demographic['id'][] = [];
    for (const code of Object.values(state.catalog.demographics)) {
      const visibility = calculateVisibility(
        code,
        state.catalog_filters.demographics
      );
      if (visibility === 'visible' || visibility === 'implicitly-visible') {
        demographicsToShow.push(code.id);
      } else if (
        visibility === 'invisible' ||
        visibility === 'implicitly-invisible'
      ) {
        demographicsToHide.push(code.id);
      }
    }
    return [demographicsToShow, demographicsToHide];
  },
  getHighlighterVisibilityMap: (state: StoreState): VisibilityMap => {
    return state.catalog_filters.highlighters;
  },
  getHighlighterVisibility: (
    state: StoreState
  ): [User['id'][], User['id'][]] => {
    const highlightersToShow: User['id'][] = [];
    const highlightersToHide: User['id'][] = [];
    for (const [id, filter] of Object.entries(
      state.catalog_filters.highlighters
    )) {
      if (filter) {
        highlightersToShow.push(Number(id));
      } else {
        highlightersToHide.push(Number(id));
      }
    }
    return [highlightersToShow, highlightersToHide];
  },
  getParticipantVisibility: (
    state: StoreState
  ): [User['id'][], User['id'][]] => {
    const participantsToShow: User['id'][] = [];
    const participantsToHide: User['id'][] = [];
    for (const [id, filter] of Object.entries(
      state.catalog_filters.participants
    )) {
      if (filter) {
        participantsToShow.push(Number(id));
      } else {
        participantsToHide.push(Number(id));
      }
    }
    return [participantsToShow, participantsToHide];
  },
  getInternalCodeVisibilityMap: (state: StoreState): VisibilityMap => {
    return state.catalog_filters.internalCodes;
  },
  getParticipantVisibilityMap: (state: StoreState): VisibilityMap => {
    return state.catalog_filters.participants;
  },
  getStructuralCodeVisibilityMap: (state: StoreState): VisibilityMap => {
    return state.catalog_filters.structuralCodes;
  },
  getThematicCodeVisibilityMap: (state: StoreState): VisibilityMap => {
    return state.catalog_filters.thematicCodes;
  },
  getTranscriptSearch: (state: StoreState): string => {
    return state.catalog_filters.transcriptSearch;
  },
  getFiltersAreLoading: (state: StoreState): boolean => {
    return state.catalog_filters.filtersAreLoading;
  },
};
