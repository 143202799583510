import { Chip, Typography } from '@mui/material';

import color from 'src/assets/_util.scss';
import Button from 'src/components/core/Button/Button';
import { HStack } from 'src/components/core/Stack';
import { updateFilters } from 'src/redux/catalog/catalog-slice';
import { Filter } from 'src/types/insights';
import { useHighlightsPageContext } from '../Catalog/HighlightsPageProvider';
import {
  useFilterLabels,
  useFilterOptions,
  valuelessOperations,
} from '../utils/filters';

const FilterPills = () => {
  const { dispatch, selectedFilters, filters, initialFilter, setFilters, t } =
    useHighlightsPageContext();

  const { filterCategoryLabel, filterOperationsLabel } = useFilterLabels();
  const filterValueMap = useFilterOptions();
  const displayFilters = selectedFilters.filter(
    (f) => f.values.length || valuelessOperations.includes(f.operation)
  );

  const deleteFilter = (ind: number) => {
    let newFilters: Filter[] = [{ ...initialFilter }];
    if (filters.length !== 1) {
      newFilters = [...filters].filter((_, x) => x !== ind);
    }
    setFilters(newFilters);
    dispatch(updateFilters(newFilters));
  };

  const deleteAllFilters = () => {
    const newFilters: Filter[] = [{ ...initialFilter }];
    setFilters(newFilters);
    dispatch(updateFilters(newFilters));
  };

  if (!filters) {
    return <></>;
  }

  return (
    <HStack
      style={{
        alignItems: 'center',
        margin: displayFilters.length ? '1rem 0 0 ' : '',
        flexWrap: 'wrap',
      }}
    >
      {displayFilters.map((filter, ind) => {
        const filterName = filterCategoryLabel[filter.filter_name];
        const filterOperation = filterOperationsLabel[filter.operation];
        const label = `${filterName} ${filterOperation.toLowerCase()}`;
        const filterValues = filter.values
          .map(
            (id) =>
              filterValueMap[filter.filter_name].find((v) => v.id === id)?.name
          )
          .join(', ');

        return (
          <Chip
            sx={{
              borderRadius: '5px !important',
              backgroundColor: color['foraLighterCustom'],
              width: 'auto',
              marginRight: '.5rem',
            }}
            label={
              <span>
                <b>{`${label}${filterValues ? ': ' : ''}`}</b>
                {filterValues && <span>{filterValues}</span>}
              </span>
            }
            onDelete={() => deleteFilter(ind)}
          />
        );
      })}
      {selectedFilters.length > 1 && (
        <Button
          onClick={deleteAllFilters}
          style={{
            color: color.black,
            boxShadow: 'none !important',
            border: '1px solid black',
            borderStyle: 'none none solid',
            padding: '0 0.25rem',
            borderRadius: '0',
            marginBottom: '.25rem',
          }}
        >
          <Typography>{t('insights.filters_clear_all')}</Typography>
        </Button>
      )}
    </HStack>
  );
};

export default FilterPills;
