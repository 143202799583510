import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'reactstrap';
import cx from 'classnames';

import Button from 'src/components/core/Button/Button';

import styles from './SearchInput.module.scss';

interface Props {
  onSearch: (searchQuery: string) => void;
  onClear?: () => void;
  initialSearchQuery?: string | undefined;
  placeholder?: string;
  size?: 'lg' | undefined;
  shape?: 'round' | 'rect' | undefined;
  inputClassName?: string | undefined;
}

function validateInput(searchQuery: string): boolean {
  return searchQuery.trim().length > 2;
}

const SearchInput = ({
  onSearch,
  onClear,
  initialSearchQuery = '',
  placeholder,
  size,
  shape = 'round',
  inputClassName,
}: Props) => {
  const [searchQuery, setSearchQuery] = React.useState(initialSearchQuery);

  const { t } = useTranslation();
  const searchPlaceholder = placeholder || t('collection.search');

  const handleSubmit = (evt: React.SyntheticEvent) => {
    evt.preventDefault();

    // clear if empty string
    if (searchQuery.trim().length === 0) {
      if (onClear) {
        onClear();
      }
      return;
    }

    // if invalid input, abort
    if (!validateInput(searchQuery)) {
      return;
    }

    // otherwise, we're all good, so searfh
    onSearch(searchQuery);
  };

  const handleClear = () => {
    setSearchQuery('');
    if (onClear) {
      onClear();
    }
  };

  return (
    <div
      className={cx(styles.container, {
        [styles[`size-${size}`]]: size != null,
        [styles[`shape-${shape}`]]: shape != null,
      })}
      data-testid="search-input"
    >
      <form
        onSubmit={handleSubmit}
        className={shape === 'rect' ? 'input-group' : undefined}
      >
        <Input
          type="text"
          placeholder={searchPlaceholder}
          bsSize={size}
          value={searchQuery}
          onChange={(evt) => setSearchQuery(evt.currentTarget.value)}
          className={cx(styles.searchInput, inputClassName, 'form-control')}
          aria-label={searchPlaceholder}
        />
        {searchQuery !== '' && (
          <Button
            color="basic"
            icon={['far', 'times']}
            type="button"
            className={cx('close', styles.clearButton)}
            onClick={handleClear}
            data-testid="search-clear-btn"
          />
        )}
        <Button
          color={shape === 'rect' ? 'default' : 'fora-purple'}
          icon={['fas', 'search']}
          shape={shape === 'rect' ? undefined : 'circle'}
          type="submit"
          className={styles.searchButton}
          aria-label="search"
          data-testid="search-button"
        />
      </form>
    </div>
  );
};

export default SearchInput;
