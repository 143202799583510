import * as React from 'react';
import { withRouter } from 'react-router-dom';

/**
 * This makes it so pages scroll to the top when jumping to new URLs
 */
class ScrollToTop extends React.Component<any> {
  componentDidUpdate(prevProps: any) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
