export function copyTextToClipboard(textToCopy: string) {
  // adapted from https://hackernoon.com/copying-text-to-clipboard-with-javascript-df4d4988697f
  // in order to copy to clipboard, have to create an input element
  const inputElement = document.createElement('input');

  // and add the input element with our value to the dom
  inputElement.value = textToCopy;
  inputElement.setAttribute('readonly', '');
  inputElement.style.position = 'absolute';
  inputElement.style.left = '-9999px';
  document.body.appendChild(inputElement);
  inputElement.select();
  document.execCommand('copy');

  // then remove it
  document.body.removeChild(inputElement);
}
