import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import { useUserTransaction } from 'src/components/AdminPage/Common/Hooks/useUserTransaction';
import LoadingSpinner from 'src/components/core/LoadingSpinner/LoadingSpinner';
import Toggle from 'src/components/core/Toggle/Toggle';
import adminSelectors from 'src/redux/admin/admin-selectors';
import { Collection, CollectionRole } from 'src/types/collection';
import { CRUD } from 'src/types/core';

import styles from './UploadConversationsToggle.module.scss';

interface UpdateUploadConversationsToggleProps {
  collection: Collection;
}

const UpdateUploadConversationsToggle = ({
  collection,
}: UpdateUploadConversationsToggleProps) => {
  const currentMember = useSelector(adminSelectors.getCurrentUser);
  const iAmAdminOrOwner = useSelector(adminSelectors.iAmAdminOrOwner);
  const isAdminOrOwner = useSelector(adminSelectors.isAdminOrOwner);
  const isOrganizationSenseMaker = useSelector(
    adminSelectors.isOrganizationSenseMaker
  );

  const { startTransaction, transactionLoading } = useUserTransaction();

  const collectionRoles = React.useMemo(
    () =>
      currentMember?.roles?.filter(
        (role) => role.collection_id === collection.id
      ),
    [currentMember, collection.id]
  );

  // The toggle is disabled if the users collection role is set to 'none'
  const hasCollectionRole = React.useMemo(
    () =>
      !!collectionRoles?.filter(
        (role) =>
          role.role_type.toLowerCase() !== CollectionRole.host.toLowerCase()
      ).length,
    [collectionRoles]
  );
  // A Manager user always has upload permissions, even when host role is not present
  const isManager = React.useMemo(
    () =>
      isAdminOrOwner ||
      !!collectionRoles?.filter(
        (role) =>
          role.role_type.toLowerCase() === CollectionRole.manager.toLowerCase()
      ).length,
    [collectionRoles, isAdminOrOwner]
  );
  // A non-manager user has upload permissions only when host role is present
  const isHostOrManager = React.useMemo(
    () =>
      isManager ||
      (!!collectionRoles?.filter(
        (role) =>
          role.role_type.toLowerCase() === CollectionRole.host.toLowerCase()
      ).length &&
        hasCollectionRole),
    [isManager, collectionRoles, hasCollectionRole]
  );

  const onUploadToggle = () => {
    if (currentMember) {
      if (isHostOrManager) {
        // Remove Host Role
        startTransaction({
          type: 'update_user_collection_role',
          crud: CRUD.delete,
          data: {
            id: currentMember.id,
            roles: [
              {
                role_type: 'host',
                collection_id: collection.id,
              },
            ],
            collectionId: collection.id,
          },
        });
      } else {
        // Add Host Role
        startTransaction({
          type: 'update_user_collection_role',
          crud: CRUD.post,
          data: {
            id: currentMember.id,
            roles: [
              {
                role_type: 'host',
                collection_id: collection.id,
              },
            ],
            collectionId: collection.id,
          },
        });
      }
    }
  };

  const disabled = !iAmAdminOrOwner || isManager || !hasCollectionRole;

  const props: UploadConversationsToggleProps = {
    collectionId: collection.id,
    canUploadConversation: isHostOrManager,
    isCollectionManager: isManager,
    disabled,
    loading: transactionLoading,
    onToggle: onUploadToggle,
  };

  return <UploadConversationsToggle {...props} />;
};

export type UploadConversationsToggleProps = {
  collectionId: Collection['id'];
  canUploadConversation?: boolean;
  isCollectionManager?: boolean;
  disabled?: boolean;
  loading?: boolean;
  onToggle: () => void;
};

export const UploadConversationsToggle = ({
  collectionId,
  canUploadConversation,
  isCollectionManager,
  disabled,
  loading,
  onToggle,
}: UploadConversationsToggleProps) => {
  const { t } = useTranslation();
  const UPLOADTOOLTIP = t('admin.tab_team_upload_conversations_tooltip');
  const [lockTooltip, setLockTooltip] = React.useState<boolean>(false);

  return (
    <div className={cx('d-flex', styles.uploadConversations)}>
      <Toggle
        lg
        id={`upload_conversation_${collectionId}`}
        testid={`upload-conversation-toggle-${collectionId}`}
        handleChange={onToggle}
        checked={!!canUploadConversation}
        disabled={disabled}
      />
      {!isCollectionManager && loading && (
        <span className={styles.icon}>
          <LoadingSpinner active size="md" />
        </span>
      )}
      {isCollectionManager && (
        <>
          <FontAwesomeIcon
            icon={['fas', 'lock']}
            size="lg"
            id={`upload-conversation-lock-${collectionId}`}
            data-testid={`upload-conversation-lock-${collectionId}`}
            className={styles.icon}
            aria-label={UPLOADTOOLTIP}
          />
          <Tooltip
            placement="bottom"
            hideArrow={true}
            isOpen={lockTooltip}
            innerClassName={cx(styles.tooltip)}
            target={`upload-conversation-lock-${collectionId}`}
            toggle={() => setLockTooltip((self) => !self)}
            data-testid={`tooltip-upload-conversation-lock-${collectionId}`}
          >
            {UPLOADTOOLTIP}
          </Tooltip>
        </>
      )}
    </div>
  );
};

export default UpdateUploadConversationsToggle;
