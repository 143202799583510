import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import { Container, Form } from 'reactstrap';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import moment from 'moment-timezone';
import { StringParam, useQueryParam } from 'use-query-params';

import { getShift, transformAccountsServerError } from 'src/api/api';
import Layout from 'src/components/Layout/Layout';
import ShiftChecker from 'src/components/RegistrationRoutes/ShiftChecker/ShiftChecker';
import authSelectors from 'src/redux/auth/auth-selectors';
import { createUser } from 'src/redux/auth/auth-slice';
import { StoreState } from 'src/redux/store';
import { User, UserCreatePayload } from 'src/types/auth';
import { Shift } from 'src/types/registration';
import dateFormatter, { useSlashDateFormat } from 'src/util/date';
import { isAnonymous } from 'src/util/user';
import * as viewportsUtil from 'src/util/viewports';
import LoadingOverlay from '../core/LoadingOverlay/LoadingOverlay';
import BirthdayStep from './BirthdayStep/BirthdayStep';
import EmailStep from './EmailStep/EmailStep';
// Form steps
import NameStep from './NameStep/NameStep';
import Over18Step from './Over18Step/Over18Step';
import PasswordStep from './PasswordStep/PasswordStep';
import Under13Step from './Under13Step/Under13Step';

import styles from './RegistrationRoutes.module.scss';

interface Step {
  component: any;
  path: string;
}
const generateRegistrationPath = (path: string) => `/register/${path}/`;
// steps everybody sees in this order
const baseSteps = [
  { component: NameStep, path: generateRegistrationPath('name') },
  { component: EmailStep, path: generateRegistrationPath('email') },
  { component: Over18Step, path: generateRegistrationPath('age') },
];
// various other steps that either are only sometimes seen or are seen in a different order
const passwordStep = {
  component: PasswordStep,
  path: generateRegistrationPath('password'),
};
const birthdayStep = {
  component: BirthdayStep,
  path: generateRegistrationPath('birthdate'),
};
const under13Step = {
  component: Under13Step,
  path: generateRegistrationPath('underage'),
};
// main path (not underage)
const steps = [...baseSteps, passwordStep];
// path for underage but over 13
const under18Steps = [...baseSteps, birthdayStep, passwordStep];
// path for underage and under 13
const under13Steps = [...baseSteps, birthdayStep, under13Step];

// list of all steps so we can generate routes
const allSteps = [...baseSteps, birthdayStep, under13Step, passwordStep];

interface BaseFormProps extends FormikProps<any> {
  onBack: () => void;
  location: any;
  generalFormError: React.ReactNode;
  isSavingUser: boolean;
  autoFocus: boolean;
}

const BaseForm = ({
  autoFocus,
  location,
  isSavingUser,
  ...formProps
}: BaseFormProps) => {
  const { t } = useTranslation();

  return (
    <Layout className={styles.layout} title={t('register.title')}>
      <Container className={styles.container}>
        <Form
          onSubmit={formProps.handleSubmit}
          className={styles.form}
          data-testid="registration-form"
          noValidate
          aria-describedby="general-error-msg"
          aria-invalid={Boolean(formProps.generalFormError != null)}
        >
          <Switch>
            <Redirect
              from="/register"
              exact
              to={{ pathname: steps[0].path, search: location.search }}
            />
            {allSteps.map((step) => {
              const { component: Step } = step;
              return (
                <Route
                  path={step.path}
                  key={step.path}
                  render={(props) => (
                    <Step
                      {...formProps}
                      {...props}
                      isSaving={isSavingUser}
                      autoFocus={autoFocus}
                    ></Step>
                  )}
                />
              );
            })}
          </Switch>
          {isSavingUser && (
            <LoadingOverlay
              active
              text="Creating your account..."
              bgColor="rgba(220, 220, 220, 0.9)"
            />
          )}
        </Form>
      </Container>
    </Layout>
  );
};

interface StateProps {
  isSavingUser: boolean;
  saveError: Error | undefined;
  isSavedUser: boolean;
  user: User;
}

/** Map state from redux to the components props */
const mapStateToProps = (state: StoreState): StateProps => ({
  isSavingUser: authSelectors.isSavingUserCreation(state),
  saveError: authSelectors.errorSavingUserCreation(state),
  isSavedUser: authSelectors.isSavedUserCreation(state),
  user: authSelectors.getUser(state) as User,
});

type Props = DispatchProp & StateProps & RouteComponentProps;
export const RegistrationRoutes = ({
  dispatch,
  isSavingUser,
  isSavedUser,
  saveError,
  location,
  history,
  user,
}: Props) => {
  // redirect if necessary
  React.useEffect(() => {
    if (isSavedUser) {
      if (user.is_minor) {
        history.push('/guardian-permission');
      } else {
        history.push({
          pathname: '/',
          state: { from: location.pathname },
        });
      }
    } else {
      // redirect logged in users to confirm signup step
      if (!isAnonymous(user)) {
        history.push({ pathname: '/confirm-signup', search: location.search });
      }
    }
  }, [isSavedUser, user, history, location.pathname, location.search]);
  // get shift parameters from query params if they exist
  const [shiftId, ,] = useQueryParam('shiftId', StringParam);
  const [shift, setShift] = React.useState<Shift | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState(false);
  const { t, i18n } = useTranslation();
  const DATEFORMAT = useSlashDateFormat();

  // check shift ID
  React.useEffect(() => {
    if (shiftId != null) {
      setIsLoading(true);
      // check the shift id with the backend
      getShift(shiftId)
        .then((shift) => {
          // make sure convo is still in the future and still needs participants
          setShift(shift);
          setIsLoading(false);
        })
        .catch(() => {
          setShift(undefined);
          setIsLoading(false);
        });
    }
  }, [shiftId]);

  const [flowSteps, setFlowSteps] = React.useState<Step[]>(steps);
  const [generalFormError, setGeneralFormError] = React.useState<
    React.ReactNode | undefined
  >(undefined);

  const { width } = useWindowSize();
  const curStepIdx = flowSteps.findIndex((d) => d.path === location.pathname);

  // this is set at the component level
  const validationSchema =
    curStepIdx === -1
      ? undefined
      : flowSteps[curStepIdx].component.validationSchema &&
        flowSteps[curStepIdx].component.validationSchema({ t });

  const isLastStep = () => {
    return location.pathname === flowSteps[flowSteps.length - 1].path;
  };

  const isFirstStep = () => {
    return location.pathname === flowSteps[0].path;
  };

  const handleNext = (values?: any) => {
    // figure out which path we should be on
    let updatedSteps: Step[];
    if (moment().diff(moment(values.birthdate, DATEFORMAT), 'years') < 13) {
      updatedSteps = under13Steps;
    } else if (values.over18 === 'false') {
      updatedSteps = under18Steps;
    } else {
      updatedSteps = steps;
    }

    setFlowSteps(updatedSteps);

    // setFlowSteps doesn't change the state in time for this action
    // so use updatedSteps
    if (!isLastStep()) {
      history.push({
        pathname: updatedSteps[curStepIdx + 1].path,
        search: location.search,
      });
    }
  };

  const handleBack = () => {
    if (!isFirstStep()) {
      history.push({
        pathname: flowSteps[curStepIdx - 1].path,
        search: location.search,
      });
    }
  };

  React.useEffect(() => {
    if (saveError) {
      const error = transformAccountsServerError(saveError);
      if (error && error.attribute) {
        if (error.attribute === 'shift_id') {
          setGeneralFormError(
            <span>
              <Trans
                i18nKey="register.error"
                /*eslint-disable */
                components={{
                  1: <a href="mailto:help@lvn.org" />,
                }}
                /*eslint-disable */
              />
            </span>
          );
        } else {
          history.push({
            pathname: generateRegistrationPath(error.attribute),
            state: error,
            search: location.search,
          });
        }
      }
    } else {
      setGeneralFormError(undefined);
    }
  }, [saveError, history, location.search]);

  const handleSubmit = (values: any, formikBag: FormikHelpers<any>) => {
    if (!isLastStep()) {
      handleNext(values);
      return;
    }

    const newUser: Partial<UserCreatePayload> = {
      email: values.email,
      first_name: values.name,
      create_in_salesforce: true, // make false if you want quicker dev
      is_minor: values.over18 === 'false', // form returns this value as a string instead of a bool
      marketing_email_opt_out: !values.allowMarketing,
      // only add these fields if they exist (don't want to send an empty string)
      ...(values.birthdate && {
        birthdate: dateFormatter.numericalDateFormat(
          moment(values.birthdate, DATEFORMAT),
          { dashed: true }
        ),
      }),
      language: i18n.language,
      ...(values.password && { password: values.password }),
      ...(shiftId && { shift_id: shiftId }),
    };

    // if successful, the user will be logged in, and App.tsx will redirect to the proper location
    dispatch(createUser(newUser));
  };

  const initialValues = {
    name: '',
    email: '',
    over18: undefined,
    password: '',
    birthdate: '',
    allowMarketing: true,
  };

  return (
    <ShiftChecker
      isLoading={isLoading}
      shiftId={shiftId}
      shift={shift}
      title={t('register.title')}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {(props) => (
          <BaseForm
            {...props}
            onBack={handleBack}
            location={location}
            generalFormError={generalFormError}
            autoFocus={!viewportsUtil.isMobile(width)}
            isSavingUser={isSavingUser}
          />
        )}
      </Formik>
    </ShiftChecker>
  );
};

export default connect(mapStateToProps)(RegistrationRoutes);
