import { StoreState } from 'src/redux/store';
import {
  NormalizedTopicSnippets,
  Paging,
  Topics,
} from 'src/types/conversation';
import { ColorScale, TopicSnippetsFilterOptions } from 'src/types/core';
import { getColorScale } from 'src/util/topics';

// selectors
export const selectors = {
  getTopics: (state: StoreState): Topics => state.topics.topics.topics,
  isTopicsLoading: (state: StoreState): boolean =>
    state.topics.topics.isLoading,
  getTopicsError: (state: StoreState): Error | undefined =>
    state.topics.topics.error,
  getTopicColorScale: (state: StoreState): ColorScale | undefined =>
    getColorScale(state.auth.user.topics),
  getTopicSnippets: (state: StoreState): NormalizedTopicSnippets =>
    state.topics.snippets.snippets,
  isTopicSnippetsLoading: (state: StoreState): boolean =>
    state.topics.snippets.isLoading,
  getTopicSnippetsError: (state: StoreState): Error | undefined =>
    state.topics.snippets.error,
  getTopicSnippetsPaging: (state: StoreState): Paging | undefined =>
    state.topics.snippets.paging,
  getTopicSnippetsFilterOptions: (
    state: StoreState
  ): TopicSnippetsFilterOptions | undefined => state.topics.snippets.filters,
};
export default selectors;
