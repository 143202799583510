// TODO: consume this places so we're not dupping this everyhwhere and we have one pattern to

import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import adminSelectors from 'src/redux/admin/admin-selectors';
import { userTransaction } from 'src/redux/admin/admin-slice';
import { generateTransaction } from 'src/redux/redux-helpers';
import { UserTransactions } from 'src/types/admin';

// align on
export const useUserTransaction = (
  onErr?: () => void,
  onSuccess?: () => void
) => {
  const dispatch = useDispatch();
  const [transaction, setTransaction] = useState<UserTransactions>();
  const [pending, setPending] = useState(false);
  const transactions = useSelector(adminSelectors.getUserTransactions);

  useEffect(() => {
    if (transaction) {
      const matchingTransaction = transactions.filter(
        (item) => item.id === transaction.id
      )[0];

      const succeeded = !matchingTransaction && !pending;
      const started = !!matchingTransaction && pending;
      const failed = !!matchingTransaction && !!matchingTransaction.error;

      if (succeeded) {
        onSuccess?.();
        setTransaction(undefined);
      } else if (started) {
        setPending(false);
        setTransaction(matchingTransaction);
      } else if (failed) {
        onErr?.();
        setTransaction(matchingTransaction);
      } else if (matchingTransaction) {
        setTransaction(matchingTransaction);
      }
    }
  }, [transactions, transaction, pending, onSuccess, onErr]);

  const startTransaction = useCallback(
    (t: UserTransactions) => {
      if (!t.id) {
        // honor id if caller has already set it
        t = generateTransaction(t);
      }

      setPending(true);
      setTransaction(t);

      // Sometimes this dispatch doesn't update the list of transactions
      // in the same tick as our local state so we need the pending
      // state to make sure we don't wipe away our running transaction
      // before it gets put into the list
      dispatch(userTransaction(t));
    },
    [dispatch]
  );

  return {
    transactionData: transaction?.data,
    transactionError: transaction?.error,
    transactionLoading: !!transaction && !transaction.error,
    startTransaction,
  };
};
