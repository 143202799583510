import { StoreState } from 'src/redux/store';
import { Tag } from 'src/types/conversation';

// selectors
export const selectors = {
  isTagsLoading: (state: StoreState): boolean => state.tags.latest.isLoading,
  getTags: (state: StoreState): Tag[] => state.tags.latest.tags,
  getTagsError: (state: StoreState): Error | undefined =>
    state.tags.latest.error,
};
export default selectors;
