import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import Button from 'src/components/core/Button/Button';

import styles from './DownloadButton.module.scss';

interface Props {
  downloadHref: string;
  onDownload: (type: string) => void;
  filename?: string;
}

const DownloadButton = ({ downloadHref, onDownload, filename }: Props) => {
  const { t } = useTranslation();
  const handleClick = () => {
    onDownload('license');
  };

  return (
    <Button
      title={t('highlight.download_audio')}
      color="plain"
      tag="a"
      href={downloadHref}
      icon={['far', 'arrow-to-bottom']}
      download={filename}
      onClick={handleClick}
      className={cx(styles.downloadBtn, 'btn--icon-only btn btn-plain')}
      data-testid="audio-download-btn"
    />
  );
};

export default DownloadButton;
