import { cloneDeep, isEqual } from 'lodash';
import {
  Descendant,
  Editor,
  Node,
  NodeEntry,
  Path,
  Range,
  Text,
  Transforms,
} from 'slate';

import { Snippet, WordEntry } from 'src/types/conversation';
import { EditableParagraph, EditableParagraphText } from 'src/types/transcript';
import { DecoratedRange, SearchParams } from 'src/types/transcript';
import { chunksFromWordEntries } from './transcript';

export const MIN_SEARCH_WORD_LENGTH = 1;

// Transform our snippets data into data that Slate can work with
export const snippetsToSlate = (snippets: Snippet[]): Descendant[] => {
  const results: Descendant[] = [];
  snippets.forEach((snippet) => {
    const wordEntries = snippet.words;
    // Chunks are used to align the text highlighting with the audio,
    // similar to how text highlighting is done in the transcript.
    const chunks = chunksFromWordEntries(wordEntries);
    const editableText: EditableParagraphText[] = chunks.length
      ? chunks
          .map((chunk) => {
            return chunk.words.map((word) => ({
              text: `${word[0]} `,
              audio_start_offset: word[1],
              audio_end_offset: word[2],
              chunk_start_offset: chunk.audio_start_offset,
              chunk_end_offset: chunk.audio_end_offset,
              confidence_score: word[3],
            }));
          })
          .reduce((a, b) => {
            return a.concat(b);
          }, [] as EditableParagraphText[])
      : [
          {
            text: '',
            audio_start_offset: snippet.audio_start_offset,
            audio_end_offset: snippet.audio_end_offset,
            chunk_start_offset: snippet.audio_start_offset,
            chunk_end_offset: snippet.audio_end_offset,
            confidence_score: 0,
          },
        ];
    const slateParagraph: Descendant = {
      id: snippet.id,
      type: 'timedText',
      speaker_name: snippet.speaker_name,
      speaker_id: snippet.speaker_id,
      audio_start_offset: snippet.audio_start_offset,
      audio_end_offset: snippet.audio_end_offset,
      children: editableText,
      index_in_conversation: snippet.index_in_conversation,
      conversation_id: snippet.conversation_id,
    };
    results.push(slateParagraph);
  });
  return results;
};

export const slateToSnippets = (paragraphs: EditableParagraph[]) => {
  const newSnippets = paragraphs.map((paragraph, ind) => {
    const newSnippet: Partial<Snippet> = {};
    const newWords = editableTextToWordEntries(paragraph.children);
    newSnippet.words = newWords;
    newSnippet.audio_start_offset = paragraph.audio_start_offset;
    newSnippet.audio_end_offset = paragraph.audio_end_offset;
    newSnippet.index_in_conversation = ind;
    newSnippet.conversation_id = paragraph.conversation_id;
    if (paragraph.speaker_name)
      newSnippet.speaker_name = paragraph.speaker_name;
    if (paragraph.speaker_id) newSnippet.speaker_id = paragraph.speaker_id;
    return newSnippet;
  });
  return newSnippets;
};

export const editableTextToWordEntries = (
  newTextNodes: EditableParagraphText[]
) => {
  // First, merge instances where the ui would suggest one word, even though
  // The slate nodes don't show as such.
  // ie. [{text:'Hello, w'}, {text:'e '}, {text:'are '}, {text:'here. '}]
  // goes to [{text:'Hello, '}, {text:'we '}, {text:'are '}, {text:'here. '}]
  const cleanedTextNodes = cloneDeep(newTextNodes).reduce(
    (ret, curr, ind, arr) => {
      if (
        !curr.text.endsWith(' ') &&
        ind !== arr.length - 1 &&
        !arr[ind + 1].text.startsWith(' ')
      ) {
        const currSplit = curr.text.split(' ');
        arr[ind + 1].text = currSplit[currSplit.length - 1] + arr[ind + 1].text;
        curr.text = currSplit.slice(0, currSplit.length - 1).join(' ');
      }
      ret.push(curr);
      return ret;
    },
    [] as EditableParagraphText[]
  );

  const newWordEntries: WordEntry[] = cleanedTextNodes
    .map((text) => {
      const newWords = text.text.trim().split(' ');
      if (newWords.length > 1) {
        // If multiple words found, change confidence score to 0
        return newWords.map(
          (word) =>
            [
              word,
              text.audio_start_offset,
              text.audio_end_offset,
              0,
            ] as WordEntry
        );
      }
      return newWords.map(
        (word) =>
          [
            word,
            text.audio_start_offset,
            text.audio_end_offset,
            text.confidence_score,
          ] as WordEntry
      );
    })
    .reduce((a, b) => {
      return a.concat(b);
    }, [] as WordEntry[]);
  return newWordEntries;
};

export const cleanFloat = (float: number, precision = 2) =>
  parseFloat(float.toFixed(precision));

/**
 * Return a list of ranges for which a search parameter exists in a node
 * @param node
 * @param path
 * @param searchParams
 * @param focusedRange If given, will tack on an extra property to the DecoratedRange to
 * show that this range in particular is in focus
 */
export const getSearchRanges = (
  node: Pick<EditableParagraph, 'children'>,
  path: Path,
  searchParams: SearchParams,
  focusedRange?: DecoratedRange
) => {
  const ranges: DecoratedRange[] = [];
  const { q, caseSensitive, isSearching } = searchParams;
  // only do a search if it is above the minimum number of characters
  if (!isSearching || q.length <= MIN_SEARCH_WORD_LENGTH) {
    return ranges;
  }

  // Grab the text as both a list of strings and a joined string
  const textList = node.children.map((c) => c.text);
  let text = textList.join('');

  // handle casing
  let search = q;
  if (!caseSensitive) {
    text = text.toLowerCase();
    search = q.toLowerCase();
  }

  // Locate the index(es) of the search string within the string of joined text nodes
  const occurranceIndexes: number[] = [];
  let searching = true;
  while (searching) {
    const previousIndex = occurranceIndexes.length
      ? occurranceIndexes[occurranceIndexes.length - 1]
      : -1;
    const index = text.indexOf(search, previousIndex + 1);
    if (index < 0) {
      searching = false;
    } else {
      occurranceIndexes.push(index);
    }
  }

  // If the search is not found, return empty ranges
  if (!occurranceIndexes.length) {
    return ranges;
  }

  // Find the ranges that match the occurrances of the string above
  let offset = 0;
  let occurrance = 0;
  textList.every((text, ind) => {
    if (occurrance >= occurranceIndexes.length) {
      // If we have found all occurrances, break; (performance)
      return false;
    }
    if (occurranceIndexes[occurrance] < offset + text.length) {
      // If the current occurrance index is between the offset and the end of
      // the current text node's length, we have found the starting path.
      let endPath = ind;
      let endOffset = search.length;
      // Search for the ending path starting at the current text node
      textList.slice(ind).every((t) => {
        if (endOffset < t.length) {
          // If the endOffset is less than the current text node's length,
          // we have found the correct end path and end offset
          return false;
        }
        endPath = endPath + 1;
        endOffset = endOffset - t.length;
        return true;
      });
      // The offset is the beginning of this text node. The start offset is the
      // difference of where the occurance is in the node, and the beginning of the node.
      const startOffset = occurranceIndexes[occurrance] - offset;
      if (ind === endPath) {
        // If within the same word, the end offset is the sjust the start offset plus the serach length
        endOffset = search.length + startOffset;
      }
      const range: DecoratedRange = {
        anchor: {
          path: [path[0], ind], // path[0] is the parent (paragraph) path of the text node
          offset: startOffset,
        },
        focus: { path: [path[0], endPath], offset: endOffset },
      };
      const isFocusedSearchHighlight =
        isEqual(range.anchor, focusedRange?.anchor) &&
        isEqual(range.focus, focusedRange?.focus);
      ranges.push({
        ...range,
        isFocusedSearchHighlight,
        searchHighlight: true,
      });
      // Move to next occurrance
      occurrance = occurrance + 1;
    }
    // Always increment offset so that it is at the beginning of the next word
    offset = offset + text.length;
    return true;
  });
  return ranges;
};

/**
 * This function provides the intersecting selection for a path given the editor has
 * a non point selection
 * @param editor - the editor object
 * @param path  - the path to check the selection against
 * @returns a list of ranges corresponding to the selection if the selection is within that node
 */
export const getSelectionRange = (editor: Editor, path: Path) => {
  if (
    !editor.selection ||
    editor.selection.anchor.path === editor.selection.focus.path
  ) {
    return [];
  }
  const intersection = Range.intersection(
    editor.selection,
    Editor.range(editor, path)
  );
  if (!intersection) {
    return [];
  }
  const range = { isSelected: true, ...intersection };
  return [range];
};

/**
 * Ensure a slate node is a paragrapph with children
 * @param node - a slate node
 * @returns boolean
 */
export const matchParagraphNode = (node: Node) =>
  !Text.isText(node) && (node as EditableParagraph).type === 'timedText';

/**
 *
 * @param editor - current slate editor
 * @param searchParams - the parameters needed for search
 * @returns list of Decorated Ranges
 */
export const getAllSearchRanges = (
  editor: Editor,
  searchParams: SearchParams
) => {
  if (
    !editor.children.length ||
    !searchParams.isSearching ||
    searchParams.q.length <= MIN_SEARCH_WORD_LENGTH
  ) {
    return [];
  }

  const matchingNodes = Editor.nodes(editor, {
    at: [],
    match: matchParagraphNode,
  });
  let nodeMatch = matchingNodes.next();
  const ranges: DecoratedRange[] = [];
  const allNodes: { [key: number]: NodeEntry[] } = {};
  // to make sure we can match across multiple words, we have to group the nodes by their paragraph
  // which is the first number of their path array
  while (!nodeMatch.done) {
    const [node, path] = nodeMatch.value;
    const paragraphNum = path[0];
    if (!allNodes[paragraphNum]) {
      allNodes[paragraphNum] = [nodeMatch.value];
    } else {
      allNodes[paragraphNum].push(nodeMatch.value);
    }
    if (matchParagraphNode(node)) {
      ranges.push(
        ...getSearchRanges(node as EditableParagraph, path, searchParams)
      );
    }
    nodeMatch = matchingNodes.next();
  }
  return ranges;
};

export const replaceAll = (
  editor: Editor,
  text: string,
  searchParams: SearchParams,
  matchedRanges: DecoratedRange[]
) => {
  if (!matchedRanges.length) {
    return;
  }

  if (text.toLowerCase() === searchParams.q.toLowerCase()) {
    // If the text to replace is the same as the query, replace all the existing matches.
    // This is for the instance we want to modify just the capitalization.
    matchedRanges.forEach((range) => {
      Transforms.insertText(editor, text, {
        at: {
          anchor: {
            ...range.anchor,
          },
          focus: {
            ...range.focus,
          },
        },
      });
    });
    return;
  }

  // We run into a problem when the text we are replacing is not the same length
  // as the text we are replacing it with. We can't just use the ranges we calculated
  // before because of this. We must get a new range for every range we mean to
  // replace.

  let replaceIndex = 0;
  while (replaceIndex < matchedRanges.length) {
    const currentPath = matchedRanges[replaceIndex].anchor.path[0];
    const newRange = getSearchRanges(
      editor.children[currentPath] as EditableParagraph,
      [currentPath],
      searchParams
    )[0];
    if (newRange) {
      Transforms.insertText(editor, text, {
        at: {
          anchor: {
            ...newRange.anchor,
          },
          focus: {
            ...newRange.focus,
          },
        },
      });
    }
    replaceIndex = replaceIndex + 1;
  }
};

/**
 * Get the next search match based on where the user's selection currently is.
 * We want to get the *next* match after the cursor, so that if the user is looking
 * at the middle of the doc, they aren't brought up to the first match, but rather the
 * next match. Returns a step number
 * @param editor
 * @param ranges
 */
export const getNextSearchMatchStep = (
  editor: Editor,
  ranges: DecoratedRange[]
) => {
  // with the ranges, we should set our step accordingly, based on the editor selection
  let step = 0;
  if (editor.selection) {
    const { anchor: selectionAnchor } = editor.selection;
    // we want to find the first range that is after the current selection
    // use .some + return True to mimic 'break' behavior
    const found = ranges.some((r) => {
      // returns -1, 0, or 1 for before, at, or after
      const pathCompare = Path.compare(r.anchor.path, selectionAnchor.path);
      // this match is above the selection
      if (pathCompare === -1) {
        ++step;
        // this match is in the same node as the selection
      } else if (pathCompare === 0) {
        // this match is before the selection
        if (r.anchor.offset <= selectionAnchor.offset) {
          ++step;
          // this match is after the selection, we found the next one
        } else {
          return true;
        }
      }
      // this match is in a node after the selection, we found the next one
      else {
        return true;
      }
      return false;
    });
    // we never found a match, could be selection is after ALL matches, so instead set to the first one
    // could also consider setting to the last one?
    if (!found) {
      step = 0;
    }
  }
  return step;
};

/**
 *
 * @param start - The start value
 * @param end - The end value (non-inclusive)
 * @param interval - The interval (as integer) in which you want the range subdivided
 * @returns a list of number between start and end subdivided by the interval
 * (ie. getRange(0,10,1) => [0,1,2,3, ... ,9, 10])
 */
export const getRange = (
  start: number,
  end: number,
  interval = 1
): number[] => {
  let value = start;
  const range = [];
  while (value < end) {
    range.push(Math.floor(value / interval) * interval);
    value = value + interval;
  }
  return range;
};

/**
 * This function returns a given time in seconds to milliseconds
 * @param time - time given in seconds
 */
export const secondsToMilliseconds = (time: number) => {
  const [seconds, subseconds] = time.toString().split('.');
  if (!subseconds?.length) {
    return parseInt(seconds + '000');
  }
  return parseInt(seconds + (subseconds + '0000').slice(0, 3));
};
