import React from 'react';
import { Badge as StrapBadge, BadgeProps } from 'reactstrap';
import cx from 'classnames';

import './Badge.scss';

interface Props extends BadgeProps {
  pale?: boolean | undefined;
}

export default function Badge({ children, className, pale, ...other }: Props) {
  return (
    <StrapBadge
      data-testid="badge"
      className={cx(className, {
        'badge--pale': pale,
      })}
      {...other}
    >
      {children}
    </StrapBadge>
  );
}
