import {
  Box,
  LinearProgress,
  linearProgressClasses,
  Typography,
  useTheme,
} from '@mui/material';

type ProgressBarProps = {
  progress: number;
  leftLabel?: string;
  rightLabel?: string;
};

export function ProgressBar({
  progress,
  leftLabel,
  rightLabel,
}: ProgressBarProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        p: 2,
        display: 'flex',
        borderRadius: '5px',
        minHeight: 65,
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
      }}
    >
      {leftLabel && (
        <Typography variant="body2" color="white" sx={{ pr: 2 }}>
          {leftLabel}
        </Typography>
      )}
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{
          alignSelf: 'center',
          flexGrow: 1,
          borderRadius: '5px',
          height: 5,
          [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: 'white',
          },
          [`& .${linearProgressClasses.bar}`]: {
            background: 'linear-gradient(to right, #4A6AD1, #4AFED1)',
          },
        }}
      />
      {rightLabel && (
        <Typography variant="body2" color="white" sx={{ pl: 2 }}>
          {rightLabel}
        </Typography>
      )}
    </Box>
  );
}
