import { createReducer } from '@reduxjs/toolkit';
import cloneDeep from 'lodash.clonedeep';

import { actions as conversationActions } from 'src/redux/conversation/conversation-slice';
import { actions as conversationsActions } from 'src/redux/conversations/conversations-slice';
import { actions as highlightActions } from 'src/redux/highlight/highlight-slice';
import { actions as searchActions } from 'src/redux/search/search-slice';
import { actions as transcriptActions } from 'src/redux/transcript-edit/transcript-edit-slice';
import { Organization, User } from 'src/types/auth';
import {
  Annotation,
  Conversation,
  Host,
  Snippet,
  Topic,
} from 'src/types/conversation';
import { NormalizedSnippetEntities } from 'src/types/core';
import { highlightInSnippet } from 'src/util/snippets';

export interface EntitiesState {
  conversations: {
    [id: number]: Conversation;
  };
  annotations: {
    [id: number]: Annotation;
  };
  snippets: {
    [id: number]: Snippet;
  };
  hosts: {
    [id: number]: Host;
  };
  organizations: {
    [id: number]: Organization;
  };
  staff_owners: {
    [id: number]: Partial<User>;
  };
  topics: {
    [id: number]: Topic;
  };

  /** store the highlight last edited to revert to on failure */
  undoHighlight: Annotation | undefined;
  undoConversation: Conversation | undefined;
}

// initial state for reducer
const initialState: EntitiesState = {
  conversations: {},
  annotations: {},
  snippets: {},
  hosts: {},
  organizations: {},
  staff_owners: {},
  topics: {},

  undoConversation: undefined,
  undoHighlight: undefined,
};

const reducer = createReducer(initialState, {
  // ADD ENTITIES
  [conversationActions.loadConversationSuccess.type]: (state, action) =>
    addEntities(state, action.payload.entities),
  [conversationsActions.loadConversationsSuccess.type]: (state, action) =>
    addEntities(state, action.payload.entities),
  [conversationsActions.loadDraftConversationsSuccess.type]: (state, action) =>
    addEntities(state, action.payload.entities),
  [searchActions.searchConversationsSuccess.type]: (state, action) =>
    addEntities(state, action.payload.entities),
  [highlightActions.loadUserHighlightsSuccess.type]: (state, action) =>
    addEntities(state, action.payload.entities),
  [highlightActions.loadAllHighlightsSuccess.type]: (state, action) =>
    addEntities(state, action.payload.entities),
  [highlightActions.loadStarredHighlightsSuccess.type]: (state, action) =>
    addEntities(state, action.payload.entities),
  [highlightActions.loadStarredAndUserHighlightsSuccess.type]: (
    state,
    action
  ) => addEntities(state, action.payload.entities),
  [highlightActions.loadSearchHighlightsSuccess.type]: (state, action) =>
    addEntities(state, action.payload.entities),
  [highlightActions.loadHighlightSuccess.type]: (state, action) =>
    addEntities(state, action.payload.entities),
  [highlightActions.loadExploreTopicsHighlightsSuccess.type]: (state, action) =>
    addEntities(state, action.payload.entities),
  [highlightActions.loadExploreHighlightsSuccess.type]: (state, action) =>
    addEntities(state, action.payload.entities),

  [conversationActions.loadConversationFailure.type]: (state, action) => {
    const { conversationId } = action.meta;
    // remove any sitting around conversation meta we have if we fail to load it
    // e.g. in case we don't have permissions for more. See LVN-967
    delete state.conversations[conversationId];
  },

  [conversationActions.saveNewAnnotationSuccess.type]: (state, action) =>
    addHighlight(state, action.payload),

  // DELETE HIGHLIGHTS
  [conversationActions.deleteHighlight.type]: (state, action) =>
    removeHighlight(state, action.payload),
  [highlightActions.deleteHighlight.type]: (state, action) =>
    removeHighlight(state, action.payload),

  // EDIT CONVERSATION
  [conversationActions.editConversation.type]: (state, action) => {
    const { conversationId, changes } = action.payload;
    state.undoConversation = cloneDeep(state.conversations[conversationId]);
    editConversation(state, conversationId, changes);
  },
  [conversationActions.editConversationSuccess.type]: (state, action) => {
    state.undoConversation = undefined;
  },
  [conversationActions.editConversationFailure.type]: (state, action) => {
    revertConversation(state);
    state.undoConversation = undefined;
  },

  // EDIT CONVERSATION DRAFT
  // does not optimistically set
  [conversationActions.editConversationDraftStateSuccess.type]: (
    state,
    action
  ) => {
    const { conversationId, changes } = action.payload;
    editConversation(state, conversationId, changes);
  },

  // EDIT CONVERSATION TRANSCRIPT (snippets)
  [transcriptActions.saveTranscriptEditSuccess.type]: (state, action) => {
    editSnippets(state, action.payload);
  },

  // EDIT HIGHLIGHT
  [highlightActions.editHighlight.type]: (state, action) => {
    const { highlight, changes } = action.payload;
    state.undoHighlight = highlight;
    editHighlight(state, highlight.id, changes);
  },

  [highlightActions.editHighlightSuccess.type]: (state, action) => {
    state.undoHighlight = undefined;
  },

  [highlightActions.editHighlightFailure.type]: (state, action) => {
    revertHighlight(state);
    state.undoHighlight = undefined;
  },

  [highlightActions.changeStarHighlightState.type]: (state, action) => {
    const { highlight, star } = action.payload;
    state.undoHighlight = highlight;
    editHighlight(state, highlight.id, { is_starred: star });
  },

  [highlightActions.changeStarHighlightStateSuccess.type]: (state, action) => {
    state.undoHighlight = undefined;
  },

  [highlightActions.changeStarHighlightStateFailure.type]: (state, action) => {
    revertHighlight(state);
    state.undoHighlight = undefined;
  },
});

export const actions = {};
export default reducer;

/**
 * Adds in all entities, properly updating byId and allIds for each.
 * @param state Draft state from immer of EntitiesState
 * @param entities Entities in normal form
 */
function addEntities(state: EntitiesState, entities: any) {
  for (const key in state) {
    // only take supported entity keys
    if (key in entities) {
      Object.assign(state[key as keyof EntitiesState]!, entities[key]);
    }
  }
}

/**
 * Adds a highlight to the entities state in all the necessary places.
 */
function addHighlight(state: EntitiesState, highlight: Annotation) {
  // add to annotations
  state.annotations[highlight.id] = highlight;

  const conversationId = highlight.conversation_id;
  if (conversationId == null) {
    return;
  }

  // add to conversation
  const conversation = state.conversations[conversationId];
  if (conversation == null) {
    return;
  }

  if (conversation.annotation_ids) {
    conversation.annotation_ids.push(highlight.id);
  } else {
    conversation.annotation_ids = [highlight.id];
  }

  // ensure converstion annotations are sorted properly
  conversation.annotation_ids.sort((aId, bId) => {
    const aTime = state.annotations[aId]
      ? state.annotations[aId].audio_start_offset
      : 0;
    const bTime = state.annotations[bId]
      ? state.annotations[bId].audio_start_offset
      : 0;
    return aTime - bTime;
  });

  if (conversation.snippet_ids) {
    // add to snippets
    conversation.snippet_ids
      .filter(
        // filter to just those that contain the highlight
        (snippetId) =>
          state.snippets[snippetId] &&
          highlightInSnippet(state.snippets[snippetId], highlight)
      ) // make sure the highlight is in their list
      .forEach((snippetId) => {
        const { annotation_ids } = state.snippets[snippetId];

        // add highlight to snippet
        if (annotation_ids) {
          annotation_ids.push(highlight.id);
        } else {
          state.snippets[snippetId].annotation_ids = [highlight.id];
        }

        // add snippet to highlight
        if (highlight.snippet_ids) {
          highlight.snippet_ids.push(snippetId);
        } else {
          highlight.snippet_ids = [snippetId];
        }
      });
  }
}

/**
 * Adds a highlight to the entities state in all the necessary places.
 */
function removeHighlight(state: EntitiesState, highlightId: number) {
  const highlight = state.annotations[highlightId];

  if (!highlight) {
    return;
  }

  // remove from annotations
  delete state.annotations[highlightId];

  const conversationId = highlight.conversation_id;
  if (conversationId == null) {
    return;
  }

  const conversation = state.conversations[conversationId];
  if (conversation == null) {
    return;
  }

  // remove from conversations
  if (conversation.annotation_ids) {
    conversation.annotation_ids = conversation.annotation_ids.filter(
      (convHighlightId) => convHighlightId !== highlightId
    );
  }

  // remove from snippets
  if (conversation.snippet_ids) {
    conversation.snippet_ids
      .filter(
        // filter to just those that contain the highlight
        (snippetId) =>
          state.snippets[snippetId] &&
          highlightInSnippet(state.snippets[snippetId], highlight)
      ) // make sure the highlight is in their list
      .forEach((snippetId) => {
        const { annotation_ids } = state.snippets[snippetId];
        if (annotation_ids) {
          state.snippets[snippetId].annotation_ids = annotation_ids.filter(
            (snipHighlightId) => snipHighlightId !== highlightId
          );
        }
      });
  }
}

/**
 * optimistically update the highlight
 */
function editHighlight(
  state: EntitiesState,
  highlightId: number,
  changes: Partial<Annotation>
) {
  if (state.annotations[highlightId]) {
    Object.assign(state.annotations[highlightId], changes);
  }
}

/**
 * Use the undo highlight cache to undo the optimistic update
 */
function revertHighlight(state: EntitiesState) {
  const undoHighlight = state.undoHighlight;
  if (!undoHighlight) {
    return;
  }

  if (state.annotations[undoHighlight.id]) {
    Object.assign(state.annotations[undoHighlight.id], undoHighlight);
  }
}

/**
 * optimistically update the conversation
 */
function editConversation(
  state: EntitiesState,
  conversationId: number,
  changes: Partial<Conversation>
) {
  if (state.conversations[conversationId]) {
    Object.assign(state.conversations[conversationId], changes);
  }
}

/**
 * Use the undo conversation cache to undo the optimistic update
 */
function revertConversation(state: EntitiesState) {
  const undoConversation = state.undoConversation;
  if (!undoConversation) {
    return;
  }

  if (state.conversations[undoConversation.id]) {
    Object.assign(state.conversations[undoConversation.id], undoConversation);
  }
}

/**
 * Update the snippets for relevant conversations and highlights after snippets are edited
 */
function editSnippets(
  state: EntitiesState,
  payload: NormalizedSnippetEntities
) {
  const { snippets } = payload.entities;
  const firstSnippetId = payload.order.snippets[0];
  const conversationId = snippets[firstSnippetId].conversation_id;
  const conversation = state.conversations[conversationId];
  // remove original snippets associated with this conversation
  conversation.snippet_ids?.forEach((snipId) => {
    delete state.snippets[snipId];
  });

  // match highlights to snippets
  const annotations: Annotation[] = Object.values(
    cloneDeep(state.annotations)
  ).filter((annotation) => annotation.conversation_id === conversationId);
  Object.values(snippets).forEach((snippet: Snippet) => {
    annotations.forEach((annotation: Annotation) => {
      if (highlightInSnippet(snippet, annotation)) {
        if (snippet.annotation_ids) {
          snippet.annotation_ids.push(annotation.id);
        } else {
          snippet.annotation_ids = [annotation.id];
        }
      }
    });
  });

  // assign the proper snippet ids
  conversation.snippet_ids = Object.keys(snippets).map(Number);
  // and repopulate snippets with the updated ones
  Object.assign(state.snippets, snippets);
}
