import * as React from 'react';
import { useTranslation } from 'react-i18next';
import xor from 'lodash.xor';
import {
  ArrayParam,
  QueryParamConfig,
  SetQuery,
  useQueryParams,
} from 'use-query-params';

import FilterChecklist from 'src/components/FilterChecklist/FilterChecklist';
import SideFilter from 'src/components/SideFilter/SideFilter';
import { ConversationsFilterOptions, FilterOption } from 'src/types/core';

export const getActiveLanguages = (
  languageCodes: string[] | undefined,
  filterOptions: ConversationsFilterOptions | undefined
) => {
  return (
    languageCodes &&
    filterOptions &&
    filterOptions.languages &&
    languageCodes.map((code) =>
      filterOptions.languages.find((lang) => lang.code === code)
    )
  );
};

export const handleLanguageChange = (
  option: FilterOption,
  languageCodes: string[] | undefined,
  setQuery: SetQuery<{
    lang: QueryParamConfig<string[] | undefined>;
    page: QueryParamConfig<number | undefined>;
  }>
) => {
  if (option.code) {
    setQuery({
      lang: xor(languageCodes, [option.code]),
      page: 1,
    });
  }
};

interface Props {
  filterOptions: ConversationsFilterOptions | undefined;
}

const LanguageFilter = ({ filterOptions }: Props) => {
  const [query, setQuery] = useQueryParams({
    lang: ArrayParam,
  });
  const { t } = useTranslation();
  const { lang: languageCodes } = query;
  return (
    <SideFilter
      title={t('conversations.filter_languages')}
      open={Boolean(languageCodes)}
      disabled={!(filterOptions && filterOptions.languages.length)}
    >
      {filterOptions && filterOptions.languages && (
        <FilterChecklist
          options={filterOptions.languages}
          onChange={(option) =>
            handleLanguageChange(option, languageCodes, setQuery)
          }
          activeFilterOptions={getActiveLanguages(languageCodes, filterOptions)}
          title={t('conversations.filter_languages')}
          fade
        />
      )}
    </SideFilter>
  );
};

export default LanguageFilter;
