import * as React from 'react';

import {
  AudioManager,
  ChangeCallback,
  SEEK_EPSILON,
  SeekSubscription,
} from 'src/audio/AudioManager';
import Html5Audio from 'src/audio/Html5Audio';
import { audioHasPlayedToEnd } from 'src/util/audio';

/**
 * The type that the context provides
 */
export interface GlobalAudioContextValue {
  isPlaying: boolean;
  isLoading: boolean;
  duration: number | undefined;
  playbackSpeed: number;
  src: string | undefined;
  meta: any;
  id: string | undefined;
  audioError: MediaError | undefined;
  sound: Html5Audio | undefined;
  play: (seekTime?: number, endTime?: number) => void;
  seek: (seekTime: number, play?: boolean) => void;
  relativeSeek: (seekTime: number, play?: boolean) => void;
  conditionalSeek: (
    minTime: number,
    maxTime?: number | undefined,
    targetTime?: number
  ) => void;
  changePlaybackSpeed: (playbackSpeed: number) => void;
  pause: () => void;
  stop: () => void; // pauses then clears the src
  togglePlaying: () => void;
  changeSound: (src: string | undefined, meta?: any, id?: any) => void;
  seekSubscribe: (seekSubscription: SeekSubscription) => void;
  seekUnsubscribe: (seekSubscription: SeekSubscription) => void;
  changeSubscribe: (changeSubscription: ChangeCallback) => void;
  changeUnsubscribe: (changeSubscription: ChangeCallback) => void;
}

// Initialize the context with placeholder default values
export const GlobalAudioContext = React.createContext<GlobalAudioContextValue>({
  isPlaying: false,
  isLoading: false,
  duration: undefined,
  playbackSpeed: 1,
  src: undefined,
  meta: undefined,
  audioError: undefined,
  id: undefined,
  sound: undefined,
  play: () => {
    return;
  },
  seek: () => {
    return;
  },
  relativeSeek: () => {
    return;
  },
  conditionalSeek: () => {
    return;
  },
  changePlaybackSpeed: () => {
    return;
  },
  pause: () => {
    return;
  },
  stop: () => {
    return;
  },
  togglePlaying: () => {
    return;
  },
  changeSound: () => {
    return;
  },
  seekSubscribe: () => {
    return;
  },
  seekUnsubscribe: () => {
    return;
  },
  changeSubscribe: () => {
    return;
  },
  changeUnsubscribe: () => {
    return;
  },
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export class GlobalAudioContextProvider extends React.Component<Props> {
  audioManager: AudioManager;

  constructor(props: Props) {
    super(props);
    this.audioManager = new AudioManager();

    // after changes have been published, we force react to update to get a re-render
    this.audioManager.onUpdate = () => {
      this.forceUpdate();
    };
  }

  componentWillUnmount() {
    this.audioManager.destroy();
  }

  globalAudioContextValue() {
    return {
      isPlaying: this.audioManager.isPlaying(),
      isLoading: this.audioManager.isLoading(),
      seekTime: this.audioManager.seekTime(),
      duration: this.audioManager.duration(),
      playbackSpeed: this.audioManager.playbackSpeed(),
      src: this.audioManager.getSrc(),
      meta: this.audioManager.meta,
      id: this.audioManager.id,
      audioError: this.audioManager.getError(),
      sound: this.audioManager.sound,
      seek: this.audioManager.handleControlsSeek,
      relativeSeek: this.audioManager.handleControlsRelativeSeek,
      conditionalSeek: this.audioManager.handleControlsConditionalSeek,
      play: this.audioManager.handleControlsPlay,
      pause: this.audioManager.handleControlsPause,
      stop: this.audioManager.handleControlsStop,
      togglePlaying: this.audioManager.handleControlsTogglePlaying,
      changeSound: this.audioManager.handleChangeSound,
      changePlaybackSpeed: this.audioManager.handleControlsChangePlaybackSpeed,
      seekSubscribe: this.audioManager.seekSubscribe,
      seekUnsubscribe: this.audioManager.seekUnsubscribe,
      changeSubscribe: this.audioManager.changeSubscribe,
      changeUnsubscribe: this.audioManager.changeUnsubscribe,
    };
  }

  render() {
    const value = this.globalAudioContextValue();

    return (
      <GlobalAudioContext.Provider value={value}>
        {this.props.children}
      </GlobalAudioContext.Provider>
    );
  }
}

export default GlobalAudioContext;

/**
 * Provides a seekTime value that stays in sync with audio while
 * isActive is true. If other parameters src, startTime, endTime
 * are provided, we further limit when updates are sent.
 */
export const useSeekTime = ({
  src,
  startTime,
  endTime,
  isActive = true,
}: Pick<SeekSubscription, 'src' | 'startTime' | 'endTime'> & {
  /** When true, keep the seekTime value in sync with audio */
  isActive?: boolean;
} = {}) => {
  const { seekSubscribe, seekUnsubscribe } =
    React.useContext(GlobalAudioContext);

  // by setting state, we get a re-render in our hook users
  const [internalSeekTime, setInternalSeekTime] = React.useState<
    number | undefined
  >(undefined);
  React.useEffect(() => {
    let seekSubscription: SeekSubscription | undefined;
    // if we actively care about seek time, subscribe
    if (isActive) {
      seekSubscription = {
        src,
        startTime,
        endTime,
        callback: (seekTime: number | undefined) => {
          // this will force a re-render with the new seekTime
          setInternalSeekTime(seekTime);
        },
      };
      seekSubscribe(seekSubscription);
    }

    return () => {
      // if we subscribed, unsubscribe when undoing this effect
      if (seekSubscription) {
        seekUnsubscribe(seekSubscription);
      }
    };
  }, [
    isActive,
    src,
    startTime,
    endTime,
    setInternalSeekTime,
    seekSubscribe,
    seekUnsubscribe,
  ]);

  // return the last seen seekTime
  return internalSeekTime;
};

/**
 * Same as useSeekTime except it remembers the last value if seekTime
 * becomes null after having a value.
 */
export const usePersistedSeekTime = ({
  src,
  startTime,
  endTime,
  isActive = true,
}: Pick<SeekSubscription, 'src' | 'startTime' | 'endTime'> & {
  /** When true, keep the seekTime value in sync with audio */
  isActive?: boolean;
} = {}) => {
  const rawSeekTime = useSeekTime({ src, startTime, endTime, isActive });
  const seekTimeWasNull = rawSeekTime != null;
  const lastSeenSeekTime = React.useRef(rawSeekTime);
  const seekTime = seekTimeWasNull ? rawSeekTime : lastSeenSeekTime.current;

  // keep the last seen up to date (only updating when available)
  React.useEffect(() => {
    if (rawSeekTime != null) {
      lastSeenSeekTime.current = rawSeekTime;
    }
  }, [rawSeekTime]);

  return { seekTime, seekTimeWasNull };
};

/**
 * Handle changing isActive based on changes to the audio being played.
 * This is necessary to handle the case where something external to the
 * component that became active changes the audio. (e.g. you activate
 * a highlight card, but then navigate elsewhere in the conversation,
 * the highlight should no longer be active).
 */
export const useIsActiveFromAudio = ({
  src,
  startTime,
  endTime,
  initialIsActive = false,
  id,
}: Pick<SeekSubscription, 'src' | 'startTime' | 'endTime' | 'id'> & {
  /** Whether upon initialization we listen for seek time immediately */
  initialIsActive?: boolean;
}): [boolean, React.Dispatch<React.SetStateAction<boolean>>] => {
  const { changeSubscribe, changeUnsubscribe } =
    React.useContext(GlobalAudioContext);
  const [isActive, setIsActive] = React.useState<boolean>(initialIsActive);

  React.useEffect(() => {
    // on change, reset isActive
    const callback: ChangeCallback = (
      seekTime: number | undefined,
      activeSrc: string | undefined,
      activeId: string | undefined
    ) => {
      if (isActive) {
        // is it outside of range or a diff audio file? mark it inactive
        // use a little padding (SEEK_EPSILON) to keep audio active if we are
        // just at the end (e.g. the handler is called slightly after the end
        // time but might as well just be the end time)
        if (
          (src != null && src !== activeSrc) ||
          (id != null && id !== activeId) ||
          (seekTime != null &&
            startTime != null &&
            endTime != null &&
            (seekTime < startTime - SEEK_EPSILON ||
              seekTime > endTime + SEEK_EPSILON))
        ) {
          setIsActive(false);
        }
      }
    };
    changeSubscribe(callback);

    return () => {
      changeUnsubscribe(callback);
    };
  }, [
    src,
    id,
    startTime,
    endTime,
    isActive,
    setIsActive,
    changeSubscribe,
    changeUnsubscribe,
  ]);

  return [isActive, setIsActive];
};

/**
 * Returns a callback that can be used to toggle playing based on a time range.
 * Handles resuming, changing audio if necessary or pausing.
 */
export const useToggleActivatedPlay = (
  isActive: boolean,
  seekTime: number | undefined,
  startTime: number | undefined,
  endTime: number | undefined,
  setIsActive: (isActive: boolean) => void,
  audioUrl: string | undefined,
  meta?: any,
  id?: string
) => {
  const { play, changeSound, isPlaying, pause } =
    React.useContext(GlobalAudioContext);

  return React.useCallback(() => {
    // are we currently within the specified segment? if so, play resumes
    // otherwise, play starts at the startTime
    const isWithinSegment =
      isActive &&
      seekTime != null &&
      startTime != null &&
      endTime != null &&
      seekTime >= startTime &&
      seekTime < endTime;

    const noSegment = endTime == null;

    if (isActive && isPlaying && (isWithinSegment || noSegment)) {
      pause();
      // resume if we are in the middle of listening
    } else if (isWithinSegment) {
      // active but paused, just resume.
      play(undefined, endTime);
      setIsActive(true);
    } else {
      if (audioUrl) {
        changeSound(audioUrl, meta, id);
      }
      play(startTime, endTime);
      setIsActive(true);
    }
  }, [
    isActive,
    seekTime,
    startTime,
    endTime,
    isPlaying,
    pause,
    play,
    setIsActive,
    audioUrl,
    changeSound,
    meta,
    id,
  ]);
};

/**
 * Returns a handler that changes audio + plays (seeking if necessary, ending if necessary) + sets active
 */
export const useActivatedPlay = (
  startTime: number | undefined,
  setIsActive: (isActive: boolean) => void,
  audioUrl: string | undefined,
  meta?: any,
  id?: string
) => {
  const { play, changeSound } = React.useContext(GlobalAudioContext);

  return React.useCallback(
    (seekTime?: number, endTime?: number) => {
      if (audioUrl) {
        changeSound(audioUrl, meta, id);
      }
      play(seekTime == null ? startTime : seekTime, endTime);
      setIsActive(true);
    },
    [changeSound, play, setIsActive, audioUrl, meta, id, startTime]
  );
};

interface UseSeekAudio {
  /** Whether or not the seek time listener is active */
  isActive: boolean;
  /** Set the seek time listener to be active or not */
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  /** The current or last seen seek time */
  seekTime: number | undefined;
  /** Play the audio, changing sound if required, while also setting isActive to true */
  activatedPlay: (seekTime?: number, endTime?: number) => void;
  /** Play/pause/resume the audio, setting isActive to true on play/resume */
  toggleActivatedPlaying: () => void;
  /** If the current audio's seek time equals the audio's end time */
  hasEnded: boolean;
  /** Change the seek time */
  seek: (seekTime: number, play?: boolean | undefined) => void;
  /** Pause the audio */
  pause: () => void;
  /** Whether or not the Audio is playing */
  isPlaying: boolean;
}

/**
 * Combine all the convenient audio/seektime hooks into one
 */
export const useSeekAudio = ({
  audioUrl,
  startTime,
  endTime,
  meta,
  initialIsActive,
  id,
}: {
  audioUrl?: string | undefined;
  startTime?: number | undefined;
  endTime?: number | undefined;
  meta?: any;
  initialIsActive?: boolean | undefined;
  id?: string;
}): UseSeekAudio => {
  const { seek, pause, isPlaying } = React.useContext(GlobalAudioContext);
  const [isActive, setIsActive] = useIsActiveFromAudio({
    src: audioUrl,
    startTime: startTime,
    endTime: endTime,
    initialIsActive,
    id,
  });

  const seekTime = useSeekTime({
    src: audioUrl,
    startTime: startTime,
    endTime: endTime,
    isActive,
  });

  const hasEnded = audioHasPlayedToEnd(seekTime, endTime);

  const activatedPlay = useActivatedPlay(
    startTime,
    setIsActive,
    audioUrl,
    meta,
    id
  );

  const toggleActivatedPlaying = useToggleActivatedPlay(
    isActive,
    seekTime,
    startTime,
    endTime,
    setIsActive,
    audioUrl,
    meta,
    id
  );

  return {
    isActive,
    setIsActive,
    seekTime,
    activatedPlay,
    toggleActivatedPlaying,
    hasEnded,
    seek,
    pause,
    isPlaying,
  };
};
