import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Moment } from 'moment-timezone';
import {
  call,
  put,
  SagaReturnType,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import * as api from 'src/api/api';
import { transformAccountsServerError } from 'src/api/api';
import { actions as collectionsActions } from 'src/redux/collections/collections-slice';
import { User, UserRequestPayload } from 'src/types/auth';
import { Collection, CollectionDetail } from 'src/types/collection';
import { BootstrapData, LanguageOption, ServerError } from 'src/types/core';
import dateFormatter from 'src/util/date';
import { anonymousUser } from 'src/util/user';

type EditType =
  | 'profile'
  | 'chapter'
  | 'language'
  | 'password'
  | 'profile_image'
  | 'legal'
  | 'guardian_permission'
  | 'guardian_agree';

export interface EditTransaction {
  userId: User['id'];
  changes: Partial<UserRequestPayload>;
  editType: EditType;
  error?: ServerError | undefined;
  isSaved?: boolean;
}

interface AuthState {
  user: User;
  createUser: {
    isSaving: boolean;
    error: Error | undefined;
    isSaved: boolean;
  };
  editUserTransactions: EditTransaction[];
  isLoggingOut: boolean;
  termsEffectiveDate: Moment | undefined;
  salesforceUrl: string;
  languages: LanguageOption[];
  acceptedCookies: boolean | undefined;
}

let user = anonymousUser;
if (process.env.NODE_ENV === 'production') {
  const data = window.BOOTSTRAP_DATA;
  user = data.user;
  user = api.transformUser(user);
}

// initial state for reducer
const initialState: AuthState = {
  user: user,
  createUser: {
    isSaving: false,
    error: undefined,
    isSaved: false,
  },
  editUserTransactions: [],
  isLoggingOut: false,
  termsEffectiveDate: undefined,
  salesforceUrl: '',
  languages: [],
  acceptedCookies: undefined,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: {
      reducer(state, action: PayloadAction<BootstrapData>) {
        const { user, terms_effective_date, languages, feature_flags } =
          action.payload;
        state.user = user;
        state.isLoggingOut = false;
        // the dates are strings at this point, but we transform to moment here
        // we type them as Moment though for easier consumption after this point
        // since loginSuccess always runs as soon as the app opens up
        state.termsEffectiveDate = terms_effective_date
          ? dateFormatter.timeToMoment(terms_effective_date as string).utc()
          : undefined;
        state.salesforceUrl = action.payload.salesforce_url;
        state.languages = languages;
      },
      prepare: (bootstrapData: BootstrapData) => {
        const event = {
          category: 'user',
          action: 'login',
        };
        return { payload: bootstrapData };
      },
    },
    logout: {
      reducer(state, action: PayloadAction<void>) {
        state.user = anonymousUser;
        state.isLoggingOut = true;
      },
      prepare: () => ({ payload: undefined }),
    },

    createUser: {
      reducer(state, action: PayloadAction<Partial<User>>) {
        state.createUser.isSaving = true;
        state.createUser.isSaved = false;
      },
      prepare: (user: Partial<User>) => {
        return { payload: user };
      },
    },

    createUserSuccess(state, action: PayloadAction<User>) {
      state.user = action.payload;
      state.createUser.isSaving = false;
      state.createUser.error = undefined;
      state.createUser.isSaved = true;
    },

    createUserFailure(state, action: PayloadAction<Error>) {
      state.createUser.isSaving = false;
      state.createUser.error = action.payload;
    },

    editUser(state, action: PayloadAction<EditTransaction>) {
      // A transaction pattern is used for updating users.
      // A form component sends a form specific (by type) transaction.
      // The state (saving, isSaved, error) of the api request
      // is stored within the transaction. The form component may
      // have side effects based on the state of the transaction.
      state.editUserTransactions = state.editUserTransactions.concat([
        action.payload,
      ]);
    },

    editUserSuccess(
      state,
      action: PayloadAction<{
        updatedUser: Partial<User>;
        transaction?: EditTransaction;
      }>
    ) {
      // endpoint only returns partial user object, so assign the relevant info
      // only do this if the changes apply to our logged in user
      // (guardians can modify the guardian fields for a different user)
      if (state.user.id === action.payload.updatedUser.id) {
        state.user = Object.assign(state.user, action.payload.updatedUser);
      }
      const payloadTransaction = action.payload.transaction;
      if (payloadTransaction) {
        const newTransactions = state.editUserTransactions.map(
          (transaction) => {
            if (
              transaction.userId === payloadTransaction.userId &&
              transaction.editType === payloadTransaction.editType
            ) {
              transaction.isSaved = true;
            }
            if (
              payloadTransaction.editType === 'guardian_agree' &&
              transaction.editType === 'guardian_agree'
            ) {
              transaction.isSaved = true;
            }
            return transaction;
          }
        );
        state.editUserTransactions = newTransactions;
      }
    },

    removeEditTransaction(state, action: PayloadAction<EditTransaction>) {
      const newTransactions = state.editUserTransactions.filter(
        (transaction) =>
          !(
            transaction.userId === action.payload.userId &&
            transaction.editType === action.payload.editType
          )
      );
      state.editUserTransactions = newTransactions;
    },

    editUserFailure(
      state,
      action: PayloadAction<{
        error: ServerError;
        transaction: EditTransaction;
      }>
    ) {
      const newTransactions = state.editUserTransactions.map((transaction) => {
        if (
          transaction.userId === action.payload.transaction.userId &&
          transaction.editType === action.payload.transaction.editType
        ) {
          transaction.error = action.payload.error;
        }
        return transaction;
      });
      state.editUserTransactions = newTransactions;
    },
    setCookieAcceptance(state, action: PayloadAction<boolean>) {
      state.acceptedCookies = action.payload;
    },
  },
  extraReducers: (builder) => {
    // This is needed after editing a collection but not after creating a new collection
    // because we refresh the bootstrap data after creating a collection
    // LVN-2068
    builder.addCase(
      collectionsActions.editCollectionDetailSuccess,
      (
        state,
        action: PayloadAction<{
          collectionDetail: Pick<CollectionDetail, 'id'>;
          changes: Partial<Collection>;
        }>
      ) => {
        if (state.user) {
          state.user.collections = state.user.collections.map((collection) => {
            if (collection.id === action.payload.collectionDetail.id) {
              return Object.assign(collection, action.payload.changes);
            }
            return collection;
          });
        }
      }
    );
  },
});

export const {
  loginSuccess,
  logout,
  editUser,
  removeEditTransaction,
  editUserSuccess,
  editUserFailure,
  createUser,
  createUserSuccess,
  createUserFailure,
  setCookieAcceptance,
} = slice.actions;
export const actions = slice.actions;

export default slice.reducer;

export const corticoAuthSignOut = () => {
  window.location.href = `/logout`;
};

/** Sagas */
export function* sagaLogout() {
  try {
    // use auth system to log out
    yield call(corticoAuthSignOut);
  } catch (err) {
    // ignore errors on sign out, but log them to console
    console.log('Error signing out', err);
  }
}

export function* sagaCreateUser(action: ReturnType<typeof createUser>) {
  try {
    const user = action.payload;
    const newUser: SagaReturnType<typeof api.createUserAccount> = yield call(
      api.createUserAccount,
      user
    );
    // fire the action with successful response
    yield put(createUserSuccess(newUser));
  } catch (err) {
    // an error occurred, fire the failure action
    yield put(createUserFailure(err as Error));
  }
}

export function* sagaEditUser(action: ReturnType<typeof editUser>) {
  try {
    const { userId, changes } = action.payload;
    const updatedUser: SagaReturnType<typeof api.editUser> = yield call(
      api.editUser,
      userId,
      changes
    );
    // fire the action with successful response
    yield put(
      editUserSuccess({
        updatedUser: updatedUser as Partial<User>,
        transaction: action.payload,
      })
    );
  } catch (err) {
    // an error occurred, fire the failure action
    const error = transformAccountsServerError(err as Error);
    yield put(editUserFailure({ error, transaction: action.payload }));
  }
}

export function* sagaRemoveEditTransaction(
  action: ReturnType<typeof editUserSuccess>
) {
  if (action.payload.transaction) {
    yield put(removeEditTransaction(action.payload.transaction));
  }
}

export const sagas = [
  takeLatest(logout.type, sagaLogout),
  takeEvery(editUser.type, sagaEditUser),
  takeEvery(editUserSuccess.type, sagaRemoveEditTransaction),
  takeEvery(editUserFailure.type, sagaRemoveEditTransaction),
  takeEvery(createUser.type, sagaCreateUser),
];
