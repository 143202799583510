import { ChevronLeftRounded, ChevronRightRounded } from '@mui/icons-material';

import Color from 'src/assets/_util.scss';

export function ArrowLeftButton(props: ArrowProps) {
  return <ChevronLeftRounded {...mapProps(props)} />;
}

export function ArrowRightButton(props: ArrowProps) {
  return <ChevronRightRounded {...mapProps(props)} />;
}

function mapProps({
  style,
  disabled = false,
  color = 'gray800',
  disabledColor = 'gray400',
  size = 16,
  ...props
}: ArrowProps) {
  return {
    style: {
      color: Color[disabled ? disabledColor : color],
      fontSize: `${size}px`,
      cursor: disabled ? '' : 'pointer',
      'pointer-events': disabled ? 'none' : 'auto',
      ...style,
    },
    ...props,
  };
}

// DEFINITIONS

type ArrowProps = {
  disabled?: boolean;
  color?: string;
  disabledColor?: string;
  size?: number;
} & Omit<Parameters<typeof ChevronLeftRounded>[0], 'color'>;
