import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import { User } from 'src/types/auth';
import { ConversationNestedCollection } from 'src/types/collection';
import {
  AnnotationPrivacyLevel,
  Conversation,
  Highlight,
} from 'src/types/conversation';
import { isConversationProtected } from 'src/util/conversation';
import {
  getHighlightPrivacyPermission,
  getPermissionToCreateHighlight,
  hasPermission,
} from 'src/util/user';

import styles from './PrivacyMenu.module.scss';

interface MenuOptionProps {
  onChange: () => void;
  enabled: boolean;
  active: boolean;
  iconName: IconName;
  iconClassName?: string;
  title: string;
  subtitle: React.ReactNode;
}
const MenuOption = ({
  onChange,
  enabled,
  active,
  iconName,
  iconClassName,
  title,
  subtitle,
  ...other
}: MenuOptionProps) => {
  return (
    <li
      onClick={onChange}
      className={cx('d-flex align-items-baseline py-2', styles.menuOption, {
        [styles.enabled]: enabled,
      })}
      {...other}
    >
      <FontAwesomeIcon
        icon={iconName}
        className={cx('me-2 pt-1', iconClassName)}
        fixedWidth
      />
      <div>
        <span className={styles.title}>{title}</span>{' '}
        {active && (
          <FontAwesomeIcon icon={['far', 'check']} className="text-success" />
        )}
        <div className={styles.subtitle}>{subtitle}</div>
      </div>
    </li>
  );
};

interface Props {
  highlight: Pick<Highlight, 'user_id' | 'privacy_level' | 'conversation'>;
  collection: ConversationNestedCollection;
  user: User;
  onChange: (privacy: AnnotationPrivacyLevel) => void;
  conversation?: Conversation;
}
const PrivacyMenu = ({
  highlight,
  collection,
  user,
  onChange,
  conversation,
}: Props) => {
  const { t } = useTranslation();
  const conversationIsProtected = isConversationProtected(
    highlight,
    conversation
  );

  // if convo is protected, user has to at least be a general member of this collection
  // otherwise they need to be able to make community highlights
  const createConversationHighlightPerm = getPermissionToCreateHighlight(
    conversationIsProtected,
    collection.id
  );
  const userHasCollectionAccess = hasPermission(
    user,
    createConversationHighlightPerm
  );

  const userIsActiveHighlighter = user.id === highlight.user_id;
  const userCanPublicize = hasPermission(
    user,
    getHighlightPrivacyPermission('update', collection.id)
  );

  const userCanUnpublicizeHighlight =
    userIsActiveHighlighter || userCanPublicize;
  const conversationOptionEnabled =
    userHasCollectionAccess &&
    (highlight.privacy_level === 'public' ? userCanUnpublicizeHighlight : true);

  // TODO: update when we allow individuals to be granted separate access to conversations
  const conversationOptionTitle = conversationIsProtected
    ? t('highlights.type_dropdown_collection')
    : t('conversation.anyone');
  const conversationOptionIcon: IconName = conversationIsProtected
    ? 'user-friends'
    : 'users';
  let conversationOptionSubtitle = conversationIsProtected ? (
    <>
      <Trans
        i18nKey="highlights.type_dropdown_collection_subtext"
        values={{
          name: collection.title,
        }}
        components={{
          1: <span className="text-gray-800" />,
        }}
      />
    </>
  ) : (
    t('conversation.create_highlight_protected_subheader_2')
  );
  if (highlight.privacy_level === 'public' && !userCanUnpublicizeHighlight) {
    conversationOptionSubtitle = t('conversation.collaborators_only');
  }

  const handleChangeToPrivate = () => {
    if (userIsActiveHighlighter) {
      onChange('private');
    }
  };

  const handleChangeToPublic = () => {
    if (userCanPublicize) {
      onChange('public');
    }
  };

  const handleChangeToConversation = () => {
    if (conversationOptionEnabled) {
      onChange('conversation');
    }
  };

  return (
    <section className="px-3 pt-2 pb-2">
      <h3 className="h6 mb-0 mt-1 muted">
        {t('highlights.type_dropdown_header')}
      </h3>
      <span className="text-gray-700">
        {t('highlights.type_dropdown_subheader')}
      </span>
      <ul className="list-unstyled mb-0" data-testid="privacyMenu">
        <MenuOption
          onChange={handleChangeToPrivate}
          enabled={userIsActiveHighlighter}
          active={highlight.privacy_level === 'private'}
          iconName="lock"
          iconClassName={
            userIsActiveHighlighter ? 'text-gray-900' : 'text-gray-700'
          }
          title={t('highlights.type_dropdown_me')}
          subtitle={
            userIsActiveHighlighter
              ? t('conversation.create_highlight_private_subheader')
              : t('highlights.type_dropdown_me_subtext')
          }
          data-testid="privateItem"
        />
        <MenuOption
          onChange={handleChangeToConversation}
          enabled={conversationOptionEnabled}
          active={highlight.privacy_level === 'conversation'}
          iconName={conversationOptionIcon}
          iconClassName="text-gray-700"
          title={conversationOptionTitle}
          subtitle={conversationOptionSubtitle}
          data-testid="conversationItem"
        />
        <MenuOption
          onChange={handleChangeToPublic}
          enabled={userCanPublicize || highlight.privacy_level === 'public'}
          active={highlight.privacy_level === 'public'}
          iconName="globe"
          iconClassName="text-primary"
          title={t('highlights.type_dropdown_anyone')}
          subtitle={
            userCanPublicize || highlight.privacy_level === 'public' ? (
              t('highlights.type_dropdown_anyone_subtext')
            ) : (
              <>{t('highlights.type_dropdown_anyone_subtext_2')}</>
            )
          }
          data-testid="publicItem"
        />
      </ul>
    </section>
  );
};

export default PrivacyMenu;
