import * as React from 'react';
import cx from 'classnames';

import Button from '../core/Button/Button';
import Tag, { Props as TagProps } from '../core/Tag/Tag';

interface ClearableTagProps {
  onClear: (e: React.SyntheticEvent) => void;
}

type Props = ClearableTagProps & TagProps;

const ClearableTag = ({
  children,
  onClear,
  className,
  tagName,
  ...rest
}: Props) => {
  return (
    <Tag {...rest} className={cx('pe-0 pt-0', className)}>
      <>
        {children}
        <Button
          onClick={onClear}
          icon={['far', 'times']}
          className="p-0 small"
          aria-label={`Clear ${tagName} filter`}
        />
      </>
    </Tag>
  );
};

export default ClearableTag;
