import React, { useCallback } from 'react';
import {
  BaseOperation,
  createEditor,
  Descendant,
  Element,
  Node,
  NodeEntry,
  NodeOperation,
  Path,
  Transforms,
} from 'slate';
import { withHistory } from 'slate-history';
import { withReact } from 'slate-react';

import { Annotation, Snippet } from 'src/types/conversation';
import {
  DecoratedRange,
  EditableParagraph,
  EditableParagraphText,
  SearchParams,
  SpeakerChangeRequest,
  SpeakerOption,
} from 'src/types/transcript';
import {
  getAllSearchRanges,
  getNextSearchMatchStep,
  getSearchRanges,
  getSelectionRange,
  matchParagraphNode,
  replaceAll,
  snippetsToSlate,
} from 'src/util/slate';
import useTranscriptSelection from './useTranscriptSelection';

const useTranscriptEditor = (
  snippets: Snippet[],
  save: (value: Descendant[]) => void,
  redact: (
    value: Descendant[],
    annotation?: Pick<Annotation, 'audio_start_offset' | 'audio_end_offset'>
  ) => void,
  scroll: (time: number, onScrollCompletion?: () => void) => void
) => {
  // create the Slate editor
  const [editor] = React.useState(withHistory(withReact(createEditor())));
  const [value, setValue] = React.useState<Descendant[]>([]);
  const [searchParams, setSearchParams] = React.useState<SearchParams>({
    q: '',
    caseSensitive: false,
    isSearching: false,
  });
  const [searchMatchedRanges, setSearchMatchedRanges] = React.useState<
    DecoratedRange[]
  >([]);
  const [searchStep, setSearchStep] = React.useState(0);
  const [speakerOptions, setSpeakerOptions] = React.useState<SpeakerOption[]>(
    []
  );
  const {
    isRedacting,
    setIsRedacting,
    redactionParams,
    setAnnotation,
    handleSelect,
    handleRedactions,
  } = useTranscriptSelection(editor);

  const getSpeakerOptions = React.useCallback(
    (value: Descendant[]): SpeakerOption[] => {
      const allSnippetSpeakers: SpeakerOption[] = value.map((paragraph) => {
        return {
          speaker_id: (paragraph as EditableParagraph).speaker_id,
          speaker_name: (paragraph as EditableParagraph).speaker_name,
        };
      });
      const speakerIds = allSnippetSpeakers.map((s) => s.speaker_id);
      return allSnippetSpeakers
        .filter((s, i) => !speakerIds.includes(s.speaker_id, i + 1))
        .sort((a, b) => a.speaker_name.localeCompare(b.speaker_name));
    },
    []
  );

  React.useEffect(() => {
    const newValue = snippetsToSlate(snippets);
    setValue(newValue);
    setSpeakerOptions(getSpeakerOptions(newValue));
  }, [snippets, getSpeakerOptions]);

  const changeSpeakerName = React.useCallback(
    (data: SpeakerChangeRequest, path: Path) => {
      if (data.all) {
        // Either re-assign all values of the speaker to the target speaker,
        // or update the name for all instances of the matching speaker
        Transforms.setNodes(
          editor,
          {
            type: 'timedText',
            speaker_name: data.speaker_name,
            speaker_id: data.speaker_id,
          },
          {
            at: [],
            match: (node) =>
              Element.isElement(node) &&
              node.type === 'timedText' &&
              node.speaker_id === data.current_speaker_id,
          }
        );
      } else {
        // Either create a new speaker, or re-assign to an existing speaker
        const newId =
          data.speaker_id ??
          (
            Math.max(
              ...speakerOptions.map((speaker) => parseInt(speaker.speaker_id))
            ) + 1
          ).toString();
        Transforms.setNodes(
          editor,
          {
            type: 'timedText',
            speaker_name: data.speaker_name,
            speaker_id: newId,
          },
          { at: path }
        );
      }
      setSpeakerOptions(getSpeakerOptions(editor.children));
    },
    [editor, getSpeakerOptions, speakerOptions]
  );

  const handleChange = React.useCallback(() => {
    // Only handle a change if it causes a diff in the value
    const { operations } = editor;
    const firstOperation = operations[0];

    if (!firstOperation || firstOperation.type === 'set_selection') {
      // User clicked or highlighted a word(s)
      handleSelect();
      return;
    }

    // Handle if the operation is within a paragraph
    if (
      firstOperation.type === 'insert_text' ||
      firstOperation.type === 'remove_text'
    ) {
      if (searchParams.isSearching) {
        const ranges = getAllSearchRanges(editor, searchParams);
        setSearchMatchedRanges(ranges);
      }
    }

    if (firstOperation.path.length === 1) {
      if (firstOperation.type === 'split_node') {
        // Handle when a paragraph is split

        // Update new paragraph
        const newPath = [firstOperation.path[0] + 1]; // The path of the new paragraph node that is created
        const newParagraph = editor.children[newPath[0]] as EditableParagraph;
        const newStartTime = newParagraph.children[0].audio_start_offset;
        Transforms.setNodes(
          editor,
          {
            audio_start_offset: newStartTime,
          },
          { at: newPath }
        );

        // Update original paragraph
        const oldParagraph = editor.children[
          firstOperation.path[0]
        ] as EditableParagraph;
        const oldEndTime =
          oldParagraph.children[oldParagraph.children.length - 1]
            .audio_end_offset;
        Transforms.setNodes(
          editor,
          {
            audio_end_offset: oldEndTime,
          },
          { at: [firstOperation.path[0]] }
        );
      }

      if (firstOperation.type === 'merge_node') {
        // Handle when paragraphs are merged
        const mergePath = [firstOperation.path[0] - 1];
        const paragraphToMerge = firstOperation.properties as EditableParagraph;
        Transforms.setNodes(
          editor,
          {
            audio_end_offset: paragraphToMerge.audio_end_offset,
          },
          { at: mergePath }
        );
      }
    }
    if (isRedacting) {
      redact(editor.children, redactionParams.annotation);
    } else {
      save(editor.children);
    }
    setIsRedacting(false);
    setAnnotation(undefined);
    setValue(editor.children);
  }, [
    editor,
    isRedacting,
    setIsRedacting,
    setAnnotation,
    handleSelect,
    searchParams,
    redact,
    redactionParams.annotation,
    save,
  ]);

  const focusedSearch = searchMatchedRanges[searchStep];

  const decorate = React.useCallback(
    ([node, path]: NodeEntry<Node>) => {
      if (matchParagraphNode(node)) {
        const searchRanges = getSearchRanges(
          node as EditableParagraph,
          path,
          searchParams,
          focusedSearch
        );
        const selectionRanges = getSelectionRange(editor, path);

        return [...searchRanges, ...selectionRanges];
      }
      return [];
    },
    [searchParams, focusedSearch, editor]
  );

  /**
   * When a new term is searched, recalculate where the matches are
   */
  React.useEffect(() => {
    const ranges = getAllSearchRanges(editor, searchParams);
    setSearchMatchedRanges(ranges);

    const step = getNextSearchMatchStep(editor, ranges);
    setSearchStep(step);
  }, [searchParams, editor]);

  /**
   * Helper function that sets the search step based on the direction (next/previous)
   * Handles the case of manually scrolling to a result when only one result is found
   */
  const handleStep = useCallback(
    (direction: 'next' | 'previous') => {
      // if there's only one search term found, we need to manually scroll to it, as scrollToChunk() only fires if the searchStep changes (which it doesn't in the case of one result, as the searchStep is always 0)
      if (searchMatchedRanges.length === 1) {
        const occurrenceTime: number = (
          editor.children[
            searchMatchedRanges[0].anchor.path[0]
          ] as EditableParagraph
        ).children[searchMatchedRanges[0].anchor.path[1]].audio_start_offset;
        scroll(occurrenceTime);
      }
      setSearchStep((searchStep) => {
        if (direction === 'next') {
          return searchStep >= searchMatchedRanges.length - 1
            ? 0
            : searchStep + 1;
        } else {
          return searchStep === 0
            ? searchMatchedRanges.length - 1
            : searchStep - 1;
        }
      });
    },
    [editor, scroll, searchMatchedRanges, setSearchStep]
  );

  const handleNextSearch = () => {
    handleStep('next');
  };
  const handlePreviousSearch = () => {
    handleStep('previous');
  };

  const handleReplace = (text: string, all: boolean) => {
    if (!all) {
      const nextOccuranceRange =
        searchStep === searchMatchedRanges.length - 1
          ? searchMatchedRanges[0]
          : searchMatchedRanges[searchStep + 1];
      const nextOccurranceTime = (
        editor.children[nextOccuranceRange.anchor.path[0]] as EditableParagraph
      ).children[nextOccuranceRange.anchor.path[1]].audio_start_offset;
      Transforms.insertText(editor, text, {
        at: {
          anchor: focusedSearch.anchor,
          focus: focusedSearch.focus,
        },
      });
      setTimeout(() => {
        scroll(nextOccurranceTime);
      }, 1000);
    } else {
      replaceAll(editor, text, searchParams, searchMatchedRanges);
    }
  };
  const onPaste = React.useCallback(
    (text: string) => {
      if (
        text.length &&
        editor.selection &&
        editor.selection.anchor.path === editor.selection.focus.path
      ) {
        // We are pasting within the same paragraph
        const pasteOperation: BaseOperation = {
          type: 'insert_text',
          path: editor.selection?.focus.path,
          offset: editor.selection.anchor.offset,
          text,
        };
        editor.apply(pasteOperation);
      }
    },
    [editor]
  );
  const totalSearchResults = searchMatchedRanges.length;

  // Undo/Redo Logic

  const getClosestNode = React.useCallback(
    // Grab the closest (previous or next) node to the edit.
    // If no node found, return undefined.
    (path: Path | undefined) => {
      if (!path) {
        return;
      }
      const getNode = (path: Path) => Node.get(editor, path);
      try {
        const editNode = getNode(path);
        return editNode;
      } catch (error) {
        const newPath = [...path];
        if (path.length === 1) {
          if (newPath[0] === 0) {
            newPath[0] = newPath[0] + 1;
          }
          newPath[0] = newPath[0] - 1;
        }
        if (newPath.length === 2) {
          if (newPath[1] === 0) {
            newPath[1] = newPath[1] + 1;
          }
          newPath[1] = newPath[1] - 1;
        }
        try {
          const editNode = getNode(newPath);
          return editNode;
        } catch (error) {
          return;
        }
      }
    },
    [editor]
  );

  // Remove any edit set that doesn't have a path in it.
  // Occasionally a selection is the only operation in the edit set.
  const filteredUndos = editor.history.undos.filter((operations) =>
    operations.some((operation) => (operation as NodeOperation).path)
  );
  const filteredRedos = editor.history.redos.filter((operations) =>
    operations.some((operation) => (operation as NodeOperation).path)
  );

  const canUndo = React.useMemo(
    () => filteredUndos.length > 0,
    [filteredUndos]
  );
  const canRedo = React.useMemo(
    () => filteredRedos.length > 0,
    [filteredRedos]
  );

  const findEditPath = (edits: BaseOperation[][]) => {
    // Find the first path in the list of operations.
    const lastEditGroup = edits[edits.length - 1];
    let path: Path | undefined;
    lastEditGroup.every((operation) => {
      if (!path && (operation as NodeOperation).path) {
        path = (operation as NodeOperation).path;
        return;
      }
    });
    return path;
  };

  const undo = React.useCallback(() => {
    if (canUndo) {
      const lastEditPath = findEditPath(filteredUndos);
      const editNode = getClosestNode(lastEditPath);
      if (editNode) {
        const seekTime = (editNode as EditableParagraphText).audio_start_offset;
        scroll(seekTime, editor.undo);
      } else {
        // if node not found, undo without a scroll
        editor.undo();
      }
    }
  }, [canUndo, editor, filteredUndos, getClosestNode, scroll]);

  const redo = React.useCallback(() => {
    if (canRedo) {
      const lastEditPath = findEditPath(filteredRedos);
      const editNode = getClosestNode(lastEditPath);
      if (editNode) {
        const seekTime = (editNode as EditableParagraphText).audio_start_offset;
        scroll(seekTime, editor.redo);
      } else {
        // if node not found, undo without a scroll
        editor.redo();
      }
    }
  }, [canRedo, editor, filteredRedos, getClosestNode, scroll]);

  return {
    editor,
    value,
    speakerOptions,
    changeSpeakerName,
    handleChange,
    onPaste,
    searchParams,
    setSearchParams,
    handleNextSearch,
    handlePreviousSearch,
    handleReplace,
    searchIndex: searchStep,
    totalSearchResults,
    decorate,
    canUndo,
    canRedo,
    undo,
    redo,
    redactionParams,
    handleRedactions,
  };
};

export default useTranscriptEditor;
