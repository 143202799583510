import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Paging } from 'src/types/conversation';

interface Props {
  pagingInfo: Paging | undefined;
  itemsPerPage: number;
  i18NextKey: string;
  i18NextRangeKey: string;
  className?: string;
}

export const calculatePagingValues = (
  pagingInfo: Paging,
  itemsPerPage: number
) => {
  // calculate first and last values to show
  const firstItemNumber = (pagingInfo.page_num - 1) * itemsPerPage + 1;
  const lastPage = Math.ceil(pagingInfo.total / itemsPerPage);
  const lastItemNumber = Math.min(
    pagingInfo.page_num * itemsPerPage,
    pagingInfo.total
  );
  return { firstItemNumber, lastItemNumber, lastPage };
};

const PagingSummary = ({
  pagingInfo,
  itemsPerPage,
  i18NextKey,
  i18NextRangeKey,
  className,
}: Props) => {
  const { t } = useTranslation();
  if (!pagingInfo) {
    return null;
  }
  const { firstItemNumber, lastItemNumber, lastPage } = calculatePagingValues(
    pagingInfo,
    itemsPerPage
  );
  if (!pagingInfo.total) {
    return null;
  }

  // if there is only one page, render "Showing N [items]"
  // otherwise, render "Showing [first] — [last] of [total] [items]"
  return (
    <div className={className} data-testid="pagingSummary">
      {lastPage === 1
        ? t(i18NextKey, { number: pagingInfo.page_size })
        : t(i18NextRangeKey, {
            number: pagingInfo.total,
            range: `${firstItemNumber}–${lastItemNumber}`,
          })}
    </div>
  );
};

export default PagingSummary;
