import { useCallback } from 'react';
import { DispatchProp, useSelector } from 'react-redux';
import { AutocompleteChangeReason } from '@mui/material';
import _ from 'lodash';

import { setFiltersVisibility } from 'src/redux/catalog-filters/catalog-filters-slice';
import {
  resetFiltersVisibility,
  resetVisibility,
  setVisibility,
} from 'src/redux/catalog-filters/catalog-filters-slice';
import { StoreState } from 'src/redux/store';
import { VisibilityCategory, VisibilityMap } from 'src/types/insights';

export const useFilterValuesChanged = ({ dispatch }: DispatchProp) => {
  const loadingMode = useSelector(
    (state: StoreState) => state.catalog.loadingMode
  );
  const handleFilterValuesChanged = useCallback(
    <T extends { id: number }>(
      filterType: VisibilityCategory,
      newValues: T[],
      existingValues: T[],
      filterVisibilityMap: VisibilityMap,
      reason: AutocompleteChangeReason
    ) => {
      const fastMode = loadingMode === 'fast';
      switch (reason) {
        case 'clear': {
          dispatch(
            fastMode
              ? resetFiltersVisibility(filterType)
              : resetVisibility(filterType)
          );
          break;
        }
        case 'selectOption': {
          _.difference(
            newValues,
            existingValues.filter((value) => filterVisibilityMap[value.id])
          ).forEach((value: T) => {
            dispatch(
              fastMode
                ? setFiltersVisibility({
                    category: filterType,
                    id: value.id,
                    visible: true,
                  })
                : setVisibility({
                    category: filterType,
                    id: value.id,
                    visible: true,
                  })
            );
          });
          break;
        }
        case 'removeOption': {
          _.difference(
            existingValues.filter((value) => filterVisibilityMap[value.id]),
            newValues
          ).forEach((value: T) => {
            dispatch(
              fastMode
                ? setFiltersVisibility({
                    category: filterType,
                    id: value.id,
                    visible: undefined,
                  })
                : setVisibility({
                    category: filterType,
                    id: value.id,
                    visible: undefined,
                  })
            );
          });
          break;
        }
      }
    },
    [dispatch, loadingMode]
  );

  return handleFilterValuesChanged;
};
