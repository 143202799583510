import React from 'react';

import CreateAnnotationControls from 'src/components/InteractiveTranscript/CreateAnnotationControls/CreateAnnotationControls';
import {
  ViewHighlightControl,
  ViewHighlightsControl,
} from 'src/components/InteractiveTranscript/ViewHighlightsControl/ViewHighlightsControl';
import {
  AnnotationCreationSelection,
  Conversation,
  Highlight,
  TranscriptSnippet,
} from 'src/types/conversation';
import { highlightInSnippet } from 'src/util/snippets';

interface Props {
  snippet: TranscriptSnippet;
  snippets: TranscriptSnippet[]; // needed to compute original text and speaker
  highlights: Highlight[] | undefined;
  conversation: Conversation;
  conversationId?: number;
  annotationCreationSelection?: AnnotationCreationSelection | undefined;
  onPersistSelection?: (persistSelection: boolean) => void;
  onClearSelection?: () => void;
  onChangeActiveHighlight: (highlight: Highlight | undefined) => void;
  onPlay?: (seekTime?: number, endTime?: number) => void;
  seekTime?: number | undefined;
}

const MarginControls = ({
  snippet,
  snippets,
  highlights,
  conversationId,
  annotationCreationSelection,
  onPersistSelection,
  onClearSelection,
  onChangeActiveHighlight,
  onPlay,
  seekTime,
  conversation,
}: Props) => {
  const [createAnnotationTopOffset, setCreateAnnotationTopOffset] =
    React.useState<number>(0);
  const [highlightTopOffset, setHighlightTopOffset] = React.useState<number>(0);

  const highlightsStartingHere = highlights?.filter(
    (highlight) => highlight.audio_start_offset >= snippet.audio_start_offset
  );

  React.useEffect(() => {
    // This use effect is to position the highlight button /creation buttons
    // inline with where the highlight occurs

    const getCorrectStartingNode = (start: number) => {
      // Ensure we are looking inside the transcript tab when
      // getting the starting node
      const transcriptTabElement = document.querySelectorAll(
        `[data-testid="transcript-tab-panel"]`
      )[0];
      const nodes = transcriptTabElement?.querySelectorAll(
        `[data-starttime="${start}"]`
      );
      const list = [...(nodes as any)].filter((element) =>
        element.attributes['data-testid']?.value.includes(start)
      );
      return list[0];
    };

    if (annotationCreationSelection) {
      setCreateAnnotationTopOffset(
        getCorrectStartingNode(annotationCreationSelection.audio_start_offset)
          ?.offsetTop
      );
    }

    if (highlightsStartingHere && highlightsStartingHere[0]) {
      setHighlightTopOffset(
        getCorrectStartingNode(highlightsStartingHere[0].audio_start_offset)
          ?.offsetTop
      );
    }
  }, [annotationCreationSelection, highlightsStartingHere]);

  const collection = conversation.collection;
  if (
    annotationCreationSelection &&
    conversationId != null &&
    collection != null &&
    onPersistSelection &&
    onClearSelection
  ) {
    // only show a button if at the end
    if (
      snippet.audio_end_offset < annotationCreationSelection.audio_end_offset
    ) {
      return null;
    }

    // include the affected snippets
    const annotatedSnippets = snippets.filter((snippet) =>
      highlightInSnippet(snippet, annotationCreationSelection)
    );

    // sometimes adjacent snippets have the same end time which === the creation selection end time
    // so we need to only show at the last one of those
    if (annotatedSnippets[annotatedSnippets.length - 1] !== snippet) {
      return null;
    }

    return (
      <div
        className="d-flex w-100 position-absolute"
        style={{ top: createAnnotationTopOffset }} // Allows the annotation to open next to the highlight
      >
        <CreateAnnotationControls
          annotationCreationSelection={annotationCreationSelection}
          onPersistSelection={onPersistSelection}
          onClearSelection={onClearSelection}
          conversationId={conversationId}
          annotatedSnippets={annotatedSnippets}
          onPlay={onPlay}
          seekTime={seekTime}
          conversation={conversation}
        />
      </div>
    );
  }

  if (!highlights || !highlights.length) {
    return null;
  }

  if (!highlightsStartingHere || !highlightsStartingHere.length) {
    return null;
  }

  return (
    <div
      className="d-flex w-100 position-absolute"
      style={{ top: highlightTopOffset }} // Allows the annotation to open next to the highlight
    >
      {highlightsStartingHere.length === 1 ? (
        <ViewHighlightControl
          highlight={highlightsStartingHere[0]}
          conversation={conversation}
          onChangeActiveHighlight={onChangeActiveHighlight}
        />
      ) : (
        <ViewHighlightsControl
          highlights={highlightsStartingHere}
          conversation={conversation}
          onChangeActiveHighlight={onChangeActiveHighlight}
        />
      )}
    </div>
  );
};

export default React.memo(MarginControls);
