import { nest } from 'd3';
import memoize from 'memoizee';

import { Conversation, Snippet } from 'src/types/conversation';

export interface SpeakerTurn {
  audio_start_offset: number;
  audio_end_offset: number;
  speaker_id: string;
  speaker_name: string;
  speaker_gender: string;
  is_participant: boolean;
  is_facilitator: boolean;
  snippets: Snippet[];
}

export interface SpeakerTurnCollection {
  speaker_id: string;
  speaker_gender: string;
  speaker_name: string;
  is_facilitator: boolean;
  is_participant: boolean;
  turns: SpeakerTurn[];
}

/**
 * Merges adjacent snippets when spoken by the same person to create
 * a set of blocks of time delineated by speaker
 */
export const computeSpeakerTurns = memoize(
  (conversation: Conversation): SpeakerTurnCollection[] => {
    const minSnippetDuration = 5; // in seconds

    function speakerTurnFromSnippet(snippet: Snippet): SpeakerTurn {
      return {
        audio_start_offset: snippet.audio_start_offset,
        audio_end_offset: snippet.audio_end_offset,
        speaker_id: snippet.speaker_id,
        speaker_name: snippet.speaker_name,
        speaker_gender: snippet.speaker_gender,
        is_participant: snippet.is_participant,
        is_facilitator: snippet.is_facilitator,
        snippets: [snippet],
      };
    }

    // remove any that are less than 5 seconds long to smooth out the vis
    const { snippets = [] } = conversation;
    const filteredSnippets = snippets.filter(
      (d: Snippet) => d.duration > minSnippetDuration
    );

    // no snippets available to render
    if (!filteredSnippets.length) {
      return [];
    }

    // merge adjacent speaker snippets
    const mergedSpeakerTurns: SpeakerTurn[] = [];
    let curr: SpeakerTurn = speakerTurnFromSnippet(filteredSnippets[0]);

    for (let i = 1; i < filteredSnippets.length; ++i) {
      const next = filteredSnippets[i];

      // extend if the same speaker
      if (next.speaker_id === curr.speaker_id) {
        curr.audio_end_offset = next.audio_end_offset;
        // otherwise create a new segment
      } else {
        mergedSpeakerTurns.push(curr);
        curr = speakerTurnFromSnippet(next);
      }
    }
    mergedSpeakerTurns.push(curr);

    const speakerEntries = nest<SpeakerTurn>()
      .key((d: SpeakerTurn) => d.speaker_id as string)
      .entries(mergedSpeakerTurns);

    const speakerTurns = speakerEntries.map((speakerEntry) => {
      const speakerTurnSample = speakerEntry.values[0];
      return {
        speaker_id: speakerTurnSample.speaker_id,
        speaker_gender: speakerTurnSample.speaker_gender,
        speaker_name: speakerTurnSample.speaker_name,
        is_facilitator: speakerTurnSample.is_facilitator,
        is_participant: speakerTurnSample.is_participant,
        turns: speakerEntry.values,
      };
    });

    // raise the facilitator to the front
    return [
      ...speakerTurns.filter((d) => d.is_facilitator),
      ...speakerTurns.filter((d) => !d.is_facilitator),
    ];
  }
);
