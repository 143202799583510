import { useSelector } from 'react-redux';

import authSelectors from 'src/redux/auth/auth-selectors';
import { FeatureFlags } from 'src/types/core';
import { User } from '../types/auth';
import { createProvider } from '../util/provider';

/**
 * NON-REACT API
 * used for access in redux slices
 *
 * The preferred way to access user flags is via the useFlagContext hook
 */
export let userFlags: User['flags'];

export const [FlagProvider, useFlagContext] = createProvider(() => {
  const user = useSelector(authSelectors.getUser);
  userFlags = user?.flags;
  return {
    flags: userFlags,
  };
});

export function useFlag(flag: keyof typeof FeatureFlags) {
  const { flags } = useFlagContext();
  return flags ? flags[FeatureFlags[flag]] : false;
}
