import * as React from 'react';
import { Button as BootstrapButton, ButtonProps } from 'reactstrap';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import LoadingSpinner from 'src/components/core/LoadingSpinner/LoadingSpinner';

export type ButtonIconProp = IconProp | 'loading' | 'loading-light' | undefined;

interface IconProps {
  icon: ButtonIconProp;
  className?: string;
}

const Icon = ({ icon, className, ...rest }: IconProps) => {
  if (icon === undefined) {
    return <></>;
  }

  if (icon === 'loading' || icon === 'loading-light') {
    const theme = icon === 'loading-light' ? 'light' : undefined;
    return <LoadingSpinner active thick theme={theme} {...rest} />;
  }

  return (
    <FontAwesomeIcon icon={icon} className={cx(className, 'Icon')} {...rest} />
  );
};

export interface Props extends ButtonProps {
  className?: string;
  content?: string;
  icon?: IconProp | 'loading' | 'loading-light';
  color?: string;
  shape?: 'circle' | 'rounded' | undefined;
  compact?: boolean | undefined;
  size?: 'sm' | 'md' | 'lg';
  children?: React.ReactNode;
  active?: boolean | undefined;
  grayOutline?: boolean | undefined;
  whiteBg?: boolean | undefined;
  outline?: boolean | undefined;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void | undefined | boolean;
  onBlur?: (e: React.FocusEvent<HTMLElement>) => void | undefined;
  iconAfter?: boolean;
}

class Button extends React.Component<Props> {
  static defaultProps = {
    color: 'default',
  };

  render() {
    const {
      children,
      className,
      icon,
      active,
      shape,
      grayOutline,
      whiteBg,
      outline,
      compact,
      color,
      iconAfter,
      ...other
    } = this.props;
    const content = this.props.content || children;
    const iconOnly = icon && React.Children.count(children) === 0 && !content;

    let iconName = icon;
    /** use the lightening loading spinner when the button is colored */
    if (icon === 'loading') {
      if (color !== 'default' && !outline && !grayOutline) {
        iconName = 'loading-light';
      }
    }

    return (
      <BootstrapButton
        color={color}
        className={cx('Button', className, {
          active,
          'btn--icon-only': iconOnly,
          'btn--circle': shape === 'circle',
          'btn--gray-outline': grayOutline,
          'btn--compact': compact,
          'btn--rounded': shape === 'rounded',
          'btn--white-bg': whiteBg,
        })}
        outline={outline || grayOutline}
        {...other}
      >
        {iconOnly && iconName ? ( // need to wrap in a span to keep proper height since icons use 0.85em
          <span>
            <Icon icon={iconName} />
          </span>
        ) : (
          <React.Fragment>
            {iconName && !iconAfter && (
              <Icon icon={iconName} className="me-2" />
            )}
            {content}
            {iconName && iconAfter && <Icon icon={iconName} className="ms-2" />}
          </React.Fragment>
        )}
      </BootstrapButton>
    );
  }
}

export default Button;
