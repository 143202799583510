import { StoreState } from 'src/redux/store';
import { Catalog } from 'src/types/insights';

export const selectors = {
  getCatalogs: (state: StoreState): Catalog[] => {
    return Object.values(state.insights.catalogs);
  },
  isLoading: (state: StoreState): boolean => {
    return state.insights.isLoading;
  },
};
