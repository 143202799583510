import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { sanitize } from 'dompurify';

import { useConversationPrivacyContent } from 'src/constants';
import { ConversationPrivacyLevel, Highlight } from 'src/types/conversation';

import styles from './ConversationPrivacyMenu.module.scss';

interface MenuOptionProps {
  collectionTitle: string;
  activePrivacyLevel: ConversationPrivacyLevel | undefined;
  hasHighlights: boolean;
  privacyLevel: ConversationPrivacyLevel;
  onChange: (privacy: ConversationPrivacyLevel) => void;
  isCollectionMenu: boolean;
}

const MenuOption = ({
  collectionTitle,
  activePrivacyLevel,
  hasHighlights,
  privacyLevel,
  isCollectionMenu,
  onChange,
}: MenuOptionProps) => {
  const {
    icon,
    title,
    helperText,
    collectionHelperText,
    highlightWarningText,
  } = useConversationPrivacyContent()[privacyLevel];
  const fullTitle = isCollectionMenu
    ? title('Collection')
    : title('Conversation');
  const isActive = privacyLevel === activePrivacyLevel;
  let subtitle = isCollectionMenu ? collectionHelperText : helperText;

  if (privacyLevel === 'protected') {
    subtitle = subtitle.replace(
      '$COLLECTION',
      `<span class="fwmedium">${collectionTitle}</span>`
    );
  }

  // helper text indicates when you change the privacy of a conversation, highlight privacy will also change
  // for protected conversations, this applies to community and public menu options
  // for community and public conversations, this applies to the protected menu option
  const shouldAppendHighlightWarning =
    hasHighlights &&
    !isCollectionMenu &&
    ((activePrivacyLevel === 'protected' &&
      (privacyLevel === 'community' || privacyLevel === 'public')) ||
      ((activePrivacyLevel === 'public' ||
        activePrivacyLevel === 'community') &&
        privacyLevel === 'protected'));

  if (shouldAppendHighlightWarning) {
    subtitle = subtitle + ' ' + highlightWarningText;
  }

  return (
    <li
      data-testid={`${fullTitle}-option`}
      onClick={() => onChange(privacyLevel)}
      className={cx('p-3 d-flex', styles.menuOption, {
        [styles.active]: isActive,
      })}
    >
      <FontAwesomeIcon
        icon={icon}
        className={cx(`text-${privacyLevel}`, styles.privacyIcon)}
      />
      <div className="ms-2 w-100">
        <div className="fwmedium w-100 d-flex justify-content-between">
          {fullTitle}
          <FontAwesomeIcon
            icon={['fas', 'check']}
            size="sm"
            className={styles.activeIcon}
          />
        </div>
        <span
          data-testid={
            shouldAppendHighlightWarning
              ? `highlight-warning-${privacyLevel}`
              : 'subtitle'
          }
          className="text-gray-800"
          dangerouslySetInnerHTML={{ __html: sanitize(subtitle) }}
        />
      </div>
    </li>
  );
};

export interface Props {
  collectionTitle: string;
  activePrivacyLevel?: ConversationPrivacyLevel;
  highlights?: Highlight[];
  isCollectionMenu?: boolean; // renders collection specific language rather than conversation specific
  onChange: (privacy: ConversationPrivacyLevel) => void;
}

const ConversationPrivacyMenu = ({
  collectionTitle,
  isCollectionMenu,
  activePrivacyLevel,
  highlights,
  onChange,
}: Props) => {
  const privacyContent = useConversationPrivacyContent();
  const privacyLevels = Object.keys(privacyContent) as Array<
    keyof typeof privacyContent
  >;

  return (
    <div>
      <ul className="list-unstyled mb-0">
        {privacyLevels.map((privacyLevel) => (
          <MenuOption
            collectionTitle={collectionTitle}
            activePrivacyLevel={activePrivacyLevel}
            hasHighlights={!!(highlights != null && highlights.length !== 0)}
            privacyLevel={privacyLevel}
            onChange={onChange}
            key={privacyLevel}
            isCollectionMenu={!!isCollectionMenu}
          />
        ))}
      </ul>
    </div>
  );
};
export default ConversationPrivacyMenu;
