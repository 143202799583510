import Color from 'src/assets/_util.scss';
import { EntryStatus } from 'src/types/insights';

export type StatusItem = {
  label: string;
  color: string;
  value: EntryStatus;
};

const StatusItemsByStatus: Record<EntryStatus, StatusItem> = {
  [EntryStatus.InProgress]: {
    label: 'insights.highlight_status.in-progress',
    color: Color.blue,
    value: EntryStatus.InProgress,
  },
  [EntryStatus.NeedsReview]: {
    label: 'insights.highlight_status.needs-review',
    color: Color.red,
    value: EntryStatus.NeedsReview,
  },
  [EntryStatus.Reviewed]: {
    label: 'insights.highlight_status.reviewed',
    color: Color.tealDark,
    value: EntryStatus.Reviewed,
  },
  [EntryStatus.ToDo]: {
    label: 'insights.highlight_status.to-do',
    color: Color.gray650,
    value: EntryStatus.ToDo,
  },
};

export const StatusItems = Object.assign(
  Object.values(StatusItemsByStatus),
  StatusItemsByStatus
);
