import * as React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Conversation } from 'src/types/conversation';
import dateFormatter from 'src/util/date';

interface Props {
  conversation: Conversation;
  linkToConversation: boolean;
  audioStartOffset?: number;
  tabIndex?: number;
}

/* Highlight card footer showing title, host, and date */
const HighlightFooter = ({
  conversation,
  linkToConversation,
  audioStartOffset,
  tabIndex,
}: Props) => {
  const queryParams = audioStartOffset != null ? `?t=${audioStartOffset}` : '';
  const conversationPath = `/conversation/${conversation.id}/${queryParams}`;

  return (
    <div className="text-muted">
      {linkToConversation ? (
        <Trans
          i18nKey="highlight.card_footer_with_link"
          values={{
            title: conversation.title,
            name: conversation.host.name,
            date: dateFormatter.dateFormat(conversation.time),
          }}
          components={{
            1: (
              <Link
                to={conversationPath}
                data-testid="conversation-link"
                tabIndex={tabIndex}
              />
            ),
            2: <span className="text-gray-700" />,
          }}
        />
      ) : (
        <Trans
          i18nKey="highlight.card_footer"
          values={{
            title: conversation.title,
            name: conversation.host.name,
            date: dateFormatter.dateFormat(conversation.time),
          }}
          components={{
            1: <span className="text-gray-700" />,
          }}
        />
      )}
    </div>
  );
};

export default HighlightFooter;
