import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { call, put, SagaReturnType, takeLatest } from 'redux-saga/effects';

import * as api from 'src/api/api';
import { RegisteredConversation } from 'src/types/registration';

interface RegistrationState {
  upcomingConversations: {
    conversations: RegisteredConversation[];
    isLoading: boolean;
    error: Error | undefined;
  };
  editRegistration: {
    isSaving: boolean;
    error: Error | undefined;
    id: RegisteredConversation['id'] | undefined;
    isSaved: boolean;
  };
}

// initial state for reducer
const initialState: RegistrationState = {
  upcomingConversations: {
    conversations: [],
    isLoading: false,
    error: undefined,
  },
  editRegistration: {
    isSaving: false,
    error: undefined,
    id: undefined,
    isSaved: false,
  },
};

const slice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    loadUpcomingConversations(state) {
      state.upcomingConversations.isLoading = true;
      state.upcomingConversations.error = undefined;
    },

    loadUpcomingConversationsSuccess(
      state,
      action: PayloadAction<RegisteredConversation[]>
    ) {
      state.upcomingConversations.isLoading = false;
      state.upcomingConversations.error = undefined;
      state.upcomingConversations.conversations = action.payload;
    },

    loadUpcomingConversationsError(state, action: PayloadAction<Error>) {
      state.upcomingConversations.isLoading = false;
      state.upcomingConversations.error = action.payload;
    },

    editRegistration: {
      reducer(
        state,
        action: PayloadAction<{
          registeredEvent: RegisteredConversation;
          changes: Partial<RegisteredConversation>;
        }>
      ) {
        state.editRegistration.isSaving = true;
        state.editRegistration.error = undefined;
        state.editRegistration.id = action.payload.registeredEvent.id;
        state.editRegistration.isSaved = false;
      },

      prepare: (payload: {
        registeredEvent: RegisteredConversation;
        changes: Partial<RegisteredConversation>;
      }) => {
        const event = {
          category: 'registration',
          action: 'editRegistration',
          label: payload.registeredEvent.shift.job.name,
        };
        return { payload: payload };
      },
    },

    editRegistrationSuccess(
      state,
      action: PayloadAction<Partial<RegisteredConversation>>
    ) {
      state.editRegistration.isSaving = false;
      state.editRegistration.isSaved = true;
      // edit the obj in state
      for (
        let i = 0;
        i < state.upcomingConversations.conversations.length;
        i++
      ) {
        if (
          state.upcomingConversations.conversations[i].id ===
          state.editRegistration.id
        ) {
          Object.assign(
            state.upcomingConversations.conversations[i],
            action.payload
          );
          break;
        }
      }
    },

    editRegistrationFailure(state, action: PayloadAction<Error>) {
      state.editRegistration.isSaving = false;
      state.editRegistration.error = action.payload;
      state.editRegistration.isSaved = false;
    },
  },
});

export const {
  loadUpcomingConversations,
  loadUpcomingConversationsSuccess,
  loadUpcomingConversationsError,
  editRegistration,
  editRegistrationFailure,
  editRegistrationSuccess,
} = slice.actions;
export const actions = slice.actions;

export default slice.reducer;

export function* sagaLoadUpcomingConversations(
  action: ReturnType<typeof loadUpcomingConversations>
) {
  try {
    const conversations: SagaReturnType<
      typeof api.getUserUpcomingConversations
    > = yield call(api.getUserUpcomingConversations);
    // fire the action with successful response
    yield put(loadUpcomingConversationsSuccess(conversations));
  } catch (err) {
    // an error occurred, fire the failure action
    yield put(loadUpcomingConversationsError(err as Error));
  }
}

export function* sagaEditRegistration(
  action: ReturnType<typeof editRegistration>
) {
  try {
    const { registeredEvent, changes } = action.payload;
    const updates: SagaReturnType<typeof api.editUserRegistration> = yield call(
      api.editUserRegistration,
      registeredEvent.id,
      changes
    );
    // fire the action with successful response
    yield put(editRegistrationSuccess(updates));
  } catch (err) {
    // an error occurred, fire the failure action
    yield put(editRegistrationFailure(err as Error));
  }
}

export const sagas = [
  // take latest because button could be toggled quickly via keyboard
  takeLatest(editRegistration.type, sagaEditRegistration),
  takeLatest(loadUpcomingConversations.type, sagaLoadUpcomingConversations),
];
