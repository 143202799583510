import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AutoSizer } from 'react-virtualized';
import { Col, Row } from 'reactstrap';
import cx from 'classnames';

import BgImage from 'src/components/BgImage/BgImage';
import ConversationEdit from 'src/components/ConversationEdit/ConversationEdit';
import ConversationPrivacyDropdown from 'src/components/ConversationPrivacyDropdown/ConversationPrivacyDropdown';
import ConversationPrivacyLabel from 'src/components/ConversationPrivacyLabel/ConversationPrivacyLabel';
import Button from 'src/components/core/Button/Button';
import LoadingOverlay from 'src/components/core/LoadingOverlay/LoadingOverlay';
import Restrict from 'src/components/Restrict/Restrict';
import {
  SPEECH_PIPELINE_STATUS,
  usePipelineStatusProperties,
} from 'src/constants';
import authSelectors from 'src/redux/auth/auth-selectors';
import { StoreState } from 'src/redux/store';
import { User } from 'src/types/auth';
import { Conversation, ConversationPrivacyLevel } from 'src/types/conversation';
import dateFormatter from 'src/util/date';
import {
  getConversationMetadataPermission,
  getConversationPrivacyPermission,
  hasPermission,
} from 'src/util/user';

import styles from './ConversationInfo.module.scss';

const ConversationMap = React.lazy(
  () => import('src/components/ConversationMap/ConversationMap')
);

const HOST_IMG_SIZE = '22.5px';

const Metadata = ({
  children,
  title,
}: {
  children: React.ReactNode;
  title?: string;
}) => (
  <div className="text-gray-800 me-4" title={title}>
    {children}
  </div>
);

const ErrorText = ({
  error,
  genericError,
}: {
  error: Error;
  genericError: string;
}) => (
  <div className="rounded text-danger px-2 py-1 mt-4 mb-md-0 mb-4">
    <b>{genericError}</b>
    <div>{error.message}</div>
  </div>
);

export interface Props {
  conversation: Conversation | undefined;
  isLoading: boolean;
  error: Error | undefined;
  user: User;
  onChangeConversationPrivacy: (privacy: ConversationPrivacyLevel) => void;
}

const ConversationInfo = ({
  conversation,
  isLoading,
  error,
  user,
  onChangeConversationPrivacy,
}: Props) => {
  const { t } = useTranslation();
  const languages = useSelector((state) =>
    authSelectors.getLanguages(state as StoreState)
  );
  const [editing, setEditing] = React.useState<boolean>(false);

  const handleHideEdit = () => {
    setEditing(false);
  };

  const handleToggleEditing = () => {
    setEditing((self) => !self);
  };

  const renderConversationActions = () => {
    if (!conversation) {
      return null;
    }
    return (
      <Restrict
        type="any"
        activeUserId={conversation.host.id}
        permission={getConversationMetadataPermission(
          'update',
          conversation.collection?.id || conversation.forum?.id
        )}
      >
        <div className="ms-1 me-2 mt-1" data-testid="edit-button">
          <Button
            onClick={handleToggleEditing}
            icon={['fas', 'pen']}
            title={t('common.edit')}
            active={editing}
            color="link"
          />
        </div>
      </Restrict>
    );
  };

  // See constants for info on pipeline statuses
  const pipelineStatusMessages = usePipelineStatusProperties();

  if (!conversation) {
    if (isLoading) {
      return (
        <h1 className={cx('h2 fwnormal', styles.loading)}>
          {t('common.loading')}
        </h1>
      );
    } else if (error) {
      return <ErrorText error={error} genericError={t('conversation.error')} />;
    }
    return <div></div>;
  }

  const {
    host,
    title,
    collection,
    forum,
    time,
    location,
    organization,
    speech_pipeline_status: speechPipelineStatus,
    language_codes: languageCodes,
    source_type: sourceType,
    transcription_type: transcriptionType,
  } = conversation;
  const isVirtual = sourceType === 'zoom';
  const isMobile = forum;
  const canChangeConversationPrivacy = isMobile
    ? false
    : hasPermission(
        user,
        getConversationPrivacyPermission(
          'update',
          conversation.collection?.id || conversation.forum?.id
        )
      );

  return (
    <div>
      <div className="d-md-flex justify-content-between">
        <div className="text-center text-md-left w-100">
          {/* --- Left Pane --- */}
          <div className="mb-2 d-md-flex">
            <div className="d-flex flex-column justify-content-center w-100">
              <div className="d-flex justify-content-center justify-content-md-between">
                <div className="d-flex align-items-center justify-content-center justify-content-md-start">
                  {canChangeConversationPrivacy ? (
                    <ConversationPrivacyDropdown
                      conversation={conversation}
                      onChange={onChangeConversationPrivacy}
                    />
                  ) : (
                    <ConversationPrivacyLabel
                      conversation={conversation}
                      className="fwmedium text-gray-800"
                      iconSize="xs"
                    />
                  )}
                  {/* Only write out English and Spanish tags for now */}
                </div>
                {renderConversationActions()}
              </div>
              <div className="w-100 d-flex mb-1 justify-content-center justify-content-md-between">
                <h1 className={cx(styles.conversationName, 'mb-0 d-inline')}>
                  {title}
                </h1>
              </div>
              {!conversation.forum && (
                <h2 className={cx(styles.collectionName, 'mb-1 text-gray-700')}>
                  <Link to={`/conversations?c=${collection.id}`}>
                    {collection.title}
                  </Link>
                </h2>
              )}
              {organization && organization.name && (
                <h3
                  className={cx(
                    'mb-1 text-gray-700 fwnormal',
                    styles.organizationName
                  )}
                >
                  {organization.name}
                </h3>
              )}
              {error && (
                <ErrorText
                  error={error}
                  genericError={t('conversation.error')}
                />
              )}
              <div className="pt-4 d-flex justify-content-center justify-content-md-start">
                {host && (
                  <Metadata title={t('common.hosted_by')}>
                    <span className="d-flex">
                      {host.profile_image && (
                        <BgImage
                          src={host.profile_image}
                          height={HOST_IMG_SIZE}
                          width={HOST_IMG_SIZE}
                          className={`${styles.hostImage} rounded-circle shadow bg-gray-200 d-inline-block me-2`}
                          testId="host-profile-image"
                        />
                      )}
                      {host.name}
                    </span>
                  </Metadata>
                )}
                <Metadata>
                  <span title={t('conversation.metadata_recorded_on')}>
                    {dateFormatter.dateFormat(time)}
                  </span>
                </Metadata>
                {conversation.forum ? (
                  <>
                    <Metadata>{t('conversation.mobile')}</Metadata>
                    <Metadata>{conversation.forum.name}</Metadata>
                  </>
                ) : isVirtual ? (
                  <Metadata>{t('conversation.virtual')}</Metadata>
                ) : (
                  location &&
                  location.name !== 'Unknown' && (
                    <Metadata title={t('conversation.metadata_recorded_in')}>
                      {conversation.location.name}
                    </Metadata>
                  )
                )}
                <Metadata>{conversation.forum_name}</Metadata>
                {/* )} */}
                <Metadata title={t('common.language')}>
                  {languages
                    .filter(({ code }) => languageCodes?.includes(code))
                    .map(({ title, code }) => (
                      <span key={code} className="me-1">
                        {title}
                      </span>
                    ))}
                </Metadata>
              </div>
            </div>
          </div>
          {speechPipelineStatus === SPEECH_PIPELINE_STATUS.FINAL_TRANSCRIPT &&
            transcriptionType === 'machine' && (
              <div
                className="rounded alert-warning px-3 py-1 mt-4 text-center d-inline-block"
                data-testid="pipeline-message"
              >
                {
                  pipelineStatusMessages[
                    SPEECH_PIPELINE_STATUS.FINAL_TRANSCRIPT
                  ].message
                }
              </div>
            )}

          {speechPipelineStatus !== SPEECH_PIPELINE_STATUS.FINAL_TRANSCRIPT && (
            <div
              className="rounded alert-warning px-3 py-1 mt-4 text-center d-inline-block"
              data-testid="pipeline-message"
            >
              {
                pipelineStatusMessages[speechPipelineStatus || 'default']
                  .message
              }
            </div>
          )}
        </div>
        {location.lng_lat.length !== 0 && (
          <div
            className={cx(
              styles.mapContainer,
              'mx-auto mx-md-0',
              'my-4 my-md-0'
            )}
          >
            {/* ---- Right Pane ---- */}
            <div className="w-100">
              <AutoSizer disableHeight>
                {({
                  width,
                  height = Math.min(200, Math.max(150, width * 0.5)),
                }) => (
                  <React.Suspense
                    fallback={
                      <div
                        style={{
                          width: `${width}px`,
                          height: `${height}px`,
                          position: 'relative',
                        }}
                      >
                        <LoadingOverlay active />
                      </div>
                    }
                  >
                    <ConversationMap
                      className="rounded-8 shadow"
                      initialLngLat={location.lng_lat}
                      width={width}
                      height={height}
                      initialZoom={11}
                    />
                  </React.Suspense>
                )}
              </AutoSizer>
            </div>
          </div>
        )}
      </div>
      {editing && conversation && (
        <div className="mt-5">
          <Row>
            <Col md={8} lg={6}>
              <h5>{t('conversation.edit')}</h5>
              <ConversationEdit
                key={conversation.id} /* needed so it resets on conv change */
                conversation={conversation}
                onCancel={handleHideEdit}
                onSaved={handleHideEdit}
              />
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default ConversationInfo;
