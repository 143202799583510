import { Trans } from 'react-i18next';
import { Button, Checkbox, FormControlLabel, Typography } from '@mui/material';

import { HStack, VStack } from 'src/components/core/Stack';
import { useHighlightsPageContext } from 'src/components/Insights/Catalog/HighlightsPageProvider';

export function SuggestCodesDialog({ onConfirm }: { onConfirm: () => void }) {
  const { hideModal, setAiSuggestionIntroEnabled, t } =
    useHighlightsPageContext();

  return (
    <VStack sx={{ maxWidth: '648px' }}>
      <Typography variant="h5" sx={{ m: '0 0 24px' }}>
        {t('insights.aiTaggingModalTitle')}
      </Typography>

      <Typography variant="body1" component="div">
        <Trans
          i18nKey="insights.aiTaggingModalContentIntro"
          components={{ b: <b /> }}
        />
        <ul style={{ margin: '24px 0' }}>
          <li>
            <Trans
              i18nKey="insights.aiTaggingModalBullet1"
              components={{ b: <b /> }}
            />
          </li>
          <li>
            <Trans
              i18nKey="insights.aiTaggingModalBullet2"
              components={{ b: <b /> }}
            />
          </li>
        </ul>
        <Trans
          i18nKey="insights.aiTaggingModalContentOutro"
          components={{ b: <b /> }}
        />
      </Typography>

      <FormControlLabel
        sx={{ mt: '24px' }}
        control={<Checkbox />}
        label={t('common.dontShowAgain')}
        onChange={(e, checked) => setAiSuggestionIntroEnabled(!checked)}
      />

      <HStack
        sx={{
          width: '100%',
          justifyContent: 'flex-end',
          gap: '16px',
          mt: '24px',
        }}
      >
        <Button
          variant="text"
          sx={{ textTransform: 'none' }}
          onClick={hideModal}
        >
          {t('common.cancel')}
        </Button>
        <Button
          variant="contained"
          sx={{ textTransform: 'none' }}
          onClick={onConfirm}
        >
          {t('insights.suggestCodesWithAI')}
        </Button>
      </HStack>
    </VStack>
  );
}
