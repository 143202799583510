import * as React from 'react';

interface Props {
  width?: number;
}

export class ForaLogo extends React.Component<Props> {
  static defaultProps = {
    width: 109,
  };

  render() {
    const { width } = this.props;

    return (
      <svg
        className="ForaLogo"
        width={width}
        height={35}
        viewBox="0 0 109 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M49.1285 9.87868V11.7854H45.523V15.3796H49.1285V29.2799H53.1814V15.3796H57.7719V11.7854H53.1814V9.97583C53.1814 9.35909 53.457 9.08348 54.0737 9.08348H57.7719V5.22717H53.7461C50.8115 5.22717 49.1308 6.92151 49.1308 9.87643L49.1285 9.87868Z"
          fill="#ebe9e8"
        />
        <path
          d="M67.9763 11.2951C62.8119 11.2951 59.0663 15.1808 59.0663 20.5349C59.0663 25.889 62.8142 29.7746 67.9763 29.7746C73.1383 29.7746 76.8862 25.889 76.8862 20.5349C76.8862 15.1808 73.1383 11.2951 67.9763 11.2951ZM67.9763 26.0471C65.0846 26.0471 63.2163 23.8829 63.2163 20.5326C63.2163 17.1823 65.1298 15.0181 67.9763 15.0181C70.8227 15.0181 72.7362 17.2343 72.7362 20.5326C72.7362 23.8309 70.8679 26.0471 67.9763 26.0471Z"
          fill="#ebe9e8"
        />
        <path
          d="M88.6991 11.5233C86.7698 11.5233 85.0597 12.4405 83.8104 14.0964L83.8511 11.7854H79.7892V29.2799H83.842V20.5982C83.842 17.5484 85.62 15.5762 88.3715 15.5762C89.1058 15.5762 89.6547 15.6168 89.8897 15.6326C89.9733 15.6394 90.0252 15.6417 90.0433 15.6417H90.5945V11.747L90.1427 11.6656C89.7361 11.5911 89.3204 11.5256 88.6991 11.5256V11.5233Z"
          fill="#ebe9e8"
        />
        <path
          d="M100.779 11.2951C96.9855 11.2951 93.8476 13.2492 92.9937 16.0912L96.8229 17.1756C97.4351 15.7772 98.9803 14.7922 100.614 14.7922C102.676 14.7922 104.063 16.3239 104.063 18.6011V19.699C102.778 18.8428 101.26 18.4113 99.5338 18.4113C95.5668 18.4113 92.6887 20.7811 92.6887 24.0433C92.6887 27.5246 95.2596 29.7746 99.2401 29.7746C101.348 29.7746 102.986 28.984 104.086 28.0012L104.05 29.2822H108.118V18.47C108.118 14.1122 105.238 11.2974 100.781 11.2974L100.779 11.2951ZM100.189 26.343C98.1738 26.343 96.8703 25.453 96.8703 24.0749C96.8703 22.5952 98.1422 21.6757 100.189 21.6757C102.398 21.6757 104.129 22.6155 104.129 23.8128C104.129 25.0102 102.511 26.343 100.189 26.343Z"
          fill="#ebe9e8"
        />
        <path
          d="M16.7983 9.65502C15.9805 9.65502 15.1944 9.19642 14.8126 8.41025L12.2733 3.16686C11.7424 2.07119 12.201 0.751864 13.2967 0.220973C14.3924 -0.309918 15.7117 0.148682 16.2426 1.24435L18.7818 6.48775C19.3127 7.58342 18.8541 8.90274 17.7585 9.43363C17.449 9.58273 17.1214 9.65502 16.7983 9.65502Z"
          fill="#ebe9e8"
        />
        <path
          d="M11.8644 11.3516C11.4375 11.3516 11.0082 11.2274 10.6287 10.9698L5.80775 7.69864C4.80018 7.01413 4.53813 5.64511 5.22038 4.63754C5.90489 3.62998 7.27391 3.36792 8.28147 4.05017L13.1024 7.32137C14.11 8.00588 14.372 9.3749 13.6898 10.3825C13.2628 11.0105 12.5693 11.3494 11.8644 11.3494V11.3516Z"
          fill="#ebe9e8"
        />
        <path
          d="M8.4464 15.287C8.39218 15.287 8.33796 15.287 8.28374 15.2802L2.4733 14.8578C1.26016 14.7697 0.347479 13.7124 0.435584 12.4993C0.523689 11.2861 1.56966 10.3757 2.7941 10.4615L8.60453 10.884C9.81768 10.9721 10.7304 12.0294 10.6423 13.2425C10.5587 14.4014 9.58951 15.287 8.4464 15.287Z"
          fill="#ebe9e8"
        />
        <path
          d="M2.20673 22.9454C1.38893 22.9454 0.602763 22.4868 0.220973 21.7006C-0.309918 20.6049 0.148682 19.2856 1.24435 18.7547L6.48775 16.2155C7.58342 15.6846 8.90274 16.1432 9.43363 17.2389C9.96452 18.3345 9.50592 19.6538 8.41025 20.1847L3.16686 22.724C2.85736 22.8731 2.52979 22.9454 2.20673 22.9454Z"
          fill="#ebe9e8"
        />
        <path
          d="M5.87102 30.1587C5.44404 30.1587 5.01481 30.0344 4.63528 29.7769C3.62772 29.0924 3.36566 27.7234 4.04791 26.7158L7.31911 21.8949C8.00136 20.8873 9.37264 20.6252 10.3802 21.3075C11.3878 21.992 11.6498 23.361 10.9676 24.3686L7.69638 29.1895C7.26941 29.8176 6.57586 30.1564 5.87102 30.1564V30.1587Z"
          fill="#ebe9e8"
        />
        <path
          d="M12.6596 34.5708C12.6054 34.5708 12.5512 34.5708 12.497 34.564C11.2838 34.4759 10.3712 33.4209 10.4593 32.2055L10.8817 26.3951C10.9698 25.1819 12.0158 24.2647 13.2402 24.3573C14.4534 24.4454 15.3661 25.5004 15.2779 26.7158L14.8555 32.5263C14.7719 33.6852 13.8027 34.5708 12.6596 34.5708Z"
          fill="#ebe9e8"
        />
        <path
          d="M20.7405 35C19.9227 35 19.1365 34.5414 18.7547 33.7552L16.2155 28.5118C15.6846 27.4162 16.1432 26.0968 17.2389 25.5659C18.3345 25.0351 19.6539 25.4937 20.1848 26.5893L22.724 31.8327C23.2549 32.9284 22.7963 34.2477 21.7006 34.7786C21.3911 34.9277 21.0636 35 20.7405 35Z"
          fill="#ebe9e8"
        />
        <path
          d="M27.9493 31.3312C27.5223 31.3312 27.0931 31.207 26.7136 30.9494L21.8926 27.6783C20.8851 26.9937 20.623 25.6247 21.3053 24.6172C21.9875 23.6096 23.3588 23.3475 24.3664 24.0298L29.1873 27.301C30.1949 27.9855 30.4569 29.3545 29.7747 30.3621C29.3477 30.9901 28.6542 31.329 27.9493 31.329V31.3312Z"
          fill="#ebe9e8"
        />
        <path
          d="M32.3659 24.5471C32.3117 24.5471 32.2574 24.5471 32.2032 24.5403L26.3928 24.1179C25.1796 24.0298 24.267 22.9725 24.3551 21.7594C24.4432 20.5462 25.4959 19.6335 26.7136 19.7216L32.524 20.1441C33.7372 20.2322 34.6498 21.2895 34.5617 22.5026C34.4781 23.6615 33.509 24.5471 32.3659 24.5471Z"
          fill="#ebe9e8"
        />
        <path
          d="M27.5517 19.0032C26.7339 19.0032 25.9477 18.5446 25.5659 17.7585C25.035 16.6628 25.4936 15.3435 26.5893 14.8148L31.8327 12.2756C32.9284 11.7447 34.2477 12.2033 34.7763 13.299C35.3072 14.3946 34.8486 15.714 33.753 16.2426L28.5096 18.7818C28.2001 18.9309 27.8725 19.0032 27.5494 19.0032H27.5517Z"
          fill="#ebe9e8"
        />
        <path
          d="M25.8506 14.0716C25.4236 14.0716 24.9944 13.9473 24.6149 13.6898C23.6073 13.0053 23.3452 11.6363 24.0275 10.6287L27.2987 5.80777C27.9809 4.8002 29.3522 4.53815 30.3598 5.2204C31.3673 5.90491 31.6294 7.27393 30.9472 8.2815L27.676 13.1024C27.249 13.7305 26.5554 14.0693 25.8506 14.0693V14.0716Z"
          fill="#ebe9e8"
        />
        <path
          d="M21.9197 10.649C21.8655 10.649 21.8113 10.649 21.7571 10.6423C20.5439 10.5542 19.6313 9.49916 19.7194 8.28376L20.1418 2.47332C20.2299 1.26018 21.2827 0.345237 22.5003 0.435601C23.7135 0.523706 24.6262 1.57871 24.538 2.79411L24.1156 8.60455C24.032 9.76348 23.0628 10.649 21.9197 10.649Z"
          fill="#ebe9e8"
        />
      </svg>
    );
  }
}

export default ForaLogo;
