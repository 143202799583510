import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import Button from 'src/components/core/Button/Button';

interface Props {
  onClear: () => void;
  filtersActive: boolean;
  title: React.ReactNode;
  noneExistMsg?: React.ReactNode;
  className?: string;
}

const FilterEmptyState = ({
  onClear,
  filtersActive,
  title,
  noneExistMsg,
  className,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className={cx('text-center', className)} data-testid="empty-message">
      <h2 className="h5 mb-2">{title}</h2>
      {filtersActive ? (
        <>
          <p className="mb-3">{t('conversations.clear_message')}</p>
          <Button
            onClick={onClear}
            color="primary"
            data-testid="clear-all-filters"
          >
            {t('conversations.clear_button')}
          </Button>
        </>
      ) : (
        <p data-testid="none-exist-message">{noneExistMsg}</p>
      )}
    </div>
  );
};

export default FilterEmptyState;
