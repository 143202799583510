import { range, scaleOrdinal } from 'd3';
import maxBy from 'lodash.maxby';

import { MIN_TOPIC_PROB } from 'src/constants';
import { Highlight, Topic, Topics } from 'src/types/conversation';

// the number of colors in the topics-colors list in _util.scss
const NUM_COLORS = 33;

export function getHighestProbabilityTopic(
  highlight: Highlight,
  topics: Topics
) {
  const analysisResults = highlight.analysis_results;
  if (!analysisResults || !analysisResults.topic_probs) {
    return null;
  }
  const topicProbs = analysisResults.topic_probs;

  // get id for topic with the highest probability
  const maxKey = maxBy(Object.keys(topicProbs), (o) => topicProbs[o]) as string;

  if (topicProbs[maxKey] < MIN_TOPIC_PROB) {
    return null;
  }
  // TODO: because highlight analysis results are still keyed by i.e. lvn-education
  // we can't just use the dictionary. instead we have to search topics for
  // the matching name
  return Object.keys(topics)
    .map((topicId) => topics[+topicId])
    .find((topic) => topic.name === maxKey);
}

export function getColorScale(user_topics: Topic[]) {
  // map from topic display names to the index of the color in the scss topics-colors list
  // this can be used to get class names generated from the topics-colors list
  // for consistency across the application, we need the full list of topics to construct this scale and topic display name should be used to fetch the color from the generated function
  const domain = user_topics.map((t) => t.display_name).sort();
  const colorScale = scaleOrdinal().domain(domain).range(range(NUM_COLORS));

  return colorScale;
}
