import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Label } from 'reactstrap';
import cx from 'classnames';
import { Field, Formik } from 'formik';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import * as Yup from 'yup';

import { useUserTransaction } from 'src/components/AdminPage/Common/Hooks/useUserTransaction';
import LoadingSpinner from 'src/components/core/LoadingSpinner/LoadingSpinner';
import Toggle from 'src/components/core/Toggle/Toggle';
import adminSelectors from 'src/redux/admin/admin-selectors';
import organizationSelectors from 'src/redux/organizations/organizations-selectors';
import { StoreState } from 'src/redux/store';
import { UpdateUserTransaction } from 'src/types/admin';
import {
  CommunityRole,
  OrganizationMetadata,
  OrganizationRole,
} from 'src/types/organization';

import styles from './AddMemberForm.module.scss';

interface AddMemberFormProps {
  first_name: string;
  last_name: string;
  email: string;
  is_admin: boolean;
  is_community_member: boolean;
}

interface StateProps {
  organizationMetaData: OrganizationMetadata[] | undefined;
  organizationId: number | undefined;
}

const mapStateToProps = (state: StoreState): StateProps => ({
  organizationMetaData: organizationSelectors.getOrganizations(state),
  organizationId: adminSelectors.getOrganizationId(state),
});

const AddMemberForm = ({
  organizationMetaData,
  organizationId,
}: StateProps) => {
  const { t } = useTranslation();

  const [, setQuery] = useQueryParams({
    page: StringParam,
    userId: NumberParam,
  });

  const {
    startTransaction,
    transactionData,
    transactionLoading,
    transactionError,
  } = useUserTransaction();

  const users = useSelector(adminSelectors.getUsers);

  // only add them to communities I am an admin in so the api call doesn't fail
  const allCommunities = useSelector((state: StoreState) => {
    return adminSelectors
      .getCommunities(state)
      .filter((c) => adminSelectors.iAmCommunityAdmin(state, c.id));
  });

  const orgHasCommunities = allCommunities.length;

  useEffect(() => {
    console.log(transactionData);
    if (transactionData) {
      const userId = (transactionData as UpdateUserTransaction['data']).id;
      // Navigate to to newly created user (id is only put into transaction data on successful user creation)
      userId && setQuery({ userId, page: 'view' });
    }
  }, [transactionData, setQuery]);

  const initialValues: AddMemberFormProps = {
    first_name: '',
    last_name: '',
    email: '',
    is_admin: false,
    is_community_member: orgHasCommunities ? true : false,
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().trim(),
    last_name: Yup.string().trim(),
    email: Yup.string()
      .trim()
      .matches(/@/, t('register.email_validation_1'))
      .email(t('register.email_validation_2'))
      .required(t('register.email_validation_3')),
  });

  const handleSubmitForm = ({
    is_admin,
    is_community_member,
    ...values
  }: AddMemberFormProps) => {
    if (users.find((u) => u.email === values.email)) {
      toast.error(
        t('admin.add_org_member_already_exists_error_toast', {
          email: values.email,
        }),
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: false,
        }
      );

      return;
    }

    startTransaction({
      type: 'create',
      data: {
        ...values,
        communities: is_community_member
          ? allCommunities.map((c) => ({
              community: c,
              role: CommunityRole.member,
            }))
          : [],
        collections: [],
        catalogs: [],
        org_role: is_admin ? OrganizationRole.admin : OrganizationRole.member,
      },
    });
  };

  return (
    <div
      className="d-flex p-4 bg-white shadow flex-column rounded"
      data-testid="add-member-form"
    >
      <span className={cx(styles.header, 'me-8')}>
        {t('admin.tab_team_add_member')}
      </span>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmitForm}
        validationSchema={validationSchema}
      >
        {(props) => (
          <form
            onSubmit={props.handleSubmit}
            className={cx('w-100 pb-4 justify-content-center')}
          >
            <div className={cx(styles.w600, 'm-auto')}>
              <div className="d-flex flex-row flex-wrap">
                <div className="flex-grow-1 mb-4">
                  <Label for="first_name" className={styles.label}>
                    {t('settings.first_name')}
                  </Label>
                  <Field
                    name="first_name"
                    id="first_name"
                    type="text"
                    component="input"
                    onChange={props.handleChange}
                    value={props.values.first_name}
                    aria-describedby="name-helper error-msg-name"
                    aria-invalid={Boolean(
                      props.errors && props.errors.first_name
                    )}
                    aria-required
                    data-testid="first-name-field"
                    className={cx('form-control', styles.h40)}
                  />
                </div>
                <div className={styles.spacer} />
                <div className="flex-grow-1 mb-4">
                  <Label for="last_name" className={styles.label}>
                    {t('settings.last_name')}
                  </Label>
                  <Field
                    name="last_name"
                    id="last_name"
                    type="text"
                    component="input"
                    onChange={props.handleChange}
                    value={props.values.last_name}
                    aria-describedby="name-helper error-msg-name"
                    aria-invalid={Boolean(
                      props.errors && props.errors.last_name
                    )}
                    aria-required
                    data-testid="last-name-field"
                    className={cx('form-control', styles.h40)}
                  />
                </div>
              </div>
              <div className="mb-4">
                <Label for="email" className={styles.label}>
                  {t('settings.email')}
                </Label>
                <Field
                  name="email"
                  id="email"
                  type="text"
                  component="input"
                  onChange={props.handleChange}
                  value={props.values.email}
                  aria-describedby="name-helper error-msg-name"
                  aria-invalid={Boolean(props.errors && props.errors.email)}
                  aria-required
                  data-testid="email-field"
                  className={cx('form-control', styles.h40)}
                />
              </div>
              <div className={cx('d-flex mb-4')}>
                <Toggle
                  id="is_admin"
                  handleChange={props.handleChange}
                  checked={props.values.is_admin}
                  testid="is-admin-toggle"
                  label={t('admin.tab_team_add_member_admin')}
                />
              </div>
              {orgHasCommunities ? (
                <div className={cx('d-flex mb-4')}>
                  <Toggle
                    id="is_community_member"
                    handleChange={props.handleChange}
                    checked={props.values.is_community_member}
                    testid="is-communinity-member-toggle"
                    label={t('admin.tab_team_add_member_community_member')}
                  />
                </div>
              ) : null}
              <Button
                color="primary"
                type="submit"
                disabled={!props.values.email || transactionLoading}
                data-testid="team-member-submit"
                className={cx('w-100', styles.h40)}
              >
                {transactionLoading ? (
                  <LoadingSpinner active size="md" />
                ) : (
                  t('common.add')
                )}
              </Button>

              {transactionError ? (
                <span className={styles.error} data-testid="add-error">
                  <Trans
                    i18nKey="admin.tab_team_add_member_error"
                    /*eslint-disable */
                    components={{
                      1: <a href="mailto:help@lvn.org" />,
                    }}
                    /*eslint-disable */
                  />
                </span>
              ) : null}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default connect(mapStateToProps)(AddMemberForm);
