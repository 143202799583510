import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';

import admin, { sagas as adminSagas } from './admin/admin-slice';
import auth, { sagas as authSagas } from './auth/auth-slice';
import catalog, { sagas as catalogSagas } from './catalog/catalog-slice';
import catalog_filters, {
  sagas as catalogFiltersSagas,
} from './catalog-filters/catalog-filters-slice';
import collection, {
  sagas as collectionSagas,
} from './collection/collection-slice';
import collections, {
  sagas as collectionsSagas,
} from './collections/collections-slice';
import conversation, {
  sagas as conversationSagas,
} from './conversation/conversation-slice';
import conversations, {
  sagas as conversationsSagas,
} from './conversations/conversations-slice';
import entities from './entities/entities-slice';
import highlight, {
  sagas as highlightSagas,
} from './highlight/highlight-slice';
import insights, { sagas as insightsSagas } from './insights/insights-slice';
import organizations, {
  sagas as organizationSagas,
} from './organizations/organizations-slice';
import registration, {
  sagas as registrationSagas,
} from './registration/registration-slice';
import search, { sagas as searchSagas } from './search/search-slice';
import tags, { sagas as tagsSagas } from './tags/tags-slice';
import topics, { sagas as topicsSagas } from './topics/topics-slice';
import transcriptEdit, {
  sagas as transcriptEditSagas,
} from './transcript-edit/transcript-edit-slice';
import ui from './ui/ui-slice';

/**
 * Combine reducers to produce a single reducer for state.
 * Each reducer handles a subtree of the state tree based
 * on its name.
 */
export const rootReducer = combineReducers({
  auth,
  admin,
  collections,
  conversation,
  conversations,
  entities,
  highlight,
  collection,
  organizations,
  search,
  tags,
  topics,
  ui,
  registration,
  transcriptEdit,
  insights,
  catalog,
  catalog_filters,
});
export type StoreState = ReturnType<typeof rootReducer>;

/** Combine sagas into a single root saga */
export function* rootSaga() {
  yield all([
    ...authSagas,
    ...adminSagas,
    ...collectionsSagas,
    ...conversationSagas,
    ...conversationsSagas,
    ...highlightSagas,
    ...collectionSagas,
    ...organizationSagas,
    ...searchSagas,
    ...tagsSagas,
    ...topicsSagas,
    ...registrationSagas,
    ...transcriptEditSagas,
    ...insightsSagas,
    ...catalogSagas,
    ...catalogFiltersSagas,
  ]);
}

/**
 * Helper function to create store with provided reducer and initial state.
 *
 * @return {Object} An object that holds the complete state of the App.
 */
export default function makeStore() {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [sagaMiddleware];

  // in the non-production builds, mix in debugging middlewares
  if (process.env.NODE_ENV === 'development') {
    const logger = createLogger({
      collapsed: true,
    });

    // debugging middleware that logs actions
    middleware.push(logger as any);
  }

  const store = configureStore({
    reducer: rootReducer,
    middleware,
    devTools: process.env.NODE_ENV !== 'production',
  });

  sagaMiddleware.run(rootSaga);

  // store globally for convenience in development
  if (process.env.NODE_ENV !== 'production') {
    (window as any).store = store;
  }

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./store', () => {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const nextRootReducer = require('./store').rootReducer;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
