// Adapted from https://gist.github.com/robbeman/9617a4ab406d7eb98d94823296deed21
// Headway cdn is loaded in index.html

import React from 'react';

import { headway } from 'src/config';

const HeadwayWidget = ({ trigger }: { trigger?: string }) => {
  // Use a function ref to determine if the DOM is ready for init
  const initWidget = (element: HTMLDivElement) => {
    // don't want Headway for tests (we don't load the cdn for unit tests)
    if (element && process.env.NODE_ENV !== 'test') {
      // @ts-ignore not sure how to type Headway which comes from a cdn
      Headway.init({
        selector: `#${headway.elementId}`,
        account: headway.account,
        trigger,
      });
    }
  };

  return <span id={headway.elementId} ref={initWidget} />;
};

export default HeadwayWidget;
