import * as React from 'react';
import { useTranslation, WithTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import { Field, FormikProps } from 'formik';
import * as Yup from 'yup';

import FormButton from 'src/components/FormButton/FormButton';
import FormError from 'src/components/FormError/FormError';
import FormStep, { FormContent } from 'src/components/FormStep/FormStep';

interface Props extends FormikProps<any> {
  onBack: () => void;
  autoFocus: boolean;
}

const Over18Step = ({
  autoFocus,
  handleChange,
  onBack,
  values,
  errors,
}: Props) => {
  const { t } = useTranslation();
  return (
    <FormStep onBack={onBack}>
      <FormContent>
        <h1 id="over18-label">{t('register.18_header')}</h1>
        <div
          role="radiogroup"
          aria-labelledby="over18-label"
          className="mb-4 text-center"
        >
          <Label check for="over18">
            <Field
              component="input"
              type="radio"
              name="over18"
              id="over18Yes"
              value={true}
              checked={values.over18 === 'true'}
              onChange={handleChange}
              aria-describedby="over18-question over18-helper error-msg-over18"
              aria-invalid={Boolean(errors && errors['over18'])}
              data-testid="yes-option"
              autoFocus={autoFocus}
            />{' '}
            {t('common.yes')}
          </Label>
          <Label check>
            <Field
              component="input"
              type="radio"
              name="over18"
              id="over18No"
              value={false}
              checked={values.over18 === 'false'}
              onChange={handleChange}
              data-testid="no-option"
              aria-describedby="over18-question over18-helper error-msg-over18"
              aria-invalid={Boolean(errors && errors['over18'])}
            />{' '}
            {t('common.no')}
          </Label>
        </div>
        <FormError name="over18">
          {errors ? errors['over18']?.toLocaleString() : null}
        </FormError>
        <p id="over18-helper">{t('register.18_subtext')}</p>
      </FormContent>
      <FormButton type="submit" data-testid="next-button">
        {t('common.next')}
      </FormButton>
    </FormStep>
  );
};

Over18Step.validationSchema = ({ t }: WithTranslation) =>
  Yup.object().shape({
    over18: Yup.boolean().required(t('register.18_validation')),
  });

export default Over18Step;
