import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { sanitize } from 'dompurify';

import { CollectionDetail } from 'src/types/collection';

import styles from './CollectionSummary.module.scss';

interface Props {
  collectionDetail: CollectionDetail;
  showDescription?: boolean;
  className?: string;
}

const CollectionSummary = ({
  collectionDetail,
  showDescription,
  className,
}: Props) => {
  const { t } = useTranslation();
  const { num_speakers, num_conversations, id, description } = collectionDetail;
  return (
    <div className={className}>
      <p className="lead mb-2" data-testid={`collection-detail-${id}`}>
        {t('collection.subheader', {
          conversations: num_conversations,
          voices: num_speakers,
        })}
      </p>
      {showDescription && description && (
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: sanitize(description, { ADD_ATTR: ['target'] }),
          }}
        />
      )}
    </div>
  );
};

export default CollectionSummary;
