import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'src/components/core/Button/Button';
import { usePersistedSeekTime } from 'src/contexts/GlobalAudioContext';
import { formatTime } from 'src/util/format';

import styles from './PlaybackControls.module.scss';

interface Props {
  onPlay: (seekTime?: number) => void;
  onPause: () => void;
  duration: number;
  audioUrl?: string | undefined;
  isPlaying: boolean;
  isLoading: boolean;
  error?: MediaError | undefined;
}

const PlaybackControls: React.FunctionComponent<Props> = ({
  isLoading,
  isPlaying,
  audioUrl,
  duration,
  error,
  onPlay,
  onPause,
}) => {
  const { t } = useTranslation();
  const { seekTime = 0, seekTimeWasNull: isActive } = usePersistedSeekTime({
    src: audioUrl,
  });

  const isActivelyPlaying = isActive && isPlaying;

  const handleTogglePlaying = () => {
    if (isActivelyPlaying) {
      onPause();
    } else {
      // pass in seek time here to ensure when resetting from x-poll
      // that it returns to the last played moment.
      onPlay(seekTime);
    }
  };

  return (
    <div className="text-center">
      {isLoading ? (
        <Button
          icon={'loading'}
          className="me-2"
          disabled
          color="fora-purple"
          shape="circle"
        />
      ) : (
        <>
          {error ? (
            <Button
              icon="exclamation"
              content="Error"
              className="me-2"
              onClick={handleTogglePlaying}
              color="danger"
              grayOutline
            />
          ) : (
            <Button
              icon={isActivelyPlaying ? 'pause' : 'play'}
              color="fora-purple"
              className="me-2"
              shape="circle"
              onClick={handleTogglePlaying}
              title={
                isActivelyPlaying
                  ? t('audio_player.pause')
                  : t('audio_player.play')
              }
            />
          )}
        </>
      )}
      <span className={styles.seekTime}>
        {formatTime(seekTime)}{' '}
        <span className="text-muted">/ {formatTime(duration)}</span>
      </span>
    </div>
  );
};

export default PlaybackControls;
