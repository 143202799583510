import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, Typography, useTheme } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import color from 'src/assets/_util.scss';
import { useCatalogPageContext } from './CatalogPageProvider';

export default function TabNavigation({
  activeTab,
  setActiveTab,
}: ReturnType<typeof useTabNavigation>) {
  const { t } = useTranslation();
  const [tabWidth, setTabWidth] = React.useState<number | undefined>();
  const tabLabelRef = React.useRef<HTMLSpanElement>(null);
  const theme = useTheme();
  const { iAmAdminOrOwner } = useCatalogPageContext();
  const [openTooltip, setOpenTooltip] = React.useState(false);

  const handleTabSelect = (event: React.SyntheticEvent, tabIdx: number) => {
    setTabWidth(event.currentTarget.children.item(0)?.clientWidth);
    setActiveTab(tabIdx);
  };

  React.useEffect(() => {
    if (tabLabelRef.current) {
      setTabWidth(tabLabelRef.current.offsetWidth);
    }
  }, []);

  const SensmakersTab = (
    <Tab
      component="button"
      disableRipple
      disabled={!iAmAdminOrOwner}
      data-testid="sensemakers-nav-tab"
      label={
        <Typography variant="body2">{t('insights.sensemakers')}</Typography>
      }
    />
  );

  return (
    <Tabs
      onChange={handleTabSelect}
      value={activeTab}
      indicatorColor="primary"
      textColor="primary"
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
        sx: {
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'transparent',
          '& .MuiTabs-indicatorSpan': {
            width: `${tabWidth}px !important`,
            backgroundColor: theme.palette.primary.main,
          },
        },
      }}
      sx={{
        backgroundColor: '#ffffff',
        borderBottom: `1px solid ${color.gray300}`,
      }}
    >
      <Tab
        component="button"
        disableRipple
        data-testid="overview-nav-tab"
        label={
          <Typography ref={tabLabelRef} variant="body2">
            {t('insights.navigation_overview')}
          </Typography>
        }
        sx={{ px: 3 }}
      />
      <Tab
        component="button"
        disableRipple
        data-testid="conversations-nav-tab"
        label={
          <Typography variant="body2">
            {t('insights.navigation_conversations')}
          </Typography>
        }
      />
      <Tab
        component="button"
        disableRipple
        data-testid="highlights-nav-tab"
        label={
          <Typography variant="body2">
            {t('insights.navigation_highlights')}
          </Typography>
        }
      />

      <Tooltip
        open={openTooltip}
        placement="bottom"
        title={t(`insights.sensemakers_disable_tooltip`)}
        onOpen={() => !iAmAdminOrOwner && setOpenTooltip(true)}
        onClose={() => !iAmAdminOrOwner && setOpenTooltip(false)}
      >
        {!iAmAdminOrOwner ? <span>{SensmakersTab}</span> : SensmakersTab}
      </Tooltip>
    </Tabs>
  );
}

export function useTabNavigation() {
  const [activeTab, setActiveTab] = React.useState(0);
  return { activeTab, setActiveTab };
}
