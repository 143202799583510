import * as React from 'react';
import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useCatalogPageContext } from 'src/components/Insights/Catalog/CatalogPageProvider';
import { EntryStatus } from 'src/types/insights';
import { ProgressBar } from '../Common/ProgressBar';
import ConversationStat from './ConversationStat';

const OverviewPage = () => {
  const { catalog, entries, conversations, participants, isLoading, t } =
    useCatalogPageContext();

  const highlightingProgress = React.useMemo(
    () =>
      entries.filter((entry) => entry.status === EntryStatus.Reviewed).length,
    [entries]
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Container maxWidth={false} sx={{ position: 'relative', py: 3 }}>
        <Box sx={{ flex: 4 }}>
          {!isLoading && catalog && (
            <Grid container lg={6} xs={12} spacing={3} flexDirection={'column'}>
              <Grid item>
                <Typography data-testid="DescriptionText" display="block">
                  {catalog.description}
                </Typography>
              </Grid>
              <Grid item>
                <Divider />
              </Grid>
              <Grid item>
                <ProgressBar
                  leftLabel={t('insights.progress_status')}
                  progress={(highlightingProgress / entries.length) * 100}
                  rightLabel={`${highlightingProgress}/${entries.length}`}
                />
                <Grid container spacing={2} sx={{ mt: 1, display: 'flex' }}>
                  <Grid item sm={3} xs={6}>
                    <ConversationStat
                      stat={conversations.length}
                      title={t('insights.navigation_conversations')}
                    />
                  </Grid>
                  <Grid item sm={3} xs={6}>
                    <ConversationStat
                      stat={catalog.entries_count ? catalog.entries_count : 0}
                      title={t('insights.navigation_highlights')}
                    />
                  </Grid>
                  <Grid item sm={3} xs={6}>
                    <ConversationStat
                      stat={participants.length}
                      title={t('insights.speakers')}
                    />
                  </Grid>
                  <Grid item sm={3} xs={6}>
                    <ConversationStat
                      stat={
                        catalog.total_duration
                          ? Math.round(catalog.total_duration / 60)
                          : '>1'
                      }
                      title={t('insights.minutes')}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Box>
      </Container>
    </Box>
  );
};

OverviewPage.displayName = 'OverviewPage';
export default OverviewPage;
