import React from 'react';
import cx from 'classnames';
import { scaleLinear } from 'd3';

import { formatTime } from 'src/util/format';

import styles from './DurationBar.module.scss';

interface Props {
  seekTime: number | undefined;
  startTime: number;
  endTime: number;
  className?: string | undefined;
  annotation: string | undefined;
  onSeek?: (seekTime: number) => void;
}

const DurationBar = ({
  seekTime,
  annotation,
  startTime,
  endTime,
  className,
  onSeek,
}: Props) => {
  const duration = endTime - startTime;
  const elapsed = seekTime == null ? 0 : seekTime - startTime;
  const durationBar = React.useRef(null);

  const handleSeek = (evt: React.MouseEvent) => {
    if (durationBar.current == null) {
      return;
    }

    const x = evt.clientX;
    const barRect = (durationBar.current as any).getBoundingClientRect();

    const xScale = scaleLinear()
      .domain([barRect.x, barRect.x + barRect.width])
      .range([startTime, endTime])
      .clamp(true);

    const seekTime = xScale(x);
    if (onSeek) {
      onSeek(seekTime);
    }
  };

  return (
    <div
      className={cx(styles.durationBar, className)}
      onClick={handleSeek}
      ref={durationBar}
    >
      <div
        className={styles.progressBar}
        style={{ width: `${(100 * elapsed) / duration}%` }}
      />
      <div className={styles.inner}>
        {annotation && <span className={styles.annotation}>{annotation}</span>}
        <div className={styles.time}>
          <span className={styles.elapsed}>{formatTime(elapsed)}</span> /{' '}
          <span className={styles.duration}>{formatTime(duration)}</span>
        </div>
      </div>
    </div>
  );
};

export default DurationBar;
