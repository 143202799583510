import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect, DispatchProp, useSelector } from 'react-redux';
import { Container, UncontrolledAlert } from 'reactstrap';
import { TabList, TabPanel, TabPanels, Tabs } from '@reach/tabs';
import { StringParam, useQueryParams } from 'use-query-params';

import Layout from 'src/components/Layout/Layout';
import OrganizationDropdown from 'src/components/OrganizationDropdown/OrganizationDropdown';
import adminSelectors from 'src/redux/admin/admin-selectors';
import { setOrganization } from 'src/redux/admin/admin-slice';
import authSelectors from 'src/redux/auth/auth-selectors';
import organizationSelectors from 'src/redux/organizations/organizations-selectors';
import { loadOrganizationsMetadata } from 'src/redux/organizations/organizations-slice';
import { StoreState } from 'src/redux/store';
import { Organization, User } from 'src/types/auth';
import { Community } from 'src/types/forum';
import { OrganizationMetadata } from 'src/types/organization';
import {
  getAdminPageOrgs,
  getCollectionsPermission,
  getCommunityUsersPermission,
  getDraftConversationPermission,
  getOrganizationMembersPermission,
  hasAllPermissions,
  hasAnyPermission,
} from 'src/util/user';
import AdminTab from './AdminTab/AdminTab';
import CommunityDetails from './Communities/CommunityDetails/CommunityDetails';
import AddCommunityMember from './CommunityMembers/AddCommunityMember/AddCommunityMember';
import CommunityMembersList from './CommunityMembers/CommunityMembersList/CommunityMembersList';
import DraftConversations from './DraftConversations/DraftConversations';
import ManageCollections from './ManageCollections/ManageCollections';
import MemberListPage from './MemberListPage/MemberListPage';
import AddOrganizationMember from './OrganizationMembers/AddOrganizationMember/AddOrganizationMember';
import ManageTeam from './OrganizationMembers/ManageTeam';
import OrganizationMembersList from './OrganizationMembers/OrganizationMembersList/OrganizationMembersList';
import ViewMember from './OrganizationMembers/ViewMember/ViewMember';

export type TabName =
  | 'manage_collections'
  | 'draft_conversations'
  | 'manage_team'
  | 'organization_members'
  | 'communities'
  | 'community_members';

interface StateProps {
  user: User;
  organizationsMetadata: OrganizationMetadata[] | undefined;
  activeOrganization: Organization | undefined;
}

type Props = StateProps & DispatchProp;

const mapStateToProps = (state: StoreState): StateProps => ({
  user: authSelectors.getUser(state),
  organizationsMetadata: organizationSelectors.getOrganizations(state),
  activeOrganization: adminSelectors.getActiveOrganization(state),
});

export const AdminPage = ({
  user,
  organizationsMetadata,
  activeOrganization,
  dispatch,
}: Props) => {
  const USER_MANAGEMENT_FLAG = user.flags?.user_management_changes;
  const activeCommunity = useSelector(adminSelectors.getActiveCommunity);

  const { t } = useTranslation();
  const tabs: TabName[] = React.useMemo(
    () =>
      USER_MANAGEMENT_FLAG
        ? [
            'draft_conversations',
            'manage_collections',
            'organization_members',
            'communities',
            'community_members',
          ]
        : ['draft_conversations', 'manage_collections', 'manage_team'],
    [USER_MANAGEMENT_FLAG]
  );

  const orgsWithAdmin = React.useMemo(() => {
    return getAdminPageOrgs(user);
  }, [user]);

  React.useEffect(() => {
    dispatch(loadOrganizationsMetadata());
    if (!activeOrganization && orgsWithAdmin.length) {
      orgsWithAdmin[0] && dispatch(setOrganization(orgsWithAdmin[0]));
    }
  }, [user, dispatch, activeOrganization, orgsWithAdmin]);

  const getTab = (tab: TabName) => {
    const accessChecks = getAdminTabAccessChecks(
      activeOrganization?.id,
      activeCommunity?.id
    );

    switch (tab as string) {
      case 'draft_conversations':
        return (
          <AdminTab
            key={tab}
            tabName="draft_conversations"
            canAccess={accessChecks.draft_conversations(user)}
          />
        );
      case 'manage_collections':
        return (
          <AdminTab
            key={tab}
            tabName="manage_collections"
            canAccess={accessChecks.manage_collections(user)}
          />
        );
      case 'manage_team':
        return (
          <AdminTab
            key={tab}
            tabName="manage_team"
            canAccess={accessChecks.manage_team(user)}
          />
        );
      case 'organization_members':
        return (
          <AdminTab
            key={tab}
            tabName="organization_members"
            tooltipText="organization_members_tooltip"
            canAccess={accessChecks.organization_members(user)}
          />
        );
      case 'communities':
        return (
          <AdminTab
            key={tab}
            tabName="communities"
            canAccess={accessChecks.communities(user)}
          />
        );
      case 'community_members':
        return (
          <AdminTab
            key={tab}
            tabName="community_members"
            canAccess={accessChecks.community_members(user)}
          />
        );
      default:
        return undefined;
    }
  };

  const [query, setQuery] = useQueryParams({
    tab: StringParam,
    page: StringParam,
    userId: StringParam,
  });
  const { tab: activeTab } = query;

  const onTabChange = useCallback(
    (tabIndex: number) => {
      // Remove all queryParams other than tab.
      // Change tab queryParam to current tab.
      setQuery(
        { tab: tabs[tabIndex], page: undefined, userId: undefined },
        'pushIn'
      );
    },
    [setQuery, tabs]
  );

  useEffect(() => {
    const noQueryParam = activeTab === undefined;

    // if we switch orgs, we may end up on a tab we aren't able to
    // see or access so trigger choosing the right tab
    const cantSeeTab = !!(
      tabs.length && tabs.indexOf(activeTab as TabName) === -1
    );

    const cantAccessTab =
      activeTab &&
      !canAccessAdminTab(
        activeTab as TabName,
        user,
        activeOrganization?.id,
        activeCommunity?.id
      );

    if (noQueryParam || cantSeeTab || cantAccessTab) {
      for (let i = 0; i < tabs.length; i++) {
        const tab = tabs[i];
        if (
          canAccessAdminTab(
            tab,
            user,
            activeOrganization?.id,
            activeCommunity?.id
          )
        ) {
          onTabChange(i);
          return;
        }
      }
    }
  }, [tabs, activeTab, activeOrganization, activeCommunity, user, onTabChange]);

  const orgMetadata = organizationsMetadata?.find(
    (org) => org.id === activeOrganization?.id
  );
  const showCappedWarning =
    orgMetadata && orgMetadata.num_conversations >= orgMetadata.upload_cap;
  const capWarningBanner = showCappedWarning ? (
    <div className="my-2">
      <UncontrolledAlert color="danger" data-testid="capped-banner">
        <Trans
          i18nKey="conversation_upload.capped"
          /*eslint-disable */
          components={{ 1: <a href="mailto:help@lvn.org" /> }}
          /*eslint-disable */
        />
      </UncontrolledAlert>
    </div>
  ) : undefined;

  const getTabPanelContent = (tab: TabName) => {
    if (activeTab !== tab) {
      return <></>;
    }
    switch (activeTab as TabName) {
      case 'manage_collections':
        return (
          <ManageCollections
            organization={activeOrganization}
            organizationMetadata={orgMetadata}
          />
        );
      case 'draft_conversations':
        return <DraftConversations />;
      case 'manage_team':
        return <ManageTeam />;
      case 'organization_members':
        return (
          <MemberListPage
            defaultScreen={<OrganizationMembersList />}
            addMemberScreen={<AddOrganizationMember />}
            viewMemberScreen={
              <ViewMember backToListLabel={t('admin.go_to_team')} />
            }
          />
        );
      case 'communities':
        return <CommunityDetails />;
      case 'community_members':
        return (
          <MemberListPage
            defaultScreen={<CommunityMembersList />}
            addMemberScreen={<AddCommunityMember />}
            viewMemberScreen={
              <ViewMember backToListLabel={t('admin.go_to_community')} />
            }
          />
        );
      default:
        return <></>;
    }
  };

  const handleOrganizationChange = (oid: number) => {
    const newOrg = orgsWithAdmin.find((o) => o?.id === oid);
    if (newOrg) {
      dispatch(setOrganization(newOrg));
    }
  };

  return (
    <Layout title={t('main_nav.account_admin')} className="AdminRoute">
      <Container fluid>
        {capWarningBanner}
        <OrganizationDropdown
          organizations={orgsWithAdmin}
          activeOrganization={activeOrganization}
          onChange={handleOrganizationChange}
          headerTag="h1"
          className="mb-3"
          headerClassname="h2"
        />
        <Tabs
          index={activeTab ? tabs.indexOf(activeTab as TabName) : 0}
          onChange={onTabChange}
        >
          <TabList>{tabs.map((tab) => getTab(tab))}</TabList>
          <TabPanels>
            {tabs.map((tab) => (
              <TabPanel key={tab}>{getTabPanelContent(tab)}</TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Container>
    </Layout>
  );
};

export const canAccessAdminPage = (user: User): boolean => {
  return Object.values(getAdminTabAccessChecks()).some((check) => check(user));
};

export const canAccessAdminTab = (
  tab: TabName,
  user: User,
  organizationId?: number,
  communityId?: number
): boolean => {
  return getAdminTabAccessChecks(organizationId, communityId)[tab](user);
};

export const canAccessAdminCommunity = (
  communityId: Community['id'],
  currentUser: User
) => {
  return getAdminTabAccessChecks(undefined, communityId).community_members(
    currentUser
  );
};

export const getAdminTabAccessChecks = (
  organization_id?: number,
  community_id?: number
): { [key in TabName]: (user: User) => boolean } => ({
  draft_conversations: (user: User) => {
    const perms = [getDraftConversationPermission('read')];
    return hasAnyPermission(user, perms);
  },
  manage_collections: (user: User) => {
    const perms = [
      getCollectionsPermission({ operation: 'update' }),
      getCollectionsPermission({ operation: 'create' }),
    ];
    return hasAnyPermission(user, perms);
  },
  manage_team: (user: User) => {
    const perms = [
      getOrganizationMembersPermission('create', organization_id),
      getOrganizationMembersPermission('update', organization_id),
      getOrganizationMembersPermission('delete', organization_id),
    ];
    return hasAnyPermission(user, perms);
  },
  organization_members: (user: User) => {
    const perms = [
      getOrganizationMembersPermission('create', organization_id),
      getOrganizationMembersPermission('update', organization_id),
      getOrganizationMembersPermission('delete', organization_id),
    ];
    return hasAnyPermission(user, perms);
  },
  communities: (user: User) => {
    // NOTE: this is a placeholder permission until we get this tab setup
    const perms = [getDraftConversationPermission('read')];
    return hasAnyPermission(user, perms);
  },
  community_members: (user: User) => {
    const perms = [getCommunityUsersPermission('read', community_id)];
    return hasAllPermissions(user, perms);
  },
});

export default connect(mapStateToProps)(AdminPage);
