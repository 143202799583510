import React from 'react';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import LoadingSpinner from 'src/components/core/LoadingSpinner/LoadingSpinner';
import Toggle from 'src/components/core/Toggle/Toggle';
import adminSelectors from 'src/redux/admin/admin-selectors';
import { userTransaction } from 'src/redux/admin/admin-slice';
import { generateTransaction } from 'src/redux/redux-helpers';
import { StoreState } from 'src/redux/store';
import { UserTransactions } from 'src/types/admin';
import { User } from 'src/types/auth';
import { CRUD } from 'src/types/core';

import styles from './CommunityMemberToggle.module.scss';

interface CommunityMemberToggleProps {
  member: User;
}

const CommunityMemberToggle = ({ member }: CommunityMemberToggleProps) => {
  const { t } = useTranslation();
  const UPLOADTOOLTIP = t('admin.tab_manage_team_community_member_tooltip');
  const dispatch = useDispatch();
  const organizationId = useSelector(adminSelectors.getOrganizationId);
  const transactions = useSelector((state) =>
    adminSelectors.getUserTransactionsById(state as StoreState, member.id)
  );
  const [transaction, setTransaction] = React.useState<
    UserTransactions | undefined
  >();
  const [lockTooltip, setLockTooltip] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (transaction) {
      const matchingTransaction = transactions.filter(
        (item) => item.id === transaction.id
      );
      if (!matchingTransaction.length) {
        setTransaction(undefined);
      } else {
        setTransaction(matchingTransaction[0]);
      }
    }
  }, [transactions, transaction]);

  const handleCommunityMemberChange = () => {
    const transaction = generateTransaction({
      type: 'update_user_details',
      crud: CRUD.put,
      data: {
        id: member.id,
        is_community_member: !member.is_community_member,
        organization_id: organizationId,
      },
    });
    setTransaction(transaction);
    dispatch(userTransaction(transaction));
  };

  return (
    <div style={{ display: 'flex' }}>
      <Toggle
        id={`is_community_member_${member.id}`}
        handleChange={handleCommunityMemberChange}
        checked={member.is_community_member}
        disabled={member.is_community_member}
        testid="is-communinity-member-toggle"
      />
      {transaction && !transaction.error && (
        <span>
          <LoadingSpinner active size="md" />
        </span>
      )}
      {transaction?.error && (
        <span className={styles.error} data-testid="add-error">
          <Trans
            i18nKey="admin.tab_edit_team_member_error"
            /*eslint-disable */
            components={{
              1: <a href="mailto:help@lvn.org" />,
            }}
            /*eslint-disable */
          />
        </span>
      )}
      {member.is_community_member && (
        <>
          <FontAwesomeIcon
            icon={['fas', 'lock']}
            size="lg"
            id={`community-member-lock-${member.id}`}
            data-testid={`community-member-lock-${member.id}`}
            className={styles.icon}
            aria-label={UPLOADTOOLTIP}
          />
          <Tooltip
            placement="bottom"
            hideArrow={true}
            isOpen={lockTooltip}
            innerClassName={cx(styles.tooltip)}
            target={`community-member-lock-${member.id}`}
            toggle={() => setLockTooltip((self) => !self)}
            data-testid={`tooltip-community-member-lock-${member.id}`}
          >
            {UPLOADTOOLTIP}
          </Tooltip>
        </>
      )}
    </div>
  );
};

export default CommunityMemberToggle;
