import {
  AbstractCode,
  Code,
  CodeCount,
  Demographic,
  Participant,
  Visibility,
  VisibilityMap,
} from 'src/types/insights';

export const calculateParentage = <Type extends AbstractCode>(codes: {
  [key: string]: Type;
}): { [key: string]: Type } => {
  return Object.fromEntries(
    Object.entries(codes).map((entry) => {
      const [codeId, code] = entry;
      let currentCode: Type | undefined = code;
      const parentage: Type['id'][] = [];
      while (currentCode !== undefined) {
        parentage.unshift(currentCode.id);
        if (currentCode.parent_id === undefined) {
          break;
        }
        currentCode = codes[`${currentCode.parent_id}`];
      }
      return [codeId, { ...code, parentage: parentage }];
    })
  );
};

/**
 * Squash a tree of codes with children/grandchildren to a depth of 1
 *
 * @param codes codes to squash
 * @returns All codes with parent_id set to the ancestor at depth 1, along with parentage
 */
export const squashCodeParentage = <Type extends AbstractCode>(codes: {
  [key: string]: Type;
}): { [key: string]: Type } => {
  return Object.fromEntries(
    Object.entries(codes).map((entry) => {
      const [codeId, code] = entry;
      let currentCode: Type | undefined = code;
      let parentId: Type['id'] | undefined = code.parent_id;
      let level = 0;

      // Need to climb up the tree, per se, to find parent
      while (currentCode !== undefined) {
        if (currentCode.parent_id === undefined) {
          if (level === 0) {
            // we started the loop on a parent code, do nothing
            break;
          }
          // we've reached the root parent code
          parentId = currentCode.id;
          break;
        }
        currentCode = codes[`${currentCode.parent_id}`];
        level++;
      }
      return [
        codeId,
        {
          ...code,
          // Need to filter out undefined values, since parentId would be undefined for root codes
          parentage: [parentId, code.id].filter((id) => id !== undefined),
          parent_id: parentId,
        },
      ];
    })
  );
};

/*
 * Calculate the visibility of a code.
 * Traverse the parentage from local to remote.
 * If a visibility setting is found, use it and break.
 * Visibility settings inherited from parents are "implicit".
 */
export const calculateVisibility = (
  code: AbstractCode,
  visibilityMap: VisibilityMap
): Visibility => {
  // Loop over parentage backwards, moving from local to remote
  for (let index = code.parentage.length - 1; index >= 0; index--) {
    const id = code.parentage[index];
    const visible: boolean | undefined = visibilityMap[id];
    if (visible === undefined) {
      continue;
    } else if (visible) {
      if (id === code.id) {
        return 'visible';
      } else {
        return 'implicitly-visible';
      }
    } else {
      if (id === code.id) {
        return 'invisible';
      } else {
        return 'implicitly-invisible';
      }
    }
  }
  return undefined;
};

/**
 * This function counts the demographics per participant
 * @param codes - demographic codes
 * @param participants - participants in the conversation
 * @returns codebookCounts
 */
export const countDemographics = (
  codes: { [key: string]: Demographic },
  participants: { [key: string]: Participant }
): CodeCount[] => {
  const counts: CodeCount[] = Object.values(codes).map((c) => ({
    id: c.id,
    name: c.name,
    count: 0,
  }));
  Object.values(participants).forEach((participant) => {
    participant.demographic_ids.map((id) => {
      const countIdx = counts.findIndex((c) => c.id === id);
      const updatedCount = { ...counts[countIdx] };
      updatedCount.count += 1;
      counts.splice(countIdx, 1, updatedCount);
    });
  });
  return counts;
};

/**
 * This function adds the code counts for parent codes
 * @param codes - the codes, post parentage processing
 * @param codebook_counts - the codebook counts returned from the backend entities endpoint
 */
export const countCodes = (
  codes: { [key: string]: Code },
  codebook_counts: CodeCount[]
): Record<CodeCount['id'], CodeCount> => {
  const counts: Record<CodeCount['id'], CodeCount> = {};
  codebook_counts.forEach((c) => {
    counts[c.id] = c;
    const parentage = codes[c.id].parentage;
    parentage?.forEach((id) => {
      if (id !== c.id) {
        if (!counts[id]) {
          // initialize parent code
          counts[id] = { id, name: '', count: 0 };
        }
        counts[id].count += c.count;
      }
    });
  });
  return counts;
};

/**
 * Increments  the codebookCount object matching the codeId
 * @param codeId -id to increment
 * @param counts - codebookCounts
 * @returns codebookCounts
 */
export const incrementCodeCounts = (
  codeId: AbstractCode['id'],
  counts: Record<CodeCount['id'], CodeCount>,
  change = 1
) => {
  if (!counts[codeId]) {
    counts[codeId] = { id: codeId, name: '', count: 0 };
  }

  counts[codeId].count += change;
  return counts;
};

/**
 * Decrements the codebookCount object matching the codeId
 * @param codeId -id to increment
 * @param counts - codebookCounts
 * @returns codebookCounts
 */
export const decrementCodeCounts = (
  codeId: AbstractCode['id'],
  counts: Record<CodeCount['id'], CodeCount>,
  change = 1
) => {
  if (!counts[codeId]) {
    counts[codeId] = { id: codeId, name: '', count: 0 };
  }

  counts[codeId].count -= change;
  return counts;
};

export const sortCodes = <Type extends AbstractCode>(codes: Type[]): Type[] => {
  return codes.sort((a: Type, b: Type) => {
    for (let i = 0; i < Math.min(a.parentage.length, b.parentage.length); i++) {
      const indexDifference = a.parentage[i] - b.parentage[i];
      if (indexDifference !== 0) {
        return indexDifference;
      }
    }
    const depthDifference = a.parentage.length - b.parentage.length;
    if (depthDifference !== 0) {
      return depthDifference;
    }
    return a.name.localeCompare(b.name);
  });
};
