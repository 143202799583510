import React from 'react';
import { useDropzone } from 'react-dropzone';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import Button from 'src/components/core/Button/Button';
import { humanFileSize } from 'src/util/format';
import TrimButton from './TrimButton/TrimButton';

import styles from './DropUpload.module.scss';

interface FileBlockProps {
  file: File;
  mainText: string;
  subheading: string;
  onClose: (file: File) => void;
  icon?: IconName;
}
const FileBlock = ({
  file,
  icon,
  mainText,
  subheading,
  onClose,
}: FileBlockProps) => {
  const handleClose = () => {
    onClose(file);
  };
  return (
    <div className={cx(styles.block, 'mb-2 d-flex align-items-center')}>
      {icon && (
        <FontAwesomeIcon
          icon={['far', icon]}
          className={cx('me-3', styles.icon)}
        />
      )}
      <div className="flex-grow-1">
        <p className={styles.mainText}>{mainText}</p>
        <p>{subheading}</p>
      </div>
      <Button
        icon={['far', 'times']}
        onClick={handleClose}
        color="plain"
      ></Button>
    </div>
  );
};

interface Props {
  onChange: ({
    files,
    error,
  }: {
    files: File[];
    error: string | undefined;
  }) => void;
  onRemoveFile: (files: File[]) => void;
  onTrim: (trimTimes: any) => void;
  defaultText: string;
  whileDraggingText: string;
  subheading?: string;
  icon?: IconName;
  accept?: string /* NOTE: must be mimetype, i.e. image/jpeg https://github.com/react-dropzone/react-dropzone/tree/master/examples/accept */;
  multiple?: boolean;
  disabled?: boolean;
  className?: string;
  maxSize?: number;
}
const DropUpload = ({
  onChange,
  onRemoveFile,
  onTrim,
  defaultText,
  whileDraggingText,
  subheading,
  icon,
  accept,
  disabled,
  className,
  maxSize,
}: Props) => {
  const [uploadedFiles, setUploadedFiles] = React.useState<File[]>([]);

  const handleDrop = React.useCallback(
    (droppedFiles: File[]) => {
      const files = [...uploadedFiles, ...droppedFiles];
      setUploadedFiles(files);
      onChange({ files, error: undefined });
    },
    [setUploadedFiles, uploadedFiles, onChange]
  );

  const handleDropRejected = (droppedFiles: File[]) => {
    if (maxSize != null && droppedFiles[0].size > maxSize) {
      onChange({ files: [], error: 'FILESIZE' });
    } else {
      onChange({ files: [], error: 'UNKNOWN' });
    }
  };

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop: handleDrop,
      onDropRejected: handleDropRejected,
      multiple:
        false /* currently only single upload but should be extensible */,
      disabled,
      accept,
      maxSize,
    });

  const handleRemoveFile = (file: File) => {
    // need to maintain our own state of files
    // https://github.com/react-dropzone/react-dropzone/issues/805
    const newFiles = [...uploadedFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setUploadedFiles(newFiles);
    onRemoveFile(newFiles);
  };

  let mainText = defaultText;
  if (isDragReject) {
    mainText = 'Invalid file type.';
  } else if (isDragActive) {
    mainText = whileDraggingText;
  }

  return (
    <section className={cx(className, styles.container)}>
      {uploadedFiles.length === 0 ? (
        <div
          {...getRootProps({
            className: cx(
              styles.block,
              'mb-2 d-flex align-items-center text-muted'
            ),
          })}
        >
          {icon && (
            <FontAwesomeIcon
              icon={['far', icon]}
              className={cx('me-3', styles.icon)}
            />
          )}
          <div>
            <input {...getInputProps()} />
            <p className={cx(styles.mainText, { 'text-danger': isDragReject })}>
              {mainText}
            </p>
            <p>{subheading}</p>
          </div>
        </div>
      ) : (
        <>
          <FileBlock
            file={uploadedFiles[0]}
            icon={icon}
            mainText={uploadedFiles[0].name}
            subheading={humanFileSize(uploadedFiles[0].size)}
            onClose={handleRemoveFile}
          />
          <TrimButton file={uploadedFiles[0]} onTrim={onTrim} />
        </>
      )}
    </section>
  );
};

export default DropUpload;
