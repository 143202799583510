import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { call, put, SagaReturnType, takeLatest } from 'redux-saga/effects';

import * as api from 'src/api/api';
import { OrganizationMetadata } from 'src/types/organization';

interface OrganizationsState {
  metadata: {
    isLoading: boolean;
    error: Error | undefined;
    organizations: OrganizationMetadata[] | undefined;
  };
}

const initialState: OrganizationsState = {
  metadata: {
    isLoading: false,
    error: undefined,
    organizations: undefined,
  },
};

const slice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    loadOrganizationsMetadata(state, action: PayloadAction) {
      state.metadata.isLoading = true;
      state.metadata.error = undefined;
    },

    loadOrganizationsMetadataSuccess(
      state,
      action: PayloadAction<OrganizationMetadata[]>
    ) {
      state.metadata.isLoading = false;
      state.metadata.error = undefined;
      state.metadata.organizations = action.payload;
    },

    loadOrganizationsMetadataFailure(state, action: PayloadAction<Error>) {
      state.metadata.isLoading = false;
      state.metadata.error = action.payload;
    },
  },
});

export const {
  loadOrganizationsMetadata,
  loadOrganizationsMetadataSuccess,
  loadOrganizationsMetadataFailure,
} = slice.actions;
export const actions = slice.actions;
export default slice.reducer;

/** Sagas */

export function* sagaloadOrganizationsMetadata(
  action: ReturnType<typeof loadOrganizationsMetadata>
) {
  try {
    const organizations: SagaReturnType<typeof api.getOrganizations> =
      yield call(api.getOrganizations);
    yield put(loadOrganizationsMetadataSuccess(organizations));
  } catch (err) {
    yield put(loadOrganizationsMetadataFailure(err as Error));
  }
}

export const sagas = [
  takeLatest(loadOrganizationsMetadata.type, sagaloadOrganizationsMetadata),
];
