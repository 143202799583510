import * as React from 'react';
import { useTranslation } from 'react-i18next';
import xor from 'lodash.xor';
import {
  ArrayParam,
  QueryParamConfig,
  SetQuery,
  useQueryParams,
} from 'use-query-params';

import { FilterOption, HighlightsFilterOptions } from 'src/types/core';
import FilterChecklist from '../FilterChecklist/FilterChecklist';
import SideFilter from '../SideFilter/SideFilter';

export const getActiveTags = (
  tags: string[] | undefined,
  filterOptions: HighlightsFilterOptions | undefined
) => {
  return (
    tags &&
    filterOptions &&
    filterOptions.tags &&
    tags.map((tag) => filterOptions.tags.find((d) => d.name === tag))
  );
};

export const handleTagChange = (
  option: FilterOption,
  tags: string[] | undefined,
  setQuery: SetQuery<{
    tags: QueryParamConfig<string[] | undefined>;
    page: QueryParamConfig<number | undefined>;
  }>
) => {
  const newTags = xor(tags, [option.name]);
  setQuery({
    tags: newTags,
    page: 1,
  });
};

interface Props {
  filterOptions: HighlightsFilterOptions | undefined;
}

const TagFilter = ({ filterOptions }: Props) => {
  const { t } = useTranslation();
  const [query, setQuery] = useQueryParams({
    tags: ArrayParam,
  });
  const { tags } = query;
  return (
    <SideFilter
      title={t('highlights.tags')}
      open={Boolean(tags)}
      disabled={
        !(filterOptions && filterOptions.tags && filterOptions.tags.length)
      }
    >
      {filterOptions && filterOptions.tags && (
        <FilterChecklist
          options={filterOptions.tags}
          onChange={(option) => {
            handleTagChange(option, tags, setQuery);
          }}
          activeFilterOptions={getActiveTags(tags, filterOptions)}
          title={t('highlights.tags')}
          fade
        />
      )}
    </SideFilter>
  );
};

export default TagFilter;
