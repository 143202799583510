import React from 'react';
import { useSelector } from 'react-redux';

import authSelectors from 'src/redux/auth/auth-selectors';
import { StoreState } from 'src/redux/store';

const LanguageOptions = () => {
  const languages = useSelector((state) =>
    authSelectors.getLanguages(state as StoreState)
  );
  return (
    <>
      {languages?.map(({ title, code }) => (
        <option value={code} data-testid={`user-language-${code}`} key={code}>
          {title}
        </option>
      ))}
    </>
  );
};

export default LanguageOptions;
