import React from 'react';
import cx from 'classnames';

import styles from './PanelActions.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string | undefined;
}

export default function PanelActions({ children, className }: Props) {
  return <div className={cx(styles.actions, className)}>{children}</div>;
}
