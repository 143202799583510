import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import { AnnotationPrivacyLevel, Conversation } from 'src/types/conversation';
import { isConversationProtected } from 'src/util/conversation';

import styles from './PrivacyLabel.module.scss';

interface Props {
  conversation: Conversation;
  privacyLevel: AnnotationPrivacyLevel;
  collectionName: string;
  className?: string;
}

// TODO: reuse this for labeling in PrivacyMenu
const PrivacyLabel = ({
  conversation,
  privacyLevel,
  collectionName,
  className,
}: Props) => {
  const { t } = useTranslation();
  const conversationIsProtected = isConversationProtected(
    undefined,
    conversation
  );

  // TODO: update when we allow individuals to be granted separate access to conversations
  let iconName: IconName = conversationIsProtected ? 'user-friends' : 'users';
  let text = conversationIsProtected ? (
    <div>
      <Trans
        i18nKey="highlights.type_dropdown_collection_subtext"
        values={{
          name: collectionName,
        }}
        components={{
          1: <span className="text-gray-800" />,
        }}
      />
    </div>
  ) : (
    t('conversation.create_highlight_protected_subheader_2')
  );

  if (privacyLevel === 'private') {
    iconName = 'lock';
    text = t('conversation.create_highlight_private_subheader');
  } else if (privacyLevel === 'public') {
    iconName = 'globe';
    text = t('highlights.type_dropdown_anyone_subtext');
  }

  return (
    <div
      className={cx(
        className,
        'd-flex align-items-baseline justify-content-between text-gray-700',
        styles.privacyLabel
      )}
    >
      <span>
        <FontAwesomeIcon
          icon={iconName}
          className={cx(
            'me-2',
            { 'text-primary': privacyLevel === 'public' },
            { 'text-gray-900': privacyLevel === 'private' }
          )}
        />
      </span>
      {text}
    </div>
  );
};

export default PrivacyLabel;
