import * as React from 'react';
import { Link } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import cx from 'classnames';
import { max, ScaleBand, scaleBand, ScaleLinear, scaleLinear } from 'd3';

import styles from './HorizontalBarChart.module.scss';

export const TOP_PADDING = 50;

export interface DataPoint {
  label: string;
  value: number;
  id: number;
}
interface Props {
  data: DataPoint[];
  formatLabel?: (value: number) => string;
  title: string;
  subtitle: string;
  width?: number;
  totalBarHeight?: number;
  wrapperRef?: React.Ref<any>;
  onClick?: (id?: number) => void;
  className?: string;
  visibleBarCount?: number; // if provided, will use aria attrs to hide bars that are hidden by other components. intended to be used in expanding/collapse animations.
  smallViewport?: boolean; // show values in boxes for small viewports
  getBarClassName?: (id: number) => string;
  getBarLinkTo?: (id?: number) => string;
}

const HorizontalBarChart = ({
  data,
  formatLabel,
  title,
  wrapperRef,
  onClick,
  width = 800,
  totalBarHeight = 40,
  className,
  visibleBarCount,
  subtitle,
  smallViewport = false,
  getBarClassName,
  getBarLinkTo,
}: Props) => {
  const barPadding = 0.2;

  // left margin set to fit the longest topic keyword at the time of development (representative government)
  const margins = {
    top: TOP_PADDING,
    left: 210,
    right: 20,
    bottom: 10,
  };
  const chartHeight = data.length * totalBarHeight;
  // total svg height
  const svgHeight = chartHeight + margins.top + margins.bottom;
  // store all of our dimensions in this obj
  const dimensions = {
    width,
    height: svgHeight,
    margins,
    // bounded dimensions are where actual chart elements go (no axes or labels, just bars)
    boundedWidth: width - margins.left - margins.right,
    boundedHeight: chartHeight,
  };

  const maxValue = max(data, (d) => d.value) || 0;
  const x = scaleLinear()
    .domain([0, maxValue])
    .range([0, dimensions.boundedWidth]);

  const y = scaleBand()
    .domain(data.map((d) => d.label))
    .rangeRound([0, dimensions.boundedHeight])
    .paddingInner(barPadding);

  const barHeight = y.bandwidth();

  const padding = 9;
  // the x position of the bar
  const barX = 0;
  // the x position of the label (i.e. Education)
  const labelX = -padding;
  // put the title right above the first bar
  let titleX = 0;
  const titleY = -30;
  const subtitleY = -6;

  if (smallViewport) {
    titleX = -margins.left;
  }

  // the y position of text we want to put next to the bars
  const barTextY = barHeight / 2;

  return (
    <div ref={wrapperRef} className={className}>
      <svg className="d-block m-auto mb-3" width={width} height={svgHeight}>
        <g
          transform={`translate(${dimensions.margins.left} ${dimensions.margins.top})`}
        >
          <text x={titleX} y={titleY} className="fwmedium">
            {title}
          </text>
          <text x={titleX} y={subtitleY} className="small">
            {subtitle}
          </text>
          {data.map((d, i) => (
            <Bar
              d={d}
              y={y}
              x={x}
              padding={padding}
              onClick={onClick}
              labelX={labelX}
              barTextY={barTextY}
              barX={barX}
              barHeight={barHeight}
              formatLabel={formatLabel}
              smallViewport={smallViewport}
              ariaHidden={visibleBarCount ? i >= visibleBarCount : false}
              key={`bar-${i}`}
              getClassName={getBarClassName}
              getBarLinkTo={getBarLinkTo}
            />
          ))}
        </g>
      </svg>
    </div>
  );
};

interface BarProps {
  d: DataPoint;
  y: ScaleBand<string>;
  x: ScaleLinear<number, number>;
  padding: number;
  labelX: number;
  barTextY: number;
  barX: number;
  barHeight: number;
  formatLabel?: (value: number) => string;
  ariaHidden: boolean;
  smallViewport: boolean;
  onClick?: (id?: number) => void;
  getClassName?: (id: number) => string;
  getBarLinkTo?: (id?: number) => string;
}

const Bar = ({
  d,
  y,
  x,
  padding,
  labelX,
  barTextY,
  barX,
  barHeight,
  smallViewport,
  formatLabel,
  onClick = () => {
    return;
  },
  ariaHidden,
  getClassName = () => '',
  getBarLinkTo,
}: BarProps) => {
  const className = React.useMemo(() => getClassName(d.id), [getClassName, d]);

  // the whole row is going to be at the same yPos
  const yPos = y(d.label) || 0;

  // bar
  let barWidth = x(d.value);

  // value label (i.e. 33 conversations)
  let countX = padding;

  // short bars will have their labels at the end of the bar
  const shortBarWidth = 50;
  const placeLabelAfterBarMinimum = formatLabel ? 140 : shortBarWidth;
  const showLabelAtEndOfBar = barWidth < placeLabelAfterBarMinimum;
  if (showLabelAtEndOfBar) {
    countX = x(d.value) + padding;
  }

  let labelText = d.label;
  let countText = formatLabel ? formatLabel(d.value) : d.value;
  let countTextAnchor;

  if (smallViewport) {
    countX = 25;
    barWidth = shortBarWidth;
    labelText = `${labelText}`;
    countText = `${d.value}`;
    countTextAnchor = 'middle';
  }

  const animatedProps = useSpring({
    width: barWidth,
  });

  const Rect = (
    <animated.rect
      x={barX}
      height={barHeight}
      rx={2}
      className={cx(styles.bar, 'barchart-bar')}
      style={animatedProps}
    />
  );

  return (
    <g
      key={`${d.label}`}
      transform={`translate(0 ${yPos})`}
      data-testid={`${d.label}-bar`}
      onClick={() => onClick(d.id)}
      aria-hidden={ariaHidden}
      className={cx(className, {
        [styles.shortBar]: showLabelAtEndOfBar && !smallViewport,
      })}
    >
      <text
        x={labelX}
        y={barTextY}
        textAnchor="end"
        className="fwmedium"
        dominantBaseline="central"
      >
        {labelText}
      </text>
      {getBarLinkTo ? (
        <Link aria-label={d.label} to={getBarLinkTo(d.id)}>
          {Rect}
        </Link>
      ) : (
        Rect
      )}
      <text
        x={countX}
        y={barTextY}
        className={cx(styles.barText, 'fwmedium barchart-text')}
        dominantBaseline="central"
        textAnchor={countTextAnchor}
      >
        {countText}
      </text>
    </g>
  );
};

export default HorizontalBarChart;
