import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { put, SagaReturnType, takeLatest } from 'redux-saga/effects';

import * as api from 'src/api/api';
import { ConversationsFilters } from 'src/api/api';
import { callWithUser } from 'src/redux/redux-helpers';
import { Conversation, Paging } from 'src/types/conversation';
import {
  ConversationsFilterOptions,
  NormalizedConversationEntities,
} from 'src/types/core';

interface ConversationsState {
  filtered: {
    isLoading: boolean;
    paging: Paging | undefined;
    error: Error | undefined;
    ids: Conversation['id'][];
    filters: ConversationsFilterOptions | undefined;
  };

  draft: {
    isLoading: boolean;
    error: Error | undefined;
    ids: number[];
  };
}

// initial state for reducer
const initialState: ConversationsState = {
  filtered: {
    isLoading: false,
    error: undefined,
    paging: undefined,
    ids: [],
    filters: undefined,
  },

  draft: {
    isLoading: false,
    error: undefined,
    ids: [],
  },
};

const slice = createSlice({
  name: 'conversations',
  initialState,
  reducers: {
    loadConversations(
      state,
      action: PayloadAction<Partial<ConversationsFilters>>
    ) {
      state.filtered.isLoading = true;
      state.filtered.error = undefined;
    },

    loadConversationsSuccess(
      state,
      action: PayloadAction<NormalizedConversationEntities>
    ) {
      state.filtered.isLoading = false;
      state.filtered.ids = action.payload.order.conversations || [];
      state.filtered.paging = action.payload.paging;
      state.filtered.filters = action.payload
        .filters as ConversationsFilterOptions;
    },

    loadConversationsFailure(state, action: PayloadAction<Error>) {
      state.filtered.isLoading = false;
      state.filtered.error = action.payload;
      state.filtered.filters = undefined;
      state.filtered.ids = [];
    },

    loadDraftConversations(
      state,
      action: PayloadAction<Partial<ConversationsFilters>>
    ) {
      state.draft.isLoading = true;
      state.draft.error = undefined;
      state.draft.ids = [];
    },

    loadDraftConversationsSuccess(
      state,
      action: PayloadAction<NormalizedConversationEntities>
    ) {
      state.draft.isLoading = false;
      state.draft.ids = action.payload.order.conversations || [];
    },

    loadDraftConversationsFailure(state, action: PayloadAction<Error>) {
      state.draft.isLoading = false;
      state.draft.error = action.payload;
    },
  },
});

export const {
  loadConversations,
  loadConversationsSuccess,
  loadConversationsFailure,
  loadDraftConversations,
  loadDraftConversationsFailure,
  loadDraftConversationsSuccess,
} = slice.actions;
export const actions = slice.actions;

export default slice.reducer;

/** Sagas */

export function* sagaLoadConversations(
  action: ReturnType<typeof loadConversations>
) {
  try {
    const filters = action.payload;

    // load conversations from API
    const result: SagaReturnType<typeof api.getConversations> =
      yield callWithUser(api.getConversations, filters);

    // fire the action with successful response
    yield put(loadConversationsSuccess(result));
  } catch (err) {
    // an error occurred, fire the failure action
    yield put(loadConversationsFailure(err as Error));
  }
}

export function* sagaLoadDraftConversations(
  action: ReturnType<typeof loadDraftConversations>
) {
  const filters = action.payload;

  try {
    // load conversation from API
    const conversations: SagaReturnType<typeof api.getConversations> =
      yield callWithUser(api.getConversations, filters);
    // fire the action with successful response
    yield put(loadDraftConversationsSuccess(conversations));
  } catch (err) {
    // an error occurred, fire the failure action
    yield put(loadDraftConversationsFailure(err as Error));
  }
}

export const sagas = [
  takeLatest(loadDraftConversations.type, sagaLoadDraftConversations),
  takeLatest(loadConversations.type, sagaLoadConversations),
];
