import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Container } from 'reactstrap';
import cx from 'classnames';
import { Moment } from 'moment-timezone';

import { getLegalContent } from 'src/api/api';
import Button from 'src/components/core/Button/Button';
import { default as ErrorText } from 'src/components/FormErrorText/FormErrorText';
import Layout from 'src/components/Layout/Layout';
import Panel from 'src/components/Panel/Panel';
import { PRIVACY_URL, TERMS_URL } from 'src/constants';
import authSelectors from 'src/redux/auth/auth-selectors';
import { EditTransaction, editUser } from 'src/redux/auth/auth-slice';
import { StoreState } from 'src/redux/store';
import { User } from 'src/types/auth';
import { ServerError } from 'src/types/core';
import dateFormatter from 'src/util/date';
import { usePrevious } from 'src/util/react';

import styles from './LegalPage.module.scss';

interface BaseProps extends RouteComponentProps {
  showAgreeAction?: boolean;
  minorUser?: User;
  agreeAction?: React.ReactNode;
}

interface StateProps {
  user: User;
  termsEffectiveDate: Moment | undefined;
  legalTransaction?: EditTransaction;
}

type Props = BaseProps & StateProps & DispatchProp;

const mapStateToProps = (state: StoreState): StateProps => ({
  user: authSelectors.getUser(state),
  termsEffectiveDate: authSelectors.getTermsEffectiveDate(state),
  legalTransaction: authSelectors.getEditUserTransaction(state, 'legal'),
});

interface AgreeActionProps {
  onSubmit: () => void;
  err: Partial<Error> | undefined;
}
const AgreeAction = ({ onSubmit, err }: AgreeActionProps) => {
  const { t } = useTranslation();
  return (
    <div data-testid="agree-action">
      <p className="fwbold text-center pt-2">{t('legal.agree_action_text')}</p>
      <div className="w-100 d-flex justify-content-center">
        <Button
          color="primary"
          size="lg"
          onClick={onSubmit}
          data-testid="agree-action-btn"
        >
          {t('legal.agree_action_button')}
        </Button>
        {err && (
          <ErrorText id="acceptError">
            {t('legal.agree_action_error')}
          </ErrorText>
        )}
      </div>
    </div>
  );
};

const AgreeInstructions = ({
  user,
  minorUser,
}: {
  user: User;
  minorUser?: User;
}) => {
  if (user.lvn_terms_agreed == null || minorUser) {
    return (
      <p className="fwbold" data-testid="initial-terms-agreement-instructions">
        <Trans
          i18nKey="legal.agree_instructions"
          /*eslint-disable */
          components={{
            1: <a href={TERMS_URL} />,
            2: <a href={PRIVACY_URL} />,
          }}
          /*eslint-disable */
        />
      </p>
    );
  }
  return (
    <p className="fwbold" data-testid="updated-terms-agreement-instructions">
      <Trans
        i18nKey="legal.agree_update_instructions"
        /*eslint-disable */
        components={{
          1: <a href={TERMS_URL} />,
          2: <a href={PRIVACY_URL} />,
        }}
        /*eslint-disable */
      />
    </p>
  );
};

const GuardianInfoBox = ({ minorUser }: { minorUser: User }) => {
  return (
    <Panel data-testid="guardian-info">
      <Trans
        i18nKey="legal.guardian_box"
        values={{
          name: minorUser.first_name,
          email: minorUser.email,
        }}
        /*eslint-disable */
        components={{
          1: <a href={TERMS_URL} />,
          2: <a href={PRIVACY_URL} />,
        }}
        /*eslint-disable */
      />
    </Panel>
  );
};

function flatten(text: string, child: any): string {
  return typeof child === 'string'
    ? text + child
    : React.Children.toArray(child.props.children).reduce(flatten, text);
}

// render headings with ids
// https://github.com/rexxars/react-markdown/issues/69#issuecomment-289860367
const HeadingElement = ({ children, node }: { children: any; node: any }) => {
  const text = children.reduce(flatten, '');
  const slug = text
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '');

  return React.createElement(node.tagName, { id: slug }, children);
};

export const LegalPage = ({
  user,
  termsEffectiveDate,
  dispatch,
  history,
  location,
  legalTransaction,
  showAgreeAction,
  agreeAction,
  minorUser,
}: Props) => {
  const { t } = useTranslation();
  const [legalContent, setLegalContent] = React.useState<string | undefined>(
    undefined
  );
  const [legalContentError, setLegalContentError] = React.useState<
    Error | undefined
  >(undefined);

  const [agreeError, setAgreeError] = React.useState<ServerError | undefined>(
    undefined
  );

  // load legal content on mount
  React.useEffect(() => {
    getLegalContent()
      .then((res) => {
        setLegalContent(res.content);
      })
      .catch((err) => {
        setLegalContentError(err);
      });
  }, []);

  React.useEffect(() => {
    if (legalTransaction?.error) {
      setAgreeError(legalTransaction?.error);
    }
  }, [legalTransaction, setAgreeError]);

  const prevLvnTermsAgreed = usePrevious(
    user.lvn_terms_agreed,
    user.lvn_terms_agreed
  );

  // redirect page if lvn_terms_agreed has changed and there is a page to redirect to
  const locationState = location.state as any;
  if (
    user.lvn_terms_agreed !== prevLvnTermsAgreed &&
    locationState &&
    locationState.referrer
  ) {
    history.push(locationState.referrer);
  }

  const handleSubmit = () => {
    dispatch(
      editUser({
        userId: user.id,
        changes: { agree_to_terms: true },
        editType: 'legal',
      })
    );
  };

  // render internal links as react-router link components with referrer
  // hash link lib used as react-router links don't scroll page
  const LinkElement = ({ href, children }: any) => {
    // if the URL is empty return just the text
    if (!href) {
      return children;
    }

    // if first value of href is not a hash, use default link behavior
    if (href[0] !== '#') {
      return <a href={href}>{children}</a>;
    }

    const { search } = window.location;
    const hashURL = `${href}${search}`;

    // if first value of href is a hash, use a hash link
    return <HashLink to={hashURL}>{children}</HashLink>;
  };

  return (
    <Layout className="LegalPage" title={t('main_nav.account_legal')}>
      <Container className={styles.container}>
        {legalContentError && !legalContent && (
          <>
            <h4>{t('legal.error_header')}</h4>
            <p data-testid="privacy-and-terms-err">
              <Trans
                i18nKey="legal.error_subheader"
                /*eslint-disable */
                components={{
                  1: <a href="mailto:help@lvn.org" />,
                }}
                /*eslint-disable */
              />
            </p>
          </>
        )}
        {legalContent && (
          <div data-testid="legal-content">
            <h2 className="mb-1">{t('legal.header')}</h2>
            <h3 className="text-muted">
              Effective{' '}
              {dateFormatter.dateFormat(termsEffectiveDate, {
                includeYear: true,
              })}
            </h3>
            {minorUser && <GuardianInfoBox minorUser={minorUser} />}
            {showAgreeAction && (
              <AgreeInstructions user={user} minorUser={minorUser} />
            )}
            <Markdown
              className={styles.termsMarkdown}
              components={{
                link: LinkElement,
                h1: HeadingElement,
                h2: HeadingElement,
                h3: HeadingElement,
                h4: HeadingElement,
                h5: HeadingElement,
                h6: HeadingElement,
              }}
            >
              {legalContent}
            </Markdown>
            <p className="fwbold text-center">
              <Trans
                i18nKey="legal.bottom_text"
                /*eslint-disable */
                components={{
                  1: <a href="mailto:help@lvn.org" />,
                }}
                /*eslint-disable */
              />
            </p>
            <div className={cx(styles.legalButtons, 'w-100 mb-2')}>
              <Button
                href={PRIVACY_URL}
                color="primary"
                size="lg"
                tag="a"
                target="_blank"
                rel="noopener noreferrer"
                outline
              >
                {t('legal.button_privacy')}
              </Button>
              <Button
                href={TERMS_URL}
                size="lg"
                color="primary"
                tag="a"
                target="_blank"
                rel="noopener noreferrer"
                outline
              >
                {t('legal.button_terms')}
              </Button>
            </div>
            {/* if we are given a custom agree action, use that, otherwise use our default one */}
            {showAgreeAction &&
              (agreeAction ? (
                agreeAction
              ) : (
                <AgreeAction onSubmit={handleSubmit} err={agreeError} />
              ))}
          </div>
        )}
      </Container>
    </Layout>
  );
};

export default connect(mapStateToProps)(LegalPage);
