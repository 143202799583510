import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import Cleave from 'cleave.js/react';
import { DateParam, NumberParam, useQueryParams } from 'use-query-params';

import SideFilter from 'src/components/SideFilter/SideFilter';
import dateFormatter from 'src/util/date';
import Button from '../core/Button/Button';

export const DATE_PLACEHOLDER = 'MM/DD/YY';

interface Props {
  draftStartDate: string;
  setDraftStartDate: (draftStartDate: string) => void;

  draftEndDate: string;
  setDraftEndDate: (draftEndDate: string) => void;
}

const stringToDate = (value: string) => (value ? new Date(value) : undefined);

// mock the filter option payload structure for dates
export const getActiveStartDates = (startDate: Date) => [
  {
    name: `Start: ${dateFormatter.dateToISOString(startDate)}`,
  },
];
export const getActiveEndDates = (endDate: Date) => [
  {
    name: `End: ${dateFormatter.dateToISOString(endDate)}`,
  },
];

const DateFilter = ({
  draftStartDate,
  setDraftStartDate,
  draftEndDate,
  setDraftEndDate,
}: Props) => {
  const { t } = useTranslation();
  const [query, setQuery] = useQueryParams({
    start: DateParam,
    end: DateParam,
    page: NumberParam,
  });
  const handleDateSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setQuery({
      start: stringToDate(draftStartDate),
      end: stringToDate(draftEndDate),
      page: 1,
    });
  };
  const { start, end } = query;
  return (
    <SideFilter
      title={t('conversations.filter_dates')}
      open={Boolean(start || end)}
    >
      <div className="px-3">
        <Form onSubmit={handleDateSubmit}>
          <FormGroup>
            <Label className="text-gray-700 mb-1 w-100">
              {t('conversations.filter_dates_start')}
              <Cleave
                placeholder={DATE_PLACEHOLDER}
                options={{ date: true, datePattern: ['m', 'd', 'Y'] }}
                onChange={(e) => setDraftStartDate(e.currentTarget.value)}
                value={draftStartDate}
                className="form-control"
                type="search"
                data-testid="start-date-input"
              />
            </Label>
            <Label className="text-gray-700 mb-1 w-100">
              {t('conversations.filter_dates_end')}
              <Cleave
                placeholder={DATE_PLACEHOLDER}
                options={{ date: true, datePattern: ['m', 'd', 'Y'] }}
                value={draftEndDate}
                onChange={(e) => setDraftEndDate(e.currentTarget.value)}
                className="form-control"
                type="search"
                data-testid="end-date-input"
              />
            </Label>
            <div className="mt-1 text-end">
              <Input
                className="mt-1"
                data-testid="date-submit"
                bsSize="sm"
                tag={Button}
                type="submit"
              >
                {t('common.save')}
              </Input>
            </div>
          </FormGroup>
        </Form>
      </div>
    </SideFilter>
  );
};

export default DateFilter;
