import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import cx from 'classnames';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from './FieldWithLinkEditor.module.scss';

export interface Props {
  value: string;
  onChange: (e: string) => void;
  wrapperClassName?: string;
}

const FieldWithLinkEditor = ({ value, wrapperClassName, onChange }: Props) => {
  // populate initial editor state from value
  const blocksFromHtml = htmlToDraft(value);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );
  const newEditorState = EditorState.createWithContent(contentState);
  const [editorState, setEditorState] = React.useState<EditorState | undefined>(
    newEditorState
  );

  const handleChange = (e: EditorState) => {
    setEditorState(e);
    const htmlContent = draftToHtml(convertToRaw(e.getCurrentContent()));
    onChange(htmlContent);
  };

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={handleChange}
      wrapperClassName={wrapperClassName}
      toolbarClassName={styles.toolbar}
      editorClassName={cx('form-control', styles.editor)}
      toolbar={{
        options: ['link'],
        link: {
          inDropdown: true,
          className: styles.toolbarLinkBtn,
          popupClassName: styles.linkPopup,
          showOpenOptionOnHover: true,
          defaultTargetOption: '_blank',
        },
      }}
    />
  );
};

export default FieldWithLinkEditor;
