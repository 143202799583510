import * as React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import Button, { Props as ButtonProps } from '../Button/Button';

import styles from './ExpandingButton.module.scss';

interface Props extends ButtonProps {
  icon: IconProp;
  children: React.ReactNode;
  title: string;
  expanded?: boolean; // programmatically expand btn, in addition to hover and focus-visible
}

const ExpandingButton = ({
  expanded,
  icon,
  children,
  className,
  ...other
}: Props) => {
  return (
    <Button
      className={cx(styles.button, 'insights-btn', className, {
        [styles.expanded]: expanded,
      })}
      {...other}
    >
      <span className={cx(styles.expandedContent)}>{children}</span>
      <FontAwesomeIcon
        icon={icon}
        className={cx(styles.icon, 'insights-icon')}
      />
    </Button>
  );
};

export default ExpandingButton;
