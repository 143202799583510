import { Box, BoxProps } from '@mui/material';

export function HSpacer({ grow, ...props }: { grow?: boolean } & BoxProps) {
  return (
    <Box
      flexBasis={props.width ?? props.flexBasis ?? '8px'}
      flexGrow={grow ? 1 : 0}
      flexShrink={0}
      height="100%"
      {...props}
    />
  );
}

export function VSpacer({ grow, ...props }: { grow?: boolean } & BoxProps) {
  return (
    <Box
      flexBasis={props.height ?? props.flexBasis ?? '8px'}
      flexGrow={grow ? 1 : 0}
      flexShrink={0}
      width="100%"
      {...props}
    />
  );
}
