import { useState } from 'react';
import { useSelector } from 'react-redux';

import { useAIContext } from 'src/components/Insights/Catalog/AIProvider';
import { useCatalogPageContext } from 'src/components/Insights/Catalog/CatalogPageProvider';
import { calculateVisibility } from 'src/components/Insights/utils/code';
import useDialogState from 'src/components/Insights/utils/dialog';
import catalogSelectors from 'src/redux/catalog/catalog-selectors';
import { assignCoding } from 'src/redux/catalog/catalog-slice';
import { selectors as filtersSelectors } from 'src/redux/catalog-filters/catalog-filters-selectors';
import { setVisibility } from 'src/redux/catalog-filters/catalog-filters-slice';
import { AbstractCode, Code, CodeTag } from 'src/types/insights';
import { createProvider } from 'src/util/provider';

export const [CodebookProvider, useCodebookContext] = createProvider(() => {
  const aiContext = useAIContext();
  const { aiOn } = aiContext;

  const catalogPageContext = useCatalogPageContext();
  const { selectedEntry, dispatch } = catalogPageContext;

  const codebook = useSelector(catalogSelectors.getCodebook);
  const structuralCodes = useSelector(catalogSelectors.getStructuralCodes);
  const thematicCodes = useSelector(catalogSelectors.getThematicCodes);
  const internalCodes = useSelector(catalogSelectors.getInternalCodes);
  const demographicCodes = useSelector(catalogSelectors.getDemographics);
  const thematicCodeFilters = useSelector(
    filtersSelectors.getThematicCodeVisibilityMap
  );
  const structuralCodeFilters = useSelector(
    filtersSelectors.getStructuralCodeVisibilityMap
  );
  const internalCodeFilters = useSelector(
    filtersSelectors.getInternalCodeVisibilityMap
  );
  const demographicCodeFilters = useSelector(
    filtersSelectors.getDemographicVisibilityMap
  );
  const filteredCodes = {
    ...thematicCodeFilters,
    ...structuralCodeFilters,
    ...internalCodeFilters,
    ...demographicCodeFilters,
  };

  const [codeFilter, setCodeFilter] = useState('');
  const [editingCodebook, setEditingCodebook] = useState(false);

  // ••• MENUS & DIALOGS

  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);
  const [dialogState, openDialog, closeDialog] = useDialogState({
    onOpen: () => setMenuAnchor(null),
  });
  const [accordionType, setAccordionType] =
    useState<AccordionType>('demographic');
  const [selectedCode, setSelectedCode] = useState<AbstractCode>();

  const openMenu = (
    code: AbstractCode,
    element: Element,
    accordionType: AccordionType
  ) => {
    setAccordionType(accordionType);
    setSelectedCode(code);
    setMenuAnchor(element);
  };

  const closeCodeMenu = () => {
    setMenuAnchor(null);
  };

  // ••• CODING

  /** applies the code to `selectedEntry` */
  const applyCode = (codeId: Code['id']) => {
    if (!selectedEntry) return;
    dispatch(assignCoding([codeId, selectedEntry.id]));
  };

  const setCodeVisibility = (codeId: Code['id'], visible: boolean) => {
    const category = thematicCodes.some((code) => code.id === codeId)
      ? 'thematicCodes'
      : structuralCodes.some((code) => code.id === codeId)
      ? 'structuralCodes'
      : demographicCodes.some((code) => code.id === codeId)
      ? 'demographics'
      : 'internalCodes';

    dispatch(
      setVisibility({
        category,
        id: codeId,
        // true shows entries with the code.
        // false hides entries with the code.
        // undefined turns off the filter for the code.
        //   we convert false to undefined here because the new codebook
        //   doesn't support hiding entries with a code.
        visible: visible ? visible : undefined,
      })
    );
  };

  const getCodeVisibility = (code: AbstractCode) =>
    calculateVisibility(code, filteredCodes);

  // ••• AI REASONING

  const [aiReasoningTag, setAIReasoningTag] = useState<CodeTag>();

  const showAIReasoning = (codeId: Code['id']) => {
    if (!aiOn) return;

    const coding = selectedEntry?.suggested_codings.find(
      (c) => c.code_id === codeId
    );
    const code = thematicCodes.find((code) => code.id === coding?.code_id);

    if (code && coding?.is_suggested && coding?.ai_metadata?.reason) {
      setAIReasoningTag({
        ...coding,
        ...code,
      });
    }
  };

  const hideAIReasoning = () => setAIReasoningTag(undefined);

  // ••• CONTEXT

  return {
    ...catalogPageContext,
    ...aiContext,

    codebook,
    structuralCodes,
    thematicCodes,
    internalCodes,
    demographicCodes,
    selectedCode,
    applyCode,

    aiReasoningTag,
    showAIReasoning,
    hideAIReasoning,

    editingCodebook,
    setEditingCodebook,

    codeFilter,
    setCodeFilter,

    setCodeVisibility,
    getCodeVisibility,

    accordionType,
    setAccordionType,

    dialogState,
    openDialog,

    menuAnchor,
    openMenu,
    closeCodeMenu,
    closeDialog,
  };
});

// DEFINITIONS

export type AccordionType =
  | 'structural'
  | 'thematic'
  | 'internal'
  | 'demographic';
