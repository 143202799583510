import { createSelector } from '@reduxjs/toolkit';

import { StoreState } from 'src/redux/store';
import { Organization } from 'src/types/auth';
import {
  Annotation,
  Conversation,
  Highlight,
  Host,
  Snippet,
  Topic,
} from 'src/types/conversation';
import { EntitiesState } from './entities-slice';

type EntityType = 'conversations' | 'annotations' | 'snippets' | 'hosts';

interface SnippetOptions {
  nestHighlights?: boolean;
}

interface HighlightOptions {
  nestSnippets?: boolean;
  nestConversation?: boolean;
  nestHostInConversation?: boolean;
}

interface ConversationOptions {
  nestHighlights?: boolean;
  nestHighlightsInSnippets?: boolean;
  nestSnippets?: boolean;
  nestSnippetsInHighlights?: boolean;
  nestHost?: boolean;
  nestStaffOwner?: boolean;
  nestOrganization?: boolean;
}

const ANNOTATION_HIGHLIGHT_TYPES = [
  'highlight_community',
  'highlight_curated',
  'highlight_auto',
];

/**
 * Gets entities as an array from the list of IDs, removing any that aren't found
 */
function nonNullEntities(
  state: StoreState,
  entityType: EntityType,
  ids: number[] | undefined
): any[] {
  return ids
    ? ids.map((id) => state.entities[entityType][id]).filter((d) => d != null)
    : [];
}

function denormalizeSnippet(
  state: StoreState,
  snippetId: number,
  options: SnippetOptions = {}
): Snippet | undefined {
  const { nestHighlights } = options;
  const result = state.entities.snippets[snippetId];

  if (!result) {
    return undefined;
  }

  const changes: any = {};
  if (nestHighlights) {
    changes.highlights = nonNullEntities(
      state,
      'annotations',
      result.annotation_ids
    );
  }

  return { ...result, ...changes };
}

function denormalizeHighlight(
  state: StoreState,
  highlightId: number,
  options: HighlightOptions = {}
): Highlight | undefined {
  const { nestSnippets, nestConversation, nestHostInConversation } = options;
  const result = state.entities.annotations[highlightId];

  if (
    !result ||
    ANNOTATION_HIGHLIGHT_TYPES.indexOf(result.annotation_type) === -1 // annotation must be highlight type
  ) {
    return undefined;
  }

  const changes: { snippets?: Snippet[]; conversation?: Conversation } = {};
  if (nestSnippets) {
    changes.snippets = nonNullEntities(state, 'snippets', result.snippet_ids);
  }

  if (nestConversation) {
    changes.conversation = denormalizeConversation(
      state,
      result.conversation_id,
      {
        nestHost: nestHostInConversation,
      }
    );
  }

  return { ...result, ...changes };
}

/**
 * Denormalizes a conversation from the store, optionally denormalizing
 * highlights and snippets.
 */
function denormalizeConversation(
  state: StoreState,
  conversationId: number,
  options: ConversationOptions = {}
): Conversation | undefined {
  const {
    nestHighlights,
    nestHighlightsInSnippets,
    nestSnippets,
    nestSnippetsInHighlights,
    nestHost = true,
    nestStaffOwner = false,
    nestOrganization = true,
  } = options;
  const result = state.entities.conversations[conversationId];
  if (!result) {
    return undefined;
  }

  const changes: any = {};
  if (nestHighlights) {
    changes.highlights = denormalizeHighlights(state, result.annotation_ids, {
      nestSnippets: nestSnippetsInHighlights,
    });
  }

  if (nestSnippets) {
    changes.snippets = denormalizeSnippets(state, result.snippet_ids, {
      nestHighlights: nestHighlightsInSnippets,
    });
  }

  if (nestHost) {
    changes.host = state.entities.hosts[result.host_id];
  }

  if (nestStaffOwner && result.staff_owner_id != null) {
    changes.staff_owner = state.entities.staff_owners[result.staff_owner_id];
  }

  if (nestOrganization) {
    if (result.forum) {
      changes.organization =
        state.entities.organizations[result.forum.community.organization_id];
    } else {
      changes.organization =
        state.entities.organizations[result.collection.organization_id];
    }
  }

  return { ...result, ...changes };
}

function denormalizeConversations(
  state: StoreState,
  ids: number[] | undefined,
  options: ConversationOptions = {}
): Conversation[] {
  return ids == null
    ? []
    : (ids
        .map((id) => denormalizeConversation(state, id, options))
        .filter((d) => d != null) as Conversation[]);
}

function denormalizeHighlights(
  state: StoreState,
  ids: number[] | undefined,
  options: HighlightOptions = {}
): Highlight[] {
  const result =
    ids == null
      ? []
      : (ids
          .map((id) => denormalizeHighlight(state, id, options))
          .filter((d) => d != null) as Highlight[]);
  return result;
}

function denormalizeSnippets(
  state: StoreState,
  ids: number[] | undefined,
  options: SnippetOptions = {}
): Snippet[] {
  const result =
    ids == null
      ? []
      : (ids
          .map((id) => denormalizeSnippet(state, id, options))
          .filter((d) => d != null) as Snippet[]);

  return result;
}

/**
 * Generic helpers
 */
const helperGetConversationAnnotations = (
  conversationEntity: Conversation | undefined,
  annotationsById: EntitiesState['annotations']
) => {
  if (!conversationEntity || !conversationEntity.annotation_ids) {
    return [];
  }
  return conversationEntity.annotation_ids
    .map((id) => annotationsById[id])
    .filter((d) => d != null);
};

const helperGetConversationSnippets = (
  conversationEntity: Conversation | undefined,
  snippetsById: EntitiesState['snippets'],
  annotationsById?: EntitiesState['annotations']
) => {
  if (!conversationEntity || !conversationEntity.snippet_ids) {
    return [];
  }

  let snippets = conversationEntity.snippet_ids
    .map((id) => snippetsById[id])
    .filter((d) => d != null);

  // add in highlights if provided
  if (annotationsById) {
    // nest highlights within snippets
    snippets = snippets.map((snippetEntity) => {
      let highlights;
      if (snippetEntity.annotation_ids) {
        highlights = snippetEntity.annotation_ids
          .map((id) => annotationsById[id])
          .filter(
            (d) =>
              d != null &&
              ANNOTATION_HIGHLIGHT_TYPES.indexOf(d.annotation_type) !== -1
          );
      }
      return {
        ...snippetEntity,
        highlights,
      };
    });
  }

  return snippets;
};

const helperGetConversationHost = (
  conversationEntity: Conversation | undefined,
  hostsById: EntitiesState['hosts']
) => {
  return conversationEntity ? hostsById[conversationEntity.host_id] : undefined;
};

const helperGetConversationOrganization = (
  conversationEntity: Conversation | undefined,
  organizationsById: EntitiesState['organizations']
) => {
  if (conversationEntity) {
    if (conversationEntity.forum) {
      const orgId = conversationEntity.forum.community.organization_id;
      return orgId != null ? organizationsById[orgId] : undefined;
    } else {
      const orgId = conversationEntity.collection.organization_id;
      return orgId != null ? organizationsById[orgId] : undefined;
    }
  }
  return undefined;
};

const helperGetConversationTopics = (
  conversationEntity: Conversation | undefined,
  topicsById: EntitiesState['topics']
) => {
  if (!conversationEntity || !conversationEntity.topic_probs) {
    return [];
  }

  // topic probs is a dict of {id: prob}, we use this to get Topics
  // and sort them in descending order of topic probability
  const topicIds = Object.entries(conversationEntity.topic_probs)
    .sort((a, b) => b[1] - a[1])
    .map((t) => +t[0]);

  return topicIds.map((id) => topicsById[id]).filter((d) => d != null);
};

const helperGetConversation = (
  conversation: Conversation | undefined,
  annotations: Annotation[] | undefined,
  snippets: Snippet[] | undefined,
  host: Host | undefined,
  organization: Organization | undefined,
  topics: Topic[] | undefined
): Conversation | undefined => {
  if (!conversation) {
    return undefined;
  }

  return {
    ...conversation,
    annotations,
    highlights:
      annotations &&
      annotations.filter(
        (d) => ANNOTATION_HIGHLIGHT_TYPES.indexOf(d.annotation_type) !== -1
      ),
    snippets,
    host: host as Host,
    organization: organization,
    topics,
  };
};

const helperGetHighlightConversation = (
  highlight: Highlight | undefined,
  conversationsById: EntitiesState['conversations']
): Conversation | undefined => {
  if (!highlight) {
    return undefined;
  }

  return conversationsById[highlight.conversation_id];
};

const helperGetHighlightSnippets = (
  highlightEntity: Highlight | undefined,
  snippetsById: EntitiesState['snippets']
) => {
  if (!highlightEntity || !highlightEntity.snippet_ids) {
    return [];
  }

  const snippets = highlightEntity.snippet_ids
    .map((id) => snippetsById[id])
    .filter((d) => d != null);

  return snippets;
};

const helperGetHighlight = (
  highlight: Highlight | undefined,
  conversation: Conversation | undefined,
  host: Host | undefined,
  snippets: Snippet[] | undefined
): Highlight | undefined => {
  if (!highlight) {
    return undefined;
  }

  // add the host to the conversation
  let conversationWithHost = conversation;
  if (conversation && host) {
    conversationWithHost = { ...conversation, host };
  }

  return {
    ...highlight,
    conversation: conversationWithHost,
    snippets,
  };
};

/**
 * Using reselect allows us to not create new objects if the underlying
 * data hasn't changed. By composing selectors that take (state, props)
 * we can limit our recreation of duplicate objects.
 */
const selectConversations = (state: StoreState) => state.entities.conversations;
const selectAnnotations = (state: StoreState) => state.entities.annotations;
const selectSnippets = (state: StoreState) => state.entities.snippets;
const selectHosts = (state: StoreState) => state.entities.hosts;
const selectOrganizations = (state: StoreState) => state.entities.organizations;
const selectTopics = (state: StoreState) => state.entities.topics;

/**
 * Map from the URL param conversationId to a conversation entity
 * (still normalized)
 *
 * This is used on the ConversationRoute ("ConversationDetails")
 */
const selectConversationDetailsEntity = (
  state: StoreState,
  props: any
): Conversation | undefined =>
  selectConversations(state)[props.match.params.conversationId];

const getConversationDetailsAnnotations = createSelector(
  selectConversationDetailsEntity,
  selectAnnotations,
  helperGetConversationAnnotations
);

/**
 * Given conversation entity, get its associated snippets
 * and nest the highlights in the snippets
 */
const getConversationDetailsSnippets = createSelector(
  selectConversationDetailsEntity,
  selectSnippets,
  selectAnnotations,
  helperGetConversationSnippets
);

/**
 * Given a conversation entity, get the host
 */
const getConversationDetailsHost = createSelector(
  selectConversationDetailsEntity,
  selectHosts,
  helperGetConversationHost
);

/**
 * Given a conversation entity, get the organization
 */
const getConversationDetailsOrganization = createSelector(
  selectConversationDetailsEntity,
  selectOrganizations,
  helperGetConversationOrganization
);

/**
 * Given a conversation entity, get its topics
 */
const getConversationDetailsTopics = createSelector(
  selectConversationDetailsEntity,
  selectTopics,
  helperGetConversationTopics
);

/*
  Selector for ConversationRoute
  nestHighlights: true,
  nestSnippets: true,
  nestHighlightsInSnippets: true,
*/
const getConversationDetails = createSelector(
  selectConversationDetailsEntity,
  getConversationDetailsAnnotations,
  getConversationDetailsSnippets,
  getConversationDetailsHost,
  getConversationDetailsOrganization,
  getConversationDetailsTopics,
  helperGetConversation
);

/**
 * Map from the URL param highlightId to a highlight entity
 * (still normalized). If there is no URL param, try to use the
 * prop that was passed in, assuming it's a highlightId
 *
 * This is used on the HighlightRoute.
 */
const selectHighlightEntity = (
  state: StoreState,
  props: any
): Highlight | undefined => {
  if (props && props.match && props.match.params) {
    return selectAnnotations(state)[props.match.params.highlightId];
  } else {
    return selectAnnotations(state)[props];
  }
};

/**
 * Given highlight entity, get its associated conversation
 */
const getHighlightConversation = createSelector(
  selectHighlightEntity,
  selectConversations,
  helperGetHighlightConversation
);

/**
 * Given highlight entity, get its associated snippets
 */
const getHighlightSnippets = createSelector(
  selectHighlightEntity,
  selectSnippets,
  helperGetHighlightSnippets
);

/**
 * Given highlight conversation, get its host
 */
const getHighlightConversationHost = createSelector(
  getHighlightConversation,
  selectHosts,
  helperGetConversationHost
);

/**
 * Selector for HighlightRoute
 * Also gets the conversation, host, and snippets
 */
const getHighlight = createSelector(
  // eslint-disable-next-line
  // @ts-ignore
  selectHighlightEntity,
  getHighlightConversation,
  getHighlightConversationHost,
  getHighlightSnippets,
  helperGetHighlight
);

// selectors
export const selectors = {
  getConversationDetails,
  getHighlight,

  /////////// OLD METHODS DEPRECATED /////////////
  getConversation: (
    state: StoreState,
    conversationId: number,
    options?: ConversationOptions
  ): Conversation | undefined =>
    denormalizeConversation(state, conversationId, options),

  getHighlights: (
    state: StoreState,
    highlightIds: number[],
    options?: HighlightOptions
  ): Highlight[] => denormalizeHighlights(state, highlightIds, options),

  getConversations: (
    state: StoreState,
    conversationIds: number[],
    options?: ConversationOptions
  ): Conversation[] =>
    denormalizeConversations(state, conversationIds, options),
};

export default selectors;
