import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Label } from 'reactstrap';
import { Field, FormikProps } from 'formik';

import LoadingSpinner from 'src/components/core/LoadingSpinner/LoadingSpinner';
import FormButton from 'src/components/FormButton/FormButton';
import FormError from 'src/components/FormError/FormError';
import FormStep, { FormContent } from 'src/components/FormStep/FormStep';
import { ServerError } from 'src/types/core';
import { getErrorMessage } from 'src/util/form';
import EmailSent from './EmailSent';

interface Props extends FormikProps<any> {
  errorFromServer: ServerError | undefined;
  isSaved: boolean;
  isSaving: boolean;
  autoFocus: boolean;
}

const GuardianInfoStep = ({
  isSaved,
  isSaving,
  values,
  errors,
  handleChange,
  errorFromServer,
  autoFocus,
}: Props) => {
  const { t } = useTranslation();
  if (isSaved) {
    return <EmailSent />;
  }

  const emailError =
    getErrorMessage({
      field: 'guardian_email',
      status: errorFromServer,
      errors,
    }) ||
    getErrorMessage({
      field: 'guardianEmail',
      status: errorFromServer,
      errors,
    });

  return (
    <FormStep>
      <FormContent>
        <h1>{t('guardian_permission.header')}</h1>
        <Label className="w-100" for="guardianName">
          {t('guardian_permission.name')}
          <Field
            name="guardianName"
            id="guardianName"
            type="text"
            component="input"
            onChange={handleChange}
            value={values.guardianName}
            aria-describedby="helper error-msg-guardianName"
            aria-invalid={Boolean(errors && errors['guardianName'])}
            aria-required
            data-testid="guardianName"
            className="form-control"
            autoFocus={autoFocus}
          />
          <FormError name="guardianName">
            {errors ? errors['guardianName']?.toLocaleString() : null}
          </FormError>
        </Label>
        <Label className="w-100" for="guardianEmail">
          {t('guardian_permission.email')}
          <Field
            name="guardianEmail"
            id="guardianEmail"
            type="email"
            component="input"
            value={values.guardianEmail}
            onChange={handleChange}
            data-testid="guardianEmail"
            aria-describedby="helper error-msg-guardianEmail"
            aria-invalid={Boolean(emailError != null)}
            aria-required
            className="form-control"
          />
          <FormError name="guardianEmail">{emailError}</FormError>
        </Label>
        <p id="helper">
          <Trans
            i18nKey="guardian_permission.subtext_1"
            components={{
              1: <Link to="/legal" />,
            }}
          />
        </p>
        <p>
          <Trans
            i18nKey="guardian_permission.subtext_2"
            /*eslint-disable */
            components={{ 1: <a href="mailto:help@lvn.org" /> }}
            /*eslint-disable */
          />
        </p>
      </FormContent>

      <FormButton type="submit" data-testid="next-button" disabled={isSaving}>
        {isSaving ? (
          <>
            <span className="me-2">{t('guardian_permission.send_active')}</span>{' '}
            <LoadingSpinner active />
          </>
        ) : (
          t('guardian_permission.send')
        )}
      </FormButton>
    </FormStep>
  );
};

export default GuardianInfoStep;
