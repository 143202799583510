import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import cx from 'classnames';

import Button from 'src/components/core/Button/Button';
import RedactionWavesurfer from 'src/components/WaveSurfer/RedactionWaveSurfer';
import { Annotation } from 'src/types/conversation';

import styles from './RedactModal.module.scss';

const RedactModal = ({
  onRedact,
  redactionParams,
}: {
  onRedact: (
    trimTimes: Pick<Annotation, 'audio_start_offset' | 'audio_end_offset'>
  ) => void;
  redactionParams: {
    annotation?: Pick<Annotation, 'audio_start_offset' | 'audio_end_offset'>;
    wordsToRedact?: string;
  };
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [tabIndex, setTabIndex] = React.useState<1 | 2>(1);
  const [trimTimes, setTrimTimes] = React.useState<
    [number, number] | undefined
  >();
  const [peaks, setPeaks] = React.useState<any>();
  const { t } = useTranslation();
  const handleRedaction = () => {
    if (trimTimes) {
      onRedact({
        audio_start_offset: trimTimes[0],
        audio_end_offset: trimTimes[1],
      });
    } else if (redactionParams.annotation) {
      onRedact({
        audio_start_offset: redactionParams.annotation.audio_start_offset,
        audio_end_offset: redactionParams.annotation.audio_end_offset,
      });
    }
    setOpen(false);
  };

  useEffect(() => {
    if (!open) {
      setTabIndex(1);
    }
  }, [open]);

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        data-testid="transcript-redact-modal-button"
        disabled={!redactionParams.annotation}
        id={'transcript-redact-button'}
        data-ignore-click={true}
      >
        {t('transcript_editor.redact')}
      </Button>
      <Modal
        className={cx('ModalContent', styles.modal)}
        overlayClassName="ModalOverlay"
        isOpen={open}
        onRequestClose={() => {
          setOpen(false);
          setTrimTimes(undefined);
        }}
        contentLabel="Redaction Modal"
        testId={'redaction-modal'}
      >
        <header>
          <h1>
            {tabIndex == 1
              ? t('transcript_editor.redact')
              : t('transcript_editor.redact_modal_confirm')}
          </h1>
        </header>
        <main>
          <div
            className={cx(
              'justify-content-center flex-column',
              tabIndex == 1 ? 'd-flex' : 'd-block'
            )}
          >
            <RedactionWavesurfer
              onTrim={(trimTimes) => setTrimTimes(trimTimes)}
              peaks={peaks}
              setPeaks={setPeaks}
              defaultTrimTimes={
                trimTimes ?? [
                  redactionParams.annotation?.audio_start_offset ?? 0,
                  redactionParams.annotation?.audio_end_offset ?? 0,
                ]
              }
            />
            <div className="d-flex flex-column">
              <span>Words to replace with [redacted]: </span>
              <span data-testid="words-to-redact">
                <b>{redactionParams.wordsToRedact}</b>
              </span>
            </div>
            <div>
              <Button
                onClick={() => setTabIndex(2)}
                className={cx(styles.cancel, styles.button)}
                data-testid="redact-modal-next"
              >
                {t('common.next')}
              </Button>
              <Button
                onClick={() => {
                  setOpen(false);
                  setTrimTimes(undefined);
                }}
                className={cx(styles.cancel, styles.button)}
                data-testid="redact-modal-cancel"
              >
                {t('common.cancel')}
              </Button>
            </div>
          </div>
          <div className={tabIndex == 2 ? '' : 'd-none'}>
            <p className="text-center" data-testid="redact-modal-explainer">
              {t('transcript_editor.redact_modal_message')}
            </p>
            <div className="d-flex justify-content-center">
              <Button
                onClick={() => setTabIndex(1)}
                className={cx(styles.cancel, styles.button)}
                data-testid="redact-modal-previous"
              >
                {t('common.previous')}
              </Button>
              <Button
                onClick={handleRedaction}
                className={cx(styles.danger, styles.button)}
                data-testid="redact-modal-confirm"
              >
                {t('transcript_editor.redact')}
              </Button>
              <Button
                onClick={() => {
                  setOpen(false);
                  setTrimTimes(undefined);
                }}
                className={cx(styles.cancel, styles.button)}
                data-testid="redact-modal-cancel-final"
              >
                {t('common.cancel')}
              </Button>
            </div>
          </div>
        </main>
      </Modal>
    </>
  );
};

export default RedactModal;
