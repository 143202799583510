import * as React from 'react';
import cx from 'classnames';
import { Bin, linkVertical, max, ScaleLinear, scaleLinear } from 'd3';

import { BaseTermTimings, TermTimings } from 'src/types/conversation';

import styles from './TimelineTerms.module.scss';

interface Props {
  annotationY: number;
  termHeight: number;
  bins: Bin<BaseTermTimings, number>[];
  onSeek: (seekTime: number, play?: boolean) => void;
  timeScale: ScaleLinear<number, number>;
  toggledTerm: TermTimings | undefined;
  onToggleTerm: (topTermString: string | undefined) => void;
  hoveredTerm: BaseTermTimings | undefined;
  onHoverTerm: (topTerm: BaseTermTimings | undefined) => void;
  searchTermTimings: BaseTermTimings | undefined;
}

export class TimelineTerms extends React.PureComponent<Props> {
  static defaultProps = {
    termHeight: 28,
  };

  handleClearToggle = () => {
    const { onToggleTerm } = this.props;
    onToggleTerm(undefined);
  };

  handleHoverTerm = (hoveredTerm: BaseTermTimings | undefined) => {
    const { onHoverTerm } = this.props;
    onHoverTerm(hoveredTerm);
  };

  handleToggleTerm = (topTerm: BaseTermTimings) => {
    const { toggledTerm, onToggleTerm } = this.props;
    onToggleTerm(toggledTerm === topTerm ? undefined : topTerm.term);
  };

  handleClickGlyph = (seekTime: number) => {
    const { onSeek } = this.props;
    const contextBuffer = 3; // num seconds before the word to give context
    onSeek(Math.max(0, seekTime - contextBuffer), true);
  };

  render() {
    const {
      timeScale,
      termHeight,
      bins,
      annotationY,
      toggledTerm,
      searchTermTimings,
    } = this.props;

    const link = linkVertical();

    // fade out by count
    const alphaScale = scaleLinear().domain([1, 5]).range([0.6, 1]).clamp(true);

    const maxBinSize = max(bins, (d) => d.length) as number;
    const glyphRadius = 7;
    const glyphPath = `M0,-${glyphRadius} l${glyphRadius},${glyphRadius} l${-glyphRadius},${glyphRadius} l${-glyphRadius},${-glyphRadius}Z`;
    const width = timeScale.range()[1] - timeScale.range()[0];

    return (
      <g className={styles.TimelineTerms}>
        <rect
          width={width}
          height={annotationY - 30}
          fill="tomato"
          fillOpacity={0}
          onClick={this.handleClearToggle}
        />
        {bins.map((binTerms, i) => {
          return (
            <g
              key={i}
              transform={`translate(${timeScale(binTerms.x0 || 0)} 0)`}
            >
              {binTerms.map((term, j) => {
                const xMid =
                  (timeScale(binTerms.x1 || 0) - timeScale(binTerms.x0 || 0)) /
                  2;

                // add in delta from max bin size to align bottom
                const y = (j + (maxBinSize - binTerms.length)) * termHeight;
                return (
                  <g
                    className={cx(styles.term, {
                      [styles.toggledTerm]: term === toggledTerm,
                      [styles.searchTerm]: term === searchTermTimings,
                    })}
                    // acronyms can end up as two separate entries, so add the i and j to
                    // be sure this is unique. would be resolved by https://cortico.atlassian.net/browse/LVN-2038
                    // and then we could just use `term.term`
                    key={`${term.term}-${i}-${j}`}
                  >
                    <text
                      className={styles.termText}
                      textAnchor="middle"
                      dy="1em"
                      x={xMid}
                      y={y}
                      fillOpacity={alphaScale(term.count)}
                      onClick={() => this.handleToggleTerm(term)}
                      onMouseEnter={() => this.handleHoverTerm(term)}
                      onMouseLeave={() => this.handleHoverTerm(undefined)}
                    >
                      {term.term}
                    </text>
                    {term.timings.map((timing, k) => {
                      const sx = xMid;
                      const sy = y + termHeight * 0.8;
                      const tx =
                        timeScale(timing) - timeScale(binTerms.x0 || 0);
                      const ty = annotationY;
                      return (
                        <React.Fragment key={k}>
                          <path
                            className={styles.link}
                            d={
                              link({
                                source: [sx, sy],
                                target: [tx, ty - glyphRadius],
                              }) || ''
                            }
                          />
                          <path
                            className={styles.glyph}
                            transform={`translate(${tx} ${ty})`}
                            d={glyphPath}
                            onClick={() => this.handleClickGlyph(timing)}
                          />
                        </React.Fragment>
                      );
                    })}
                  </g>
                );
              })}
            </g>
          );
        })}
      </g>
    );
  }
}

export default TimelineTerms;
