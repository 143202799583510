import * as React from 'react';
import cx from 'classnames';
import { ScaleLinear, scaleLinear } from 'd3';

import {
  SpeakerTurn,
  SpeakerTurnCollection,
} from 'src/components/ConversationTimeline/speaker-turns';
import { BaseTermTimings, TermTimings } from 'src/types/conversation';

import styles from './SpeakerTracks.module.scss';

interface Props {
  speakerTurns: SpeakerTurnCollection[];
  width: number;
  trackHeight: number;
  onPlay: (seekTime?: number) => void;
  timeScale: ScaleLinear<number, number>;
  nameWidth: number;
  onToggleSpeaker: (speakerId: string | undefined) => void;
  toggledSpeakerId: string | undefined;
  toggledTerm: TermTimings | undefined;
  searchTermTimings: BaseTermTimings | undefined;
  hoveredTerm: BaseTermTimings | undefined;
  seekTime?: number | undefined;
}

interface State {
  hoverSpeakerId: string | number | null;
  hoverTurn: SpeakerTurn | null;
}

class SpeakerTracks extends React.PureComponent<Props, State> {
  static defaultProps = {
    width: 800,
    trackHeight: 24,
    nameWidth: 100,
  };

  state: State = {
    hoverSpeakerId: null,
    hoverTurn: null,
  };

  handleHoverSpeaker = (speakerId: string | number | null) => {
    this.setState({
      hoverSpeakerId: speakerId,
    });
  };

  handleHoverTurn = (turn: SpeakerTurn | null) => {
    this.setState({
      hoverTurn: turn,
    });
  };

  handleToggleSpeaker = (speakerId: string | undefined) => {
    const { onToggleSpeaker, toggledSpeakerId } = this.props;
    onToggleSpeaker(toggledSpeakerId === speakerId ? undefined : speakerId);
  };

  handleClickTurn = (turn: SpeakerTurn) => {
    const { onPlay } = this.props;
    onPlay(turn.audio_start_offset);
  };

  render() {
    const {
      width,
      trackHeight,
      speakerTurns,
      timeScale,
      nameWidth,
      toggledSpeakerId,
      searchTermTimings,
      toggledTerm,
      hoveredTerm,
      seekTime,
    } = this.props;
    const { hoverSpeakerId, hoverTurn } = this.state;

    if (!speakerTurns.length) {
      return null;
    }

    const barHeight = trackHeight * 0.8;
    const barMargin = (trackHeight - barHeight) / 2;

    const yScale = scaleLinear()
      .domain([0, speakerTurns.length])
      .range([0, trackHeight * speakerTurns.length]);

    return (
      <g>
        {speakerTurns.map((speakerTurn, i) => {
          const y = yScale(i);
          return (
            <g
              className={cx(styles.speakerTrack, styles[`speaker-${i % 10}`], {
                [styles.isFacilitator]: speakerTurn.is_facilitator,
                [styles.isParticipant]:
                  speakerTurn.is_participant || !speakerTurn.is_facilitator,
                [styles.speakerHover]:
                  hoverSpeakerId === speakerTurn.speaker_id,
                [styles.speakerToggled]:
                  toggledSpeakerId === speakerTurn.speaker_id,
                [styles.hasToggledTerm]: !!toggledTerm,
                [styles.hasSearchedTerm]: !!searchTermTimings,
                [styles.hasHoveredTerm]: !!hoveredTerm,
                [styles.hasHoveredTurn]:
                  hoverTurn && hoverTurn.speaker_id === speakerTurn.speaker_id,
              })}
              transform={`translate(0, ${y})`}
              key={speakerTurn.speaker_id}
            >
              <rect
                className={styles.trackBg}
                x={0}
                width={width}
                height={trackHeight}
              />
              <g transform={`translate(${-nameWidth} 0 )`}>
                <rect
                  x={0}
                  width={nameWidth}
                  height={trackHeight}
                  className={styles.speakerNameBg}
                  fillOpacity={0}
                  onMouseEnter={() =>
                    this.handleHoverSpeaker(speakerTurn.speaker_id)
                  }
                  onMouseLeave={() => this.handleHoverSpeaker(null)}
                  onClick={() =>
                    this.handleToggleSpeaker(speakerTurn.speaker_id)
                  }
                />
                <text className={styles.speakerName} dy="1em">
                  {speakerTurn.speaker_name}
                </text>
              </g>
              <g>
                {speakerTurn.turns.map((turn, j) => {
                  const isSearched =
                    searchTermTimings &&
                    searchTermTimings.timings.some(
                      (timing) =>
                        turn.audio_start_offset <= timing &&
                        turn.audio_end_offset >= timing
                    );
                  const isToggled =
                    toggledTerm &&
                    toggledTerm.timings.some(
                      (timing) =>
                        turn.audio_start_offset <= timing &&
                        turn.audio_end_offset >= timing
                    );

                  const isHovered =
                    hoveredTerm &&
                    hoveredTerm.timings.some(
                      (timing) =>
                        turn.audio_start_offset <= timing &&
                        turn.audio_end_offset >= timing
                    );

                  const isSeekedTo =
                    seekTime != null &&
                    turn.audio_start_offset <= seekTime &&
                    turn.audio_end_offset >= seekTime;
                  return (
                    <rect
                      key={j}
                      className={cx(styles.turnRect, {
                        [styles.toggledTerm]: isToggled,
                        [styles.searchedTerm]: isSearched,
                        [styles.hoveredTerm]: isHovered,
                        [styles.isSeekedTo]: isSeekedTo,
                      })}
                      x={timeScale(turn.audio_start_offset)}
                      rx={1}
                      ry={1}
                      width={
                        timeScale(turn.audio_end_offset) -
                        timeScale(turn.audio_start_offset)
                      }
                      height={barHeight}
                      y={barMargin}
                      onClick={() => this.handleClickTurn(turn)}
                      onMouseEnter={() => this.handleHoverTurn(turn)}
                      onMouseLeave={() => this.handleHoverTurn(null)}
                    />
                  );
                })}
              </g>
            </g>
          );
        })}
      </g>
    );
  }
}

export default SpeakerTracks;
