import * as React from 'react';

import {
  default as ScrollingTranscript,
  Props as TranscriptProps,
} from 'src/components/ScrollingTranscript/ScrollingTranscript';
import useAnnotationFromSelection from 'src/components/Transcript/useAnnotationSelection';
import { useSeekTime } from 'src/contexts/GlobalAudioContext';
import { Highlight } from 'src/types/conversation';

interface Props extends TranscriptProps {
  conversationId: number;
  highlights: Highlight[];
  isActive: boolean;
  isMobile: boolean;
  autoScroll?: boolean;
  handleScrollInterruption?: () => void;
}

const InteractiveTranscript = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      conversationId,
      isActive,
      isMobile,
      handleScrollInterruption,
      autoScroll = true,
      ...other
    },
    ref
  ) => {
    const {
      annotationCreationSelection,
      setPersistSelection,
      clearSelectionRange,
    } = useAnnotationFromSelection();
    const handleClearSelection = React.useCallback(() => {
      setPersistSelection(false);
      clearSelectionRange(true);
    }, [clearSelectionRange, setPersistSelection]);
    const seekTime = useSeekTime({ isActive });
    return (
      <ScrollingTranscript
        annotationCreationSelection={annotationCreationSelection}
        embedded={false}
        interactive={!isMobile}
        conversationId={conversationId}
        onPersistSelection={setPersistSelection}
        onClearSelection={handleClearSelection}
        seekTime={seekTime}
        handleScrollInterruption={handleScrollInterruption}
        autoScroll={autoScroll}
        {...other}
        ref={ref}
      />
    );
  }
);

export default React.memo(InteractiveTranscript);
