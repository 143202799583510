import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { call, put, SagaReturnType, takeLatest } from 'redux-saga/effects';

import * as api from 'src/api/api';
import { Organization } from 'src/types/auth';
import {
  Collection,
  CollectionDetail,
  CollectionFields,
} from 'src/types/collection';

export interface CollectionsState {
  managed: {
    isLoading: boolean;
    error: Error | undefined;
    collections: Collection[] | undefined;
  };
  editCollectionDetail: {
    isSaving: boolean;
    isSaved: boolean;
    error: Error | undefined;
    collectionId: number | undefined;
  };
  createCollection: {
    isSaving: boolean;
    isSaved: boolean;
    error: Error | undefined;
  };
}

const initialState: CollectionsState = {
  managed: {
    isLoading: false,
    error: undefined,
    collections: undefined,
  },
  editCollectionDetail: {
    isSaving: false,
    isSaved: false,
    error: undefined,
    collectionId: undefined,
  },
  createCollection: {
    isSaving: false,
    isSaved: false,
    error: undefined,
  },
};

const slice = createSlice({
  name: 'collections',
  initialState,
  reducers: {
    loadManagedCollections(
      state,
      action: PayloadAction<{ organizationId?: Organization['id'] }>
    ) {
      state.managed.isLoading = true;
      state.managed.error = undefined;
    },

    loadManagedCollectionsSuccess(state, action: PayloadAction<Collection[]>) {
      state.managed.isLoading = false;
      state.managed.error = undefined;
      state.managed.collections = action.payload;
    },

    loadManagedCollectionsFailure(state, action: PayloadAction<Error>) {
      state.managed.isLoading = false;
      state.managed.error = action.payload;
    },

    editCollectionDetail(
      state,
      action: PayloadAction<{
        collectionId: CollectionDetail['id'];
        changes: Partial<CollectionDetail>;
      }>
    ) {
      state.editCollectionDetail.isSaving = true;
      state.editCollectionDetail.isSaved = false;
      state.editCollectionDetail.error = undefined;
      state.editCollectionDetail.collectionId = action.payload.collectionId;
    },
    editCollectionDetailSuccess(
      state,
      action: PayloadAction<{
        collectionDetail: Pick<CollectionDetail, 'id'>;
        changes: Partial<Collection>;
      }>
    ) {
      state.editCollectionDetail.isSaving = false;
      state.editCollectionDetail.isSaved = true;
      state.editCollectionDetail.error = undefined;
      // update admin state with changes
      if (state.managed.collections) {
        state.managed.collections = state.managed.collections.map(
          (collection) => {
            if (collection.id !== action.payload.collectionDetail.id)
              return collection;

            return Object.assign(collection, action.payload.changes);
          }
        );
      }
    },
    editCollectionDetailFailure(state, action: PayloadAction<Error>) {
      state.editCollectionDetail.isSaving = false;
      state.editCollectionDetail.isSaved = false;
      state.editCollectionDetail.error = action.payload;
    },
    clearEditCollectionDetail(state, action: PayloadAction) {
      state.editCollectionDetail.isSaving = false;
      state.editCollectionDetail.isSaved = false;
      state.editCollectionDetail.error = undefined;
      state.editCollectionDetail.collectionId = undefined;
    },

    deleteCollection(state, action: PayloadAction<CollectionDetail['id']>) {
      if (state.managed.collections) {
        state.managed.collections = state.managed.collections.filter(
          (collection) => collection.id !== action.payload
        );
      }
    },

    createCollection(state, action: PayloadAction<CollectionFields>) {
      state.createCollection.isSaving = true;
      state.createCollection.isSaved = false;
      state.createCollection.error = undefined;
    },
    createCollectionSuccess(
      state,
      action: PayloadAction<{
        collectionId: number;
        collectionFields: CollectionFields;
      }>
    ) {
      state.createCollection.isSaving = false;
      state.createCollection.isSaved = true;
      const { collectionFields, collectionId } = action.payload;
      const { description, title, privacy_level, organization_id } =
        collectionFields;

      // these fields should be present when creating a collection
      if (title && privacy_level && organization_id) {
        const newCollection: Collection = {
          id: collectionId,
          num_conversations: 0,
          description: description || '',
          title,
          privacy_level,
          organization_id,
        };
        if (state.managed.collections) {
          state.managed.collections = [
            ...state.managed.collections,
            newCollection,
          ];
        } else {
          state.managed.collections = [newCollection];
        }
      }
    },
    createCollectionFailure(state, action: PayloadAction<Error>) {
      state.createCollection.isSaving = false;
      state.createCollection.isSaved = false;
      state.createCollection.error = action.payload;
    },
    clearCreateCollection(state, action: PayloadAction) {
      state.createCollection.isSaving = false;
      state.createCollection.isSaved = false;
      state.createCollection.error = undefined;
    },
  },
});

export const {
  loadManagedCollections,
  loadManagedCollectionsSuccess,
  loadManagedCollectionsFailure,
  editCollectionDetail,
  editCollectionDetailSuccess,
  editCollectionDetailFailure,
  clearEditCollectionDetail,
  deleteCollection,
  createCollection,
  createCollectionSuccess,
  createCollectionFailure,
  clearCreateCollection,
} = slice.actions;
export const actions = slice.actions;
export default slice.reducer;

/** Sagas */

export function* sagaLoadManagedCollections(
  action: ReturnType<typeof loadManagedCollections>
) {
  try {
    const collections: SagaReturnType<typeof api.getCollections> = yield call(
      api.getCollections,
      { isManager: true, organizationId: action.payload.organizationId }
    );
    yield put(loadManagedCollectionsSuccess(collections));
  } catch (err) {
    yield put(loadManagedCollectionsFailure(err as Error));
  }
}

export function* sagaEditCollectionDetail(
  action: ReturnType<typeof editCollectionDetail>
) {
  try {
    const { collectionId, changes } = action.payload;
    const collectionDetail: SagaReturnType<typeof api.editCollectionDetail> =
      yield call(api.editCollectionDetail, collectionId, changes);

    yield put(editCollectionDetailSuccess({ collectionDetail, changes }));
  } catch (err) {
    yield put(editCollectionDetailFailure(err as Error));
  }
}

export function* sagaDeleteCollection(
  action: ReturnType<typeof deleteCollection>
) {
  try {
    const collectionId = action.payload;
    yield call(api.deleteCollection, collectionId);
  } catch (err) {
    // an error occurred, ignore for now
  }
}

export function* sagaCreateCollection(
  action: ReturnType<typeof createCollection>
) {
  try {
    const response: SagaReturnType<typeof api.createCollection> = yield call(
      api.createCollection,
      action.payload
    );

    yield put(
      createCollectionSuccess({
        collectionId: response.id,
        collectionFields: action.payload,
      })
    );
  } catch (err) {
    yield put(createCollectionFailure(err as Error));
  }
}

export const sagas = [
  takeLatest(loadManagedCollections.type, sagaLoadManagedCollections),
  takeLatest(editCollectionDetail.type, sagaEditCollectionDetail),
  takeLatest(deleteCollection.type, sagaDeleteCollection),
  takeLatest(createCollection.type, sagaCreateCollection),
];
