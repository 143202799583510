/** For global UI state */
import { createSlice } from '@reduxjs/toolkit';

interface UiState {
  audio: {
    isPlayerVisible: boolean;
  };
}

const initialState: UiState = {
  audio: {
    isPlayerVisible: false,
  },
};

const slice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    showAudioPlayer(state) {
      state.audio.isPlayerVisible = true;
    },
    hideAudioPlayer(state) {
      state.audio.isPlayerVisible = false;
    },
  },
});

export const { showAudioPlayer, hideAudioPlayer } = slice.actions;
export const actions = slice.actions;

export default slice.reducer;
