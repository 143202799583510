import { WordChunk, WordEntry } from 'src/types/conversation';

// number of seconds long a chunk can be at its max
export const CHUNK_DURATION_LIMIT = 3;

/**
 * Break up the array of words into chunks that can be clicked or highlighted
 */
export function chunksFromWordEntries(words: WordEntry[]): WordChunk[] {
  const chunks = [];
  let chunkWords: WordEntry[] = [];
  for (let i = 0; i < words.length; ++i) {
    chunkWords.push(words[i]);

    // The termination clause for a chunk of words:
    // 1) Does the current word contain a punctuation break
    // 2) Is the current word the last word in the word entry
    // 3) Is the current chunk longer than CHUNK_DURATION_LIMIT
    if (
      /[.!?;,]$/.test(words[i][0]) ||
      i === words.length - 1 ||
      chunkWords[chunkWords.length - 1][2] - chunkWords[0][1] >=
        CHUNK_DURATION_LIMIT
    ) {
      // For end time, prefer to use the start of the next word (minus epsilon) if available.
      // Helps remove gaps between chunks which is helpful for showing where we are in the transcript
      chunks.push({
        words: chunkWords,
        audio_start_offset: chunkWords[0][1],
        audio_end_offset: words[i + 1]
          ? words[i + 1][1]
          : chunkWords[chunkWords.length - 1][2],
      });

      // Initialize a new chunk
      chunkWords = [];
    }
  }

  return chunks;
}
