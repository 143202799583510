import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { clone } from 'lodash';
import debounce from 'lodash.debounce';
import WaveSurfer from 'wavesurfer.js';
import * as WaveSurferCursor from 'wavesurfer.js/dist/plugin/wavesurfer.cursor';
import * as WaveSurferRegion from 'wavesurfer.js/dist/plugin/wavesurfer.regions';
import * as WaveSurferTimeline from 'wavesurfer.js/dist/plugin/wavesurfer.timeline';

import PlayButton from '../PlayButton/PlayButton';

import styles from './WaveSurfer.module.scss';

interface WavesurferProps {
  file?: File; // passed in for uploads
  url?: string; // passed in when the url is hosted
  display?: boolean;
  onTrim: (trimTimes: any) => void;
  onZoom: (zoom: number) => void;
  defaultZoom: number;
  defaultSeek?: number;
  defaultTrimTimes?: [number, number];
  onReady?: () => void;
  extraButtons?: React.ReactNode;
  width?: string;
}

const Wavesurfer = React.forwardRef<HTMLDivElement, WavesurferProps>(
  (
    {
      file,
      url,
      display,
      defaultZoom,
      defaultTrimTimes = [undefined, undefined],
      onTrim,
      onZoom,
      onReady,
      defaultSeek = 0,
      extraButtons,
      width,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const wavesurfer = React.useRef<typeof WaveSurfer>(null);
    const [wavesurferReady, setWavesurferReady] = React.useState(false);
    const [isPlaying, setIsPlaying] = React.useState(false);
    const REGION_ID = 'audioTrimRegion';

    const getWavesurferPosition = (time: number) => {
      return time / wavesurfer.current.getDuration();
    };

    const getWavesurferTimeData = React.useCallback(() => {
      const startTime = wavesurfer.current.regions.list[REGION_ID].start;
      const startPosition = getWavesurferPosition(startTime);
      const endTime = wavesurfer.current.regions.list[REGION_ID].end;
      const endPosition = getWavesurferPosition(endTime);
      const currentTime = wavesurfer.current.getCurrentTime();
      const currentPosition = getWavesurferPosition(currentTime);
      return {
        startTime,
        startPosition,
        endTime,
        endPosition,
        currentTime,
        currentPosition,
      };
    }, []);

    React.useEffect(() => {
      let ready = false;
      if (!wavesurfer.current) {
        wavesurfer.current = WaveSurfer.create({
          backend: 'WebAudio',
          backgroundColor: 'hsl(0deg, 0%, 30%, 0.35)',
          barGap: 3,
          barRadius: 3,
          barWidth: 3,
          container: '.wave',
          cursorWidth: 1,
          fillParent: true,
          height: 150,
          loopSelection: false,
          minPxPerSec: 0,
          normalize: true,
          partialRender: true,
          pixelRatio: 1,
          progressColor: '#6351D8',
          responsive: true,
          scrollParent: false,
          waveColor: '#6351D8',

          plugins: [
            WaveSurferRegion.create({}),
            WaveSurferCursor.create({
              showTime: true,
              opacity: 0.9,
              customShowTimeStyle: {
                padding: '4px',
                backgroundColor: '#666666',
                color: '#ffffff',
                fontSize: '12px',
                position: 'absolute',
                top: 0,
              },
            }),
            WaveSurferTimeline.create({
              container: '.waveTimeline',
            }),
          ],
        });

        if (wavesurfer.current && url) {
          wavesurfer.current.load(url);
        }

        if (wavesurfer.current && file) {
          const reader = new FileReader();
          reader.onload = () => {
            const blob = new window.Blob([
              new Uint8Array(reader.result as ArrayBuffer),
            ]);
            wavesurfer.current.loadBlob(blob);
          };
          reader.readAsArrayBuffer(file);
        }

        wavesurfer.current.on('ready', () => {
          onReady && onReady();
          setWavesurferReady(true);
          ready = true;
        });

        wavesurfer.current.on(
          'seek',
          debounce((seekPosition: number) => {
            const { startPosition, endPosition } = getWavesurferTimeData();
            if (seekPosition < startPosition || seekPosition > endPosition) {
              // Move the seek to within the region
              wavesurfer.current.seekTo(startPosition);
            }
          }, 50)
        );

        wavesurfer.current.on(
          'region-updated',
          debounce(() => {
            const {
              startTime,
              startPosition,
              endTime,
              endPosition,
              currentPosition,
            } = getWavesurferTimeData();
            const trimTimes = [startTime, endTime];
            if (
              currentPosition < startPosition ||
              currentPosition > endPosition
            ) {
              // Move the seek to within the region
              wavesurfer.current.seekTo(startPosition);
            }
            onTrim(trimTimes);
          }, 100)
        );
      }
      return () => {
        if (ready) {
          wavesurfer.current.destroy();
          onReady && onReady();
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resetAudio = React.useCallback(() => {
      const { startTime, startPosition, endTime, currentTime } =
        getWavesurferTimeData();
      if (currentTime < startTime) {
        wavesurfer.current.seekTo(startPosition);
      } else if (currentTime.toFixed() >= endTime.toFixed()) {
        wavesurfer.current.seekTo(startPosition);
        wavesurfer.current.pause();
        setIsPlaying(false);
      }
    }, [getWavesurferTimeData]);

    const addRegion = React.useCallback(() => {
      wavesurfer.current.clearRegions();
      wavesurfer.current.addRegion({
        start: defaultTrimTimes[0] ?? 0,
        end: defaultTrimTimes[1] ?? wavesurfer.current.getDuration(),
        color: '#ffffff',
        drag: true,
        loop: false,
        minLength: 0.1,
        resize: true,
        id: REGION_ID,
      });

      const initialZoom =
        defaultTrimTimes && defaultTrimTimes[0] && url // Prevent file upload auto zoom
          ? Math.min(
              36 -
                (36 * (defaultTrimTimes[1] - defaultTrimTimes[0])) /
                  wavesurfer.current.getDuration(),
              35
            ) ?? defaultZoom
          : defaultZoom;

      wavesurfer.current.zoom(initialZoom);
      wavesurfer.current.seekTo(
        getWavesurferPosition(defaultTrimTimes[0] ?? defaultSeek)
      );

      wavesurfer.current.regions.list[REGION_ID].on('out', () => {
        resetAudio();
      });
      if (initialZoom !== defaultZoom) {
        onZoom(defaultZoom);
      }
    }, [defaultSeek, defaultTrimTimes, defaultZoom, onZoom, resetAudio, url]);

    const changeRegionAppearance = React.useCallback(() => {
      const regionHandles = document.getElementsByClassName(
        'wavesurfer-handle'
      ) as HTMLCollectionOf<HTMLElement>;
      Array.from(regionHandles).forEach((handle) => {
        handle.style.width = '5px';
        handle.style.backgroundColor = '#29a847';
      });

      const wavesurferRegion = document.getElementsByClassName(
        'wavesurfer-region'
      ) as HTMLCollectionOf<HTMLElement>;
      wavesurferRegion[0].style.zIndex = '1';
    }, []);

    React.useEffect(() => {
      if (wavesurferReady) {
        if (display) {
          addRegion();
          changeRegionAppearance();
        } else {
          wavesurfer.current.regions.list[REGION_ID]?.remove();
          wavesurfer.current.pause();
          setIsPlaying(false);
        }
      }
    }, [addRegion, changeRegionAppearance, display, wavesurferReady]);

    const playAudio = () => {
      console.log('play');
      const { startTime, startPosition, endTime, currentTime } =
        getWavesurferTimeData();
      console.log(startTime, startPosition, endTime, currentTime);
      if (currentTime < startTime || currentTime > endTime) {
        wavesurfer.current.seekTo(startPosition);
      } else {
        setIsPlaying(!isPlaying);
        wavesurfer.current.playPause();
      }
    };

    const adjustZoomLevel = debounce((event: any) => {
      console.log('zoom');
      const zoom = clone(event.target.value);
      onZoom && onZoom(zoom);
    }, 100);

    return (
      <div className="waveContainer" ref={ref}>
        <div
          data-testid="wavesurfer-display"
          style={
            display
              ? { width: width ?? '100%' } // Allow width to be responsive to screen size changes
              : {
                  position: 'absolute',
                  left: '-9999px',
                  width: width ?? 'calc(100% - 10px)',
                } // Hide wavesurfer component offscreen, but render it with largest dimensions
          }
        >
          <div className={cx('waveTimeline', styles.timeline)}></div>
          <div className={cx('wave', styles.wavesurfer)} />
          <div className={cx(styles.zoomSlider)}>
            <label className={cx(styles.zoomLabel)} htmlFor="zoomSlider">
              {t('audio_player.minus')}
            </label>
            <input
              type="range"
              name="zoomSlider"
              min="0"
              max="35"
              defaultValue={0}
              onChange={adjustZoomLevel}
            />
            <label className={cx(styles.zoomLabel)} htmlFor="zoomSlider">
              {t('audio_player.plus')}
            </label>
          </div>
          <div className={cx('buttonContainer', styles.buttonContainer)}>
            <PlayButton
              data-testid="wavesurfer-play-button"
              className={cx(styles.waveButton)}
              onClick={playAudio}
              isPlaying={isPlaying}
            />
            {extraButtons}
          </div>
        </div>
      </div>
    );
  }
);

export default Wavesurfer;
