import * as React from 'react';
import { useTranslation } from 'react-i18next';

import EmptyRect from 'src/components/core/EmptyRect/EmptyRect';
import LoadingOverlay from 'src/components/core/LoadingOverlay/LoadingOverlay';
import SnippetCard from 'src/components/SnippetCard/SnippetCard';
import {
  NormalizedTopicSnippets,
  Topic,
  TopicSnippet,
} from 'src/types/conversation';

interface Props {
  topicSnippets: NormalizedTopicSnippets;
  activeTopic: Topic;
  relatedTopic: Topic | null;
  isLoading: boolean;
  primaryTopicClassName?: string;
  secondaryTopicClassName?: string;
}
const TopicSnippets = ({
  topicSnippets,
  activeTopic,
  relatedTopic,
  isLoading,
  primaryTopicClassName,
  secondaryTopicClassName,
}: Props) => {
  const { t } = useTranslation();
  if (isLoading) {
    return (
      <div className="position-relative" style={{ minHeight: '80vh' }}>
        <LoadingOverlay bgColor="transparent" active />
      </div>
    );
  }

  if (!topicSnippets || Object.keys(topicSnippets).length === 0) {
    return (
      <EmptyRect height={150} testid="no-keywords">
        {t('topics.no_excerpts_found')}
      </EmptyRect>
    );
  }

  return (
    <div>
      {Object.values(topicSnippets).map((snippet: TopicSnippet) => {
        let emphasizedTerms = snippet.matched_terms.map((t) => t.term);
        let primaryTerms: string[] = [];
        let secondaryTerms: string[] = [];
        // if there's a related topic, we should show primary and secondary terms
        // instead of emphasized terms
        if (relatedTopic) {
          emphasizedTerms = [];
          primaryTerms = snippet.matched_terms
            .filter((t) => t.topic_id === activeTopic.id)
            .map((t) => t.term);
          secondaryTerms = snippet.matched_terms
            .filter((t) => t.topic_id === relatedTopic.id)
            .map((t) => t.term);
        }

        return (
          <SnippetCard
            snippet={snippet}
            key={snippet.id}
            emphasizedTerms={emphasizedTerms}
            primaryTerms={primaryTerms}
            secondaryTerms={secondaryTerms}
            primaryTopicClassName={primaryTopicClassName}
            secondaryTopicClassName={secondaryTopicClassName}
          />
        );
      })}
    </div>
  );
};

export default TopicSnippets;
