import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup, FormText, Input, Label } from 'reactstrap';
import { FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';

import LoadingSpinner from 'src/components/core/LoadingSpinner/LoadingSpinner';
import LanguageOptions from 'src/components/I18n/LanguageOptions';
import { EditTransaction } from 'src/redux/auth/auth-slice';
import { User } from 'src/types/auth';

interface FormProps {
  defaultCollectionId: number | undefined;
  user: User;
  userLanguage: string;
}

interface UserPreferencesProps {
  user: User;
  chapterTransaction?: EditTransaction;
  languageTransaction?: EditTransaction;
  onUpdateUser: (editTransaction: EditTransaction) => void;
}

const InnerForm = (props: UserPreferencesProps & FormikProps<FormProps>) => {
  const { t } = useTranslation();
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    user,
    chapterTransaction,
    languageTransaction,
  } = props;

  const { defaultCollectionId } = values;
  const { userLanguage } = values;

  return (
    <div>
      <form>
        <FormGroup>
          <Label for="defaultCollectionId">
            <span className="me-1">{t('settings.pref_chapter')}</span>
            <LoadingSpinner active={!!chapterTransaction} />
          </Label>
          <div>
            <Input
              type="select"
              name="defaultCollectionId"
              id="defaultCollectionId"
              value={defaultCollectionId}
              className="w-75 d-inline-block mt-1"
              onChange={(value) => {
                handleChange(value);
                // https://github.com/jaredpalmer/formik/issues/1218#issuecomment-481707848
                setTimeout(handleSubmit, 0);
              }}
              onBlur={handleBlur}
            >
              {defaultCollectionId != null && (
                <option value={undefined}>{t('settings.select')}</option>
              )}
              {user.collections.map((collection) => {
                return (
                  <option key={collection.id} value={collection.id}>
                    {collection.title}
                  </option>
                );
              })}
            </Input>{' '}
          </div>
          <FormText>{t('settings.pref_chapter_helper')}</FormText>
          {/* TODO: email/notification preferences */}
          <Label for="userLanguage" className={'mt-2'}>
            <span className="me-1">{t('settings.languages')}</span>
            <LoadingSpinner active={!!languageTransaction} />
          </Label>
          <div>
            <Input
              type="select"
              name="userLanguage"
              id="userLanguage"
              data-testid="user-language"
              value={userLanguage}
              className="w-50 d-inline-block mt-1"
              onChange={(value) => {
                handleChange(value);
                console.log('handling change');
                // https://github.com/jaredpalmer/formik/issues/1218#issuecomment-481707848
                setTimeout(handleSubmit, 0);
              }}
              onBlur={handleBlur}
            >
              <LanguageOptions />
            </Input>
          </div>
          <FormText>{t('settings.pref_language_helper')}</FormText>
        </FormGroup>
      </form>
    </div>
  );
};

const UserPreferences = withFormik<UserPreferencesProps, FormProps>({
  // set initial values based on user
  mapPropsToValues: ({ user }: UserPreferencesProps) => ({
    defaultCollectionId: user.preferences
      ? user.preferences.default_collection_id
      : undefined,
    user: user,
    userLanguage: user.language,
  }),

  validationSchema: Yup.object().shape({
    defaultCollectionId: Yup.number(),
    userLanguage: Yup.string(),
  }),

  handleSubmit({ defaultCollectionId, userLanguage }: FormProps, { props }) {
    if (
      defaultCollectionId != null &&
      props.user.preferences?.default_collection_id !== defaultCollectionId
    ) {
      // Only run this when default collection Id has been updated
      props.onUpdateUser({
        userId: props.user.id,
        changes: {
          preferences: { default_collection_id: defaultCollectionId },
        },
        editType: 'chapter',
      });
    }

    if (props.user.language !== userLanguage) {
      // Only run this when language preference has been updated
      props.onUpdateUser({
        userId: props.user.id,
        changes: {
          language: userLanguage,
        },
        editType: 'language',
      });
    }
  },

  validateOnBlur: true,
  enableReinitialize: true,
})(InnerForm);

export default UserPreferences;
