/*
 * Various formatting functions
 */

function leftPad(num: number) {
  if (num < 10) {
    return `0${num}`;
  }

  return `${num}`;
}

export function formatTime(time: number | undefined) {
  if (time == null) {
    return '';
  }

  const hours = Math.floor(time / (60 * 60));
  const minutes = Math.floor((time % (60 * 60)) / 60);
  const seconds = Math.floor(time % 60);

  const parts = [minutes, seconds];
  if (hours > 0) {
    parts.unshift(hours);
  }

  // don't leftpad biggest number
  return parts.map((d, i) => (i > 0 ? leftPad(d) : d)).join(':');
}

/**
 * Converts bytes to human readable time
 * Taken from https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string/10420404
 * @param bytes number of bytes to convert
 * @param si boolean indicating whether or not to use SI unit symbols
 */
export function humanFileSize(bytes: number, si = true) {
  const thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }
  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1) + ' ' + units[u];
}
