// This file is nearly the same as useSeekTimeScroll. We decided to make this file independent from
// that file so that the transcript editor stuff can remain independent of the transcript.
import * as React from 'react';
import throttle from 'lodash.throttle';

import { getRange, secondsToMilliseconds } from 'src/util/slate';
import smoothScroll from 'src/util/smooth-scroll';

// Interval in miliseconds
const AUDIO_RANGE_INTERVAL = 100;

// this is needed since the smooth scroller cancels animations if it detects an
// unexpected change in the scrollTop (e.g. the user scrolled). This means
// that if animation A is playing then we try to start animation B, anim B gets
// canceled.
const scroller = throttle(
  (
    scrollContainer,
    newTop,
    duration,
    onScrollInterruption = () => {
      return;
    },
    onScrollCompletion = () => {
      return;
    }
  ) =>
    smoothScroll(scrollContainer, newTop, duration)
      .then(onScrollCompletion)
      .catch(onScrollInterruption), // call onScrollInterruption handler when the element is manually scrolled if one is defined
  350,
  { leading: true, trailing: true }
);

// extracted from jQuery - gets the pixel offset this element is drawn on screen at
// the current window scroll level
function getOffset(node: HTMLElement) {
  const rect = node.getBoundingClientRect();
  return {
    top: rect.top + window.pageYOffset,
    left: rect.left + window.pageXOffset,
  };
}

function scrollToChunk(
  scrollContainerRef: React.RefObject<HTMLElement>,
  seekTime?: number,
  onScrollInterruption?: () => void | undefined,
  onScrollCompletion?: () => void | undefined
) {
  const scrollContainer = scrollContainerRef.current;
  if (scrollContainer) {
    // Try to set the chunk to find the focused search term
    let chunk: HTMLElement | null = scrollContainer.querySelector(
      '[data-slate-leaf="true"][data-focused-search="true"]'
    );
    if (!chunk && seekTime) {
      // If search term is not found, try to find seekTime chunk
      const seekTimeInMilliseconds = secondsToMilliseconds(seekTime);
      const currentTime = getRange(
        seekTimeInMilliseconds,
        seekTimeInMilliseconds + AUDIO_RANGE_INTERVAL,
        AUDIO_RANGE_INTERVAL
      )[0];
      chunk = scrollContainer.querySelector(
        `[data-slate-leaf="true"][data-audio-range*=",${currentTime},"]`
      );
    }

    // It's possible we have no chunk, e.g. if the search term returns no value(s) or seekTime is not in audio range
    // in which case, we should return to 0.
    let newTop;
    if (chunk) {
      const offset = 67; // leave some space to show there may be things above it.
      // getOffset will give us the y position of the chunk on the screen (even if clipped)
      // We add the current scrolltop to the new position to get the delta between the chunk top
      // and the container top if the container were scrolled to the very top.
      // This is the new scrollTop
      const chunkTop = getOffset(chunk).top;
      const containerTop = getOffset(scrollContainer).top;
      newTop = Math.max(
        0,
        chunkTop + scrollContainer.scrollTop - containerTop - offset
      );

      // scroll to top if seekTime is near beginning and we have no chunk
    } else if (!seekTime || seekTime < 30) {
      newTop = 0;
    }

    // if we have a new top to set and it doesn't equal the current scroll top
    if (newTop != null && newTop !== scrollContainer.scrollTop) {
      return scroller(
        scrollContainer,
        newTop,
        300,
        onScrollInterruption,
        onScrollCompletion
      );
    } else {
      // call the callback even if a scroll did not occur
      onScrollCompletion && onScrollCompletion();
    }
  }
}

export function useTranscriptScroll(
  scrollContainerRef: React.RefObject<HTMLElement>,
  seekTime?: number,
  searchIndex?: number,
  onScrollInterruption?: () => void | undefined,
  autoScroll?: boolean
) {
  React.useEffect(() => {
    if (autoScroll) {
      scrollToChunk(scrollContainerRef, seekTime, onScrollInterruption);
    }
  }, [
    scrollContainerRef,
    seekTime,
    onScrollInterruption,
    autoScroll,
    searchIndex,
  ]);
  const scroll = React.useCallback(
    (time: number, onScrollCompletion?: () => void) => {
      scrollToChunk(
        scrollContainerRef,
        time,
        onScrollInterruption,
        onScrollCompletion
      );
    },
    [onScrollInterruption, scrollContainerRef]
  );
  return scroll;
}
