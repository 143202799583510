import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import { HighlightOffRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  DialogTitle,
  Fade,
  IconButton,
  Paper,
  Stack,
  Typography,
} from '@mui/material';

import { setCookieAcceptance } from 'src/redux/auth/auth-slice';

import styles from './CookieBanner.module.scss';

const CookieBanner = ({ dispatch }: DispatchProp) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(true);

  const handleReject = () => {
    localStorage.setItem('userCookieSelectionChoice', 'false');
    dispatch(setCookieAcceptance(false));
  };

  const handleAccept = () => {
    localStorage.setItem('userCookieSelectionChoice', 'true');
    dispatch(setCookieAcceptance(true));
  };

  return (
    <Fade appear={false} in={open}>
      <Paper
        className={styles.cookieBannerContainer}
        role="dialog"
        tabIndex={-1}
      >
        <Box data-testid="cookie-banner">
          <DialogTitle sx={{ padding: '16px 32px' }}>
            <Stack direction="row" sx={{ alignItems: 'center' }}>
              <Typography sx={{ fontSize: '24px' }}>
                {t('cookie_banner.banner_title')}
              </Typography>
              <IconButton
                onClick={() => setOpen(false)}
                sx={{ color: '#11112B', marginLeft: 'auto' }}
              >
                <HighlightOffRounded />
              </IconButton>
            </Stack>
          </DialogTitle>
          <div className={styles.cookieBannerText}>
            <Typography>{t('cookie_banner.banner_text')}</Typography>
          </div>
        </Box>
        <div className={styles.cookieBannerActions}>
          <Stack sx={{ gap: 2 }} direction="row">
            <Button
              className={styles.cookieBannerRejectButton}
              onClick={() => handleReject()}
              variant="outlined"
            >
              {t('cookie_banner.reject')}
            </Button>
            <Button
              className={styles.cookieBannerAcceptButton}
              color="primary"
              onClick={() => handleAccept()}
              variant="contained"
            >
              {t('cookie_banner.accept')}
            </Button>
          </Stack>
        </div>
      </Paper>
    </Fade>
  );
};

export default connect()(CookieBanner);
