import { Conversation, Highlight } from 'src/types/conversation';

/**
 * Determine if the conversation a highlight belongs to has "protected" privacy_level.
 * First checks for a nested highlight, then passed in conversation.
 * Returns undefined if no conversation present.
 */
export const isConversationProtected = (
  highlight: Highlight | Pick<Highlight, 'conversation'> | undefined,
  conversation?: Conversation
) => {
  if (highlight && highlight.conversation) {
    return highlight.conversation.privacy_level === 'protected';
  } else if (conversation) {
    return conversation.privacy_level === 'protected';
  }

  // TODO: LVN-649 should report this https://cortico.atlassian.net/browse/LVN-649
  console.error('undefined conversation object for highlight', highlight);
  return undefined;
};
