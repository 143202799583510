import * as React from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import cx from 'classnames';

import Button from 'src/components/core/Button/Button';
import Stepper from 'src/components/Stepper/Stepper';
import { Conversation, Highlight } from 'src/types/conversation';
import ViewHighlightCard from './ViewHighlightCard/ViewHighlightCard';

import styles from './ViewHighlightsControl.module.scss';

export const ViewHighlightsControl = ({
  highlights,
  conversation,
  className,
  onChangeActiveHighlight,
}: {
  highlights: Highlight[];
  conversation?: Conversation;
  className?: string | undefined;
  onChangeActiveHighlight: (
    highlight: Highlight | undefined,
    removeIfMatch?: boolean
  ) => void;
}) => {
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const [currHighlightIndex, setCurrHighlightIndex] = React.useState(0);

  const highlight = highlights[currHighlightIndex];
  const popoverId = `highlights-popover-${highlights[0].id}`;
  const numHighlights = highlights.length;

  const handleChangeActiveHighlight = (index: number) => {
    setCurrHighlightIndex(index);
    onChangeActiveHighlight(highlights[index]);
  };

  return (
    <>
      <Button
        icon={['fas', 'comment']}
        id={popoverId}
        data-testid={'highlight-button-popover'}
        className={cx(className, [styles.highlightButton])}
        onClick={() => {
          onChangeActiveHighlight(highlight);
          setPopoverOpen(true);
        }}
      >
        {numHighlights > 1 ? `${numHighlights} Highlights` : 'Highlight'}
      </Button>
      <Popover
        trigger="legacy"
        placement="bottom-start"
        isOpen={popoverOpen}
        target={popoverId}
        toggle={() => {
          onChangeActiveHighlight(highlight, true);
          setPopoverOpen(false);
        }}
        hideArrow
        className={styles.viewHighlightPopover}
        delay={0}
      >
        <PopoverBody>
          <ViewHighlightCard
            highlight={highlight}
            conversation={conversation}
          />
          {numHighlights > 1 && (
            <Stepper
              numSteps={numHighlights}
              step={currHighlightIndex}
              onChange={handleChangeActiveHighlight}
              className={`text-center mt-3`}
            />
          )}
        </PopoverBody>
      </Popover>
    </>
  );
};

export const ViewHighlightControl = ({
  highlight,
  conversation,
  ...other
}: {
  highlight: Highlight;
  conversation?: Conversation;
  className?: string | undefined;
  onChangeActiveHighlight: (
    highlight: Highlight | undefined,
    removeIfMatch?: boolean
  ) => void;
}) => {
  return (
    <ViewHighlightsControl
      highlights={[highlight]}
      conversation={conversation}
      {...other}
    />
  );
};
