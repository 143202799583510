import * as React from 'react';
import Highlighter from 'react-highlight-words';
import AutosizeInput from 'react-input-autosize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { GetItemPropsOptions } from 'downshift';

import MultiDownshift, {
  RemoveButtonProps,
} from './MultiDownshift/MultiDownshift';

import './MultiSelect.scss';

export interface Option {
  label: string;
  value: string;
  score?: number;
  isNew?: boolean;
}

interface Props {
  className?: string | undefined;
  options: Option[];
  value: string[];
  enableAddNew: boolean;
  maxSelectedItems: number;
  onChange: (selectedItems: Option[]) => any;
}

interface State {
  focused: boolean;
}

class MultiSelect extends React.Component<Props, State> {
  static defaultProps = {
    enableAddNew: false,
    maxSelectedItems: 20,
  };
  state = {
    focused: false,
  };
  inputNode: AutosizeInput | null = null;
  containerNode = null;

  handleChange = (selectedItems: Option[]) => {
    const { onChange } = this.props;
    onChange(selectedItems);
  };

  itemToString() {
    return '';
  }

  handleKeyDown = (
    evt: React.KeyboardEvent<HTMLInputElement>,
    selectedItems: Option[],
    toggleItem: (item: Option) => any
  ) => {
    // remove last item on backspace.
    if (
      evt.key === 'Backspace' &&
      evt.currentTarget.selectionStart === 0 &&
      selectedItems.length
    ) {
      toggleItem(selectedItems[selectedItems.length - 1]);
    }
  };

  handleInputFocus = (isOpen: boolean, openMenu: () => any) => {
    if (!this.state.focused) {
      this.setState({ focused: true });
      if (!isOpen) {
        openMenu();
      }
    }
  };

  handleInputBlur = () => {
    if (this.state.focused) {
      this.setState({ focused: false });
    }
  };

  handleMouseDown = (evt: React.MouseEvent) => {
    // ignore right clicks which produce a weird bug if you prevent default on
    // them. The input thinks it is still in focus so making it refocus does
    // nothing, breaking the component until the user clicks outside.
    if (evt.button !== 0) {
      return;
    }

    // prevent default blurring/focusing of input field
    evt.preventDefault();

    // focus on the input field and show the focus border
    // if we are clicking on the button, do not double toggle the open menu:
    if (evt.target instanceof HTMLButtonElement) {
      // set focused to false first so we do not trigger openMenu
      this.setState({ focused: true }, () => {
        if (this.inputNode) {
          this.inputNode.getInput().focus();
        }
      });
    } else {
      // trigger normal focus behavior (sets state and opens menu)
      if (this.inputNode) {
        this.inputNode.getInput().focus();
      }
    }
  };

  renderSelectedItems(
    selectedItems: Option[],
    getRemoveButtonProps: (
      options?: RemoveButtonProps<Option> | undefined
    ) => RemoveButtonProps<Option>
  ) {
    // need to use a fragment here since they need to wrap naturally along
    // with the input field
    return (
      <React.Fragment>
        {selectedItems.length > 0 &&
          selectedItems.map((item) => (
            <div className="selected-value" key={item.value}>
              {item.label}{' '}
              <span
                {...getRemoveButtonProps({
                  item,
                  className: 'remove-selected',
                })}
              >
                &times;
              </span>
            </div>
          ))}
      </React.Fragment>
    );
  }

  renderAvailableOptions(
    highlightedIndex: number | null,
    isOpen: boolean,
    options: Option[],
    getItemProps: (props: GetItemPropsOptions<Option>) => any,
    selectedItems: Option[],
    inputValue: string | null,
    enableAddNew: boolean,
    maxSelectedItems: number
  ) {
    if (!isOpen) {
      return null;
    }

    if (selectedItems.length >= maxSelectedItems) {
      return (
        <div className="dropdown-menu d-block">
          <div className="dropdown-item disabled">Max allowable reached.</div>
        </div>
      );
    }

    const availableOptions = options.filter(
      (option) => !selectedItems.find((selOpt) => selOpt.value === option.value)
    );

    let filteredOptions;

    // filter based on typed in value
    if (inputValue != null && inputValue !== '') {
      const normalizedInputValue = inputValue.toLowerCase();

      filteredOptions = availableOptions.filter((option) =>
        option.label.toLowerCase().includes(normalizedInputValue)
      );
      // if we are able to add new ones and we haven't added this one yet, add it.
      if (
        enableAddNew &&
        !filteredOptions.find(
          (opt) => opt.value.toLowerCase() === normalizedInputValue
        )
      ) {
        const newOption: Option = {
          label: inputValue,
          value: inputValue,
          isNew: true,
        };
        filteredOptions.push(newOption);
      }
    } else {
      filteredOptions = availableOptions;
    }

    return (
      <div className="dropdown-menu d-block" data-testid="multiselect-options">
        {filteredOptions.map((item, index) => (
          <div
            key={item.value}
            data-testid={`multiselect-option-${item.value}`}
            {...getItemProps({
              item,
              index,
              className: cx('dropdown-item no-hover', {
                active: highlightedIndex === index,
              }),
            })}
          >
            {item.isNew && (
              <FontAwesomeIcon
                icon={['far', 'plus']}
                className="me-2 text-primary"
              />
            )}
            <Highlighter
              highlightClassName="highlighted"
              unhighlightClassName="unhighlighted"
              searchWords={inputValue == null ? [] : [inputValue]}
              textToHighlight={item.label}
            />
          </div>
        ))}
        {!filteredOptions.length && (!enableAddNew || !inputValue) && (
          <div className="dropdown-item disabled">No matches.</div>
        )}
      </div>
    );
  }

  render() {
    const {
      className,
      options,
      value,
      enableAddNew,
      maxSelectedItems,
      ...other
    } = this.props;
    const { focused } = this.state;
    const valuesAsItems: Option[] = value
      .map((v) => options.find((o) => o.value === v))
      .filter((d) => d != null) as Option[];

    return (
      <div className={cx('MultiSelect', className, { focus: focused })}>
        <MultiDownshift<Option>
          itemToString={this.itemToString}
          selectedItems={valuesAsItems}
          {...other}
          onChange={this.handleChange}
        >
          {({
            getToggleButtonProps,
            getRemoveButtonProps,
            selectedItems,
            toggleItem,
            isOpen,
            getItemProps,
            getInputProps,
            highlightedIndex,
            inputValue,
            openMenu,
          }) => (
            <div
              className="multi-select-container"
              onMouseDown={this.handleMouseDown}
            >
              <div className="inputs">
                {this.renderSelectedItems(selectedItems, getRemoveButtonProps)}
                {/* @ts-ignore typescript 4.x^ doesn't like the input props, not sure why */}
                <AutosizeInput
                  {...getInputProps({
                    onKeyDown: (evt: React.KeyboardEvent<HTMLInputElement>) => {
                      this.handleKeyDown(evt, selectedItems, toggleItem);
                    },
                    onFocus: () => {
                      this.handleInputFocus(isOpen, openMenu);
                    },
                    onBlur: this.handleInputBlur,
                  })}
                  ref={(node: any /* AutosizeInput */) =>
                    (this.inputNode = node)
                  }
                  data-testid="multiselect-input"
                />
              </div>
              <div className="controls">
                <button
                  tabIndex="-1"
                  className="btn dropdown-toggle"
                  data-testid="multiselect-toggle"
                  {...getToggleButtonProps()}
                />
              </div>
              {this.renderAvailableOptions(
                highlightedIndex,
                isOpen,
                options,
                getItemProps,
                selectedItems,
                inputValue,
                enableAddNew,
                maxSelectedItems
              )}
            </div>
          )}
        </MultiDownshift>
      </div>
    );
  }
}

export default MultiSelect;
