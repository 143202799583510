import * as React from 'react';
import cx from 'classnames';

import './Tag.scss';

export interface Props {
  children: React.ReactNode;
  className?: string;
  outline?: boolean;
  outlineWhiteBg?: boolean;
  color?: string;
  pale?: boolean;
  size?: string;
  animation?: boolean;
  uppercase?: boolean;
  tagName?: string;
}

class Tag extends React.Component<Props> {
  static defaultProps = { pale: true };
  render() {
    const {
      children,
      className,
      size,
      color,
      pale,
      outline,
      outlineWhiteBg,
      animation,
      uppercase,
      ...other
    } = this.props;

    const withAddons = React.Children.count(children) > 1;

    return (
      <div
        {...other}
        className={cx('Tag', className, {
          'no-animation': animation === false,
          'tag-sm': size === 'sm',
          'tag-lg': size === 'lg',
          'with-addons': withAddons,
          'tag-inner': !withAddons,
          'tag--pale': pale,
          [`tag-${color || ''}`]: color && !outline,
          [`tag-outline-${color || ''}`]: color && outline,
          'tag-outline-bg-white': color && outline && outlineWhiteBg,
          uppercase,
        })}
      >
        {children}
      </div>
    );
  }
}

export default Tag;
