import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import Button from 'src/components/core/Button/Button';
import { AnnotationPrivacyLevel } from 'src/types/conversation';

interface Props {
  privacyLevel: AnnotationPrivacyLevel;
  className?: string;
}
export const HighlightHelperText = ({ privacyLevel, className }: Props) => {
  if (privacyLevel === 'private') {
    return (
      <div
        className={cx(
          className,
          'd-flex align-items-baseline justify-content-between'
        )}
      >
        <span>
          <FontAwesomeIcon
            icon={['fas', 'exclamation-triangle']}
            className="me-2"
          />
        </span>{' '}
        This highlight is private, only you can see this link.
      </div>
    );
  }
  return <></>;
};

interface EmbedHelperProps extends Props {
  onChangePrivacy: () => void;
}

export const EmbedHelperText = ({
  privacyLevel,
  onChangePrivacy,
  className,
}: EmbedHelperProps) => {
  const { t } = useTranslation();
  return (
    <div className={className}>
      {privacyLevel === 'public' ? (
        t('highlight.embed_helper_public')
      ) : (
        <Trans
          i18nKey="highlight.embed_helper"
          components={{
            1: (
              <Button color="link" size="sm" onClick={onChangePrivacy}>
                change the visibility
              </Button>
            ),
          }}
        />
      )}
    </div>
  );
};
