import * as React from 'react';

import { FilterOption } from 'src/types/core';
import ClearableTag from '../ClearableTag/ClearableTag';

interface Props {
  activeFilterOptions: (FilterOption | undefined)[] | undefined;
  onClear: (option: FilterOption) => void;
}

const FilterTags = ({ activeFilterOptions, onClear }: Props) => {
  if (!activeFilterOptions) {
    return null;
  }
  return (
    <>
      {activeFilterOptions.map((option) => {
        if (!option) {
          return null;
        }

        return (
          <ClearableTag
            color="secondary"
            pale={false}
            uppercase
            className="me-1 mb-1"
            onClear={() => onClear(option)}
            key={option.name}
            data-testid={`${option.name}-tag`}
            tagName={option.name}
          >
            {option.name}
          </ClearableTag>
        );
      })}
    </>
  );
};

export default FilterTags;
