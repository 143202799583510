import * as React from 'react';
import { useTranslation } from 'react-i18next';

import ConversationCards from 'src/components/ConversationCards/ConversationCards';
import Layout from 'src/components/Layout/Layout';

// import styles from './ConversationsRoute.module.scss';

const ConversationsRoute = () => {
  const { t } = useTranslation();
  return (
    <Layout className="ConversationsRoute" title={t('main_nav.conversations')}>
      <div className="container">
        <h1 className="h2">{t('conversations.header')}</h1>
        <ConversationCards />
      </div>
    </Layout>
  );
};

export default ConversationsRoute;
