import * as React from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

import Color from 'src/assets/_util.scss';
import { SparkleIcon } from 'src/components/core/Icon/SparkleIcon';
import { selectors as catalogSelectors } from 'src/redux/catalog/catalog-selectors';
import { StoreState } from 'src/redux/store';
import { AbstractCode, CodeTag } from 'src/types/insights';
import { getFontColor } from '../utils/color';

interface BaseProps {
  code: AbstractCode | CodeTag;
  noCount?: boolean;
  suggested?: boolean;
  selected?: boolean;
}

interface StateProps {
  count: number;
}

type Props = BaseProps & StateProps;

const mapStateToProps = (state: StoreState, props: BaseProps): StateProps => ({
  count: catalogSelectors.getCodeCount(state, props.code),
});

const CodeChip: React.FunctionComponent<Props> = (props: Props) => {
  const { code, count, noCount, suggested, selected } = props;
  const { name, description, color } = code;

  const label = noCount ? (
    name
  ) : (
    <Trans
      i18nKey={'insights.code_chip_label'}
      values={{ name, count }}
      components={{ bold: <strong /> }}
    ></Trans>
  );
  const fontColor = getFontColor(color) ?? 'black';
  const tentative = suggested && !selected;

  return (
    <Tooltip arrow title={description || undefined}>
      <Chip
        component="span"
        data-testid="CodeChip"
        label={label}
        size="small"
        icon={
          suggested ? (
            <SparkleIcon
              color={tentative ? color : Color.white}
              style={{ width: '12px', aspectRatio: '1/1', marginRight: '0' }}
            />
          ) : undefined
        }
        sx={{
          backgroundColor: tentative ? 'transparent' : color,
          color: tentative ? color : fontColor,
          border: `1px ${tentative ? 'dashed' : 'solid'} ${color}`,
          px: '6px',
          py: '4px',
          fontSize: '13px',
          lineHeight: '18px',
        }}
      />
    </Tooltip>
  );
};

CodeChip.displayName = 'CodeChip';

export default connect(mapStateToProps)(CodeChip);
