import { User } from 'src/types/auth';
import { OrganizationMetadata } from 'src/types/organization';
import { getAudioUploadPermission } from 'src/util/user';

/**
 * Get the active collection in this order:
 * 1. If the passed in collection ID is valid (likely from a query param)
 * 2. Session storage (note: session storage is set by HomeRoute)
 * 3. User's default collection from user preferences
 * 4. First collection user has access to
 * 5. If user has no collections, returns undefined
 * If disableAllCollections is true, it will return the first active collection in the
 * provided order that is does not have the id -1, or return the second collection in the userCollections list.
 */
export const getActiveCollection = (
  collectionId: number | undefined,
  user: User,
  disableAllCollections?: boolean
) => {
  const userCollections = user.collections;
  const userDefaultCollectionId =
    user.preferences && user.preferences.default_collection_id;

  if (userCollections == null || userCollections.length <= 0) {
    return undefined;
  }
  // first check if we were passed a valid collection ID and return that collection if so
  const validCollection = userCollections.find(
    (collection) => collection.id === collectionId
  );
  if (
    validCollection != null &&
    !(disableAllCollections && validCollection.id === -1)
  ) {
    return validCollection;
  }

  // then check if there's something we can use in session storage
  const sessionStorageCollectionId =
    window.sessionStorage.getItem('collectionId');
  const collectionFromSessionStorage = userCollections.find(
    (collection) => collection.id.toString() === sessionStorageCollectionId
  );
  if (
    collectionFromSessionStorage != null &&
    !(disableAllCollections && collectionFromSessionStorage.id === -1)
  ) {
    return collectionFromSessionStorage;
  }

  // if not, grab the user's default collection
  const defaultCollection = userCollections.find(
    (collection) => collection.id === userDefaultCollectionId
  );
  if (
    defaultCollection != null &&
    !(disableAllCollections && defaultCollection.id === -1)
  ) {
    return defaultCollection;
  }

  // last resort: return first user collection
  if (disableAllCollections && userCollections[0].id === -1) {
    return userCollections[1];
  }
  return userCollections[0];
};

/**
 * Return an array of collections that a user has upload audio permissions for.
 */
export const getHostCollections = (user: User): User['collections'] => {
  const { collections, permissions } = user;
  const audioUploadPermission = getAudioUploadPermission('create');
  const uploadPermissions = permissions.filter(
    (perm) =>
      perm.operation === audioUploadPermission.operation &&
      perm.permission_type === audioUploadPermission.permission_type
  );

  // find matching collections and filter out undefineds
  // TS complains the output of find can also be undefined, so filter out the undefineds
  const hostCollections = uploadPermissions
    .map((perm) =>
      collections.find((collection) => collection.id === perm.collection_id)
    )
    .filter((collection) => collection != null) as User['collections'];

  return hostCollections;
};

/**
 * From a user's collections, get the organization metadata for the selected collection
 * @param hostCollections
 * @param organizationsMetadata
 * @param collectionId
 * @returns
 */
export const getActiveOrganizationMetadata = (
  hostCollections: User['collections'],
  organizationsMetadata: OrganizationMetadata[] | undefined,
  collectionId: number
) => {
  const selectedCollection = hostCollections.find(
    (col) => col.id === collectionId
  );
  if (organizationsMetadata && selectedCollection) {
    return organizationsMetadata.find(
      (org) => org.id === selectedCollection.organization_id
    );
  }
};
