import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Container } from 'reactstrap';
import moment from 'moment-timezone';
import { StringParam, useQueryParams } from 'use-query-params';

import { getShift, registerUserForShift } from 'src/api/api';
import LoadingSpinner from 'src/components/core/LoadingSpinner/LoadingSpinner';
import FormButton from 'src/components/FormButton/FormButton';
import FormError from 'src/components/FormError/FormError';
import FormStep, { FormContent } from 'src/components/FormStep/FormStep';
import Layout from 'src/components/Layout/Layout';
import RegistrationDetailBlock from 'src/components/RegistrationDetailBlock/RegistrationDetailBlock';
import ShiftChecker from 'src/components/RegistrationRoutes/ShiftChecker/ShiftChecker';
import registrationSelectors from 'src/redux/registration/registration-selectors';
import { loadUpcomingConversations } from 'src/redux/registration/registration-slice';
import { StoreState } from 'src/redux/store';
import { RegisteredConversation, Shift } from 'src/types/registration';
import dateFormatter from 'src/util/date';

import styles from '../RegistrationRoutes.module.scss';

const ShiftDetails = ({ shift }: { shift: Shift }) => {
  const { t } = useTranslation();
  const { job } = shift;
  const {
    timezone,
    name: conversationName,
    location_type: locationType,
    location_name: locationName,
    street,
    city,
    state_province: state,
    zipcode,
    campaign,
  } = job;
  const { name: campaignName, account } = campaign;
  const isVirtual = locationType === 'Virtual';

  const tz = timezone ? timezone : moment.tz.guess();
  // get the date in the tz
  const startDateTime = moment(shift.start_datetime).tz(tz);
  const startTime = dateFormatter.timeFromMoment(startDateTime, {
    shortTime: true,
  });
  // figure out the end time from the start time + duration
  const endTime = dateFormatter.timeFromMoment(
    startDateTime.clone().add(shift.duration_hours, 'hours'),
    {
      timeZone: true,
    }
  );

  return (
    <>
      <div>
        <h2 className="mb-1">{conversationName}</h2>
        {campaignName && (
          <h3 className="mb-2 h4" data-testid="campaign-details">
            {campaignName}
            {campaignName && (
              <>
                {' '}
                &#8226;{' '}
                <a href={account.website} data-testid="campaign-website">
                  {account.name}
                </a>
              </>
            )}
          </h3>
        )}
      </div>
      <RegistrationDetailBlock heading={t('confirm_signup.info_detail_1')}>
        <p className="mb-0 text-start">
          {dateFormatter.dateFormat(startDateTime, {
            includeDayName: true,
          })}
        </p>
        <p className="mb-0 text-start" data-testid="time">
          {startTime} - {endTime}
        </p>
      </RegistrationDetailBlock>
      <RegistrationDetailBlock
        heading={t('confirm_signup.info_detail_2')}
        data-testid="location-block"
      >
        {isVirtual ? (
          <p className="mb-0 text-start">
            {t('confirm_signup.info_detail_2_virtual')}
          </p>
        ) : (
          <>
            {locationName && <span>{locationName}</span>}
            <p className="mb-0 text-start">{street}</p>
            <p className="mb-1 text-start">
              {city}, {state} {zipcode}
            </p>
          </>
        )}
      </RegistrationDetailBlock>
    </>
  );
};

interface StateProps {
  registeredConversations: RegisteredConversation[];
  isLoadingRegisteredConversations: boolean;
  errorLoadingRegisteredConversations: Error | undefined;
}

/** Map state from redux to the components props */
const mapStateToProps = (state: StoreState): StateProps => ({
  registeredConversations:
    registrationSelectors.getRegisteredConversations(state),
  isLoadingRegisteredConversations:
    registrationSelectors.isLoadingRegisteredConversations(state),
  errorLoadingRegisteredConversations:
    registrationSelectors.errorLoadingRegisteredConversations(state),
});

type Props = RouteComponentProps & StateProps & DispatchProp;

const ConfirmSignupRoute = ({
  history,
  registeredConversations,
  isLoadingRegisteredConversations,
  errorLoadingRegisteredConversations,
  dispatch,
}: Props) => {
  const { t } = useTranslation();
  const [query] = useQueryParams({
    shiftId: StringParam,
  });
  const { shiftId } = query;
  const duplicateRegistration = registeredConversations.some(
    (conv) => conv.shift.id === shiftId
  );

  const [isLoadingShift, setIsLoadingShift] = React.useState(true);
  const [shift, setShift] = React.useState<Shift | undefined>(undefined);
  const [signupError, setSignupError] = React.useState(false);

  React.useEffect(() => {
    dispatch(loadUpcomingConversations());
  }, [dispatch]);

  React.useEffect(() => {
    if (shiftId) {
      getShift(shiftId)
        .then((res: Shift) => {
          setShift(res);
          setIsLoadingShift(false);
        })
        .catch(() => {
          setShift(undefined);
          setIsLoadingShift(false);
        });
    } else {
      setIsLoadingShift(false);
    }
  }, [shiftId]);

  const [isSaving, setIsSaving] = React.useState(false);
  const handleClick = () => {
    if (shiftId) {
      setIsSaving(true);
      registerUserForShift(shiftId)
        .then(() => {
          history.push('/');
        })
        .catch(() => {
          setIsSaving(false);
          setSignupError(true);
        });
    }
  };

  return (
    <ShiftChecker
      isLoading={isLoadingShift || isLoadingRegisteredConversations}
      shiftId={shiftId}
      shift={shift}
      title={t('confirm_signup.button')}
      shiftIdRequired
    >
      <Layout title={t('confirm_signup.button')} className={styles.layout}>
        <Container className={styles.container}>
          <FormStep hideBackBtn>
            <FormContent>
              <h1 className="mb-1">{t('confirm_signup.header')}</h1>
              {duplicateRegistration ? (
                <p className="mb-3">
                  {t('confirm_signup.signup_completed')}
                  <br />
                  <Trans
                    i18nKey="confirm_signup.signup_edit"
                    components={{
                      1: <Link to={`/`} data-testid="home-link" />,
                    }}
                  />
                </p>
              ) : (
                <p className="mb-3">{t('confirm_signup.subheader')}</p>
              )}
              <>{shift !== undefined && <ShiftDetails shift={shift} />}</>
            </FormContent>
            {(signupError || errorLoadingRegisteredConversations) && (
              <FormError name="sign-up">
                <Trans
                  i18nKey="confirm_signup.signup_error"
                  /*eslint-disable */
                  components={{
                    1: <a href="mailto:help@lvn.org" />,
                  }}
                  /*eslint-disable */
                />
              </FormError>
            )}
            {!duplicateRegistration && (
              <FormButton
                onClick={handleClick}
                disabled={isSaving}
                data-testid="sign-up-button"
              >
                {isSaving ? (
                  <>
                    <span className="me-2">
                      {t('confirm_signup.button_active')}
                    </span>{' '}
                    <LoadingSpinner active />
                  </>
                ) : (
                  t('confirm_signup.button')
                )}
              </FormButton>
            )}
          </FormStep>
        </Container>
      </Layout>
    </ShiftChecker>
  );
};

export default connect(mapStateToProps)(ConfirmSignupRoute);
