import * as React from 'react';
import { useHistory } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import { makeStyles } from '@mui/styles';

import { useCatalogPageContext } from 'src/components/Insights/Catalog/CatalogPageProvider';
import { deleteCatalog } from 'src/redux/catalog/catalog-slice';
import AddConversationsDialog from '../Dialogs/AddConversationsDialog';
import ConfirmationDialog from '../Dialogs/ConfirmationDialog';
import CreateCatalogDialog from '../Dialogs/CreateCatalogDialog';
import DeleteConversationsDialog from '../Dialogs/DeleteConversationsDialog';
import UpdateCatalogDialog from '../Dialogs/UpdateCatalogDialog';
import useDialogState from '../utils/dialog';

const useStyles = makeStyles(() => ({
  staticTooltipLabel: {
    whiteSpace: 'nowrap',
  },
}));

export default function CatalogSpeedDial() {
  const { catalog, conversations, showModal, dispatch, t } =
    useCatalogPageContext();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [dialogState, openDialog, closeDialog] = useDialogState();
  const history = useHistory();

  return (
    <>
      <Backdrop open={open} sx={{ backgroundColor: '#fffe', zIndex: 10 }} />
      <SpeedDial
        ariaLabel="SpeedDial"
        data-testid="SpeedDial"
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        sx={{ position: 'fixed', bottom: 24, left: 24, zIndex: 1200 }}
      >
        <SpeedDialAction
          classes={classes}
          icon={<DeleteForeverIcon />}
          onClick={openDialog('deleteCatalog')}
          tooltipOpen
          tooltipPlacement="right"
          tooltipTitle={t('insights.delete_catalog')}
        />
        <SpeedDialAction
          classes={classes}
          icon={<PlaylistRemoveIcon />}
          onClick={openDialog('deleteConversations')}
          tooltipOpen
          tooltipPlacement="right"
          tooltipTitle={t('insights.delete_conversations')}
        />
        <SpeedDialAction
          classes={classes}
          icon={<PlaylistAddIcon />}
          onClick={openDialog('addConversations')}
          tooltipOpen
          tooltipPlacement="right"
          tooltipTitle={t('insights.add_conversations')}
        />
        <SpeedDialAction
          classes={classes}
          icon={<EditIcon />}
          onClick={() =>
            showModal(<UpdateCatalogDialog />, { disableRestoreFocus: true })
          }
          tooltipOpen
          tooltipPlacement="right"
          tooltipTitle={t('insights.update_catalog')}
        />
        <SpeedDialAction
          classes={classes}
          icon={<AddCircleIcon />}
          onClick={openDialog('newCatalog')}
          tooltipOpen
          tooltipPlacement="right"
          tooltipTitle={t('insights.create_catalog')}
        />
      </SpeedDial>

      <CreateCatalogDialog
        closeDialog={closeDialog('newCatalog')}
        open={Boolean(dialogState['newCatalog'])}
      />
      {catalog && (
        <>
          <AddConversationsDialog
            catalog={catalog}
            closeDialog={closeDialog('addConversations')}
            conversations={conversations}
            open={Boolean(dialogState['addConversations'])}
          />
          <ConfirmationDialog
            closeDialog={closeDialog('deleteCatalog')}
            confirmationText="delete"
            description="Are you sure you want to delete this catalog?"
            onSuccess={() => {
              dispatch(
                deleteCatalog({
                  data: catalog.id,
                  callback: () => history.push('/insights'),
                })
              );
            }}
            open={Boolean(dialogState['deleteCatalog'])}
            submitText="Delete"
            title="Delete Catalog"
          />
          <DeleteConversationsDialog
            catalog={catalog}
            closeDialog={closeDialog('deleteConversations')}
            conversations={conversations}
            open={Boolean(dialogState['deleteConversations'])}
          />
        </>
      )}
    </>
  );
}
