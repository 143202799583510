import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

import styles from './CustomError.module.scss';

const CustomError = (props: { error: string }) => {
  return (
    <div className={styles.customErrorOverlay}>
      <div className={styles.errorInfoContainer}>
        <div tabIndex={0}>{props.error}</div>
        <Link to="/">
          <Button
            color="primary"
            size="sm"
            className={styles.okButton}
            role="button"
          >
            Ok
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default CustomError;
