import { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useMeasure } from 'react-use';
import { Typography } from '@mui/material';

import Color from 'src/assets/_util.scss';
import Button, {
  Props as ButtonProps,
} from 'src/components/core/Button/Button';
import { ContextMenu, useContextMenu } from 'src/components/core/ContextMenu';
import { HSpacer } from 'src/components/core/Spacer';
import { HStack } from 'src/components/core/Stack';
import { View } from 'src/components/core/View';
import { useHighlightsPageContext } from 'src/components/Insights/Catalog/HighlightsPageProvider';
import {
  StatusItem,
  StatusItems,
} from 'src/components/Insights/Catalog/status';
import { setEntriesStatus } from 'src/redux/catalog/catalog-slice';
import { useDelayedState } from 'src/util/useDelayed';

export function HighlightsPageSelectionToolbar() {
  const { selectedHighlightIds, canTagWithAI, clearSelection, t } =
    useHighlightsPageContext();

  const visible = selectedHighlightIds.size > 0;
  const AnimDuration = 200; // ms
  const [selectedHighlightCount] = useDelayedState(
    selectedHighlightIds.size,
    visible ? 0 : AnimDuration,
    [visible]
  );

  const [rootRef, { height }] = useMeasure();
  const padding = 16;

  return (
    <View
      sx={{
        overflow: 'hidden',
        transition: `all ${AnimDuration}ms ease-out`,
        height: visible ? height + 2 * padding : 0,
        filter: visible ? 'none' : 'blur(1px)',
        marginTop: visible ? '16px' : 0,
      }}
      data-testid="selection-toolbar"
    >
      <HStack
        sx={{
          alignItems: 'center',
          gap: '16px',
          background: Color.foraPurpleDark,
          borderRadius: '5px',
          color: Color.white,
          padding: `${padding}px`,
        }}
        ref={rootRef}
      >
        <Typography variant="body1" style={{ fontWeight: 600 }}>
          {t('insights.highlights_selected', { count: selectedHighlightCount })}
        </Typography>

        <ToolbarButton label="common.clear" onClick={clearSelection} />
        <HSpacer grow />
        {canTagWithAI && <SuggestCodesButton />}
        <UpdateStatusButton />
      </HStack>
    </View>
  );
}

function SuggestCodesButton() {
  const { selectedHighlightIds, clearSelection, tagWithAI } =
    useHighlightsPageContext();

  const onClick = () => {
    tagWithAI(Array.from(selectedHighlightIds), clearSelection);
  };

  return (
    <ToolbarButton label="insights.suggestCodesWithAI" onClick={onClick} />
  );
}

function UpdateStatusButton() {
  const {
    catalog,
    selectedHighlightIds,
    clearSelection,
    showBanner,
    dispatch,
    t,
  } = useHighlightsPageContext();

  const statusMenuProps = useContextMenu(StatusItems, (item: StatusItem) => {
    const entryIds = Array.from(selectedHighlightIds);
    dispatch(setEntriesStatus([catalog.id, entryIds, item.value]));
    clearSelection();

    showBanner({
      message: t('insights.status_updated', { count: entryIds.length }),
    });
  });
  const { open, setOpen, anchorRef } = statusMenuProps;

  return (
    <>
      <ToolbarButton
        label="insights.update_status"
        onClick={() => setOpen(true)}
        open={open}
        innerRef={anchorRef as RefObject<HTMLButtonElement>}
      />
      <ContextMenu {...statusMenuProps} />
    </>
  );
}

function ToolbarButton({ style, label, open, ...props }: ButtonProps) {
  const { t } = useTranslation();
  return (
    <Button
      style={{
        background: Color[open ? 'foraPurpleDarker' : 'foraPurple'],
        color: Color.white,
        borderColor: Color.foraPurpleLight,
        transitionDuration: '100ms',
        ...style,
      }}
      {...props}
    >
      {t(label)}
    </Button>
  );
}
