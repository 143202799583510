import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import { Link } from 'react-router-dom';
import { AutoSizer } from 'react-virtualized';
import cx from 'classnames';
import moment from 'moment-timezone';

import LoadingOverlay from 'src/components/core/LoadingOverlay/LoadingOverlay';
import TagList from 'src/components/Highlight/TagList/TagList';
import highlightCardStyles from 'src/components/HighlightCard/HighlightCard.module.scss';
import HighlightDropdown from 'src/components/HighlightDropdown/HighlightDropdown';
import HighlightFooter from 'src/components/HighlightFooter/HighlightFooter';
import HighlightModal from 'src/components/HighlightModal/HighlightModal';
import HighlightStarButton from 'src/components/HighlightStarButton/HighlightStarButton';
import HighlightTypeLabel from 'src/components/HighlightTypeLabel/HighlightTypeLabel';
import Panel from 'src/components/Panel/Panel';
import SpeakerTranscriptRoll from 'src/components/SpeakerTranscriptRoll/SpeakerTranscriptRoll';
import TopicTag from 'src/components/TopicTag/TopicTag';
import TruncatedParagraph from 'src/components/TruncatedParagraph/TruncatedParagraph';
import { mapbox } from 'src/config';
import GlobalAudioContext, {
  useSeekAudio,
} from 'src/contexts/GlobalAudioContext';
import { selectors as authSelectors } from 'src/redux/auth/auth-selectors';
import { editHighlight } from 'src/redux/highlight/highlight-slice';
import { StoreState } from 'src/redux/store';
import { selectors as topicsSelectors } from 'src/redux/topics/topics-selectors';
import { User } from 'src/types/auth';
import {
  Conversation,
  Highlight,
  Host,
  Snippet,
  Topics,
} from 'src/types/conversation';
import {
  isActiveAudio,
  mapSeekTimeToConversation,
  mapSeekTimeToHighlight,
} from 'src/util/audio';
import dateFormatter from 'src/util/date';
import { getHighestProbabilityTopic } from 'src/util/topics';

import styles from './ExpandedHighlightCard.module.scss';

const ConversationMap = React.lazy(
  () => import('src/components/ConversationMap/ConversationMap')
);

interface BaseProps {
  tabIndex?: number;
  highlight: Highlight;
  /** if provided, use this snippets list for the transcript, otherwise highlight.snippets */
  snippets?: Snippet[] | undefined;
  /** if provided, use this conversation, otherwise highlight.conversation */
  conversation?: Conversation | undefined;
  className?: string | undefined;

  /** True if shown in a conversation details page, so we don't link to the conversation */
  inConversation?: boolean;

  /** In conversation details, we can navigate directly to the seek time with this. */
  onViewInTranscript?: () => void;
}

interface StateProps {
  topics: Topics;
  user: User;
}

type Props = BaseProps & StateProps & DispatchProp;

/** Map state from redux to the components props */
const mapStateToProps = (state: StoreState): StateProps => ({
  topics: topicsSelectors.getTopics(state),
  user: authSelectors.getUser(state),
});

export const ExpandedHighlightCard = ({
  highlight,
  snippets,
  conversation,
  className,
  dispatch,
  inConversation,
  topics,
  user,
  tabIndex,
}: Props) => {
  const { t } = useTranslation();
  const {
    audio_start_offset,
    audio_end_offset,
    audio_fade_in_out = 1,
  } = highlight;
  if (!snippets) {
    snippets = highlight.snippets || [];
  }

  const { play, changeSound, src, isLoading, audioError, isPlaying } =
    React.useContext(GlobalAudioContext);
  if (!conversation) {
    conversation = highlight.conversation;
  }

  if (conversation == null) {
    const partialConversation: any = {
      title: t('common.loading'),
      time: moment(),
      id: '',
      host: {} as Host,
      audio_url: '',
    };
    conversation = partialConversation as Conversation;
  }

  const { audio_url: conversation_audio_url } = conversation;
  const audio_url = inConversation
    ? conversation_audio_url
    : highlight.audio_url;

  const adjustedAudioStartTime = inConversation ? audio_start_offset : 0;
  const adjustedAudioEndTime = inConversation
    ? audio_end_offset
    : audio_end_offset - audio_start_offset + 2 * audio_fade_in_out;
  const audioDuration = adjustedAudioEndTime - adjustedAudioStartTime;

  const seekAudioData = useSeekAudio({
    audioUrl: audio_url,
    startTime: adjustedAudioStartTime,
    endTime: adjustedAudioEndTime,
    meta: conversation,
  });

  const { isActive, toggleActivatedPlaying, setIsActive } = seekAudioData;
  let { seekTime } = seekAudioData;

  if (!inConversation && isActiveAudio(src, audio_url)) {
    // this is the seek time relative to a conversation audio
    const conversationSeekTime = mapSeekTimeToConversation(
      seekTime,
      audio_start_offset,
      audio_fade_in_out
    );
    seekTime = conversationSeekTime;
  }

  const isFeatured = highlight.annotation_type === 'highlight_curated';
  const handleToggleFeatured = () =>
    dispatch(
      editHighlight({
        highlight,
        changes: {
          annotation_type: !isFeatured // negate since we are switching
            ? 'highlight_curated'
            : 'highlight_community',
        },
      })
    );

  /** Modal state and callbacks */
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [modalType, setModalType] = React.useState<string | null>(null);
  const handleCloseModal = React.useCallback(() => {
    setModalIsOpen(false);
    setModalType(null);
  }, []);
  const handleOpenModal = (type: string) => {
    setModalType(type);
    setModalIsOpen(true);
  };

  const handleSeek = React.useCallback(
    (seekTime: number) => {
      const adjustedSeekTime = inConversation
        ? seekTime
        : mapSeekTimeToHighlight(
            seekTime,
            audio_start_offset,
            audio_fade_in_out
          );

      changeSound(audio_url, conversation);
      play(adjustedSeekTime, adjustedAudioEndTime);
      setIsActive(true);
    },
    [
      inConversation,
      audio_start_offset,
      audio_fade_in_out,
      changeSound,
      audio_url,
      conversation,
      play,
      adjustedAudioEndTime,
      setIsActive,
    ]
  );

  const hasTags = highlight.tags && highlight.tags.length > 0;
  const topic = getHighestProbabilityTopic(highlight, topics);
  const topTerms = topic ? topic.keywords.map((kw) => kw.term) : [];

  return (
    <>
      <HighlightModal
        conversation={conversation}
        highlight={highlight}
        modalType={modalType}
        snippets={snippets}
        handleCloseModal={handleCloseModal}
        modalIsOpen={modalIsOpen}
      />
      <Panel
        data-testid="highlight-card"
        className={cx(
          className,
          'p-0 d-flex overflow-hidden',
          styles[`panelHighlightType_${highlight.annotation_type}`],
          {
            [highlightCardStyles.panelPrivateHighlight]:
              highlight.privacy_level === 'private',
          }
        )}
        p={null}
      >
        <div className={cx(styles.contentsContainer, 'p-3 ps-4')}>
          <div className="mb-3">
            <div className={cx('float-end', highlightCardStyles.topIcons)}>
              <HighlightStarButton
                highlight={highlight}
                dispatch={dispatch}
                tabIndex={tabIndex}
              />
              <HighlightDropdown
                conversation={conversation}
                highlight={highlight}
                handleToggleFeatured={handleToggleFeatured}
                handleOpenModal={handleOpenModal}
                isFeatured={isFeatured}
                user={user}
                tabIndex={tabIndex}
              />
            </div>

            {/*<button onClick={handleOpenModal}>Open Modal</button> */}

            <div className="mb-3">
              <div className="fwbold">
                {highlight.user_name}{' '}
                <span className="fwnormal">
                  {t('highlights.highlight_card_action')}
                  <HighlightTypeLabel
                    highlight={highlight}
                    className={cx(
                      'fwbold small-header d-inline ms-2',
                      highlightCardStyles.highlightTypeLabel
                    )}
                    iconOnly
                  />
                  <span className="text-muted ms-1 small">
                    <Link to={`/highlight/${highlight.id}`} tabIndex={tabIndex}>
                      {dateFormatter.relativeAbsDateFormat(
                        highlight.created_at
                      )}
                    </Link>
                  </span>
                </span>
              </div>
              <TruncatedParagraph className={cx('text-gray-700')}>
                {highlight.description}
              </TruncatedParagraph>
            </div>
          </div>

          <div className="clearfix">
            <div className={'mb-3'}>
              <div className="d-flex justify-content-md-between">
                <SpeakerTranscriptRoll
                  seekTime={seekTime}
                  snippets={snippets}
                  locationName={conversation.location.name}
                  startTime={audio_start_offset}
                  endTime={audio_end_offset}
                  audioDuration={audioDuration}
                  onSeek={handleSeek}
                  isActive={isActive}
                  isLoading={isLoading}
                  isPlaying={isPlaying}
                  audioError={audioError}
                  onPlay={toggleActivatedPlaying}
                  className="mb-2 w-100"
                  emphasizedTerms={topTerms}
                  tabIndex={tabIndex}
                />
              </div>
              <div className="d-flex">
                {topic && <TopicTag topic={topic} />}
                {hasTags ? (
                  <div className="mt-1 ps-1">
                    <TagList tags={highlight.tags} />
                  </div>
                ) : null}
              </div>
            </div>

            {!inConversation && (
              <HighlightFooter
                conversation={conversation}
                linkToConversation={highlight.can_access_conversation}
                audioStartOffset={audio_start_offset}
                tabIndex={tabIndex}
              />
            )}
          </div>
        </div>

        <div className="flex-grow-1">
          <AutoSizer defaultWidth={500} defaultHeight={300}>
            {({ width, height = 300 }) => (
              <React.Suspense
                fallback={
                  <div
                    style={{
                      width: `${width}px`,
                      height: `${height}px`,
                      position: 'relative',
                    }}
                    tabIndex={-1}
                  >
                    <LoadingOverlay active />
                  </div>
                }
              >
                {tabIndex !== -1 && (
                  <ConversationMap
                    initialLngLat={
                      conversation && conversation.location.lng_lat
                    }
                    width={width}
                    height={height}
                    mapStyle={mapbox.mapStyles.lvnLighter}
                    initialZoom={12.5}
                    fixed
                  />
                )}
              </React.Suspense>
            )}
          </AutoSizer>
        </div>
      </Panel>
    </>
  );
};

export default connect(mapStateToProps)(React.memo(ExpandedHighlightCard));
