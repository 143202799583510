import { CSSProperties } from 'react';

export function prefersReducedMotion() {
  return !!window.matchMedia('(prefers-reduced-motion: reduce)').matches;
}

export function elementHasOverflow(element: HTMLElement) {
  return element.offsetHeight < element.scrollHeight;
}

export const AbsoluteFill: CSSProperties = {
  position: 'absolute',
  inset: '0 0 0 0',
};

export function Hidden(hidden = true) {
  return hidden ? { opacity: 0, pointerEvents: 'none' } : {};
}
