import * as React from 'react';

import { Highlight, Snippet } from 'src/types/conversation';

const HighlightTitle = ({
  highlight,
  snippets,
}: {
  highlight: Highlight;
  snippets?: Snippet[];
}) => {
  const { id } = highlight;
  if (!snippets) {
    snippets = highlight.snippets || [];
  }

  let title = highlight.title;
  if (snippets.length && !title) {
    // first 10 words
    const wordLimit = 10;
    const titleWords = snippets[0].words
      .filter(
        (word) =>
          highlight.audio_start_offset <= word[2] &&
          highlight.audio_end_offset > word[1]
      )
      .map((word) => word[0]);

    if (titleWords.length > wordLimit) {
      title = `${titleWords.slice(0, wordLimit).join(' ')}...`;
    } else {
      title = titleWords.join(' ');
    }
  }

  if (title == null || title.length === 0) {
    title = `Highlight ${id}`;
  }

  return <span data-testid="highlight-title">{title}</span>;
};

export default HighlightTitle;
