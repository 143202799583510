import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import Box from '@mui/material/Box';

import { selectors as catalogSelectors } from 'src/redux/catalog/catalog-selectors';
import { assignCoding, unassignCoding } from 'src/redux/catalog/catalog-slice';
import { StoreState } from 'src/redux/store';
import { Code, Entry } from 'src/types/insights';
import CodeAccordionModern from './CodeAccordionModern';

interface StateProps {
  codes: Code[];
  selectedEntry?: Entry;
}

type Props = StateProps & DispatchProp;

const mapStateToProps = (state: StoreState): StateProps => ({
  codes: catalogSelectors.getStructuralCodes(state),
  selectedEntry: catalogSelectors.getSelectedEntry(state),
});

const StructuralCodeAccordion: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { codes, dispatch, selectedEntry } = props;

  const selectCode = (id: number, selected: boolean) => {
    if (selectedEntry !== undefined) {
      if (selected) {
        dispatch(assignCoding([id, selectedEntry.id]));
      } else {
        dispatch(unassignCoding([id, selectedEntry.id]));
      }
    }
  };

  return (
    <Box id="structural-code-accordion">
      <CodeAccordionModern
        codes={codes}
        selectCode={selectCode}
        selectedCodes={selectedEntry?.codings ?? []}
        selecting={Boolean(selectedEntry)}
        type="structural"
      />
    </Box>
  );
};

StructuralCodeAccordion.displayName = 'StructuralCodeAccordion';

export default connect(mapStateToProps)(StructuralCodeAccordion);
