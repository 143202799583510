import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

interface BaseProps {
  title: string;
  stat: number | string;
}

const ConversationStat: React.FunctionComponent<BaseProps> = ({
  title,
  stat,
}) => {
  return (
    <Paper data-testid="ConversationStat" sx={{ p: 2 }} variant="outlined">
      <Typography
        component="div"
        variant="overline"
        textAlign={'center'}
        sx={{ fontWeight: 'bolder' }}
      >
        {title}
      </Typography>
      <Typography component="div" sx={{ textAlign: 'center' }} variant="h5">
        {stat}
      </Typography>
    </Paper>
  );
};

export default ConversationStat;
