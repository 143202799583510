import React from 'react';
import cx from 'classnames';

interface Props {
  children: React.ReactNode;
  className?: string | undefined;
  p?: number | undefined | null;
  mb?: number | undefined | null;
}

export default function Panel({
  children,
  p = 3,
  mb = 3,
  className,
  ...other
}: Props) {
  return (
    <div
      {...other}
      className={cx(
        'rounded card shadow',
        {
          [`p-${p}`]:
            p != null &&
            (!className || (className && className.indexOf('p-') === -1)),
          [`mb-${mb}`]:
            mb != null &&
            (!className || (className && className.indexOf('mb-') === -1)),
        },
        className
      )}
    >
      {children}
    </div>
  );
}
