import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import cx from 'classnames';
import partition from 'lodash.partition';
import moment from 'moment-timezone';

import EmptyTable from 'src/components/EmptyTable/EmptyTable';
import Panel from 'src/components/Panel/Panel';
import conversationSelectors from 'src/redux/conversation/conversation-selectors';
import { editConversation } from 'src/redux/conversation/conversation-slice';
import { StoreState } from 'src/redux/store';
import { RowFieldErrors, RowInputs } from 'src/types/admin';
import { Conversation } from 'src/types/conversation';
import ConversationRow from './ConversationRow/ConversationRow';

import styles from './DraftConversationTable.module.scss';

// a conversation is stale if it was uploaded more than
// one week ago, update tooltip text if updating threshold
const STALE_UPLOAD_THRESHOLD = moment().subtract(1, 'week');

interface BaseProps {
  conversations: Conversation[];
  isLoading: boolean;
}

interface StateProps {
  isSavedEditConversation: boolean;
  errorEditConversation: Error | undefined;
}

type Props = BaseProps & StateProps & DispatchProp;

/** Map state from redux to the components props */
const mapStateToProps = (state: StoreState): StateProps => ({
  isSavedEditConversation: conversationSelectors.isSavedEditConversation(state),
  errorEditConversation: conversationSelectors.getErrorEditConversation(state),
});

export const DraftConversationTable = ({
  dispatch,
  conversations,
  isLoading,
  isSavedEditConversation,
  errorEditConversation,
}: Props) => {
  const [editConv, setEditConv] = React.useState<Conversation>();
  const [ignoreError, setIgnoreError] = React.useState(false);
  const [inputs, setInputs] = React.useState<RowInputs>({
    draftTitle: '',
    draftLocation: '',
  });
  const { t } = useTranslation();
  const [fieldErrors, setFieldErrors] = React.useState<RowFieldErrors>({});

  React.useEffect(() => {
    // signal that it has saved successfully
    if (isSavedEditConversation) {
      handleHideEdit();
    }
  }, [isSavedEditConversation]);

  const handleEditClick = (conv: Conversation) => {
    setIgnoreError(true); // hide old edit conversation errors
    setEditConv(conv);
    setInputs({
      draftTitle: conv.title,
      draftLocation: conv.location.name,
    });
  };

  const handleHideEdit = () => {
    setEditConv(undefined);
    setFieldErrors({});
  };

  const handleChangeInput = (evt: React.SyntheticEvent<HTMLInputElement>) => {
    const { name, value } = evt.currentTarget;
    setInputs({ ...inputs, [name]: value });
  };

  const handleSaveEdit = (evt: React.SyntheticEvent) => {
    evt.preventDefault(); // stop form submission

    const draftFieldErrors: RowFieldErrors = {};
    const changes: Partial<Conversation> = {};
    if (editConv) {
      if (editConv.title !== inputs.draftTitle) {
        changes.title = inputs.draftTitle;
      }
      // TODO: edit collection and host objects, possibly location.latlng?
      if (editConv.location.name !== inputs.draftLocation) {
        changes.location = {
          ...editConv.location,
          name: inputs.draftLocation,
        };
      }

      if (Object.keys(draftFieldErrors).length !== 0) {
        setFieldErrors(draftFieldErrors);
      } else if (Object.keys(changes).length !== 0) {
        setFieldErrors({});
        dispatch(editConversation({ conversationId: editConv.id, changes }));
      } else {
        setFieldErrors({});
        handleHideEdit();
      }
      // unhide errors
      setIgnoreError(false);
    }
  };

  const [recentConversations, staleConversations] = partition(
    conversations,
    (conversation: Conversation) => {
      return conversation.post_time.isSameOrAfter(STALE_UPLOAD_THRESHOLD);
    }
  );

  if (!isLoading && conversations.length === 0) {
    return (
      <Panel>
        <EmptyTable title={t('admin.draft_no_conversations')}>
          <p>{t('admin.draft_empty')}</p>
        </EmptyTable>
      </Panel>
    );
  }

  return (
    <Panel
      className={cx(styles.panel, {
        [styles.loading]: isLoading,
      })}
      data-testid="draft-conversation-table"
    >
      <div className={cx('row border-bottom', styles.tableHeader)}>
        <div className={cx('col-1 p-0 ps-3')}>{t('admin.draft_status')}</div>
        <div className={cx('col-2 p-0 pe-1')}>{t('admin.draft_title')}</div>
        <div className={cx('col-2 p-0 pe-1')}>
          {t('admin.draft_collection')}
        </div>
        <div className={cx('col-2 p-0 pe-1')}>{t('admin.draft_host')}</div>
        <div className={cx(`col-2 p-0 pe-1`)}>{t('admin.draft_location')}</div>
        <div className={cx('col-2 p-0 pe-1')}>{t('admin.draft_date')}</div>
        <div className={cx('col-1 p-0 pe-1')}></div>
      </div>
      {staleConversations.map((conversation) => (
        <ConversationRow
          conversation={conversation}
          key={conversation.id}
          formId="conv-row-edit-form"
          inputs={inputs}
          editError={ignoreError ? undefined : errorEditConversation}
          editing={editConv ? conversation.id === editConv.id : false}
          fieldErrors={fieldErrors}
          onEditClick={handleEditClick}
          onChangeInput={handleChangeInput}
          onCancel={handleHideEdit}
          stale
        />
      ))}
      {recentConversations.map((conversation) => (
        <ConversationRow
          conversation={conversation}
          key={conversation.id}
          formId="conv-row-edit-form"
          inputs={inputs}
          editError={ignoreError ? undefined : errorEditConversation}
          editing={editConv ? conversation.id === editConv.id : false}
          fieldErrors={fieldErrors}
          onEditClick={handleEditClick}
          onChangeInput={handleChangeInput}
          onCancel={handleHideEdit}
        />
      ))}
      {editConv && <form id="conv-row-edit-form" onSubmit={handleSaveEdit} />}
    </Panel>
  );
};

export default connect(mapStateToProps)(DraftConversationTable);
