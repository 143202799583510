import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Panel from 'src/components/Panel/Panel';
import PublishButton from 'src/components/PublishButton/PublishButton';
import { Conversation } from 'src/types/conversation';

interface Props {
  conversation: Conversation;
  onChangeDraftStatus: (isDraft: boolean) => void;
  isSaving: boolean;
  isSaved: boolean;
  error: Error | undefined;
  className?: string;
}
const PublishSection = ({
  conversation,
  onChangeDraftStatus,
  isSaving,
  isSaved,
  error,
  className,
}: Props) => {
  const { t } = useTranslation();
  let description = t('admin.draft_publishing_description_1');

  if (conversation.is_draft) {
    description = t('admin.draft_publishing_description_2');
  }
  return (
    <section className={className} data-testid="publish-section">
      <h4>{t('admin.draft_publishing')}</h4>
      <Panel>
        <p>{description}</p>
        <PublishButton
          isDraft={conversation.is_draft}
          isSaving={isSaving}
          isSaved={isSaved}
          error={error}
          toggle
          onClick={onChangeDraftStatus}
        />
      </Panel>
    </section>
  );
};

export default PublishSection;
