import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import styles from './CheckboxWrapper.module.scss';

interface Props {
  inputName: string;
  checked: boolean;
  labelText?: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
  testid?: string;
  spacing?: 'me-1' | 'me-2' | 'me-3';
}

// uses a custom checkbox when wrapped around a checkbox input
const CheckboxWrapper = ({
  checked,
  children,
  className,
  inputName,
  labelText,
  testid,
  spacing = 'me-3',
}: Props) => (
  <label
    htmlFor={inputName}
    className={cx('d-block', styles.checkboxWrapper, className)}
    data-testid={testid}
  >
    {children}
    <div
      className={cx(spacing, 'custom-checkbox', styles.checkbox)}
      aria-hidden
    >
      <span>
        <FontAwesomeIcon
          icon={['fas', 'check']}
          className={cx(styles.checkIcon, 'bs-primary', {
            [styles.checked]: checked,
          })}
        />
      </span>
    </div>
    {labelText}
  </label>
);

export default CheckboxWrapper;
