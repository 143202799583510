import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import './NotAuthorized.scss';

type Props = {
  username: string | undefined;
};

const NotAuthorized = ({ username }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="NotAuthorized">
      <div className="box-container">
        <div className="box">
          {username && <p>{t('not_authorized.hi', { name: username })}</p>}
          <p>{t('not_authorized.message')}</p>
          <p>
            <Trans
              i18nKey="not_authorized.help"
              /*eslint-disable */
              components={{
                1: <a href="mailto:help@lvn.org" />,
              }}
              /*eslint-disable */
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotAuthorized;
