import * as React from 'react';
import { useTranslation } from 'react-i18next';
import xor from 'lodash.xor';
import {
  ArrayParam,
  QueryParamConfig,
  SetQuery,
  useQueryParams,
} from 'use-query-params';

import FilterChecklist from 'src/components/FilterChecklist/FilterChecklist';
import SideFilter from 'src/components/SideFilter/SideFilter';
import {
  ConversationsFilterOptions,
  FilterOption,
  HighlightsFilterOptions,
} from 'src/types/core';

export const getActivePrivacy = (
  privacyLevels: string[] | undefined,
  filterOptions:
    | ConversationsFilterOptions
    | HighlightsFilterOptions
    | undefined
) => {
  return (
    privacyLevels &&
    filterOptions &&
    filterOptions.privacy_levels &&
    privacyLevels.map((level) =>
      filterOptions.privacy_levels.find((priv) => priv.level === level)
    )
  );
};

export const handlePrivacyChange = (
  option: FilterOption,
  privacyLevels: string[] | undefined,
  setQuery: SetQuery<{
    priv: QueryParamConfig<string[] | undefined>;
    page: QueryParamConfig<number | undefined>;
  }>
) => {
  if (option.level) {
    setQuery({
      priv: xor(privacyLevels, [option.level]),
      page: 1,
    });
  }
};

interface Props {
  filterOptions:
    | ConversationsFilterOptions
    | HighlightsFilterOptions
    | undefined;
}

const PrivacyFilter = ({ filterOptions }: Props) => {
  const [query, setQuery] = useQueryParams({
    priv: ArrayParam,
  });
  const { t } = useTranslation();
  const { priv: privacyLevels } = query;
  return (
    <SideFilter
      title={t('common.privacy')}
      open={Boolean(privacyLevels)}
      disabled={!(filterOptions && filterOptions.privacy_levels.length)}
    >
      {filterOptions && filterOptions.privacy_levels && (
        <FilterChecklist
          options={filterOptions.privacy_levels}
          onChange={(option) =>
            handlePrivacyChange(option, privacyLevels, setQuery)
          }
          activeFilterOptions={getActivePrivacy(privacyLevels, filterOptions)}
          title={t('common.privacy')}
          fade
        />
      )}
    </SideFilter>
  );
};

export default PrivacyFilter;
