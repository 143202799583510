import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { connect as twilioConnect, createLocalTracks } from 'twilio-video';
import WaveSurfer from 'wavesurfer.js';
import * as WaveSurferMicrophone from 'wavesurfer.js/dist/plugin/wavesurfer.microphone';

import {
  getRecordingRoomStatus,
  startRecordingRoom,
  updateRecordingRoom,
} from 'src/api/api';
import Button from 'src/components/core/Button/Button';
import { useAnalyticsContext } from 'src/Providers/AnalyticsProvider';
import { selectors as authSelectors } from 'src/redux/auth/auth-selectors';
import { StoreState } from 'src/redux/store';
import { AnalyticsEvent, Category, Name } from 'src/types/analytics';
import { RecordingRoomMetadata } from 'src/types/conversation';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import LoadingOverlay from '../core/LoadingOverlay/LoadingOverlay';
import CustomError from '../CustomError/CustomError';
import Layout from '../Layout/Layout';
import Stopwatch from '../Stopwatch/Stopwatch';
import {
  checkForSessionMetadata,
  handleWakeLock,
} from './recording_room_helpers';

import styles from './RecordingRoom.module.scss';

const RecordingRoom = () => {
  const user = useSelector((state) =>
    authSelectors.getUser(state as StoreState)
  );
  const wavesurfer = useRef<typeof WaveSurferMicrophone>(null);
  const [isLoading, setIsLoading] = useState(true); // for start & (un)pause
  const [error, setError] = useState('');
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [roomInfo, setRoomInfo] = useState<RecordingRoomMetadata>(
    {} as RecordingRoomMetadata
  );
  const [recordingDuration, setRecordingDuration] = useState();
  const [finishingRecording, setFinishingRecording] = useState(false); // for finish
  const micLabel = sessionStorage.getItem('selectedMicLabel');
  const storedMetadata: string | null = sessionStorage.getItem(
    'recordingRoomMetadata'
  );
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (checkForSessionMetadata()) {
      handleStartRecording();
      handleWakeLock('start');
    } else {
      history.push('/conversation-record');
    }
  }, []);

  useEffect(() => {
    if (roomInfo.room_sid) {
      const intervalId = setInterval(checkRecordingRoomStatus, 5000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [roomInfo]);

  const checkRecordingRoomStatus = () => {
    const errorMessage = t('live_recording.error_during_room');

    getRecordingRoomStatus(roomInfo.room_sid)
      .then((data) => {
        console.log('data.recording_duration', data.recording_duration);
        setRecordingDuration(data.recording_duration);
        return data;
      })
      .then((data) => handleCurrentRoomStatus(data, errorMessage))
      .catch((error) => {
        console.log('Error:', error);
        sessionStorage.clear();
        setError(errorMessage);
      });
  };

  const handleCurrentRoomStatus = (roomData: any, errorMessage: string) => {
    if (
      roomData.status === 'completed' ||
      roomData.recording_duration >= 10785
    ) {
      handleUpdateRecording('timeout');
      return;
    } else if (
      roomData.status !== 'in-progress' ||
      roomData.participant_connected === false ||
      roomData.recordings_count < 1
    ) {
      setError(errorMessage);
      return;
    }
  };

  const modifiedMicLabel =
    micLabel && micLabel.length > 20
      ? micLabel.slice(0, 20) + ' ...'
      : micLabel;

  const handleStartRecording = () => {
    if (storedMetadata) {
      const parsedData = JSON.parse(storedMetadata);

      startRecordingRoom(parsedData)
        .then((roomData) => {
          console.log('roomData >>', roomData);
          setRoomInfo(roomData);
          connectToARoom(roomData);
          sessionStorage.setItem('conversationId', roomData.conversation_id);
        })
        .catch((error) => {
          console.log(error);
          sessionStorage.clear();
          setIsLoading(false);
          setError(t('live_recording.error_starting_room'));
        });
    } else {
      setError(t('live_recording.error_starting_room'));
    }
  };

  const connectToARoom = async (roomData: any) => {
    const selectedMicId: string | undefined =
      sessionStorage.getItem('selectedMicID') ?? undefined;
    console.log('connectToARoom, selectedMicId', selectedMicId);
    try {
      const localTracks = await createLocalTracks({
        audio: { deviceId: { exact: selectedMicId } },
      });
      const room = await twilioConnect(roomData.token, {
        name: roomData.room_name,
        tracks: localTracks,
      });
      console.log('localTracks>>>', localTracks);
      console.log(`Successfully joined a Room: ${room.name}`);
      handleWaveRecorder(selectedMicId);
    } catch (error) {
      console.error(`Unable to connect to Room: ${error}`);
      setError(t('live_recording.error_joining_room'));
    }
  };

  const handleWaveRecorder = (selectedMicId: string | undefined) => {
    console.log('handleWaveRecorder - selectedMicId', selectedMicId);
    setIsLoading(false);
    wavesurfer.current = WaveSurfer.create({
      barGap: 2,
      barRadius: 2,
      barWidth: 2,
      container: '#waveRecorder',
      cursorWidth: 0,
      height: 350,
      interact: false,
      waveColor: '#51C783',
      plugins: [
        WaveSurferMicrophone.create({
          constraints: { audio: { deviceId: selectedMicId } },
        }),
      ],
    });
    wavesurfer.current.microphone.start();
  };

  const toggleLoadingStates = (action: string) => {
    if (action === 'finish') {
      setFinishingRecording((prevFinishing) => !prevFinishing);
    } else {
      setIsLoading((prevLoading) => !prevLoading);
    }
  };

  const { analyticsEvent } = useAnalyticsContext();

  const handleUpdateRecording = (action: string) => {
    toggleLoadingStates(action);

    analyticsEvent({
      category: Category.LiveRecording,
      action: `${action}`,
      name: Name.UpdateRecordingRoom,
    } as AnalyticsEvent);

    if (storedMetadata) {
      const parsedData = JSON.parse(storedMetadata);

      updateRecordingRoom({
        user_id: user.id,
        action: action,
        room_name: roomInfo.room_name,
        room_sid: roomInfo.room_sid,
        collection_id: parsedData.collection_id,
      })
        .then((data) => {
          console.log(data);

          if (action === 'finish' || action === 'timeout') {
            finishRecording();
          } else if (action === 'cancel') {
            sessionStorage.clear();
            history.push('/conversation-record');
          }
        })
        .catch((error) => {
          console.log(error);
          sessionStorage.clear();
          toggleLoadingStates(action);
          setError(t('live_recording.error_finishing_room'));
        });
    } else {
      setError(t('live_recording.error_finishing_room'));
    }
  };

  const finishRecording = async () => {
    wavesurfer.current.microphone.stop();
    sessionStorage.removeItem('recordingRoomMetadata');
    history.push('/recording-complete');
  };

  return (
    <Layout
      title={'Recording Room'}
      className={'recordingRoom'}
      data-testid="recording-room-container"
    >
      <LoadingOverlay
        active={isLoading || finishingRecording}
        className={styles.recordingLoadingOverlay}
        fixed={true}
      />
      {error !== '' && <CustomError error={error} />}
      <>
        <ConfirmationModal
          isLoading={false}
          isOpen={cancelModalOpen}
          handleCloseModal={() => setCancelModalOpen(false)}
          text={{
            question: t('live_recording.cancel_recording_question'),
            description: t('live_recording.cancel_recording_description'),
            confirm: t('live_recording.cancel_recording_confirmation_button'),
            cancel: t('live_recording.cancel_recording_cancel_button'),
          }}
          confirm={() => handleUpdateRecording('cancel')}
        />
        <div className={styles.recordingRoomInfoBadgeContainer} role="banner">
          <h6 className={styles.recordingRoomInfoBadge}>
            {t('live_recording.recording_room_banner')}
          </h6>
        </div>
        <div className={styles.recordingRoomNav}>
          <FontAwesomeIcon
            icon={['fas', 'x']}
            size="sm"
            onClick={() => setCancelModalOpen(true)}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setCancelModalOpen(true);
              }
            }}
          />
          <div className={styles.micLabel}>
            {t('live_recording.microphone_connected_label')} {modifiedMicLabel}
          </div>
          <div></div> {/*  placeholder for styling and future feat. */}
        </div>
      </>
      <div id="waveRecorder" data-testid="wave-recorder" />{' '}
      <div className={styles.recordingRoomFooter}>
        {!isLoading && (
          <Stopwatch
            duration={recordingDuration ? recordingDuration : undefined}
          />
        )}
        <Button
          color="secondary"
          size="sm"
          data-testid="finish-button"
          className={cx(styles.finishButton, 'w-75')}
          onClick={() => handleUpdateRecording('finish')}
          disabled={isLoading || finishingRecording}
        >
          {t('live_recording.recording_room_finish_button')}
        </Button>
      </div>
    </Layout>
  );
};

export default RecordingRoom;
