import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import Button from 'src/components/core/Button/Button';
import { elementHasOverflow } from 'src/util/styles';

import styles from './TruncatedParagraph.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const TruncatedParagraph = ({ children, className }: Props) => {
  const { t } = useTranslation();
  const paragraphEl = React.useRef<HTMLParagraphElement>(null);
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [isTruncated, setIsTruncated] = React.useState(false);

  // remove the truncation and hide the see more button when it is clicked
  const handleClick = () => {
    setIsTruncated(false);
    setIsExpanded(true);
  };

  React.useEffect(() => {
    if (paragraphEl && paragraphEl.current) {
      const currentEl = paragraphEl.current;
      // if there is overflow, the "see more" button should be shown
      if (elementHasOverflow(currentEl)) {
        setIsTruncated(true);
      }
    }
  }, [paragraphEl, setIsTruncated]);

  return (
    <div>
      <p
        ref={paragraphEl}
        className={cx(className, 'mb-1', styles.truncated, {
          'd-block': isExpanded,
        })}
      >
        {children}
      </p>
      {isTruncated && (
        <Button size="sm" onClick={handleClick}>
          {t('topics.discover_hover_button')}
        </Button>
      )}
    </div>
  );
};

export default TruncatedParagraph;
