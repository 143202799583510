import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { StringParam, useQueryParams } from 'use-query-params';

import { getUserFromToken } from 'src/api/api';
import LoadingOverlay from 'src/components/core/LoadingOverlay/LoadingOverlay';
import { User } from 'src/types/auth';
import Layout from '../Layout/Layout';
import GuardianAgreeForm from './GuardianAgreeForm';
import LegalPage from './LegalPage';

const GuardianPage = (props: RouteComponentProps) => {
  const { t } = useTranslation();
  /** Define URL query parameters */
  const [query, ,] = useQueryParams({
    u: StringParam,
    t: StringParam,
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [minorUser, setMinorUser] = React.useState<User>();
  const [error, setError] = React.useState<Error>();

  // verify the query params correspond to a valid minor
  React.useEffect(() => {
    const { u: uidb64, t: token } = query;
    if (uidb64 && token) {
      setIsLoading(true);
      getUserFromToken(uidb64, token)
        .then((user) => {
          setIsLoading(false);
          setMinorUser(user);
        })
        .catch((err) => {
          // backend told us this isn't a valid link
          setIsLoading(false);
          setMinorUser(undefined);
          setError(err);
        });
    } else {
      setError(Error('No credentials provided.'));
    }
  }, [query]);

  if (isLoading) {
    return (
      <Layout className="GuardianPage" title={t('guardian_permission.title')}>
        <LoadingOverlay active={isLoading} />
      </Layout>
    );
  }

  if (error || minorUser == null || !query.u || !query.t) {
    return (
      <Layout className="GuardianPage" title={t('guardian_permission.title')}>
        <div className="text-center" data-testid="invalid-link">
          <Trans
            i18nKey="legal.guardian_error"
            /*eslint-disable */
            components={{
              1: <a href="mailto:help@lvn.org" />,
            }}
            /*eslint-disable */
          />
        </div>
      </Layout>
    );
  }

  const AgreeAction = (
    <GuardianAgreeForm minorUser={minorUser} uidb64={query.u} token={query.t} />
  );

  return (
    <LegalPage
      showAgreeAction
      agreeAction={AgreeAction}
      minorUser={minorUser}
      {...props}
    />
  );
};

export default GuardianPage;
