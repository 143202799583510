import { useEffect } from 'react';
import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import { VStack } from 'src/components/core/Stack';
import ConversationCard from './ConversationCard';
import { ConversationCardModal } from './ConversationCardModal';
import { useConversationsPageContext } from './ConversationsPageProvider';
import ConversationsPageToolbar from './ConversationsPageToolbar';

const ConversationsPage = () => {
  const {
    catalog,
    visibleConversations,
    isLoading,
    selectedConversation,
    hideModal,
    showModal,
    onConversationClick,
    onConversationModalClose,
    t,
  } = useConversationsPageContext();

  useEffect(() => {
    if (!selectedConversation) return;

    hideModal();
    showModal(<ConversationCardModal />, {
      withCodebook: true,
      containerStyle: {
        background: 'transparent',
        maxHeight: 'unset',
        height: '100%',
        pointerEvents: 'none',
        minWidth: '432px',
        width: '70%',
      },
      onClose: onConversationModalClose,
    });
  }, [selectedConversation]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <VStack
        maxWidth={'1280px'}
        width={'100%'}
        alignSelf={'center'}
        padding={'0 !important'}
      >
        <ConversationsPageToolbar />
        <Divider />
      </VStack>
      <Container
        sx={{
          position: 'relative',
          py: 3,
          flexGrow: 1,
          overflow: 'auto',
          margin: '0 !important',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          maxWidth: 'none !important',
        }}
      >
        <Box
          sx={{
            flex: 4,
            py: 3,
            px: 2,
            width: '100%',
            maxWidth: '1280px !important',
          }}
        >
          {!isLoading && catalog && (
            <Grid container item spacing={3} xs={12}>
              <Grid container item spacing={3} xs={12}>
                {visibleConversations?.map((conversation) => (
                  <Grid
                    item
                    key={conversation.id}
                    lg={4}
                    md={6}
                    sx={{ alignItems: 'stretch' }}
                    xl={3}
                    xs={12}
                  >
                    <ConversationCard
                      conversation={conversation}
                      onClick={() => onConversationClick(conversation.id)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Box>
      </Container>
    </Box>
  );
};

ConversationsPage.displayName = 'ConversationsPage';
export default ConversationsPage;
