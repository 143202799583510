import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { useField } from 'formik';

import adminSelectors from 'src/redux/admin/admin-selectors';
import { UserCollectionMetadata } from 'src/types/admin';
import { Collection, CollectionRole } from 'src/types/collection';
import { createBasicProvider } from 'src/util/provider';
import AccordianTable, {
  AccordianTableProps,
} from '../../../Common/AccordianTable';
import { CollectionRoleDropdown } from '../../PillDropdowns/CollectionRoleDropdown';
import CollectionRoleTooltip from '../../Tooltips/CollectionRoleTooltip';
import { UploadConversationsToggle } from '../../ViewMember/MemberCollectionsTable/UploadConversationsToggle';

import styles from './CollectionsTableInput.module.scss';

type CollectionRowState = UserCollectionMetadata;

export type CollectionTableInputState = CollectionRowState[];

const [LocalProvider, LocalContext] = createBasicProvider<{
  collections: CollectionTableInputState;
  onUploadConversationsToggled: (
    collectionId: Collection['id'],
    canUploadConversations: boolean
  ) => void;
  onRoleChanged: (
    collectionId: Collection['id'],
    role: CollectionRowState['role']
  ) => void;
}>();

type CollectionsTableInputProps = {
  name: string;
} & Partial<AccordianTableProps<CollectionRowState>>;

export const CollectionsTableInput = ({
  name,
  ...tableProps
}: CollectionsTableInputProps) => {
  const [_field, _meta, helpers] = useField<CollectionTableInputState>(name);

  const { setValue } = helpers;

  const allCollections = useSelector(adminSelectors.getCollections);

  const [collections, setCollections] = useState<CollectionTableInputState>(
    allCollections.map((c) => ({
      collection: c,
      role: 'None',
    }))
  );

  const onChange = useCallback(
    (state: CollectionTableInputState) => {
      setCollections(state);
      setValue(
        // only pass values up that aren't the defaults
        state.filter(
          (s) =>
            !(
              s.role.toLowerCase() === 'None'.toLowerCase() &&
              !s.canUploadConversations
            )
        )
      );
    },
    [setCollections, setValue]
  );

  const onUploadConversationsToggled = useCallback(
    (collectionId: Collection['id'], canUploadConversations: boolean) => {
      const updatedCollections = collections.map((c) => {
        if (c.collection.id === collectionId) {
          c.canUploadConversations = canUploadConversations;
        }
        return c;
      });

      onChange(updatedCollections);
    },
    [collections, onChange]
  );

  const onRoleChanged = useCallback(
    (collectionId: Collection['id'], role: CollectionRowState['role']) => {
      const updatedCollections = collections.map((c) => {
        if (c.collection.id === collectionId) {
          c.role = role;

          if (role.toLowerCase() === CollectionRole.manager.toLowerCase()) {
            c.canUploadConversations = true;
          } else if (
            !(
              role.toLowerCase() === CollectionRole.viewer.toLowerCase() ||
              role.toLowerCase() === CollectionRole.highlighter.toLowerCase()
            )
          ) {
            c.canUploadConversations = false;
          }
        }
        return c;
      });

      onChange(updatedCollections);
    },
    [collections, onChange]
  );

  return (
    <LocalProvider
      value={{
        collections,
        onRoleChanged,
        onUploadConversationsToggled,
      }}
    >
      <CollectionsTable {...tableProps} />
    </LocalProvider>
  );
};

const CollectionsTable = (
  props: Partial<AccordianTableProps<CollectionRowState>>
) => {
  const { t } = useTranslation();
  const { collections } = LocalContext();

  const tableProps: AccordianTableProps<CollectionRowState> = {
    ...props,
    values: collections,
    startClosed: true,
    label: t('admin.collection_accordian_label'),
    testId: 'user-collection-table',
    valToKey: (state) => state.collection.id,
    columns: [
      {
        id: 'collection-name',
        headerLabel: t('admin.member_collection_column_1'),
        content: CollectionNameContent,
      },
      {
        id: 'collection-role',
        headerLabel: CollectionRoleColumnLabel,
        content: CollectionRoleContent,
      },
      {
        id: 'upload-conversations',
        headerLabel: t('admin.member_collection_column_3'),
        content: UploadToggleContent,
      },
    ],
  };

  return <AccordianTable {...tableProps} />;
};

const CollectionNameContent = ({ val: state }: { val: CollectionRowState }) => {
  return (
    <Link className={cx(styles.title)} to={`/?c=${state.collection.id}`}>
      {state.collection.title}
    </Link>
  );
};

const CollectionRoleContent = ({ val: state }: { val: CollectionRowState }) => {
  const { onRoleChanged } = LocalContext();
  return (
    <CollectionRoleDropdown
      collectionRole={state.role}
      collectionId={state.collection.id}
      onSelect={(role) => onRoleChanged(state.collection.id, role)}
    />
  );
};

const UploadToggleContent = ({ val: state }: { val: CollectionRowState }) => {
  const { onUploadConversationsToggled } = LocalContext();

  const disabled =
    state.role.toLowerCase() === CollectionRole.manager.toLowerCase() ||
    !(
      state.role.toLowerCase() === CollectionRole.viewer.toLowerCase() ||
      state.role.toLowerCase() === CollectionRole.highlighter.toLowerCase()
    );

  return (
    <UploadConversationsToggle
      collectionId={state.collection.id}
      canUploadConversation={state.canUploadConversations}
      onToggle={() =>
        onUploadConversationsToggled(
          state.collection.id,
          !state.canUploadConversations
        )
      }
      disabled={disabled}
    />
  );
};

const CollectionRoleColumnLabel = () => {
  const { t } = useTranslation();
  const label = t('admin.member_collection_column_2');
  return (
    <div className="d-flex">
      {label}
      <CollectionRoleTooltip width="300px" />
    </div>
  );
};

export default CollectionsTableInput;
