import { FormikProps } from 'formik';

/**
 * Given a field name, a formik status obj, and a formik error obj
 * returns the highest priority message
 *
 * If no relevant status or error, returns undefined
 */
export function getErrorMessage({
  field,
  status,
  errors,
}: {
  field: string;
  status: FormikProps<any>['status'];
  errors: FormikProps<any>['errors'];
}) {
  if (status && status.attribute === field) {
    return status.message;
  }
  if (errors && errors[field]) {
    return errors[field];
  }
  return undefined;
}
