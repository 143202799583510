import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { scaleLinear } from 'd3';

import LoadingSpinner from 'src/components/core/LoadingSpinner/LoadingSpinner';
import { formatTime } from 'src/util/format';

import './AudioControls.scss';

interface Props {
  isPlaying?: boolean;
  isLoading?: boolean;
  seekTime?: number;
  duration?: number;
  onPlay: () => void;
  onSeek: (seekTime: number) => void;
  onPause: () => void;
  showPlayControls?: boolean;
}

interface State {
  dragging: boolean;
}

class AudioControls extends React.PureComponent<Props, State> {
  state: State = {
    dragging: false,
  };

  durationBar: any | null = null;

  handleTogglePlaying = () => {
    const { isPlaying, onPlay, onPause } = this.props;
    if (isPlaying) {
      if (onPause) {
        onPause();
      }
    } else if (onPlay) {
      onPlay();
    }
  };

  handleStartSeekDrag = (evt: React.MouseEvent<any>) => {
    this.seekByXPosition(evt.clientX);
    this.setState({
      dragging: true,
    });
    document.body.addEventListener('mousemove', this.handleSeekDragMouseMove);
    document.body.addEventListener('mouseup', this.handleStopDrag);
    document.body.addEventListener('mouseleave', this.handleStopDrag);
  };
  handleSeekDragMouseMove = (evt: any) => {
    this.seekByXPosition(evt.clientX);
  };

  handleStopDrag = (evt: any) => {
    this.seekByXPosition(evt.clientX);
    this.setState({
      dragging: false,
    });
    document.body.removeEventListener(
      'mousemove',
      this.handleSeekDragMouseMove
    );
    document.body.removeEventListener('mouseup', this.handleStopDrag);
    document.body.removeEventListener('mouseleave', this.handleStopDrag);
  };

  seekByXPosition = (x: number) => {
    const { duration = 1, onSeek } = this.props;
    const barRect = this.durationBar.getBoundingClientRect();

    const xScale = scaleLinear()
      .domain([barRect.x, barRect.x + barRect.width])
      .range([0, duration])
      .clamp(true);

    const seekTime = xScale(x);
    onSeek(seekTime);
  };

  render() {
    const {
      isPlaying,
      isLoading,
      duration,
      seekTime,
      showPlayControls = true,
    } = this.props;
    const { dragging } = this.state;

    const timeScale = scaleLinear()
      .domain([0, duration || 0])
      .range([0, 100])
      .clamp(true);

    return (
      <div
        className={cx('AudioControls', {
          isPlaying,
          loaded: !isLoading,
          loading: isLoading,
          dragging,
        })}
      >
        {showPlayControls && (
          <button className="play-pause-btn" onClick={this.handleTogglePlaying}>
            {isLoading ? (
              <LoadingSpinner active thick />
            ) : isPlaying ? (
              <FontAwesomeIcon icon="pause" />
            ) : (
              <FontAwesomeIcon icon="play" />
            )}
          </button>
        )}
        <div className="play-status-bar">
          <span className="seek-time" data-testid="seek-time">
            {formatTime(seekTime || 0)}
          </span>
          <div
            className="interaction-bar"
            onMouseDown={this.handleStartSeekDrag}
          >
            <div
              className="duration-bar"
              ref={(node) => (this.durationBar = node)}
            >
              <div
                className="seek-bar"
                style={{
                  width: `${isLoading ? 0 : timeScale(seekTime || 0)}%`,
                }}
              />
            </div>
          </div>
          <span className="duration-time">
            {duration == null ? '' : formatTime(duration)}
          </span>
        </div>
      </div>
    );
  }
}

export default AudioControls;
