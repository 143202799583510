import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Label } from 'reactstrap';

import { UserNestedCollection } from 'src/types/collection';

interface Props {
  hostCollections: UserNestedCollection[];
  onBlur: (e: React.FocusEvent<HTMLElement>) => void;
  onChange: (e: React.ChangeEvent<HTMLElement>) => void;
  value: string;
}

const CollectionInput = ({
  hostCollections,
  onBlur,
  onChange,
  value,
}: Props) => {
  const { t } = useTranslation();
  if (hostCollections.length === 1) {
    const collection = hostCollections[0];
    return (
      <>
        <Label for="collectionId">{t('conversation_upload.collection')}</Label>
        <p data-testid={`chapter-${collection.id}-value`}>{collection.title}</p>
      </>
    );
  }

  return (
    <>
      <Label for="collectionId">{t('conversation_upload.collection')}</Label>
      <Input
        type="select"
        name="collectionId"
        id="collectionId"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        data-testid="chapter-input"
      >
        {hostCollections.map((collection) => (
          <option
            key={collection.id}
            value={collection.id}
            data-testid={`collection-${collection.id}-option`}
          >
            {collection.title}
          </option>
        ))}
      </Input>
    </>
  );
};

export default CollectionInput;
