import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Container } from 'reactstrap';
import cx from 'classnames';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import ConversationSearchResultCard from 'src/components/ConversationSearchResultCard/ConversationSearchResultCard';
import LoadingOverlay from 'src/components/core/LoadingOverlay/LoadingOverlay';
import Layout from 'src/components/Layout/Layout';
import PagingControl from 'src/components/PagingControl/PagingControl';
import PagingSummary from 'src/components/PagingSummary/PagingSummary';
import SearchInput from 'src/components/SearchInput/SearchInput';
import authSelectors from 'src/redux/auth/auth-selectors';
import searchSelectors from 'src/redux/search/search-selectors';
import { searchConversations as searchConversationsAction } from 'src/redux/search/search-slice';
import { StoreState } from 'src/redux/store';
import { User } from 'src/types/auth';
import { Conversation, Paging } from 'src/types/conversation';
import { getActiveCollection } from 'src/util/collections';
import { makePagingHandlers } from 'src/util/paging';

import styles from './SearchPage.module.scss';

interface StateProps {
  isSearchConversationsLoading: boolean;
  searchConversations: Conversation[];
  searchConversationsError: Error | undefined;
  user: User;
  pagingInfo: Paging | undefined;
}

type Props = RouteComponentProps & StateProps & DispatchProp;

/** Map state from redux to the components props */
const mapStateToProps = (state: StoreState): StateProps => ({
  isSearchConversationsLoading:
    searchSelectors.isSearchConversationsLoading(state),
  searchConversations: searchSelectors.getSearchConversations(state),
  searchConversationsError: searchSelectors.getSearchConversationsError(state),
  user: authSelectors.getUser(state),
  pagingInfo: searchSelectors.getSearchPagingInfo(state),
});

const PAGE_SIZE = 10;

/**
 * The list of search results
 */
const SearchResults: React.FunctionComponent<{
  searchQuery: string | undefined;
  conversations: Conversation[];
  isLoading: boolean;
  error: Error | undefined;
  handleNextPage: () => void;
  handlePrevPage: () => void;
  handleSeekPage: (pageNum: number) => void;
  pagingInfo: Paging | undefined;
}> = ({
  searchQuery,
  conversations,
  isLoading,
  error,
  handleNextPage,
  handlePrevPage,
  handleSeekPage,
  pagingInfo,
}) => {
  const { t } = useTranslation();
  if (!searchQuery) {
    return null;
  }

  return (
    <div className={styles.conversationCardsContainer}>
      {!conversations.length && isLoading ? (
        <div className="position-relative" style={{ minHeight: '40vh' }}>
          <LoadingOverlay active bgColor="transparent" />
        </div>
      ) : (
        <>
          <div className="mb-4">
            <h3 className="mb-2">
              <span>
                <Trans
                  i18nKey={
                    conversations.length > 0
                      ? 'search.results_description'
                      : 'search.results_description_none'
                  }
                  values={{
                    query: searchQuery,
                  }}
                  components={{
                    1: <b />,
                  }}
                />
              </span>
            </h3>
            <PagingSummary
              className="mb-1 text-gray-700"
              itemsPerPage={PAGE_SIZE}
              pagingInfo={pagingInfo}
              i18NextKey="conversations.showing"
              i18NextRangeKey="conversations.showing_range"
            />

            {error && (
              <p className="text-danger">
                {t('common.error_unexpected')}
                {process.env.NODE_ENV !== 'production' && error.message}
              </p>
            )}
          </div>
          <div>
            {conversations.map((conversation) => (
              <ConversationSearchResultCard
                key={conversation.id}
                className="mb-4"
                conversation={conversation}
              />
            ))}
          </div>
          <PagingControl
            className={'mb-5 text-center'}
            itemsPerPage={PAGE_SIZE}
            pagingInfo={pagingInfo}
            onNext={handleNextPage}
            onPrevious={handlePrevPage}
            onSeek={handleSeekPage}
          />
        </>
      )}
    </div>
  );
};

/**
 * Page for when user login information is loading
 * TODO: This page is going to be redone
 * In the mean time, search and the map are here but aren't perfect
 */
const SearchPage = ({
  dispatch,
  isSearchConversationsLoading,
  searchConversations,
  searchConversationsError,
  user,
  pagingInfo,
}: Props) => {
  const { t } = useTranslation();
  // get search query parameters
  const [query, setQuery] = useQueryParams({
    q: StringParam,
    c: NumberParam,
    page: NumberParam,
  });
  const {
    q: searchQuery,
    c: activeCollectionId,
    page: currentPage = 1,
  } = query;
  const activeCollection = getActiveCollection(activeCollectionId, user);

  // load conversations on mount
  React.useEffect(() => {
    if (!activeCollection) {
      return;
    }

    if (searchQuery) {
      dispatch(
        searchConversationsAction({
          page: currentPage,
          limit: PAGE_SIZE,
          searchQuery,
          collectionIds: [activeCollection.id],
        })
      );
    }
  }, [dispatch, searchQuery, activeCollection, currentPage]);

  const handleSearch = (searchQuery: string) => {
    setQuery({ q: searchQuery }, 'pushIn');
  };

  const handleClearSearch = () => {
    if (searchQuery !== '') {
      setQuery({ q: '', page: undefined }, 'pushIn');
    }
  };

  const { handleNextPage, handlePrevPage, handleSeekPage } = makePagingHandlers(
    currentPage,
    PAGE_SIZE,
    pagingInfo ? pagingInfo.total : 0,
    (page: number) => setQuery({ page }, 'pushIn')
  );

  return (
    <Layout
      className="SearchPage"
      title={searchQuery || t('conversations.latest')}
    >
      <Container>
        <section className="mb-5">
          <div className={'text-center mb-5 mt-5'}>
            <h2 className="mb-4">
              {t('search.description')}
              {activeCollection && activeCollection.title}
            </h2>
            <div className={cx(styles.searchInputContainer, 'mx-auto')}>
              <SearchInput
                onSearch={handleSearch}
                onClear={handleClearSearch}
                initialSearchQuery={searchQuery}
                placeholder=""
                size="lg"
                inputClassName="shadow"
                key={searchQuery} /* for refresh if search query changes */
              />
            </div>
          </div>
        </section>

        <SearchResults
          searchQuery={searchQuery}
          conversations={searchConversations}
          isLoading={isSearchConversationsLoading}
          error={searchConversationsError}
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
          handleSeekPage={handleSeekPage}
          pagingInfo={pagingInfo}
        />
      </Container>
    </Layout>
  );
};

// use connect() to get redux to connect to the component
export default connect(mapStateToProps)(SearchPage);
