import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faApple,
  faCreativeCommons,
  faCreativeCommonsBy,
  faGoogle,
  faWindows,
} from '@fortawesome/free-brands-svg-icons';
import {
  faAlignLeft,
  faArrowRight as fasArrowRight,
  faBan,
  faBars,
  faBell,
  faCamera,
  faCaretDown,
  faCaretRight,
  faCheck,
  faChevronDown as fasChevronDown,
  faChevronLeft as fasChevronLeft,
  faChevronUp as fasChevronUp,
  faCircle,
  faCircleCheck,
  faCircleXmark,
  faComment,
  faCommentDots,
  faComments,
  faDownload,
  faEllipsisV,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faGlobe,
  faHighlighter,
  faICursor,
  faLock,
  faMicrophone,
  faPause,
  faPen,
  faPlay,
  faQuestionCircle,
  faSearch,
  faShare,
  faSliders,
  faStar as fasStar,
  faTimesCircle,
  faTrash,
  faUndo as fasUndo,
  faUserFriends,
  faUsers,
  faUserShield,
  faX,
} from '@fortawesome/free-solid-svg-icons';
import {
  faArrowLeft,
  faArrowToBottom,
  faCalendarPlus,
  faCheck as farCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle as farCircle,
  faCopy,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFileAudio,
  faInfoCircle,
  faLoader,
  faMinus,
  faPlus,
  faRedo as farRedo,
  faSlidersSimple,
  faStar as farStar,
  faTimes,
  faTrashCan,
  faUndo as farUndo,
} from '@fortawesome/pro-regular-svg-icons';

// all icons should be added to the application here
// adds font awesome icons globally so they can used as a string in the FontAwesomeIcon component
export function addIcons() {
  library.add(
    // far (regular)
    // eslint-disable-next-line
    // @ts-ignore
    faArrowLeft,
    faArrowToBottom,
    faCalendarPlus,
    farCheck,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCopy,
    faDownload,
    faExclamationCircle,
    faExternalLinkAlt,
    faFileAudio,
    faInfoCircle,
    faQuestionCircle,
    faPlus,
    faMicrophone,
    faMinus,
    faTimes,
    farUndo,
    farRedo,
    farCheck,
    farCircle,
    faEye,
    faEyeSlash,
    faTimes,
    farStar,
    faCircleCheck,
    faCircleXmark,
    faX,
    faBars,
    faSliders,

    // fas (solid)
    fasArrowRight,
    faBan,
    faCamera,
    faCaretDown,
    faCaretRight,
    faCheck,
    fasChevronDown,
    fasChevronUp,
    fasChevronLeft,
    faCircle,
    faComments,
    faDownload,
    faEllipsisV,
    faExclamationTriangle,
    faGlobe,
    faLock,
    faPause,
    faPen,
    faPlay,
    faShare,
    fasStar,
    faUserFriends,
    faUsers,
    faUserShield,
    fasUndo,
    faAlignLeft,
    faComment,
    faCommentDots,
    faExclamation,
    faExclamationCircle,
    faHighlighter,
    faICursor,
    faPause,
    faPen,
    faPlay,
    faSearch,
    fasStar,
    faSlidersSimple,
    faTimesCircle,
    faBell,
    faTrash,
    faTrashCan,
    faLoader,

    // fab (brand)
    faApple,
    faCreativeCommons,
    faCreativeCommonsBy,
    faGoogle,
    faWindows
  );
}
