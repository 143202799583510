import { StoreState } from 'src/redux/store';
import { Conversation, TermTimings } from '../../types/conversation';

// selectors
export const selectors = {
  isLoading: (state: StoreState): boolean =>
    state.conversation.active.isLoading,
  getError: (state: StoreState): Error | undefined =>
    state.conversation.active.error,

  isNewAnnotationSaving: (state: StoreState): boolean =>
    state.conversation.newAnnotation.isSaving,
  isNewAnnotationSaved: (state: StoreState): boolean =>
    state.conversation.newAnnotation.isSaved,
  getNewAnnotationError: (state: StoreState): Error | undefined =>
    state.conversation.newAnnotation.error,
  getNewAnnotationCreationTag: (state: StoreState): string | undefined =>
    state.conversation.newAnnotation.creationTag,

  isSavingEditConversation: (state: StoreState): boolean =>
    state.conversation.editConversation.isSaving,
  isSavedEditConversation: (state: StoreState): boolean =>
    state.conversation.editConversation.isSaved,
  getErrorEditConversation: (state: StoreState): Error | undefined =>
    state.conversation.editConversation.error,

  isSavingEditConversationDraftState: (state: StoreState): boolean =>
    state.conversation.editConversationDraftState.isSaving,
  isSavedEditConversationDraftState: (state: StoreState): boolean =>
    state.conversation.editConversationDraftState.isSaved,
  getErrorEditConversationDraftState: (state: StoreState): Error | undefined =>
    state.conversation.editConversationDraftState.error,
  getEditDraftStateConversationId: (
    state: StoreState
  ): Conversation['id'] | undefined =>
    state.conversation.editConversationDraftState.conversationId,

  getTopTerms: (state: StoreState): TermTimings[] | undefined =>
    state.conversation.topTerms.topTerms,
  isLoadingTopTerms: (state: StoreState): boolean =>
    state.conversation.topTerms.isLoading,
  getTopTermsError: (state: StoreState): Error | undefined =>
    state.conversation.topTerms.error,
};
export default selectors;
