import * as React from 'react';
import { Popover } from 'reactstrap';
import cx from 'classnames';
import nanoid from 'nanoid';

import Button from 'src/components/core/Button/Button';
import CrossPollinatedConversationList from 'src/components/CrossPollinatedConversationList/CrossPollinatedConversationList';
import CrossPollinationLabel from 'src/components/CrossPollinationLabel/CrossPollinationLabel';
import { CrossPollinatedConversation } from 'src/types/conversation';

import styles from './HighlightCrossPollinationDropdown.module.scss';

interface Props {
  numberOfCrossPollinations: number;
  crossPollinatedConversations: CrossPollinatedConversation[];
  className?: string | undefined;
}

const HighlightCrossPollinationDropdown = ({
  numberOfCrossPollinations,
  crossPollinatedConversations,
  className,
}: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  // make a unique ID for each button to target
  const buttonId = React.useMemo(() => 'highlightTypeButton-' + nanoid(), []);

  return (
    <div
      className={cx(className, 'd-inline-block px-2')}
      data-testid="highlight-type-dropdown"
    >
      <Button
        className={cx(styles.dropdownButton, 'fwbold small-header')}
        onClick={handleToggle}
        id={buttonId}
        compact
      >
        <CrossPollinationLabel
          numberOfCrossPollinations={numberOfCrossPollinations}
          className="me-1"
        />
      </Button>

      <Popover
        placement="bottom-end"
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        size="sm"
        target={buttonId}
        trigger="legacy" // allows clicking inside as well as going away when clicking outside
        hideArrow
      >
        <CrossPollinatedConversationList
          numberOfCrossPollinations={numberOfCrossPollinations}
          crossPollinatedConversations={crossPollinatedConversations}
        />
      </Popover>
    </div>
  );
};

export default HighlightCrossPollinationDropdown;
