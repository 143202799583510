import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment, { Moment } from 'moment';
import nanoid from 'nanoid';
import {
  call,
  delay,
  fork,
  put,
  SagaReturnType,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import * as api from 'src/api/api';
import { actions as conversationActions } from 'src/redux/conversation/conversation-slice';
import { Snippet } from 'src/types/conversation';
import { NormalizedEntities, NormalizedSnippetEntities } from 'src/types/core';
import selectors from './transcript-edit-selectors';

// Amount of time (in seconds) to wait before save
export const SAVE_INTERVAL = 3;

interface EditPayload {
  snippets: Partial<Snippet>[];
}
export interface RedactionPayload {
  snippets: Partial<Snippet>[];
  redaction: boolean;
  audio_start_offset: number;
  audio_end_offset: number;
}
interface ResetPayload {
  reset: boolean;
}

type SavingPayload = EditPayload | ResetPayload | RedactionPayload;

export interface TranscriptEditTransaction {
  payload: SavingPayload;
  id: string;
}
interface TranscriptEditState {
  version: number | undefined;
  conversationId: number;
  isSaving: boolean;
  error: Error | undefined;
  isResetting: boolean;
  isRedacting: boolean;
  redactionTime: number | undefined;
  transactions: TranscriptEditTransaction[];
  saveTime: Moment | undefined;
}

const initialState: TranscriptEditState = {
  version: undefined,
  conversationId: -1,
  isSaving: false,
  error: undefined,
  isResetting: false,
  isRedacting: false,
  redactionTime: undefined,
  transactions: [],
  saveTime: undefined,
};

const slice = createSlice({
  name: 'transcript-edit',
  initialState,
  reducers: {
    saveTranscriptEdit(state, action: PayloadAction<SavingPayload>) {
      // LIFO queue for transactions
      const cleanedTransactions = cleanTransactions(
        state.transactions.concat([{ payload: action.payload, id: nanoid() }])
      );
      state.transactions = cleanedTransactions;
      if (
        (
          cleanedTransactions[cleanedTransactions.length - 1]
            .payload as ResetPayload
        ).reset
      ) {
        state.isResetting = true;
      }
      if (
        (
          cleanedTransactions[cleanedTransactions.length - 1]
            .payload as RedactionPayload
        ).redaction
      ) {
        state.isRedacting = true;
      }
    },
    saveTranscriptEditSuccess(
      state,
      action: PayloadAction<
        NormalizedSnippetEntities & { transactionId?: string }
      >
    ) {
      const priorTransaction = state.transactions.find(
        (t) => t.id == action.payload.transactionId
      );
      if ((priorTransaction?.payload as ResetPayload).reset) {
        state.isResetting = false;
      }
      if ((priorTransaction?.payload as RedactionPayload).redaction) {
        state.isRedacting = false;
        state.redactionTime = (
          priorTransaction?.payload as RedactionPayload
        ).audio_start_offset;
      }
      // Clean transactions and add new version
      const cleanedTransactions = cleanTransactions(
        state.transactions,
        action.payload.transactionId
      );
      state.version = action.payload.version;
      state.transactions = cleanedTransactions;
    },
    saveTranscriptEditFailure(
      state,
      action: PayloadAction<{ error: Error } & { transactionId?: string }>
    ) {
      // Completely re-initialize state on failure. User will need to refresh page
      state.isResetting = false;
      state.isSaving = false;
      state.error = action.payload.error;
      state.transactions = [];
    },
    endTransactionProcessing(state) {
      state.isSaving = false;
      state.error = undefined;
      state.isResetting = false;
      state.saveTime = moment();
    },
    startTransactionProcessing(state) {
      state.isSaving = true;
      state.error = undefined;
    },
    clearRedactionTime(state) {
      state.redactionTime = undefined;
    },
  },
  extraReducers: (builder) => {
    // extra case because version comes from the conversation detail endpoint
    builder.addCase(
      conversationActions.loadConversationSuccess,
      (state, action: PayloadAction<NormalizedEntities>) => {
        const conversationId = action.payload.order.conversations[0];
        const conversationEntities = action.payload.entities.conversations;
        state.version =
          conversationEntities[conversationId].transcript_edit_version;
        state.conversationId = conversationEntities[conversationId].id;
      }
    );
  },
});

export const {
  saveTranscriptEdit,
  saveTranscriptEditSuccess,
  saveTranscriptEditFailure,
  endTransactionProcessing,
  startTransactionProcessing,
  clearRedactionTime,
} = slice.actions;
export const actions = slice.actions;
export default slice.reducer;

/** Sagas */
export function* sagaStartTransactionProcessing() {
  // Delay the save by
  yield delay(SAVE_INTERVAL * 1000);
  const isSaving: boolean = yield select(selectors.isSaving);
  if (isSaving) {
    // Skip, as a saga chain on the transactions list has begun
    return;
  } else {
    yield put(startTransactionProcessing());
  }
}

export function* sagaSaveTranscriptEdit(): any {
  const transaction: TranscriptEditTransaction | undefined = yield select(
    selectors.getCurrentTransaction
  );
  if (transaction == null) {
    yield put(endTransactionProcessing());
    return;
  }
  try {
    const version: number | undefined = yield select(selectors.getVersion);
    if (version == null) {
      throw Error('No version is defined, cannot make edit');
    }
    const conversationId: number = yield select(selectors.getConversationId);
    let res: SagaReturnType<typeof api.saveTranscriptEdit>;
    if ((transaction.payload as ResetPayload).reset) {
      res = yield call(api.resetTranscript, {
        version,
        conversationId,
        ...transaction.payload,
      });
    } else if ((transaction.payload as RedactionPayload).redaction) {
      res = yield call(api.redactionTranscriptEdit, {
        version,
        conversationId,
        ...(transaction.payload as RedactionPayload),
      });
    } else {
      res = yield call(api.saveTranscriptEdit, {
        version,
        conversationId,
        ...(transaction.payload as EditPayload),
      });
    }
    yield put(
      saveTranscriptEditSuccess({ ...res, transactionId: transaction.id })
    );
    yield fork(sagaSaveTranscriptEdit);
  } catch (err) {
    yield put(
      saveTranscriptEditFailure({
        error: err as Error,
        transactionId: transaction.id,
      })
    );
  }
}

export const sagas = [
  takeLatest(saveTranscriptEdit.type, sagaStartTransactionProcessing),
  takeEvery(startTransactionProcessing.type, sagaSaveTranscriptEdit),
];

export const cleanTransactions = (
  transactions: TranscriptEditTransaction[],
  currentTransactionId?: string
) => {
  // Initialize a copy of the transactions
  let newTranscations = [...transactions];

  // Always make a reset transaction last (the first to be processed)
  newTranscations.sort((a, b) => {
    if ((b.payload as ResetPayload).reset) {
      return -1;
    }
    return 0;
  });

  // Remove Transactions that are before the currentTransactionId
  // as well as the current transaction.
  if (currentTransactionId) {
    const currentIndex = newTranscations.findIndex(
      (transaction) => transaction.id === currentTransactionId
    );
    newTranscations = newTranscations.slice(currentIndex + 1);
  }

  return newTranscations;
};
