import React from 'react';
import cx from 'classnames';

import TranscriptRoll from 'src/components/TranscriptRoll/TranscriptRoll';
import { Snippet } from 'src/types/conversation';
import { getActiveSnippet } from 'src/util/snippets';
import SpeakerPlay from './SpeakerPlay/SpeakerPlay';

import styles from './SpeakerTranscriptRoll.module.scss';

interface Props {
  seekTime: number | undefined;
  snippets: Snippet[];
  locationName: string;
  startTime: number;
  endTime: number;
  audioDuration: number;
  className?: string | undefined;
  numVisibleWords?: number;
  onSeek?: (seekTime: number) => void;
  isActive: boolean;
  isPlaying: boolean;
  isLoading: boolean;
  audioError: MediaError | undefined;
  onPlay: () => void;
  hideTranscript?: boolean;
  emphasizedTerms?: string[];
  tabIndex?: number;
}

const SpeakerTranscriptRoll = ({
  seekTime,
  snippets = [],
  locationName,
  startTime,
  endTime,
  audioDuration,
  className,
  onSeek,
  isActive,
  isPlaying,
  isLoading,
  audioError,
  onPlay,
  hideTranscript,
  emphasizedTerms,
  tabIndex,
}: Props) => {
  const activeSnippet = getActiveSnippet(seekTime, snippets, startTime);

  if (!activeSnippet) {
    return null;
  }

  return (
    <div className={cx(className)}>
      <SpeakerPlay
        speakerName={activeSnippet.speaker_name}
        locationName={locationName}
        seekTime={seekTime}
        startTime={startTime}
        isActive={isActive}
        isLoading={isLoading}
        audioError={audioError}
        isPlaying={isPlaying}
        onPlay={onPlay}
        audioDuration={audioDuration}
        tabIndex={tabIndex}
      />
      {!hideTranscript && (
        <div className="d-flex pt-2">
          <div className={cx(styles.rectangle, 'me-2')}></div>
          <TranscriptRoll
            snippets={snippets}
            seekTime={seekTime}
            startTime={startTime}
            endTime={endTime}
            onSeek={onSeek}
            audioIsLoaded={isActive && !isLoading}
            className="w-100 ms-1"
            emphasizedTerms={emphasizedTerms}
            compact
          />
        </div>
      )}
    </div>
  );
};

export default SpeakerTranscriptRoll;
