import { toast } from 'react-toastify';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { call, put, SagaReturnType, takeLatest } from 'redux-saga/effects';

import * as api from 'src/api/api';
import { Catalog, CatalogsEntities } from 'src/types/insights';

interface InsightsState {
  catalogs: { [key: string]: Catalog };
  error: Error | undefined;
  isLoading: boolean;
}

const initialState: InsightsState = {
  catalogs: {},
  error: undefined,
  isLoading: false,
};

const onRequestInitiated = (state: InsightsState, loading = false) => {
  state.error = undefined;
  state.isLoading = loading;
};

const onRequestFailure = (
  state: InsightsState,
  action: PayloadAction<Error>,
  message: string
) => {
  state.error = action.payload;
  state.isLoading = false;
  toast.error(message, { position: toast.POSITION.BOTTOM_RIGHT });
};

const onRequestSuccess = (state: InsightsState) => {
  state.error = undefined;
  state.isLoading = false;
};

const slice = createSlice({
  name: 'catalogs',
  initialState,
  reducers: {
    loadCatalogs(state) {
      onRequestInitiated(state, true);
    },
    loadCatalogsSuccess(state, action: PayloadAction<CatalogsEntities>) {
      onRequestSuccess(state);
      state.catalogs = action.payload.catalogs || {};
    },
    loadCatalogsFailure(state, action: PayloadAction<Error>) {
      onRequestFailure(state, action, 'Failed to load catalogs.');
    },
    createCatalog(
      state,
      action: PayloadAction<{
        data: Omit<Catalog, 'id'>;
        callback: (id: Catalog['id']) => void;
      }>
    ) {
      onRequestInitiated(state, true);
    },
    createCatalogFailure(state, action: PayloadAction<Error>) {
      onRequestFailure(state, action, 'Failed to create catalog.');
    },
  },
});

export const {
  createCatalog,
  createCatalogFailure,
  loadCatalogs,
  loadCatalogsFailure,
  loadCatalogsSuccess,
} = slice.actions;

export const actions = slice.actions;

export default slice.reducer;

export function* sagaCreateCatalog(action: ReturnType<typeof createCatalog>) {
  const request = action.payload.data;
  try {
    const response: SagaReturnType<typeof api.createCatalog> = yield call(
      api.createCatalog,
      request
    );
    yield call(action.payload.callback, response.catalog_id);
  } catch (err) {
    yield put(createCatalogFailure(err as Error));
  }
}

export function* sagaLoadCatalogs(action: ReturnType<typeof loadCatalogs>) {
  try {
    const catalogs: SagaReturnType<typeof api.getCatalogs> = yield call(
      api.getCatalogs
    );
    yield put(loadCatalogsSuccess(catalogs));
  } catch (err) {
    yield put(loadCatalogsFailure(err as Error));
  }
}

export const sagas = [
  takeLatest(loadCatalogs.type, sagaLoadCatalogs),
  takeLatest(createCatalog.type, sagaCreateCatalog),
];
