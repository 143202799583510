import React from 'react';
import { useTranslation } from 'react-i18next';
import { Popover } from 'reactstrap';
import nanoid from 'nanoid';

import Button from 'src/components/core/Button/Button';
import CreateHighlightForm from 'src/components/CreateHighlightForm/CreateHighlightForm';
import Restrict from 'src/components/Restrict/Restrict';
import {
  AnnotationCreationSelection,
  Conversation,
  TranscriptSnippet,
} from 'src/types/conversation';
import { getPermissionToCreateHighlight } from 'src/util/user';
import FixTranscriptForm from './FixTranscriptForm/FixTranscriptForm';

import styles from './CreateAnnotationControls.module.scss';

interface Props {
  annotationCreationSelection: AnnotationCreationSelection | undefined;
  onPersistSelection: (persistSelection: boolean) => void;
  onClearSelection: () => void;
  conversationId: number;
  annotatedSnippets: TranscriptSnippet[];
  onPlay?: (seekTime?: number, endTime?: number) => void;
  seekTime?: number | undefined;
  conversation: Conversation;
}

export default function CreateAnnotationControls({
  annotationCreationSelection,
  onPersistSelection,
  onClearSelection,
  conversationId,
  annotatedSnippets,
  onPlay,
  seekTime,
  conversation,
}: Props) {
  const { t } = useTranslation();
  // FIXME: use a string ID for popper since dom references are broken in reactstrap 8.1.1
  // https://github.com/reactstrap/reactstrap/issues/1686
  const popperId = React.useMemo(() => `popper-${nanoid()}`, []);
  const [popoverType, setPopoverType] = React.useState<
    'create-highlight' | 'fix-transcript'
  >('create-highlight');
  const [popoverIsOpen, setPopoverIsOpen] = React.useState(false);

  const handleCloseCreatePopover = () => {
    setPopoverIsOpen(false);
    onClearSelection();
  };

  const handleStartCreateHighlight = () => {
    onPersistSelection(true);
    setPopoverIsOpen(true);
    setPopoverType('create-highlight');
  };

  const handleStartCreateTranscriptCorrection = () => {
    onPersistSelection(true);
    setPopoverIsOpen(true);
    setPopoverType('fix-transcript');
  };

  const handleSaveNewHighlight = () => {
    onClearSelection();
  };

  const handleSaveNewTranscriptCorrection = () => {
    onClearSelection();
  };

  // if we have a selection and it's ready, open the popover
  const controlsRef = React.useRef<HTMLDivElement | null>(null);

  const collection = conversation.collection;
  // different permission required to make a highlight depending on convo privacy level
  const permToCreateHighlight = getPermissionToCreateHighlight(
    conversation.privacy_level === 'protected',
    collection.id
  );

  let popoverContent;
  if (annotationCreationSelection && controlsRef.current) {
    if (popoverType === 'create-highlight') {
      popoverContent = (
        <CreateHighlightForm
          key={annotationCreationSelection.creation_tag} // need to reset on new selection
          startTime={annotationCreationSelection.audio_start_offset}
          endTime={annotationCreationSelection.audio_end_offset}
          conversationId={conversationId}
          conversation={conversation}
          onFinish={handleSaveNewHighlight}
          onCancel={handleCloseCreatePopover}
        />
      );
    } else if (popoverType === 'fix-transcript') {
      popoverContent = (
        <FixTranscriptForm
          key={annotationCreationSelection.creation_tag} // need to reset on new selection
          creationTag={annotationCreationSelection.creation_tag}
          startTime={annotationCreationSelection.audio_start_offset}
          endTime={annotationCreationSelection.audio_end_offset}
          conversationId={conversationId}
          onFinish={handleSaveNewTranscriptCorrection}
          snippets={annotatedSnippets}
          onCancel={handleCloseCreatePopover}
          onPlay={onPlay}
          seekTime={seekTime}
          className="p-2"
          conversation={conversation}
        />
      );
    }
  }

  return (
    <React.Fragment>
      <div
        ref={controlsRef}
        className={styles.controlsBox}
        id={popperId}
        data-testid="createAnnotationControl"
      >
        <Restrict permission={permToCreateHighlight}>
          <Button
            color="primary"
            icon={['fas', 'highlighter']}
            className="w-100 mb-1"
            onClick={handleStartCreateHighlight}
            data-testid="createHighlightButton"
          >
            {t('highlight.header')}
          </Button>
        </Restrict>
        {/* Commenting the following out until there's a mechanism in place for users w/o edit access to request edit access from their org admin  */}
        {/* TODO: Fix unit tests in CreateAnnotationControls.test.tsx */}
        {/* TODO: Fix cypress tests in transcript.interactive.test.ts */}
        {/* <Restrict atMostRole={getHighlighterRole()}>
          <Button
            color="primary"
            outline
            icon={['fas', 'i-cursor']}
            onClick={handleStartCreateTranscriptCorrection}
            data-testid="createTranscriptCorrectionButton"
          >
            {t('conversation.highlight_button_transcript')}
          </Button>
        </Restrict> */}
      </div>
      {annotationCreationSelection && controlsRef.current && (
        <Popover
          placement="bottom-end"
          isOpen={popoverIsOpen}
          trigger="click"
          target={popperId} // was {controlsRef.current} (https://github.com/reactstrap/reactstrap/issues/1686)
          toggle={handleCloseCreatePopover}
          className={
            popoverType === 'create-highlight'
              ? styles.createHighlightPopover
              : styles.fixTranscriptPopover
          }
          hideArrow
          delay={0}
        >
          {popoverContent}
        </Popover>
      )}
    </React.Fragment>
  );
}
