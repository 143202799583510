import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Collapse, Form, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import Button from 'src/components/core/Button/Button';
import { useAnalyticsContext } from 'src/Providers/AnalyticsProvider';
import { Action, Category, Name } from 'src/types/analytics';
import Layout from '../../Layout/Layout';
import {
  checkFeatureSupport,
  checkForSessionMetadata,
} from '../recording_room_helpers';

import styles from './RecordingRoomSetUp.module.scss';

const RecordingRoomSetUp = () => {
  const [userMics, setUserMics] = useState<MediaDeviceInfo[]>([]);
  const [micPermissionStatus, setMicPermissionStatus] = useState('');
  const [canProceedWithRecording, setCanProceedWithRecording] = useState(false);
  const [featureWarning, setFeatureWarning] = useState(false);
  const [selectedMicId, setSelectedMicId] = useState('');
  const [savedNewMicId, setSavedNewMicId] = useState('');
  const [micSelectIsOpen, setMicSelectIsOpen] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (checkForSessionMetadata()) {
      if (!checkFeatureSupport()) {
        setFeatureWarning(true);
      }
      checkMicPermission();
      getAvailableMicrophones();
    } else {
      history.push('/conversation-record');
    }
  }, [micPermissionStatus, selectedMicId]);

  const grabUserMicInfo = () => {
    checkMicPermission();
    getAvailableMicrophones();
  };

  const requestMicPermission = async () => {
    // only for when status is "prompt"
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      console.log('Microphone access granted.');
    } catch (error) {
      console.log('Microphone access denied or error:', error);
    }

    grabUserMicInfo();
  };

  const alternativeMicAccessCheck = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      stream.getTracks().forEach((track) => track.stop());
      setMicPermissionStatus('granted');
    } catch (error) {
      setMicPermissionStatus('denied');
    }
  };

  const checkMicPermission = async () => {
    try {
      const permissionStatus = await navigator.permissions.query({
        name: 'microphone' as PermissionName,
      });
      setMicPermissionStatus(permissionStatus.state);

      if (permissionStatus.state === 'prompt') {
        requestMicPermission();
      }
    } catch (error) {
      alternativeMicAccessCheck();
      console.log(error);
    }
  };

  const getAvailableMicrophones = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      if (devices.length >= 1) {
        const microphones = devices.filter(
          (device) => device.kind === 'audioinput'
        );
        console.log('all user mics', microphones);

        setUserMics(microphones);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const toggleMicSelect = () => setMicSelectIsOpen(!micSelectIsOpen); // toggles Collapse

  const handleMicChange = (event: any) => {
    console.log('select value', event.target.value);
    const audioDeviceId = event.target.value;
    setSelectedMicId(audioDeviceId);
  };

  const { analyticsEvent } = useAnalyticsContext();
  const saveNewMic = () => {
    analyticsEvent({
      category: Category.LiveRecording,
      action: Action.StatusChange,
      name: Name.SelectNewMicrophone,
    });

    setSavedNewMicId(selectedMicId);
    sessionStorage.setItem('selectedMicID', selectedMicId);
    toggleMicSelect();
  };

  const cancelNewMic = () => {
    savedNewMicId === ''
      ? setSelectedMicId('')
      : setSelectedMicId(savedNewMicId);
    toggleMicSelect();
  };

  const MicrophoneInfoCard = () => {
    let content = null;
    const userHasMics: boolean = userMics.length >= 1;

    console.log(userMics);
    let micInUse = null;
    let micOptions = null;
    let userSelectedMic = null;

    if (savedNewMicId !== '') {
      const newMic = userMics.find((mic) => mic.deviceId === savedNewMicId);
      if (newMic) {
        const micLabel = newMic.label;
        userSelectedMic = micLabel === '' ? newMic.deviceId : micLabel;
      }
    }

    if (userHasMics) {
      // Depends on browser if the deviceId is "default"
      micInUse = userMics.find((mic) => mic.deviceId === 'default');
      micInUse = micInUse ?? userMics[0];

      micOptions = userMics.map((mic) => (
        <option key={mic.deviceId} value={mic.deviceId}>
          {mic.label === '' ? mic.deviceId : mic.label}
        </option>
      ));

      if (micPermissionStatus === 'granted') {
        setCanProceedWithRecording(true);
      }
    }

    if (micPermissionStatus === 'granted' && !userHasMics) {
      content = (
        <div className={styles.noMicsAvailable}>
          {t('live_recording.no_microphone_detected')}
        </div>
      );
    } else if (micPermissionStatus === 'granted' && userHasMics) {
      content = (
        <div>
          {micInUse && !userSelectedMic && (
            <>
              <div>{t('live_recording.microphone_connected_label')}</div>
              <div className={styles.currentMic}>
                {micInUse.label === '' ? micInUse.deviceId : micInUse.label}
              </div>
            </>
          )}
          {userSelectedMic && (
            <>
              <div>{t('live_recording.microphone_selected_label')}</div>
              <div className={styles.currentMic}>{userSelectedMic}</div>
            </>
          )}
          <div className={styles.selectMicContainer}>
            <Button
              color="secondary"
              size="sm"
              onClick={toggleMicSelect}
              className={styles.toggleMicSelectButton}
              icon={['fas', 'microphone']}
            >
              {t('live_recording.update_microphone_button')}
            </Button>
            <Collapse isOpen={micSelectIsOpen}>
              <Form>
                <Input
                  type="select"
                  value={selectedMicId || 'default'}
                  onChange={handleMicChange}
                >
                  {micOptions}
                </Input>
                <div className={styles.micSelectFooter}>
                  <Button
                    color="primary"
                    size="sm"
                    className={cx(styles.updateMicButton, 'w-100')}
                    onClick={saveNewMic}
                  >
                    {t('live_recording.save_new_microphone_button')}
                  </Button>
                  <Button
                    color="secondary"
                    size="sm"
                    className={cx(styles.updateMicButton, 'w-100')}
                    onClick={cancelNewMic}
                  >
                    {t('live_recording.cancel_new_microphone_button')}
                  </Button>
                </div>
              </Form>
            </Collapse>
          </div>
        </div>
      );
    } else if (micPermissionStatus === 'denied') {
      // when permission is not "granted", it can either have a status of "denied" or "prompt"
      content = (
        <div>
          <div>{t('live_recording.denied_microphone_message')}</div>
        </div>
      );
    } else if (micPermissionStatus === 'prompt') {
      content = (
        <div>
          <div>{t('live_recording.prompt_microphone_message')}</div>
        </div>
      );
    } else {
      content = (
        <div>{t('live_recording.warning_cannot_access_microphones')}</div>
      );
    }
    return (
      <div>
        <div
          className={styles.checkmarkIconWrapper}
          data-testid="checkmarkIcon"
        >
          <FontAwesomeIcon
            icon={[
              'fas',
              canProceedWithRecording ? 'circle-check' : 'circle-xmark',
            ]}
            className={cx(
              styles.checkIcon,
              canProceedWithRecording ? 'text-primary' : 'text-secondary'
            )}
            size="lg"
          />
        </div>
        <div className={styles.currentMicContainer}>{content}</div>
      </div>
    );
  };

  const returnToForm = () => {
    sessionStorage.clear();
    history.push('/conversation-record');
  };

  const handleNavigateToRecordingRoom = () => {
    const mic = userMics.find((mic) => {
      return mic.deviceId === (selectedMicId || 'default');
    });
    if (mic) {
      // handles when a user has manually selected a mic or they are using their "default"
      sessionStorage.setItem('selectedMicLabel', mic.label);
    } else {
      // handles when a user is using their default mic that has no "default" as an id.
      sessionStorage.setItem(
        'selectedMicLabel',
        userMics[0].label || userMics[0].deviceId
      );
    }

    analyticsEvent({
      category: Category.LiveRecording,
      action: Action.Click,
      name: Name.StartRecordingButton,
    });

    history.push('/recording-room');
  };

  return (
    <Layout title="Recording Room SetUp" className={'recordingRoomSetUp'}>
      {featureWarning && (
        <div
          className={styles.recordingSetupWarningBadgeContainer}
          role="banner"
        >
          <h6 className={styles.recordingSetupWarningBadge}>
            {t('live_recording.warning_device_or_browser')}
          </h6>
        </div>
      )}
      <div className="recording-room-setup-nav">
        <Button
          color="plain"
          className="me-2 mb-2"
          icon={['fas', 'chevron-left']}
          onClick={returnToForm}
        >
          Back
        </Button>
      </div>
      <div>
        <div className={styles.microphoneContainer}>
          {<MicrophoneInfoCard />}
        </div>
        <div className={styles.instructionsContainer}>
          <h6>{t('live_recording.instructions_heading')}</h6>
          <ol>
            <li>{t('live_recording.instruction_one_microphone_support')}</li>
            <li>
              {t('live_recording.instruction_two_device_browser_compatibility')}
            </li>
            <li>{t('live_recording.instruction_three_update_microphone')}</li>
            <li>{t('live_recording.instruction_four_keep_tab_open')}</li>
            <li>
              {t('live_recording.instruction_five_three_hour_time_limit')}
            </li>
          </ol>
        </div>
        <div className={styles.setUpFooter}>
          <Button
            color={micPermissionStatus === 'granted' ? 'primary' : 'secondary'}
            disabled={!canProceedWithRecording || micSelectIsOpen}
            className="me-2 mb-2"
            onClick={handleNavigateToRecordingRoom}
          >
            {t('live_recording.begin_recording_button')}
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default RecordingRoomSetUp;
