import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

type Props = {
  anchor: Element | null;
  closeMenu: () => void;
  label: string;
  openDialog: (key: string) => () => void;
};

const CodeAccordionMenu: React.FunctionComponent<Props> = (props: Props) => {
  const { anchor, closeMenu, label, openDialog } = props;
  const { t } = useTranslation();

  return (
    <Menu
      anchorEl={anchor}
      onClose={() => {
        closeMenu();
      }}
      open={Boolean(anchor)}
      MenuListProps={{ disablePadding: true }}
      data-testid="code-menu"
    >
      <MenuList sx={{ py: 0 }}>
        <MenuItem
          key="update-code-menu-item"
          onClick={openDialog('update')}
          sx={{ py: 1 }}
          data-testid="update-code"
        >
          <ListItemText>
            {t('insights.edit_value', { value: label })}
          </ListItemText>
        </MenuItem>
        <MenuItem
          key="delete-code-menu-item"
          data-testid="delete-code"
          onClick={openDialog('delete')}
          sx={{ py: 1 }}
        >
          <ListItemText>
            {t('insights.delete_value', { value: label })}
          </ListItemText>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default CodeAccordionMenu;
