import * as React from 'react';

import { User } from 'src/types/auth';
import { getActiveCollection } from './collections';

// Store values in local storage with same API as useState
// https://usehooks.com/useLocalStorage/
export function useLocalStorage(key: string, initialValue: any) {
  return useStorage(key, initialValue, 'local');
}

export function useSessionStorage(key: string, initialValue: any) {
  return useStorage(key, initialValue, 'session');
}

function useStorage(key: string, initialValue: any, type: 'local' | 'session') {
  const storage =
    type === 'session' ? window.sessionStorage : window.localStorage;

  // State to store our value
  const [storedValue, setStoredValue] = React.useState(() => {
    try {
      const item = storage.getItem(key);

      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that
  // persists the new value to localStorage.
  const setValue = (value: any) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;

      setStoredValue(valueToStore);
      storage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error(`Error setting storage: ${error}`);
    }
  };

  return [storedValue, setValue];
}

/**
 * Given a user and an optional collection ID, will return the active collection object.
 *
 * This hook handles the getting/setting of session storage values and updating when activeCollectionId changes, but uses getActiveCollection for the determination of which collection is active.
 *
 * disableAllCollections is activated whenever there is a likelyhood that pulling the data for all collections may cause performance issues in a backend service.
 */
export function useActiveCollection(
  user: User,
  activeCollectionId?: number,
  disableAllCollections?: boolean
) {
  // we store the last seen collection ID in local storage so it is persisted
  // even after the user navigates to a URL that doesn't have it.
  // use the id from the URL if it exists, otherwise fall back to local storage
  const [collectionId, setCollectionId] = useSessionStorage(
    'collectionId',
    activeCollectionId || window.sessionStorage.getItem('collectionId')
  );
  const activeCollection = getActiveCollection(
    activeCollectionId,
    user,
    disableAllCollections
  ) as User['collections'][0];

  // keep last collection ID in sync with query param
  // (only matters if the collection changes by something other than the dropdown)
  React.useEffect(() => {
    // only update the last seen collection if it is available in the URL
    if (activeCollectionId != null && activeCollectionId !== collectionId) {
      setCollectionId(activeCollectionId);
    }
  }, [activeCollectionId, collectionId, setCollectionId]);

  return activeCollection;
}

/**
 * This function uses the intersection observer pattern to decide whether or not an element is on screen
 * @param ref The ref for the element that you want to check the onScreen status of
 * @returns boolean indicating on screen status
 */
export default function useOnScreen(ref: React.RefObject<any>) {
  const [isIntersecting, setIntersecting] = React.useState<boolean>(false);

  React.useEffect(() => {
    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting)
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, [ref, setIntersecting]);

  return isIntersecting;
}
