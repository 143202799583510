import { StoreState } from 'src/redux/store';
import { OrganizationMetadata } from 'src/types/organization';

export const selectors = {
  isLoading: (state: StoreState): boolean =>
    state.organizations.metadata.isLoading,
  getOrganizations: (state: StoreState): OrganizationMetadata[] | undefined =>
    state.organizations.metadata.organizations,
  getOrganizationsError: (state: StoreState): Error | undefined =>
    state.organizations.metadata.error,
};
export default selectors;
