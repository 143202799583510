import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import cx from 'classnames';

interface Props {
  onChange: (speed: number) => void;
  speed: number;
  disabled?: boolean;
  size?: string;
  minimal?: boolean;
  className?: string;
}

const PlaybackSpeed = (props: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const { speed, onChange, disabled, minimal, ...other } = props;

  return (
    <Dropdown
      disabled={disabled}
      isOpen={isOpen}
      toggle={handleToggle}
      {...other}
    >
      <DropdownToggle
        disabled={disabled}
        color="default"
        caret={!minimal}
        className={cx({ fwbold: minimal })}
      >
        {minimal ? `${speed}x` : `Speed ${speed}x`}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem header>{t('audio_player.playback_speed')}</DropdownItem>
        <DropdownItem className="w-100" onClick={() => onChange(0.5)}>
          0.5
        </DropdownItem>
        <DropdownItem className="w-100" onClick={() => onChange(0.75)}>
          0.75
        </DropdownItem>
        <DropdownItem className="w-100" onClick={() => onChange(1)}>
          {t('audio_player.playback_speed_normal')} (1x)
        </DropdownItem>
        <DropdownItem className="w-100" onClick={() => onChange(1.25)}>
          1.25x
        </DropdownItem>
        <DropdownItem className="w-100" onClick={() => onChange(1.5)}>
          1.5x
        </DropdownItem>
        <DropdownItem className="w-100" onClick={() => onChange(1.75)}>
          1.75x
        </DropdownItem>
        <DropdownItem className="w-100" onClick={() => onChange(2)}>
          2x
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default PlaybackSpeed;
