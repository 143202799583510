import * as React from 'react';
import { useTranslation } from 'react-i18next';

import ConversationHealthMetrics from 'src/components/ConversationHealthMetrics/ConversationHealthMetrics';
import { Conversation } from 'src/types/conversation';
import PublishSection from '../PublishSection/PublishSection';

interface Props {
  conversation: Conversation;
  canChangeDraftStatus: boolean;
  onChangeDraftStatus: (isDraft: boolean) => void;
  isSavingDraftState: boolean;
  isSavedDraftState: boolean;
  errorDraftState: Error | undefined;
  userIsStaff: boolean;
  className?: string;
}
const StaffFeatures = ({
  conversation,
  canChangeDraftStatus,
  onChangeDraftStatus,
  isSavedDraftState,
  isSavingDraftState,
  errorDraftState,
  userIsStaff,
  className,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className={className}>
      {canChangeDraftStatus && (
        <PublishSection
          conversation={conversation}
          onChangeDraftStatus={onChangeDraftStatus}
          isSaved={isSavedDraftState}
          isSaving={isSavingDraftState}
          error={errorDraftState}
          className="mb-5"
        />
      )}

      {userIsStaff && (
        <section data-testid="conversation-metrics-section">
          <h4>{t('conversation.admin_metrics')}</h4>
          <ConversationHealthMetrics conversation={conversation} />
        </section>
      )}
    </div>
  );
};

export default StaffFeatures;
