import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Conversation, Highlight } from 'src/types/conversation';
import { isConversationProtected } from 'src/util/conversation';

interface Props {
  highlight: Highlight;
  iconOnly?: boolean;
  className?: string | undefined;
  conversation?: Conversation;
}

const HighlightTypeLabel = ({
  highlight,
  iconOnly,
  conversation,
  className,
}: Props) => {
  const { t } = useTranslation();
  const defaultProps: { fixedWidth: boolean; size: 'sm' } = {
    fixedWidth: true,
    size: 'sm',
  };
  let typeLabel = t('highlights.community');
  let typeIcon: React.ReactNode = (
    <FontAwesomeIcon icon="users" {...defaultProps} />
  );

  const conversationIsProtected = isConversationProtected(
    highlight,
    conversation
  );

  if (highlight.privacy_level === 'public') {
    typeIcon = (
      <FontAwesomeIcon
        icon="globe"
        className="text-primary"
        {...defaultProps}
      />
    );
    typeLabel = t('highlights.public');
  } else if (highlight.privacy_level === 'private') {
    typeIcon = (
      <FontAwesomeIcon
        icon="lock"
        className="text-gray-800"
        {...defaultProps}
      />
    );
    typeLabel = t('highlights.private');
  } else if (conversationIsProtected) {
    typeIcon = <FontAwesomeIcon icon="user-friends" {...defaultProps} />;
    typeLabel = t('highlights.protected');
  }

  return (
    <div className={className}>
      {typeIcon} {!iconOnly && typeLabel}
    </div>
  );
};

export default HighlightTypeLabel;
