import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { addConversations } from 'src/redux/catalog/catalog-slice';
import { Conversation } from 'src/types/conversation';
import { Catalog } from 'src/types/insights';
import ConversationAutocomplete from './ConversationAutocomplete';

interface BaseProps {
  catalog: Catalog;
  conversations: Conversation[];
  open: boolean;
  closeDialog: () => void;
}

type Props = BaseProps & DispatchProp;

const AddConversationsDialog: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { catalog, closeDialog, conversations, dispatch, open } = props;
  const { t } = useTranslation();

  const [selectedConversations, setSelectedConversations] =
    React.useState(conversations);

  React.useEffect(() => {
    setSelectedConversations([]);
  }, [open]);

  const handleSubmit = () => {
    const request: [Catalog['id'], Conversation['id'][]] = [
      catalog.id,
      selectedConversations.map((conversation) => conversation.id),
    ];
    closeDialog();
    dispatch(addConversations(request));
  };

  return (
    <Dialog
      data-testid="AddConversationsDialog"
      disableRestoreFocus
      fullWidth
      maxWidth="sm"
      onClose={closeDialog}
      open={open}
    >
      <DialogTitle>{t('insights.add_conversation')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('insights.add_conversation_description')}
        </DialogContentText>
        <ConversationAutocomplete
          initialConversations={conversations}
          organizationId={catalog.organization_id}
          selectedConversations={selectedConversations}
          setSelectedConversations={setSelectedConversations}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>{t('common.cancel')}</Button>
        <Button onClick={handleSubmit}>{t('common.submit')}</Button>
      </DialogActions>
    </Dialog>
  );
};

AddConversationsDialog.displayName = 'AddConversationsDialog';

export default connect()(AddConversationsDialog);
