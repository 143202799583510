import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { call, put, SagaReturnType, takeLatest } from 'redux-saga/effects';

import * as api from 'src/api/api';
import { ActivityMapData } from 'src/components/ActivityMap/ActivityMap';
import { CollectionDetail } from 'src/types/collection';
import { Activity, Paging, RecentActivities } from 'src/types/conversation';

interface CollectionState {
  collectionDetail: {
    isLoading: boolean;
    error: Error | undefined;
    details: CollectionDetail | undefined;
  };

  activityMap: {
    isLoading: boolean;
    error: Error | undefined;
    activityMapData: ActivityMapData[];
  };

  recentActivity: {
    isLoading: boolean;
    error: Error | undefined;
    activities: Activity[];
    paging: Paging | undefined;
    collectionIds: number[] | undefined;
  };
}

// initial state for reducer
const initialState: CollectionState = {
  collectionDetail: {
    isLoading: false,
    error: undefined,
    details: undefined,
  },
  recentActivity: {
    isLoading: false,
    error: undefined,
    activities: [],
    paging: undefined,
    collectionIds: undefined,
  },

  activityMap: {
    isLoading: false,
    error: undefined,
    activityMapData: [],
  },
};

const slice = createSlice({
  name: 'collection',
  initialState,
  reducers: {
    loadCollectionDetails(state, action: PayloadAction<number>) {
      state.collectionDetail.isLoading = true;
      state.collectionDetail.error = undefined;
    },

    loadCollectionDetailsSuccess(
      state,
      action: PayloadAction<CollectionDetail>
    ) {
      state.collectionDetail.isLoading = false;
      state.collectionDetail.details = action.payload;
    },

    loadCollectionDetailsFailure(state, action: PayloadAction<Error>) {
      state.collectionDetail.isLoading = false;
      state.collectionDetail.error = action.payload;
    },

    loadRecentActivity(
      state,
      action: PayloadAction<{
        limit: number;
        page: number;
        collectionIds: number[];
      }>
    ) {
      state.recentActivity.isLoading = true;
      state.recentActivity.error = undefined;
      // clear recent activity if no previous collectionIds or if current collectionIds doesn't match the current collectionIds
      // cannot just compare arrays because state.recentActivity.collectionIds is a proxy
      if (
        (state.recentActivity.collectionIds &&
          state.recentActivity.collectionIds[0] !==
            action.payload.collectionIds[0]) ||
        state.recentActivity.collectionIds === undefined
      ) {
        // this is a new collection so reset the list of activities
        // need to do this because of infinite scroll where new results are appended
        state.recentActivity.activities = [];
        state.recentActivity.collectionIds = action.payload.collectionIds;
        // also need to clear the paging info of any previous collection so that the query for this collection is right
        state.recentActivity.paging = undefined;
      }
    },

    loadRecentActivitySuccess(state, action: PayloadAction<RecentActivities>) {
      state.recentActivity.isLoading = false;
      // since this is infinite scroll, we want to append new results as long as they are in the same collection
      state.recentActivity.activities = state.recentActivity.activities.concat(
        action.payload.activities
      );
      state.recentActivity.paging = action.payload.paging;
    },

    loadRecentActivityFailure(state, action: PayloadAction<Error>) {
      state.recentActivity.isLoading = false;
      state.recentActivity.error = action.payload;
    },

    clearRecentActivity(state) {
      state.recentActivity.isLoading = true;
      state.recentActivity.activities = [];
      state.recentActivity.paging = undefined;
    },

    loadActivityMap(state, action: PayloadAction<number>) {
      state.activityMap.isLoading = true;
      state.activityMap.error = undefined;
      state.activityMap.activityMapData = [];
    },

    loadActivityMapSuccess(state, action: PayloadAction<ActivityMapData[]>) {
      state.activityMap.isLoading = false;
      state.activityMap.activityMapData = action.payload;
    },

    loadActivityMapFailure(state, action: PayloadAction<Error>) {
      state.activityMap.isLoading = false;
      state.activityMap.error = action.payload;
    },
  },
});

export const {
  loadCollectionDetails,
  loadCollectionDetailsSuccess,
  loadCollectionDetailsFailure,
  loadRecentActivity,
  loadRecentActivitySuccess,
  loadRecentActivityFailure,
  clearRecentActivity,
  loadActivityMap,
  loadActivityMapSuccess,
  loadActivityMapFailure,
} = slice.actions;
export const actions = slice.actions;

export default slice.reducer;

/** Sagas */

export function* sagaLoadCollectionDetails(
  action: ReturnType<typeof loadCollectionDetails>
) {
  try {
    const collectionId = action.payload;
    const collectionDetail: SagaReturnType<typeof api.getCollectionDetail> =
      yield call(api.getCollectionDetail, collectionId);
    yield put(loadCollectionDetailsSuccess(collectionDetail));
  } catch (err) {
    yield put(loadCollectionDetailsFailure(err as Error));
  }
}

export function* sagaLoadRecentActivity(
  action: ReturnType<typeof loadRecentActivity>
) {
  const filters = action.payload;
  try {
    // load recent activity from API
    const recentActivity: SagaReturnType<typeof api.getRecentActivity> =
      yield call(api.getRecentActivity, filters);

    // fire the action with successful response
    yield put(loadRecentActivitySuccess(recentActivity));
  } catch (err) {
    // an error occurred, fire the failure action
    yield put(loadRecentActivityFailure(err as Error));
  }
}

export function* sagaLoadActivityMap(
  action: ReturnType<typeof loadActivityMap>
) {
  try {
    const collectionId = action.payload;

    // get data from API
    const activityMapData: SagaReturnType<typeof api.getActivityMap> =
      yield call(api.getActivityMap, collectionId);

    // fire the action with successful response
    yield put(loadActivityMapSuccess(activityMapData));
  } catch (err) {
    // an error occurred, fire the failure action
    yield put(loadActivityMapFailure(err as Error));
  }
}

export const sagas = [
  takeLatest(loadCollectionDetails.type, sagaLoadCollectionDetails),
  takeLatest(loadRecentActivity.type, sagaLoadRecentActivity),
  takeLatest(loadActivityMap.type, sagaLoadActivityMap),
];
