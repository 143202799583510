import { SVGProps } from 'react';

import foraColors from '../../../assets/_util.scss';

export function ForaEditIcon({
  color = foraColors.foraPurpleDarker,
  height = ICON_HEIGHT,
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg width={height} height={height} fill="none" {...props}>
      <path
        fill={color}
        d="M13 3a1 1 0 0 1 .117 1.993L13 5H5v14h14v-8a1 1 0 0 1 1.993-.117L21 11v8a2 2 0 0 1-1.85 1.995L19 21H5a2 2 0 0 1-1.995-1.85L3 19V5a2 2 0 0 1 1.85-1.995L5 3h8Zm6.243.343a1 1 0 0 1 1.497 1.32l-.083.095-9.9 9.899a1 1 0 0 1-1.497-1.32l.083-.094 9.9-9.9Z"
      />
    </svg>
  );
}

export const ICON_HEIGHT = 24;
