import { DependencyList, useEffect, useState } from 'react';
import throttle from 'lodash.throttle';

export function useResizeListener<T>(
  onResize: () => T,
  initialValue: T = {} as T,
  deps: DependencyList = []
) {
  const [value, setValue] = useState<T>(initialValue);

  useEffect(() => {
    const throttledHandler = throttle(() => {
      setValue(onResize());
    }, 50);

    // call on startup to initialize
    throttledHandler();

    window.addEventListener('resize', throttledHandler);
    return () => {
      window.removeEventListener('resize', throttledHandler);
    };
  }, deps);

  return value;
}
