import * as React from 'react';
import { Trans, useTranslation, WithTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import { Field, FormikProps } from 'formik';
import * as Yup from 'yup';

import FormButton from 'src/components/FormButton/FormButton';
import FormError from 'src/components/FormError/FormError';
import FormStep, { FormContent } from 'src/components/FormStep/FormStep';

interface Props extends FormikProps<any> {
  autoFocus: boolean;
}

const NameStep = ({ autoFocus, values, errors, handleChange }: Props) => {
  const { t } = useTranslation();
  return (
    <FormStep>
      <FormContent>
        <h1>
          <Trans i18nKey="register.name_header">
            Welcome to the Local Voices Network! <br /> What should we call you?
          </Trans>
        </h1>
        <Label className="w-100" for="name">
          {t('register.name_label')}
          <Field
            name="name"
            id="name"
            type="text"
            component="input"
            onChange={handleChange}
            value={values.name}
            aria-describedby="name-helper error-msg-name"
            aria-invalid={Boolean(errors && errors['name'])}
            aria-required
            data-testid="name-step"
            className="form-control"
            autoFocus={autoFocus}
          />
          <FormError name="name">
            {errors ? errors['name']?.toLocaleString() : null}
          </FormError>
        </Label>
        <p id="name-helper">
          <Trans i18nKey="register.name_subtext">
            This is how you'll be greeted when you join a conversation.
            <br />
            We recommend using your first name, but feel free to use a
            pseudonym.
          </Trans>
        </p>
      </FormContent>
      <FormButton type="submit" data-testid="next-button">
        {t('common.next')}
      </FormButton>
    </FormStep>
  );
};

NameStep.validationSchema = ({ t }: WithTranslation) =>
  Yup.object().shape({
    name: Yup.string().trim().required(t('register.name_validation')),
  });

export default NameStep;
