import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { call, put, SagaReturnType, takeLatest } from 'redux-saga/effects';

import * as api from 'src/api/api';
import {
  NormalizedTopicSnippets,
  Paging,
  Topics,
} from 'src/types/conversation';
import {
  NormalizedTopicSnippetEntities,
  TopicSnippetsFilterOptions,
} from 'src/types/core';

interface TopicsState {
  topics: {
    isLoading: boolean;
    error: Error | undefined;
    topics: Topics;
  };
  snippets: {
    isLoading: boolean;
    error: Error | undefined;
    snippets: NormalizedTopicSnippets;
    paging: Paging | undefined;
    filters: TopicSnippetsFilterOptions | undefined;
  };
}

// initial state for reducer
const initialState: TopicsState = {
  topics: {
    isLoading: false,
    error: undefined,
    topics: {},
  },
  snippets: {
    isLoading: false,
    error: undefined,
    snippets: [],
    paging: undefined,
    filters: undefined,
  },
};

const slice = createSlice({
  name: 'topics',
  initialState,
  reducers: {
    // note you must specify the type of action (even if only used in saga, not in reducer)
    // since it will be inferred by the type system
    loadTopics(state, action: PayloadAction<number>) {
      state.topics.isLoading = true;
      state.topics.error = undefined;
    },

    loadTopicsSuccess(state, action: PayloadAction<Topics>) {
      state.topics.isLoading = false;
      state.topics.topics = action.payload;
    },

    loadTopicsFailure(state, action: PayloadAction<Error>) {
      state.topics.isLoading = false;
      state.topics.error = action.payload;
    },

    loadTopicSnippets(
      state,
      action: PayloadAction<{
        limit: number;
        page: number;
        collectionId: number;
        topicId: number;
        relatedTopicId?: number;
        keywords?: string[];
      }>
    ) {
      state.snippets.isLoading = true;
      state.snippets.error = undefined;
    },
    loadTopicSnippetsSuccess(
      state,
      action: PayloadAction<NormalizedTopicSnippetEntities>
    ) {
      state.snippets.isLoading = false;
      state.snippets.snippets = action.payload.entities.topic_snippets;
      state.snippets.paging = action.payload.paging;
      state.snippets.filters = action.payload.filters;
    },
    loadTopicSnippetsFailure(state, action: PayloadAction<Error>) {
      state.snippets.isLoading = false;
      state.snippets.error = action.payload;
      state.snippets.paging = undefined;
      state.snippets.filters = undefined;
    },
    clearTopicSnippets(
      state,
      action: PayloadAction<{
        keepFilters?: boolean;
      }>
    ) {
      state.snippets.isLoading = false;
      state.snippets.snippets = [];
      state.snippets.paging = undefined;
      if (!action.payload.keepFilters) {
        state.snippets.filters = undefined;
      }
    },
  },
});

export const {
  loadTopics,
  loadTopicsSuccess,
  loadTopicsFailure,
  loadTopicSnippets,
  loadTopicSnippetsSuccess,
  loadTopicSnippetsFailure,
  clearTopicSnippets,
} = slice.actions;
export const actions = slice.actions;

export default slice.reducer;

/** Sagas */
export function* sagaLoadTopics(action: ReturnType<typeof loadTopics>) {
  try {
    const collectionId = action.payload;

    // load topics from API
    const topics: SagaReturnType<typeof api.getTopics> = yield call(
      api.getTopics,
      collectionId
    );

    // fire the action with successful response
    yield put(loadTopicsSuccess(topics));
  } catch (err) {
    // an error occurred, fire the failure action
    yield put(loadTopicsFailure(err as Error));
  }
}

export function* sagaLoadTopicSnippets(
  action: ReturnType<typeof loadTopicSnippets>
) {
  try {
    // load topic snippets from API
    const topicSnippets: SagaReturnType<typeof api.getTopicSnippets> =
      yield call(api.getTopicSnippets, action.payload);

    // fire the action with successful response
    yield put(loadTopicSnippetsSuccess(topicSnippets));
  } catch (err) {
    // an error occurred, fire the failure action
    yield put(loadTopicSnippetsFailure(err as Error));
  }
}

export const sagas = [
  takeLatest(loadTopics.type, sagaLoadTopics),
  takeLatest(loadTopicSnippets.type, sagaLoadTopicSnippets),
];
