import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useField } from 'formik';

import AccordianTable, {
  AccordianTableProps,
} from 'src/components/AdminPage/Common/AccordianTable';
import adminSelectors from 'src/redux/admin/admin-selectors';
import { StoreState } from 'src/redux/store';
import { UserCommunityMetadata } from 'src/types/admin';
import { Community } from 'src/types/forum';
import { CommunityRole } from 'src/types/organization';
import { createBasicProvider } from 'src/util/provider';
import { CommunityRoleDropdown } from '../../PillDropdowns/CommunityRoleDropdown';

type CommunityRowState = UserCommunityMetadata;

export type CommunityTableInputState = CommunityRowState[];

const [LocalProvider, LocalContext] = createBasicProvider<{
  communities: CommunityTableInputState;
  onRoleChanged: (communityId: Community['id'], role: CommunityRole) => void;
}>();

type CommunitiesTableInputProps = {
  name: string;
} & Partial<AccordianTableProps<CommunityRowState>>;

const CommunitiesTableInput = ({
  name,
  ...tableProps
}: CommunitiesTableInputProps) => {
  const [_field, _meta, helpers] = useField<CommunityTableInputState>(name);

  const { setValue } = helpers;

  // only show communities I can set permissions in
  const allCommunities = useSelector((state: StoreState) => {
    return adminSelectors
      .getCommunities(state)
      .filter((c) => adminSelectors.iAmCommunityAdmin(state, c.id));
  });

  const [communities, setCommunities] = useState<CommunityTableInputState>(
    allCommunities.map((c) => ({
      community: c,
      role: CommunityRole.member,
    }))
  );

  const onChange = useCallback(
    (state: CommunityTableInputState) => {
      setCommunities(state);
      // only pass values up that aren't the defaults
      setValue(state.filter((s) => s.role !== CommunityRole.member));
    },
    [setCommunities, setValue]
  );

  const onRoleChanged = useCallback(
    (communityId: number, role: CommunityRole) => {
      const updatedCatalogs = communities.map((c) => {
        if (c.community.id === communityId) {
          return {
            ...c,
            role,
          };
        }
        return c;
      });

      onChange(updatedCatalogs);
    },
    [communities, onChange]
  );

  return (
    <LocalProvider value={{ communities, onRoleChanged }}>
      <CommunitiesTable {...tableProps} />
    </LocalProvider>
  );
};

const CommunitiesTable = (
  props: Partial<AccordianTableProps<CommunityRowState>>
) => {
  const { t } = useTranslation();

  const { communities } = LocalContext();

  const tableProps: AccordianTableProps<CommunityRowState> = {
    ...props,
    values: communities,
    startClosed: true,
    label: t('admin.community_accordian_label'),
    testId: 'user-communities-table',
    valToKey: (state) => state.community.id,
    columns: [
      {
        id: 'community-name',
        headerLabel: t('admin.member_community_column_1'),
        widthFraction: 8,
        content: CommunityNameContent,
      },
      {
        id: 'community-role',
        headerLabel: t('admin.member_community_column_2'),
        widthFraction: 4,
        content: CommunityRoleContent,
      },
    ],
  };

  return <AccordianTable {...tableProps} />;
};

const CommunityNameContent = ({ val: state }: { val: CommunityRowState }) => {
  return <span>{state.community.name}</span>;
};

const CommunityRoleContent = ({ val: state }: { val: CommunityRowState }) => {
  const { onRoleChanged } = LocalContext();

  return (
    <CommunityRoleDropdown
      communityRole={state.role}
      onSelect={(role) => onRoleChanged(state.community.id, role)}
    />
  );
};

export default CommunitiesTableInput;
