import * as React from 'react';
import { useTitle } from 'react-use';

import FixedAudioPlayer from 'src/components/FixedAudioPlayer/FixedAudioPlayer';

import './Layout.scss';

type Props = {
  children?: React.ReactNode;
  className: string;
  title?: string;
  closableAudioPlayer?: boolean;
  banner?: React.ReactNode;
};

function formatTitle(subTitle?: string): string {
  const baseTitle = 'Fora';
  return subTitle ? `${subTitle} - ${baseTitle}` : baseTitle;
}

const Layout: React.FC<Props> = ({
  title,
  className,
  children,
  closableAudioPlayer = true,
  banner,
}) => {
  useTitle(formatTitle(title));
  return (
    <div className={`Layout ${className}`}>
      {banner}
      <main role="main" className="main-content">
        {children}
      </main>
      <FixedAudioPlayer
        className="fixed-audio-player"
        closable={closableAudioPlayer}
      />
    </div>
  );
};

export default Layout;
