// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Box } from '@mui/material';
import Autocomplete, {
  AutocompleteRenderGroupParams,
} from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import _ from 'lodash';

import { setAllConversations } from 'src/redux/catalog/catalog-slice';
import { Organization } from 'src/types/auth';
import { Conversation } from 'src/types/conversation';
import { getConversationGroupTitle } from '../Catalogs/utils';

interface BaseProps {
  conversationOptions?: Conversation[];
  initialConversations: Conversation[];
  organizationId: Organization['id'];
  selectedConversations: Conversation[];
  setSelectedConversations: React.Dispatch<
    React.SetStateAction<Conversation[]>
  >;
}

type Props = BaseProps;

// It seems that 4 is the magic number before the Popper component begins to behave in unexpected ways (e.g. shifting to the top of the Autocomplete)
const MAX_TAGS_IN_SEARCH_BOX = 4;

const ConversationAutocomplete: React.FunctionComponent<Props> = (
  props: Props
) => {
  const {
    conversationOptions,
    initialConversations,
    organizationId,
    selectedConversations,
    setSelectedConversations,
  } = props;
  const { t } = useTranslation();
  const initialConversationIds: number[] = initialConversations.map(
    (conversation) => conversation.id
  );
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<Conversation[]>([]);
  const [selectedCollectionMap, setSelectedCollectionMap] = React.useState<
    Map<string, boolean>
  >(new Map());
  const [fetched, setFetched] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    let active = true;
    if (!(open && !fetched)) {
      return;
    }
    const fetchData = async () => {
      // fetching / setting happens in an async function
      // to prevent infinite looping
      if (conversationOptions !== undefined && active) {
        setOptions(conversationOptions);
        setSelectedConversations(
          conversationOptions.filter(
            (option) => initialConversationIds.indexOf(option.id) !== -1
          )
        );
        setFetched(true);
      } else {
        if (active && !loading && !fetched) {
          // disptach with callback
          setLoading(true);
          dispatch(
            setAllConversations({
              callback: (allConversations) => {
                setOptions([...allConversations]);
                setSelectedConversations(
                  allConversations.filter(
                    (option) => initialConversationIds.indexOf(option.id) !== -1
                  )
                );
                setFetched(true);
                setLoading(false);
                if (!allConversations.length) {
                  toast.warning('No conversations found', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                }
              },
            })
          );
        }
      }
    };
    fetchData().catch(console.error);
    return () => {
      active = false;
    };
  }, [
    conversationOptions,
    loading,
    initialConversationIds,
    organizationId,
    setFetched,
    setSelectedConversations,
  ]);

  // Populate the Collection map
  React.useEffect(() => {
    if (options.length > 0) {
      _.uniqBy(options, 'collection.title')
        .map((conversation) => getConversationGroupTitle(conversation))
        .forEach((collectionTitle) =>
          setSelectedCollectionMap(
            (collectionMap) =>
              new Map(collectionMap.set(collectionTitle, false))
          )
        );
    }
  }, [options]);

  /**
   * If all child conversations are selected, check their parent Collection.
   * Likewise, if one child gets de-selected, uncheck their parent Collection.
   */
  React.useEffect(() => {
    _.uniqBy(options, 'collection.title')
      .map((conversation) => getConversationGroupTitle(conversation))
      .forEach((collectionTitle: string) => {
        const selectedCountMatches: boolean =
          options.filter(
            (conversation) =>
              getConversationGroupTitle(conversation) === collectionTitle
          ).length ===
          selectedConversations.filter(
            (conversation) =>
              getConversationGroupTitle(conversation) === collectionTitle
          ).length;
        setSelectedCollectionMap(
          (collectionMap) =>
            new Map(collectionMap.set(collectionTitle, selectedCountMatches))
        );
      });
  }, [selectedConversations, options]);

  const handleCollectionChecked = (
    checked: boolean,
    collectionTitle: string
  ): void => {
    // set local checked state of the collection itself
    setSelectedCollectionMap(
      new Map(selectedCollectionMap.set(collectionTitle, checked))
    );

    // select/de-select constituent conversations
    setSelectedConversations((currentConversations: Conversation[]) => {
      return checked
        ? currentConversations.concat(
            options.filter(
              (conversation) =>
                getConversationGroupTitle(conversation) === collectionTitle &&
                !currentConversations.includes(conversation)
            )
          )
        : currentConversations.filter(
            (conversation) =>
              getConversationGroupTitle(conversation) !== collectionTitle
          );
    });
  };

  return (
    <Autocomplete
      disableCloseOnSelect
      fullWidth
      getOptionLabel={(option) => option.title}
      groupBy={(option) => getConversationGroupTitle(option)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      limitTags={MAX_TAGS_IN_SEARCH_BOX}
      loading={loading}
      multiple
      onChange={(event, newValue) => {
        setSelectedConversations([
          ...options.filter(
            (option) => initialConversationIds.indexOf(option.id) !== -1
          ),
          ...newValue.filter(
            (option) => initialConversationIds.indexOf(option.id) === -1
          ),
        ]);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      options={options.sort((a, b) => {
        const n = -getConversationGroupTitle(b).localeCompare(
          getConversationGroupTitle(a)
        );
        if (n !== 0) {
          return n;
        }
        return -b.title.localeCompare(a.title);
      })}
      renderGroup={(params: AutocompleteRenderGroupParams) => {
        return (
          <ListItem
            sx={{
              flexFlow: 'column',
              alignItems: 'flex-start',
              paddingTop: '0',
              paddingBottom: '0',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'lightgray',
                width: '100%',
              }}
            >
              <Checkbox
                checked={selectedCollectionMap.get(params.group) ?? false}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                data-testid="autocomplete-collection-checkbox"
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                onChange={(event) =>
                  handleCollectionChecked(event.target.checked, params.group)
                }
                style={{ marginRight: 8 }}
              />
              <ListItemText sx={{ justifyContent: 'left' }}>
                {params.group}
              </ListItemText>
            </Box>
            {params.children}
          </ListItem>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          label={t('insights.filter_conversations')}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <ListItem
          {...props}
          alignItems="flex-start"
          disabled={initialConversationIds.indexOf(option.id) !== -1}
          key={option.id}
        >
          <Checkbox
            checked={selected}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            data-testid="autocomplete-checkbox"
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            style={{ marginRight: 8 }}
          />
          <ListItemText>{option.title}</ListItemText>
          {option.highlight_count !== undefined && (
            <ListItemIcon color="primary">
              {t('insights.count_highlights', {
                count: option.highlight_count,
              })}
            </ListItemIcon>
          )}
        </ListItem>
      )}
      renderTags={(value, getTagProps) => {
        const numTags = value.length;
        return (
          <React.Fragment>
            {value.slice(0, MAX_TAGS_IN_SEARCH_BOX).map((option, index) => (
              <Chip
                label={option.title}
                {...getTagProps({ index })}
                disabled={initialConversationIds.indexOf(option.id) !== -1}
              />
            ))}
            {numTags > MAX_TAGS_IN_SEARCH_BOX && (
              <Chip
                data-testid="maximum-tag-chip"
                label={t('insights.maximum_tag_chip', {
                  count: numTags - MAX_TAGS_IN_SEARCH_BOX,
                })}
                variant="outlined"
              />
            )}
          </React.Fragment>
        );
      }}
      sx={{ mt: 2 }}
      value={selectedConversations}
    />
  );
};

export default ConversationAutocomplete;
