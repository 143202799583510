import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { TopicSnippetFilters } from 'src/api/api';
import KeywordFilter from 'src/components/card-filters/KeywordFilter';
import EmptyRect from 'src/components/core/EmptyRect/EmptyRect';
import PagingControl from 'src/components/PagingControl/PagingControl';
import TopicsDropdown from 'src/components/TopicsDropdown/TopicsDropdown';
import {
  NormalizedTopicSnippets,
  Paging,
  Topic,
  Topics,
  TopicWithRelatedContent,
} from 'src/types/conversation';
import {
  ColorScale,
  FilterOption,
  TopicSnippetsFilterOptions,
} from 'src/types/core';
import { makePagingHandlers } from 'src/util/paging';
import TopicSnippets from '../TopicSnippets/TopicSnippets';
import TopicsLegend from './TopicsLegend/TopicsLegend';

import styles from './ExcerptListing.module.scss';

interface Props {
  activeTopic: TopicWithRelatedContent | null;
  topics: Topics;
  topicSnippets: NormalizedTopicSnippets;
  topicColorScale: ColorScale | undefined;
  onLoad: (filters: Omit<TopicSnippetFilters, 'collectionId'>) => void;
  onClear: ({ keepFilters }: { keepFilters: boolean }) => void;
  paging: Paging | undefined;
  filters: TopicSnippetsFilterOptions | undefined;
  isLoading: boolean;
}
const PAGE_SIZE = 10;

/**
 * Transform Keyword filter options to just the strings, expanding out their
 * term groups (lemma'd together words)
 * @param keywords
 * @returns keyword parameter
 */
const transformKeywordOptionsToTerms = (
  keywords: FilterOption[] | undefined
): string[] | undefined => {
  const termGroups = keywords?.map((kw) =>
    kw.term_group ? kw.term_group : []
  );
  // flatten term groups
  return termGroups ? Array.prototype.concat.apply([], termGroups) : undefined;
};

const ExcerptListing = ({
  activeTopic,
  topics,
  topicSnippets,
  topicColorScale,
  paging,
  filters,
  isLoading,
  onLoad,
  onClear,
}: Props) => {
  const { t } = useTranslation();
  const [selectedKeywords, setSelectedKeywords] = React.useState<
    FilterOption[] | undefined
  >(undefined);
  const [relatedTopic, setRelatedTopic] = React.useState<Topic | null>(null);
  const [currentPage, setCurrentPage] = React.useState(1);

  const snippetHeaderRef = React.useRef<HTMLDivElement | null>(null);
  const scrollAfterPageChange = () => {
    // scroll to the top of Discover Excerpts after paging
    if (snippetHeaderRef.current) {
      // add nav and topic selector heights
      const offset = snippetHeaderRef.current.offsetTop - 160;
      window.scroll({ top: offset });
    }
  };

  const keywordOptions = React.useMemo(() => {
    if (!filters) {
      return [];
    }
    return filters.keywords;
  }, [filters]);

  const relatedTopicList: Topic[] = React.useMemo(() => {
    // go through the related topic list which is {topicId: count}
    // and figure out the topic + attach the count
    if (!filters) {
      return [];
    }
    return filters.related_topics
      .map((opt) => {
        return { ...topics[+opt.id], count: opt.count };
      })
      .sort((a, b) => b.count - a.count);
  }, [filters, topics]);

  React.useEffect(() => {
    // reset internal state when a topic changes
    setCurrentPage(1);
    setSelectedKeywords(undefined);
    setRelatedTopic(null);
  }, [activeTopic]);

  const handleKeywordChange = (keywords: FilterOption[] | undefined) => {
    if (activeTopic) {
      setSelectedKeywords(keywords);
      setCurrentPage(1);
      if (keywords?.length === 0) {
        onClear({ keepFilters: true });
        return;
      }

      onLoad({
        page: 1,
        limit: PAGE_SIZE,
        topicId: activeTopic.id,
        keywords: transformKeywordOptionsToTerms(keywords),
        relatedTopicId: relatedTopic?.id,
      });
    }
  };

  const handleRelatedTopicChange = (newRelatedTopic: Topic | null) => {
    if (activeTopic) {
      setRelatedTopic(newRelatedTopic);
      setCurrentPage(1);
      onLoad({
        page: 1,
        limit: PAGE_SIZE,
        topicId: activeTopic.id,
        keywords: transformKeywordOptionsToTerms(selectedKeywords),
        relatedTopicId: newRelatedTopic?.id,
      });
    }
  };

  const handlePageChange = (pageNum: number) => {
    if (activeTopic) {
      setCurrentPage(pageNum);
      onLoad({
        page: pageNum,
        limit: PAGE_SIZE,
        topicId: activeTopic?.id,
        keywords: transformKeywordOptionsToTerms(selectedKeywords),
        relatedTopicId: relatedTopic?.id,
      });
    }
  };

  const { handleNextPage, handlePrevPage, handleSeekPage } = makePagingHandlers(
    currentPage,
    PAGE_SIZE,
    paging ? paging.total : 0,
    handlePageChange,
    scrollAfterPageChange
  );

  let primaryTopicIndex: number | undefined;
  let secondaryTopicIndex: number | undefined;
  if (relatedTopic && activeTopic && topicColorScale) {
    primaryTopicIndex = topicColorScale(activeTopic.display_name);
    secondaryTopicIndex = topicColorScale(relatedTopic.display_name);
  }

  return (
    <section>
      <div className="text-center">
        <h3 className="h5">{t('topics.excerpts_header')}</h3>
        <p className="mb-0">
          {activeTopic
            ? t('topics.excerpts_keyword_subheader_1', {
                keyword: activeTopic.display_name,
              })
            : t('topics.excerpts_topic_subheader_1')}
        </p>
        <p>
          {activeTopic
            ? t('topics.excerpts_keyword_subheader_2', {
                keyword: activeTopic.display_name,
              })
            : t('topics.excerpts_topic_subheader_2')}
        </p>
      </div>

      <div className="d-flex align-items-start">
        <div
          className={cx(
            'd-none d-md-block',
            { 'me-0': !!relatedTopic },
            { 'me-4': !relatedTopic }
          )}
        >
          <KeywordFilter
            filterOptions={keywordOptions}
            activeOptions={selectedKeywords}
            onChange={handleKeywordChange}
            className="mb-3"
            disabled={!activeTopic}
          />
          <TopicsDropdown
            activeTopic={relatedTopic}
            topics={relatedTopicList}
            onClear={() => {
              handleRelatedTopicChange(null);
            }}
            onTopicSelection={handleRelatedTopicChange}
            isLoadingTopics={false}
            dropdownText={t('topics.topic_filter')}
            clearText={t('topics.related_topic_clear')}
            disabled={!activeTopic}
            toggleClassName="fwmedium text-gray-900 p-1"
            fullWidth
          />
        </div>

        <div className="w-100">
          <div className="w-100 pt-2 mb-4 rounded card border">
            <div
              className={cx('text-center pb-2', {
                'border-bottom':
                  isLoading ||
                  !topicSnippets ||
                  (topicSnippets && Object.keys(topicSnippets).length === 0),
              })}
              ref={snippetHeaderRef}
            >
              <div className={cx('d-flex', styles.header)}>
                <h4 className="p mb-0 fwmedium">{t('topics.discover')}</h4>
                <div className={styles.legendWrapper}>
                  {activeTopic && relatedTopic && (
                    <TopicsLegend
                      activeTopic={activeTopic}
                      relatedTopic={relatedTopic}
                      className={cx(styles.legend, 'me-2 fwmedium')}
                      primaryTopicClassName={
                        styles[`color${primaryTopicIndex}`]
                      }
                      secondaryTopicClassName={
                        styles[`color${secondaryTopicIndex}`]
                      }
                    />
                  )}
                </div>
              </div>
            </div>
            {activeTopic ? (
              <TopicSnippets
                topicSnippets={topicSnippets}
                isLoading={isLoading}
                activeTopic={activeTopic}
                relatedTopic={relatedTopic}
                primaryTopicClassName={styles[`underline${primaryTopicIndex}`]}
                secondaryTopicClassName={
                  styles[`underline${secondaryTopicIndex}`]
                }
              />
            ) : (
              <EmptyRect height={150} testid="topic-select-snippet-empty">
                {t('topics.discover_empty_body')}
              </EmptyRect>
            )}
          </div>
          {activeTopic && !isLoading && (
            <PagingControl
              className="text-center"
              style={{ marginBottom: '5rem' }}
              itemsPerPage={PAGE_SIZE}
              pagingInfo={paging}
              onNext={handleNextPage}
              onPrevious={handlePrevPage}
              onSeek={handleSeekPage}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default ExcerptListing;
