import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExpandMoreRounded } from '@mui/icons-material';
import {
  CircularProgress,
  darken,
  lighten,
  Typography,
  TypographyProps,
} from '@mui/material';

import Color from 'src/assets/_util.scss';
import {
  ContextMenu,
  MenuItem,
  useContextMenu,
} from 'src/components/core/ContextMenu';
import { HStack, VStack } from 'src/components/core/Stack';
import { ViewProps } from 'src/components/core/View';

export function Dropdown<T extends MenuItem>({
  items,
  initialSelection,
  placeholder,
  label,
  loading = false,
  color = Color.foraPurpleDark,
  onSelect,
  ...viewProps
}: DropdownProps<T>) {
  const [selectedItem, setSelectedItem] = useState(
    initialSelection ?? placeholder ?? items[0]
  );

  const menuProps = useContextMenu(
    items.filter((i) => i.label !== selectedItem.label),
    (item) => {
      setSelectedItem(item);
      onSelect(item);
    }
  );
  const { anchorRef, setOpen } = menuProps;

  return (
    <VStack {...viewProps}>
      {label && <DropdownLabel {...{ label }} />}

      <HStack
        sx={s.root(!!label, menuProps.open, loading, color)}
        ref={anchorRef}
        onClick={() => setOpen(true)}
        role="button"
      >
        <MenuItem {...selectedItem} sx={s.selectedItem(color)}>
          {loading ? (
            <CircularProgress size={12} sx={s.icon(color)} />
          ) : (
            <ExpandMoreRounded sx={s.icon(color)} />
          )}
        </MenuItem>
      </HStack>

      <ContextMenu {...menuProps} />
    </VStack>
  );
}

export function DropdownLabel({
  label,
  sx,
  ...props
}: { label: string } & TypographyProps) {
  const { t } = useTranslation();
  return (
    <Typography
      variant="body2"
      sx={{
        fontSize: '12px',
        textTransform: 'uppercase',
        fontWeight: 600,
        padding: '0 12px',
        margin: '0 0 4px',
        ...sx,
      }}
      {...props}
    >
      {t(label)}
    </Typography>
  );
}

// STYLE

const s = {
  root: (
    hasLabel: boolean,
    open: boolean,
    loading: boolean,
    color = Color.foraLightCustom
  ) => ({
    alignItems: 'center',
    flexShrink: 0,
    cursor: 'pointer',
    color,
    pointerEvents: loading ? 'none' : 'auto',
    transition: 'all 200ms ease-out',
    ...(hasLabel
      ? {
          borderRadius: '8px',
          border: `1px solid ${color}`,
          padding: '4px 8px 4px 12px',
          background: lighten(color, 0.97),
        }
      : {}),
    filter: open ? 'brightness(108%)' : undefined,
    ':hover': {
      filter: 'brightness(108%)',
    },
  }),
  selectedItem: (color?: string) => ({
    p: 0,
    ':hover': 0,
    color: color ? darken(color, 0.25) : undefined,
  }),
  icon: (color = Color.gray600) => ({
    color,
    fontSize: '22px',
    ml: '6px',
  }),
};

// DEFINITIONS

export type DropdownProps<T extends MenuItem> = {
  items: T[];
  initialSelection?: T;
  placeholder?: MenuItem;
  label?: string; // translation key or string
  color?: string;
  loading?: boolean;
  onSelect: (item: T) => void;
} & Omit<ViewProps, 'placeholder' | 'onSelect'>;
