import React from 'react';
import {
  useTranslation,
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { Col, Form, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import cx from 'classnames';
import { FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';

import Button from 'src/components/core/Button/Button';
import FieldWithLinkEditor from 'src/components/FieldWithLinkEditor/FieldWithLinkEditor';
import FormError from 'src/components/FormError/FormError';
import { Organization } from 'src/types/auth';
import { Collection, CollectionFields } from 'src/types/collection';
import { ConversationPrivacyLevel } from 'src/types/conversation';
import CollectionPrivacyDropdown from '../CollectionPrivacyDropdown/CollectionPrivacyDropdown';

import styles from './CollectionDetailEditForm.module.scss';

interface FormProps {
  displayName: string;
  description: string;
  privacyLevel: ConversationPrivacyLevel | undefined;
}

interface ComponentProps extends WithTranslation {
  onCollapse: () => void;
  isSaving: boolean;
  isSaved: boolean;
  error: Error | undefined;
  onSubmit: (collectionFields: CollectionFields, id?: number) => void;
  collection?: Collection;
  className?: string;
  organizationId?: Organization['id'];
}

const InnerForm = (props: ComponentProps & FormikProps<FormProps>) => {
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    onCollapse,
    isSaved,
    errors,
    error: formError,
    className,
    setFieldValue,
    isSaving,
  } = props;

  const handlePrivacyChange = (privacyLevel: ConversationPrivacyLevel) => {
    setFieldValue('privacyLevel', privacyLevel);
  };

  const handleChangeDescription = (description: string) => {
    setFieldValue('description', description);
  };

  if (isSaved) {
    onCollapse();
  }

  const { t } = useTranslation();

  return (
    <Form onSubmit={handleSubmit} className={className}>
      <Row form={true}>
        <Col>
          <FormGroup>
            <Label for="displayName">{t('admin.collection_edit_name')}</Label>
            <Input
              type="text"
              name="displayName"
              id="displayName"
              value={values.displayName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.displayName && (
              <FormError name="displayName">{errors.displayName}</FormError>
            )}
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="privacySettings">
              {t('admin.collection_edit_privacy')}
            </Label>
            <CollectionPrivacyDropdown
              value={values.privacyLevel}
              collectionTitle={values.displayName}
              onChange={handlePrivacyChange}
            />
            {errors.privacyLevel && (
              <FormError name="privacyLevel">{errors.privacyLevel}</FormError>
            )}
          </FormGroup>
        </Col>
      </Row>
      {values.privacyLevel === 'public' && (
        <Row form={true}>
          <Col>
            <FormGroup>
              <Label for="description">
                {t('admin.collection_edit_description')}
              </Label>
              <FormText className="m-0">
                {t('admin.collection_edit_description_helper')}
              </FormText>
              <FieldWithLinkEditor
                value={values.description || ''}
                onChange={handleChangeDescription}
                wrapperClassName={styles.descriptionWrapper}
              />
            </FormGroup>
          </Col>
        </Row>
      )}
      <Row className="ms-0">
        {formError && <FormError name="form">{formError.message}</FormError>}
      </Row>
      <Row form={true}>
        <Col>
          <Button
            type="submit"
            disabled={isSaving}
            color="primary"
            className={cx('w-100', styles.saveBtn)}
            icon={isSaving ? 'loading' : undefined}
            iconAfter
          >
            {t('common.save')}
          </Button>
        </Col>
        <Col>
          <Button
            color="secondary"
            className="w-100"
            onClick={onCollapse}
            data-testid="cancel-btn"
          >
            {t('common.cancel')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const CollectionDetailEditForm = withFormik<ComponentProps, FormProps>({
  mapPropsToValues: ({ collection }: ComponentProps) => {
    if (collection) {
      return {
        displayName: collection.title,
        description: collection.description,
        privacyLevel: collection.privacy_level,
      };
    }
    return {
      displayName: '',
      description: '',
      privacyLevel: undefined,
    };
  },

  validationSchema: (props: ComponentProps) => {
    const { t } = props;
    return Yup.object().shape({
      displayName: Yup.string()
        .min(1)
        .required(t('admin.collection_edit_validation_name')),
      description: Yup.string(),
      privacyLevel: Yup.string().required(
        t('admin.collection_edit_validation_privacy')
      ),
    });
  },

  handleSubmit: (values: FormProps, { props }) => {
    const {
      displayName,
      description: newDescription,
      privacyLevel: newPrivacyLevel,
    } = values;
    const { collection, organizationId, onSubmit } = props;
    if (collection) {
      const { id, title, description, privacy_level } = collection;
      const changes: Partial<Collection> = {};
      if (title !== displayName) {
        changes.title = displayName;
      }
      if (description !== newDescription) {
        changes.description = newDescription;
      }
      if (privacy_level !== newPrivacyLevel) {
        changes.privacy_level = newPrivacyLevel;
      }

      onSubmit(changes, id);
    } else if (organizationId) {
      onSubmit({
        title: displayName,
        description: newDescription,
        privacy_level: newPrivacyLevel,
        organization_id: organizationId,
      });
    }
  },
  validateOnChange: false,
  validateOnBlur: false,
  enableReinitialize: true,
})(InnerForm);

export default withTranslation()(CollectionDetailEditForm);
