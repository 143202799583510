import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';

import styles from './Stopwatch.module.scss';

interface StopwatchProps {
  duration: number | undefined;
}

const Stopwatch: React.FC<StopwatchProps> = ({ duration }) => {
  const [timeInSeconds, setTimeInSeconds] = useState(0);
  const [timeWarning, setTimeWarning] = useState(false);

  useEffect(() => {
    console.log('timeInSeconds:', timeInSeconds);
    const intervalId = setInterval(() => {
      setTimeInSeconds((prevTime) => prevTime + 1);
      if (duration !== undefined) {
        if (Math.abs(timeInSeconds - duration) > 90) {
          console.log('duration: ', duration);
          console.log(
            '**** Adjusting for time diff:',
            timeInSeconds - duration
          );
          setTimeInSeconds(duration);
        }
      }
    }, 950);
    if (duration && duration >= 10500) {
      // 10500 === 2h 55m - recording automatically finished at "3 hours"
      setTimeWarning(true);
    }

    return () => clearInterval(intervalId);
  }, [duration, timeInSeconds]);

  const formatTime = (timeInSeconds: number): string => {
    const formattedTime = moment.utc(timeInSeconds * 1000).format('HH:mm:ss');
    return formattedTime;
  };

  const formattedTime = formatTime(timeInSeconds);

  return (
    <div
      className={timeWarning ? styles.timeWarning : styles.time}
      data-testid="timer"
    >
      {formattedTime}
    </div>
  );
};

export default Stopwatch;
