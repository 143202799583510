import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import styles from './FormError.module.scss';

interface FormErrorProps {
  name: string;
  children: React.ReactNode;
  className?: string;
}

const FormError = ({ name, children, className }: FormErrorProps) => (
  <div className={cx(styles.errorContainer, 'my-1', className)}>
    <FontAwesomeIcon
      icon={['fas', 'exclamation-circle']}
      className={cx(styles.errorIcon, 'text-danger me-2', {
        invisible: children == null,
      })}
      data-testid="error-icon"
    />
    <span
      className="text-danger text-start fwnormal mb-0 d-inline"
      role="alert"
      id={`error-msg-${name}`}
      data-testid={`error-msg-${name}`}
    >
      {children}
    </span>
  </div>
);

export default FormError;
