import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'src/components/core/Button/Button';
import { Props as ButtonProps } from 'src/components/core/Button/Button';

export interface Props extends ButtonProps {
  isSaving: boolean;
  error?: Error | undefined;
  content?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  className?: string;
  type?: 'submit' | 'reset' | 'button';
  compact?: boolean | undefined;
}

export default function SaveButton({
  onClick,
  isSaving,
  error,
  content,
  className,
  type = 'submit',
  compact,
  ...other
}: Props) {
  const { t } = useTranslation();
  if (!isSaving && error) {
    return (
      <Button
        title={t('common.error')}
        color="danger"
        onClick={onClick}
        icon={['fas', 'exclamation-circle']}
        content={compact ? undefined : t('common.error')}
        className={className}
        type={type}
        data-testid="error-button"
        {...other}
      />
    );
  }

  if (compact) {
    return (
      <Button
        title={t('common.save')}
        color="success"
        onClick={onClick}
        icon={isSaving ? 'loading' : ['far', 'check']}
        disabled={isSaving}
        className={className}
        type={type}
        grayOutline
        data-testid="save-button"
        {...other}
      />
    );
  }
  return (
    <Button
      title={t('common.save')}
      color="success"
      onClick={onClick}
      icon={isSaving ? 'loading' : undefined}
      content={isSaving ? t('common.saving') : content || t('common.save')}
      disabled={isSaving}
      className={className}
      type={type}
      data-testid="save-button"
      {...other}
    />
  );
}
