import * as React from 'react';

interface DialogOpenState {
  [key: string]: boolean | undefined;
}

interface UseDialogStateProps {
  onOpen?: () => void;
  onClose?: () => void;
}

const useDialogState = (
  props?: UseDialogStateProps
): [
  DialogOpenState,
  (key: string) => () => void,
  (key: string) => () => void
] => {
  const { onOpen, onClose } = props || {};
  const [dialogState, setDialogState] = React.useState<DialogOpenState>({});
  const openDialog = (key: string) => {
    return () => {
      setDialogState({ ...dialogState, [key]: true });
      if (onOpen !== undefined) {
        onOpen();
      }
    };
  };
  const closeDialog = (key: string) => {
    return () => {
      setDialogState({ ...dialogState, [key]: false });
      if (onClose !== undefined) {
        onClose();
      }
    };
  };
  return [dialogState, openDialog, closeDialog];
};

export default useDialogState;
