import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import Button from 'src/components/core/Button/Button';
import LoadingSpinner from 'src/components/core/LoadingSpinner/LoadingSpinner';
import Wavesurfer from 'src/components/WaveSurfer/WaveSurfer';

import styles from './TrimButton.module.scss';

interface TrimButtonProps {
  file: File;
  toggle?: boolean;
  onTrim: (trimTimes: any) => void;
  defaultZoom?: number;
  defaultSeek?: number;
}

const TrimButton = ({ file, onTrim }: TrimButtonProps) => {
  const { t } = useTranslation();
  const [trimTimes, setTrimTimes] = React.useState<
    [number, number] | undefined
  >();
  const [zoom, setZoom] = React.useState<number>(0);
  const [displayWavesurfer, setDisplayWavesurfer] = React.useState(false);
  const [wavesurferReady, setWavesurferReady] = React.useState(false);

  const toggleWavesurfer = () => {
    if (!displayWavesurfer) {
      setDisplayWavesurfer(true);
    } else {
      const trimTimes = [undefined, undefined];
      onTrim(trimTimes);
      setDisplayWavesurfer(false);
    }
  };

  return (
    <div className="waveContainer">
      <Wavesurfer
        file={file}
        defaultTrimTimes={trimTimes}
        onTrim={(trimTimes) => {
          onTrim(trimTimes);
          setTrimTimes(trimTimes);
        }}
        defaultZoom={zoom}
        onZoom={setZoom}
        onReady={() => setWavesurferReady(true)}
        display={displayWavesurfer}
        extraButtons={
          <Button
            data-testid="wavesurfer-cancel-button"
            className={cx(styles.waveButton)}
            onClick={toggleWavesurfer}
            color="primary"
          >
            {t('common.cancel')}
          </Button>
        }
      />
      {!displayWavesurfer &&
        (wavesurferReady ? (
          <Button
            className={cx(styles.trimAudioButton)}
            onClick={toggleWavesurfer}
            data-testid="trim-audio-button"
          >
            {t('audio_player.trim')}
          </Button>
        ) : (
          <LoadingSpinner active size="md" />
        ))}
    </div>
  );
};

export default TrimButton;
