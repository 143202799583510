import React from 'react';
import cx from 'classnames';

import { Snippet, WordEntry } from 'src/types/conversation';
import {
  computeWordEmphasisIndices,
  getActiveSnippet,
} from 'src/util/snippets';
import DurationBar from './DurationBar/DurationBar';

import styles from './TranscriptRoll.module.scss';

interface SnippetTextProps {
  index: number;
  prevSnippet: Snippet;
  activeSnippet: Snippet;
  snippet: Snippet;
  emphasizedTerms?: string[];
  startTime: number;
  endTime: number;
  audioIsLoaded: boolean | undefined;
  handleWordClicked: (word: WordEntry) => void;
}

const SnippetText = ({
  prevSnippet,
  activeSnippet,
  snippet,
  emphasizedTerms,
  startTime,
  endTime,
  audioIsLoaded,
  handleWordClicked,
}: SnippetTextProps) => {
  let snippetStyle = `${styles.snippet}`;
  const prevSpeaker = prevSnippet ? prevSnippet.speaker_id : undefined;
  // grey out any words not spoken by the current speaker
  // also handle case when it goes speaker A, B, A and all
  // are visible on screen (e.g. 3 lines)
  if (
    activeSnippet.speaker_id !== snippet.speaker_id ||
    (prevSpeaker != null &&
      prevSpeaker !== snippet.speaker_id &&
      activeSnippet !== snippet)
  ) {
    snippetStyle = `${styles.snippet} ${styles.inactiveSnippet}`;
  }

  // compute word tuples that should be bolded
  const wordEmphasisIndices: {
    [index: number]: boolean | undefined;
  } = React.useMemo(
    () => computeWordEmphasisIndices(snippet.words, emphasizedTerms),
    [snippet.words, emphasizedTerms]
  );

  return (
    <div className={snippetStyle}>
      {snippet.words.map((word, j) => {
        const isEmphasized = wordEmphasisIndices[j];
        if (startTime <= word[2] && endTime > word[1]) {
          return (
            <React.Fragment key={j}>
              <span
                className={cx('word', styles.word, {
                  [styles.clickableWord]: audioIsLoaded,
                  [styles.emphasizedTerm]: isEmphasized,
                })}
                onClick={() => handleWordClicked(word)}
              >
                {word[0]}
              </span>{' '}
            </React.Fragment>
          );
        }

        return null;
      })}
    </div>
  );
};

interface Props {
  seekTime: number | undefined;
  snippets: Snippet[];
  startTime: number;
  endTime: number;
  className?: string | undefined;
  numVisibleWords?: number;
  onSeek?: (seekTime: number) => void;
  audioIsLoaded?: boolean;
  withDurationBar?: boolean;
  compact?: boolean;
  emphasizedTerms?: string[];
}

const TranscriptRoll = ({
  seekTime,
  snippets = [],
  startTime,
  endTime,
  className,
  onSeek,
  audioIsLoaded,
  withDurationBar,
  compact,
  emphasizedTerms,
}: Props) => {
  const activeSnippet = getActiveSnippet(seekTime, snippets, startTime);

  // get word bboxes to move the highlight box through
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [wordBboxes, setWordBboxes] = React.useState<DOMRect[]>([]);

  React.useEffect(() => {
    if (!ref || !ref.current) {
      return;
    }
    const rootBbox = ref.current.getBoundingClientRect() as DOMRect;
    const wordNodes = Array.from(ref.current.querySelectorAll('.word'));
    const firstWordRelativeLocation =
      wordNodes[0].getBoundingClientRect().top - rootBbox.top;
    const bboxes = wordNodes.map((node) => {
      const bbox = node.getBoundingClientRect() as DOMRect;

      return {
        x: bbox.left - rootBbox.left,
        y: bbox.top - rootBbox.top - firstWordRelativeLocation,
        width: bbox.width,
        height: bbox.height,
      };
    }) as DOMRect[];
    setWordBboxes(bboxes);
  }, [snippets, audioIsLoaded]);

  // find the active word and snippet based on last word that starts before seek time
  let activeWordIndex = 0;
  let wordCount = 0;
  // number of words that are in the snippet but are before highlight audio start
  // we need to keep track of this in order to get an accurate word index relative to
  // the number of words actually in the highlight
  let skippedWords = 0;
  for (let i = 0; i < snippets.length; ++i) {
    for (let j = 0; seekTime != null && j < snippets[i].words.length; ++j) {
      const word = snippets[i].words[j];
      if (startTime <= word[2] && endTime > word[1]) {
        if (snippets[i].words[j][1] > seekTime) {
          break;
        }
        activeWordIndex = j + wordCount - skippedWords;
      } else {
        skippedWords++;
      }
    }
    wordCount += snippets[i].words.length;
  }

  // get a list of the y positions of each line
  const linesY = wordBboxes
    .map((bbox) => bbox.y)
    .filter((value, index, array) => array.indexOf(value) === index);

  // update the position of the active word box
  const activeWordBbox = wordBboxes[activeWordIndex];
  const seekHighlightBarStyle: React.CSSProperties = {};
  let topLineY = 0;

  if (activeWordBbox) {
    // get the position that the div should be moved up based on the line number
    const activeLineIndex = linesY.indexOf(activeWordBbox.y);
    let numVisibleRows = 2;
    const lineHeight = compact ? 1.6 : 1.95; // from css
    if (ref.current) {
      const containerHeight = ref.current.getBoundingClientRect().height;
      numVisibleRows = Math.ceil(
        containerHeight / wordBboxes[0].height / lineHeight
      );
    }
    topLineY = -linesY[activeLineIndex - (activeLineIndex % numVisibleRows)];

    // only fade in if we are playing
    if (seekTime != null) {
      seekHighlightBarStyle.opacity = 1;
    }
    // need to set position even if not playing so it is initialized properly
    seekHighlightBarStyle.transform = `translate(${activeWordBbox.x}px, ${activeWordBbox.y}px)`;
    seekHighlightBarStyle.width = `${activeWordBbox.width}px`;
  }

  const handleWordClicked = (word: WordEntry) => {
    // use an epsilon in order to make sure we click the right word in the case of
    // close word boundaries
    if (audioIsLoaded && onSeek) {
      const epsilon = 0.000001;
      const seekTime = word[1] + epsilon;
      onSeek(seekTime);
    }
  };

  if (!activeSnippet) {
    return null;
  }

  return (
    <div
      ref={ref}
      className={cx(
        className,
        { [styles.container]: !compact },
        styles.toEdges
      )}
    >
      <div className={cx(styles.words, { [styles.compact]: compact })}>
        <div
          className={cx(styles.wordsInner, { 'ps-1': compact })}
          style={{ transform: `translateY(${topLineY}px)` }}
        >
          <div /* box that moves along with the currently active word */
            className={styles.seekHighlightBar}
            style={seekHighlightBarStyle}
          />
          <div>
            {snippets.map((snippet, i) => (
              <SnippetText
                index={i}
                prevSnippet={snippets[i - 1]}
                activeSnippet={activeSnippet}
                snippet={snippet}
                emphasizedTerms={emphasizedTerms}
                startTime={startTime}
                endTime={endTime}
                audioIsLoaded={audioIsLoaded}
                handleWordClicked={handleWordClicked}
                key={i}
              />
            ))}
          </div>
        </div>
      </div>
      {withDurationBar && (
        <DurationBar
          annotation={activeSnippet.speaker_name}
          startTime={startTime}
          endTime={endTime}
          seekTime={seekTime}
          onSeek={onSeek}
        />
      )}
    </div>
  );
};

export default React.memo(TranscriptRoll);
