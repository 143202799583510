import type { ElementType, PropsWithChildren } from 'react';
import React from 'react';

type NestedProps = {
  components: ElementType[];
};

/** nests `components` inside each other, e.g.:
 * ```tsx
 * <Nested components={[A, B, C]}><D /></Nested>
 * => <A><B><C><D /></C></B></A>
 * ```
 * for `Providers`, this means that each component can access data
 * in the component before it
 */
export function Nested({
  components,
  children,
}: PropsWithChildren<NestedProps>) {
  let Nest = children;

  components
    .reverse()
    .forEach((Component) => (Nest = <Component>{Nest}</Component>));

  return <>{Nest}</>;
}
