import React from 'react';
import { StringParam, useQueryParams } from 'use-query-params';

export type TeamPageName = 'add' | 'view';

interface MemberListPageProps {
  defaultScreen: React.ReactNode;
  addMemberScreen: React.ReactNode;
  viewMemberScreen: React.ReactNode;
}

const MemberListPage = ({
  defaultScreen,
  addMemberScreen,
  viewMemberScreen,
}: MemberListPageProps) => {
  const [query] = useQueryParams({
    page: StringParam,
  });
  const { page: activePage } = query;

  switch (activePage as TeamPageName) {
    case 'add':
      return <>{addMemberScreen}</>;
    case 'view':
      return <>{viewMemberScreen}</>;
    default:
      return <>{defaultScreen}</>;
  }
};

export default MemberListPage;
