import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';

import HighlightStarButton from 'src/components/HighlightStarButton/HighlightStarButton';
import HighlightTypeLabel from 'src/components/HighlightTypeLabel/HighlightTypeLabel';
import authSelectors from 'src/redux/auth/auth-selectors';
import { StoreState } from 'src/redux/store';
import { User } from 'src/types/auth';
import { Conversation, Highlight } from 'src/types/conversation';

interface BaseProps {
  highlight: Highlight;
  conversation?: Conversation;
}

interface StateProps {
  user: User | undefined;
}

type Props = BaseProps & StateProps & DispatchProp;

/** Map state from redux to the components props */
const mapStateToProps = (state: StoreState): StateProps => {
  return {
    user: authSelectors.getUser(state),
  };
};

const ViewHighlightCard = ({ highlight, conversation, dispatch }: Props) => {
  return (
    <>
      <HighlightStarButton
        highlight={highlight}
        dispatch={dispatch}
        className="float-end"
      />
      <div data-testid="view-highlight-card">
        <div className="p-1">
          <HighlightTypeLabel
            highlight={highlight}
            conversation={conversation}
            className="small-header"
          />
          <div className="fwbold">{highlight.user_name}</div>
          <div>{highlight.description}</div>
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps)(ViewHighlightCard);
