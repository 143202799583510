import { selectors as entitiesSelectors } from 'src/redux/entities/entities-selectors';
import { StoreState } from 'src/redux/store';
import { Highlight, Paging } from 'src/types/conversation';
import { HighlightsFilterOptions } from 'src/types/core';

// selectors
export const selectors = {
  isLoadingUserHighlights: (state: StoreState): boolean =>
    state.highlight.userHighlights.isLoading,
  getUserHighlights: (state: StoreState): Highlight[] =>
    entitiesSelectors.getHighlights(state, state.highlight.userHighlights.ids, {
      nestConversation: true,
      nestHostInConversation: true,
      nestSnippets: true,
    }),
  getErrorUserHighlights: (state: StoreState): Error | undefined =>
    state.highlight.userHighlights.error,
  getUserHighlightsPagingInfo: (state: StoreState): Paging | undefined =>
    state.highlight.userHighlights.paging,
  getUserHighlightsFilterOptions: (
    state: StoreState
  ): HighlightsFilterOptions | undefined =>
    state.highlight.userHighlights.filters,

  getAllHighlights: (state: StoreState): Highlight[] =>
    entitiesSelectors.getHighlights(state, state.highlight.allHighlights.ids, {
      nestConversation: true,
      nestHostInConversation: true,
      nestSnippets: true,
    }),
  isLoadingAllHighlights: (state: StoreState): boolean =>
    state.highlight.allHighlights.isLoading,
  getAllHighlightsPagingInfo: (state: StoreState): Paging | undefined =>
    state.highlight.allHighlights.paging,
  getAllHighlightsFilterOptions: (
    state: StoreState
  ): HighlightsFilterOptions | undefined =>
    state.highlight.allHighlights.filters,
  getStarredHighlights: (state: StoreState): Highlight[] =>
    entitiesSelectors.getHighlights(
      state,
      state.highlight.starredHighlights.ids,
      {
        nestConversation: true,
        nestHostInConversation: true,
        nestSnippets: true,
      }
    ),
  isLoadingStarredHighlights: (state: StoreState): boolean =>
    state.highlight.starredHighlights.isLoading,
  getStarredHighlightsPagingInfo: (state: StoreState): Paging | undefined =>
    state.highlight.starredHighlights.paging,
  getStarredHighlightsFilterOptions: (
    state: StoreState
  ): HighlightsFilterOptions | undefined =>
    state.highlight.starredHighlights.filters,

  getStarredAndUserHighlights: (state: StoreState): Highlight[] =>
    entitiesSelectors.getHighlights(
      state,
      state.highlight.starredAndUserHighlights.ids,
      {
        nestConversation: true,
        nestHostInConversation: true,
        nestSnippets: true,
      }
    ),
  isLoadingStarredAndUserHighlights: (state: StoreState): boolean =>
    state.highlight.starredAndUserHighlights.isLoading,
  getStarredAndUserHighlightsPagingInfo: (
    state: StoreState
  ): Paging | undefined => state.highlight.starredAndUserHighlights.paging,

  getSearchHighlights: (state: StoreState): Highlight[] =>
    entitiesSelectors.getHighlights(
      state,
      state.highlight.searchHighlights.ids,
      {
        nestConversation: true,
        nestHostInConversation: true,
        nestSnippets: true,
      }
    ),
  isLoadingSearchHighlights: (state: StoreState): boolean =>
    state.highlight.searchHighlights.isLoading,
  getSearchHighlightsPagingInfo: (state: StoreState): Paging | undefined =>
    state.highlight.searchHighlights.paging,
  getSearchHighlightsFilterOptions: (
    state: StoreState
  ): HighlightsFilterOptions | undefined =>
    state.highlight.searchHighlights.filters,

  isLoadingHighlight: (state: StoreState): boolean =>
    state.highlight.singleHighlight.isLoading,
  getErrorHighlight: (state: StoreState): Error | undefined =>
    state.highlight.singleHighlight.error,

  getEditHighlightId: (state: StoreState): Highlight['id'] | undefined =>
    state.highlight.editHighlight.highlightId,
  isSavingEditHighlight: (state: StoreState): boolean =>
    state.highlight.editHighlight.isSaving,
  isSavedEditHighlight: (state: StoreState): boolean =>
    state.highlight.editHighlight.isSaved,
  getErrorEditHighlight: (state: StoreState): Error | undefined =>
    state.highlight.editHighlight.error,

  getStarredHighlightId: (state: StoreState): Highlight['id'] | undefined =>
    state.highlight.starHighlight.highlightId,
  isSavingStarredHighlight: (state: StoreState): boolean =>
    state.highlight.starHighlight.isSaving,
  getErrorStarredHighlight: (
    state: StoreState
  ): Error | undefined | Highlight => state.highlight.starHighlight.error,

  isLoadingExploreHighlights: (state: StoreState): boolean =>
    state.highlight.exploreHighlights.isLoading,
  getExploreHighlights: (state: StoreState): Highlight[] =>
    entitiesSelectors.getHighlights(
      state,
      state.highlight.exploreHighlights.ids,
      {
        nestConversation: true,
        nestHostInConversation: true,
        nestSnippets: true,
      }
    ),
  getErrorExploreHighlights: (state: StoreState): Error | undefined =>
    state.highlight.exploreHighlights.error,
  getExploreHighlightsPagingInfo: (state: StoreState): Paging | undefined =>
    state.highlight.exploreHighlights.paging,
  getErrorExploreTopicsHighlights: (state: StoreState): Error | undefined =>
    state.highlight.exploreTopicsHighlights.error,
  getExploreTopicsHighlightsPagingInfo: (
    state: StoreState
  ): Paging | undefined => state.highlight.exploreTopicsHighlights.paging,
};
export default selectors;
