import * as React from 'react';
import { Trans, useTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Label } from 'reactstrap';
import { Field, FormikProps } from 'formik';
import * as Yup from 'yup';

import CheckboxWrapper from 'src/components/CheckboxWrapper/CheckboxWrapper';
import FormButton from 'src/components/FormButton/FormButton';
import FormError from 'src/components/FormError/FormError';
import FormStep, { FormContent } from 'src/components/FormStep/FormStep';
import { getErrorMessage } from 'src/util/form';
import { redirectUserToConversations } from 'src/util/urls';

interface BaseProps extends FormikProps<any> {
  onBack: () => void;
  autoFocus: boolean;
}
type LocationState = {
  attribute?: string;
  message?: any;
};

type Props = BaseProps & FormikProps<any> & RouteComponentProps<any>;

const EmailStep = ({
  autoFocus,
  values,
  handleChange,
  onBack,
  location,
  status,
  setStatus,
  errors,
}: Props) => {
  const { t } = useTranslation();
  React.useEffect(() => {
    const locationState = location.state as LocationState | undefined;
    if (locationState) {
      // location.state is in the form {attribute: 'email', message: '...'}
      // for email, we want different error messages depending on if the email already exists/is active
      if (locationState.attribute === 'email') {
        // need to check string type since message can be set to a <span> here
        if (
          locationState.message &&
          typeof locationState.message === 'string'
        ) {
          // error message for if email exists and is active— go sign in
          if (locationState.message.startsWith('EMAIL_EXISTS_ACTIVE')) {
            // we use window.location here because we need the whole url including domain
            // for the next parameter
            const signInUrl = `/login/?next=${redirectUserToConversations(
              '/'
            )}`;
            locationState.message = (
              <span data-testid="email-exists-active">
                <Trans
                  i18nKey="register.email_exist_active"
                  /*eslint-disable */
                  components={{
                    1: <a href={signInUrl} />,
                  }}
                  /*eslint-disable */
                />
              </span>
            );
          } else if (
            locationState.message.startsWith('EMAIL_EXISTS_INACTIVE')
          ) {
            // error message for if email exists but is inactive— email us for help
            locationState.message = (
              <span data-testid="email-exists-inactive">
                <Trans
                  i18nKey="register.email_exist_inactive"
                  /*eslint-disable */
                  components={{
                    1: <a href="mailto:help@lvn.org" />,
                  }}
                  /*eslint-disable */
                />
              </span>
            );
          }
        }
      }
      // all other errors will just be set as-is from the server
      setStatus(locationState);
    }
  }, [location.state, setStatus]);

  const errorMessage = getErrorMessage({
    field: 'email',
    status,
    errors,
  });

  return (
    <FormStep onBack={onBack}>
      <FormContent>
        <h1>{t('register.email_header', { name: values.name })}</h1>
        <Label className="w-100" for="email">
          {t('register.email_label')}
          <Field
            name="email"
            id="email"
            type="email"
            component="input"
            value={values.email}
            onChange={handleChange}
            data-testid="email-step"
            aria-describedby="email-helper error-msg-email"
            aria-invalid={Boolean(errorMessage != null)}
            aria-required
            className="form-control"
            autoFocus={autoFocus}
          />
          <FormError name="email">{errorMessage}</FormError>
        </Label>
        <p id="email-helper">{t('register.email_subtext')}</p>
      </FormContent>
      <CheckboxWrapper
        inputName="allowMarketing"
        checked={values.allowMarketing}
      >
        <Field
          type="checkbox"
          name="allowMarketing"
          id="allowMarketing"
          checked={values.allowMarketing}
          data-testid="allow-marketing-checkbox"
        />
        {t('register.email_marketing')}
      </CheckboxWrapper>
      <FormButton type="submit" data-testid="next-button">
        {t('common.next')}
      </FormButton>
    </FormStep>
  );
};

EmailStep.validationSchema = ({ t }: WithTranslation) =>
  Yup.object().shape({
    email: Yup.string()
      .matches(/@/, t('register.email_validation_1'))
      .email(t('register.email_validation_2'))
      .required(t('register.email_validation_3')),
    allowMarketing: Yup.boolean(),
  });

export default EmailStep;
