import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Col, Container, Row, UncontrolledAlert } from 'reactstrap';
import { NumberParam, useQueryParams } from 'use-query-params';

import { getUploadFormMetadata } from 'src/api/api';
import Button from 'src/components/core/Button/Button';
import authSelectors from 'src/redux/auth/auth-selectors';
import organizationSelectors from 'src/redux/organizations/organizations-selectors';
import { loadOrganizationsMetadata } from 'src/redux/organizations/organizations-slice';
import { StoreState } from 'src/redux/store';
import { User } from 'src/types/auth';
import {
  ConversationMetadataResponse,
  UploadMetadata,
} from 'src/types/conversation';
import { OrganizationMetadata } from 'src/types/organization';
import {
  getActiveCollection,
  getActiveOrganizationMetadata,
  getHostCollections,
} from 'src/util/collections';
import Layout from '../Layout/Layout';
import ConversationUploadForm from './ConversationUploadForm/ConversationUploadForm';
import ConversationUploadFormSuccess from './ConversationUploadFormSuccess/ConversationUploadFormSuccess';

interface StateProps {
  user: User;
  organizationsMetadata: OrganizationMetadata[] | undefined;
}

type Props = RouteComponentProps &
  StateProps &
  DispatchProp & {
    uploadType: string;
  };

const mapStateToProps = (state: StoreState): StateProps => ({
  user: authSelectors.getUser(state),
  organizationsMetadata: organizationSelectors.getOrganizations(state),
});

/**
 * Form to upload conversation audio and metadata from recordings
 */
const ConversationUploadPage = ({
  user,
  organizationsMetadata,
  dispatch,
  uploadType,
}: Props) => {
  const { t } = useTranslation();
  const [formResponse, setFormResponse] = React.useState<
    ConversationMetadataResponse | undefined
  >(undefined);
  const [metadata, setMetadata] = React.useState<UploadMetadata>({
    languages: [],
    audio_sources: [],
  });

  React.useEffect(() => {
    getUploadFormMetadata().then((res) => {
      setMetadata(res);
    });
    dispatch(loadOrganizationsMetadata());
  }, [dispatch]);

  const [query] = useQueryParams({
    c: NumberParam,
  });
  const { c: activeCollectionId } = query;
  const activeCollection = getActiveCollection(
    activeCollectionId,
    user
  ) as User['collections'][0];

  const handleUploadAnotherConversation = () => {
    setFormResponse(undefined);
  };

  const uploadTitle =
    uploadType == 'recording' ? 'main_nav.record' : 'main_nav.upload';
  const uploadClassName =
    uploadType == 'recording'
      ? 'ConversationRecordingPage'
      : 'ConversationUploadPage';
  const uploadHeading =
    uploadType == 'recording'
      ? 'conversation_upload.recording_title'
      : 'conversation_upload.title';
  const uploadSubtitle =
    uploadType == 'recording'
      ? 'conversation_upload.recording_subtitle'
      : 'conversation_upload.subtitle';
  const history = useHistory();
  const enterRecordingRoom = () => {
    history.push('/recording-room-setup');
  };

  const hostCollections = getHostCollections(user);
  if (hostCollections.length === 0) {
    return (
      <Layout className={uploadClassName} title={t(uploadTitle)}>
        <p>
          <Trans
            i18nKey="conversation_upload.no_collections"
            /*eslint-disable */
            components={{ 1: <a href="mailto:help@lvn.org" /> }}
            /*eslint-disable */
          />
        </p>
      </Layout>
    );
  }

  if (formResponse) {
    const {
      title,
      collection_id,
      start_time,
      source_type,
      geo,
      language_codes,
      num_participants,
    } = formResponse;

    const collection = user.collections.find((d) => d.id === collection_id);
    const sourceType = metadata.audio_sources.find((d) => d[0] === source_type);
    const sourceTypeTitle = sourceType && sourceType[1] ? sourceType[1] : '';
    // TODO: update to support multiple languages when supported by leaven
    const languageCode = language_codes[0];
    const language = metadata.languages.find((d) => d.code === languageCode);

    const orgMetadata = getActiveOrganizationMetadata(
      hostCollections,
      organizationsMetadata,
      collection_id
    );

    // note: since a convo has to go through the pipeline before it'll increment,
    // we just eagerly increment here. this can result in an inaccurate result if
    // something goes wrong in the pipeline
    // https://corticoai.slack.com/archives/CLXG310R5/p1622669471011300
    const banner = orgMetadata ? (
      <UncontrolledAlert
        color="primary"
        className="banner bg-primary text-white"
      >
        {t('conversation_upload.banner', {
          number: orgMetadata.num_conversations + 1,
          limit: orgMetadata.upload_cap,
        })}
      </UncontrolledAlert>
    ) : undefined;

    return (
      <Layout
        className={uploadClassName}
        title={t(uploadTitle)}
        banner={banner}
      >
        <Container>
          <header className="mb-2">
            <h2 className="mb-1">
              {t('conversation_upload.success_header')}
              <span aria-hidden>🎉</span>
            </h2>
            <p className="text-muted">
              <Trans
                i18nKey="conversation_upload.success_subheader"
                /*eslint-disable */
                components={{
                  1: <a href="mailto:help@lvn.org" />,
                }}
                /*eslint-disable */
              />
            </p>
          </header>
          {collection != null && (
            <ConversationUploadFormSuccess
              conversationTitle={title}
              user={user}
              collection={collection}
              startDatetime={start_time}
              sourceTypeTitle={sourceTypeTitle}
              location={geo}
              languageTitle={language ? language.title : ''}
              numberOfParticipants={num_participants}
            />
          )}
          <Button
            color="primary"
            className="me-2 mb-2"
            data-testid="upload-another-conversation"
            aria-label="upload another conversation"
            onClick={handleUploadAnotherConversation}
          >
            {t('conversation_upload.button_again')}
          </Button>
        </Container>
      </Layout>
    );
  }

  return (
    <Layout className="ConversationUploadPage" title={t(uploadTitle)}>
      <Container>
        <header className="mb-3">
          <h2 className="mb-1">{t(uploadHeading)}</h2>
          <p className="text-muted">
            <Trans
              i18nKey={uploadSubtitle}
              /*eslint-disable */
              components={{ 1: <a href="mailto:help@lvn.org" /> }}
              /*eslint-disable */
            />
          </p>
        </header>
        <Row className="mb-5">
          <Col md={8} lg={6}>
            <ConversationUploadForm
              user={user}
              hostCollections={hostCollections}
              activeCollection={activeCollection}
              uploadMetadata={metadata}
              organizationsMetadata={organizationsMetadata}
              onRecord={enterRecordingRoom}
              onSuccess={(res: any) => {
                setFormResponse(res);
              }}
              formType={uploadType}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default connect(mapStateToProps)(ConversationUploadPage);
