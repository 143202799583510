import { CSSProperties } from 'react';
import { Dialog, DialogProps } from '@mui/material';

import { NAVBAR_HEIGHT } from 'src/constants';

export type ModalDialogProps = {
  containerStyle?: CSSProperties;
  onClose?: () => void;
} & DialogProps;

/**
 * Wrapper class that applies a Dialog style to the children nested inside of it.
 */
const ModalDialog = ({
  children,
  open,
  onClose,
  sx,
  containerStyle,
  PaperProps,
  ...dialogProps
}: ModalDialogProps) => {
  return (
    <Dialog
      maxWidth="md"
      onClose={onClose}
      hideBackdrop
      open={open}
      sx={{
        width: '100%',
        margin: 'auto 0',
        top: `${NAVBAR_HEIGHT}px`,
        background: 'rgba(0,0,0,.75)',
        backdropFilter: 'blur(4px)',
        ...sx,
      }}
      PaperProps={{
        ...PaperProps,
        style: {
          boxShadow: 'none',
          borderRadius: '24px',
          margin: 0,
          padding: '32px',
          gap: '20px',
          alignItems: 'center',
          ...containerStyle,
        },
      }}
      {...dialogProps}
    >
      {children}
    </Dialog>
  );
};

export default ModalDialog;
