import * as React from 'react';
import { useTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Label } from 'reactstrap';
import cx from 'classnames';
import { Field, FormikProps } from 'formik';
import * as Yup from 'yup';

import Button, { ButtonIconProp } from 'src/components/core/Button/Button';
import FormButton from 'src/components/FormButton/FormButton';
import FormError from 'src/components/FormError/FormError';
import FormStep, { FormContent } from 'src/components/FormStep/FormStep';
import { getErrorMessage } from 'src/util/form';

import styles from './PasswordStep.module.scss';

interface BaseProps {
  onBack: () => void;
  generalFormError: React.ReactNode;
  isSaving: boolean;
  autoFocus: boolean;
}

type MappedData = { btnText: string; icon: ButtonIconProp; inputType: string };

type Props = BaseProps & FormikProps<any> & RouteComponentProps;

const PasswordStep = ({
  handleChange,
  submitForm,
  onBack,
  values,
  setFieldValue,
  errors,
  location,
  setStatus,
  setErrors,
  status,
  generalFormError,
  isSaving,
  autoFocus,
}: Props) => {
  const { t } = useTranslation();
  const passwordToggleContent: { hidden: MappedData; visible: MappedData } = {
    hidden: {
      btnText: t('common.show'),
      icon: ['far', 'eye'],
      inputType: 'password',
    },
    visible: {
      btnText: t('common.hide'),
      icon: ['far', 'eye-slash'],
      inputType: 'text',
    },
  };
  const [showPassword, setShowPassword] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<undefined | string>(
    undefined
  );

  React.useEffect(() => {
    if (location.state) {
      setStatus(location.state);
    }
  }, [location.state, setStatus]);

  React.useEffect(() => {
    setErrorMessage(
      getErrorMessage({
        field: 'password',
        status,
        errors,
      })
    );
  }, [status, errors]);

  const handleDoLater = () => {
    // clear out anything the user might have put in password
    setFieldValue('password', '');
  };

  const handlePasswordChange = (e: any) => {
    setStatus(undefined);
    // call default handle change
    handleChange(e);
  };

  const handleSubmit = () => {
    if (values.password === '') {
      setErrors({
        password: t('register.password_validation_empty'),
      });
      return;
    }

    submitForm();
  };

  const { icon, inputType, btnText } = showPassword
    ? passwordToggleContent.visible
    : passwordToggleContent.hidden;

  return (
    <FormStep onBack={onBack}>
      <FormContent>
        <h1 className="mb-1">{t('register.password_header')}</h1>
        <Label for="password" className="w-100">
          {t('register.password_label')}
          <Button
            onClick={() => setShowPassword(!showPassword)}
            icon={icon}
            color="link"
            data-testid="show-password-button"
            className={cx('float-end me-1', styles.passwordToggleButton)}
          >
            {btnText}
          </Button>
          <Field
            name="password"
            id="password"
            type={inputType}
            component="input"
            onChange={handlePasswordChange}
            value={values.password}
            aria-describedby="password-helper error-msg-password"
            aria-invalid={Boolean(errorMessage != null)}
            data-testid="password-step"
            aria-required
            className="form-control"
            autoFocus={autoFocus}
          />
          <FormError name="password">{errorMessage}</FormError>
        </Label>
        <div id="password-helper">
          <p>{t('register.password_subtext')}</p>
        </div>
      </FormContent>
      <FormError name="general">{generalFormError}</FormError>

      <FormButton
        type="submit"
        onClick={handleDoLater}
        className="mb-2"
        outline
        aria-disabled={isSaving}
        disabled={isSaving}
      >
        {t('register.password_button_later')}
      </FormButton>
      <FormButton
        onClick={handleSubmit}
        data-testid="next-button"
        aria-disabled={isSaving}
        disabled={isSaving}
      >
        {t('common.next')}
      </FormButton>
    </FormStep>
  );
};

PasswordStep.validationSchema = ({ t }: WithTranslation) =>
  Yup.object().shape({
    password: Yup.string()
      .min(8, t('register.password_validation_length'))
      .matches(/\D/, t('register.password_validation_letters')),
  });

export default PasswordStep;
