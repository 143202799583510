// Note we import our css first so our components can override
// eslint-disable-next-line
import './assets/base.scss';

import 'react-app-polyfill/ie11';
import './internationalization/i18n';

import * as React from 'react';
import * as ReactDOM from 'react-dom';

import Modal from 'react-modal';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

import { getBootstrapData } from 'src/api/api';
import App from 'src/components/App/App';
import ScrollToTop from 'src/components/ScrollToTop/ScrollToTop';

import { loginSuccess } from 'src/redux/auth/auth-slice';
import makeStore from 'src/redux/store';

const store = makeStore();

// configure modal
Modal.setAppElement('#root');

const render = (Component: React.ComponentClass) => {
  ReactDOM.render(
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <ScrollToTop>
          <Provider store={store}>
            <Component />
          </Provider>
        </ScrollToTop>
      </QueryParamProvider>
    </Router>,
    document.getElementById('root')
  );
};
if (process.env.NODE_ENV === 'development') {
  // when in dev mode, window.BOOTSTRAP_DATA is not available so grab it this way
  getBootstrapData().then((data) => {
    store.dispatch(loginSuccess(data));
    render(App as any);
  });
} else {
  // do this to set GA user on login
  store.dispatch(loginSuccess(window.BOOTSTRAP_DATA));
  render(App as any);
}

// Hot Module Replacement API
if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./components/App/App', () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const App = require('./components/App/App').default;
    render(App as any);
  });
}
