import { StoreState } from 'src/redux/store';
import { RegisteredConversation } from 'src/types/registration';

// selectors
export const selectors = {
  getRegisteredConversations: (state: StoreState): RegisteredConversation[] =>
    state.registration.upcomingConversations.conversations,
  isLoadingRegisteredConversations: (state: StoreState): boolean =>
    state.registration.upcomingConversations.isLoading,
  errorLoadingRegisteredConversations: (state: StoreState): Error | undefined =>
    state.registration.upcomingConversations.error,

  getEditRegistrationId: (
    state: StoreState
  ): RegisteredConversation['id'] | undefined =>
    state.registration.editRegistration.id,
  isSavingEditRegistration: (state: StoreState): boolean =>
    state.registration.editRegistration.isSaving,
  errorEditRegistration: (state: StoreState): Error | undefined =>
    state.registration.editRegistration.error,
  isSavedEditRegistration: (state: StoreState): boolean =>
    state.registration.editRegistration.isSaved,
};
export default selectors;
