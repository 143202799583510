import * as React from 'react';
import cx from 'classnames';

import { Organization } from 'src/types/auth';

import styles from './OrganizationFavicon.module.scss';

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  organization: Organization;
  className?: string;
}
const OrganizationFavicon = ({ organization, className, ...other }: Props) => {
  if (!organization.favicon_image) {
    return null;
  }
  return (
    <img
      src={organization.favicon_image}
      data-testid="org-favicon"
      className={cx(className, styles.favicon)}
      alt={organization.name}
      {...other}
    />
  );
};

export default OrganizationFavicon;
