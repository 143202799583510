import { selectors as entitiesSelectors } from 'src/redux/entities/entities-selectors';
import { StoreState } from 'src/redux/store';
import { Conversation, Paging } from 'src/types/conversation';
import { ConversationsFilterOptions } from 'src/types/core';

// selectors
export const selectors = {
  isLoading: (state: StoreState): boolean =>
    state.conversations.filtered.isLoading,
  getConversations: (state: StoreState): Conversation[] =>
    entitiesSelectors.getConversations(
      state,
      state.conversations.filtered.ids,
      {
        nestHighlights: true,
        nestSnippetsInHighlights: true,
        nestOrganization: true,
      }
    ),
  getConversationsPagingInfo: (state: StoreState): Paging | undefined =>
    state.conversations.filtered.paging,
  getError: (state: StoreState): Error | undefined =>
    state.conversations.filtered.error,
  getConversationsFilterOptions: (
    state: StoreState
  ): ConversationsFilterOptions | undefined =>
    state.conversations.filtered.filters,
  getDraftConversations: (state: StoreState): Conversation[] =>
    entitiesSelectors.getConversations(state, state.conversations.draft.ids, {
      nestHighlights: false,
      nestSnippetsInHighlights: false,
      nestOrganization: true,
      nestStaffOwner: true,
    }),
  getDraftConversationsError: (state: StoreState): Error | undefined =>
    state.conversations.draft.error,
  isDraftConversationsLoading: (state: StoreState): boolean =>
    state.conversations.draft.isLoading,
};
export default selectors;
