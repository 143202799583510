import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { clone } from 'lodash';
import debounce from 'lodash.debounce';
import WaveSurfer from 'wavesurfer.js';
import * as WaveSurferCursor from 'wavesurfer.js/dist/plugin/wavesurfer.cursor';
import * as WaveSurferRegion from 'wavesurfer.js/dist/plugin/wavesurfer.regions';
import * as WaveSurferTimeline from 'wavesurfer.js/dist/plugin/wavesurfer.timeline';

import GlobalAudioContext from 'src/contexts/GlobalAudioContext';
import LoadingOverlay from '../core/LoadingOverlay/LoadingOverlay';
import PlayButton from '../PlayButton/PlayButton';

import styles from './WaveSurfer.module.scss';

interface WavesurferProps {
  defaultTrimTimes?: [number, number];
  onTrim: (trimTimes: any) => void;
  peaks: any;
  setPeaks: (peaks: any) => void;
  defaultSeek?: number;
  extraButtons?: React.ReactNode;
}

const RedactionWavesurfer = ({
  defaultTrimTimes,
  peaks,
  setPeaks,
  defaultSeek = 0,
  onTrim,
  extraButtons,
}: WavesurferProps) => {
  const { t } = useTranslation();
  const { sound, src } = React.useContext(GlobalAudioContext);
  const wavesurferRef = React.useRef<typeof WaveSurfer>(null);
  const waveRef = React.useRef<HTMLDivElement>(null);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [isReady, setIsReady] = React.useState(false);
  const REGION_ID = 'audioTrimRegion';

  const getWavesurferPosition = (time: number) => {
    const position = time / (wavesurferRef.current?.getDuration() ?? 0);
    return position;
  };

  const getWavesurferTimeData = React.useCallback(() => {
    const startTime = wavesurferRef.current.regions.list[REGION_ID].start;
    const startPosition = getWavesurferPosition(startTime);
    const endTime = wavesurferRef.current.regions.list[REGION_ID].end;
    const endPosition = getWavesurferPosition(endTime);
    const currentTime = wavesurferRef.current.getCurrentTime();
    const currentPosition = getWavesurferPosition(currentTime);
    return {
      startTime,
      startPosition,
      endTime,
      endPosition,
      currentTime,
      currentPosition,
    };
  }, []);

  React.useEffect(() => {
    if (isReady && peaks && wavesurferRef.current) {
      //Load the timeline once the waveform is visible
      wavesurferRef.current
        .addPlugin(
          WaveSurferTimeline.create({
            container: '.waveTimeline',
          })
        )
        .initPlugin('timeline');
    }
  }, [isReady, peaks]);

  React.useLayoutEffect(() => {
    let ready = false;
    const wavesurfer = WaveSurfer.create({
      backend: 'MediaElement',
      barGap: 3,
      barRadius: 3,
      barWidth: 3,
      waveColor: '#6351D8',
      container: waveRef.current,
      normalize: true,

      plugins: [
        WaveSurferRegion.create({}),
        WaveSurferCursor.create({
          showTime: true,
          opacity: 0.9,
          customShowTimeStyle: {
            padding: '4px',
            backgroundColor: '#666666',
            color: '#ffffff',
            fontSize: '12px',
            position: 'absolute',
            top: 0,
          },
        }),
      ],
    });

    const createTimeline = () =>
      wavesurfer
        .addPlugin(
          WaveSurferTimeline.create({
            container: '.waveTimeline',
          })
        )
        .initPlugin('timeline');

    if (sound?.audio && src) {
      wavesurfer.load(sound?.audio, peaks);
    }

    wavesurfer.on('ready', () => {
      ready = true;
      wavesurferRef.current = wavesurfer;
      addRegion();
      setIsReady(true);
      if (peaks) {
        createTimeline();
      }
    });

    wavesurfer.on('waveform-ready', (e: any) => {
      // Store the peaks on first load so we don't have to wait for the peaks after first load.
      const length = Math.round(
        wavesurfer.getDuration() * 20 * window.devicePixelRatio
      );
      const start = 0;
      const end = length;
      setPeaks(wavesurfer.backend.getPeaks(length, start, end));
      if (ready) {
        createTimeline();
      }
    });

    wavesurfer.on(
      'seek',
      debounce((seekPosition: number) => {
        const { startPosition, endPosition } = getWavesurferTimeData();
        if (seekPosition < startPosition || seekPosition > endPosition) {
          // Move the seek to within the region
          wavesurfer.seekTo(startPosition);
        }
      }, 50)
    );

    wavesurfer.on(
      'region-updated',
      debounce(() => {
        const {
          startTime,
          startPosition,
          endTime,
          endPosition,
          currentPosition,
        } = getWavesurferTimeData();
        const trimTimes = [startTime, endTime];
        if (currentPosition < startPosition || currentPosition > endPosition) {
          // Move the seek to within the region
          wavesurfer.seekTo(startPosition);
        }
        onTrim(trimTimes);
      }, 100)
    );
    return () => {
      if (ready) {
        wavesurfer.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  const resetAudio = React.useCallback(() => {
    const { startTime, startPosition, endTime, currentTime } =
      getWavesurferTimeData();
    if (currentTime < startTime) {
      wavesurferRef.current.seekTo(startPosition);
    } else if (currentTime.toFixed() >= endTime.toFixed()) {
      wavesurferRef.current.seekTo(startPosition);
      wavesurferRef.current.pause();
      setIsPlaying(false);
    }
  }, [getWavesurferTimeData]);

  const addRegion = () => {
    wavesurferRef.current.clearRegions();
    wavesurferRef.current.addRegion({
      start: defaultTrimTimes ? defaultTrimTimes[0] : 0,
      end: defaultTrimTimes
        ? defaultTrimTimes[1]
        : wavesurferRef.current.getDuration(),
      color: 'rgba(1,1,1,0.5)',
      drag: true,
      loop: false,
      minLength: 0.1,
      resize: true,
      id: REGION_ID,
    });

    // Change start position
    wavesurferRef.current.seekTo(
      getWavesurferPosition(
        defaultTrimTimes ? defaultTrimTimes[0] : defaultSeek
      )
    );

    // Handle initial zoom based on trim times
    const initialZoom =
      defaultTrimTimes && defaultTrimTimes[0]
        ? Math.min(
            36 -
              (36 * (defaultTrimTimes[1] - defaultTrimTimes[0])) /
                wavesurferRef.current.getDuration(),
            35
          ) ?? 0
        : 0;
    wavesurferRef.current.zoom(initialZoom);

    // handle region cursor out
    wavesurferRef.current.regions.list[REGION_ID].on('out', () => {
      resetAudio();
    });

    // style region
    wavesurferRef.current.regions.on('ready', () => {
      changeRegionAppearance();
    });
  };

  const changeRegionAppearance = React.useCallback(() => {
    const regionHandles = document.getElementsByClassName(
      'wavesurfer-handle'
    ) as HTMLCollectionOf<HTMLElement>;
    Array.from(regionHandles).forEach((handle) => {
      handle.style.width = '5px';
      handle.style.backgroundColor = '#29a847';
    });

    const wavesurferRegion = document.getElementsByClassName(
      'wavesurfer-region'
    ) as HTMLCollectionOf<HTMLElement>;
    wavesurferRegion[0].style.zIndex = '1';
  }, []);

  const playAudio = () => {
    setIsPlaying(!isPlaying);
    wavesurferRef.current.playPause();
  };

  const adjustZoomLevel = debounce((event: any) => {
    const zoom = clone(event.target.value);
    wavesurferRef.current.zoom(zoom);
  }, 100);

  return (
    <div className="waveContainer">
      <div data-testid="wavesurfer-display">
        <LoadingOverlay active={!(isReady && peaks)} />
        <div className={cx('waveTimeline', styles.timeline)}></div>
        <div
          className={cx('wave', styles.wavesurfer)}
          ref={waveRef}
          style={{
            width: '100%',
            display: isReady && peaks ? 'block' : 'none',
          }}
        />
        <div className={cx(styles.zoomSlider)}>
          <label className={cx(styles.zoomLabel)} htmlFor="zoomSlider">
            {t('audio_player.minus')}
          </label>
          <input
            type="range"
            name="zoomSlider"
            min="0"
            max="35"
            defaultValue={35}
            onChange={adjustZoomLevel}
          />
          <label className={cx(styles.zoomLabel)} htmlFor="zoomSlider">
            {t('audio_player.plus')}
          </label>
        </div>
        <div className={cx('buttonContainer', styles.buttonContainer)}>
          <PlayButton
            data-testid="wavesurfer-play-button"
            className={cx(styles.waveButton)}
            onClick={playAudio}
            isPlaying={isPlaying}
          />
          {extraButtons}
        </div>
      </div>
    </div>
  );
};

export default RedactionWavesurfer;
