import * as React from 'react';
import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';
import nanoid from 'nanoid';

import { AnnotationCreationSelection } from 'src/types/conversation';

export default function useAnnotationFromSelection(
  maxSelectionDuration: number | undefined = 240 // 4 minute max default
) {
  const [annotationCreationSelection, setAnnotationCreationSelection] =
    React.useState<AnnotationCreationSelection | undefined>(undefined);
  const [persistSelection, setPersistSelection] = React.useState(false);

  // true when a selection has stabilized being created
  const [selectionReady, setSelectionReady] = React.useState(false);
  const debouncedSelectionReady = React.useMemo(
    () =>
      debounce(
        (ready: boolean) => {
          setSelectionReady(ready);
        },
        200,
        {
          trailing: true,
        }
      ),
    []
  );

  const clearSelectionRange = React.useCallback(
    (clearWindowSelection?: boolean) => {
      setAnnotationCreationSelection(undefined);
      debouncedSelectionReady.cancel();
      setSelectionReady(false);

      if (clearWindowSelection) {
        // ensure nothing is selected
        const selection = window.getSelection();
        if (selection) {
          selection.removeAllRanges();
        }
      }
    },
    [debouncedSelectionReady]
  );

  /**
   * Handle creating a highlight by selecting text in the transcript
   */
  const handleSelectionChange = React.useMemo(
    () =>
      throttle(
        function handleSelectionChangeInner() {
          // related docs:
          // https://developer.mozilla.org/en-US/docs/Web/API/Selection
          // https://developer.mozilla.org/en-US/docs/Web/API/Range
          const selection = window.getSelection();

          // ignore selection changes if we are persisting
          if (persistSelection) {
            return;
          }

          // if it's not a range, ignore
          if (!selection || selection.type !== 'Range') {
            clearSelectionRange();
            return;
          }

          const startRange = selection.getRangeAt(0);
          const endRange = selection.getRangeAt(selection.rangeCount - 1);

          // if for some reason we don't have parent elements, ignore
          if (
            !(
              startRange &&
              startRange.startContainer &&
              startRange.startContainer.parentElement &&
              endRange &&
              endRange.endContainer &&
              endRange.endContainer.parentElement
            )
          ) {
            clearSelectionRange();
            return;
          }

          // read the timings from the selected elements
          const startTimeStr =
            startRange.startContainer.parentElement.dataset.starttime;
          const endTimeStr =
            endRange.endContainer.parentElement.dataset.endtime;

          if (startTimeStr == null || endTimeStr == null) {
            clearSelectionRange();
            return;
          }

          // convert to number
          const startTime = +startTimeStr;
          const endTime = +endTimeStr;

          // clear the highlight if longer than our max duration
          if (Math.abs(endTime - startTime) > maxSelectionDuration) {
            clearSelectionRange();
            return;
          }

          debouncedSelectionReady(true);

          // set highlight bounds if different
          if (
            !annotationCreationSelection ||
            startTime !== annotationCreationSelection.audio_start_offset ||
            endTime !== annotationCreationSelection.audio_end_offset
          ) {
            setAnnotationCreationSelection({
              audio_start_offset: startTime,
              audio_end_offset: endTime,
              creation_tag: nanoid(),
            });
          }
        },
        16,
        {
          leading: true,
          trailing: true,
        }
      ),
    [
      annotationCreationSelection,
      persistSelection,
      clearSelectionRange,
      debouncedSelectionReady,
      maxSelectionDuration,
    ]
  );

  React.useEffect(() => {
    document.addEventListener('selectionchange', handleSelectionChange);

    return () => {
      document.removeEventListener('selectionchange', handleSelectionChange);
    };
  }, [handleSelectionChange]);

  return {
    annotationCreationSelection,
    selectionReady,
    setPersistSelection,
    clearSelectionRange,
  };
}
