import * as React from 'react';
import cx from 'classnames';

interface Props {
  heading: string;
  children: React.ReactNode;
  headingId?: string;
  className?: string;
}
const RegistrationDetailBlock = ({
  heading,
  children,
  headingId,
  className,
  ...other
}: Props) => (
  <div className={cx(className, 'mb-2')} {...other}>
    <h3 className="mb-1 h4" id={headingId}>
      {heading}
    </h3>
    {children}
  </div>
);

export default RegistrationDetailBlock;
