import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import partition from 'lodash.partition';

import { useConversationPrivacyContent } from 'src/constants';
import { User } from 'src/types/auth';
import { UserNestedCollection } from 'src/types/collection';

import styles from './CollectionDropdown.module.scss';

interface SearchProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

const SearchBar = ({ value, className, onChange }: SearchProps) => {
  const { t } = useTranslation();
  return (
    <div className={className}>
      <Input
        type="search"
        value={value}
        placeholder={t('topics.topic_collection_dropdown_search')}
        name="collections-search"
        id="collections-search"
        onChange={onChange}
        className="px-1"
        data-testid="search-bar"
      />
    </div>
  );
};

const CollectionDropdownItems = ({
  collections,
  onClick,
}: {
  collections: UserNestedCollection[];
  onClick: (id: number) => void;
}) => {
  const privacyConstants = useConversationPrivacyContent();
  return (
    <>
      {collections.map((collection) => {
        const { id, title, privacy_level, organization_name } = collection;
        const privacyContent = privacy_level
          ? privacyConstants[privacy_level]
          : undefined;

        const isPublic = privacy_level === 'public';
        const isProtected = privacy_level === 'protected';
        const hasIcon = isPublic || isProtected;

        const itemProps = {
          key: `dropdown-item-${id}`,
          'data-collection-id': id,
          'data-testid': `collection-dropdown-item-${id}`,
        };

        return (
          <div key={id}>
            <DropdownItem
              {...itemProps}
              title={
                privacyContent && hasIcon
                  ? privacyContent.title('Collection')
                  : undefined
              }
              onClick={() => onClick(id)}
            >
              <span className="fwmedium">
                {privacyContent && hasIcon && (
                  <span
                    className={styles.itemIconContainer}
                    data-testid={`${privacy_level}-icon`}
                  >
                    <FontAwesomeIcon
                      icon={privacyContent.icon}
                      className={`text-${privacy_level}`}
                    />
                  </span>
                )}
                {title}
              </span>
              <span className={'d-block text-gray-700'}>
                {organization_name}
              </span>
            </DropdownItem>
          </div>
        );
      })}
    </>
  );
};

interface Props {
  user: User;
  onChange: (collectionId: number) => void;
  activeCollection: UserNestedCollection | undefined;
  headerTag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  headerClassname?: string;
  disableAllCollections?: boolean;
}

export const CollectionDropdown = ({
  user,
  onChange,
  activeCollection,
  headerTag = 'h1',
  headerClassname,
  disableAllCollections = false,
}: Props) => {
  const { t } = useTranslation();
  const [dropdownIsOpen, setDropdownIsOpen] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');
  const handleToggleDropdown = () => setDropdownIsOpen(!dropdownIsOpen);

  const handleCollectionClick = React.useCallback(
    (cid: number) => {
      onChange(cid);
    },
    [onChange]
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.currentTarget.value.toLowerCase());
  };

  const { collections: userCollections, organizations } = user;

  // Don't render dropdown or label in case of no collections
  // e.g. anonymous user, staff with no specified collections
  if (userCollections.length === 0) {
    return null;
  }

  if (userCollections.length === 1) {
    return (
      <h1
        // className={styles['collection-label']}
        data-testid="collection-label"
      >
        {activeCollection && activeCollection.title}
      </h1>
    );
  }

  // separate out the all collection option (should only be one)
  const [allCollectionOptions, standardCollections] = partition(
    userCollections,
    (collection: UserNestedCollection) => collection.is_all_collection_option
  );
  const allCollectionOption =
    allCollectionOptions.length > 0 && allCollectionOptions[0];

  const filteredCollections = searchValue
    ? standardCollections.filter(
        (col) =>
          col.title.toLowerCase().includes(searchValue) ||
          (col.organization_name &&
            col.organization_name.toLowerCase().includes(searchValue))
      )
    : standardCollections;

  // separate into whether the user is part of the organization of the collection
  const [myCollections, otherCollections] = partition(
    filteredCollections,
    (collection: UserNestedCollection) =>
      organizations.find((org) => org.id === collection.organization_id)
  );

  // allow for dynamic headers
  const Heading = headerTag as keyof JSX.IntrinsicElements;

  return (
    <Dropdown
      isOpen={dropdownIsOpen}
      toggle={handleToggleDropdown}
      data-testid="collection-dropdown"
      className={styles['collection-dropdown-container']}
      direction="down"
    >
      <DropdownToggle
        color="''" /* disable bootstrap coloring */
        className={styles.dropdownToggle}
        data-testid="collection-dropdown-toggle"
      >
        <Heading className={cx('mb-0', headerClassname)}>
          {activeCollection?.is_all_collection_option
            ? t('collection.all')
            : activeCollection && activeCollection.title}
          <span className={styles.caret} />
        </Heading>
        {/* {subheading ? (
          <h2 className="mb-1 h3">{label}</h2>
        ) : (
          <h1 className="mb-0">{label}</h1>
        )} */}
      </DropdownToggle>
      <DropdownMenu className={styles.centeredMenu}>
        <SearchBar
          value={searchValue}
          onChange={handleSearchChange}
          className={styles.search}
        />
        {/* first render the all collection option if it exists and it is allowed */}
        {allCollectionOption && !disableAllCollections && (
          <>
            <DropdownItem
              className="fwmedium"
              data-testid="all-collections"
              onClick={() => handleCollectionClick(allCollectionOption.id)}
            >
              {t('topics.topic_collection_dropdown_default_option')}
            </DropdownItem>
            <DropdownItem divider className="mt-1" />
          </>
        )}
        {searchValue && filteredCollections.length === 0 && (
          <div className="dropdown-item">
            {t('collection.search_empty', { searchValue })}
          </div>
        )}
        {/* then render the user's organization collections if there any */}
        {myCollections.length > 0 && (
          <div data-testid="my-collections">
            <DropdownItem header tag="span" className="small-header">
              {t('topics.topic_collection_dropdown_header')}
            </DropdownItem>

            <CollectionDropdownItems
              collections={myCollections}
              onClick={handleCollectionClick}
            />
            {otherCollections.length > 0 && <DropdownItem divider />}
          </div>
        )}
        {/* then the rest of the collections */}
        <div data-testid="other-collections">
          <CollectionDropdownItems
            collections={otherCollections}
            onClick={handleCollectionClick}
          />
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default CollectionDropdown;
