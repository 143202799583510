import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Label } from 'reactstrap';
import cx from 'classnames';

import { FilterOption } from 'src/types/core';
import useOnScreen from 'src/util/hooks';
import CheckboxWrapper from '../CheckboxWrapper/CheckboxWrapper';
import Toggle from '../core/Toggle/Toggle';

import styles from './FilterChecklist.module.scss';

const SEARCH_THRESHOLD = 12;
const SCROLL_THRESHOLD = 10;

interface Props {
  options: FilterOption[];
  activeFilterOptions: (FilterOption | undefined)[] | undefined;
  onChange: (option: FilterOption) => void;
  title: string;
  hideSearch?: boolean;
  onSelectAll?: (selectAll: boolean) => void;
  selectAllDisabled?: boolean;
  fade?: boolean;
}

const FilterChecklist = ({
  options,
  activeFilterOptions,
  title,
  hideSearch,
  selectAllDisabled,
  fade,
  onSelectAll,
  onChange,
}: Props) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = React.useState('');

  // initialize ref and observer
  const buffer = React.useRef<HTMLDivElement>(null);
  const atBottom = useOnScreen(buffer);

  // set selectAllChecked based on if all options are active
  const allSelected =
    activeFilterOptions?.length === options.length && options.length > 0;

  // we want search if there are more than SEARCH_THRESHOLD keywords, but also
  // want to just be able to not show it at all
  const showInput = hideSearch ? false : options.length >= SEARCH_THRESHOLD;
  const filteredOptions = inputValue
    ? options.filter((d) => d.name.toLowerCase().includes(inputValue))
    : options;

  return (
    <div
      className={cx('px-3', styles.container)}
      data-testid="filter-container"
    >
      <div className={styles.inputContainer}>
        {onSelectAll && (
          <div className="mb-2">
            <div className="d-flex align-items-center justify-content-between">
              <Label
                htmlFor={`${title}-select-all`}
                className={cx(styles.toggleText, {
                  'text-gray-600': selectAllDisabled,
                })}
                disabled={selectAllDisabled}
              >
                {`${t('common.select_all')} ${title.toLowerCase()}`}
              </Label>
              <Toggle
                id={`${title}-select-all`}
                handleChange={() => onSelectAll(!allSelected)}
                checked={allSelected}
                testid="select-all"
                disabled={selectAllDisabled}
              />
            </div>
            {options.length > 0 && <hr className="mt-2 mb-0"></hr>}
          </div>
        )}
        {showInput && (
          <div>
            <Input
              type="search"
              value={inputValue}
              placeholder={t('common.search')}
              name={`${title}-search`}
              id={`${title}-search`}
              className="mb-3 pt-1"
              data-testid="filter-search"
              onChange={(e) =>
                setInputValue(e.currentTarget.value.toLowerCase())
              }
            />
          </div>
        )}
        {inputValue && filteredOptions.length === 0 && (
          <div className="mb-2" data-testid="empty-search-msg">
            No results found for '{inputValue}'
          </div>
        )}
      </div>
      {filteredOptions.map((option, i) => {
        const checked = !!(
          activeFilterOptions && activeFilterOptions.includes(option)
        );
        const label = option.count
          ? `${option.name} (${option.count})`
          : option.name;
        const optionKey = `${title}-${option.name}`;
        return (
          <CheckboxWrapper
            key={`${optionKey}-${i}`}
            inputName={optionKey}
            checked={checked}
            labelText={label}
            className={styles.checkboxWrapper}
            testid={optionKey}
          >
            <input
              type="checkbox"
              name={optionKey}
              id={optionKey}
              checked={checked}
              onChange={() => onChange(option)}
            />
          </CheckboxWrapper>
        );
      })}
      {fade && filteredOptions.length > SCROLL_THRESHOLD && (
        // Adds a fade at the bottom when the side filter is scrollable
        <>
          <div className={styles.buffer} ref={buffer} />
          <div className={cx({ [styles.fade]: !atBottom })} />
        </>
      )}
    </div>
  );
};

export default React.memo(FilterChecklist);
