import React from 'react';
import Flatpickr from 'react-flatpickr';
import {
  Trans,
  useTranslation,
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { AutoSizer } from 'react-virtualized';
import { Col, Form, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import cx from 'classnames';
import { FormikProps, withFormik } from 'formik';
import moment from 'moment-timezone';
import * as Yup from 'yup';

import { uploadConversation } from 'src/api/api';
import Button from 'src/components/core/Button/Button';
import LoadingOverlay from 'src/components/core/LoadingOverlay/LoadingOverlay';
import LoadingSpinner from 'src/components/core/LoadingSpinner/LoadingSpinner';
import DropUpload from 'src/components/DropUpload/DropUpload';
import { default as ErrorText } from 'src/components/FormErrorText/FormErrorText';
import { User } from 'src/types/auth';
import {
  ConversationMetadataForm,
  UploadMetadata,
} from 'src/types/conversation';
import { OrganizationMetadata } from 'src/types/organization';
import { getActiveOrganizationMetadata } from 'src/util/collections';
import dateFormatter from 'src/util/date';
import { humanFileSize } from 'src/util/format';
import CollectionInput from './CollectionInput/CollectionInput';
import GeocodingMap, { GeocoderResult } from './GeocodingMap/GeocodingMap';

import 'flatpickr/dist/flatpickr.min.css';
import styles from './ConversationUploadForm.module.scss';

// configured in nginx
// https://github.com/CorticoAI/cortico-platform/blob/master/src/nginx/app-endpoint/sites-enabled/app.lvn.org
const MAX_AUDIO_FILE_SIZE = 1500000000; // 1.5GB

type FormProps = ConversationMetadataForm;

interface ConversationUploadFormProps {
  activeCollection: User['collections'][0];
  hostCollections: User['collections'];
  user: User;
  uploadMetadata: UploadMetadata;
  organizationsMetadata: OrganizationMetadata[] | undefined;
  onRecord: any;
  onSuccess: (res: any) => void;
  formType: string;
}

// if the user is a host in the active collection, use that as default
// otherwise take first collection
const getInitialCollection = (
  activeCollection: User['collections'][0],
  hostCollections: User['collections']
) => {
  return activeCollection &&
    hostCollections.find(
      (hostCollection) => hostCollection.id === activeCollection.id
    )
    ? activeCollection
    : hostCollections[0];
};

const InnerForm = (
  props: ConversationUploadFormProps & FormikProps<FormProps>
) => {
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    isSubmitting,
    errors,
    touched,
    status,
    setFieldValue,
    setTouched,
    uploadMetadata,
    activeCollection,
    hostCollections,
    organizationsMetadata,
    formType,
  } = props;
  const { t } = useTranslation();
  const [showLocationMarker, setShowLocationMarker] = React.useState(false);
  const handleLngLatResult = (result: GeocoderResult) => {
    const { center } = result;
    setFieldValue('lngLat', center);
    setShowLocationMarker(true);
    setTouched({ lngLat: true });
  };

  const handleLngLatClear = () => {
    setFieldValue('lngLat', ['', '']);
    setShowLocationMarker(false);
  };

  const handleChangeAudioFile = ({
    files,
    error,
  }: {
    files: File[];
    error: string | undefined;
  }) => {
    if (error) {
      setFieldValue('audioFile', error);
    } else {
      const file: File | Error = files[0] || 'UNKNOWN';
      setFieldValue('audioFile', file);
    }

    setTouched({ audioFile: true });
  };

  const handleRemoveAudioFile = (files: File[]) => {
    // if there are still files after removing a file
    // fire change audio file event
    if (files.length > 0) {
      handleChangeAudioFile({ files: files, error: undefined });
      return;
    }

    // set file to undefined if no files remain
    setFieldValue('audioFile', undefined);
    setTouched({ audioFile: true });
  };

  const handleAudioFileTrim = (trimTimes: any) => {
    setFieldValue('trimmedAudioStartTime', trimTimes[0]);
    setFieldValue('trimmedAudioEndTime', trimTimes[1]);
  };

  const initialCollection = getInitialCollection(
    activeCollection,
    hostCollections
  );
  const activeOrganizationMetadata = getActiveOrganizationMetadata(
    hostCollections,
    organizationsMetadata,
    +values.collectionId
  );
  const collectionIsCapped = activeOrganizationMetadata
    ? activeOrganizationMetadata.num_conversations >=
      activeOrganizationMetadata.upload_cap
    : true;

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Input hidden value={values.userId} readOnly></Input>
        {/* note current typings are wrong for "Row form" so we force it */}
        <Row form={true}>
          <Col sm={6}>
            <FormGroup data-testid="chapter-form-group">
              <CollectionInput
                hostCollections={hostCollections}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.collectionId}
              />
              {collectionIsCapped && (
                <ErrorText id="uploadCapError" testId="upload-cap-error">
                  <Trans
                    i18nKey="conversation_upload.capped"
                    /*eslint-disable */
                    components={{
                      1: <a href="mailto:help@lvn.org" />,
                    }}
                    /*eslint-disable */
                  />
                </ErrorText>
              )}
            </FormGroup>
          </Col>
        </Row>
        {!collectionIsCapped && (
          <>
            <Row form={true as any}>
              <Col sm={6}>
                <FormGroup>
                  <Label for="conversationTitle">
                    {t('conversation_upload.conversation')}
                  </Label>
                  <Input
                    type="text"
                    name="conversationTitle"
                    id="conversationTitle"
                    value={values.conversationTitle}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    data-testid="conversation-title"
                  ></Input>
                </FormGroup>
              </Col>
            </Row>
            <Row form={true as any}>
              <Col xs={12}>
                <FormGroup data-testid="location-form-group">
                  <Input
                    hidden
                    value={values.lngLat}
                    name="lngLat"
                    onChange={handleChange}
                  ></Input>
                  <Label for="location">
                    {t('conversation_upload.location')}
                  </Label>
                  {formType == 'upload' && (
                    <FormText className="mt-0 mb-1">
                      {t('conversation_upload.location_description')}
                    </FormText>
                  )}
                  <AutoSizer disableHeight>
                    {({ width, height = 400 }) => (
                      <React.Suspense
                        fallback={
                          <div
                            style={{
                              width: `${width}px`,
                              height: `${height}px`,
                              position: 'relative',
                            }}
                          >
                            <LoadingOverlay active />
                          </div>
                        }
                      >
                        <GeocodingMap
                          height={height}
                          width={width}
                          initialLngLat={initialCollection.lng_lat}
                          onResult={handleLngLatResult}
                          onClear={handleLngLatClear}
                          showLocationMarker={showLocationMarker}
                        />
                      </React.Suspense>
                    )}
                  </AutoSizer>
                </FormGroup>
              </Col>
            </Row>

            <Row form={true as any}>
              <Col sm={6}>
                <FormGroup>
                  <Label for="languageCode">{t('common.language')}</Label>
                  <Input
                    type="select"
                    name="languageCode"
                    id="languageCode"
                    value={values.languageCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    data-testid="language-code"
                  >
                    {uploadMetadata.languages.map((language) => (
                      <option
                        key={language.code}
                        value={language.code}
                        data-testid={`language-code-${language.code}-option`}
                      >
                        {language.title}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row form={true as any}>
              <Col sm={6}>
                <FormGroup data-testid="num-participants-form-group">
                  <Label for="numberOfParticipants" className="text-nowrap">
                    {t('conversation_upload.participants')}
                  </Label>
                  <FormText className="text-nowrap mt-0 mb-1">
                    {t('conversation_upload.self')}
                  </FormText>
                  <Input
                    type="number"
                    name="numberOfParticipants"
                    min={1}
                    id="numberOfParticipants"
                    value={values.numberOfParticipants}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    data-testid="number-of-participants"
                  ></Input>
                  {errors.numberOfParticipants && touched.numberOfParticipants && (
                    <ErrorText
                      id="numberOfParticipantsError"
                      testId="number-of-participants-error"
                    >
                      {errors.numberOfParticipants}
                    </ErrorText>
                  )}
                </FormGroup>
              </Col>
            </Row>
            {formType == 'upload' && (
              <>
                <Row form={true as any}>
                  <Col sm={6}>
                    <FormGroup>
                      <Label for="startDate">
                        {t('conversation_upload.date')}
                      </Label>
                      <Input
                        type="date"
                        name="startDate"
                        id="startDate"
                        value={values.startDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        data-testid="start-date"
                      ></Input>
                      {errors.startDate && touched.startDate && (
                        <ErrorText
                          id="startDateError"
                          testId="start-date-error"
                        >
                          {errors.startDate}
                        </ErrorText>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row form={true as any}>
                  <Col sm={6}>
                    <FormGroup>
                      <Label for="startTime">
                        {t('conversation_upload.start')}
                      </Label>
                      <Input
                        name="startTime"
                        id="startTime"
                        value={values.startTime}
                        onClose={(e: any) => {
                          // set time from full date value
                          const time = dateFormatter.timeFromMoment(
                            moment(new Date(e)),
                            { seconds: true }
                          );
                          setFieldValue('startTime', time);
                        }}
                        className={styles.flatpickrInput}
                        options={{
                          enableTime: true,
                          noCalendar: true,
                          dateFormat: 'h:i K',
                          time_24hr: false,
                        }}
                        tag={Flatpickr}
                        data-testid="start-time"
                      />
                      {errors.startTime && touched.startTime && (
                        <ErrorText
                          id="startTimeError"
                          testId="start-time-error"
                        >
                          {errors.startTime}
                        </ErrorText>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row form={true as any}>
                  <Col sm={6}>
                    <FormGroup>
                      <Label for="sourceType">
                        {t('conversation_upload.source')}
                      </Label>
                      <Input
                        type="select"
                        name="sourceType"
                        id="sourceType"
                        value={values.sourceType}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        data-testid="source-type"
                      >
                        {uploadMetadata.audio_sources.map((source) => (
                          <option
                            key={source[0]}
                            value={source[0]}
                            data-testid={`source-${source[0]}-option`}
                          >
                            {source[1]}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row form={true as any}>
                  <Col>
                    <FormGroup>
                      <Label for="audioFile">
                        {t('conversation_upload.audio')}
                      </Label>
                      <DropUpload
                        onChange={handleChangeAudioFile}
                        onRemoveFile={handleRemoveAudioFile}
                        onTrim={handleAudioFileTrim}
                        defaultText={t('conversation_upload.drag_and_drop')}
                        whileDraggingText={t('conversation_upload.dragging')}
                        subheading={t(
                          'conversation_upload.drag_and_drop_subtext'
                        )}
                        icon="file-audio"
                        accept="audio/mp3, audio/mp4, audio/wav, audio/x-m4a, audio/mpeg, audio/wave, audio/x-wav"
                        maxSize={MAX_AUDIO_FILE_SIZE}
                      />
                      {errors.audioFile && touched.audioFile && (
                        <ErrorText
                          id="audioFileError"
                          testId="audio-file-error"
                        >
                          {errors.audioFile}
                        </ErrorText>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </>
            )}
            {formType == 'recording' && (
              <FormGroup>
                <Button
                  type="submit"
                  color="primary"
                  className="me-2 mb-2 w-100"
                  data-testid="recording-conversation-btn"
                  aria-label={t('conversation_upload.recording_button')}
                  icon={['fas', 'microphone']}
                >
                  {t('conversation_upload.recording_button')}
                </Button>
                <div className={'fwlight'}>
                  {t('conversation_upload.recording_supports_copy')}
                </div>
              </FormGroup>
            )}
            {formType == 'upload' && (
              <FormGroup>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  color="primary"
                  className="me-2 mb-2 w-100"
                  data-testid="upload-conversation-btn"
                  aria-label={t('conversation_upload.button')}
                >
                  {isSubmitting
                    ? t('conversation_upload.button_active')
                    : t('conversation_upload.button')}
                </Button>
                <LoadingSpinner active={isSubmitting} />
              </FormGroup>
            )}
            {status && (
              <p
                className={cx({ 'text-danger': status.status === 'fail' })}
                data-testid="conversation-upload-submit-status"
              >
                {status.message}
              </p>
            )}
          </>
        )}
      </Form>
    </div>
  );
};

const ConversationUploadForm = withFormik<
  ConversationUploadFormProps,
  FormProps
>({
  // set initial values
  mapPropsToValues: ({
    user,
    activeCollection,
    hostCollections,
    ...props
  }: ConversationUploadFormProps) => {
    const now = moment(new Date());
    // if the user is a host in the active collection, use that as default
    // otherwise take first collection
    const initialCollection =
      activeCollection &&
      hostCollections.find(
        (hostCollection) => hostCollection.id === activeCollection.id
      )
        ? activeCollection
        : hostCollections[0];

    // empty string instead of undefined quiets some formik errors
    // https://github.com/jaredpalmer/formik/issues/28#issuecomment-312697214
    return {
      conversationTitle: '',
      collectionId: String(initialCollection.id),
      numberOfParticipants: '',
      startDate: dateFormatter.numericalDateFormat(now, { dashed: true }),
      startTime: '12:00:00', // match flatpickr's default time
      sourceType: 'zoom',
      audioFile: undefined,
      userId: user.id,
      languageCode: 'en-US',
      lngLat: undefined,
      trimmedAudioStartTime: undefined,
      trimmedAudioEndTime: undefined,
      ...props,
    };
  },

  validationSchema: ({ t }: WithTranslation) => {
    return Yup.object().shape({
      numberOfParticipants: Yup.number()
        .min(1, t('conversation_upload.validation_participants'))
        .required(t('conversation_upload.validation_participants')),
      startDate: Yup.string().required(
        t('conversation_upload.validation_date')
      ),
      startTime: Yup.string().required(
        t('conversation_upload.validation_time')
      ),
      audioFile: Yup.mixed().when('formType', {
        is: 'recording',
        then: Yup.mixed().nullable(),
        otherwise: Yup.mixed()
          .required(t('conversation_upload.validation_file_required'))
          .test(
            'filesize',
            t('conversation_upload.validation_file_type', {
              fileSize: humanFileSize(MAX_AUDIO_FILE_SIZE),
            }),
            (val) => val !== 'FILESIZE'
          )
          .notOneOf(['UNKNOWN'], t('conversation_upload.validation_file_type')),
      }),
    });
  },

  handleSubmit(
    {
      audioFile,
      collectionId,
      conversationTitle,
      numberOfParticipants,
      sourceType,
      startDate,
      startTime,
      userId,
      languageCode,
      lngLat,
      trimmedAudioStartTime,
      trimmedAudioEndTime,
      formType,
    }: FormProps,
    { props, setSubmitting, setStatus, resetForm }
  ) {
    if (formType == 'recording') {
      const recordingSourceType = 'other';
      const recordingStartDatetime = moment().format('YYYY-MM-DDTHH:mm:ss');
      const payload = {
        collection_id: collectionId,
        title: conversationTitle,
        num_participants: numberOfParticipants,
        source_type: recordingSourceType,
        start_time: recordingStartDatetime,
        host_id: userId,
        language_code: languageCode,
        geo: lngLat?.includes('')
          ? undefined
          : lngLat?.map((i) => i.toString()),
      };

      sessionStorage.setItem('recordingRoomMetadata', JSON.stringify(payload));
      sessionStorage.setItem('conversationTitle', conversationTitle);
      props.onRecord();
      return;
    }
    if (audioFile != null) {
      setSubmitting(true);
      const startDatetime = `${startDate}T${startTime}`;
      uploadConversation(
        {
          collectionId,
          conversationTitle,
          numberOfParticipants,
          sourceType,
          startDatetime,
          userId,
          languageCode,
          lngLat,
          trimmedAudioStartTime,
          trimmedAudioEndTime,
        },
        audioFile
      )
        .then((res) => {
          // overwrite the response start_time (UTC) with the user input start_time (local time zone)
          const successInfo = Object.assign(res, {
            start_time: startDatetime,
          });
          props.onSuccess(successInfo);
          setSubmitting(false);
        })
        .catch((err) => {
          setStatus({
            status: 'fail',
            message: (
              <Trans
                i18nKey="'conversation_upload.error'"
                /*eslint-disable */
                components={{ 1: <a href="mailto:help@lvn.org" /> }}
                /*eslint-disable */
              />
            ),
          });
          setSubmitting(false);
        });
    }
  },

  validateOnBlur: true,
  enableReinitialize: true,
})(InnerForm);

export default withTranslation()(ConversationUploadForm);
