import React from 'react';
import cx from 'classnames';

interface Props {
  children: React.ReactNode;
  height?: number;
  bgColor?: string;
  shadow?: boolean;
  testid?: string;
  className?: string;
}
const EmptyRect = ({
  children,
  height,
  bgColor = 'gray-200',
  shadow,
  className,
  testid,
}: Props) => {
  return (
    <div
      className={cx(className, `bg-${bgColor}`)}
      style={{ height }}
      data-testid={testid}
    >
      <div
        className={cx(
          'd-flex align-items-center justify-content-center text-gray-700 rounded p-0 h-100 fwmedium',
          { shadow: shadow }
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default EmptyRect;
