import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import Panel from 'src/components/Panel/Panel';
import { User } from 'src/types/auth';
import TeamMemberRow from './TeamMemberRow';

import styles from './TeamMemberTable.module.scss';

interface Props {
  members: User[] | undefined;
}

const TeamMemberTable = ({ members }: Props) => {
  const { t } = useTranslation();

  return (
    <Panel className={cx(styles.panel)} data-testid="team-mgmt-table">
      <div className={cx('row w-100 border-bottom', styles.tableHeader)}>
        {/* A row has a maximum of 12 columns */}
        <div className={cx('p-0 ps-4 fwnormal col-4')}>
          {t('admin.tab_team_member_list_column_1')}
        </div>
        <div className={cx('p-0 fwnormal col-2')}>
          {t('admin.tab_team_member_list_column_2')}
        </div>
        <div className={cx('p-0 fwnormal col-2')}>
          {t('admin.tab_team_member_list_column_3')}
        </div>
        <div className={cx('p-0 fwnormal col-1')}>
          {t('admin.tab_team_member_list_column_4')}
        </div>
        <div className={cx('p-0 fwnormal col-2')}>
          {t('admin.tab_team_member_list_column_5')}
        </div>
        <div className={cx('p-0 fwnormal col-1')}></div>
      </div>
      {members &&
        members.map((member, ind) => (
          <TeamMemberRow
            members={members}
            member={member}
            key={member.id}
            isFirst={ind === 0}
          />
        ))}
    </Panel>
  );
};

export default TeamMemberTable;
