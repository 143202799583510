import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Container } from 'reactstrap';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import { TopicSnippetFilters } from 'src/api/api';
import CollectionDropdown from 'src/components/CollectionDropdown/CollectionDropdown';
import EmptyRect from 'src/components/core/EmptyRect/EmptyRect';
import LoadingOverlay from 'src/components/core/LoadingOverlay/LoadingOverlay';
import Layout from 'src/components/Layout/Layout';
import { TopicSelector } from 'src/components/TopicSelector/TopicSelector';
import authSelectors from 'src/redux/auth/auth-selectors';
import collectionSelectors from 'src/redux/collection/collection-selectors';
import { loadCollectionDetails } from 'src/redux/collection/collection-slice';
import { StoreState } from 'src/redux/store';
import topicsSelectors from 'src/redux/topics/topics-selectors';
import {
  clearTopicSnippets,
  loadTopics,
  loadTopicSnippets,
} from 'src/redux/topics/topics-slice';
import { User } from 'src/types/auth';
import { CollectionDetail, UserNestedCollection } from 'src/types/collection';
import {
  NormalizedTopicSnippets,
  Paging,
  Topics,
  TopicWithRelatedContent,
} from 'src/types/conversation';
import { ColorScale, TopicSnippetsFilterOptions } from 'src/types/core';
import { useActiveCollection } from 'src/util/hooks';
import ExcerptListing from './ExcerptListing/ExcerptListing';
import TopicsAndKeywordsChart from './TopicsAndKeywordsChart/TopicsAndKeywordsChart';

interface StateProps {
  collectionDetail: CollectionDetail | undefined;
  isLoadingCollectionDetail: boolean;
  topicColorScale: ColorScale | undefined;
  topics: Topics;
  topicSnippets: NormalizedTopicSnippets;
  topicSnippetsPaging: Paging | undefined;
  topicSnippetsFilters: TopicSnippetsFilterOptions | undefined;
  isLoadingTopics: boolean;
  isLoadingTopicsSnippets: boolean;
  user: User;
}

type Props = RouteComponentProps & StateProps & DispatchProp;

const mapStateToProps = (state: StoreState): StateProps => ({
  collectionDetail: collectionSelectors.getCollectionDetail(state),
  isLoadingCollectionDetail:
    collectionSelectors.isLoadingCollectionDetail(state),
  topicColorScale: topicsSelectors.getTopicColorScale(state),
  topics: topicsSelectors.getTopics(state),
  isLoadingTopics: topicsSelectors.isTopicsLoading(state),
  topicSnippets: topicsSelectors.getTopicSnippets(state),
  topicSnippetsPaging: topicsSelectors.getTopicSnippetsPaging(state),
  topicSnippetsFilters: topicsSelectors.getTopicSnippetsFilterOptions(state),
  isLoadingTopicsSnippets: topicsSelectors.isTopicSnippetsLoading(state),
  user: authSelectors.getUser(state),
});
export const CollectionTopicsPage = ({
  dispatch,
  collectionDetail,
  isLoadingCollectionDetail,
  topicColorScale,
  topics,
  isLoadingTopics,
  topicSnippets,
  topicSnippetsPaging,
  topicSnippetsFilters,
  isLoadingTopicsSnippets,
  user,
}: Props) => {
  const { t } = useTranslation();
  const [query, setQuery] = useQueryParams({
    topic: StringParam,
    c: NumberParam,
  });
  const { topic: activeTopicName, c: activeCollectionId } = query;
  // top level check for loading
  const isLoading = isLoadingCollectionDetail || isLoadingTopics;

  const activeCollection = useActiveCollection(user, activeCollectionId, true); // disableAllCollections (ie collectionId === -1)

  if (activeCollectionId == null) {
    setQuery({ c: activeCollection.id });
  }

  const title = collectionDetail
    ? `${collectionDetail.title} Insights`
    : 'Insights';

  const setTopicFromId = (id?: number | null) => {
    if (id == null) {
      setQuery({ topic: undefined });
      return;
    }
    const topic = topics[id];
    setQuery({ topic: topic.name });
  };

  React.useEffect(() => {
    dispatch(loadCollectionDetails(activeCollection.id));
    dispatch(loadTopics(activeCollection.id));
  }, [activeCollection.id, dispatch]);

  const activeTopic: TopicWithRelatedContent | null =
    activeTopicName &&
    Object.values(topics).find((t) => t.name === activeTopicName);

  const handleLoadTopicSnippets = React.useCallback(
    (filters: Omit<TopicSnippetFilters, 'collectionId'>) => {
      const payload = {
        ...filters,
        collectionId: activeCollection.id,
      };
      dispatch(loadTopicSnippets(payload));
    },
    [dispatch, activeCollection.id]
  );

  const handleClearTopicSnippets = React.useCallback(
    ({ keepFilters }: { keepFilters: boolean }) => {
      dispatch(clearTopicSnippets({ keepFilters }));
    },
    [dispatch]
  );

  React.useEffect(() => {
    if (activeTopic) {
      const payload = { page: 1, limit: 10, topicId: activeTopic.id };
      handleLoadTopicSnippets(payload);
    } else {
      handleClearTopicSnippets({ keepFilters: false });
    }
  }, [activeTopic, handleLoadTopicSnippets, handleClearTopicSnippets]);

  const noTopics = Object.keys(topics).length === 0;

  const toggleActiveTopic = (id: number | undefined) => {
    const topicId = activeTopic && activeTopic.id === id ? null : id;
    setTopicFromId(topicId);
  };

  const handleChangeCollection = (collectionId: number) => {
    setQuery({ c: collectionId, topic: undefined });
  };

  return (
    <Layout className="CollectionInsights" title={title}>
      <Container>
        <Header
          collectionDetail={collectionDetail}
          user={user}
          activeCollection={activeCollection}
          onChangeCollection={handleChangeCollection}
          isLoading={isLoading}
        />
        {!isLoading && (
          <>
            {noTopics || collectionDetail == null || topicColorScale == null ? (
              <EmptyRect height={300} className="mb-5" testid="empty-topics">
                {t('topics.empty_state')}
              </EmptyRect>
            ) : (
              <>
                <TopicSelector
                  activeTopic={activeTopic || null}
                  topics={topics}
                  topicColorScale={topicColorScale}
                  handleToggle={toggleActiveTopic}
                  className="pt-3 pb-2"
                  sticky
                />
                <section className="mb-4">
                  <TopicsAndKeywordsChart
                    topicColorScale={topicColorScale}
                    topics={topics}
                    activeTopicId={activeTopic?.id}
                    setTopic={toggleActiveTopic}
                  />
                </section>
              </>
            )}
            {!noTopics && (
              <ExcerptListing
                activeTopic={activeTopic}
                topics={topics}
                topicSnippets={topicSnippets}
                topicColorScale={topicColorScale}
                paging={topicSnippetsPaging}
                filters={topicSnippetsFilters}
                isLoading={isLoadingTopicsSnippets}
                onLoad={handleLoadTopicSnippets}
                onClear={handleClearTopicSnippets}
              />
            )}
          </>
        )}
      </Container>
    </Layout>
  );
};

interface HeaderProps {
  collectionDetail?: CollectionDetail;
  user: User;
  activeCollection: UserNestedCollection;
  onChangeCollection: (collectionId: number) => void;
  isLoading: boolean;
}
const Header = ({
  collectionDetail,
  user,
  activeCollection,
  onChangeCollection,
  isLoading,
}: HeaderProps) => {
  const { t } = useTranslation();
  return (
    <section className="mt-5 text-center" style={{ minHeight: '6.5rem' }}>
      {collectionDetail && (
        <>
          <h1 className="mb-2">{t('topics.topic')}</h1>
          <CollectionDropdown
            user={user}
            activeCollection={activeCollection}
            onChange={onChangeCollection}
            headerTag="h2"
            headerClassname="mb-1 h3"
            disableAllCollections
          />
          {isLoading ? (
            <div className="position-relative" style={{ minHeight: '80vh' }}>
              <LoadingOverlay active bgColor="transparent" />
            </div>
          ) : (
            <p className="h5 fwnormal">
              {t('topics.topic_subheader', {
                collection: collectionDetail.title,
              })}
              {/* TODO: add back in when we have a doc on help.lvn.org LVN-2046 */}
              {/* <Link to="TODO" className="d-block underline">
                Learn more about your data.
              </Link> */}
            </p>
          )}
        </>
      )}
    </section>
  );
};

export default connect(mapStateToProps)(CollectionTopicsPage);
