import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label } from 'reactstrap';

import Button from 'src/components/core/Button/Button';
import { Option } from 'src/components/core/MultiSelect/MultiSelect';
import HighlightTagInput from 'src/components/HighlightTagInput/HighlightTagInput';
import PrivacyLabel from 'src/components/PrivacyLabel/PrivacyLabel';
import PrivacyMenu from 'src/components/PrivacyMenu/PrivacyMenu';
import Restrict from 'src/components/Restrict/Restrict';
import { User } from 'src/types/auth';
import {
  AnnotationPrivacyLevel,
  Conversation,
  Highlight,
} from 'src/types/conversation';

import styles from './HighlightEdit.module.scss';

interface Props {
  className?: string | undefined;
  highlight: Pick<
    Highlight,
    'title' | 'description' | 'tags' | 'privacy_level' | 'user_id'
  >;
  user: User;
  onSave: (changes: Partial<Highlight>) => void;
  isSaving?: boolean;
  isSaved?: boolean;
  saveError?: Error | undefined;
  onCancel: () => void;
  conversation: Conversation;
}

export default function HighlightEdit({
  highlight,
  user,
  onSave,
  onCancel,
  className,
  isSaving,
  saveError,
  conversation,
}: Props) {
  const { t } = useTranslation();
  const [draftDescription, setDraftDescription] = React.useState(
    highlight.description
  );
  const [draftTitle] = React.useState(highlight.title);
  const [draftTags, setDraftTags] = React.useState(highlight.tags);

  const [draftPrivacyLevel, setDraftPrivacyLevel] =
    React.useState<AnnotationPrivacyLevel>(highlight.privacy_level);
  const [isPrivacyChangeView, setIsPrivacyChangeView] = React.useState(false);

  const handleChangeDescription = (
    evt: React.SyntheticEvent<HTMLTextAreaElement>
  ) => setDraftDescription(evt.currentTarget.value);

  const handleChangeTag = (tags: Option[]) => {
    const newValues = tags.map((tag) => tag.value);
    setDraftTags(newValues);
  };

  const handleChangePrivacy = (privacy: AnnotationPrivacyLevel) => {
    setDraftPrivacyLevel(privacy);
    setIsPrivacyChangeView(false);
  };

  const handleSave = () => {
    onSave({
      title: draftTitle,
      description: draftDescription,
      tags: draftTags,
      privacy_level: draftPrivacyLevel,
    });
  };

  return (
    <div className={className}>
      <div className="d-flex justify-content-between px-2 pt-2">
        <h5 className="mb-2">{t('conversation.create_highlight_title')}</h5>
        {isPrivacyChangeView && (
          <Button
            color="link"
            size="sm"
            onClick={() => setIsPrivacyChangeView(false)}
          >
            {t('common.cancel')}
          </Button>
        )}
      </div>
      {isPrivacyChangeView ? (
        <PrivacyMenu
          highlight={{
            user_id: highlight.user_id,
            privacy_level: draftPrivacyLevel,
            conversation: conversation,
          }}
          user={user}
          collection={conversation.collection}
          onChange={handleChangePrivacy}
        />
      ) : (
        <div className="px-2 pb-2">
          <div className="d-flex justify-content-between align-items-baseline">
            <PrivacyLabel
              className="mb-2"
              privacyLevel={draftPrivacyLevel}
              collectionName={conversation.collection.title}
              conversation={conversation}
            />
            <Button
              color="link"
              size="sm"
              onClick={() => setIsPrivacyChangeView(true)}
              className="text-nowrap ms-3"
            >
              {t('conversation.create_highlight_visibility')}
            </Button>
          </div>
          <FormGroup>
            {/* only the creator of the highlight can edit the caption */}
            <Restrict activeUserId={highlight.user_id}>
              {/* for when we're ready to have titles set by users
          <FormGroup>
            <Label for="highlightTitle">A short title</Label>
            <Input
              onChange={handleChangeTitle}
              value={draftTitle}
              className={`form-control`}
              disabled={isSaving}
            />
          </FormGroup>  */}
              <FormGroup>
                <Label for="highlightDescription">
                  {t('conversation.create_highlight_note')}
                </Label>
                <textarea
                  onChange={handleChangeDescription}
                  value={draftDescription}
                  className={`form-control ${styles.descriptionTextarea}`}
                  disabled={isSaving}
                  data-testid="highlightFormInput"
                />
              </FormGroup>
            </Restrict>
            <FormGroup>
              <Label for="highlightTags">
                {t('conversation.create_highlight_tags')}
              </Label>
              <HighlightTagInput
                values={draftTags}
                onChange={handleChangeTag}
              />
            </FormGroup>
          </FormGroup>
          {saveError && (
            <div className="text-danger mb-3">
              {t('conversation.create_highlight_save_error')}
            </div>
          )}
          <Button
            key="save" /* need the key to prevent css animation when switching to Edit again */
            color="success"
            onClick={handleSave}
            icon={isSaving ? 'loading' : undefined}
            disabled={isSaving}
            className="me-2"
            data-testid="saveHighlightButton"
          >
            {isSaving ? t('common.saving') : t('common.save')}
          </Button>
          <Button onClick={onCancel}>{t('common.cancel')}</Button>
        </div>
      )}
    </div>
  );
}
