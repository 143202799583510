import React from 'react';
import { Tooltip } from 'reactstrap';

interface UsertStatusTooltipProps {
  width: string;
  text: string;
  id: string;
  isRegistered: boolean;
  children: React.ReactNode;
}
const UsertStatusTooltip = ({
  width,
  text,
  id,
  isRegistered,
  children,
}: UsertStatusTooltipProps) => {
  const [tooltipUserStatus, setTooltipUserStatus] =
    React.useState<boolean>(false);
  return isRegistered ? (
    <>
      <div id={id} tabIndex={0} aria-label={text} data-testid={id}>
        {children}
      </div>
      <Tooltip
        placement="bottom-start"
        hideArrow={true}
        isOpen={tooltipUserStatus}
        innerClassName={'text-start'}
        style={{ width }}
        target={id}
        toggle={() => setTooltipUserStatus((self) => !self)}
        data-testid={`tooltip-${id}`}
      >
        <span className={'w-100 text-start'}>{text}</span>
      </Tooltip>
    </>
  ) : (
    <div id={id} data-testid={id}>
      {children}
    </div>
  );
};

export default UsertStatusTooltip;
