import * as React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import Button, { ButtonIconProp } from 'src/components/core/Button/Button';
import GlobalAudioContext, {
  useSeekAudio,
} from 'src/contexts/GlobalAudioContext';
import { CrossPollinatedConversation } from 'src/types/conversation';

import styles from './CrossPollinatedConversationList.module.scss';

interface Props {
  xpol: CrossPollinatedConversation;
}

const CrossPollinatedConversationListElement = ({ xpol }: Props) => {
  const { pause, isPlaying, isLoading } = React.useContext(GlobalAudioContext);
  const { isActive, seekTime, activatedPlay } = useSeekAudio({
    audioUrl: xpol.conversation_audio_url,
    startTime: xpol.audio_end_offset,
    meta: {
      id: xpol.conversation_id,
      title: xpol.conversation_title,
      audio_url: xpol.conversation_audio_url,
      host: xpol.conversation_host,
      host_id: xpol.conversation_host_id,
      location: xpol.conversation_location,
    },
  });

  let buttonIcon: ButtonIconProp;
  let buttonClickHandler;
  if (isLoading && isActive) {
    buttonIcon = 'loading';
  } else if (isPlaying && isActive) {
    buttonIcon = 'pause';
    buttonClickHandler = () => pause();
  } else {
    buttonIcon = 'play';
    buttonClickHandler = () => activatedPlay(seekTime);
  }

  return (
    <li className={cx('align-items-baseline py-2 px-3', styles.menuOption)}>
      <div>
        <span className={styles.title}>
          <Button
            icon={buttonIcon}
            onClick={buttonClickHandler}
            shape="circle"
            color="fora-purple"
            className={cx(styles.crossPollinationButton)}
          />
          <Link
            className={cx(styles.crossPollinationLink)}
            to={`/conversation/${xpol.conversation_id}/?t=${
              seekTime ? seekTime : xpol.audio_end_offset
            }`}
          >
            {xpol.conversation_title}
          </Link>
        </span>{' '}
      </div>
    </li>
  );
};

export default CrossPollinatedConversationListElement;
