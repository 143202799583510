import React from 'react';

import { Conversation, Paging } from 'src/types/conversation';
import { makePagingHandlers } from 'src/util/paging';
import ConversationCard from '../ConversationCard/ConversationCard';
import LoadingOverlay from '../core/LoadingOverlay/LoadingOverlay';
import PagingControl from '../PagingControl/PagingControl';

export interface Props {
  conversations: Conversation[];
  isLoading: boolean;
  error: Error | undefined;
  pagingInfo: Paging | undefined;
  pagingHandlers: ReturnType<typeof makePagingHandlers>;
  pageSize: number;
}

const ExploreConversations = ({
  conversations,
  isLoading,
  pagingHandlers,
  pagingInfo,
  pageSize,
}: Props) => {
  const { handleSeekPage, handleNextPage, handlePrevPage } = pagingHandlers;

  return (
    <>
      {isLoading ? (
        <div
          className="position-relative d-flex align-items-center"
          style={{ minHeight: '45.75rem' }}
        >
          <LoadingOverlay bgColor="transparent" active />
        </div>
      ) : (
        <div className="two-column-flex-container">
          {conversations.map((conversation) => (
            <ConversationCard
              key={conversation.id}
              conversation={conversation}
              mini
            />
          ))}
        </div>
      )}
      <PagingControl
        className="mb-5 text-center"
        itemsPerPage={pageSize}
        pagingInfo={pagingInfo}
        onNext={handleNextPage}
        onPrevious={handlePrevPage}
        onSeek={handleSeekPage}
      />
    </>
  );
};

export default ExploreConversations;
