import { Organization } from 'src/types/auth';
import { Conversation } from 'src/types/conversation';
import { Catalog } from 'src/types/insights';

export const organizeCatalogs = (
  catalogs: Catalog[],
  organizations: Organization[]
): [Organization, Catalog[]][] => {
  const catalogsByOrganizationId = new Map<Organization['id'], Catalog[]>();
  const organizationsById = new Map<Organization['id'], Organization>();
  const items: [Organization, Catalog[]][] = [];
  catalogs.map((catalog) => {
    if (!catalogsByOrganizationId.has(catalog.organization_id)) {
      const array: Catalog[] = [];
      catalogsByOrganizationId.set(catalog.organization_id, array);
    }
    const catalogs = catalogsByOrganizationId.get(catalog.organization_id);
    if (catalogs !== undefined) {
      catalogs.push(catalog);
    }
  });
  organizations.map((organization) =>
    organizationsById.set(organization.id, organization)
  );
  Array.from(catalogsByOrganizationId.entries()).map((entry) => {
    const [organizationId, catalogs] = entry;
    const organization = organizationsById.get(organizationId);
    if (organization !== undefined) {
      items.push([organization, catalogs]);
    }
  });
  return items;
};

export const getConversationGroupTitle = (
  conversation?: Conversation
): string => {
  return (
    conversation?.collection_title ||
    conversation?.forum_name ||
    conversation?.forum?.name ||
    conversation?.collection?.title ||
    ''
  );
};
