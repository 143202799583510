import * as React from 'react';
import { TwitterPicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import { AbstractCode } from 'src/types/insights';
import CodeChip from '../Common/CodeChip';
import { TAG_COLORS } from '../utils/color';
import CodeSelect from './CodeSelect';

interface BaseProps<CodeType> {
  code: CodeType;
  codes: CodeType[];
  onSuccess: (code: CodeType) => void;
  open: boolean;
  closeDialog: () => void;
}

const UpdateCodeDialog = <CodeType extends AbstractCode>(
  props: BaseProps<CodeType>
) => {
  const { closeDialog, code, codes, onSuccess, open } = props;
  const { t } = useTranslation();

  const [color, setColor] = React.useState(code.color);
  const [description, setDescription] = React.useState(code.description);
  const [name, setName] = React.useState(code.name);
  const [nameError, setNameError] = React.useState('');
  const [parentId, setParentId] = React.useState(code.parent_id);

  const disabledCodes: CodeType['id'][] = [];
  codes.map((currentCode) => {
    if (
      currentCode.parentage &&
      currentCode.parentage.length !== 1 // is a child code
    ) {
      disabledCodes.push(currentCode.id);
    }
  });

  React.useEffect(() => {
    // reset when opening only
    // avoids making the reset visible during close
    if (open) {
      setColor(code.color);
      setDescription(code.description || '');
      setName(code.name);
      setNameError('');
      setParentId(code.parent_id);
    }
  }, [code, open]);

  const handleSubmit = () => {
    if (nameError.length > 0) {
      return;
    }
    closeDialog();
    onSuccess({
      ...code,
      color: color,
      description: description,
      name: name,
      parent_id: parentId,
    });
  };

  const handleCodeSelectChange = (parentId: number) => {
    setParentId(parentId);
  };

  const handleColorChangeComplete = (color: any) => {
    setColor(color.hex);
  };

  const exampleCode: CodeType = { ...code, name: name, color: color };
  let dialogTitle;

  switch (code.code_type) {
    case 'thematic':
      dialogTitle = t('insights.edit_thematic_code');
      break;
    case 'structural':
      dialogTitle = t('insights.edit_structural_code');
      break;
    case 'demographic':
      dialogTitle = t('insights.edit_demographic');
      break;
    default:
      dialogTitle = t('insights.edit_code');
      break;
  }

  return (
    <Dialog
      data-testid="UpdateCodeDialog"
      disableRestoreFocus
      onClose={closeDialog}
      open={open}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          sx={{ width: 360 }}
        >
          <CodeChip code={code} />
          <ArrowForwardIcon />
          <CodeChip code={exampleCode} />
        </Box>
        <Box sx={{ mt: 4 }}>
          <TextField
            autoFocus
            error={nameError.length > 0}
            fullWidth
            helperText={nameError}
            label={t('common.name')}
            onChange={(event) => {
              if (event.target.value === '') {
                setNameError(t('insights.name_error'));
              } else if (event.target.value === 'Exclude from Portal') {
                setNameError(t('insights.reserve_name_error'));
              } else {
                setNameError('');
              }
              setName(event.target.value);
            }}
            value={name}
          />
        </Box>
        <Box sx={{ mt: 4 }}>
          <TextField
            fullWidth
            label={t('common.description')}
            multiline
            onChange={(event) => {
              setDescription(event.target.value);
            }}
            value={description}
          />
        </Box>
        <Box sx={{ mt: 4 }}>
          <TwitterPicker
            color={color}
            colors={TAG_COLORS}
            onChangeComplete={handleColorChangeComplete}
            triangle="hide"
            width="360px"
          />
        </Box>
        <Box sx={{ mt: 4 }}>
          <CodeSelect
            code={code}
            codes={codes}
            disabledCodes={disabledCodes}
            onChange={handleCodeSelectChange}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>{t('common.cancel')}</Button>
        <Button onClick={handleSubmit}>{t('common.confirm')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateCodeDialog;
