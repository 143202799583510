import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import { Label } from 'reactstrap';
import cx from 'classnames';
import { Field, Formik, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';

import Button from 'src/components/core/Button/Button';
import LoadingSpinner from 'src/components/core/LoadingSpinner/LoadingSpinner';
import FormError from 'src/components/FormError/FormError';
import authSelectors from 'src/redux/auth/auth-selectors';
import { EditTransaction, editUser } from 'src/redux/auth/auth-slice';
import { StoreState } from 'src/redux/store';
import { User } from 'src/types/auth';

import styles from './LegalPage.module.scss';

interface GuardianForm {
  name: string;
}

interface FormProps extends FormikProps<GuardianForm> {
  showGeneralError: boolean;
  showSuccess: boolean;
  minorUser: User;
}
export const GuardianFormContent = ({
  handleChange,
  setSubmitting,
  values,
  errors,
  showGeneralError,
  isSubmitting,
  showSuccess,
  minorUser,
}: FormProps) => {
  React.useEffect(() => {
    // This ensures that when the gaurdian transaction has finished successfully,
    // (resulting in showSuccess as true), the submitting flag is set to false.
    if (isSubmitting && showSuccess) setSubmitting(false);
  }, [isSubmitting, showSuccess, setSubmitting]);

  const { t } = useTranslation();
  if (showSuccess) {
    return (
      <div data-testid="success-msg" className="text-center">
        <p>
          <Trans
            i18nKey="legal.guardian_form_success_text"
            values={{
              name: minorUser.first_name,
            }}
            /*eslint-disable */
            components={{
              1: (
                <a
                  href="https://lvn.org"
                  rel="noopener noreferrer"
                  target="_blank"
                />
              ),
            }}
            /*eslint-disable */
          />
        </p>
        <p>
          <Trans
            i18nKey="legal.guardian_form_success_subtext"
            /*eslint-disable */
            components={{
              1: (
                <a
                  href="https://lvn.org/about"
                  rel="noopener noreferrer"
                  target="_blank"
                />
              ),
              2: <a href="mailto:help@lvn.org" />,
            }}
            /*eslint-disable */
          />
        </p>
      </div>
    );
  }

  return (
    <div className="d-flex align-items-center flex-column">
      <div
        className={cx(
          'w-100',
          'd-flex',
          'justify-content-center',
          'mb-2',
          styles.layout,
          styles.guardian
        )}
      >
        <Label for="name" className="flex-grow-1">
          {t('legal.guardian_form_input')}
          <Field
            name="name"
            id="name"
            type="text"
            component="input"
            onChange={handleChange}
            value={values.name}
            aria-describedby="name-helper error-msg-name"
            aria-invalid={Boolean(errors && errors['name'])}
            aria-required
            data-testid="guardian-field"
            className="form-control"
          />
        </Label>
        <Button
          color="primary"
          type="submit"
          disabled={isSubmitting}
          data-testid="guardian-submit"
        >
          {t('legal.guardian_form_button')}
        </Button>
      </div>
      <LoadingSpinner active={isSubmitting} />
      {errors && errors['name'] && (
        <FormError name="name">{errors['name']}</FormError>
      )}
      {showGeneralError && (
        <FormError name="name">
          <Trans
            i18nKey="legal.guardian_form_error"
            /*eslint-disable */
            components={{
              1: <a href="mailto:help@lvn.org" />,
            }}
            /*eslint-disable */
          />
        </FormError>
      )}
    </div>
  );
};

interface Props {
  minorUser: User;
  uidb64: string;
  token: string;
}
interface StateProps {
  guardianAgreeTransaction?: EditTransaction;
}

const mapStateToProps = (state: StoreState): StateProps => ({
  guardianAgreeTransaction: authSelectors.getEditUserTransaction(
    state,
    'guardian_agree'
  ),
});

const GuardianAgreeForm = ({
  minorUser,
  uidb64,
  token,
  guardianAgreeTransaction,
  dispatch,
}: Props & StateProps & DispatchProp) => {
  const { t } = useTranslation();
  const [serverErrorOccurred, setServerErrorOccurred] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);

  React.useEffect(() => {
    if (guardianAgreeTransaction?.error) {
      setShowSuccess(false);
      setServerErrorOccurred(true);
    }
    if (guardianAgreeTransaction?.isSaved) {
      setShowSuccess(true);
      setServerErrorOccurred(false);
    }
  }, [guardianAgreeTransaction, setShowSuccess, setServerErrorOccurred]);

  const initialValues: GuardianForm = { name: '' };
  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required(t('legal.guardian_validation_name')),
  });
  const handleSubmitForm = (
    values: GuardianForm,
    formikBag: FormikHelpers<GuardianForm>
  ) => {
    const payload = {
      guardian_agree: true,
      guardian_name: values.name,
      uidb64,
      token,
    };
    formikBag.setSubmitting(true);
    dispatch(
      editUser({
        userId: minorUser.id,
        changes: payload,
        editType: 'guardian_agree',
      })
    );
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmitForm}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          <div data-testid="guardian-agree-form">
            {showSuccess ? (
              <h3 className="fwbold text-center pt-2">
                {t('legal.guardian_success')}
              </h3>
            ) : (
              <p className="fwbold text-center pt-2">
                {t('legal.guardian_continue', {
                  name: minorUser.first_name,
                  email: minorUser.email,
                })}
              </p>
            )}
            <GuardianFormContent
              {...props}
              minorUser={minorUser}
              showGeneralError={serverErrorOccurred}
              showSuccess={showSuccess}
            />
          </div>
        </form>
      )}
    </Formik>
  );
};

export default connect(mapStateToProps)(GuardianAgreeForm);
