import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import nanoid from 'nanoid';

import HighlightEdit from 'src/components/HighlightEdit/HighlightEdit';
import authSelectors from 'src/redux/auth/auth-selectors';
import conversationSelectors from 'src/redux/conversation/conversation-selectors';
import { saveNewAnnotation } from 'src/redux/conversation/conversation-slice';
import { StoreState } from 'src/redux/store';
import { User } from 'src/types/auth';
import {
  AnnotationPrivacyLevel,
  Conversation,
  Highlight,
} from 'src/types/conversation';

interface BaseProps {
  startTime: number;
  endTime: number;
  conversationId: number;
  conversation: Conversation;
  onFinish: () => void;
  onCancel: () => void;
}

interface StateProps {
  isSaving?: boolean;
  isSaved?: boolean;
  saveError?: Error | undefined;
  user: User;
}

type Props = BaseProps & StateProps & DispatchProp;

/** Map state from redux to the components props */
const mapStateToProps = (state: StoreState): StateProps => ({
  isSaving: conversationSelectors.isNewAnnotationSaving(state),
  isSaved: conversationSelectors.isNewAnnotationSaved(state),
  saveError: conversationSelectors.getNewAnnotationError(state),
  user: authSelectors.getUser(state) as User,
});

export const CreateHighlightForm = ({
  startTime,
  endTime,
  conversationId,
  onFinish,
  onCancel,
  user,
  dispatch,
  conversation,
}: Props) => {
  const handleSave = (changes: Partial<Highlight>) => {
    const newHighlight = { ...highlight, ...changes };
    dispatch(saveNewAnnotation(newHighlight));
    onFinish();
  };

  const handleCancel = () => {
    onCancel();
  };

  const highlight = React.useMemo(
    () => ({
      audio_end_offset: endTime,
      audio_start_offset: startTime,
      creation_tag: nanoid(),
      description: '',
      title: '',
      tags: [],
      user_id: user.id,
      privacy_level: 'conversation' as AnnotationPrivacyLevel,
      conversation_id: conversationId,
    }),
    [endTime, startTime, user.id, conversationId]
  );

  return (
    <HighlightEdit
      highlight={highlight}
      user={user}
      onSave={handleSave}
      onCancel={handleCancel}
      conversation={conversation}
    />
  );
};

export default connect(mapStateToProps)(CreateHighlightForm);
