import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Container, Form } from 'reactstrap';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import Layout from 'src/components/Layout/Layout';
import GuardianInfoStep from 'src/components/RegistrationRoutes/GuardianInfoStep/GuardianInfoStep';
import authSelectors from 'src/redux/auth/auth-selectors';
import { EditTransaction, editUser } from 'src/redux/auth/auth-slice';
import { StoreState } from 'src/redux/store';
import { User } from 'src/types/auth';
import { ServerError } from 'src/types/core';

import styles from '../RegistrationRoutes.module.scss';

interface FormProps {
  isSaved: boolean;
  errorFromServer: ServerError | undefined;
  isSaving: boolean;
}

const GuardianInfoForm = ({
  errorFromServer,
  isSaved,
  isSaving,
  ...formProps
}: FormikProps<any> & FormProps) => {
  const { t } = useTranslation();
  return (
    <Layout title={t('register.title')} className={styles.layout}>
      <Container className={styles.container}>
        <Form
          onSubmit={formProps.handleSubmit}
          className={styles.form}
          data-testid="guardian-info-form"
          noValidate
        >
          <GuardianInfoStep
            {...formProps}
            errorFromServer={errorFromServer}
            isSaved={isSaved}
            isSaving={isSaving}
            autoFocus
          />
        </Form>
      </Container>
    </Layout>
  );
};

interface StateProps {
  user: User;
  guardianPermissionTransaction?: EditTransaction;
}
/** Map state from redux to the components props */
const mapStateToProps = (state: StoreState): StateProps => ({
  user: authSelectors.getUser(state) as User,
  guardianPermissionTransaction: authSelectors.getEditUserTransaction(
    state,
    'guardian_permission'
  ),
});

type Props = RouteComponentProps & DispatchProp & StateProps;
const GuardianRoute = ({
  user,
  guardianPermissionTransaction,
  dispatch,
}: Props) => {
  const [isSaved, setIsSaved] = React.useState<boolean>(false);
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [error, setError] = React.useState<ServerError>();
  const { t } = useTranslation();

  React.useEffect(() => {
    if (guardianPermissionTransaction) {
      if (guardianPermissionTransaction.isSaved) {
        setIsSaved(true);
        setIsSaving(false);
      } else if (guardianPermissionTransaction.error) {
        setError(guardianPermissionTransaction?.error);
        setIsSaving(false);
      } else {
        setIsSaving(true);
      }
    }
  }, [guardianPermissionTransaction, setIsSaving, setIsSaved, setError]);

  const initialValues = {
    guardianName: '',
    guardianEmail: '',
  };

  const validationSchema = Yup.object().shape({
    guardianName: Yup.string()
      .trim()
      .required(t('guardian_permission.validation_name')),
    guardianEmail: Yup.string()
      .matches(/@/, t('guardian_permission.validation_email_match'))
      .email(t('guardian_permission.validation_email_invalid'))
      .required(t('guardian_permission.validation_email_required')),
  });

  const handleSubmit = (values: any) => {
    dispatch(
      editUser({
        userId: user.id,
        changes: {
          guardian_name: values.guardianName,
          guardian_email: values.guardianEmail,
          send_guardian_email: true,
        },
        editType: 'guardian_permission',
      })
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {(props) => (
        <GuardianInfoForm
          errorFromServer={error}
          isSaved={isSaved}
          isSaving={isSaving}
          {...props}
        />
      )}
    </Formik>
  );
};

export default connect(mapStateToProps)(GuardianRoute);
