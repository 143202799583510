import * as React from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import OrganizationFavicon from 'src/components/OrganizationFavicon/OrganizationFavicon';
import { Community } from 'src/types/forum';

import styles from './CommunityDropdown.module.scss';

const CommunityItems = ({
  communities,
  onClick,
}: {
  communities: Community[];
  onClick: (e: any) => void;
}) => {
  return (
    <>
      {communities.map((org) => {
        const { id, name } = org;

        const itemProps = {
          key: `dropdown-item-${id}`,
          'data-community-id': id,
          'data-testid': `community-dropdown-item-${id}`,
        };

        return (
          <div key={id}>
            <DropdownItem
              {...itemProps}
              title={name}
              onClick={() => onClick(id)}
              className="d-flex align-items-center"
            >
              <span className="fwmedium">{name}</span>
            </DropdownItem>
          </div>
        );
      })}
    </>
  );
};

interface Props {
  communities: Community[];
  onChange: (communityId: number) => void;
  activeCommunity: Community | undefined;
  headerTag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  headerClassname?: string;
  className?: string;
}

const CommunityDropdown = ({
  communities,
  onChange,
  activeCommunity,
  headerTag = 'h1',
  className,
  headerClassname,
}: Props) => {
  const [dropdownIsOpen, setDropdownIsOpen] = React.useState(false);
  const handleToggleDropdown = () => setDropdownIsOpen(!dropdownIsOpen);

  const handleCommunityClick = React.useCallback(
    (oid: number) => {
      onChange(oid);
    },
    [onChange]
  );

  // allow for dynamic headers
  const Heading = headerTag as keyof JSX.IntrinsicElements;

  if (communities.length === 0 || !activeCommunity) {
    return null;
  }

  if (communities.length === 1) {
    return (
      <div className={cx('d-flex align-items-center', className)}>
        <OrganizationFavicon
          organization={activeCommunity}
          // decorative, to be ignored by screen readers
          alt=""
          className="me-2"
        />
        <Heading
          data-testid="organization-label"
          className={cx('mb-0', headerClassname)}
        >
          {activeCommunity.name}
        </Heading>
      </div>
    );
  }

  return (
    <Dropdown
      isOpen={dropdownIsOpen}
      toggle={handleToggleDropdown}
      data-testid="community-dropdown"
      className={cx(styles['community-dropdown-container'], className)}
      direction="down"
    >
      <DropdownToggle
        color="''" /* disable bootstrap coloring */
        className={styles.dropdownToggle}
        data-testid="community-dropdown-toggle"
      >
        <div className="d-flex align-items-center">
          <OrganizationFavicon
            organization={activeCommunity}
            // decorative, to be ignored by screen readers
            alt=""
            className="me-2"
          />
          <Heading className={cx('mb-0', headerClassname)}>
            <span className="me-2">{activeCommunity.name}</span>
            <FontAwesomeIcon icon="caret-down" size="xs" />
          </Heading>
        </div>
      </DropdownToggle>
      <DropdownMenu>
        <CommunityItems
          communities={communities}
          onClick={handleCommunityClick}
        />
      </DropdownMenu>
    </Dropdown>
  );
};

export default CommunityDropdown;
