import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import Panel from 'src/components/Panel/Panel';
import { Collection } from 'src/types/collection';
import CollectionManagementRow from '../CollectionManagementRow/CollectionManagementRow';

import styles from './CollectionManagementTable.module.scss';
interface Props {
  collections: Collection[] | undefined;
  isLoading: boolean;
  onDeleteCollection: (collectionId: Collection['id']) => void;
}

const CollectionManagementTable = ({
  collections,
  isLoading,
  onDeleteCollection,
}: Props) => {
  const { t } = useTranslation();
  const showEmptyState =
    !isLoading &&
    ((collections && collections.length === 0) || collections === undefined);

  return (
    <Panel
      className={cx(styles.panel, {
        [styles.empty]: showEmptyState,
      })}
      data-testid="collection-mgmt-table"
    >
      {showEmptyState ? (
        <p className="fwbold  text-gray-700 mb-0">
          {t('admin.collection_empty')}
          <span aria-hidden>🚀</span>
        </p>
      ) : (
        <>
          <div
            className={cx(
              'd-flex row w-full border-bottom',
              styles.tableHeader
            )}
          >
            <div className="col-5 m-auto p-0 ps-4">
              {t('admin.collection_name')}
            </div>
            <div className="col-2 m-auto p-0">{t('conversations.header')}</div>
            <div className="col-2 m-auto p-0">
              {t('admin.collection_privacy')}
            </div>
            <div className="col-2 m-auto p-0">
              {t('admin.collection_share')}
            </div>
            <div className="col-1 m-auto p-0"></div>
          </div>
          {collections &&
            collections.map((collection) => (
              <CollectionManagementRow
                collection={collection}
                onDeleteCollection={onDeleteCollection}
                key={collection.id}
              />
            ))}
        </>
      )}
    </Panel>
  );
};

export default CollectionManagementTable;
