import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import { FormGroup, Input, Label } from 'reactstrap';

import Button from 'src/components/core/Button/Button';
import Transcript from 'src/components/Transcript/Transcript';
import authSelectors from 'src/redux/auth/auth-selectors';
import conversationSelectors from 'src/redux/conversation/conversation-selectors';
import { saveNewAnnotation } from 'src/redux/conversation/conversation-slice';
import { StoreState } from 'src/redux/store';
import { User } from 'src/types/auth';
import {
  Annotation,
  Conversation,
  TranscriptSnippet,
} from 'src/types/conversation';
import { snippetsToSpeakers, snippetsToText } from 'src/util/snippets';

import styles from './FixTranscriptForm.module.scss';

interface BaseProps {
  startTime: number;
  endTime: number;
  conversationId: number;
  onFinish: () => void;
  onCancel: () => void;
  className?: string | undefined;
  creationTag: string;
  snippets: TranscriptSnippet[];
  conversation: Conversation;
  onPlay?: (seekTime?: number, endTime?: number) => void;
  seekTime?: number | undefined;
}

interface StateProps {
  isSaving?: boolean;
  isSaved?: boolean;
  saveError?: Error | undefined;
  user: User;
}

type Props = BaseProps & StateProps & DispatchProp;

/** Map state from redux to the components props */
const mapStateToProps = (state: StoreState, props: BaseProps): StateProps => ({
  isSaving: conversationSelectors.isNewAnnotationSaving(state),
  isSaved:
    conversationSelectors.isNewAnnotationSaved(state) &&
    conversationSelectors.getNewAnnotationCreationTag(state) ===
      props.creationTag,
  saveError: conversationSelectors.getNewAnnotationError(state),
  user: authSelectors.getUser(state) as User,
});

/**
 * @Deprecated until there's a mechanism in place for users w/o edit access to request edit access from their org admin
 */
export const FixTranscriptForm = ({
  startTime,
  endTime,
  snippets,
  conversationId,
  onFinish,
  onCancel,
  user,
  dispatch,
  className,
  isSaving,
  isSaved,
  creationTag,
  onPlay,
  seekTime,
  conversation,
}: Props) => {
  const { t } = useTranslation();
  const originalText: string = React.useMemo(
    () => snippetsToText(snippets, startTime, endTime).join('\n\n'),
    [snippets, startTime, endTime]
  );
  const originalSpeaker = React.useMemo(
    () => snippetsToSpeakers(snippets).join(', '),
    [snippets]
  );

  const [correctionType, setCorrectionType] = React.useState<
    'transcript_correction_words' | 'transcript_correction_speaker'
  >('transcript_correction_words');
  const [draftFixedText, setDraftFixedText] = React.useState(originalText);
  const [draftFixedSpeaker, setDraftFixedSpeaker] =
    React.useState(originalSpeaker);

  const handlePlay = React.useCallback(
    (seekTime?: number) => {
      if (onPlay) {
        onPlay(seekTime, endTime);
      }
    },
    [onPlay, endTime]
  );

  const handleChangeCorrectionType = (
    evt: React.SyntheticEvent<HTMLInputElement>
  ) => {
    setCorrectionType(evt.currentTarget.value as any);
  };

  const handleSave = () => {
    const newAnnotation: Partial<Annotation> = {
      ...annotation,
      annotation_type: correctionType,
    };
    if (correctionType === 'transcript_correction_words') {
      newAnnotation.description = draftFixedText;
      newAnnotation.details = { original_text: originalText };
    } else {
      // transcript_correction_speaker
      newAnnotation.description = draftFixedSpeaker;
      newAnnotation.details = { original_text: originalSpeaker };
    }

    dispatch(saveNewAnnotation(newAnnotation));
  };

  const handleChangeFixedText = (
    evt: React.SyntheticEvent<HTMLTextAreaElement>
  ) => setDraftFixedText(evt.currentTarget.value);

  const handleChangeFixedSpeaker = (
    evt: React.SyntheticEvent<HTMLInputElement>
  ) => setDraftFixedSpeaker(evt.currentTarget.value);

  const annotation = React.useMemo(
    () =>
      ({
        audio_end_offset: endTime,
        audio_start_offset: startTime,
        creation_tag: creationTag,
        description: originalText,
        annotation_type: 'transcript_correction_words',
        user_id: user.id,
        privacy_level: 'conversation',
        conversation_id: conversationId,
      } as Partial<Annotation>),
    [endTime, startTime, creationTag, originalText, user.id, conversationId]
  );

  if (isSaved) {
    return (
      <div className={className}>
        <div className={styles.thankYouBox}>
          <div className={`${styles.thankYouIcon} animated tada`}>
            <span role="img" aria-label="Party Hat">
              🎉
            </span>
          </div>
          <div className={styles.thankYouMessage}>
            {t('conversation.fix_transcript_message')}
          </div>
          <div className={styles.thankYouDisclaimer}>
            {t('conversation.fix_transcript_notice')}
          </div>
          <Button
            onClick={onFinish}
            color="primary"
            shape="rounded"
            className="mt-4"
            data-testid="fix_transcript_popover_button"
          >
            {t('conversation.fix_transcript_button_2')}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={className}>
      <FormGroup>
        <FormGroup>
          <Label>{t('conversation.fix_transcript_question')}</Label>
          <div>
            <FormGroup check inline>
              <Label check>
                <Input
                  type="radio"
                  name="correctionType"
                  value="transcript_correction_words"
                  data-testid="transcript_correction_words"
                  checked={correctionType === 'transcript_correction_words'}
                  onChange={handleChangeCorrectionType}
                />{' '}
                {t('conversation.fix_transcript_text')}
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Label check>
                <Input
                  type="radio"
                  name="correctionType"
                  value="transcript_correction_speaker"
                  data-testid="transcript_correction_speaker"
                  checked={correctionType === 'transcript_correction_speaker'}
                  onChange={handleChangeCorrectionType}
                />{' '}
                {t('conversation.fix_transcript_name')}
              </Label>
            </FormGroup>
          </div>
        </FormGroup>
        {correctionType === 'transcript_correction_words' ? (
          <>
            <FormGroup>
              <Label>{t('conversation.fix_transcript_original')}</Label>
              <Transcript
                onPlay={handlePlay}
                seekTime={seekTime}
                snippets={snippets}
                filterWordsStart={startTime}
                filterWordsEnd={endTime}
                conversation={conversation}
                className={styles.originalText}
              />
            </FormGroup>
            <FormGroup>
              <Label for="fixedText">
                {t('conversation.fix_transcript_corrected')}
              </Label>
              <textarea
                onChange={handleChangeFixedText}
                value={draftFixedText}
                className={`form-control ${styles.descriptionTextarea}`}
                data-testid="transcript_correction_input"
              />
            </FormGroup>
          </>
        ) : (
          <>
            <FormGroup>
              <Label>{t('conversation.fix_transcript_original_speaker')}</Label>
              <div className={styles.originalSpeaker}>{originalSpeaker}</div>
            </FormGroup>
            <FormGroup>
              <Label for="fixedSpeaker">
                {t('conversation.fix_transcript_speaker')}
              </Label>
              <Input
                onChange={handleChangeFixedSpeaker}
                value={draftFixedSpeaker}
                data-testid="transcript_correction_input"
              />
            </FormGroup>
          </>
        )}
      </FormGroup>
      <Button
        key="save" /* need the key to prevent css animation when switching to Edit again */
        color="success"
        onClick={handleSave}
        icon={isSaving ? 'loading' : undefined}
        disabled={isSaving}
        className="me-2"
        data-testid="transcript_correction_save"
      >
        {isSaving
          ? t('common.saving')
          : correctionType === 'transcript_correction_words'
          ? t('conversation.fix_transcript_text_button')
          : t('conversation.fix_transcript_speaker_button')}
      </Button>
      <Button onClick={onCancel}>{t('common.cancel')}</Button>
    </div>
  );
};

export default connect(mapStateToProps)(FixTranscriptForm);
