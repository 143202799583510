import * as React from 'react';
import { SVGOverlay, ViewportProps } from 'react-map-gl';
import Geocoder from 'react-map-gl-geocoder';

import ApproximateLocationMarker from 'src/components/Map/ApproximateLocationMarker/ApproximateLocationMarker';
import Map from 'src/components/Map/Map';
import { mapbox } from 'src/config';
import { DEFAULT_LONG_LAT } from 'src/constants';
import { SVGRedrawOptions } from 'src/types/maps';

import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';
import styles from './GeocodingMap.module.scss';

interface Props {
  width: number;
  height: number;
  onResult: (result: GeocoderResult) => void;
  onClear: () => void;
  // form returns string[], collection lnglat returns [number, number]
  initialLngLat?: string[] | [number | null, number | null];
  showLocationMarker?: boolean;
}

export type GeocoderResult = {
  id: string;
  type: string;
  place_type: string[];
  relevance: number;
  properties: any;
  'text_en-US': string;
  'place_name_en-US': string;
  text: string;
  place_name: string;
  bbox: number[];
  center: [number, number];
  geometry: any;
  context: any[];
};

const GeocodingMap = ({
  height,
  width,
  initialLngLat = DEFAULT_LONG_LAT,
  onResult,
  onClear,
  showLocationMarker = false,
}: Props) => {
  // lnglat could also be [null, null] so do an extra check and fill in default if necessary
  const [viewport, setViewport] = React.useState({
    longitude: initialLngLat[0] || DEFAULT_LONG_LAT[0],
    latitude: initialLngLat[1] || DEFAULT_LONG_LAT[1],
    zoom: 14,
  });
  const refContainer = React.useRef(null);

  const handleViewportChange = (newViewport: ViewportProps) => {
    setViewport({ ...viewport, ...newViewport });
  };

  const handleRedraw = (opts: SVGRedrawOptions) => {
    if (!showLocationMarker) {
      return;
    }
    const lngLat: [number, number] = [+viewport.longitude, +viewport.latitude];
    const { project } = opts;
    return (
      <ApproximateLocationMarker
        center={lngLat}
        project={project}
        milesRadius={0.2}
        className={styles.conversationLocationRegion}
      />
    );
  };

  return (
    <Map
      height={height}
      width={width}
      viewport={viewport as ViewportProps}
      mapRef={refContainer}
      onViewportChange={handleViewportChange}
      dragPan={false}
      scrollZoom={false}
      doubleClickZoom={false}
      maxZoom={14}
      className="rounded-8 shadow"
    >
      {() => (
        <>
          <Geocoder
            mapRef={refContainer}
            mapboxApiAccessToken={mapbox.accessToken}
            onViewportChange={handleViewportChange}
            position="top-left"
            enableEventLogging={false}
            onResult={(res: any) => onResult(res.result)}
            onClear={onClear}
          />
          <SVGOverlay redraw={handleRedraw} />
        </>
      )}
    </Map>
  );
};

export default GeocodingMap;
