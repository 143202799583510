import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import {
  Activity,
  ConversationActivity,
  HighlightActivity,
} from 'src/types/conversation';
import dateFormatter from 'src/util/date';

import styles from './ActivityItem.module.scss';

interface HighlightActivityProps {
  highlightActivity: HighlightActivity;
}

interface ConversationActivityProps {
  conversationActivity: ConversationActivity;
}

interface ActivityProps {
  activity: Activity;
}

export const ActivityItem = ({ activity }: ActivityProps) => {
  let description = null;
  if (activity.type === 'conversation') {
    description = <ConversationActivityItem conversationActivity={activity} />;
  } else if (activity.type === 'highlight') {
    description = <HighlightActivityItem highlightActivity={activity} />;
  }
  return (
    <div
      className={cx(styles.activityItem, 'd-flex')}
      data-testid={`recent-activity-block-${activity.id}`}
    >
      <div
        className={cx('pe-2', styles.bulletPoint, {
          [styles.highlightItem]: activity.type === 'highlight',
          [styles.conversationItem]: activity.type === 'conversation',
        })}
      >
        <FontAwesomeIcon icon="circle" />
      </div>
      <span className={styles.activityContents}>
        {description}
        <div
          className={cx('text-muted pt-1', styles.relativeDate)}
          data-testid={`recent-activity-date-${activity.id}`}
        >
          {dateFormatter.relativeAbsDateFormat(activity.created_at)}
        </div>
      </span>
    </div>
  );
};

function truncateWords(words: string, maxNumWords: number) {
  const tokens = words.split(/\s/);
  if (tokens.length <= maxNumWords) {
    return words;
  }

  return `${tokens.slice(0, maxNumWords).join(' ')} ...`;
}

const maxPreviewWords = 10;
export const HighlightActivityItem = ({
  highlightActivity,
}: HighlightActivityProps) => {
  const { t } = useTranslation();
  return (
    <div title={highlightActivity.preview_text}>
      <span className="fwbold">{highlightActivity.user_name}</span>
      {` ${t('common.created')} `}
      <Link to={`/highlight/${highlightActivity.id}`}>
        {t('collection.activity_highlight', {
          name: highlightActivity.preview_text
            ? ` "${truncateWords(
                highlightActivity.preview_text,
                maxPreviewWords
              )}"`
            : null,
        })}
      </Link>
    </div>
  );
};

export const ConversationActivityItem = ({
  conversationActivity,
}: ConversationActivityProps) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <span className="fwbold">{conversationActivity.host.name}</span>
      {` ${t('common.hosted')} `}
      {
        <Link to={`/conversation/${conversationActivity.id}`}>
          {t('collection.activity_conversation', {
            location: conversationActivity.location.name,
          })}
        </Link>
      }
    </React.Fragment>
  );
};
