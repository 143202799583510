import * as React from 'react';
import { useTranslation } from 'react-i18next';
import xor from 'lodash.xor';
import {
  ArrayParam,
  QueryParamConfig,
  SetQuery,
  useQueryParams,
} from 'use-query-params';

import {
  ConversationsFilterOptions,
  FilterOption,
  HighlightsFilterOptions,
} from 'src/types/core';
import FilterChecklist from '../FilterChecklist/FilterChecklist';
import SideFilter from '../SideFilter/SideFilter';

export const getActiveCollections = (
  collectionIds: string[] | undefined,
  filterOptions:
    | ConversationsFilterOptions
    | HighlightsFilterOptions
    | undefined
) => {
  return (
    collectionIds &&
    filterOptions &&
    filterOptions.collections &&
    collectionIds.map((id) =>
      filterOptions.collections.find((col) => String(col.id) === id)
    )
  );
};

export const handleCollectionChange = (
  option: FilterOption,
  collectionIds: string[] | undefined,
  setQuery: SetQuery<{
    c: QueryParamConfig<string[] | undefined>;
    page: QueryParamConfig<number | undefined>;
  }>
) => {
  const newCollectionIds = xor(collectionIds, [String(option.id)]);
  setQuery({
    c: newCollectionIds,
    page: 1,
  });
};

interface Props {
  filterOptions:
    | ConversationsFilterOptions
    | HighlightsFilterOptions
    | undefined;
}

const CollectionFilter = ({ filterOptions }: Props) => {
  const [query, setQuery] = useQueryParams({
    c: ArrayParam,
  });
  const { c: collectionIds } = query;
  const { t } = useTranslation();
  return (
    <SideFilter
      title={t('collection.header')}
      open={Boolean(collectionIds)}
      disabled={!(filterOptions && filterOptions.collections.length)}
    >
      {filterOptions && filterOptions.collections && (
        <FilterChecklist
          options={filterOptions.collections}
          onChange={(option) => {
            handleCollectionChange(option, collectionIds, setQuery);
          }}
          activeFilterOptions={getActiveCollections(
            collectionIds,
            filterOptions
          )}
          title={t('collection.header')}
          fade
        />
      )}
    </SideFilter>
  );
};

export default CollectionFilter;
