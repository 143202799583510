import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@reach/tabs';
import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
} from 'use-query-params';

import { HighlightsFilters } from 'src/api/api';
import CollectionFilter from 'src/components/card-filters/CollectionFilter';
import PrivacyFilter from 'src/components/card-filters/PrivacyFilter';
import TagFilter from 'src/components/card-filters/TagFilter';
import ClosableTab from 'src/components/ClosableTab/ClosableTab';
import LoadingOverlay from 'src/components/core/LoadingOverlay/LoadingOverlay';
import Layout from 'src/components/Layout/Layout';
import authSelectors from 'src/redux/auth/auth-selectors';
import highlightSelectors from 'src/redux/highlight/highlight-selectors';
import {
  loadAllHighlights,
  loadSearchHighlights,
  loadStarredHighlights,
  loadUserHighlights,
} from 'src/redux/highlight/highlight-slice';
import { StoreState } from 'src/redux/store';
import { User } from 'src/types/auth';
import { Highlight, Paging } from 'src/types/conversation';
import { HighlightsFilterOptions } from 'src/types/core';
import { getHighlightPermission, hasPermission } from 'src/util/user';
import SearchInput from '../SearchInput/SearchInput';
import HighlightsTabPane from './HighlightsTabPane/HighlightsTabPane';

import styles from './HighlightsRoute.module.scss';

type TabName = 'my-highlights' | 'all-highlights' | 'my-favorites';

interface StateProps {
  isAuthenticated: boolean;
  user: User;
  userHighlights: Highlight[];
  userHighlightsPagingInfo: Paging | undefined;
  userHighlightsFilterOptions: HighlightsFilterOptions | undefined;
  isLoadingUserHighlights: boolean;
  allHighlights: Highlight[];
  allHighlightsPagingInfo: Paging | undefined;
  allHighlightsFilterOptions: HighlightsFilterOptions | undefined;
  isLoadingAllHighlights: boolean;

  starredHighlights: Highlight[];
  starredHighlightsPagingInfo: Paging | undefined;
  starredHighlightsFilterOptions: HighlightsFilterOptions | undefined;
  isLoadingStarredHighlights: boolean;
  searchHighlights: Highlight[];
  searchHighlightsPagingInfo: Paging | undefined;
  searchHighlightsFilterOptions: HighlightsFilterOptions | undefined;
  isLoadingSearchHighlights: boolean;
}

type Props = RouteComponentProps & StateProps & DispatchProp;

/** Map state from redux to the components props */
const mapStateToProps = (state: StoreState): StateProps => ({
  isAuthenticated: authSelectors.isAuthenticated(state),
  user: authSelectors.getUser(state),

  userHighlights: highlightSelectors.getUserHighlights(state),
  isLoadingUserHighlights: highlightSelectors.isLoadingUserHighlights(state),
  userHighlightsPagingInfo:
    highlightSelectors.getUserHighlightsPagingInfo(state),
  userHighlightsFilterOptions:
    highlightSelectors.getUserHighlightsFilterOptions(state),

  allHighlights: highlightSelectors.getAllHighlights(state),
  isLoadingAllHighlights: highlightSelectors.isLoadingAllHighlights(state),
  allHighlightsPagingInfo: highlightSelectors.getAllHighlightsPagingInfo(state),
  allHighlightsFilterOptions:
    highlightSelectors.getAllHighlightsFilterOptions(state),

  starredHighlights: highlightSelectors.getStarredHighlights(state),
  isLoadingStarredHighlights:
    highlightSelectors.isLoadingStarredHighlights(state),
  starredHighlightsPagingInfo:
    highlightSelectors.getStarredHighlightsPagingInfo(state),
  starredHighlightsFilterOptions:
    highlightSelectors.getStarredHighlightsFilterOptions(state),

  searchHighlights: highlightSelectors.getSearchHighlights(state),
  isLoadingSearchHighlights:
    highlightSelectors.isLoadingSearchHighlights(state),
  searchHighlightsPagingInfo:
    highlightSelectors.getSearchHighlightsPagingInfo(state),
  searchHighlightsFilterOptions:
    highlightSelectors.getSearchHighlightsFilterOptions(state),
});

const PAGE_SIZE = 10;

/**
 * Page for when user login information is loading
 */
export const HighlightsRoute = ({
  dispatch,
  userHighlights,
  userHighlightsPagingInfo,
  userHighlightsFilterOptions,
  isLoadingUserHighlights,
  allHighlights,
  allHighlightsPagingInfo,
  allHighlightsFilterOptions,
  isLoadingAllHighlights,
  starredHighlights,
  starredHighlightsPagingInfo,
  starredHighlightsFilterOptions,
  isLoadingStarredHighlights,
  searchHighlights,
  searchHighlightsPagingInfo,
  searchHighlightsFilterOptions,
  isLoadingSearchHighlights,
  user,
}: Props) => {
  const { t } = useTranslation();
  // get search query parameters
  const [query, setQuery] = useQueryParams({
    tab: StringParam,
    page: NumberParam,
    c: ArrayParam,
    q: StringParam,
    tags: ArrayParam,
    priv: ArrayParam,
  });
  const {
    c: collectionIds,
    page: currentPage = 1,
    tab: activeTab = 'all-highlights',
    q: searchQuery,
    priv: privacyLevels,
    tags,
  } = query;

  // get user highlight permissions to determine which tabs to display
  const canCreateHighlights = hasPermission(
    user,
    getHighlightPermission('create')
  );
  const canReadHighlights = hasPermission(
    user,
    getHighlightPermission('read') // TODO: should this be collection based? where to get collection?
  );

  // load the page of highlights for the active tab
  const loadHighlightsOfActiveTab = React.useCallback(() => {
    const filters: Partial<HighlightsFilters> = {
      limit: PAGE_SIZE,
      page: currentPage,
      collectionIds,
      privacyLevels,
      tags,
    };
    if (activeTab === 'search') {
      filters.searchQuery = searchQuery;
    }

    const tabLoadActions = {
      'my-highlights': loadUserHighlights,
      'all-highlights': loadAllHighlights,
      'my-favorites': loadStarredHighlights,
      search: loadSearchHighlights,
    };
    const loadAction = tabLoadActions[activeTab as TabName];

    // make sure window is scrolled back to the top when we load new convos
    // not covered by ScrollToTop component since it's the same route
    window.scrollTo(0, 0);

    // TODO: cache results by only loading if we have a new page or a new collection
    // to do this requires storing the collection ID in our paging info or elsewhere
    // in the redux store.
    if (loadAction) {
      dispatch(loadAction(filters));
    }
  }, [
    collectionIds,
    privacyLevels,
    activeTab,
    currentPage,
    dispatch,
    searchQuery,
    tags,
  ]);

  React.useEffect(() => {
    // reload the highlights
    loadHighlightsOfActiveTab();
  }, [collectionIds, privacyLevels, setQuery, loadHighlightsOfActiveTab, tags]);

  const handleSearch = (searchQuery: string) => {
    // we want to clear filters when searching, so use 'push'
    setQuery({ q: searchQuery, tab: 'search', page: 1 }, 'push');
  };

  const handleClearSearch = () => {
    const tabToGoTo = activeTab === 'search' ? undefined : activeTab;
    setQuery(
      {
        q: undefined,
        tab: tabToGoTo,
        page: currentPage,
      },
      'push'
    );
  };

  const handleCloseTab = (tabKey: string) => {
    // reset the search term, and reset to the default or active tab
    const tabToGoTo = activeTab === tabKey ? undefined : activeTab;
    if (tabKey === 'search') {
      setQuery(
        {
          q: undefined,
          tab: tabToGoTo,
          page: currentPage,
        },
        'pushIn'
      );
    }
  };

  const emphasizedTerms = React.useMemo(
    () => (searchQuery ? [searchQuery] : undefined),
    [searchQuery]
  );
  const isLoading =
    isLoadingSearchHighlights ||
    isLoadingAllHighlights ||
    isLoadingStarredHighlights ||
    isLoadingUserHighlights;

  const allTabs = [
    {
      label: searchQuery || 'Search',
      include: searchQuery,
      key: 'search',
      content: activeTab === 'search' && (
        <HighlightsTabPane
          emphasizedTerms={emphasizedTerms}
          currentPage={currentPage}
          highlights={searchHighlights}
          pagingInfo={searchHighlightsPagingInfo}
          reloadTab={loadHighlightsOfActiveTab}
          isLoading={isLoadingSearchHighlights}
          filterOptions={searchHighlightsFilterOptions}
          noneFoundMsg={
            <>{t('highlights.search_empty', { query: searchQuery })}</>
          }
        />
      ),
      closable: true,
      filterOptions: searchHighlightsFilterOptions,
    },
    {
      label: t('highlights.tab_all'),
      content: activeTab === 'all-highlights' && (
        <HighlightsTabPane
          currentPage={currentPage}
          highlights={allHighlights}
          pagingInfo={allHighlightsPagingInfo}
          reloadTab={loadHighlightsOfActiveTab}
          isLoading={isLoadingAllHighlights}
          filterOptions={allHighlightsFilterOptions}
          noneFoundMsg={t('highlights.not_found')}
        />
      ),
      include: true,
      key: 'all-highlights',
      closable: false,
      filterOptions: allHighlightsFilterOptions,
    },
    {
      label: t('highlights.tab_my'),
      content: activeTab === 'my-highlights' && (
        <HighlightsTabPane
          currentPage={currentPage}
          highlights={userHighlights}
          pagingInfo={userHighlightsPagingInfo}
          reloadTab={loadHighlightsOfActiveTab}
          isLoading={isLoadingUserHighlights}
          filterOptions={userHighlightsFilterOptions}
          noneFoundMsg={t('highlights.personal_not_found')}
          noneExistMsg={t('highlights.personal_none_exist')}
        />
      ),
      include: canCreateHighlights,
      key: 'my-highlights',
      closable: false,
      filterOptions: userHighlightsFilterOptions,
    },
    {
      label: (
        <span>
          <FontAwesomeIcon icon="star" className="smaller-icon me-2" />
          {t('highlights.tab_favorites')}
        </span>
      ),
      content: activeTab === 'my-favorites' && (
        <HighlightsTabPane
          currentPage={currentPage}
          highlights={starredHighlights}
          pagingInfo={starredHighlightsPagingInfo}
          reloadTab={loadHighlightsOfActiveTab}
          isLoading={isLoadingStarredHighlights}
          filterOptions={starredHighlightsFilterOptions}
          noneFoundMsg={t('highlights.favorites_not_found')}
          noneExistMsg={
            <Trans
              i18nKey="highlights.favorite_none_exist"
              components={{
                1: <FontAwesomeIcon icon="star" />,
              }}
            />
          }
        />
      ),
      include: canReadHighlights,
      key: 'my-favorites',
      closable: false,
      filterOptions: starredHighlightsFilterOptions,
    },
  ];

  const tabData = allTabs.filter((d) => d.include);
  const currentTabIndex = tabData.map((d) => d.key).indexOf(activeTab);
  const currentTabData = tabData[currentTabIndex];

  const handleChangeTab = (tabIndex: number) => {
    const tabKey = tabData[tabIndex].key;
    // clear all filters on tab change so use 'push'
    setQuery({ tab: tabKey, page: 1 }, 'push');
  };

  return (
    <Layout className="HighlightsRoute" title={t('main_nav.highlights')}>
      <div className="container">
        <div className="d-flex flex-wrap justify-content-between mb-2">
          <div>
            <h2>{t('highlights.header')}</h2>
          </div>
          <div style={{ width: '300px' }}>
            <SearchInput
              key={searchQuery} /* reset on searchQuery clear */
              initialSearchQuery={searchQuery}
              onSearch={handleSearch}
              onClear={handleClearSearch}
              placeholder={t('highlights.search')}
            />
          </div>
        </div>

        <div className="d-flex">
          <div className="me-4">
            <CollectionFilter filterOptions={currentTabData.filterOptions} />
            <TagFilter filterOptions={currentTabData.filterOptions} />
            <PrivacyFilter filterOptions={currentTabData.filterOptions} />
          </div>

          <Tabs
            index={currentTabIndex}
            onChange={handleChangeTab}
            className={styles.tabContainer}
          >
            <TabList>
              {tabData.map((tab, index) => (
                <Tab
                  key={index}
                  closable={tab.closable}
                  onClose={() => handleCloseTab(tab.key)}
                  as={ClosableTab}
                  data-testid={`${tab.key}-tab`}
                >
                  {tab.label}
                </Tab>
              ))}
            </TabList>
            <TabPanels className="pt-3">
              {tabData.map((tab, index) => (
                <TabPanel key={index} data-testid={`${tab.key}-tab-panel`}>
                  {tab.content}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </div>
      </div>
      <LoadingOverlay active={isLoading} fixed />
    </Layout>
  );
};

// use connect() to get redux to connect to the component
export default connect(mapStateToProps)(HighlightsRoute);
