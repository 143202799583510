import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import {
  AnnotationCreationSelection,
  Highlight,
  TranscriptSnippet,
  WordChunk,
  WordEntry,
} from 'src/types/conversation';
import { formatTime } from 'src/util/format';
import {
  computeWordEmphasisIndices,
  highlightInSnippet,
  seekInSnippet,
  wordInRange,
} from 'src/util/snippets';
import { chunksFromWordEntries } from 'src/util/transcript';

import styles from './TranscriptParagraph.module.scss';

interface Props {
  snippet: TranscriptSnippet;
  showSpeaker: boolean;
  speakerColor?: string;
  speakerName: React.ReactNode;
  speakerShortName: React.ReactNode;
  /** if not provided, just renders as non clickable text */
  onPlay?: (seekTime?: number, endTime?: number) => void;
  emphasizedTerms?: string[];
  primaryTerms?: string[];
  secondaryTerms?: string[];
  primaryClassName?: string;
  secondaryClassName?: string;
  seekTime?: number | undefined;
  annotationCreationSelection?: AnnotationCreationSelection | undefined;

  /** if supplied, filters words that are rendered to just within this time window */
  filterWordsStart?: number;
  filterWordsEnd?: number;
  className?: string;
  activeHighlight?: Highlight | undefined;
  linkToConversation?: boolean;
  hideAdditionalInfo?: boolean;
}

const TranscriptChunk = ({
  snippet,
  chunk,
  seekTime,
  isLastChunk,
  onPlay,
  onMouseEnter,
  wordEmphasisIndices,
  primaryIndices,
  secondaryIndices,
  primaryClassName: primaryClassname = '',
  secondaryClassName: secondaryClassname = '',
  initialWordIndex,
  activeHighlight,
  annotationCreationSelection,
}: {
  snippet: TranscriptSnippet;
  chunk: WordChunk;
  seekTime: number | undefined;
  isLastChunk: boolean;
  onPlay: (seekTime?: number) => void;
  onMouseEnter: (seekTime: number, snippet: TranscriptSnippet) => void;
  wordEmphasisIndices: { [index: number]: boolean | undefined };
  primaryIndices: { [index: number]: boolean | undefined };
  secondaryIndices: { [index: number]: boolean | undefined };
  initialWordIndex: number;
  activeHighlight: Highlight | undefined;
  annotationCreationSelection: AnnotationCreationSelection | undefined;
  primaryClassName?: string;
  secondaryClassName?: string;
}) => {
  const isSeekChunk =
    seekTime != null &&
    chunk.audio_start_offset <= seekTime &&
    // if it is the last chunk, ignore the end time to prevent gaps
    // note this only works if we only receive seekTime when it is
    // within range of this paragraph, otherwise we should drop the i === check.
    (isLastChunk || chunk.audio_end_offset > seekTime);

  // compute which highlights are contained in this word chunk
  const chunkHighlights =
    snippet.highlights &&
    snippet.highlights.filter((highlight) =>
      highlightInSnippet(chunk, highlight, true)
    );
  const chunkHighlightClasses = chunkHighlights
    ? chunkHighlights
        .map((d) => `transcript-chunk-contains-highlight-${d.id}`)
        .join(' ')
    : undefined;

  const handleAudioPlay = React.useCallback(() => {
    onPlay(chunk.audio_start_offset);
  }, [onPlay, chunk.audio_start_offset]);
  const handleWordChunkMouseEnter = React.useCallback(() => {
    onMouseEnter(chunk.audio_start_offset, snippet);
  }, [chunk.audio_start_offset, onMouseEnter, snippet]);

  return (
    <span
      className={cx(
        styles['word-chunk'],
        {
          [`${styles.seekChunk} is-seek-chunk`]: isSeekChunk,
        },
        chunkHighlightClasses
      )}
      onClick={handleAudioPlay}
      onMouseEnter={handleWordChunkMouseEnter}
      data-starttime={chunk.audio_start_offset}
      data-endtime={chunk.audio_end_offset}
    >
      {chunk.words.map((word, j) => {
        const index = initialWordIndex + j;
        const isEmphasized = wordEmphasisIndices[index];
        const isPrimary = primaryIndices[index];
        const isSecondary = secondaryIndices[index];

        const isInHighlight =
          chunkHighlights &&
          chunkHighlights.some((highlight) => wordInRange(word, highlight));

        const isInActiveHighlight =
          activeHighlight &&
          isInHighlight &&
          wordInRange(word, activeHighlight);

        return (
          <React.Fragment key={j}>
            <span
              data-testid={`${word[0]}-${word[1]}`}
              data-starttime={word[1]} // needed for highlight creation from user selection
              data-endtime={word[2]}
              data-transcript-word={true}
              className={cx('position-relative', {
                [styles['emphasized-term']]:
                  isEmphasized || isPrimary || isSecondary,
                [primaryClassname]: isPrimary,
                [secondaryClassname]: isSecondary,
                [styles.isInHighlight]: isInHighlight,
                [styles.isInActiveHighlight]: isInActiveHighlight,
                [styles.isInCreateHighlight]:
                  annotationCreationSelection &&
                  wordInRange(word, annotationCreationSelection),
              })}
            >
              {word[0]}{' '}
            </span>{' '}
          </React.Fragment>
        );
      })}
    </span>
  );
};

const MemoTranscriptChunk = React.memo(TranscriptChunk);
(MemoTranscriptChunk as any).whyDidYouRender = false;

const TranscriptChunks = ({
  words,
  snippet,
  emphasizedTerms,
  primaryTerms,
  secondaryTerms,
  primaryClassName,
  secondaryClassName,
  seekTime,
  onPlay,
  onMouseEnter,
  activeHighlight,
  annotationCreationSelection,
}: {
  words: WordEntry[];
  emphasizedTerms?: string[];
  primaryTerms?: string[];
  secondaryTerms?: string[];
  primaryClassName?: string;
  secondaryClassName?: string;
  snippet: TranscriptSnippet;
  seekTime: number | undefined;
  onPlay: (seekTime?: number) => void;
  onMouseEnter: (seekTime: number, snippet: TranscriptSnippet) => void;
  activeHighlight: Highlight | undefined;
  annotationCreationSelection: AnnotationCreationSelection | undefined;
}) => {
  // convert to chunks
  const chunks = React.useMemo(() => chunksFromWordEntries(words), [words]);

  // compute word tuples that should be bolded or have primary/secondary classes applied
  const wordEmphasisIndices: {
    [index: number]: boolean | undefined;
  } = React.useMemo(
    () => computeWordEmphasisIndices(words, emphasizedTerms),
    [words, emphasizedTerms]
  );
  const primaryIndices: {
    [index: number]: boolean | undefined;
  } = React.useMemo(
    () => computeWordEmphasisIndices(words, primaryTerms),
    [words, primaryTerms]
  );
  const secondaryIndices: {
    [index: number]: boolean | undefined;
  } = React.useMemo(
    () => computeWordEmphasisIndices(words, secondaryTerms),
    [words, secondaryTerms]
  );

  let wordIndex = 0;

  return (
    <>
      {chunks.map((chunk: WordChunk, i: number) => {
        const initialWordIndex = wordIndex;
        wordIndex += chunk.words.length;
        const filteredSeekTime = seekInSnippet(seekTime, chunk)
          ? seekTime
          : undefined;

        return (
          <MemoTranscriptChunk
            key={i}
            snippet={snippet}
            chunk={chunk}
            seekTime={filteredSeekTime}
            isLastChunk={i === chunks.length - 1}
            onPlay={onPlay}
            onMouseEnter={onMouseEnter}
            wordEmphasisIndices={wordEmphasisIndices}
            primaryIndices={primaryIndices}
            secondaryIndices={secondaryIndices}
            primaryClassName={primaryClassName}
            secondaryClassName={secondaryClassName}
            initialWordIndex={initialWordIndex}
            activeHighlight={activeHighlight}
            annotationCreationSelection={annotationCreationSelection}
          />
        );
      })}
    </>
  );
};
const MemoTranscriptChunks = React.memo(TranscriptChunks);
(MemoTranscriptChunks as any).whyDidYouRender = false;

const TranscriptParagraph: React.FunctionComponent<Props> = ({
  snippet,
  showSpeaker,
  speakerColor,
  speakerName,
  speakerShortName,
  annotationCreationSelection,
  onPlay,
  filterWordsStart,
  filterWordsEnd,
  className,
  seekTime,
  activeHighlight,
  emphasizedTerms,
  primaryTerms,
  secondaryTerms,
  primaryClassName,
  secondaryClassName,
  linkToConversation,
  hideAdditionalInfo,
}: Props) => {
  const { t } = useTranslation();
  const [hoverTime, setHoverTime] = React.useState<number | null>(null);
  const [hoverSnippet, setHoverSnippet] =
    React.useState<TranscriptSnippet | null>(null);

  const handleAudioPlay = React.useCallback(
    (seekTime?: number) => {
      // do not trigger a play if we have a highlight range and we are clicking in it
      if (annotationCreationSelection) {
        return;
      }

      if (onPlay) {
        let startTime;
        if (seekTime == null) {
          startTime = Math.max(
            filterWordsStart || 0,
            snippet.audio_start_offset
          );
        } else {
          startTime = seekTime;
        }
        onPlay(startTime, filterWordsEnd);
      }
    },
    [
      onPlay,
      annotationCreationSelection,
      filterWordsStart,
      filterWordsEnd,
      snippet.audio_start_offset,
    ]
  );
  const handleAudioPlayFromEvent = React.useCallback(() => {
    handleAudioPlay();
  }, [handleAudioPlay]);

  // update to keep track of hovered on word chunk for time preview
  const handleWordChunkMouseEnter = React.useCallback(
    (startTime: number, snippet: TranscriptSnippet) => {
      setHoverSnippet(snippet);
      setHoverTime(startTime);
    },
    []
  );

  // reset time preview when no longer hovering on snippet
  const handleSnippetMouseLeave = React.useCallback(() => {
    setHoverSnippet(null);
    setHoverTime(null);
  }, []);

  const {
    words,
    audio_start_offset: startTime,
    audio_end_offset: endTime,
  } = snippet;

  const highlightTimeProps = {
    'data-starttime': startTime,
    'data-endtime': startTime, // use start time since this happens when selecting the very start of the transcript
  };

  const filteredWords = React.useMemo(() => {
    let filteredWords = words;
    if (filterWordsStart != null && filterWordsEnd != null) {
      const filterWordRange = {
        audio_start_offset: filterWordsStart,
        audio_end_offset: filterWordsEnd,
      };
      filteredWords = words.filter((word) =>
        wordInRange(word, filterWordRange)
      );
    }
    return filteredWords;
  }, [filterWordsStart, filterWordsEnd, words]);

  // do not render a speaker name if no words are available. do not render anything
  // LVN-287
  if (!filteredWords.length) {
    return null;
  }

  let displayTime = filteredWords.length ? filteredWords[0][1] : startTime;
  if (hoverSnippet === snippet && hoverTime != null) {
    displayTime = hoverTime;
  }

  return (
    <div
      className={cx(styles['TranscriptParagraph'], className, {
        [styles.notPlayable]: !onPlay,
        [styles.hideHighlight]: filteredWords !== words,
        [styles.hideAdditionalInfo]: hideAdditionalInfo,
      })}
      {...highlightTimeProps} /* needed for highlight creation from user selection */
    >
      {!hideAdditionalInfo && (
        <>
          {showSpeaker ? (
            <div
              className={`${styles['header']}`}
              {...highlightTimeProps} /* needed for highlight creation from user selection */
            >
              <span
                className={styles['transcript-speaker-avatar']}
                style={{ backgroundColor: speakerColor }}
              >
                {speakerShortName}
              </span>
              <span
                className={styles['transcript-speaker']}
                {...highlightTimeProps} /* needed for highlight creation from user selection */
              >
                {speakerName}
              </span>
              <span
                className={styles['transcript-timestamp']}
                onClick={handleAudioPlayFromEvent}
                data-content-after={`▶ ${t('common.listen')}`} // filled in via 'after' in scss
                {...highlightTimeProps} /* needed for highlight creation from user selection */
              >
                {formatTime(displayTime)}
              </span>
              {linkToConversation && (
                <div className="d-inline-flex align-items-end float-end">
                  <Link
                    data-testid="conversation-link"
                    className={cx(
                      'd-inline small',
                      styles['conversation-link']
                    )}
                    to={`/conversation/${snippet.conversation_id}?t=${snippet.audio_start_offset}`}
                  >
                    {t('conversations.go_to')}
                  </Link>
                </div>
              )}
            </div>
          ) : (
            <div
              className={`${styles['header']} ${styles['same-speaker']}`}
              onClick={handleAudioPlayFromEvent}
              {...highlightTimeProps} /* needed for highlight creation from user selection */
            >
              <span className={styles['transcript-timestamp']}>
                {formatTime(displayTime)}
              </span>
            </div>
          )}
        </>
      )}
      <div
        data-testid={`snippet-${snippet.id}`}
        onMouseLeave={handleSnippetMouseLeave}
        className={styles['transcript-content']}
        data-starttime={
          endTime
        } /* needed for highlight creation from user selection */
        data-endtime={
          endTime
        } /* this one uses end time since it happens when dragging from the end of the paragraph */
      >
        <MemoTranscriptChunks
          words={filteredWords}
          snippet={snippet}
          seekTime={seekTime}
          onPlay={handleAudioPlay}
          onMouseEnter={handleWordChunkMouseEnter}
          activeHighlight={activeHighlight}
          emphasizedTerms={emphasizedTerms}
          primaryTerms={primaryTerms}
          secondaryTerms={secondaryTerms}
          primaryClassName={primaryClassName}
          secondaryClassName={secondaryClassName}
          annotationCreationSelection={annotationCreationSelection}
        />
      </div>
    </div>
  );
};
TranscriptParagraph.defaultProps = {
  showSpeaker: true,
  speakerColor: '#333',
  speakerName: 'Speaker',
  speakerShortName: '',
};
(TranscriptParagraph as any).whyDidYouRender = false;
const MemoTranscriptParagraph = React.memo(TranscriptParagraph);

export default MemoTranscriptParagraph;
