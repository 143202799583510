import * as React from 'react';

import Button from 'src/components/core/Button/Button';
import TranscriptParagraph from 'src/components/Transcript/TranscriptParagraph/TranscriptParagraph';
import { useSeekTime } from 'src/contexts/GlobalAudioContext';
import { Snippet } from 'src/types/conversation';

interface Props {
  snippet: Snippet;
  onPlay: (seekTime?: number) => void;
  onPause: () => void;
  emphasizedTerms?: string[];
  seekTime?: number | undefined;
  showSpeaker: boolean;
  showControls: boolean;
  onViewInTranscript?: (
    targetSeekTimeMin: number,
    targetSeekTimeMax?: number
  ) => void;
}

const ConversationExcerpt: React.FunctionComponent<Props> = ({
  showSpeaker = true,
  snippet,
  onPlay,
  emphasizedTerms,
  showControls = true,
  onViewInTranscript,
}) => {
  const { speaker_name: speakerName } = snippet;
  const speakerShortName = speakerName[0];
  const seekTime = useSeekTime({
    startTime: snippet.audio_start_offset,
    endTime: snippet.audio_end_offset,
  });

  const handleViewInTranscript = () => {
    if (onViewInTranscript) {
      onViewInTranscript(snippet.audio_start_offset, snippet.audio_end_offset);
    }
  };

  return (
    <div className="row">
      <div className="col-lg-8">
        <TranscriptParagraph
          snippet={snippet}
          showSpeaker={showSpeaker}
          speakerName={speakerName}
          speakerShortName={speakerShortName}
          onPlay={onPlay}
          emphasizedTerms={emphasizedTerms}
          filterWordsStart={snippet.audio_start_offset}
          filterWordsEnd={snippet.audio_end_offset}
          seekTime={seekTime}
        />
      </div>
      {showControls && onViewInTranscript && (
        <div className="col-lg-4">
          <div className="pt-4">
            <Button onClick={handleViewInTranscript}>View in Transcript</Button>
          </div>
        </div>
      )}
    </div>
  );
};

(ConversationExcerpt as any).whyDidYouRender = false;
export default ConversationExcerpt;
