import * as React from 'react';
import { Trans } from 'react-i18next';

import Button from 'src/components/core/Button/Button';

interface Props {
  numSteps: number;
  step: number;
  className?: string | undefined;
  onChange: (step: number) => void;
}

export default function Stepper({
  className,
  onChange,
  numSteps,
  step,
}: Props) {
  const handleNext = () => {
    const newStep = step - 1 < 0 ? numSteps - 1 : step - 1;
    onChange(newStep);
  };

  const handlePrev = () => {
    const newStep = (step + 1) % numSteps;
    onChange(newStep);
  };

  return (
    <div className={className}>
      <Button
        icon={['far', 'chevron-left']}
        shape="circle"
        className="me-2"
        onClick={handleNext}
      />
      <Trans
        i18nKey={'conversation.stepper'}
        values={{
          count: step + 1,
          total: numSteps,
        }}
      />
      <Button
        icon={['far', 'chevron-right']}
        shape="circle"
        className="ms-2"
        onClick={handlePrev}
      />
    </div>
  );
}
