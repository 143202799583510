import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import { Collapse, Tooltip } from 'reactstrap';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import Button from 'src/components/core/Button/Button';
import { useConversationPrivacyContent } from 'src/constants';
import authSelectors from 'src/redux/auth/auth-selectors';
import collectionsSelectors from 'src/redux/collections/collections-selectors';
import {
  clearEditCollectionDetail,
  editCollectionDetail,
} from 'src/redux/collections/collections-slice';
import { StoreState } from 'src/redux/store';
import { User } from 'src/types/auth';
import { Collection } from 'src/types/collection';
import { copyTextToClipboard } from 'src/util/copy-to-clipboard';
import { getCollectionsPermission, hasPermission } from 'src/util/user';
import CollectionDetailEditForm from '../CollectionDetailEditForm/CollectionDetailEditForm';

import styles from './CollectionManagementRow.module.scss';

const PublicShareLink = ({ collection }: { collection: Collection }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isClicked, setIsClicked] = React.useState(false);
  const tooltipId = `share-link-${collection.id}`;
  if (collection.privacy_level === 'public') {
    const linkText = isClicked ? (
      <span>
        {t('admin.collection_share_copied')}
        <FontAwesomeIcon icon={['far', 'check']} className="ms-1" size="sm" />
      </span>
    ) : (
      <>{t('admin.collection_share_copy')}</>
    );

    const handleClick = () => {
      // https://app.lvn.org for prod or the base url for your env
      const baseUrl = window.location.origin;
      copyTextToClipboard(`${baseUrl}/collection/${collection.id}`);
      setIsClicked(true);
    };

    return (
      <Button color="link" onClick={handleClick} className={styles.copyLinkBtn}>
        {linkText}
      </Button>
    );
  }

  return (
    <>
      <span id={tooltipId}>{t('admin.collection_share_inactive')}</span>
      <Tooltip
        isOpen={isOpen}
        target={tooltipId}
        toggle={() => setIsOpen(!isOpen)}
        data-testid={`tooltip-${collection.id}`}
      >
        {t('admin.collection_share_inactive_tooltip', {
          level: collection.privacy_level,
        })}
      </Tooltip>
    </>
  );
};

interface CollectionManagementRowProps {
  collection: Collection;
  onDeleteCollection: (collectionId: Collection['id']) => void;
}

interface StateProps {
  isSaving: boolean;
  isSaved: boolean;
  error: Error | undefined;
  editCollectionId: number | undefined;
  user: User;
}

type Props = CollectionManagementRowProps & StateProps & DispatchProp;

const mapStateToProps = (state: StoreState) => ({
  isSaving: collectionsSelectors.isSaving(state),
  isSaved: collectionsSelectors.isSaved(state),
  error: collectionsSelectors.getEditCollectionDetailError(state),
  editCollectionId: collectionsSelectors.getEditCollectionId(state),
  user: authSelectors.getUser(state),
});

const CollectionManagementRow = ({
  collection,
  isSaved,
  isSaving,
  editCollectionId,
  error,
  user,
  dispatch,
  onDeleteCollection,
}: Props) => {
  const { t } = useTranslation();
  const { title, num_conversations, privacy_level, id } = collection;
  const { icon: privacyIcon, title: privacyTitle } =
    useConversationPrivacyContent()[privacy_level];
  const isCollectionRemovable = num_conversations === 0;
  const [isRowExpanded, setIsRowExpanded] = React.useState(false);

  const toggleRowExpansion = () => {
    setIsRowExpanded(!isRowExpanded);
  };

  const handleSubmit = (changes: Partial<Collection>, id?: number) => {
    // there will always be an id when editing a collection, rather than creating one
    if (id != null) {
      dispatch(editCollectionDetail({ collectionId: id, changes }));
    }
  };

  // reset editCollectionDetail redux state after edit collection detail form is successfully saved
  if (isSaved && id === editCollectionId) {
    dispatch(clearEditCollectionDetail());
  }
  const canUpdateCollection = hasPermission(
    user,
    getCollectionsPermission({ operation: 'update', collection_id: id })
  );
  let rowIcon: IconProp | undefined = 'caret-right';
  if (!canUpdateCollection) {
    rowIcon = undefined;
  } else if (isRowExpanded) {
    rowIcon = 'caret-down';
  }
  const [removeTooltipOpen, setRemoveTooltipOpen] = React.useState(false);
  const toggleRemoveTooltip = () => setRemoveTooltipOpen(!removeTooltipOpen);

  return (
    <div className={styles.row}>
      <div
        className={cx(
          { [styles.active]: isRowExpanded },
          'row w-full border-top',
          styles.viewRow
        )}
        data-testid={`collection-management-row-${id}`}
      >
        <div className="col-5 m-auto p-0 ps-4">
          <Button
            icon={rowIcon}
            onClick={
              canUpdateCollection
                ? toggleRowExpansion
                : () => {
                    return;
                  }
            }
            title={t('admin.collection_edit')}
            color="plain"
            data-testid="expand-row-btn"
            aria-expanded={isRowExpanded}
            className={styles.expandRowBtn}
            disabled={!canUpdateCollection}
          >
            {title}
          </Button>
        </div>
        <div className="col-2 m-auto p-0 ps-3">{num_conversations}</div>
        <div className="text-nowrap col-2 m-auto p-0">
          <FontAwesomeIcon
            icon={privacyIcon}
            className={`text-${privacy_level} me-2`}
            size="sm"
          />
          {privacyTitle()}
        </div>
        <div className="col-2 m-auto p-0">
          <PublicShareLink collection={collection} />
        </div>
        <div className="text-center col-1 m-auto p-0">
          <div id={`remove-collection-${collection.id}`}>
            <Button
              outline
              disabled={!isCollectionRemovable}
              whiteBg
              compact
              className="shadow-none"
              icon={isCollectionRemovable ? ['fas', 'trash'] : ['fas', 'ban']}
              onClick={() => onDeleteCollection(id)}
              aria-labelledby={`tooltip-remove-collection-${collection.id}`}
              data-testid={`remove-collection-${collection.id}`}
            />
          </div>
          <Tooltip
            placement="bottom"
            hideArrow={true}
            isOpen={removeTooltipOpen}
            innerClassName={cx(styles.tooltip)}
            target={`remove-collection-${collection.id}`}
            toggle={toggleRemoveTooltip}
            data-testid={`tooltip-remove-collection-${collection.id}`}
          >
            {isCollectionRemovable ? (
              t('admin.collection_remove')
            ) : (
              <Trans
                i18nKey="admin.collection_remove_tooltip"
                /*eslint-disable */
                components={{
                  1: <a href="mailto:help@lvn.org" className="text-white" />,
                }}
                /*eslint-disable */
              />
            )}
          </Tooltip>
        </div>
      </div>
      <div className={styles.rowExpansion}>
        <Collapse
          className={styles.collapse}
          data-testid={`row-expansion-${id}`}
          isOpen={isRowExpanded}
        >
          <div className="w-100 d-flex justify-content-center">
            {isRowExpanded ? (
              <CollectionDetailEditForm
                collection={collection}
                isSaved={isSaved}
                isSaving={isSaving}
                error={error}
                onCollapse={toggleRowExpansion}
                onSubmit={handleSubmit}
                className={cx('mb-4', styles.editForm)}
              />
            ) : (
              <>
                {/* placeholder to allow collapse animation without rendering form */}
                <div style={{ height: '230px' }} aria-hidden="true" />
              </>
            )}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(CollectionManagementRow);
