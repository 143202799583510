import * as React from 'react';
import { Popover } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import nanoid from 'nanoid';

import Button from 'src/components/core/Button/Button';
import HighlightTypeLabel from 'src/components/HighlightTypeLabel/HighlightTypeLabel';
import PrivacyMenu from 'src/components/PrivacyMenu/PrivacyMenu';
import { User } from 'src/types/auth';
import { ConversationNestedCollection } from 'src/types/collection';
import {
  AnnotationPrivacyLevel,
  Conversation,
  Highlight,
} from 'src/types/conversation';

import styles from './HighlightTypeDropdown.module.scss';

interface Props {
  highlight: Highlight;
  onChange: (privacyLevel: AnnotationPrivacyLevel) => void;
  collection: ConversationNestedCollection;
  user: User;
  iconOnly?: boolean;
  className?: string | undefined;
  conversation?: Conversation | undefined;
}

const HighlightTypeDropdown = ({
  highlight,
  onChange,
  collection,
  conversation,
  user,
  iconOnly,
  className,
}: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handlePrivacyChange = (privacyLevel: AnnotationPrivacyLevel) => {
    onChange(privacyLevel);
    setIsOpen(false);
  };

  // make a unique ID for each button to target
  const buttonId = React.useMemo(() => 'highlightTypeButton-' + nanoid(), []);

  return (
    <div className={className} data-testid="highlight-type-dropdown">
      <Button
        className={cx(styles.dropdownButton, 'fwbold small-header')}
        onClick={handleToggle}
        id={buttonId}
        compact
      >
        <HighlightTypeLabel
          highlight={highlight}
          conversation={conversation}
          iconOnly={iconOnly}
          className="me-1"
        />
        {<FontAwesomeIcon icon="caret-down" />}
      </Button>

      <Popover
        placement="bottom-start"
        isOpen={isOpen}
        toggle={() => setIsOpen(false)} // set this to false or else the popover won't stay closed when handlePrivacyChange is called
        size="sm"
        target={buttonId}
        trigger="legacy" // allows clicking inside as well as going away when clicking outside
        hideArrow
      >
        <PrivacyMenu
          collection={collection}
          highlight={highlight}
          onChange={handlePrivacyChange}
          user={user}
          conversation={conversation}
        />
      </Popover>
    </div>
  );
};

export default HighlightTypeDropdown;
