import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useInterval } from 'react-use';
import _ from 'lodash';

import {
  cancelPendingAITaggingRequests,
  pollAITaggingRequests,
  tagEntriesWithAI,
} from 'src/api/api';
import { useCatalogPageContext } from 'src/components/Insights/Catalog/CatalogPageProvider';
import { SuggestCodesDialog } from 'src/components/Insights/Dialogs/SuggestCodesDialog';
import { useFlag } from 'src/Providers/FlagProvider';
import { refreshEntries } from 'src/redux/catalog/catalog-slice';
import {
  AIRequest,
  AIRequestStatus,
  AITaggingResponse,
  Entry,
} from 'src/types/insights';
import { useLocalStorage } from 'src/util/hooks';
import { createProvider } from 'src/util/provider';

export const [AIProvider, useAIContext] = createProvider(() => {
  const { catalog, entries, showModal, hideModal, dispatch } =
    useCatalogPageContext();
  const [aiSuggestionIntroEnabled, setAiSuggestionIntroEnabled] =
    useLocalStorage('aiSuggestionIntroEnabled', true);
  const [aiOn, setAIOn] = useState(true);

  // we keep track of tagRequests as a kind of "session" since the server always returns
  // all the requests ever made by the current user. this way, we can show the status of
  // the current session of requests only.
  const [tagRequests, setTagRequests] = useState<AIRequest[]>([]);
  const [pendingTagRequests, setPendingTagRequests] = useState<AIRequest[]>([]);
  const [succeededTagRequests, setSucceededTagRequests] = useState<AIRequest[]>(
    []
  );
  const [canceledTagRequests, setCanceledTagRequests] = useState<AIRequest[]>(
    []
  );
  const [failedTagRequests, setFailedTagRequests] = useState<AIRequest[]>([]);
  const [emptyTagRequests, setEmptyTagRequests] = useState<AIRequest[]>([]);

  const pendingCount = pendingTagRequests?.length ?? 0;
  const succeededCount = succeededTagRequests?.length ?? 0;
  const failedCount = failedTagRequests?.length ?? 0;
  const canceledCount = canceledTagRequests?.length ?? 0;
  const emptyCount = emptyTagRequests?.length ?? 0;
  const completedCount =
    succeededCount + failedCount + canceledCount + emptyCount;
  const totalCount = tagRequests?.length ?? 0;

  const taggingProgress = useMemo(
    () => ({
      // at least 1% so that progress bar appears
      percent: totalCount
        ? Math.max(1, Math.ceil((completedCount / totalCount) * 100))
        : 1,
      succeededCount,
      failedCount,
      canceledCount,
      emptyCount,
      completedCount,
      totalCount,
    }),
    [
      totalCount,
      completedCount,
      succeededCount,
      failedCount,
      canceledCount,
      emptyCount,
    ]
  );

  // ••• ACTIONS

  const tagWithAI = async (
    entryIds: Entry['id'][],
    onTaggingStart?: () => void
  ) => {
    const start = () => {
      startTaggingWithAI(entryIds);
      onTaggingStart?.();
    };

    if (aiSuggestionIntroEnabled) {
      const onConfirm = () => {
        hideModal();
        start();
      };
      showModal(<SuggestCodesDialog {...{ onConfirm }} />);
    } else {
      start();
    }
  };

  const startTaggingWithAI = async (entryIds: Entry['id'][]) => {
    const annotationIds = _.compact(
      entryIds.map((id) => entries.find((e) => e.id === id)?.annotation_id)
    );
    const newPendingRequests = annotationIds.map(toPendingAIRequest);

    // the calls to setPendingTagRequests here optimistically updates the ui
    if (pendingTagRequests.length) {
      setPendingTagRequests(
        _.uniqBy(
          [...pendingTagRequests, ...newPendingRequests],
          'annotation_id'
        )
      );
      setTagRequests(
        _.uniqBy([...tagRequests, ...newPendingRequests], 'annotation_id')
      );
    } else {
      setPendingTagRequests(newPendingRequests);
      setTagRequests(newPendingRequests);
    }

    try {
      handleResponse(await tagEntriesWithAI(catalog.id, annotationIds));
    } catch (e) {
      onFailure('Failed to start AI tagging.');
    }
  };

  const poll = async (withRefresh = true) => {
    try {
      const response = await pollAITaggingRequests(catalog.id);
      handleResponse(response);

      const completedAnnotationIds = tagRequests
        .filter((r) =>
          response.completed_ai_requests.some(
            (cr) => cr.annotation_id === r.annotation_id
          )
        )
        .map((r) => r.annotation_id);

      if (withRefresh && completedAnnotationIds.length) {
        dispatch(
          refreshEntries({
            annotation_ids: completedAnnotationIds,
            catalogId: catalog.id,
          })
        );
      }
    } catch (e) {
      onFailure('Failed to poll AI tagging requests.');
    }
  };

  const stopTaggingWithAI = async () => {
    try {
      handleResponse(await cancelPendingAITaggingRequests(catalog.id));
    } catch (e) {
      onFailure('Failed to stop AI tagging.');
    }
  };

  const handleResponse = ({
    pending_ai_requests,
    completed_ai_requests,
    failed_ai_requests,
    canceled_ai_requests,
  }: AITaggingResponse) => {
    const [emptyList, succeededList] = _.partition(
      completed_ai_requests,
      (r) => !r.codes?.length
    );
    const empty = _.keyBy(emptyList, 'annotation_id');
    const succeeded = _.keyBy(succeededList, 'annotation_id');
    const failed = _.keyBy(failed_ai_requests, 'annotation_id');
    const canceled = _.keyBy(canceled_ai_requests, 'annotation_id');

    setPendingTagRequests(pending_ai_requests);
    setEmptyTagRequests(tagRequests.filter((r) => empty[r.annotation_id]));
    setSucceededTagRequests(
      tagRequests.filter((r) => succeeded[r.annotation_id])
    );
    setFailedTagRequests(tagRequests.filter((r) => failed[r.annotation_id]));
    setCanceledTagRequests(
      tagRequests.filter((r) => canceled[r.annotation_id])
    );
  };

  const onFailure = (message: string) => {
    toast.error(message, { position: toast.POSITION.BOTTOM_RIGHT });
  };

  // ••• EFFECTS

  useInterval(poll, pendingCount ? 3000 : null);
  useEffect(() => {
    poll(false);
  }, []); // check for requests on startup

  // ••• CONTEXT

  return {
    tagWithAI,
    stopTaggingWithAI,

    aiOn,
    setAIOn,

    canTagWithAI: useFlag('ai_tagging'),
    pendingTagRequests,
    taggingProgress,

    aiSuggestionIntroEnabled,
    setAiSuggestionIntroEnabled,
  };
});

// HELPERS

function toPendingAIRequest(annotationId: Entry['annotation_id']): AIRequest {
  return {
    annotation_id: annotationId,
    ai_request_status: AIRequestStatus.pending,
  };
}
