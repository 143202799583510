import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Button, {
  Props as ButtonProps,
} from 'src/components/core/Button/Button';
import { useAnalyticsContext } from 'src/Providers/AnalyticsProvider';
import { Action, Category, Name } from 'src/types/analytics';

export interface Props {
  onClick: () => void;
  isLoading?: boolean;
  isPlaying?: boolean;
  isError?: boolean;
  whiteBg?: boolean | undefined;
  className?: string | undefined;
  tabIndex?: number;
  shape?: 'rect' | 'circle';
  size?: 'sm' | 'md' | 'lg';
  style?: React.CSSProperties;
}

const PlayButton = ({
  isLoading,
  isPlaying,
  isError,
  className,
  whiteBg,
  onClick,
  tabIndex,
  shape = 'rect',
  size,
  style,
}: Props) => {
  const { analyticsEvent } = useAnalyticsContext();
  const { t } = useTranslation();
  const buttonProps: ButtonProps = {
    icon: ['fas', 'play'],
    content: t('audio_player.play'),
  };
  const handleClick = () => {
    onClick();
    if (className?.includes('highlight-card-play-button')) {
      analyticsEvent({
        category: Category.Highlight,
        action: buttonProps.content as Action,
        name: Name.HighlightCard,
      });
    }
  };

  // if this is the active audio (corresponds to the audio props from global)
  if (isError) {
    buttonProps.icon = ['fas', 'exclamation'];
    buttonProps.content = t('common.error');
    buttonProps.color = 'danger';
  } else if (isLoading) {
    buttonProps.disabled = true;
    buttonProps.icon = 'loading';
    buttonProps.content = t('common.loading');
  } else if (isPlaying) {
    buttonProps.icon = ['fas', 'pause'];
    buttonProps.content = t('audio_player.pause');
  }

  if (shape === 'circle') {
    return (
      <Button
        onClick={handleClick}
        color={buttonProps.color ? buttonProps.color : 'fora-purple'}
        shape="circle"
        size={size || 'sm'}
        aria-label={buttonProps.content}
        icon={buttonProps.icon}
        className={className}
        data-testid="play-button"
        tabIndex={tabIndex}
        style={style}
      />
    );
  }

  return (
    <Button
      color="primary"
      outline={!isPlaying}
      grayOutline={!isPlaying}
      whiteBg={whiteBg && !isPlaying}
      size={size}
      onClick={handleClick}
      className={className}
      tabIndex={tabIndex}
      data-testid="play-button"
      style={style}
      {...buttonProps}
    />
  );
};

export default PlayButton;
