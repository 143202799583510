export const isColorLight = (hexColor: string) => {
  const red = parseInt(`${hexColor[1]}${hexColor[2]}`, 16);
  const green = parseInt(`${hexColor[3]}${hexColor[4]}`, 16);
  const blue = parseInt(`${hexColor[5]}${hexColor[6]}`, 16);
  return red * 0.299 + green * 0.587 + blue * 0.114 > 186;
};

export const getFontColor = (backgroundHexColor: string) => {
  return isColorLight(backgroundHexColor) ? '#333333' : 'white';
};

export const TAG_COLORS = [
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#cddc39',
  '#ffeb3b',
  '#ffc107',
  '#ff9800',
  '#ff5722',
  '#795548',
  '#607d8b',
];
