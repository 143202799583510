import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import { Link } from 'react-router-dom';
import { Collapse, FormGroup, Input, Label, Tooltip } from 'reactstrap';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { Moment } from 'moment-timezone';

import Button from 'src/components/core/Button/Button';
import FormError from 'src/components/FormError/FormError';
import PipelineStatusIcon from 'src/components/PipelineStatusIcon/PipelineStatusIcon';
import PublishButton from 'src/components/PublishButton/PublishButton';
import SaveButton from 'src/components/SaveButton/SaveButton';
import conversationSelectors from 'src/redux/conversation/conversation-selectors';
import { editConversationDraftState } from 'src/redux/conversation/conversation-slice';
import { StoreState } from 'src/redux/store';
import { RowFieldErrors, RowInputs } from 'src/types/admin';
import { Conversation } from 'src/types/conversation';

import styles from './ConversationRow.module.scss';

export const UploadedTableData = ({
  conversationId,
  postTime,
  stale,
}: {
  conversationId: Conversation['id'];
  postTime: Moment;
  stale?: boolean;
}) => {
  const { t } = useTranslation();
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const tooltipTargetId = `upload-${conversationId}`;

  const uploaded = postTime ? postTime.fromNow() : '';
  return (
    <>
      {stale && (
        <Tooltip
          isOpen={tooltipOpen}
          target={tooltipTargetId}
          toggle={toggle}
          data-testid={`${tooltipTargetId}-tooltip`}
        >
          {t('admin.draft_uploaded_stale')}
        </Tooltip>
      )}
      <div id={tooltipTargetId} className="d-inline-block">
        {stale && (
          <FontAwesomeIcon
            icon={['fas', 'exclamation-triangle']}
            className="text-warning me-2"
            data-testid="upload-warning-icon"
          />
        )}
        {uploaded}
      </div>
    </>
  );
};

interface BaseProps {
  conversation: Conversation;
  formId: string;
  inputs: RowInputs;
  editError: Error | undefined;
  editing: boolean;
  fieldErrors: RowFieldErrors;
  onEditClick: (conv: Conversation) => void;
  onChangeInput: (evt: React.SyntheticEvent<HTMLInputElement>) => void;
  onCancel: () => void;
  stale?: boolean;
}

interface StateProps {
  isSavingEditConversation: boolean;
  isSavingDraftState: boolean;
  isSavedDraftState: boolean;
  errorDraftState: Error | undefined;
  editDraftStateConversationId: Conversation['id'] | undefined;
}

type Props = BaseProps & StateProps & DispatchProp;

/** Map state from redux to the components props */
const mapStateToProps = (state: StoreState): StateProps => ({
  isSavingEditConversation:
    conversationSelectors.isSavingEditConversation(state),
  isSavingDraftState:
    conversationSelectors.isSavingEditConversationDraftState(state),
  isSavedDraftState:
    conversationSelectors.isSavedEditConversationDraftState(state),
  errorDraftState:
    conversationSelectors.getErrorEditConversationDraftState(state),
  editDraftStateConversationId:
    conversationSelectors.getEditDraftStateConversationId(state),
});

export const ConversationRow = ({
  dispatch,
  conversation,
  formId,
  inputs,
  editError,
  editing,
  onEditClick,
  onChangeInput,
  onCancel,
  isSavingEditConversation,
  isSavingDraftState,
  isSavedDraftState,
  errorDraftState,
  editDraftStateConversationId,
  fieldErrors,
  stale,
}: Props) => {
  const {
    title,
    speech_pipeline_status: pipelineStatus,
    post_time: postTime,
    collection,
    host,
    location,
    id,
    is_draft: isDraft,
    privacy_level,
  } = conversation;

  const { t } = useTranslation();
  const thisConversation = id === editDraftStateConversationId;

  const handlePublish = () => {
    dispatch(
      editConversationDraftState({
        conversationId: id,
        is_draft: false, // could also change to !conversation.is_draft if we want to toggle
      })
    );
  };

  let rowIcon: IconProp | undefined = 'caret-right';
  if (editing) {
    rowIcon = 'caret-down';
  }

  return (
    <div className={styles.row}>
      <div
        className={cx('row border-top', styles.viewRow)}
        tabIndex={0}
        data-testid={`conversation-${id}`}
      >
        <div
          className={cx('col-1 m-auto p-0 d-flex', styles.editButton)}
          onClick={() => {
            if (editing) {
              onCancel();
            } else {
              onEditClick(conversation);
            }
          }}
        >
          <Button
            icon={rowIcon}
            color="plain"
            data-testid="edit-button"
            aria-expanded={!!editing}
            aria-label="Edit"
            className={cx({ [styles.expandRowBtn]: editing })}
          ></Button>
          <PipelineStatusIcon
            speechPipelineStatus={pipelineStatus}
            conversationId={id}
          />
        </div>
        <div className={'col-2  m-auto p-0'}>
          <Link data-testid="title-link" to={`/conversation/${id}`}>
            {title}
          </Link>
          {privacy_level === 'protected' && (
            <>
              {' '}
              -{' '}
              <FontAwesomeIcon
                icon="user-shield"
                className="me-1 text-protected"
                size="sm"
                title={t('conversation.privacy_protected_conversation_header')}
              />
            </>
          )}
        </div>
        <div className={'col-2 m-auto p-0'}>{collection.title}</div>
        <div className={'col-2 m-auto p-0'}>{host.name}</div>
        <div className={`col-2  m-auto p-0`} data-testid="location-field">
          {location.name}
        </div>
        <div className={'col-2 m-auto p-0'}>
          <UploadedTableData
            postTime={postTime}
            stale={stale}
            conversationId={id}
          />
        </div>
        <div className={'col-1 m-auto p-0 text-center'}>
          <PublishButton
            isDraft={isDraft}
            isSaving={thisConversation && isSavingDraftState}
            isSaved={thisConversation && isSavedDraftState}
            error={thisConversation ? errorDraftState : undefined}
            onClick={() => handlePublish()}
          />
        </div>
      </div>
      <div>
        <Collapse className={'pt-2'} isOpen={!!editing}>
          <div className="w-100 d-flex justify-content-center">
            {editing ? (
              <div
                className="d-flex flex-column"
                data-testid={`conversation-${conversation.id}-edit`}
              >
                <FormGroup className={cx(styles.formGroup, 'row')}>
                  <div className={cx(styles.inputGroup, 'column')}>
                    <Label>{t('admin.draft_title')}</Label>
                    <Input
                      type="text"
                      name="draftTitle"
                      id="conversationTitle"
                      value={inputs.draftTitle}
                      onChange={onChangeInput}
                      disabled={isSavingEditConversation}
                      form={formId}
                      aria-label={t('admin.draft_title')}
                      data-testid="title-input"
                    />
                  </div>
                  <div className={cx(styles.inputGroup, 'column')}>
                    <Label>{t('admin.draft_location')}</Label>
                    <Input
                      type="text"
                      name="draftLocation"
                      id="conversationLocation"
                      value={inputs.draftLocation}
                      onChange={onChangeInput}
                      disabled={isSavingEditConversation}
                      form={formId}
                      aria-label={t('admin.draft_location')}
                      data-testid="location-input"
                    />
                  </div>
                </FormGroup>
                <div className="text-center py-4 row">
                  <SaveButton
                    type="submit"
                    isSaving={isSavingEditConversation}
                    disabled={isSavingEditConversation}
                    error={editError}
                    color={editError ? 'danger' : 'primary'}
                    className="w-100 mx-2 col"
                    icon={isSavingEditConversation ? 'loading' : undefined}
                    iconAfter
                    form={formId}
                  >
                    {t('common.save')}
                  </SaveButton>
                  <Button
                    color="secondary"
                    className="w-100 mx-2 col"
                    onClick={onCancel}
                    disabled={isSavingEditConversation}
                    type="button"
                    form={formId}
                    data-testid="cancel-button"
                  >
                    {t('common.cancel')}
                  </Button>
                </div>
                {fieldErrors.salesforceURL && (
                  <FormError name="salesforceURL" className="small">
                    {fieldErrors.salesforceURL.message}
                  </FormError>
                )}

                {editError && (
                  <FormError name="save" className={cx(styles.rowError)}>
                    {t('admin.draft_saving_error')} {editError.message}
                  </FormError>
                )}
              </div>
            ) : (
              <>
                {/* placeholder to allow collapse animation without rendering form */}
                <div style={{ height: '230px' }} aria-hidden />
              </>
            )}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(ConversationRow);
